<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-end mb-3">
          <div>
            <button @click="exportRequest" class="btn btn-primary btn-sm">
              <i class="fas fa-file-excel"></i> Export Excel
            </button>
          </div>
          <div class="mx-2">
            <b-button variant="info" @click="refreshPage" class="btn-sm"><i class="fas fa-redo-alt"></i></b-button>
          </div>
          <div class="mx-2">
            <b-button class="btn-sm" @click="clearFilter" variant="danger">Clear</b-button>
          </div>
        </div>
        <b-form @submit.prevent="getWithdralRequest">
          <button type="button" class="btn btn-info btn-sm" @click="disburseModalOpen" v-if="selected_row.length > 0">
            Disburse
          </button>

          <div class="row align-items-center justify-content-center">
            <div class="col-md-1">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    @input="getWithdralRequest"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0">
                <b-input-group size="sm">
                  <b-form-select
                      id="sort-by-select"
                      v-model="sortBy"
                      :options="sortOptionsCustomize"
                      class="w-75">
                    <template #first>
                      <option value="">-- Sort by --</option>
                    </template>
                  </b-form-select>

                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-input v-model="search" placeholder="Search by Name,Phone number"></b-form-input>
            </div>
            <div class="col-md-3">
              <b-form-input v-model="min_balance" type="number" min="0" placeholder="Enter min balance"></b-form-input>
            </div>

            <div class="col-md-1">
              <b-form-checkbox
                  id="checkbox-1"
                  name="checkbox-1"
                  value="1"
                  v-model="has_due"
                  unchecked-value="0"
              >
                Has Due
              </b-form-checkbox>
            </div>
            <div class="col-md-1">
              <button type="submit" class="d-block w-100 btn btn-info">Filter</button>
            </div>
          </div>
        </b-form>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="0"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="table-responsive">

              <template #cell(index)="data">
                <div class="white-space-nowrap">
                  <label class="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                    <!--                    <input type="checkbox" v-model="selected_row[data.item.user_id]" @change="selectMultipleRow($event, data.item)" :value="data.item.user_id">-->
                    <b-form-checkbox
                        :id="`checkbox-${data.item.user_id}`"
                        name="checkbox-1"
                        v-model="selected_row"
                        :value="`${data.item.user_id}`"
                        v-on:change="selectMultipleRow(data.item)"
                        unchecked-value="0"
                    >
                    </b-form-checkbox>
                  </label>

                </div>
              </template>

              <template #cell(total_balance)="row">
                <span :class="`${row.item.total_balance > 0 ? 'text-success' : 'text-danger'}`"
                      class="font-weight-bold font-size-lg">{{ row.item.total_balance }}</span>
              </template>

              <template #cell(last_disbursed_at)="row" class="text-center">
                {{ row.item.last_disbursed_at ? createdAtFormat(row.item.last_disbursed_at) : '-' }}
              </template>

              <template #cell(fullname)="row">
                <div class="">
                  <b-media tag="li">
                    <template #aside>
                      <div>
                        <b-img
                            :src="userPhoto(row.item.image)"
                            class="user_image">
                        </b-img>
                      </div>
                    </template>
                    <h5 class="mt-0 mb-1">{{ row.item.fullname }}</h5>
                    <p class="mb-0 font-weight-bold">{{ row.item.phone_number }}</p>
                  </b-media>
                </div>
              </template>

              <template #cell(request_status)="row">
                <div class="">
                  <b-badge variant="success" v-if="row.item.request_status == 'Disbursed'">Disbursed</b-badge>
                  <b-badge variant="danger" v-else-if="row.item.request_status == 'Rejected'">{{
                      row.item.request_status
                    }}
                  </b-badge>
                  <b-badge variant="primary" v-else-if="row.item.request_status == 'Verified'">
                    {{ row.item.request_status }}
                  </b-badge>
                  <b-badge variant="warning" v-else-if="row.item.request_status == 'Requested'">
                    {{ row.item.request_status }}
                  </b-badge>
                  <b-badge variant="info" v-else>{{ row.item.request_status }}</b-badge>
                </div>
              </template>

              <template #cell(actions)="row" v-if="items.length">
                <div>
                  <b-dropdown variant="primary" class="m-2" no-caret id="dropdown-dropleft" dropleft
                              text="Drop-Left">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <div>
                      <b-dropdown-item @click="viewDetails(row.item)">
                        <span class="pr-4"><i class="fas fa-eye"></i></span>View details
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="sendInfo(row.item)" v-if="row.item.total_balance > 1">
                        <span class="pr-4"><i class="fas fa-money-bill-alt"></i></span>Disburse
                      </b-dropdown-item>
                      <router-link :to="`/doctor/transaction/statement/${row.item.user_id}`" class="dropdown-item">
                        <span class="pr-4"><i class="fas fa-file-alt"></i></span>Transaction statement
                      </router-link>
                      <router-link :to="`/doctor/transaction/history/${row.item.user_id}`" class="dropdown-item">
                        <span class="pr-4"><i class="fas fa-file-alt"></i></span>Transaction history
                      </router-link>
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="total_request"
                :per-page="perPage"
                @change="handlePageChange"
                size="lg"
                class="my-0"
                aria-controls="doctorList"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal id="modal-lg" size="lg" title="Please provide a reason" hide-footer>
      <b-form @submit="handleSubmit" ref="form" @reset="resetModal" v-if="show">

        <b-form-group
            label="Comment *"
            :state="commnetState"
            invalid-feedback="Comment is required">
          <b-form-textarea
              id="textarea"
              v-model="form.comment"
              placeholder="Enter something..."
              rows="3"
              :state="commnetState"
              required>
            >
          </b-form-textarea>
        </b-form-group>


        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>
    <!-- modal -->
    <b-modal id="modal-disburse" size="lg" title="Please provide transaction details" hide-footer>
      <b-form @submit="disburseRequest" @reset="resetModal">

        <b-form-group
            id="input-group-1"
            label="Amount *"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="disburse.amount"
              type="text"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Transaction type">
          <b-form-select v-model="disburse.transaction_type" :options="transaction_type" required></b-form-select>
        </b-form-group>

        <b-form-group
            id="input-group-1"
            :label="`${disburse.transaction_type}`"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="disburse.banking_name"
              type="text"
              required
              :placeholder="`${disburse.transaction_type == 'Mobile Banking' ? 'Mobile Banking Name' : 'Bank Name , Branch Name'}`"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-1"
            label="Transaction id *"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="disburse.transaction_id"
              type="text"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Action comment *">
          <b-form-textarea
              id="textarea"
              v-model="disburse.comment"
              placeholder="Enter something..."
              rows="3"
              required
          ></b-form-textarea>
        </b-form-group>


        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>

    <!-- modal for bank account form -->
    <b-modal id="open-back-account-form" size="lg" title="Add bank account" hide-footer>
      <b-form @submit="accountCreate" @reset="resetModal">
        <div class="mb-8">
          <label>Select bank type</label>
          <v-select
              :options="back_type"
              :reduce="back_type => back_type.bank_account_type_id"
              v-model="account_info.bank_account_type_id"
              label="title">
          </v-select>
        </div>

        <b-form-group
            id="input-group-1"
            label="Account name *"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="account_info.account_name"
              type="text"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-1"
            label="Account number *"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="account_info.account_number"
              type="text"
              required
          ></b-form-input>
        </b-form-group>


        <div v-if="account_info.bank_account_type_id ==1">
          <div class="mb-8">
            <label>Select bank</label>
            <v-select
                :options="back_list"
                :reduce="back_list => back_list.id"
                v-model="account_info.bank_id"
                label="bank_title">
            </v-select>
          </div>


          <b-form-group
              id="input-group-1"
              label="Branch name *"
              label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="account_info.branch_name"
                type="text"
                required
            ></b-form-input>
          </b-form-group>

        </div>
        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>
    <!--    View details modal -->
    <b-modal id="view-details" size="xl" hide-footer>
      <div>
        <div class="card">
          <div class="card-body">
            <h3>Doctor information</h3>
            <div class="text-muted mt-5">
              <h5>{{ row_details.fullname }}</h5>
              <p class="mb-0">{{ row_details.user_profession }}</p>
              <p class="mb-0">{{ row_details.phone_number }}</p>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <h3>Account information</h3>
            <div class="mt-5">
              <b-table striped bordered hover :fields="accounts_table_columns" :items="row_details.bank_account_info"
                       show-empty>
                <template #cell(bank_info)="data">
                  <p class="mb-0">{{ data.item.bank_info ? data.item.bank_info.bank_title : '' }}</p>
                  <p class="mb-0">{{ data.item.bank_info ? data.item.bank_info.address : '' }}</p>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!--    -->
    <b-modal
        id="disburse-model"
        ref="modal"
        size="xl"
        title="Submit Your Name"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleDisburseOk"
    >
      <form ref="form" @submit.stop.prevent="handleDisbburseSubmit">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
                label="Transaction type"
                label-for="name-input"
            >
              <b-form-input
                  id="name-input"
                  v-model="disburse_form.transaction_type"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
                label="Transaction ID"
                label-for="name-input"
            >
              <b-form-input
                  id="name-input"
                  v-model="disburse_form.transaction_id"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
                label="Amount"
                label-for="name-input"
            >
              <b-form-input
                  id="name-input"
                  v-model="disburse_form.amount"
              ></b-form-input>
              <b-form-checkbox
                  v-if="disburse_form.amount"
                  id="checkbox-disburse_equally"
                  v-model="disburse_form.disburse_equally"
                  name="checkbox-disburse_equally"
                  value="1"
                  @change="disburseEqually()"
                  unchecked-value="0"
              >
                Disburse Equally
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="row">

          <div class="col-md-12">
            <table class="table table-bordered">
              <thead>
              <th>Name</th>
              <th>Select AC</th>
              <th>Disburse Amount</th>
              <th>Wallet Balance</th>
              <th>Action</th>
              </thead>
              <tbody>
              <tr v-for="(row,index) in row_details" :key="index">

                <td>{{ row.fullname }}</td>
                <td>
                  <select name="account_no" class="form-control" v-model="row.bank_account_id" :id="disburse_form.account_no" v-if="row.bank_account_info">
                    <option>------</option>
                    <option :value="accounts.id" v-for="(accounts,index) in row.bank_account_info" :key="index">
                      {{  accounts.account_name }}
                      <br> {{ accounts.account_no ? accounts.account_no : '' }}
                      <br> {{ accounts.bank_account_type ? accounts.bank_account_type : ''}}
                    </option>
                  </select>
                </td>
                <td>
                    <b-form-input
                        type="number"
                        id="index"
                        v-model="row.withdrawal_amount"
                    ></b-form-input>
                </td>
                <td>{{ row.total_balance }}</td>
                <td>
                  <button type="button" class="btn btn-danger" @click="delRowDetailsItem(index)"><i class="fas fa-trash-alt"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-form-textarea
                id="textarea"
                v-model="disburse_form.comment"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
            ></b-form-textarea>

          </div>
        </div>

      </form>
    </b-modal>

    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {WithDrawalRequestMixin} from '../../mixins/WithDrawalRequestMixin';
import {DateFormat} from "../../mixins/core/DateFormat";
import {ExportMixin} from "../../mixins/core/ExportMixin";

export default {
  name: "WithdrawalRequests",
  mixins: [WithDrawalRequestMixin, DateFormat, ExportMixin],
  title: 'Withdrawal Request',
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.user_icon i {
  font-size: 10px;
}

.user_info li {

  margin: 5px 0px;
}

.user_info li i {
  padding: 4px 6px;
  font-size: 10px;
  color: #44A0FF;
  border-radius: 50%;
  background-color: rgba(230, 242, 255, 0.9);
  margin-right: 5px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.user_image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  transition: .5s;
  object-position: center;
  object-fit: inherit;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}

tr td:nth-child(2) {
  width: 300px;
}

.invalid-feedback {
  display: block !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-nowrap-width {
  white-space: nowrap;
  width: 300px;
  overflow: scroll;
}

.checkbox > span {
  background-color: #fff;
  border: 3px solid #1b87c9;
}
</style>