<template>
  <div>
    <b-modal
        id="modal-1"
        size="lg"
        title="Assign Use to Institute"
        @hidden="resetModal"
        @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmitEdit" style="height: 50vh;">
        <div class="mb-3">
          <label>Select Institute</label>
          <v-select
              :options="institutes"
              :reduce="institutes => institutes.id"
              v-model="form.institute_id"
              label="name"
              name="name">
          </v-select>
        </div>

      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AssignUserToInstitute",
  props: {
    doctor_id: {},
  },
  data() {
    return {
      institutes: [],
      form: {
        institute_id: '',
        user_id: '',
      },
    }
  },
  mounted() {
    this.getInstituteFilter();
    this.$bvModal.show('modal-1')
    if (this.doctor_id) {
      this.getAssignInstitute();
    }
  },
  methods: {
    resetModal() {
      this.form = {}
      this.$emit('modalClosed')
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmitEdit()
    },
    getInstituteFilter() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko`).then(res => {
        this.institutes = res.data.data;
      })
    },
    handleSubmitEdit() {
      this.form.user_id = this.doctor_id;
      axios.post(`${process.env.VUE_APP_URL}/api/v2/institute/user/assign?sub_type=physio_assistant&api_token=${localStorage.getItem('api_token')}`, this.form).then(res => {
        if (res.data.status_code == 200) {
          this.$snotify.success('Successfully Assigned');
          this.$bvModal.hide('modal-1');
          this.resetModal();
        } else {
          this.$snotify.error(res.data.message);
        }
      })
    },
    getAssignInstitute() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/institute/user/assigned?api_token=${localStorage.getItem('api_token')}&user_id=${this.doctor_id}`).then(res => {
        this.form.institute_id = res.data.data.institute_id;
      })
    }
  }
}
</script>

<style scoped>

</style>
