<template>
  <div>
    <div class="card">
      <div class="card-body">

        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="columns"
                  worksheet="Call log"
                  name="Call log">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <b-table
            responsive
            striped hover
            :items="items.length ? filtered : items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            thead-class="bg-primary text-white"
            class="mt-5"
            show-empty>


          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
            </td>
          </template>

          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(user)="row">
            {{ row.item.fullname }}
          </template>

          <template #cell(call_status)="row">
            {{ row.item.call_action_status }}
          </template>

          <template #cell(call_action_date)="row">
            {{ row.item.call_action_date }}
          </template>


          <template #cell(call_response)="row">
            <b-badge variant="success" v-if="row.item.call_response == true">Yes</b-badge>
            <b-badge variant="danger" v-else>No</b-badge>
          </template>

          <template #cell(reminder_date)="row">
            {{ row.item.reminder_date ? row.item.reminder_date : 'N/A' }}
          </template>

        </b-table>

        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="items.length"
                size="lg"
                class="my-0"
            ></b-pagination>


          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {CallLogMixin} from "../../mixins/CallLogMixin";
import {ResponseMixin} from "../../mixins/core/ResponseMixin";

export default {
  name: "CallLogDetails",
  mixins: [CallLogMixin]
}
</script>

<style scoped>

</style>
