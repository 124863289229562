<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <h2>Bank list</h2>
          <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new Bank
          </b-button>
          <b-modal
              id="modal-1"
              size="lg"
              title="Create a new bank"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
              <b-form-group
                  label-for="name-input"
                  invalid-feedback="Bank name is required"
                  :state="bank_name_state">
                <label for="">Bank name <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.bank_name"
                    :state="bank_name_state"
                    required/>
              </b-form-group>
              <b-form-group class="mt-5" :state="bank_address_state" invalid-feedback="Bank address is required">
                <label for="">Bank address <span class="text-danger">*</span></label>
                <b-form-textarea
                    id="textarea"
                    v-model="form.bank_address"
                    placeholder="Enter Address"
                    rows="3"
                    class=""
                    :state="bank_address_state"
                    required
                ></b-form-textarea>
              </b-form-group>
              <b-form-group class="mt-5" invalid-feedback="Bank image is required">
                <label for="">Upload bank image <span class="text-danger">*</span></label>
                <b-form-file
                    class="mt-1"
                    ref="bank_logo"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    :state="bankImageState"
                    required
                ></b-form-file>
              </b-form-group>

              <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  name="checkbox-1"
                  class="mt-3"
                  value="1"
                  unchecked-value="0"
              >
                Status
              </b-form-checkbox>


            </form>
          </b-modal>
        </div>


        <div class="row mt-3 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Bank list"
                  name="Bank list">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(status)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.status == 1">Active</b-badge>
                  <b-badge variant="success" v-else>Inactive</b-badge>
                </div>
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'bank_logo'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>

              <template #cell(bank_logo)="row">
                <div class="">
                  <b-img :src="userPhoto(row.item.bank_logo)" class="user_image" :alt="row.item.bank_title"
                         v-if="row.item.bank_logo"></b-img>
                  <b-img v-else-if="row.itm"></b-img>
                  <img src="../../assets/img/blank.png" class="user_image" v-else/>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>

        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>

import {BankMixin} from "../../mixins/BankMixin";
import {ResponseMixin} from "../../mixins/core/ResponseMixin";

export default {
  name: "BankList",
  mixins: [BankMixin, ResponseMixin],

}
</script>

<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>