<template>
    <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <b-btn variant="dark" @click="$router.push('/occ/report/quality-control')">Back</b-btn>
                        <b-btn variant="warning" @click="print()">Print</b-btn>
                    </div>
                    <div  class="row jusity-contents-center" style="justify-content: center;">
        
                    <div class="col-md-8">
                   
                    <div id="printArea" >
                    <div style="margin: 30px;">
                      
                        <div class="form-header" style="display: flex;justify-content: center;">
                            
                            <div>
                            <h2><u>Audit Report</u></h2>
                            <h3> <strong>{{  data.name }} </strong></h3>
                            <h3> <strong>Designation: </strong>{{  data.designation }}</h3>
                            <h3> <strong>Department: </strong>{{  data.department }}</h3>
                            <h3> <strong>Date of submission:</strong>  {{  data.submission_date }} </h3>
                            </div>
                        </div>
                        <div>
                        <h3 class="header-description">{{  data.description }}</h3> 
                    </div>
                        <br>
                        <div v-if="data.topics">
                            <h5 class="text-center"><u> <strong>  Visit to {{  data.center }} </strong> </u></h5>
                            <br>
                            <table class="table table-bordered" style="border: 1px solid #000 !important">
                                <thead>
                                    <tr>
                                        <th>S.N</th>
                                        <th>Topics</th>
                                        <th>Weightage</th>
                                        <th>Score</th>
                                        <th>Remarks</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(topic, index) in JSON.parse(data.topics)" :key="index" style="font-size: 15px;font-weight: 500;">
                                        <td>{{  index+1 }}</td>
                                        <td>{{  topic.title }}</td>
                                        <td>{{  topic.weightage }}</td>
                                        <td>{{  topic.score }}</td>
                                        <td>{{  topic.remarks }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br>
                        <div v-if="data.findings">
                            <h2><u><strong>Findings</strong></u></h2>
                            <div style="margin-left: 50px;margin-top: 20px;font-size: 15px;font-weight: 500;">
                                <div v-for="(item, index) in  JSON.parse(data.findings)" :key="index" style="display: flex;">
                                    <div>{{  index+1 }}. </div>
                                    <div style="margin-left: 15px;">{{ item.title }} </div>
                                </div>
                            </div>
                            <!-- <ul style="list-style: auto;margin-left: 50px;margin-top: 20px;font-size: 15px;font-weight: 500;">
                                <li v-for="(item, index) in  JSON.parse(data.findings)" :key="index"> <span style="margin-left:15px;">{{ item.title }}</span></li>
                            </ul> -->
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default{
    name: 'QualityControlPreview',
    data(){
        return {
            data: {},
        }
    },
    created() {
    
        this.getItem();
    },
    methods: {
        getItem() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko/center/audit/reports/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
            .then(res => {
                this.data = res.data.data;
            })
        },

        print() {
            this.printPage = true;
            setTimeout(() => {
                this.printSetup();
            }, 50);
        },

        printSetup() {
        // Get HTML to print from element
            const prtHtml = document.getElementById('printArea').innerHTML;
            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [
                ...document.querySelectorAll('link[rel="stylesheet"], style'),
            ]) {
                stylesHtml += node.outerHTML;
            }

            // Open the print window
            const WinPrint = window.open(
                '',
                '',
                'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
            );

            WinPrint.document.write(
                `<!DOCTYPE html>
                    <html>
                    <head>
                        <title>Application Detail </title>
                        ${stylesHtml}
                    </head>
                    <body>
                        ${prtHtml}
                    </body>
                    </html>
                `);

                WinPrint.document.close();
                WinPrint.focus();
                WinPrint.addEventListener('load', () => {
                    setTimeout(() => {
                    WinPrint.print();
                    WinPrint.close();
                    this.printPage = false;
                    }, 200);
            });
        },
    }

}
</script>
<style scoped>
@page  
{ 
    size: auto;   /* auto is the initial value */ 

    /* this affects the margin in the printer settings */ 
    margin: 55mm 25mm 25mm 25mm;  
} 

.form-header h3{
    line-height: 25px;
    font-size: 17px;
    font-weight: 500;
}
.header-description {
    /* line-height: 3px; */
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
}
#printArea {
    /* color: #000; */
}
.table thead th {
    /* line-height: 25px; */
    font-size: 16px;
    font-weight: 700;
}
.table-bordered th, .table-bordered td {
    border: 1px solid #000;
}
</style>