import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const MedicineMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
                "Medicine generics": {
                    field: "medicine_generics_name",
                },
                "Strength": {
                    field: "strength",
                },
                "Dosages desc type": {
                    field: "dosages_desc_type",
                },
                "Use for": {
                    field: "use_for",
                },
                "Dar": {
                    field: "dar",
                },
                "Manufacturer": {
                    field: "manufacturer_id",
                },

                "Importer": {
                    field: "importer_id",
                },
                "Unit price": {
                    field: "unit_price",
                },


            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Brand name',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Generic Name',
                    key: 'generics',
                    sortable: true,
                },
                {
                    label: 'Strength',
                    key: 'strength',
                    sortable: true,
                },
                {
                    label: 'Dosage Form',
                    key: 'dosages_desc_type',
                    sortable: true,
                },
                {
                    label: 'Dosage type',
                    key: 'dosage_type',
                    sortable: true,
                },
                {
                    label: 'Use for',
                    key: 'use_for',
                    sortable: true,
                },
                {
                    label: 'Indications',
                    key: 'generics_indications',
                    sortable: true,
                },
                {
                    label: 'Manufacturer Name',
                    key: 'medicine_company',
                    sortable: true,
                },
                {
                    label: 'Pack Size',
                    key: 'pack_size',
                    sortable: true,
                },
                {
                    label: 'Price',
                    key: 'unit_price',
                    sortable: true,
                },
                {
                    label: 'Unit price',
                    key: 'unit_price',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            strengthState: null,
            useForState: null,
            dosagesState: null,
            darState: null,
            unit_priceState: null,
            dosages_desc_typeState: null,
            form: {
                title: '',
                strength: '',
                use_for: '',
                dar: '',
                unit_price: '',
                dosages_desc_type: '',
                manufacturer_id: '',
                medicine_generics_id: '',
            },
            edit: false,
            edit_id: '',
            medicine_generics: [],
            medicine_company: [],
            manufacturers: [],
            loading: true,
            medicine_generics_is_required: '',
            medicine_company_required: '',
            price_formet: '',
            total_items: 0,
            medicine_generics_id: '',
            manufacturers_id: '',
            export_limit: 1000,
            total_export_excel_file: 0,
            base_url: process.env.VUE_APP_URL

        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Medicine", route: "/medicine"},
        ]);
        this.getMedicine();
        this.getMedicineGenerics();
        this.getMedicineCompany();
    },
    created() {
        localStorage.setItem('tabIndex', 11)
        this.$root.$emit('call-active-menu', 11);
    },
    metaInfo: {
        title: 'Orko Health Ltd | Medicine',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },

    methods: {
        getMedicine() {
            this.filter = '';
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/list?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=0`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.total_items = response.data.total_count;
                        this.items = response.data.data;
                        this.loading = false;
                        this.total_export_excel_file = this.total_items / this.export_limit;
                    }
                })
        },
        searchMedicine() {
            // event.preventDefault()
            this.loading = true
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/filter?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=0${this.filter ? '&search_text=' + this.filter : ''}${this.medicine_generics_id ? '&medicine_generics_id=' + this.medicine_generics_id : ''}${this.manufacturers_id ? '&manufacturer_id=' + this.manufacturers_id : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        if (this.filter) {
                            this.total_items = response.data.data.length;
                        } else {
                            this.total_items = response.data.total_count;
                        }

                        this.items = response.data.data;
                        this.total_items = response.data.total_count;
                        this.loading = false;
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/filter?api_token=${localStorage.getItem('api_token')}&offset=${correctValue}&limit=${this.perPage}${this.filter ? '&search_text=' + this.filter : ''}${this.medicine_generics_id ? '&medicine_generics_id=' + this.medicine_generics_id : ''}${this.manufacturers_id ? '&manufacturer_id=' + this.manufacturers_id : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        // this.total_items = response.data.total_count;
                        this.items = response.data.data;
                        // if(this.filter) {
                        //     this.total_items = response.data.data.length;
                        // }

                        this.loading = false;
                    }
                })
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            this.unit_priceState = valid
            this.strengthState = valid
            this.dosagesState = valid
            this.useForState = valid
            return valid
        },
        resetModal() {
            this.form = {
                title: '',
                medicine_generics_id: '',
                strength: '',
                use_for: '',
                dar: '',
                unit_price: '',
                dosages_desc_type: '',
                manufacturer_id: '',
            };
            this.titleState = null;
            this.medicine_generics_is_required = null;
            this.medicine_company_required = null;
            this.titleState = null;
            this.strengthState = null;
            this.useForState = null;
            this.dosagesState = null;
            this.darState = null;
            this.unit_priceState = null;
            this.dosages_desc_typeState = null;
            this.edit = false;
            this.edit_id = '';
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler

            if (this.edit) {
                return this.updatedata();
            }
            this.handleSubmit()
        },
        handleImport(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmitImport()
        },
        handleSubmitImport() {
            let form = new FormData();
            // let imported_file = document.getElementById('imported_file').files[0];
            let imported_file = this.$refs.imported_file.files[0];
            form.append("imported_file", imported_file);
            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/medicine/import?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    }
                    this.$emit('call_basic_info')
                    this.resetModal();
                    this.$bvModal.hide('importModal')
                    this.$snotify.success('Medicine imported successfully');
                })
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }

            if (this.form.medicine_generics_id == '' || this.form.medicine_generics_id == null) {
                this.medicine_generics_is_required = "Medicine generics is required";
            }
            if (this.form.manufacturer_id == '' || this.form.manufacturer_id == null) {
                return this.medicine_company_required = 'Medicine company is required';
            }

            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);
            form.append("medicine_generics_id", this.form.medicine_generics_id);
            form.append("strength", this.form.strength);
            form.append("dosages_desc_type", this.form.dosages_desc_type);
            form.append("use_for", this.form.use_for);
            form.append("unit_price", this.form.unit_price);
            form.append("manufacturer_id", this.form.manufacturer_id);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medicine/create?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicine();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        updatedata() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }

            if (this.form.medicine_generics_id == '' || this.form.medicine_generics_id == null) {
                this.medicine_generics_is_required = "Medicine generics is required";
                return
            }
            if (this.form.manufacturer_id == '' || this.form.manufacturer_id == null) {
                return this.medicine_company_required = 'Medicine company is required';
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);
            form.append("medicine_generics_id", this.form.medicine_generics_id);
            form.append("strength", this.form.strength);
            form.append("dosages_desc_type", this.form.dosages_desc_type);
            form.append("use_for", this.form.use_for);
            form.append("unit_price", this.form.unit_price);
            form.append("manufacturer_id", this.form.manufacturer_id);
            // form.append("manufacturer_id", this.form.manufacturer.id);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medicine/update/${this.edit_id}?api_token=${localStorage.getItem('api_token')}&_method=put`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicine();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        getMedicineGenerics() {
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/medicine/generics/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    }
                    this.medicine_generics = response.data.data;

                })
        },
        getMedicineCompany() {
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/medicine/company/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    }
                    this.manufacturers = response.data.data;

                })
        },
        editAction(row) {
            this.$bvModal.show('modal-1');
            this.edit = true;
            this.edit_id = row.item.id;
            this.form = row.item;
        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/medicine/remove/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getMedicine();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        exportMedicine(skip, take) {

            if (skip == 0 || skip && take) {
                this.loading = true;
                axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/export?api_token=${localStorage.getItem('api_token')}${skip ? '&skip=' + skip : '&skip=0'}${take ? '&take=' + take : ''}`)
                    .then(response => {
                        var url = `${process.env.VUE_APP_URL}/api/v1/medicine/export?api_token=${localStorage.getItem('api_token')}${skip ? '&skip=' + skip : '&skip=0'}${take ? '&take=' + take : ''}` // http://localhost.local/public/files/data.xlsx
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = url.split("/").pop();
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        this.loading = false;
                    })
            } else {

                this.loading = true;
                axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/export?api_token=${localStorage.getItem('api_token')}${this.filter ? '&search_text=' + this.filter : ''}${this.medicine_generics_id ? '&medicine_generics_id=' + this.medicine_generics_id : ''}${this.manufacturers_id ? '&manufacturer_id=' + this.manufacturers_id : ''}`)
                    .then(response => {
                        var url = `${process.env.VUE_APP_URL}/api/v1/medicine/export?api_token=${localStorage.getItem('api_token')}${this.filter ? '&search_text=' + this.filter : ''}${this.medicine_generics_id ? '&medicine_generics_id=' + this.medicine_generics_id : ''}${this.manufacturers_id ? '&manufacturer_id=' + this.manufacturers_id : ''}` // http://localhost.local/public/files/data.xlsx
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = url.split("/").pop();
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        this.loading = false;
                    })
                // ${this.filter ? '&search_text=' + this.filter : ''}${this.medicine_generics_id ? '&medicine_generics_id=' + this.medicine_generics_id : ''}${this.manufacturers_id ? '&manufacturer_id=' + this.manufacturers_id : ''}
            }
        },
        exportMedicineModal() {
            this.$bvModal.show('exportModal')
        },
        clearFilter() {
            this.filter = null;
            this.medicine_generics_id = '';
            this.manufacturers_id = '';
            this.getMedicine();

        },
        importMedicine() {
            this.$bvModal.show('importModal')
        }

    }
}