<template>
  <div>
    <b-modal
        id="addAgentModal"
        title="Add Aide"
        size="lg"
        @hidden="resetModal"
        scrollable
        @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleAddAgentSubmit" enctype="multipart/form-data">

        <div class="row mb-5">
          <div class="col-6">
            <label>First name<span class="text-danger">*</span></label>
            <b-form-input
                type="text"
                :placeholder="`First name`"
                v-model="form.first_name"
                required>
            </b-form-input>
          </div>
          <div class="col-6">
            <label>Last name<span class="text-danger">*</span></label>
            <b-form-input
                require type="text"
                :placeholder="`Last name`"
                v-model="form.last_name"
                required>
            </b-form-input>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-6">
            <label>Password<span class="text-danger">*</span></label>
            <b-form-input required type="password" placeholder="Password"
                          v-model="form.password"></b-form-input>
          </div>
          <div class="col-6">
            <b-form-group label="Profile image">
              <b-form-file
                  id="profile_image"
                  ref="profile_image"
                  accept=".png,.jpg,.jpeg">
              </b-form-file>
            </b-form-group>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-6">
            <label>Telephone<span class="text-danger">*</span></label>
            <VuePhoneNumberInput
                @update="form.primaryPhoneNumberupdatedObj = $event" size="sm"
                default-country-code="BD" v-model="form.phone_number"
            />

          </div>
          <div class="col-6">
            <label>Secondary Telephone</label>
            <VuePhoneNumberInput @update="form.secondaryPhoneNumberupdatedObj = $event" size="sm"
                                 default-country-code="BD" v-model="form.secondary_phone_no"/>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-6">
            <label>Sub type<span class="text-danger">*</span></label>
            <v-select
                :options="sub_types"
                :reduce="sub_types => sub_types.value"
                v-model="form.sub_type"
                label="name"
                class="h-100"
                name="name">
            </v-select>
          </div>
          <div class="col-6">
            <label>Institute<span class="text-danger">*</span></label>
            <v-select
                :options="institutes"
                :reduce="institutes => institutes.id"
                label="name"
                v-model="form.institute_id"
                class="h-100">
            </v-select>
          </div>
        </div>


        <div class="row mb-5">
          <div class="col-6">
            <label>NID card No <span class="text-danger">*</span></label>
            <b-form-input type="tel" placeholder="NID card No"
                          v-model="form.identity_card_no"
                          required></b-form-input>
          </div>
          <div class="col-6">
            <b-form-group label="NID Image">
              <b-form-file
                  id="NID_image"
                  ref="NID_image"
                  required
                  accept=".png,.jpg,.jpeg">
              </b-form-file>
            </b-form-group>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-6">
            <label>District<span class="text-danger">*</span></label>

            <v-select
                :options="districts"
                :reduce="districts => districts.id"
                v-model="form.region_id"
                @input="getTowns"
                label="name"
                class=""
                name="name"
            >
            </v-select>


          </div>
          <div ref="thana_div" class="col-6" v-if="form.region_id">
            <label>Town<span class="text-danger">*</span></label>
            <v-select
                :options="towns"
                :reduce="towns => towns.id"
                v-model="form.town_id"
                label="name"
                class="h-100"
                name="name">
            </v-select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <label>Address<span class="text-danger">*</span></label>
            <b-form-textarea
                aria-required="true" required
                id="textarea-small"
                size="sm"
                v-model="form.location"
                placeholder="Agent Address"
            ></b-form-textarea>
          </div>
        </div>

      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: "AideAddEditModal",
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        password: '',
        phone_number: '',
        secondary_phone_no: '',
        sub_type: '',
        identity_card_no: '',
        region_id: '',
        town_id: '',
        location: '',
        institute_id: '',
      },
      sub_types: [],
      institutes: [],
      districts: [],
      towns: [],
    }
  },
  created() {
    this.getUserDetails();
    this.getCities();
    this.getTowns();
    this.getInstitute();
    this.subTypes();
  },
  mounted() {
    this.$bvModal.show('addAgentModal');
  },
  methods: {
    getUserDetails() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {

            this.form.phone_number = response.data.data.phone_number;
            this.form = response.data.data;
            if (response.data.data.pharmacy_details) {
              this.form.institute_id = response.data.data.pharmacy_details.institute_id
            }
          })
    },
    getInstitute() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.institutes = response.data.data
            }
          })
    },
    getCities() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=city&parent_id=18`)
          .then(response => {
            this.districts = response.data.location;
          })
    },
    getTowns() {
      // this.form.town_id = '';
      axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=town&parent_id=${this.form.region_id}`)
          .then(response => {
            this.towns = response.data.location;
          })
    },
    subTypes() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/partner/agent/sub_type/list?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            for (const property in res.data.data) {
              this.sub_types.push({
                name: property,
                value: res.data.data[property],
              })
            }
          })
    },
    resetModal() {
      this.form = {
        first_name: '',
        last_name: '',
        password: '',
        phone_number: '',
        secondary_phone_no: '',
        sub_type: '',
        identity_card_no: '',
        region_id: '',
        town_id: '',
        location: '',
        institute_id: '',
      };
      this.$emit('reloadComponent');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleAddAgentSubmit()
    },
    handleAddAgentSubmit() {
      let form = new FormData();
      let profile_image = this.$refs.profile_image.files[0];
      let NID_image = this.$refs.NID_image.files[0];

      form.append("first_name", this.form.first_name);
      form.append("last_name", this.form.last_name);
      if (this.form.password) {
        form.append("password", this.form.password);
      }

      form.append("phone_number", this.form.phone_number);
      form.append("secondary_phone_no", this.form.secondary_phone_no);
      form.append("identity_card_no", this.form.identity_card_no);
      form.append("city_id", this.form.region_id);
      form.append("town_id", this.form.town_id);
      form.append("location", this.form.location);
      // below section is set default value
      // form.append("blood_group", "A+");
      // form.append("dob", "1990-01-01");
      // form.append("country_id", 18);
      // form.append("platform", "backoffice");
      // form.append("is_partner", "1");
      // form.append("is_system_user", "0");
      form.append("system_user", localStorage.getItem('user_id'));
      // form.append("user_type", "aide");
      form.append("institute_id", this.form.institute_id);
      // form.append("gender", "male");
      form.append("sub_type", this.form.sub_type);
      if (profile_image) {
        form.append("profile_img", profile_image);
      }
      if (NID_image) {
        form.append('identity_card', NID_image);
      }
      this.$nextTick(() => {
        axios
            .post(`${process.env.VUE_APP_URL}/api/v1/partner/agent/update/${this.$route.params.id}?_method=put&api_token=${localStorage.getItem('api_token')}&platform=backoffice`, form)
            .then(response => {
              if (response.data.status_code == 400) {
                return this.$snotify.error('Something worng');
              }
              this.resetModal();
              this.$bvModal.hide('addAgentModal');
              this.$emit('reloadComponent', response.data.data);
              this.$snotify.success('Successfully Updated');

            })
      });
    }
  }
}
</script>

<style scoped>

</style>
