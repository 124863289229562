<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <div class="row mt-5 align-items-center">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12">
              <label for="">From</label>
<!--              <input type="date" v-model="from_date" class="form-control">-->
              <b-form-select v-model="from_date" :options="filter_options"></b-form-select>

            </div>
          </div>
        </div>
        <div class="col-md-4 mt-6">
          <label>Doctor</label>
            <b-form-group>
              <input type="text" v-model="q" placeholder="Search Doctor by name or phone no." class="form-control">
            </b-form-group>
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
              <template #cell(actions)="row">
                <button class="btn btn-primary btn-sm" @click="showDetails(row.item)">Details</button>
              </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              @change="getItems"
              class="my-0"
              aria-controls="doctorList"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {dateFormate} from "../../../healper/dateTimeFormet";

export default {
  name: "UserMovementTracking",
  title: 'Doctor Movement Tracking',
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: 20,
      items: [],
      fields: [
        {
          key: 'user_name',
          label: 'Doctor\'s Name',
          sortable: true,
        },
        {
          key: 'phone_number',
          label: 'Phone Number',
          sortable: true,
        },
        {
          key: 'total_point',
          label: 'Activity Point',
          sortable: true,
        },
        {
          key: "actions",
        },
      ],
      from_date: 'weekly',
      total_count: 0,
      q: '',
      filter_options: [
        {
          text: 'Weekly',
          value: 'weekly'
        },
        {
          text: 'Biweekly',
          value: 'biweekly'
        },
        {
          text: 'monthly',
          value: 'Monthly'
        },
      ]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Doctor Movement Tracking", route: "/doctor/movement/tracking"},
    ]);
  },
  created() {
    localStorage.setItem('tabIndex', 1)
    this.$root.$emit('call-active-menu', 1);
    this.getItems();
  },
  methods: {
    getItems(value) {
      console.log('weekly', dateFormate().biweekly_first_day, 'last day', dateFormate().biweekly_last_day)
      this.loading = true;
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      var date_range = '';
      if(this.from_date == 'weekly'){
        date_range = dateFormate().weekly_first_day
      }else if(this.from_date == 'biweekly') {
        date_range = dateFormate().biweekly_first_day
      }else{
       date_range = dateFormate().monthlay_first_day
      }
      let url = `${process.env.VUE_APP_URL}/api/v2/administration/doctors/app/engagement?api_token=${localStorage.getItem('api_token')}${this.from_date ? '&from_date=' +  date_range : ''}&limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}${this.q ? '&q='+this.q : ''}`;
      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },

    clear() {
      this.from_date = null;
    },
    showDetails(item) {
      this.$router.push(`/doctor/movement/tracking/${item.user_id}?from_date=${this.from_date}`)
    }
  }
}
</script>

<style scoped>

</style>
