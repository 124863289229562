<template>
  <div>
    <b-modal
        id="imagePreview"
        hide-backdrop content-class="shadow"
        size="lg"
        hide-footer>
      <img src="" alt="">
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ImageModal",
  props:{
    // eslint-disable-next-line vue/require-prop-type-constructor
    image: '',
  },
  mounted() {
    this.$bvModal.show('imagePreview');
  }
}
</script>

<style scoped>

</style>