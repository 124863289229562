<template>
  <div>

    <div class="row mt-3">
      <div class="col-md-2">
        <div class="">
          <b-form-group
              class="mb-0">
            <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
      <div class="col-md-2">
        <download-excel
            :data="items"
            class="btn btn-primary"
            :fields="json_fields"
            worksheet="Agent wise Transaction list"
            name="Agent wise Transaction list">
          <i class="fas fa-file-excel"></i> Export Excel
        </download-excel>
      </div>
      <div class="col-md-8">
        <b-form-group
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-0">
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"/>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <div class="row mt-5 align-items-center">
      <div class="col-md-4">
        <label for="">From</label>
        <input type="date" v-model="startDate" class="form-control">
      </div>
      <div class="col-md-4">
        <label for="">To</label>
        <input type="date" v-model="endDate" class="form-control">
      </div>
      <div class="col-md-2">
        <button class="btn btn-danger mt-7" @click="clearDate">Clear</button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 my-5" v-for="(history, index) in agent_pharmacy_history" :key="index">

        <div class="card" v-if="index !=  'transactions'">
          <div class="card-body">
            <p class="report_label mb-1">{{ underScoreToSpace(index) }}</p>
            <h1 class="bg-default mb-0 ">{{ Math.abs(history) }}</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-table
            striped hover responsive
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-10">

          <template #cell(patient_name)="row">
            <div class="">
              <b-media tag="li" class="d-flex justify-content-center align-items-center">
                <template #aside>
                  <div>
                    <b-img
                        v-if="row.item.patient_image"
                        :src="userPhoto(row.item.patient_image ? row.item.patient_image : '' )"
                        class="user_image">
                    </b-img>
                    <img src="../../../assets/img/blank.png" class="user_image" v-else>
                  </div>
                </template>

                <p class="mb-0 font-weight-bold p">
                  {{ row.item.patient_name }}
                </p>
                <p class="mb-0 text-muted">{{ row.item.patient_phone_number }}</p>

              </b-media>
            </div>
          </template>

          <template #cell(doctor_name)="row">
            <div class="">
              <b-media tag="li" class="d-flex justify-content-center align-items-center">
                <template #aside>
                  <div v-if="row.item.doctor_image">

                    <b-img
                        v-if="!imgError"
                        :src="userPhoto(row.item.doctor_image ? row.item.doctor_image : '' )"
                        class="user_image" @error="onImgError()">
                    </b-img>

                    <img src="../../../assets/img/blank.png" class="user_image" v-else>

                  </div>
                </template>

                <p class="mb-0 font-weight-bold p">
                  {{ row.item.doctor_name }}

                </p>
                <p class="mb-0 text-muted">{{ row.item.doctor_degree }}</p>

              </b-media>
            </div>
          </template>

          <template #cell(prescription_status)="row">
            <div class="">

              <b-badge :variant="`${row.item.prescription_status == true ? 'success' : 'danger'}`">
                {{ row.item.prescription_status == true ? 'Valid' : 'Invalid' }}
              </b-badge>

            </div>
          </template>

        </b-table>
      </div>
    </div>

<!--    <div class="row mt-3">-->
<!--      <div class="col-md-12">-->
<!--        <b-pagination-->
<!--            v-if="items.length"-->
<!--            v-model="currentPage"-->
<!--            :total-rows="items.length"-->
<!--            :per-page="perPage"-->
<!--            size="lg"-->
<!--            class="my-0"-->
<!--        ></b-pagination>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {underScoreToSpace} from "../../../healper/nameFormating";

export default {
  name: "DoctorTransactionHistoryList",
  data() {
    return {
      downloadLoading: true,
      filename: '',
      autoWidth: true,
      bookType: 'xlsx',
      json_fields: {
        "Doctor Name": {
          field: "doctor_name"
        },
        "Doctor Degree": {
          field: "doctor_degree"
        },
        "Patient Name": {
          field: "patient_name"
        },
        "Patient Phone Number": {
          field: "patient_phone_number"
        },
        "Prescription Fee": {
          field: "prescription_fee"
        },
      },
      columns: [
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'fullname',
          label: 'Fullname',
        },
        {
          key: 'slug',
          label: 'Slug',
        },
        {
          key: 'phone_number',
          label: 'Phone number',
        },
        {
          key: 'age',
          label: 'Age',
        },
        {
          key: 'wallet',
          label: 'Wallet',
        },
        {
          key: 'action',
          label: 'Action'
        }
      ],
      loading: false,
      currentPage: 1,
      // perPage: 20,
      pageOptions: [10, 20, 40, 60, {value: 100, text: "Show a lot"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      localActive: false,
      items: [],
      fields: [

        {
          key: 'doctor_name',
          label: 'Doctor name',
          sortable: true
        },
        {
          key: 'patient_name',
          label: 'Patient name',
          sortable: true
        },
        {
          key: 'prescription_fee',
          label: 'Prescription fee',
          sortable: true
        },
        {
          key: 'prescription_status',
          label: 'Prescription status',
          sortable: true
        },
        {
          key: 'service_type',
          label: 'Service type',
          sortable: true
        }
      ],
      filter: null,
      filterOn: [],
      filters: {
        patients: '',
        doctors: '',
        phone_number: '',
        is_paid: '',
        prescription_identifier: '',
        created_date: '',
      },
      startDate: null,
      endDate: null,
      show: true,
      base_url: process.env.VUE_APP_API_URL,
      users: [],
      api_token: '',
      agent_pharmacy_history: '',
      imgError: false,
    }
  },
  metaInfo: {
    title: 'Orko Health Ltd | Transaction historyList list',
    htmlAttrs: {
      lang: 'en-US'
    }
  },
  mounted() {
    this.getAgentData();
    // this.getTransactionHistroy();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Doctor wise Transaction history list", route: "/doctor-wise-doctor-list"},
    ]);

  },
  computed: {
    rows() {
      return this.users.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {

      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const filtered = this.items.filter(item => {
        const itemDate = new Date(item.created_at)
        if (startDate && endDate) {
          return startDate <= itemDate && itemDate <= endDate;
        }
        if (startDate && !endDate) {
          return startDate <= itemDate;
        }
        if (!startDate && endDate) {
          return itemDate <= endDate;
        }
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })


      return filtered.length > 0 ? filtered : [{
        patients: '',
        doctors: '',
        phone_number: '',
        is_paid: '',
        prescription_identifier: '',
        created_date: '',
      }]
    },
    filteredData() {

      let filterType = this.selectedType;
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
      return itemsByType
          .filter(item => {
            const itemDate = new Date(item.created_at)
            if (startDate && endDate) {
              return startDate <= itemDate && itemDate <= endDate;
            }
            if (startDate && !endDate) {
              return startDate <= itemDate;
            }
            if (!startDate && endDate) {
              return itemDate <= endDate;
            }
            return true;
          })
    }
  },
  methods: {
    getAgentData() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.api_token = response.data.data.api_token;
            this.getTransactionHistroy();
          })
    },

    getTransactionHistroy() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/wallet/transaction/details?api_token=${this.api_token}${this.startDate ? '&start_date=' + this.startDate : ''}${this.endDate ? '&end_date=' + this.endDate : ''}`)
          .then(response => {
            this.items = response.data.data.transactions
            this.agent_pharmacy_history = response.data.data

          })
    },
    underScoreToSpace,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
    },

    onImgError() {
      this.imgError = true;
    },

    clearDate() {
      this.startDate = null;
      this.endDate = null;
    }

  }
}
</script>
<style>
.hide {
  display: none;
}

.text-green {
  color: green;
}

.is_online {
  position: relative;
}

.is_online_icon {
  position: absolute;
  left: 0;
  bottom: 4px;
  margin-left: 14px;
  font-size: 12px;
}

.user_image {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  text-align: center;
}

.font-size-10 {
  font-size: 10px;
}

.report_label {
  text-transform: capitalize;
}

.user_image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  transition: .5s;
  object-position: center;
  object-fit: inherit;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>
