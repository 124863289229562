<template>
  <div>
    <div class="card" v-if="loading">
      <div class="card-body">
        <h3>Notification Template</h3>
        <b-button v-b-modal.modal variant="primary">Add new Notification template</b-button>
        <div>
          <b-modal
              id="modal"
              title="Create a Notification Template"
              @show="resetModal"
              @hidden="resetModal"
              size="lg"
              @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">

<!--              <b-form-group-->
<!--                  label="Notification type*"-->
<!--                  label-for="name-input"-->
<!--                  invalid-feedback="Notification type*"-->
<!--                  :state="notificationTypeState">-->
                <v-select
                    :options="notification_types"
                    :reduce="notification_types => notification_types.id"
                    v-model="form.notification_type_id"
                    label="notification_type_term"
                    class="h-100"
                    :state="notificationTypeState"
                    name="notification_type_term">
                </v-select>

<!--              </b-form-group>-->

              <b-form-group
                  label="Notification template Name*"
                  label-for="name-input"
                 >
                <b-form-input
                    id="name-input"
                    v-model="form.notification_template"
                   />
              </b-form-group>

              <b-form-group
                  label="Action point"
                  label-for="name-input">
                <b-form-textarea
                    id="name-input"
                    v-model="form.action_point"

                    rows="3"
                />
              </b-form-group>

              <b-form-group
                  label="Orko chat bot template"
                  label-for="name-input">
                <b-form-input
                    id="name-input"
                    v-model="form.orko_chat_bot_template"
                    />
              </b-form-group>

              <b-form-group
                  label="Orko chat bot action point"
                  label-for="name-input">
                <b-form-textarea
                    id="name-input"
                    v-model="form.orko_chat_bot_action_point"
                    rows="3"

                />
              </b-form-group>

              <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  name="checkbox-1"
                  value="1"
                  unchecked-value="0"
              >
                Status
              </b-form-checkbox>

              <b-form-checkbox
                  id="checkbox-2"
                  v-model="form.is_system_initiated"
                  name="checkbox-2"
                  value="1"
                  unchecked-value="0"
              >
                Is CRM
              </b-form-checkbox>


            </form>
          </b-modal>
          <br>
          <div class="row align-items-center">
            <div class="col-md-2">
              <div class="">
                <b-form-group
                    class="mb-0">
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="col-md-2">


              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Orko Notification type list"
                  name="Orko Notification type list">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>

            </div>
            <div class="col-md-4">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
            <b-col lg="4">
              <b-form-group
                  class="mb-0"
                  v-slot="{ ariaDescribedby }">
                <b-input-group size="sm">
                  <b-form-select
                      id="sort-by-select"
                      v-model="sortBy"
                      :options="sortOptions"
                      :aria-describedby="ariaDescribedby"
                      class="w-75">
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>

                  <b-form-select
                      v-model="sortDesc"
                      :disabled="!sortBy"
                      :aria-describedby="ariaDescribedby"
                      size="sm"
                      variant="primary"
                      class="w-25"

                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
          </div>

          <b-table
              striped hover
              :items="filtered"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              thead-class="bg-primary text-white"
              show-empty
              class="table-responsive mt-10">

            <template slot="top-row" slot-scope="{ fields }">
              <td v-for="field in fields" :key="field.key">
                <p v-if="field.key == 'actions' || field.key == 'status' || field.key=='is_paid' "></p>
                <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
              </td>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(notification_type)="data">
              <div v-for="(ntt, index) in data.item.notification_type" :key="index">
                {{ ntt.notification_type_term }}
              </div>
            </template>

            <template #cell(action_point)="data">
              <div v-if="data.item.action_point">

                {{ data.item.action_point ? data.item.action_point : '' }}
<!--                {{ actionPoint(data.item.action_point)}}-->
<!--                <li v-for="(faciliti, index) in data.item.action_point.split(',')" :key="index" class="mb-0 ml-5">-->
<!--                  {{ faciliti.replace(/[^a-zA-Z]/g, " ") }}-->
<!--                </li>-->
<!--                {{ action_point.button }}-->
              </div>
              <div v-else>-</div>
            </template>

            <template #cell(status)="data">
              <div v-if="data.item.status === '1'">
                <b-badge variant="success">Active</b-badge>
              </div>
              <div v-else>
                <b-badge variant="danger">Inactive</b-badge>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-button variant="primary" @click="updateData(row)"><i class="far fa-edit"></i></b-button>
            </template>

          </b-table>
          <div class="description found"> Total : {{ items.length }}</div>

          <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              size="lg"
              class="my-0"
          ></b-pagination>
          <!--    updat modal start -->
<!--          <b-modal-->
<!--              id="updateModal"-->
<!--              size="lg"-->
<!--              title="Update notification"-->
<!--              @show="resetModal"-->
<!--              @hidden="resetModal"-->
<!--              @ok="editData">-->
<!--            <form ref="form" @submit.stop.prevent="updateNotification()">-->

<!--              <b-form-group-->
<!--                  label="Notification type*"-->
<!--                  label-for="name-input"-->
<!--                  invalid-feedback="Notification type*"-->
<!--                  :state="notificationTypeState">-->
<!--                <v-select-->
<!--                    :options="notification_types"-->
<!--                    :reduce="notification_types => notification_types.id"-->
<!--                    v-model="form.notification_type_id"-->
<!--                    label="notification_type_term"-->
<!--                    class="h-100"-->
<!--                    :state="notificationTypeState"-->
<!--                    name="notification_type_term">-->
<!--                </v-select>-->

<!--              </b-form-group>-->

<!--              <b-form-group-->
<!--                  label="Notification template Name*"-->
<!--                  label-for="name-input"-->
<!--                  invalid-feedback="Notification template name is required"-->
<!--                  :state="notification_template_nameState">-->
<!--                <b-form-input-->
<!--                    id="name-input"-->
<!--                    v-model="form.notification_template"-->
<!--                    :state="notification_template_nameState"-->
<!--                    required/>-->
<!--              </b-form-group>-->

<!--              <b-form-group-->
<!--                  label="Action point*"-->
<!--                  label-for="name-input"-->
<!--                  invalid-feedback="Action point is required"-->
<!--                  :state="actionPointState">-->
<!--                <b-form-textarea-->
<!--                    id="name-input"-->
<!--                    v-model="form.action_point"-->
<!--                    :state="actionPointState"-->
<!--                    rows="3"-->

<!--                    required-->
<!--                />-->
<!--              </b-form-group>-->

<!--              <b-form-group-->
<!--                  label="Orko chat bot template"-->
<!--                  label-for="name-input">-->
<!--                <b-form-input-->
<!--                    id="name-input"-->
<!--                    v-model="form.orko_chat_bot_template"-->
<!--                    required/>-->
<!--              </b-form-group>-->

<!--              <b-form-group-->
<!--                  label="Orko chat bot action point"-->
<!--                  label-for="name-input">-->
<!--                <b-form-textarea-->
<!--                    id="name-input"-->
<!--                    v-model="form.orko_chat_bot_action_point"-->
<!--                    rows="3"-->
<!--                    required-->
<!--                />-->
<!--              </b-form-group>-->

<!--              <b-form-checkbox-->
<!--                  id="checkbox-1"-->
<!--                  v-model="form.status"-->
<!--                  name="checkbox-1"-->
<!--                  value="1"-->
<!--                  unchecked-value="0"-->
<!--              >-->
<!--                Status-->
<!--              </b-form-checkbox>-->

<!--              <b-form-checkbox-->
<!--                  id="checkbox-2"-->
<!--                  v-model="form.is_system_initiated"-->
<!--                  name="checkbox-2"-->
<!--                  value="1"-->
<!--                  unchecked-value="0"-->
<!--              >-->
<!--                Is CRM-->
<!--              </b-form-checkbox>-->


<!--            </form>-->
<!--          </b-modal>-->
          <!--    end -->
        </div>
      </div>
    </div>

    <div v-else>
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {NotificationTemplateMixin} from '../../mixins/NotificationTemplateMixin'

export default {
  name: "NotificationTemplate",
  mixins: [NotificationTemplateMixin],
  title: 'Notification template',
}
</script>

<style scoped>

</style>
