<template>
  <nav>
    <ol class="breadcrumb">
      <li
          v-for="(crumb, ci) in crumbs"
          :key="ci"
          class="breadcrumb-item align-items-center">
        <button class="btn btn-link" :class="{ disabled: isLast(ci) }" @click="selected(crumb)">
          {{ crumb }}
        </button>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isLast(index) {

      return index === this.crumbs.length - 1;
    },
    selected(crumb) {
      this.$emit('selected', crumb);
    },
  },
}
</script>

<style scoped>

</style>