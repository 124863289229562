<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3 d-flex justify-content-end">
              <div class="mx-2">
                <button
                    type="button"
                    @click="exportData"
                    class="btn btn-primary">
                  <i class="fas fa-file-excel"></i> Export Excel
                </button>
              </div>
              <div>
                <b-button @click="dateRangeClear" variant="danger">Clear</b-button>
              </div>
            </div>
            <div>
              <div class="row justify-content-between">
                <div class="col-md-1 mt-9">
                  <div class="">
                    <b-form-group
                        class="mb-0">
                      <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="">From</label>
                      <input type="date" v-model="start_date" class="form-control">
                    </div>
                    <div class="col-md-6">
                      <label for="">To</label>
                      <input type="date" v-model="end_date" class="form-control">
                    </div>
                  </div>
                </div>


                <div class="col-md-3">
                  <label for="">Select TSO</label>
                  <b-form-group>
                    <v-select
                        :options="sales_users"
                        :reduce="sales_users => sales_users.id"
                        v-model="seller_id"
                        label="name">
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-1">
                  <label for="">Is selected</label>
                  <b-form-group>
                    <select name="" class="form-control" id="" v-model="is_selected">
                      <option value="1">Yes</option>
                      <option value="0">No</option>
                    </select>
                  </b-form-group>
                </div>
                <div class="col-md-2 mt-8">
                  <b-form-group
                      label-for="filter-input"
                      label-cols-sm="1"
                      label-align-sm="right"
                      label-size="lg"
                      class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input
                          id="filter-input"
                          v-model="search"
                          type="search"
                          placeholder="Type to Search"/>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-md-1 mt-8">
                  <b-form-checkbox
                      id="unique-visits-checkbox"
                      v-model="unique_visits"
                      name="unique-visits-checkbox"
                      :value="true"
                      :unchecked-value="false"
                      size="lg"
                      @change="getUser"
                  >
                    Unique Visits
                  </b-form-checkbox>
                </div>
                <div class="col-md-1 mt-8">
                  <button type="button" class="btn btn-info" @click="getUser">Filter</button>
                </div>
              </div>
              <!-- Table -->
              <div class="row">
                <div class="col-12">
                  <b-table
                      striped hover responsive
                      :items="items"
                      :fields="computedFields"
                      :current-page="currentPage"
                      thead-class="bg-primary text-white"
                      class="mt-5"
                      show-empty>


                    <template #cell(seller_id)="row">
                      <user-card :image="row.item.seller_image" :name="row.item.seller_name"
                                 :phone_number="row.item.seller_phone_number" :user_id="row.item.seller_id"/>
                    </template>

                    <template #cell(is_selected)="row">
                      <b-badge variant="success" v-if="row.item.is_selected == 1">YES</b-badge>
                      <b-badge variant="danger" v-else>NO</b-badge>
                    </template>

                    <template #cell(is_senior_doctor)="row">
                      <b-badge variant="success" v-if="row.item.is_senior_doctor == 1">Senior</b-badge>
                      <b-badge variant="info" v-else>Junior</b-badge>
                    </template>

                    <template #cell(registered_at)="row">
                      <span v-if="row.item.registered_at">{{ createdAtFormat(row.item.registered_at) }}</span>
                      <span v-else>Not register yet</span>
                    </template>

                    <template #cell(full_name)="row">
                      <user-card :name="row.item.full_name"
                                 :phone_number="row.item.phone_no">
                        <p style="margin-bottom: 0" v-if="row.item.speciality">{{ row.item.speciality }}</p>
                        <p v-if="row.item.bmdc_reg_no">BMDC Reg: {{ row.item.bmdc_reg_no }}</p>
                      </user-card>
                    </template>

                    <template #cell(hospital_id)="row">
                      <div>
                        <p class="mb-0"><b>Hospital name: </b>{{ row.item.hospital_name }}</p>
                        <p class="mb-0"><b>Chamber address: </b>{{ row.item.chamber_address }}</p>
                        <p class="mb-0"><b>Daily patient: </b>
                          <b-badge variant="info" style="font-size: 16px;font-weight: bold;">{{
                              row.item.daily_patient
                            }}
                          </b-badge>
                        </p>
                      </div>
                    </template>


                    <template #cell(is_confirmed)="row">
                      <div class="is_online text-center" v-if="row.item.registered_at">
                        <p v-if="row.item.is_confirmed==1" class="text-success font-weight-bold"> Verified</p>
                        <p v-else-if="row.item.is_confirmed == 0" class="text-warning font-weight-bold">Pending</p>
                        <p v-else class="text-danger font-weight-bold">Unverified</p>
                      </div>
                      <div v-else>
                        not registered
                      </div>
                    </template>

                    <template #cell(created_at)="row">
                      {{ createdAtFormat(row.item.created_at) }}
                    </template>

                    <template #cell(deleted_at)="row">

                      <b-badge variant="danger" v-if="row.item.deleted_at">{{ createdAtFormat(row.item.deleted_at) }}</b-badge>
                      <p class="mb-0" v-else>-</p>

                    </template>

                    <template #cell(actions)="row">
                      <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                                  class="m-2">
                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>

                        <b-dropdown-item href="#" @click="editActivity(row.item)"><span class="pr-4"><i
                            class="fas fa-edit"></i></span> Edit Activity
                        </b-dropdown-item>
                        <b-dropdown-item href="#" v-if="!row.item.deleted_at"  @click="deleteAction(row.item)"><span class="pr-4"><i
                            class="fas fa-trash"></i></span> Delete
                        </b-dropdown-item>
<!--                        <b-dropdown-item href="#" @click="userDetails(row.item)"><span class="pr-4"><i-->
<!--                            class="fas fa-eye"></i></span> View details-->
<!--                        </b-dropdown-item>-->
<!--                        <b-dropdown-item @click="changeDoctorType(row.item)">-->
<!--                          <i class="fas fa-users mr-3"></i>Change Doctor(Sr./Jr.)-->
<!--                        </b-dropdown-item>-->
                      </b-dropdown>
                    </template>

                  </b-table>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="total_activity"
                      :per-page="perPage"
                      size="lg"
                      @change="handlePageChange"
                      class="my-0"
                      aria-controls="doctorList"
                  ></b-pagination>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="sales-activities-details" size="xl" @hidden="resetModal">
      <template #modal-title>
        {{ activities_details.seller_name }} View details
      </template>
    </b-modal>
    <!--  Edit activity  -->
    <b-modal ref="modal" size="xl" id="edit-activity" title="Edit activity" @show="resetModal"
             @hidden="resetModal" @ok="handleOk" scrollable>
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <h4>Doctor Details</h4>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-3">
            <b-form-group
                label="Full Name *"
                label-for="name-input"
                invalid-feedback="Name is required"
                :state="fullNameState"
            >
              <b-form-input
                  id="name-input"
                  v-model="form.full_name"
                  :state="fullNameState"
                  required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="Phone Number *"
                label-for="phone-input">
              <b-form-input id="phone-input" v-model="form.phone_no" @input="acceptNumber"
                              ></b-form-input>
              <span class="text-danger">{{ phone_no_validation_msg }}</span>
            </b-form-group>

          </div>
          <div class="col-md-3">
            <label for="designation">Select Designation</label>
            <v-select
                :options="designations"
                :reduce="designations => designations.id"
                v-model="form.designation_id"
                label="name"
                id="designation">
            </v-select>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="BMDC Registration No."
                label-for="bmdc-input">
              <b-form-input
                  id="bmdc-input"
                  v-model="form.bmdc_reg_no"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <label for="speciality">Select Speciality</label>
            <v-select
                :options="specilities"
                :reduce="specilities => specilities.name"
                v-model="form.speciality"
                label="name"
                id="speciality">
            </v-select>
          </div>
          <div class="col-md-3">
            <label for="prescriptionmode">Prescription Mode</label>
            <b-form-select id="prescriptionmode" v-model="form.prescription_mode" :options="prescription_modes"></b-form-select>
          </div>
          <div class="col-md-3">
            <b-form-checkbox
                id="checkbox-has_other_software"
                v-model="form.has_other_software"
                name="checkbox-has_other_software"
                value="1"
                unchecked-value="0"
            >
              Has Other Software
            </b-form-checkbox>

          </div>
          <div class="col-md-3" v-if="form.has_other_software==1">
            <b-form-group
                label="Vendor Name"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  v-model="form.vendor_name"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="Mobile Device"
                label-for="name-input">
              <b-form-select v-model="form.mobile_device" :options="mobile_devices"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="Preference"
                label-for="name-input">
              <b-form-select v-model="form.preference" :options="preferences"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="Connectivity"
                label-for="name-input">
              <b-form-select v-model="form.connectivity" :options="connectivities"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <p> Available Devices </p>
            <div class="d-flex">
              <b-form-checkbox
                  class="mx-2"
                  id="checkbox-has_pc"
                  v-model="form.has_pc"
                  name="checkbox-has_pc"
                  value="1"
                  unchecked-value="0"
              >
                Has pc
              </b-form-checkbox>
              <b-form-checkbox
                  class="mx-2"
                  id="checkbox-has_printer"
                  v-model="form.has_printer"
                  name="checkbox-has_printer"
                  value="1"
                  unchecked-value="0"
              >
                Has printer
              </b-form-checkbox>
              <b-form-checkbox
                  class="mx-2"
                  id="checkbox-has_laptop"
                  v-model="form.has_laptop"
                  name="checkbox-has_laptop"
                  value="1"
                  unchecked-value="0"
              >
                Has laptop
              </b-form-checkbox>
            </div>
          </div>

        </div>
        <hr>
        <!--    Chamber details     -->
        <div class="row">
          <div class="col-md-12">
            <h4>Chamber Details</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label for="">Select Hospital</label>
            <v-select
                :options="institutes"
                :reduce="institutes => institutes.id"
                v-model="form.hospital_id"
                label="name">
            </v-select>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="Chamber Address"
                label-for="name-input">
              <b-form-textarea
                  id="textarea"
                  v-model="form.chamber_address"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="Daily Patient"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  v-model="form.daily_patient"></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-checkbox
                id="checkbox-visit_other_district"
                v-model="form.visit_other_district"
                name="checkbox-visit_other_district"
                value="1"
                unchecked-value="0"
            >
              Visit other district
            </b-form-checkbox>
          </div>
          <div class="col-md-3" v-if="form.visit_other_district == 1">

            <b-form-group
                label="How frequent"
                label-for="name-input">
              <b-form-select v-model="form.how_frequent" :options="how_frequents"></b-form-select>
            </b-form-group>

          </div>
          <div class="col-md-3" v-if="form.visit_other_district == 1">
            <b-form-group
                label="Served remote patient"
                label-for="name-input"
            >
              <b-form-input
                  id="name-input"
                  type="number"
                  v-model="form.served_remote_patient"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="No. of Follow Up Patient"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  type="number"
                  v-model="form.followup_patient"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="No. of New Patient"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  type="number"
                  v-model="form.new_patient">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="No. of Patient for Report"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  type="number"
                  v-model="form.patient_for_report"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
                label="No of Patient form other district"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  type="number"
                  v-model="form.other_area">
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="No of Patient form current district"
                label-for="name-input">
              <b-form-input
                  type="number"
                  id="name-input"
                  v-model="form.local_area"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
                label="Avg waiting time for prescription(min)"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  type="number"
                  v-model="form.avg_time_prescription"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
                label="Avg time spent for patient(min)"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  type="number"
                  v-model="form.avg_time_patient"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
                label="Logistics (Travel + Other Cost) BDT"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  type="number"
                  v-model="form.transportation_cost"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
                label="Comment"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  v-model="form.review"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group label="Stump image:">
              <b-form-file ref="stump_image" id="file-stump"></b-form-file>
              <img :src="userPhoto(form.stump_image)" alt="" v-if="form.stump_image" class="img-fluid img-thumbnail squre-img mt-2">
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Visiting card">
              <b-form-file ref="visiting_card" id="file-visiting"></b-form-file>
              <img :src="userPhoto(form.visiting_card)" alt="" v-if="form.visiting_card" class="img-fluid img-thumbnail squre-img mt-2">
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Chamber image:">
              <b-form-file ref="chamber_image" id="file-chamber"></b-form-file>
              <img :src="userPhoto(form.chamber_image)" alt="" v-if="form.chamber_image" class="img-fluid img-thumbnail squre-img mt-2">
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group label="Doctor image:">
              <b-form-file ref="doctor_image" id="file-doctor"></b-form-file>
              <img :src="userPhoto(form.doctor_image)" alt="" v-if="form.doctor_image" class="img-fluid img-thumbnail squre-img mt-2">
            </b-form-group>
          </div>
        </div>

        <hr>
        <div class="row">
          <div class="col-md-12">
            <h4>Location Details</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <b-form-group
                label="Latitude"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  v-model="form.lat"
              ></b-form-input>
            </b-form-group>

          </div>
          <div class="col-md-3">
            <b-form-group
                label="Longitude"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  v-model="form.lon"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-3">
            <b-form-group
                label="Log Address"
                label-for="name-input">
              <b-form-input
                  id="name-input"
                  v-model="form.log_address">
              </b-form-input>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <vue-snotify></vue-snotify>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {SalesActivityMixin} from "../../mixins/SalesActivityMixin";
import {DateFormat} from "../../mixins/core/DateFormat";
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";

export default {
  name: "SalesActivityList",
  title: 'Sales Activity Log',
  mixins: [SalesActivityMixin, DateFormat, SalesUserMixins],
}
</script>
<style scoped>
.squre-img {
  height: 50px;
}
</style>
