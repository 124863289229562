import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const ConnectionRequestMixin = {

    data() {
        return {
            count: '',
            columns: [

            ],
            json_fields: {
                "PATIENT": {
                    field: 'sender_name'
                },
                "DOCTOR": {
                    field: 'receiver_name',
                },
                "DURATION": {
                    field: 'duration',
                },
            },

            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            currentPage: 1,
            perPage: 30,
            pageOptions: [30, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                index: '',
                sender_name: '',
                receiver_name: '',
                duration: '',
            },
            items: [],
            loading: true,

            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'sender_name',
                    label: 'PATIENT',
                    sortable: true
                },
                {
                    key: 'receiver_name',
                    label: 'DOCTOR',
                    sortable: true
                },
                {
                    key: 'duration',
                    label: 'DURATION',
                    sortable: true
                }
            ],
            filter: null,
            filterOn: [],
            startDate: '',
            endDate: '',
            selectedType: '',
        }
    },

    created() {
        localStorage.setItem('tabIndex', 4)
        this.$root.$emit('call-active-menu', 4);
        this.getFinantialReport();
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Connection request list", route: "/connection-request"},
        ]);
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.transaction_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>

                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                index: '',
                sender_name: '',
                receiver_name: '',
                duration: '',
            }]
        },
        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },

    },
    methods: {
        durationMenupulate(time) {
            let _duration = time.split(":");
            let humanize_duration = _duration[0] +" days,"+ _duration[1] +" hours,"+ _duration[2] +" mins,"+ _duration[3] +' seconds';
            return humanize_duration;

        },
        getFinantialReport() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/report/connection/pending/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.loading = false
                    this.items=response.data.data.list;
                    this.count=response.data.data.count;

                })
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        }
    },

}