import { render, staticRenderFns } from "./ParentInfo.vue?vue&type=template&id=af0f8a74&scoped=true"
import script from "./ParentInfo.vue?vue&type=script&lang=js"
export * from "./ParentInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af0f8a74",
  null
  
)

export default component.exports