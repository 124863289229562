<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">

          <div class="total-point">
            <p class="mb-0 text-muted">Total point</p>
            <div class="display-1 text-primary">
              {{ total_point }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="lists"
                :fields="point_fields"
                thead-class="bg-primary text-white"
                show-empty>
              <template #cell(created_at)="row">
                {{ row.item.created_at | moment("ddd, MMM Do YYYY, h:mm:ss a") }}
              </template>
            </b-table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  name: "Points",
  props: {
    user: {},
  },
  data() {
    return {
      total_point: '',
      lists: [],
      point_fields: [
        {
          label: 'Point type',
          key: 'point_type_description',
        },
        {
          label: 'No of occurrence',
          key: 'no_of_occurrence',
        },
        {
          label: 'Point earned',
          key: 'point_earned',
        },

      ],
    }
  },
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   {title: "Points", route: "/points"},
    // ]);
    this.getPoints();
  },
  // metaInfo: {
  //   title: 'Orko Health Ltd | Doctor points',
  //   htmlAttrs: {
  //     lang: 'en-US'
  //   }
  // },
  methods: {
    getPoints() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/user/point/list?api_token=${localStorage.getItem('api_token')}&doctor_id=${this.user.id}`)
          .then(res => {
            this.total_point = res.data.data.total_point;
            this.lists = res.data.data.list;
          })
    },
    imageUrl(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
  }
}
</script>

<style scoped>
.user_image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  text-align: center;
  border: 1px solid #1b87c9;
}
</style>