<template>
  <div>
    <div class="card">
      <loader-component v-if="loading"/>
      <div class="card-body">
        <div class="row mt-5 align-items-center justify-content-between">
          <div class="col-md-3">
            <label for="">Select TSM</label>
            <b-form-select
                id="doctor_type"
                v-model="tsm_id"
                :options="sales_users"
                text-field="name"
                value-field="id">
              <template #first>
                <b-form-select-option value="" disabled>-- Select TSM --</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col-md-3">
            <label for="">Select Year</label>
            <b-form-select
                id="doctor_type"
                v-model="year"
                :options="years">
            </b-form-select>
          </div>
          <div class="col-md-3">

            <label for="">Select Month</label>
            <b-form-select id="doctor_type" v-model="month" :options="months"></b-form-select>
          </div>
          <div class="col-md-2 mt-7">
            <button class="btn btn-success" @click="getItems">Filter</button>
            <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="items"
                :fields="fields"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">
            </b-table>
          </div>
        </div>
      </div>
      <vue-snotify></vue-snotify>
    </div>
    <div class="row mt-5">
      <div class="col-md-4">
        <div class="card border-0">
          <div class="card-body">
            <div class="text-center box-height">
              <h4>Achieved Doctor Percentage</h4>
              <h3>{{ achieved_doctor_percentage }}%</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card border-0">
          <div class="card-body">
            <div class="text-center box-height">
              <h4>Achieved prescription percentage</h4>
              <h3>{{ achieved_prescription_percentage }}%</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card border-0">
          <div class="card-body">
            <div class="text-center box-height">
              <h4>Total Income</h4>
              <h3>{{ total_income }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h5 class="mt-6"><strong>Key Performance Indicator (KPI):</strong></h5>

    <div class="row mt-5">
      <div class="col-md-12">

        <div class="card border-0" v-for="(item, key) in items" :key="key" style="margin-bottom: 20px;">
          <div class="card-body">

              <h6 class="text-center mb-3"><strong>TSM: {{ item.seller_name }}</strong></h6>
              <div class="row mb-1">
                <div class="col-sm-3"><strong>Doctor Acquisition: ({{ calculateKPI(item.target_doctor, item.achieved_doctor, 100) }}%)</strong></div>
                <div class="col-sm-9 pt-1">
                  <b-progress :max="100" variant="success">
                    <b-progress-bar :value="calculateKPI(item.target_doctor, item.achieved_doctor, 100)" :label="`${calculateKPI(item.target_doctor, item.achieved_doctor, 100)}%`"></b-progress-bar>
                  </b-progress>
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-sm-3"><strong>Prescription Acquisition: ({{ calculateKPI(item.target_prescription, item.achieved_prescription, 100) }}%)</strong></div>
                <div class="col-sm-9 pt-1">
                  <b-progress :max="100" variant="info">
                    <b-progress-bar :value="calculateKPI(item.target_prescription, item.achieved_prescription, 100)" :label="`${calculateKPI(item.target_prescription, item.achieved_prescription, 100)}%`"></b-progress-bar>
                  </b-progress>
                </div>
              </div>

              <div class="row mb-1">
                <div class="col-sm-3">
                  <strong>Overall: ({{ calculateKPI(item.target_doctor, item.achieved_doctor, 30) + calculateKPI(item.target_prescription, item.achieved_prescription, 70) }}%)</strong> <br>
                  <small>(Weight: Doctor 30, Prescription 70)</small>
                </div>
                <div class="col-sm-9 pt-1">
                  <b-progress :max="100" variant="primary">
                    <b-progress-bar :value="calculateKPI(item.target_doctor, item.achieved_doctor, 30) + calculateKPI(item.target_prescription, item.achieved_prescription, 70)" :label="`${calculateKPI(item.target_prescription, item.achieved_prescription, 70) + calculateKPI(item.target_doctor, item.achieved_doctor, 30)}%`"></b-progress-bar>
                  </b-progress>
                </div>
              </div>


              <!-- <h4>Prescription Acquisition (70%)</h4>
              <b-progress :max="100" height="16px">
                <b-progress-bar :value="calculateKPI(item.target_prescription, item.achieved_prescription, 100)" :label="`${calculateKPI(item.target_prescription, item.achieved_prescription, 100)}%`"></b-progress-bar>
              </b-progress>

              <h4>Overall Progress</h4>
              <b-progress :max="100" height="16px">
                <b-progress-bar :value="calculateKPI(item.target_doctor, item.achieved_doctor, 30) + calculateKPI(item.target_prescription, item.achieved_prescription, 70)" :label="`${calculateKPI(item.target_prescription, item.achieved_prescription, 70) + calculateKPI(item.target_doctor, item.achieved_doctor, 30)}%`"></b-progress-bar>
              </b-progress> -->


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "SalesCallTargetReport",
  mixins: [SalesUserMixins],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales Target Report", route: "/sales/call/target"},
    ]);

  },
  data() {
    let all_months = [
      {
        text: 'January',
        value: 'January'
      },
      {
        text: 'February',
        value: 'February'
      },
      {
        text: 'March',
        value: 'March'
      },
      {
        text: 'April',
        value: 'April'
      },
      {
        text: 'May',
        value: 'May'
      },
      {
        text: 'June',
        value: 'June'
      },
      {
        text: 'July',
        value: 'July'
      },
      {
        text: 'August',
        value: 'August'
      },
      {
        text: 'September',
        value: 'September'
      },
      {
        text: 'October',
        value: 'October'
      },
      {
        text: 'November',
        value: 'November'
      },
      {
        text: 'December',
        value: 'December'
      },
    ];
    return {
      items: [],
      fields: [
        {
          key: 'seller_name',
          label: 'TSM name',
          sortable: true,
        },
        {
          key: 'target_doctor',
          label: 'Target doctor',
          sortable: true,
        },
        {
          key: 'achieved_doctor',
          label: 'Achieved doctor',
          sortable: true,
        },
        {
          key: 'target_prescription',
          label: 'Target prescription',
          sortable: true,
        },
        {
          key: 'achieved_prescription',
          label: 'Achieved prescription',
          sortable: true,
        },

      ],
      loading: false,
      form: {
        user_id: '',
        user_name: '',
        doctor_type: '',
        month: '',
        year: '',
        target: '',
      },
      doctor_types: [
        {
          text: 'Celebrity',
          value: 'Celebrity'
        },
        {
          text: 'Senior',
          value: 'Senior'
        },
        {
          text: 'Mid',
          value: 'Mid'
        },
        {
          text: 'Junior',
          value: 'Junior'
        },
      ],
      months: all_months,
      nameState: null,
      tsm_id: '',
      month: all_months[new Date().getMonth()].value,
      year: new Date().getFullYear(),
      doctor_type: '',
      current_year: new Date,
      start_year: 2022,
      years: [],
      total_income: '',
      achieved_doctor_percentage: '',
      achieved_prescription_percentage: '',
    }
  },
  created() {
    localStorage.setItem('tabIndex', 14)
    this.$root.$emit('call-active-menu', 14);
    this.getItems();
    this.setYears(15);
    // this.month = this.currentMonth.value;
  },
  computed: {
    currentMonth() {
      let current_month = new Date().getMonth();
      return this.months[current_month.value]
    }
  },
  methods: {
    calculateKPI(target, achievement, weight) {
      if(achievement) {
      let calc = ((achievement * weight)/target).toFixed(2);
      return parseFloat(calc);
      }
      return 0;
    },
    setQueryParams() {
      let params = {};
      if (this.tsm_id) {
        params.user_id = this.tsm_id;
      }
      if (this.month) {
        params.month = this.month;
      }
      if (this.year) {
        params.year = this.year;
      }
      if (this.doctor_type) {
        params.doctor_type = this.doctor_type;
      }
      return params;
    },
    getItems() {
      this.loading = true;
      let url = `${process.env.VUE_APP_URL}/api/v2/sales/team/sales/target/report?api_token=${localStorage.getItem('api_token')}${this.tsm_id ? '&user_id=' + this.tsm_id : ''}${this.month ? '&month=' + this.month : ''}${this.year ? '&year=' + this.year : ''}${this.doctor_type ? '&doctor_type=' + this.doctor_type : ''}`;
      this.$router.replace({
        query: this.setQueryParams()
      })
      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_income = response.data.total_income;
              this.achieved_doctor_percentage = response.data.achieved_doctor_percentage;
              this.achieved_prescription_percentage = response.data.achieved_prescription_percentage;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    addNewTarget() {
      this.$bvModal.show('addTarget')
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.form = {};
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      let sales_user_name = this.sales_users.find(item => item.id == this.form.user_id);
      this.form.user_name = sales_user_name.name;
      this.$nextTick(() => {
        axios.post(`${process.env.VUE_APP_API_URL}/api/v2/sales/team/sales/target/set?api_token=${localStorage.getItem('api_token')}`, this.form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addTarget')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
    setYears(num) {
      for (let i = 0; i < num; i++) {
        this.years.push(this.start_year + i);
      }
    },
    clear() {
      this.tsm_id = '';
      this.month = this.months[new Date().getMonth()].value;
      this.year = new Date().getFullYear();
      this.doctor_type = '';
      this.getItems();
    },
  }
}
</script>

<style scoped>
.box-height {
  height: 70px;
}
</style>
