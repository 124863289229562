<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <div class="row">
            <div class="col-md-12">
              <div>
                <b-form-radio-group
                    v-model="notification_type_selected"
                    :options="notification_type"
                    class="mb-3"
                    value-field="item"
                    text-field="name"
                    disabled-field="notEnabled"
                ></b-form-radio-group>

              </div>
            </div>
            <div class="col-md-6">
              <b-form-group
                  label-for="name-input">
                <label for="">Notification type <span class="text-danger"> * </span></label>
                <v-select
                    :options="notification_types"
                    :reduce="notification_types => notification_types.id"
                    v-model="form.notification_type"
                    label="notification_type_term"
                    button-variant="outline-primary"
                    class="h-100"
                    name="notification_type_term">
                </v-select>
                <p class="text-danger"> {{ notification_type_is_required }} </p>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group label-for="name-input">
                <label for="">Notification title <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.notification_title"
                    :state="notification_titleState"
                    required/>
              </b-form-group>
            </div>
            <div class="col-md-6" v-if="notification_type_selected == 'image'">
              <b-form-group label-for="name-input">
                <label for="">Upload image <span class="text-danger">*</span></label>
                <b-form-file id="file-default" v-model="notification_img" ref="notification_img"></b-form-file>

              </b-form-group>
            </div>
          </div>

          <b-form-group
              v-if="notification_type_selected == 'text'"
              label-for="name-input"
              invalid-feedback="Action point is required"
              :state="notificationBodyState">
            <label for="">Notification body <span class="text-danger">*</span></label>
            <b-form-textarea
                id="name-input"
                v-model="form.notification_body"
                :state="notificationBodyState"
                rows="3"
                required
            />
          </b-form-group>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  label-for="name-input">
                <label for="">User type <span class="text-danger">*</span></label>
                <v-select
                    :options="user_types"
                    :reduce="user_types => user_types"
                    v-model="form.user_type"
                    class="h-200"
                    label="user_types"
                    name="user_type"
                >
                </v-select>
                <p class="text-danger">{{ user_type_is_required }}</p>
              </b-form-group>
            </div>
            <div class="col-md-6">
<!--              <div class="d-flex align-items-center mt-10">-->
<!--                <b-spinner label="Loading..."></b-spinner>-->
<!--              </div>-->
              <div >
                <b-form-group class="mt-5 pt-3" v-if="form.user_type">
                  <b-form-radio-group
                      
                      id="btn-radios-1"
                      v-model="form.individual"
                      :options="options"
                      name="radios-btn-primary"
                      button-variant="outline-primary"
                      buttons>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </div>
          </div>


          <div>
            <vue-autosuggest
              v-if="this.form.individual != '1' && this.form.user_type && !this.user_loader"
              :suggestions="[{data:users}]"
              v-model="form.user_id"
              :input-props="{id:'autosuggest__input', placeholder:'Enter phone number'}"
              @input="onInputChange"
              @selected="selectHandler"
              @click="clickHandler"
              :get-suggestion-value="getSuggestionValue"
          >  
            <template slot-scope="{suggestion}">
              <span class="my-suggestion-item">{{suggestion.item.fullname}}</span>
            </template>
          </vue-autosuggest>

          <div v-if="pushedUsers.length" class="position-relative">
            <b-badge pill variant="info" class="m-3" v-for="(pushed_user, index) in pushedUsers" :key="index">{{ pushed_user.fullname }} <span @click="removePushedUser(index)" class="remove_user">X</span></b-badge>
          </div>
        </div> 

          <!-- <b-form-group
              label="Choose user"
              v-if="this.form.individual != '1' && this.form.user_type && !this.user_loader"
              label-for="name-input"
              invalid-feedback="User type*"
              :state="userState">
            <v-select
            
              @change="fuseSearch()"
                :options="users"
                :reduce="users => users.id"
                v-model="form.user_id"
                label="name"
                class="h-100"
                multiple
                :state="userState"
                id="feedback-user"
                :filter="fuseSearch"
                name="id"
                required>
              <template slot="option" slot-scope="user">
                {{ user.id }} - ({{ user.phone_number }}) - ({{ user.name }})
              </template>
            </v-select>
            <p class="text-danger"> {{ single_user_is_required }} </p>
          </b-form-group> -->

          <div v-if="user_loader">
            <b-spinner variant="primary"></b-spinner>
          </div>

          <b-button type="submit" variant="primary" class="mt-10">Send</b-button>

        </form>

      </div>
      <vue-snotify></vue-snotify>
    </div>
    <!--      <b-spinner variant="primary" label="Spinning"></b-spinner>-->
    <loader-component v-if="loading"/>

  </div>
</template>

<script>
import {NotificationSendMixin} from '../../mixins/NotificationSendMixin'
import { VueAutosuggest } from 'vue-autosuggest';

export default {
  name: "NotificationSend",
  mixins: [NotificationSendMixin],
  title: 'Send Notification',
  components: {
      VueAutosuggest
  },
}
</script>

<style>
.vs__search, .vs__search:focus {
  /*margin: 15px 0 0 0 !important;*/
}

.vs__search, .vs__search:focus {
  padding: 6px 7px !important;
}

.vs__dropdown-toggle {
  border: 1px solid #3699ff;
}
#autosuggest__input {
      outline: none;
      position: relative;
      display: block;
      font-family: monospace;
      font-size: 20px;
      border: 1px solid #616161;
      padding: 10px;
      width: 100%;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
    }
    
    #autosuggest__input.autosuggest__input-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    
    .autosuggest__results-container {
      position: relative;
      width: 100%;
    }
    
    .autosuggest__results {
      font-weight: 300;
      margin: 0;
      position: absolute;
      z-index: 10000001;
      width: 100%;
      border: 1px solid #e0e0e0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background: white;
      padding: 0px;
      overflow: scroll;
      max-height: 200px;
    }
    
    .autosuggest__results ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
    
    .autosuggest__results .autosuggest__results_item {
      cursor: pointer;
      padding: 15px;
    }
    
    #autosuggest ul:nth-child(1) > .autosuggest__results_title {
      border-top: none;
    }
    
    .autosuggest__results .autosuggest__results_title {
      color: gray;
      font-size: 11px;
      margin-left: 0;
      padding: 15px 13px 5px;
      border-top: 1px solid lightgray;
    }
    
    .autosuggest__results .autosuggest__results_item:active,
    .autosuggest__results .autosuggest__results_item:hover,
    .autosuggest__results .autosuggest__results_item:focus,
    .autosuggest__results .autosuggest__results_item.autosuggest__results_item-highlighted {
      background-color: #ddd;
    }
</style>
<style scoped>
.remove_user {
  position: absolute;
    background: red;
    padding: 3px 4px;
    border-radius: 50%;
    top: 3px;
}
</style>