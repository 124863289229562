import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from "axios";

export const ProductMixin = {
    data() {
        return {
            editor: ClassicEditor,
            items: [],
            json_fields: {
                "Name": {
                    field: "name"
                },
                "Institute type": {
                    field: "institute_type"
                },
                "Address": {
                    field: "address"
                },
                "Contact number": {
                    field: "contact_number"
                },

            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'image',
                    label: 'Image',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'title_bn',
                    label: 'Title bangla',
                    sortable: true
                },
                {
                    key: 'slug',
                    label: 'Slug',
                    sortable: true
                },
                {
                    key: 'price',
                    label: 'Price',
                    sortable: true
                },

                {
                    key: 'intro_en',
                    label: 'Intro en',
                    sortable: true
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Actions'
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                name: '',
                institute_type: '',
                address: '',
                contact_number: '',
            },
            filter: null,
            filterOn: [],
            title: null,
            details: '',
            form: {
                title: '',
                title_bn: '',
                description: '',
                price: '',
                intro_en: '',
                intro_bn: '',
                description_bn: '',

            },
            loading: true,
            institute_type: [],
            row_id: '',
            is_edit: false,
            productDescriptions: [{
                description: ''
            }],

        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Manage product", route: "/products"},
        ]);


    },
    created() {
        localStorage.setItem('tabIndex', 2)
        this.$root.$emit('call-active-menu', 2);
        this.products();
        this.instituteType();
    },
    metaInfo: {
        title: 'Manage product | Orko Health Ltd',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                institute_type: '',
                address: '',
                contact_number: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        products() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/product/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                        this.loading = false;
                    }
                })
        },

        instituteType() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute-type/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.institute_type = response.data.data
                    }
                })
        },

        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../../assets/img/blank.png";
            }
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.title = valid
            return valid
        },

        resetModal() {
            this.title = null;
            this.details = null;
            this.form = {};
        },

        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.is_edit) {
                return this.update();
            }
            this.handleSubmit()
        },

        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();

            form.append("title", this.form.title);
            if (this.form.title_bn) {
                form.append("title_bn", this.form.title_bn);
            }

            if (this.form.description) {
                form.append("description", this.form.description);
            }
            if (this.form.description_bn) {
                form.append("description_bn", this.form.description_bn);
            }
            if (this.form.price) {
                form.append("price", this.form.price);
            }
            if (this.form.intro_en) {
                form.append("intro_en", this.form.intro_en);
            }
            if (this.form.intro_bn) {
                form.append("intro_bn", this.form.intro_bn);
            }

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/product/store?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.products();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },

        editAction(row) {
            this.$bvModal.show("modal-1");
            this.form = row;
            this.is_edit = true;
            // this.form.parent_id = row;
            this.productDescriptions = row.description
            this.row_id = row.id;

        },

        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/speciality/delete/${row.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getSpeialitiesist();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },

        update() {
            if (!this.checkFormValidity()) {
                return
            }

            let form = new FormData();

            form.append("title", this.form.title);
            if (this.form.title_bn) {
                form.append("title_bn", this.form.title_bn);
            }

            // if (this.form.description) {
            //     form.append("description", this.form.description);
            // }
            if (this.form.description_bn) {
                form.append("description_bn", this.form.description_bn);
            }
            if (this.form.price) {
                form.append("price", this.form.price);
            }
            if (this.form.intro_en) {
                form.append("intro_en", this.form.intro_en);
            }
            if (this.form.intro_bn) {
                form.append("intro_bn", this.form.intro_bn);
            }
            if (this.productDescriptions.length) {
                this.productDescriptions.map((item, index) => {
                    form.append(`description[${index}][description]`, item.description);
                })
            }


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/product/update/${this.row_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.products();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully updated');
                    })
            })

        },

        assignPermissions(row) {
            console.log('row', row)
        },
        addGroup() {
            this.productDescriptions = this.productDescriptions || [];

            this.productDescriptions.push({
                description: '',
            });
        },
        deleteGroup(index) {
            this.productDescriptions.splice(index, 1)
        },

    }
}