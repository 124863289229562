import {PATIENT_WITHDRAWAL_LIST, BACK_LIST, BANK_TYPE } from "../../api/ApiUrl";
import axios from 'axios';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {underScoreToSpace} from "../../healper/nameFormating";

export const PatientWithdrawalListMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Patient name": {
                    field: "patient_fullname",
                },
                "Patient phone number": {
                    field: "patient_phone_number",
                },
                "Total payable amount": {
                    field: "total_payable",
                }
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Patient info',
                    key: 'patient',
                    sortable: true,
                },
                {
                    label: 'Payable amount',
                    key: 'payable_amount',
                    sortable: true,
                },
                {
                    label: 'Payment status',
                    key: 'withdrawal_wallet_info',
                    sortable: true,
                },
                {
                    label: 'Service type',
                    key: 'service_type',
                    sortable: true,
                },
                {
                    label: 'Schedule date',
                    key: 'schedule_date',
                    sortable: true,
                },
                {
                    label: 'Appointment type',
                    key: 'appointment_type',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: true,
            sortDirection: 'desc',
            filters: {
                id: '',
                patient_fullname: '',
                patient_phone_number: '',
                total_payable: '',
            },
            filter: null,
            filterOn: [],
            loading: true,
            imgError: false,
            disburse: {
                transaction_type: '',
                banking_name: '',
                transaction_id: '',
                comment: '',
                amount: '',

            },
            account_info: {
                bank_id: '',
                branch_name: '',
                account_name: '',
                account_number: '',
                bank_account_type_id: '',
            },
            transaction_type: [
                {
                    text: 'Bank',
                    value: 'Bank'
                },
                {
                    text: "Mobile Banking",
                    value: "Mobile Banking",
                }
            ],
            single_withdrawlRequest: {},
            back_list: [],
            back_type: [],
            confirmation_box: '',
            patient_api_token: '',
            visibleRows: [],
            ref_table_id: null,
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })

            return filtered.length > 0 ? filtered : [{
                id: '',
                patient_fullname: '',
                patient_phone_number: '',
                total_payable: '',
            }]


        },
        routeName() {
            return this.$route.name
        },

        fTotalPayable() {
            if (this.visibleRows) {
                return this.visibleRows.reduce((accum, item) => {
                    return Number(accum) + Number(item.total_payable)
                }, 0.0)
            }
        },
        fTotalDisbursed() {
            if (this.visibleRows) {
                return this.visibleRows.reduce((accum, item) => {
                    return Number(accum) + Number(item.disbursed_amount ? item.disbursed_amount[0].total_amount : '0')
                }, 0.0)
            }
        },
        fTotal() {
            return this.visibleRows.reduce((accum, item) => {
                return Number(item.total_payable) - Number(item.disbursed_amount ? item.disbursed_amount[0].total_amount : '0') + Number(accum)
            }, 0.0)
        },
        // bTotal() {
        //     return this.visibleRows.reduce((accum, item) => { return accum + item.b }, 0.0)
        // }

    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Patient due list", route: "/withdrawlrequest"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 8)
        this.$root.$emit('call-active-menu', 8);
        this.getPatientWithDrawalList();
    },
    methods: {
        underScoreToSpace,
        getBankList() {
            axios.get(BACK_LIST)
                .then(res => {
                    this.back_list = res.data.data;
                })
        },

        getBankType() {
            axios.get(BANK_TYPE).then(res => {
                this.back_type = res.data.data;
            })
        },

        getPatientWithDrawalList() {
            axios.get(PATIENT_WITHDRAWAL_LIST)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false;
                        this.items = response.data.data
                    }
                })
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            }
        },

        onImgError() {
            this.imgError = true;
        },

        getUserData(patient_id) {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${patient_id}?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {

                    this.patient_api_token = response.data.data.api_token;
                })
        },

        sendInfo(item) {
            this.getUserData(item.patient_id);
            this.getBankList();
            this.getBankType();
            this.disburse.amount = item.payable_amount;
            this.ref_table_id = item.id;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/bank/accounts/${item.patient_id}?api_token=${localStorage.getItem('api_token')}`)
                .then(res => {
                    if (res.data.data == null) {
                        this.$bvModal.hide('modal-disburse')
                        return this.$bvModal.msgBoxConfirm('This patient has no bank information in our system. Do you want to create new one ?', {
                            title: 'Need to confirm',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            okTitle: 'YES',
                            cancelTitle: 'NO',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        })
                            .then(value => {
                                this.confirmation_box = value;
                                if (this.confirmation_box) {
                                    this.$bvModal.show('open-back-account-form')
                                }
                            })
                            .catch(err => {
                                console.log(err);
                                // An error occurred
                            })
                        // return this.$bvModal.show('open-back-account-form');
                    } else {
                        this.request_status_numeric = status;
                        this.single_withdrawlRequest = item;
                        this.$bvModal.show('modal-disburse')
                    }
                });


        },

        disburseRequest(event) {
            event.preventDefault()
            // Push the name to submitted names
            let withdrawal_status_fd = new FormData();
            withdrawal_status_fd.append("action_type", "disburse");
            withdrawal_status_fd.append("comment", this.disburse.comment);
            withdrawal_status_fd.append("module_type", "withdrawal-request");
            // withdrawal_status_fd.append("withdrawal_request_id", this.single_withdrawlRequest.id);
            withdrawal_status_fd.append("user_id", this.single_withdrawlRequest.patient_id);
            withdrawal_status_fd.append("withdrawal_amount", this.disburse.amount);
            withdrawal_status_fd.append("bank_tran_id", this.disburse.transaction_id);
            withdrawal_status_fd.append("card_type", this.disburse.transaction_type);
            withdrawal_status_fd.append("card_brand", this.disburse.banking_name);
            withdrawal_status_fd.append("status", 5);
            withdrawal_status_fd.append("ref_table_id", this.ref_table_id);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/manage/request?api_token=${localStorage.getItem('api_token')}`, withdrawal_status_fd)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.disburse = {}
                        this.resetModal();
                        this.getPatientWithDrawalList();
                        this.$bvModal.hide('modal-disburse')
                        this.$snotify.success('Withdrawal Request Successfully Disburse');
                    })
            })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.commnetState = valid
            return valid
        },

        resetModal() {
            // this.form.comment = '';
            this.form = {};
            this.disburse = {};
            this.account_info = {};
            this.ref_table_id = null;
        },

        accountCreate(event) {
            event.preventDefault()
            let form = new FormData();
            form.append('bank_account_type_id', this.account_info.bank_account_type_id);
            form.append('account_name', this.account_info.account_name);
            form.append('account_no', this.account_info.account_number);
            if (this.account_info.bank_account_type_id == 1) {

                form.append('branch_name', this.account_info.branch_name);
                form.append('bank_id', this.account_info.bank_id);
            }
            this.$nextTick(() => {
                axios.post(`${process.env.VUE_APP_URL}/api/v1/payment/user/account/add?api_token=${this.patient_api_token}`, form)
                    .then(res => {
                        if (res.data.status_code == 200) {
                            this.$snotify.success('Successfully added');
                            this.$bvModal.hide('open-back-account-form')
                        }
                    })
            });
        },
        showMsgBoxTwo() {
            this.confirmation_box = ''
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete everything.', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    this.boxTwo = value
                })
                .catch(err => {
                    // An error occurred
                })
        }

    }
}
