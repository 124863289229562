<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <div class="mx-2">
                <b-button @click="addNewDoctor" variant="primary">Add new Doctor</b-button>
              </div>
              <div class="mx-2">
                <b-button
                    variant="primary"
                    @click="exportDoctorList">
                  <i class="fas fa-file-excel"></i> Export Excel
                </b-button>
              </div>
              <div class="mx-2">
                <b-button @click="dateRangeClear" variant="danger">Clear</b-button>
              </div>
            </div>
            <!--            Add new doctor modal start   -->
            <b-modal
                id="addNewDoctor"
                ref="modal"
                title="Add new doctor"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleNewDoctor"
            >
              <form ref="form" @submit.stop.prevent="submitNewDoctor">

                <b-form-group
                    label="First name"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                    :state="fullNameState">
                  <b-form-input
                      id="name-input"
                      v-model="newDoctor.first_name"
                      :state="fullNameState"
                      required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Last name"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                    :state="fullNameState">
                  <b-form-input
                      id="name-input"
                      v-model="newDoctor.last_name"
                      :state="fullNameState"
                      required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Phone number"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                    :state="phoneNumberState">
                  <b-form-input
                      id="name-input"
                      v-model="newDoctor.phone_number"
                      :state="phoneNumberState"
                      required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Choose gender" v-slot="{ ariaDescribedby }">
                  <b-form-radio-group
                      id="radio-group-1"
                      v-model="newDoctor.gender"
                      :options="gender"
                      :aria-describedby="ariaDescribedby"
                      name="radio-options"
                  ></b-form-radio-group>
                </b-form-group>

                <div class="mb-5">
                  <label for="">Choose Sub type</label>
                  <b-form-select v-model="newDoctor.sub_type" :options="sub_types"></b-form-select>
                </div>


                <b-form-group
                    label="Password"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                    :state="passwordState">
                  <b-form-input
                      type="password"
                      id="name-input"
                      v-model="newDoctor.password"
                      :state="passwordState"
                      required
                  ></b-form-input>
                </b-form-group>

                <b-form-group label="Profile image">
                  <b-form-file
                      id="profile_image"
                      ref="file"
                      accept=".png,.jpg,.jpeg">
                  </b-form-file>

                </b-form-group>


              </form>
            </b-modal>
            <!--  Add new doctor modal end   -->
            <div>
              <!-- Filter -->
              <div class="row  justify-content-between">
                <div class="col-md-1 mt-8">
                  <div class="">
                    <b-form-group
                        class="mb-0">
                      <b-form-select
                          id="per-page-select"
                          @input="getUser"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-md-3 ">
                  <label for="">From</label>
                  <input type="date" v-model="start_date" class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="">To</label>
                  <input type="date" v-model="end_date" class="form-control">
                </div>
                <div class="col-md-1 mt-8">
                  <button type="button" class="btn btn-info" @click="getUser">Filter</button>
                </div>
                <div class="col-md-4 mt-9">
                  <b-form @submit="searchDoctor" @reset="onReset">
                    <b-form-group
                        label-for="filter-input"
                        label-cols-sm="1"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"/>

                        <b-input-group-append>
                          <b-button variant="primary" type="submit">search</b-button>
                        </b-input-group-append>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                        </b-input-group-append>

                      </b-input-group>

                    </b-form-group>
                  </b-form>
                </div>
              </div>
              <!-- Table -->
              <div class="row">
                <div class="col-12">

                  <div class="row mt-3 align-items-center justify-content-end">
                    <div class="col-md-2 text-center">
                      <b-form-group
                          label-align-sm="right"
                          label-size="sm"
                          class="mb-0 text-center">
                        <b-input-group size="sm">
                          <b-form-select
                              id="sort-by-select"
                              v-model="sortBy"
                              @input="getUser"
                              :options="sortOptionDoctors">
                            <template #first>
                              <option value="">Sort by</option>
                            </template>
                          </b-form-select>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="col-md-2">
                      <b-form-checkbox
                          id="checkbox-1"
                          class="text-center"
                          v-model="has_chamber"
                          name="checkbox-1"
                          value="1"
                          unchecked-value="0"
                          @input="getUser"
                      >
                        Has Chamber
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-2">
                      <b-form-select v-model="confirmation_status" @input="getUser" :options="conformation_values"
                                     class=""></b-form-select>
                    </div>
                    <div class="col-md-2">
                      <div class="d-flex align-items-center justify-content-center">
                        <b-button class="mx-2" size="sm" variant="info" @click="trendyFilter('all')">All</b-button>
                        <b-button size="sm" variant="secondary" class="" @click="trendyFilter('trendy')">Trendy
                        </b-button>
                        <b-button size="sm" variant="secondary" class="mx-2" @click="trendyFilter('qc')">QC</b-button>
                      </div>
                    </div>
                  </div>

                  <b-table
                      striped hover responsive
                      :items="items.length ? filtered : items"
                      :fields="computedFields"
                      :current-page="currentPage"
                      :per-page="0"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      @filtered="onFiltered"
                      :filter-included-fields="filterOn"
                      thead-class="bg-primary text-white"
                      class="mt-5"
                      id="doctorList"
                      ref="selectableTable"
                      show-empty>


                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <p v-if="field.key == 'actions' || field.key == 'image' || field.key=='otp' || field.key=='bulk_trending'"></p>
                        <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                      </td>
                    </template>

                    <template #cell(bulk_trending)="{ rowSelected }">
                      <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                      </template>
                    </template>

                    <template #cell(image)="row">

                      <div class="is_online">
                        <img :src="userPhoto(row.item.image)" alt="image"
                             class="user_image" v-if="row.item.image" @click="showImage(row.item.image)"/>
                        <img src="../../../assets/img/blank.png" alt="" class="user_image" v-else>
                        <i :class="`${row.item.is_online == '1' ? 'text-green ' : 'text-danger'} fas fa-circle is_online_icon`"></i>
                      </div>
                    </template>

                    <template #cell(otp)="row">
                      <div class="is_online text-center">
                        <p v-if="row.item.otp===true"><i class="fas fa-check-circle text-success"></i></p>
                        <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                      </div>
                    </template>

                    <template #cell(confirmation)="row">
                      <div class="is_online text-center">
                        <p v-if="row.item.confirmation===true"><i class="fas fa-check-circle text-success"></i></p>
                        <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                      </div>
                    </template>

                    <template #cell(registration_date)="row">
                      <p>{{ row.item.registration_date }}</p>
                    </template>

                    <template #cell(email)="row">
                      <span> {{ row.item.email ? row.item.email : 'n/a' }}</span>
                    </template>

                    <template #cell(score)="row">
                      <b-badge :variant="`${row.item.score > 0 ? 'success' : 'warning'}`">
                        {{ row.item.score ? row.item.score : '0' }}
                      </b-badge>
                    </template>


                    <template #cell(is_trending)="row">
                      <div class="text-center">
                        <span v-if="row.item.is_trending == 1" class="text-success text-center"> <i
                            class="fas fa-street-view font-size-16 text-success trendy-icon"></i> </span>
                        <span v-else class="text-danger"> <i class="fas fa-male text-danger trendy-icon"></i> </span>
                      </div>
                    </template>

                    <template #cell(available_qc)="row">
                      <div class="text-center">
                        <span v-if="row.item.available_qc == 1" class="text-success  qc-yes">Y</span>
                        <span v-else class="text-danger qc-no">N</span>
                      </div>
                    </template>

                    <template #cell(name)="row">
                      <div v-if="row.item.parent_id">
                        <user-parent-info :parent_id="row.item.parent_id" :parent_name="row.item.parent"/>
                      </div>
                      <router-link :to="`/user/details/${row.item.id}`" class="font-weight-bold"> {{
                          row.item.name
                        }} {{ row.item.user_sub_type ? '(' + row.item.user_sub_type + ')' : '' }}
                      </router-link>
                      <br>
                      <span class="text-muted">{{ row.item.nature_of_service }}</span>
                      <div>
                        <b-badge>{{ row.item.id }}</b-badge>
                      </div>
                    </template>

                    <template #cell(has_chamber)="row">
                      <div v-if="row.item.chambers">
                        <ol>
                          <li v-for="(chamber, index) in row.item.chambers" :key="index">
                            <b>{{ chamber.institute }}</b><br><span class="text-muted">{{ chamber.address }}</span></li>
                        </ol>
                      </div>
                    </template>

                    <template #cell(username)="row">
                      <span> {{ row.item.username }}</span>
                    </template>

                    <template #cell(actions)="row">
                      <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" boundary="viewport"
                                  variant="primary"
                                  class="m-2">

                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>

                        <b-dropdown-item href="#" @click="userDetails(row.item.id)"><span class="pr-4"><i
                            class="fas fa-eye"></i></span> View details
                        </b-dropdown-item>

                        <router-link :to="`agent-wise/doctor-search/${row.item.id}`" class="dropdown-item"
                                     v-if="row.item.user_type === 'agent-pharmacy'">
                          Connect doctor
                        </router-link>
                        <b-dropdown-item href="#" @click="callHistory(row.item)" v-b-modal.modal-call>
                          <span class="pr-4"><i class="fas fa-phone-volume"></i></span> Call log
                        </b-dropdown-item>

                        <b-dropdown-item href="#" @click="userActiveInactive(row.item)" class="d-flex align-item-center"><span
                            class="pr-4"><i class="far fa-user"></i></span> Make
                          {{ row.item.is_active == true ? 'Inactive' : 'Active' }}
                        </b-dropdown-item>

                        <b-dropdown-item @click="changeProduct(row.item)">
                          <span class="pr-4"><i class="fab fa-product-hunt"></i></span> Change product
                        </b-dropdown-item>

                        <b-dropdown-item @click="assignOrkoCenter(row.item)">
                          <span class="pr-4"><i class="fas fa-university"></i></span> Assign Orko Center
                        </b-dropdown-item>

                        <b-dropdown-item @click="assignTeam(row.item)">
                          <span class="pr-4"><i class="fas fa-university"></i></span> Assign Team
                        </b-dropdown-item>

                        <div v-if="row.item.user_type === 'doctor'">
                          <!--                          <b-dropdown-item href="#">Withdrawal request</b-dropdown-item>-->
                          <b-dropdown-item @click="viewPoint(row.item)">
                            <span class="pr-4"><i class="fas fa-eye"></i></span> View scores
                          </b-dropdown-item>
                        </div>

                        <b-dropdown-item @click="setTrending(row.item)" v-if="row.item.is_trending == 0">
                          <span class="pr-4"><i class="fas fa-user-check"></i></span>Set trendy
                        </b-dropdown-item>

                        <b-dropdown-item @click="unsetTrending(row.item)" v-else>
                          <span class="pr-4"><i class="fas fa-user-check"></i></span> Unset trendy
                        </b-dropdown-item>

                        <b-dropdown-item @click="setQc(row.item)" v-if="row.item.available_qc == 0">
                          <span class="pr-4"><i class="fas fa-skiing"></i></span>Available for qc
                        </b-dropdown-item>

                        <b-dropdown-item @click="unsetQc(row.item)" v-else>
                          <span class="pr-4"><i class="fas fa-skiing"></i></span> Unavailable for qc
                        </b-dropdown-item>

                        <router-link :to="`agent-wise-patient-list/${row.item.id}`" class="dropdown-item">
                          <span class="pr-4"><i class="fas fa-user-injured"></i></span> Show patients
                        </router-link>

                        <router-link :to="`doctor/transaction/statement/${row.item.id}`" class="dropdown-item">
                          <span class="pr-4"><i class="fas fa-file-alt"></i></span>Transaction statement
                        </router-link>

                        <router-link :to="`doctor/transaction/history/${row.item.id}`" class="dropdown-item">
                          <span class="pr-4"><i class="fas fa-file-alt"></i></span>Transaction history
                        </router-link>

                      </b-dropdown>
                    </template>
                  </b-table>

                  <b-pagination
                      v-model="currentPage"
                      :total-rows="total_doctor"
                      :per-page="perPage"
                      @change="handlePageChange"
                      size="lg"
                      class="my-0"
                      aria-controls="doctorList"
                  ></b-pagination>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  View points  -->
    <b-modal id="view-point" size="lg" title="Points"
             @show="resetModal"
             @hidden="resetModal">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div class="user-info d-flex align-items-center">
              <div>
                <img :src="userPhoto(user_image)" alt="" class="img-fluid user_image mr-10">
              </div>
              <div>
                <h1>{{ user_name }}</h1>
                <h3 class="text-muted">{{ user_phone_number }}</h3>
              </div>
            </div>
            <div class="total-point">
              <p class="mb-0 text-muted">Total point</p>
              <div class="display-1 text-primary">
                {{ total_point }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="points"
              :fields="point_fields"
              thead-class="bg-primary text-white"
              show-empty>
            <template #cell(created_at)="row">
              {{ row.item.created_at | moment("ddd, MMM Do YYYY, h:mm:ss a") }}
            </template>

          </b-table>
        </div>
      </div>

    </b-modal>

    <!--    Reset password modal -->
    <b-modal id="modal-lg" size="lg" title="Reset Password" hide-footer>
      <b-form @submit="resetPassword" @reset="onReset" v-if="show">
        <b-form-group
            id="input-group-1"
            label="Password * "
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="form.password"
              type="password"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Action comment">
          <b-form-textarea
              class="mb-2"
              id="textarea"
              v-model="form.comment"
              placeholder="Enter something..."
              rows="3"
          ></b-form-textarea>
          <span class="text-danger"><b>Note: </b>Provide a valid reason/comment here. No dummy or irrelevant content will be acceptable</span>
        </b-form-group>

        <b-form-group>
          <div>
            <p>N.B: Password should be unique.</p>
            <p>1. Do not use repeated password.</p>
            <p>2. Please set atleast 6 charaecter length password.</p>
            <p>3. Do not use same password for multiple users.</p>
          </div>
        </b-form-group>

        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>

    <!--    Call history -->
    <b-modal id="modal-call" size="xl" :title="`Add call log ( ${user.user_name} )`" hide-footer>
      <b-form @submit.prevent="saveCallHisory" @reset="onReset" v-if="show">

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Response *</label>
              <select class="form-control" v-model="call_history.call_response">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="call_type">Call Type *</label>
              <select name="" id="call_type" class="form-control" v-model="call_history.call_type">
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Status *</label>
              <v-select
                  :options="call_category"
                  :reduce="call_category => call_category.id"
                  v-model="call_history.call_category"
                  class="h-100"
                  label="title"
                  name="title">
              </v-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Remarks: *</label>
              <b-form-textarea
                  id="textarea"
                  v-model="call_history.remarks"
                  placeholder="Remarks:*"
                  rows="1"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox
                id="checkbox-1"
                v-model="call_history.is_reminder"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
            >
              Want a reminder
            </b-form-checkbox>

            <div v-if="call_history.is_reminder ==1">
              <b-form-datepicker id="example-datepicker" v-model="call_history.reminder_date"
                                 class="mb-2"></b-form-datepicker>
            </div>

          </div>
        </div>

        <div class="row mt-10">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <h4>Call history</h4>
            <b-table
                responsive
                striped hover
                :items="callHistories"
                :fields="call_history_filed"
                thead-class="bg-primary text-white">
            </b-table>
          </div>
        </div>

      </b-form>
    </b-modal>

    <vue-snotify></vue-snotify>

    <loader-component v-if="loading"/>

    <div v-if="trending_submit_btn" class="floating_btn_for_trending">
      <button type="button" class="btn btn-info btn-lg">Save trending doctor</button>
    </div>

    <ChangeProductModal @modalClosed="is_change_modal_open=false" v-if="is_change_modal_open"
                        :change_modal_data="change_modal_data"/>
    <AssignUserToInstitute :doctor_id="doctor_id" v-if="assign_user_to_institute_modal" @modalClosed="assign_user_to_institute_modal=false"/>
   
    <AssignTeamToDoctor :doctor_id="doctor_id" v-if="assign_team_modal" @modalClosed="assign_team_modal=false"/>

  </div>
</template>

<script>
import {DoctorListMixin} from "../../../mixins/DoctorListMixin";
import ChangeProductModal from "../../modal/ChangeProductModal";
import AssignUserToInstitute from "../../modal/AssignUserToInstitute";
import AssignTeamToDoctor from "../../modal/AssignTeamToDoctor";

export default {
  name: "DoctorList",
  mixins: [DoctorListMixin],
  components: {
    ChangeProductModal,
    AssignUserToInstitute,
    AssignTeamToDoctor
  },
  title: 'Doctor list',
}
</script>

<style scoped>
.font-size-12 {
  font-size: 12px;
}

.floating_btn_for_trending {
  position: absolute;
  bottom: 30px;
  right: 55px;
}

.trendy-icon {
  font-size: 24px;
  text-align: center;
}

.qc-no {
  border: 2px solid red;
  border-radius: 100%;
  padding: 3px 9px;
  font-size: 18px;
  font-weight: 500;
}

.qc-yes {
  border: 2px solid green;
  border-radius: 100%;
  padding: 3px 10px;
  font-size: 18px;
  font-weight: 500;
}

.active {
  font-weight: bold;
}
</style>
