<template>
  <div class="card">
    <div class="card-body">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-3">
          <label for="">Select TSM</label>
          <b-form-select
              id="doctor_type"
              v-model="tsm_id"
              :options="sales_users"
              text-field="name"
              value-field="id">
          </b-form-select>
        </div>
        <div class="col-md-9">
          <div class="d-flex align-items-center justify-content-between">
            <div class="w-100">
              <label for="">From</label>
              <input type="date" v-model="start_date" class="form-control">
            </div>
            <div class="w-100 mx-2">
              <label for="">To</label>
              <input type="date" v-model="end_date" class="form-control">
            </div>
            <div class="mt-8 d-flex">
              <button type="button" class="btn btn-info" @click="getItems">Filter</button>
              <button type="button" class="btn btn-danger mx-5" @click="clear">Clear</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              :current-page="0"
              :per-page="perPage"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(doctor_name)="row">
              <div>
                <h5 v-if="row.item.doctor_id">
                  <router-link :to="`/user/details/${row.item.doctor_id}`" target="_blank">{{
                      row.item.doctor_name
                    }}
                  </router-link>
                </h5>
                <h5 v-else>{{ row.item.doctor_name }}</h5>
                <p class="mb-0">{{ row.item.designation }}</p>
                <p class="mb-0">{{ row.item.phone_no }}</p>
                <p class="mb-0" v-if="row.item.bmdc_reg_no"><b>BMDC:</b> {{ row.item.bmdc_reg_no }}</p>
                <p class="mb-0" v-if="row.item.doctor_registered_at"><b>Registered At:</b>
                  {{ createdAtFormat(row.item.doctor_registered_at) }}</p>
              </div>
            </template>

            <template #cell(hospital_name)="row">
              <div>
                <h5>{{ row.item.chamber_name }}</h5>
                <p class="mb-0">{{ row.item.chamber_address }}</p>
              </div>
            </template>

            <template #cell(created_at)="row">
              {{ createdAtFormat(row.item.created_at) }}
            </template>

            <template #cell(is_doctor_registered)="row">
              <div>
                <b-badge variant="success" v-if="row.item.is_doctor_registered==1">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </div>
            </template>

            <template #cell(is_doctor_confirmed)="row">
              <div>
                <b-badge variant="success" v-if="row.item.is_doctor_confirmed==1">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </div>
            </template>

            <template #cell(is_bmdc_ss_uploaded)="row">
              <div>
                <b-badge variant="success" v-if="row.item.is_bmdc_ss_uploaded==1">Yes</b-badge>
                <div v-else>
                  <b-badge variant="danger">No</b-badge>
                  <button class="btn btn-sm btn-info my-2" v-if="row.item.is_doctor_registered==1"
                          @click="getUserData(row.item)">Upload SS
                  </button>
                </div>

              </div>
            </template>

            <template #cell(lat)="row">
              <div>
                <h5><b>Lat: </b>{{ row.item.lat }}</h5>
                <h5><b>Lon: </b>{{ row.item.lon }}</h5>
              </div>
            </template>

          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              @change="getItems"
              size="lg"
              class="my-0"
              aria-controls="doctorList"
          ></b-pagination>
        </div>
      </div>
    </div>
    <b-modal
        id="editBmdc"
        title="Edit medical registration"
        @hidden="resetModal"
        size="lg"
        @ok="handleOk">

      <form ref="form" @submit.stop.prevent="editBmdcNumber">
        <div class="mb-4">
          <h3 for="simage">Screenshot upload</h3>

          <div class="preview text-center">

            <img v-if="screenshot_image_preview" class="preview-img" :src="screenshot_image_preview"
                 alt="Preview Image"
                 width="200" height="200"/>

            <img v-else-if="exist_screenshot" class="preview-img" :src="userPhoto(exist_screenshot)"
                 alt="Preview Image"
                 width="200" height="200"/>

            <img v-else class="preview-img"
                 :src="screenshot_image_preview ? screenshot_image_preview : `https://cdn.getshifter.co/9ffafe227584833e1d83fe8314723e0fe39da375/uploads/2017/11/null25-1024x759.jpeg`"
                 alt="Preview Image" width="300" height="300"/>
            <div class="browse-button">
              <i class="fa fa-pencil-alt"></i>
              <input class="browse-input" @change="onScreenChange" type="file"
                     name="UploadedFile" id="simage"
                     ref="screenshot_image" accept=".png,.jpg,.jpeg"/>
            </div>
            <span class="Error"></span>
          </div>
        </div>
      </form>
    </b-modal>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>

  </div>
</template>


<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import axios from "axios";
import {DateFormat} from "../../mixins/core/DateFormat";
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";

export default {
  name: "SalesCallLog",
  mixins: [DateFormat, SalesUserMixins],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales Calls", route: "/sales/call/log"},
    ]);
  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'seller_name',
          label: 'TSM name',
          sortable: true,
        },
        {
          key: 'doctor_name',
          label: 'Doctor',
          sortable: true,
        },
        {
          key: 'hospital_name',
          label: 'Chamber Info',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Created At',
          sortable: true,
        },
        {
          key: 'remarks',
          label: 'Remarks',
          sortable: true,
        },
        {
          key: 'log_address',
          label: 'Log address',
          sortable: true,
        },
        {
          key: 'lat',
          label: 'Lat & Lon',
          sortable: true,
        },
        {
          key: 'is_doctor_registered',
          label: 'Doctor registered ?',
          sortable: true,
        },
        {
          key: 'is_bmdc_ss_uploaded',
          label: 'BMDC Screenshot uploaded ?',
          sortable: true,
        },
        {
          key: 'is_doctor_confirmed',
          label: 'Doctor confirmed',
          sortable: true,
        },

        
        
      ],
      currentPage: 1,
      perPage: 20,
      total_count: 0,
      loading: false,
      medical_registration_authority: [],
      form: {
        bmdc_no: '',
        reg_year: '',
        registration_authority_id: '',
        id: null,
        api_token: '',
      },
      screenshot_image_preview: null,
      exist_screenshot: '',
      doctor_token: null,
      start_date: null,
      end_date: null,
      tsm_id: '',
    }
  },
  created() {
    localStorage.setItem('tabIndex', 14)
    this.$root.$emit('call-active-menu', 14);
    this.getItems();
    this.getMedicalRegistration();
  },

  methods: {
    getItems(value) {
      this.loading = true;

      var offset = value - 1;
      var correctValue = offset * this.perPage;


      let url = `${process.env.VUE_APP_URL}/api/v2/sales/team/activity/calls?api_token=${localStorage.getItem('api_token')}${correctValue ? '&offset=' + correctValue : '&offset=0'}&limit=${this.perPage}&platform=backoffice${this.tsm_id ? '&user_id=' + this.tsm_id : ''}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`;
      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    getMedicalRegistration() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/medical-registration-authority/list`)
          .then(response => {
            this.medical_registration_authority = response.data.data;
          })
    },
    uploadSS(item) {
      console.log('item', item)
      console.log('cjalled', this.medical_registration_authority[0].code)
      this.form.bmdc_no = this.medical_registration_authority[0].code;
      this.form.reg_year = this.medical_registration_authority[0].registration_year;
      // this.form.registration_authority_id = this.medical_registration[0].registration_authority_id;
      this.medical_registration_authority.map(item => {
        if (item.name == this.medical_registration_authority[0].authority_name) {
          return this.form.registration_authority_id = item.id;
        }
      });
      this.doctor_token = item.doctor_token;
      this.$bvModal.show('editBmdc');
    },
    editBmdcNumber() {
      // if (this.form.registration_authority_id == '' || this.form.registration_authority_id == null) {
      //   return this.medical_registration_authority_is_required = 'Medical registration authority is required';
      // }
      let screenshot_image = this.$refs.screenshot_image.files[0];

      let form = new FormData();
      form.append("authority[0][registration_code]", this.form.bmdc_no);
      form.append("authority[0][registration_year]", this.form.reg_year);
      form.append("authority[0][registration_authority_id]", this.form.registration_authority_id);

      if (screenshot_image) {
        form.append('authority[0][screenshot]', screenshot_image);
      }
      form.append("is_walk_through", "1");
      axios.post(`${process.env.VUE_APP_URL}/api/v1/medical-registration-authority/set?user_id=${this.form.id}&platform=backoffice&api_token=${localStorage.getItem('api_token')}`, form)
          .then(response => {
            if (response.data.status_code == 400) {
              return this.$snotify.error(response.data.message);
            }
            this.$snotify.success('Successfully updated')
            this.getItems();
            this.resetModal();
          })
    },
    resetModal() {
      this.form = {}
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.editBmdcNumber()
    },
    onScreenChange(e) {
      const file = e.target.files[0];
      this.screenshot_image_preview = URL.createObjectURL(file);
    },
    userPhoto(img) {
      if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return "../../assets/img/blank.png";
      }
    },
    getUserData(item) {
      this.loading = true;
      this.getMedicalRegistration();
      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${item.doctor_id}?platform=backoffice&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.medical_registration_authority.map(item => {
              if (!item.authority_type_id) {
                this.$snotify.error('Sorry medical registration not found')
                this.resetModal;
                this.loading = false;
                return true;
              }
              if (item.name == response.data.data.doctor_information.medical_registration[0].authority_name) {
                return this.form.registration_authority_id = item.id;
              }
            });
            // this.form = response.data.data.doctor_information.medical_registration[0]
            this.form.bmdc_no = response.data.data.doctor_information.medical_registration[0].code
            this.form.reg_year = response.data.data.doctor_information.medical_registration[0].registration_year
            this.form.api_token = response.data.data.api_token
            this.loading = false;
            this.$bvModal.show('editBmdc');
          })
    },
    clear() {
      this.tsm_id = '';
      this.start_date = null;
      this.end_date = null;
    }
  }
}
</script>

<style scoped>

.preview {
  padding: 10px;
  position: relative;
}

.preview i {
  color: white;
  font-size: 35px;
  transform: translate(50px, 130px);
}

.preview-img {
  border-radius: 100%;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.7);
}

.preview-img-nid {
  /*border-radius: 100%;*/
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.7);
}

.browse-button {

  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute; /* Tweak the position property if the element seems to be unfit */
  top: 10px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, transparent, black);
  opacity: 0;
  transition: 0.3s ease;
}

.browse-button-nid {
  width: 200px;
  height: 200px;
  /*border-radius: 100%;*/
  position: absolute; /* Tweak the position property if the element seems to be unfit */
  top: 10px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, transparent, black);
  opacity: 0;
  transition: 0.3s ease;
}

.browse-button:hover {
  opacity: 1;
}

.browse-button-nid:hover {
  opacity: 1;
}

.browse-input {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  transform: translate(-1px, -26px);
  opacity: 0;
}

.browse-input-nid {
  width: 200px;
  height: 200px;
  /*border-radius: 100%;*/
  transform: translate(-1px, -26px);
  opacity: 0;
}

</style>
