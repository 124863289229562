import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const ManagePermissionMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Name": {
                    field: "name",
                },
                "Method": {
                    field: "method",
                },
                "Api": {
                    field: "api",
                },
                "Access level": {
                    field: "access_level",
                },
                "Is common": {
                    field: "is_common",
                }
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Name',
                    key: 'name',
                    sortable: true,
                },
                {
                    label: 'Method',
                    key: 'method',
                    sortable: true,
                },
                {
                    label: 'Permission group',
                    key: 'permission_group',
                    sortable: true,
                },
                {
                    label: 'API',
                    key: 'api',
                    sortable: true,
                },
                {
                    label: 'Access level',
                    key: 'access_level',
                    sortable: true,
                },
                {
                    label: 'Is common',
                    key: 'is_common',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            strengthState: null,
            useForState: null,
            dosagesState: null,
            darState: null,
            unit_priceState: null,
            dosages_desc_typeState: null,
            form: {
                name: '',
                method: '',
                api: '',
                is_common: '',
                access_level: '',
                permission_group: ''
            },
            edit: false,
            edit_id: '',
            medicine_generics: [],
            medicine_company: [],
            manufacturers: [],
            loading: true,
            medicine_generics_is_required: '',
            medicine_company_required: '',
            price_formet: '',
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Manage permissions", route: "/manage-permission"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 13)
        this.$root.$emit('call-active-menu', 13)
        this.getPermissions();
        this.getMedicineGenerics();
        this.getMedicineCompany();

    },
    metaInfo: {
        title: 'Orko Health Ltd | Manage permission',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },

    methods: {
        getPermissions() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/permission/list/all?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            this.unit_priceState = valid
            this.strengthState = valid
            this.dosagesState = valid
            this.useForState = valid
            return valid
        },
        resetModal() {
            this.form = {
                title: '',
                medicine_generics_id: '',
                strength: '',
                use_for: '',
                dar: '',
                unit_price: '',
                dosages_desc_type: '',
                manufacturer_id: '',
            };
            this.titleState = null;
            this.medicine_generics_is_required = null;
            this.medicine_company_required = null;
            this.titleState = null;
            this.strengthState = null;
            this.useForState = null;
            this.dosagesState = null;
            this.darState = null;
            this.unit_priceState = null;
            this.dosages_desc_typeState = null;
            this.edit = false;
            this.edit_id = '';
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler

            if (this.edit) {
                return this.updatedata();
            }
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }

            if (this.form.medicine_generics_id == '' || this.form.medicine_generics_id == null) {
                this.medicine_generics_is_required = "Medicine generics is required";
            }
            if (this.form.manufacturer_id == '' || this.form.manufacturer_id == null) {
                return this.medicine_company_required = 'Medicine company is required';
            }

            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);
            form.append("medicine_generics_id", this.form.medicine_generics_id);
            form.append("strength", this.form.strength);
            form.append("dosages_desc_type", this.form.dosages_desc_type);
            form.append("use_for", this.form.use_for);
            form.append("unit_price", this.form.unit_price);
            form.append("manufacturer_id", this.form.manufacturer_id);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medicine/create?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicine();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        updatedata() {


            let form = new FormData();
            form.append("permission_id", this.edit_id);
            // form.append("name", this.form.name);
            // form.append("method", this.form.method);
            // form.append("api", this.form.api);
            // form.append("permission_group", this.form.permission_group);
            form.append("access_level", this.form.access_level);
            form.append("is_common", this.form.is_common);
            form.append("status", '1');

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/permission/update?api_token=${localStorage.getItem('api_token')}&_method=put`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getPermissions();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        getMedicineGenerics() {
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/medicine/generics/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    }
                    this.medicine_generics = response.data.data;

                })
        },
        getMedicineCompany() {
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/medicine/company/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    }
                    this.manufacturers = response.data.data;

                })
        },
        editAction(row) {
            this.$bvModal.show('modal-1');
            this.edit = true;
            this.edit_id = row.item.id;
            this.form = row.item;

            // this.form.title = row.item.title;
            // this.form.manufacturer_id = row.item.manufacturer_id;
            // this.form.medicine_generics_id = row.item.medicine_generics_id;
            // this.form.manufacturer = row.manufacturer;

        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/medicine/remove/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getMedicine();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }

    }
}
