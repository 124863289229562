<template>
    <div>
       <div class="card">
        <div class="card-body">
            <h1>Audit Form</h1>
           
            <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="4" class="my-5">
              <label>Name</label>
              <b-form-input v-model="form.name"></b-form-input>
            </b-col>
           
            <b-col cols="4" class="my-5">
              <label>Designation</label>
              <b-form-input v-model="form.designation"></b-form-input>
            </b-col>

            <b-col cols="4" class="my-5">
              <label>Department</label>
              <b-form-input v-model="form.department"></b-form-input>
            </b-col>

            <b-col cols="4" class="my-5">
              <label>Date of Submission</label>
              <b-form-input type="date" v-model="form.submission_date"></b-form-input>
            </b-col>

            <b-col cols="8" class="my-5">
              <label>Description</label>
              <b-form-textarea v-model="form.description"></b-form-textarea>
            </b-col>

          </b-row>
          <b-row>
            <div class="col-md-3">
                <label for="">Select Center</label>
                <b-form-select
                    id="doctor_type"
                    v-model="form.institute_id"
                    :options="centers"
                    text-field="name"
                    value-field="id">
                </b-form-select>
            </div>
          </b-row>
          <table class="table">
            <thead>
                <th>Topics</th>
                <th>Weightage</th>
                <th>Score</th>
                <th>Remarks</th>
                <th>Actions</th>
            </thead>
            <tbody>
                <tr v-for="(topic, index) in topics" :key="index">
                    <td>
                        <b-form-textarea v-model="topic.title"></b-form-textarea>
                    </td>
                    <td>
                        <b-form-input type="number" max="10" v-model="topic.weightage"></b-form-input>
                    </td>
                    <td>
                        <b-form-input type="number" max="10" v-model="topic.score"></b-form-input>
                    </td>
                    <td>
                        <b-form-textarea v-model="topic.remarks"></b-form-textarea>
                    </td>
                    <td>
                        <b-btn @click="remove(index)" variant="danger" class="mt-5"  v-show="index != 0" >Del</b-btn>
                    </td>
                </tr>
            </tbody>
          </table>
          <b-btn class="btn-sm" @click="addMore()" variant="warning">Add more</b-btn>

          <b-row>
            <b-col cols="8" class="my-5">
                <h1>Findings:</h1>
                <div v-for="(find, index) in findings" :key="index" class="mb-2  d-flex">
                    <b-form-input v-model="find.title"></b-form-input>
                    <b-btn @click="removeFindings(index)" variant="danger" class="ml-2"  v-show="index != 0" >Del</b-btn>
                </div>
                <b-btn class="btn-sm mt-2" @click="addMoreFindings()" variant="warning">Add more</b-btn>

            </b-col>
          </b-row>
          <br><br><br>
          <button type="submit" class="btn btn-info btn-lg">Submit</button>
        </form>
        </div>
       </div>
    <vue-snotify></vue-snotify>
    </div>
</template>
<script>
import axios from 'axios'
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
    name: 'AuditForm',
    data() {
        return {
            form: {
                id: '',
                name: '',
                designation: '',
                description: '',
                submission_date: '',
                institute_id: '',
            },
            topics: [
                {
                    title: '',
                    weightage: '',
                    score: '',
                    remarks: ''
                }
            ],
            findings:[
                {
                    title: '',
                }
            ],
            centers: [],
        }
    },
    created() {
        localStorage.setItem('tabIndex', 9)
        this.$root.$emit('call-active-menu', 9);
        this.centerList();
        if(this.$route.params.id) {
            this.getItem();
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Audit Form", route: "/audit/form"},
        ]);
    },
    methods: {
        handleSubmit() {
            let form = new FormData();
            form.append('name', this.form.name);
            form.append('designation', this.form.designation);
            form.append('department', this.form.department);
            form.append('description', this.form.description);
            form.append('institute_id', this.form.institute_id);
            form.append('submission_date', this.form.submission_date);
            form.append('topics', JSON.stringify(this.topics));
            form.append('findings', JSON.stringify(this.findings));
            let url = '';
            if(this.form.id) {
                 url = `${process.env.VUE_APP_API_URL}/api/v2/orko/center/audit/reports/${this.form.id}?api_token=${localStorage.getItem('api_token')}`;

            }else {
                 url = `${process.env.VUE_APP_API_URL}/api/v2/orko/center/audit/reports?api_token=${localStorage.getItem('api_token')}`;

            }
            axios.post(url, form).then(res => {
                if (res.data.status_code == 200) {
                    this.$router.push('/audit/list')
                    this.$snotify.success('Successfully saved');
                } else {
                    this.$snotify.error(res.data.message);
                }
            })
        },
        getItem() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko/center/audit/reports/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
            .then(res => {
                this.data = res.data.data;
                this.findings = JSON.parse(res.data.data.findings)
                this.topics = JSON.parse(res.data.data.topics)
                this.form = res.data.data;
            })
        },
        centerList() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                    this.$router.push('/access-denied')
                    } else {
                    
                    this.centers = response.data.data
                    }
                })
        },
        addMore() { 
            this.topics.push({
                title: '',
                weightage: '',
                score: '',
                remarks: ''
            });
        },
        addMoreFindings() {
            this.findings.push({
                title: '',
            })
        },
        remove(index) {
            this.topics.splice(index, 1);
        },
        removeFindings(index) {
            this.findings.splice(index, 1);
        },
    }
}
</script>