<template>
  <div class="card">
  <div class="card-body">
    <b-row class="my-1">
      <b-col sm="2">
        <label for="input-default">Search Menu</label>
      </b-col>
      <b-col sm="10">
        <b-form-input id="input-default" v-model="search" placeholder="Enter menu name"></b-form-input>
      </b-col>
    </b-row>
    <div>
      <router-link :to="`/${menu.link}`"  pill v-for="(menu, index) in filteredList" :key="index" class="btn btn-default btn-pill my-2 mx-2">{{ menu.name }}</router-link>
    </div>
  </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
  name: "ShortCutMenu",
  data(){
    return {
      menus: [
        {
          name: 'Doctor list',
          link: 'doctor-users'
        },
        {
          name: 'Patient list',
          link: 'patient-users'
        },
        {
          name: 'GSO',
          link: 'agent'
        },
        {
          name: 'Bank',
          link: 'bank'
        },
        {
          name: 'Institute',
          link: 'institute',
        },
        {
          name: 'Investigation',
          link: 'investigation'
        },
        {
          name: 'Chief Complements',
          link: 'chief-complements'
        },
        {
          name: 'Medical Service',
          link: 'advice'
        },
        {
          name: 'Therapy',
          link: 'therapy'
        },
        {
          name: 'Diagnosis',
          link: 'diagnosis',
        },
        {
          name: 'Medical Service',
          link: 'nature-of-service'
        },
        {
          name: 'specialities',
          link: 'orkospecialities'
        },
        {
          name: 'Orko Profession',
          link: 'orko-profession'
        },
        {
          name: 'Symptoms',
          link: 'symptoms'
        },
        {
          name: 'Appointment list',
          link: 'appointment-list',
        },

      ],
      search: '',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Shortcut", route: "/short-cut"},
    ]);
  },
  computed: {
    filteredList() {
      return this.menus.filter(menu => {
        return menu.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },

}
</script>

<style scoped>

</style>
