import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
export const NotificationTypeMixin = {
    data() {
        return {
            form: {
                notification_type_term: '',
                relationship_with: '',
                relationship_with_column: '',
                status: '',
                id: '',
            },
            bookType: 'xlsx',
            list: null,
            listLoading: true,
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            items: [],
            loading: false,
            name: '',
            relationship_columnState: null,
            relationship_withState: null,
            notification_type_nameState: null,
            submittedNames: [],
            groups: [
                {
                    id: 1,
                    name: "Users"
                }
            ],
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'notification_type_term',
                    label: 'Notification type term',
                    sortable: true
                },
                {
                    key: 'relationship_with',
                    label: 'Relationship with',
                    sortable: true
                },
                {
                    key: 'relationship_with_column',
                    label: 'Relationship with column',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'created_at',
                    label: 'Created at',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Action',
                }
            ],
            filter: null,
            filterOn: [],
            filters: {
                index: '',
                notification_type_term: '',
                relationship_with: '',
                relationship_with_column: '',
                status: '',
            },
            json_fields: {
                "Notification type term": {
                    field: "notification_type_term",
                },
                "Relationship with": {
                    field: "relationship_with",
                },
                "Relationship with column": {
                    field: "relationship_with_column",
                },
                "Created date": {
                    field: "created_at",
                },

            },
            columns: [
                {
                    key: 'index',
                    label: 'SL.',
                },
                {
                    key: 'notification_type_term',
                    label: 'Notification type term',
                },
                {
                    key: 'relationship_with',
                    label: 'Relationship with',
                },
                {
                    key: 'relationship_with_column',
                    label: 'Relationship with column',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
                {
                    key: 'created_at',
                    label: 'Created at',
                }
            ],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Notification type", route: "notification-type"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 5)
        this.$root.$emit('call-active-menu', 5);
        this.getNotificationType();
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            const filtered = this.items.filter(item => {

                return Object.keys(this.filters).every(key =>

                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                index: '',
                notification_type_term: '',
                relationship_with: '',
                relationship_with_column: '',
                status: '',
            }]
        }
    },
    methods: {
        getNotificationType() {
            // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
            axios.get(`${process.env.VUE_APP_URL}/api/v1/notification/manage/type/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                        this.loading = true;
                    }
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            this.relationship_columnState = valid
            this.relationship_withState = valid
            this.notification_type_nameState = valid
            return valid
        },
        resetModal() {
            this.name = ''
            this.nameState = null;
            this.form = {};
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();
            form.append("notification_type_name", this.form.notification_type_term);
            form.append("relationship_model", this.form.relationship_with);
            form.append("relationship_column", this.form.relationship_with_column);
            form.append("status", '1');

            // Hide the modal manually
            this.$nextTick(() => {
                var url = '';
                if(this.form.id){
                     url = `${process.env.VUE_APP_URL}/api/v1/notification/manage/type/update/${this.form.id}?api_token=${localStorage.getItem('api_token')}`
                }else {
                     url = `${process.env.VUE_APP_URL}/api/v1/notification/manage/type/add?api_token=${localStorage.getItem('api_token')}`;
                }
                axios
                    .post(url, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }

                        this.resetModal();
                        this.getNotificationType();
                        this.$bvModal.hide('modal')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        showRolesDetails(id) {
            this.$router.push(`/roles/show/${id}`);
        },
        editData(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.updateNotification()
        },
        updateData(row) {
            this.$bvModal.show('modal')
            this.form = row.item;
            this.update_id = row.item.id
        },
        updateNotification() {
            // Push the name to submitted names
            let form = new FormData();
            form.append("notification_type_name", this.form.notification_type_term);
            form.append("relationship_model", this.form.relationship_with);
            form.append("relationship_column", this.form.relationship_with_column);
            form.append("status", '1');

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/notification/manage/type/update/${this.update_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getNotificationTemplate();
                        this.$bvModal.hide('modal')
                        this.$snotify.success('Successfully Updated');
                    })
            })
        }
    }
}
