<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <div class="d-flex justify-content-end">
          <div class="mx-3">
            <b-button variant="primary" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new chief
              complaints
            </b-button>
          </div>
          <div>
            <download-excel
                :data="items"
                class="btn btn-primary"
                :fields="json_fields"
                worksheet="Medical advice"
                name="Medical advice">
              <i class="fas fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">

            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a new chief complaints"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">


                <label for="title-input">Chief Complaints <span class="text-danger">*</span></label>
                <b-form-textarea
                    id="textarea"
                    v-model="form.title"
                    :state="titleState"
                    rows="3"
                    max-rows="6"
                    required/>

              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-2">
            <div class="d-flex align-items-center">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
              <button
                  @click="multipleDelete"
                  type="button"
                  v-if="selected_id.length"
                  class="btn btn-dark text-white ms-5 ml-5">
                Delete
              </button>
            </div>

          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">

            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">

              <template #cell(id)="row">
                <div class="d-flex">
                  <b-form-checkbox
                      :id="`checkbox-${row.item.id}`"
                      name="checkbox-1"
                      v-model="selected_id"
                      :value="`${row.item.id}`"
                      v-on:change="deletedObj(row.item)"
                      unchecked-value="0"
                  >
                  </b-form-checkbox>
                  <span>{{ row.index + 1 }}</span>
                </div>

              </template>

              <template #head(id)>
                <div class="d-flex">
                  <div style="margin-left: 10px;margin-top: 2px">
                    <input type="checkbox" v-model="checkAll"/>
                  </div>
                  <div style="margin-left: 10px">
                    Select
                  </div>
                </div>

              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>


              <template #cell(title)="data">
                <div>
                  <div v-if="data.item.type=='image'">
                    <b-img :src="userPhoto(data.item.bank_logo)" class="user_image" :alt="data.item.bank_logo"
                           v-if="data.item.bank_logo"></b-img>
                  </div>
                  <div v-else>
                    {{ data.item.title }}
                  </div>
                </div>
              </template>

              <template #cell(actions)="row">

                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">

                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>

                  <div>
                    <b-dropdown-item href="#" @click="editAction(row)">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="deleteAction(row)">Hide Record</b-dropdown-item>
                  </div>
                </b-dropdown>

              </template>

            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {ResponseMixin} from "../../mixins/core/ResponseMixin";
import {ChiefComplaintsMixin} from "../../mixins/miscellaneous/ChiefComplaintsMixin";

export default {
  name: "Advice",
  mixins: [ChiefComplaintsMixin, ResponseMixin],

}
</script>
<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>