<template>
<div class="card border-0">
  <div class="card-body">
    <h2>CRM</h2>
    <h2>{{ details.assigned_crm_name }}</h2>
  </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
  name: "CrmCallDetails",
  data(){
    return {
      details:{},
    }
  },
  created() {
    this.getDetails();
    localStorage.setItem("tabIndex", 9);
    this.$root.$emit("call-active-menu", 9);
  },
  methods:{
    getDetails() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/crm/call-logs/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`).then(res => {
        this.details = res.data.data;
      })
    },
    prescriptionUrl(url) {
      if (url) {
        return `${process.env.VUE_APP_API_URL}/${url}`;
      } else {
        return ''
      }
    },
  }
}
</script>

<style scoped>

</style>
