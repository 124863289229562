<template>
  <div>

    <div>
      <div>
        <box-card/>
      </div>
      <!--begin::Dashboard-->
      <div>
        <div class="row mb-10 mt-4">
          <div class="col-md-4">
            <div class="card card-box">
              <div class="card-body" v-if="pie_loading">
                <h3 class="text-center">Orko users</h3>
                <PieChart :data="pieData" :options="{responsive: true, maintainAspectRatio: true}"
                          class="small"></PieChart>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card card-box">
              <div class="card-body" v-if="pie_appointment_loading">
                <h3 class="text-center">Orko Appointments</h3>
                <AppoinmentPieChart :data="appointmentData" :options="{responsive: true, maintainAspectRatio: true}"
                                    class="small"></AppoinmentPieChart>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card card-box">
              <div class="card-body" v-if="pie_prescription_loading">
                <h3 class="text-center">Orko Prescription</h3>
                <PrescriptionPieChart :data="prescriptionData" :options="{responsive: true, maintainAspectRatio: true}"
                                      class="small"></PrescriptionPieChart>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="row">
        <div class="col-md-4">
          <doctor-report :doctors="doctors" :series="doctor_chart" @filterData="doctors"/>
        </div>
        <div class="col-md-4">
          <patient-report :patients="patients" :series="patient_chart"/>
        </div>
        <div class="col-md-4">
          <agent-report :agents="agents" :series="agent_chart"/>
        </div>

      </div>

      <level-wise-doctor-count-component/>

      <div class="row">
        <div class="col-md-6">
          <top-scorer-user/>
        </div>

        <div class="col-md-6">
          <latest-prescription/>
        </div>
      </div>
      <!--end::Dashboard-->
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {mapActions} from "vuex";
import PieChart from "../../components/chart/PieChart";
import AppoinmentPieChart from "../../components/chart/AppointmentPieChart";
import PrescriptionPieChart from "../../components/chart/PrescriptionPieChart";

export default {
  name: "dashboard",
  components: {PieChart, AppoinmentPieChart, PrescriptionPieChart},
  data() {
    return {
      doctors: {},
      agents: {},
      patients: {},
      doctor_chart: [],
      patient_chart: [],
      agent_chart: [],
      chartData: {
        labels: ['January', 'February'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [40, 20]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      pieData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#4287f5  ", "red", "black", 'green','yellow', '#4251f5'],
            data: []
          }
        ]
      },
      appointmentData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "blue", "red", "black", 'green'],
            data: []
          }
        ]
      },
      prescriptionData: {
        labels: ['Invalid', 'Valid'],
        datasets: [
          {
            backgroundColor: ["#E46651", "#41B883"],
            data: []
          }
        ]
      },
      pie_loading: false,
      pie_appointment_loading: false,
      pie_prescription_loading: false,
    }
  },

  mounted() {
    this.getChartData();
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Dashboard"}]);

    if (this.$store.state.auth.isAuthenticated === '') {
      this.$router.push('login');
    }
    this.getAppintmentPieChart();
    this.getPrescriptionPieChart();
    this.users();
    this.getData();


  },
  created() {

    // this.showStats()
  },
  methods: {
    ...mapActions("dashboard", ["getReport"]),
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },

    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },

    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      // set current active tab
      event.target.classList.add("active");
      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    users() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/user/report?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.loading = false;
            this.doctors = res.data.data.user.doctor;
            this.agents = res.data.data.user.agent;
            this.patients = res.data.data.user.patient;
          })
    },

    getData() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/user/pie/chart?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {

            if (res.data.status_code == 400) {
              this.pieData.datasets[0].data = [];
              this.pieData.datasets[0].data = [];
            } else {
              var result = [];
              var user_type = [];
              res.data.map(item => {
                result.push(item.total);
                user_type.push(item.user_type);
              })

              this.pieData.datasets[0].data = [];
              this.pieData.datasets[0].data = result;
              this.pieData.labels = [];
              this.pieData.labels = user_type;
              this.pie_loading = true;
            }
          })
    },
    getAppintmentPieChart() {
      this.pie_appointment_loading = false;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/appointment/pie/chart?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
                if (res.data.status_code == 400) {
                  this.appointmentData.datasets[0].data = [];
                } else {
                  var appoitment_result = [];
                  var appointment_type = [];
                  res.data.map(item => {
                    appoitment_result.push(item.total);
                    appointment_type.push(item.appointment_type);
                  })
                  this.appointmentData.datasets[0].data = [];
                  this.appointmentData.datasets[0].data = appoitment_result;
                  this.appointmentData.labels = [];
                  this.appointmentData.labels = appointment_type;
                  this.pie_appointment_loading = true;
                }

              }
          )
    },
    getPrescriptionPieChart() {
      this.pie_prescription_loading = false;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/prescription/pie/chart?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
                if (res.data.status_code == 400) {
                  this.prescriptionData.datasets[0].data = [];
                } else {
                  var prescription_result = [];
                  // var user_type = [];
                  res.data.map(item => {
                    prescription_result.push(item.total);
                    // user_type.push(item.status);
                  })
                  this.prescriptionData.datasets[0].data = [];
                  this.prescriptionData.datasets[0].data = prescription_result;
                  // this.prescriptionData.labels = [];
                  // this.prescriptionData.labels = user_type;
                  this.pie_prescription_loading = true;
                }
          })
    }
    ,

    getChartData() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/user/bopanel/statistics?api_token=${localStorage.getItem('api_token')}&str_year=2021`)
          .then(res => {

            this.doctor_chart = res.data.data.filter(item => item.name == 'doctor');
            this.patient_chart = res.data.data.filter(item => item.name == 'patient');
            this.agent_chart = res.data.data.filter(item => item.name == 'agent-pharmacy');
          });

    }
    ,

    // showStats() {
    //   this.showChart = true;
    //   let rd = Math.floor(Math.random() * 10 + 1);
    //   this.pieData.datasets[0].data = [];
    //   this.pieData.datasets[0].data = [5, 8, 2];
    // },
  }
};
</script>
<style>
.nav .nav-link {
  padding: 0.75rem .6rem !important;
}
</style>