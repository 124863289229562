import Vue from "vue";
import Router from "vue-router";
import BiGeneralReport from "./components/reports/BiGeneralReport";
import FinancialReport from "./components/reports/FinancialReport";
import NotificationType from "./components/notification/NotificationType";
import NotificationTemplate from "./components/notification/NotificationTemplate";
import NotificationSend from "./components/notification/NotificationSend";
import Prescriptions from "./components/prescriptions/Prescriptions";
import BankList from "./components/bank/BankList";
import InstituteType from "./components/institute/InstituteType";
import NatureOfService from "./components/natureOfService/NatureOfService";
import Institute from "./components/institute/Institute";
import FaqType from "./components/faq/FaqType";
import Specialities from "./components/symptomps/Specialities";
import Symptomps from "./components/symptomps/Symptomps";
import WithdrawalRequests from "./components/payment/WithdrawalRequests";
import DisbursedList from "./components/payment/DisbursedList";
import ConnectionRequestPendingList from "./components/reports/ConnectionRequestPendingList";
import CallLogDetails from "./components/crm/CallLogDetails";
import Appointment from "./components/appointments/Appointment";
import AppointmentList from "./components/appointments/AppointmentList";
import FollowUpList from "./components/appointments/FollowUpList";
import MedicalRecord from "./components/prescriptions/MedicalRecord";
import FeedbackAnalysisReport from "./components/reports/FeedbackAnalysisReport";
import LocalityAnalysis from "./components/reports/LocalityAnalysis";
import NatureOfServiceReport from "./components/reports/NatureOfServiceReport";
import MedicineClass from "./components/medicine/MedicineClass";
import MedicineGenerics from "./components/medicine/MedicineGenerics";
import Medicine from "./components/medicine/Medicine";
import MedicineCompany from "./components/medicine/MedicineCompany";
import SubscriptionOffer from "./components/subscription/SubscriptionOffer";
import SubscriptionPackage from "./components/subscription/SubscriptionPackage";
import SubscriptionFeature from "./components/subscription/SubscriptionFeature";
import AppointmentHistory from "./components/appointments/AppointmentHistory";
import AppointByHistory from "./components/appointments/AppointmentByHistory";
import AssignPermissionsByRole from "./components/users/permissions/AssignPermissionsByRole";
import DoctorWisePrescriptionList from "./components/prescriptions/DoctorWisePrescriptionList";
import PerDoctorWisePrescription from "./components/prescriptions/PerDoctorWisePrescription";
import SubscriberUsers from "./components/subscription/SubscriberUsers";
import PatientWithDrawalList from "./components/payment/PatientWithDrawalList";
import OrkoTransactionsReport from "./components/reports/OrkoTransactionsReport";
import Advice from "./components/advice/Advice";
import Investigation from "./components/investigation/Investigation";
import OtpListt from "./components/users/OtpList";
import NotificationList from "./components/notification/NotificationList";
import CustomNotificationList from "./components/notification/CustomNotificationList";
import UserDetails from "./components/users/UserDetails";
import Dashboard from "./view/pages/Dashboard";
import OrkoUsers from "./components/users/OrkoUsers";
import DoctorList from "./components/users/doctor/DoctorList";
import BackofficeNotificationList from "./components/backofficeNotification/BackofficeNotificationList";
import InvestigationService from "./components/investigation/investigationService/InvestigationService";
import UserStatement from "./components/reports/UserStatement";
import DoctorTransactionStatement from "./components/users/doctor/DoctorTransactionStatement";
import QuestionariesGroup from "./components/questionaries/QuestionariesGroup";
import Questionaries from "./components/questionaries/Questionaries";
import FinancialStatement from "./components/reports/FinancialStatement";
import ChatBotQuery from "./components/chat/ChatBotQuery";
import TransactionStatementPdfWithEmpty from "./components/users/doctor/TransactionStatementPdfWithEmpty";
import OrkoProfession from "./components/profession/OrkoProfession";
import ManageProduct from "./components/product/ManageProduct";
import ManageProductPermissions from "./components/product/ManageProductPermissions";
import UserProduct from "./components/product/UserProduct";
import ProductUsers from "./components/product/ProductUsers";
import MedicineGenericsDetails from "./components/medicine/MedicineGenericsDetails";
import ManageDynogonestic from "./components/orko-partner/ManageDynogonestic";
import ManagePharmaceuticals from "./components/orko-partner/ManagePharmaceuticals";
import ManagePermissions from "./components/users/ManagePermissions";
import ManageTherapy from "./components/orko-partner/ManageTherapy";
import ManageDiagnosis from "./components/orko-partner/ManageDiagnosis";
import CenterWisePatientList from "./components/reports/CenterWisePatientList";
import CenterPrescriptionList from "./components/reports/CenterPrescriptionList";
import CenterWiseIncome from "./components/reports/CenterWiseIncome";
import MedicineDetails from "./components/medicine/MedicineDetails";
import DoctorTransactionHistoryList from "./components/users/doctor/DoctorTransactionHistoryList";
import CheafComplements from "./components/cheafComplements/CheafComplements";
import AreaWiseDoctorChart from "./components/reports/AreaWiseDoctorChart";
import CityWiseDoctorList from "./components/reports/CityWiseDoctorList";
import Organization from "./components/organization/Organization";
import SalesActivityList from "./components/sales/SalesActivityList";
import SalesActivityLogs from "./components/sales/SalesActivityLogs";
import SalesTeamLog from "./components/sales/SalesTeamLog";
import SalesDailyReports from "./components/sales/SalesDailyReports";
import SalesDashboard from "./components/sales/SalesDashboard";
import SalesActivityDoctorList from "./components/sales/SalesActivityDoctorList";
import PendingReportPatientList from "./components/crm/PendingReportPatientList";
import UnverifiedDoctor from "./components/users/doctor/UnverifiedDoctor";
import SalesActivitySeal from "./components/sales/SalesActivitySeal";
import SalesCallLog from "./components/sales/SalesCallLog";
import MedicineGenericAdvice from "./components/advice/MedicineGenericAdvice";
import PharmacySurvey from "./components/sales/PharmacySurvey";
import ShortCutMenu from "./components/reusable-component/ShortCutMenu";
import SocialNetworkCallLog from "./components/crm/SocialNetworkCallLog";
import SalesCallTarget from "./components/sales/SalesCallTarget";
import SalesPrescriptionTarget from "./components/sales/SalesPrescriptionTarget";
import UserMovementTracking from "./components/users/doctor/UserMovementTracking";
import UserMovementTrackingDetail from "./components/users/doctor/UserMovementTrackingDetail";
import OutboundCalls from "./components/crm/OutboundCalls";
import SalesCallTargetReport from "./components/sales/SalesCallTargetReport";
import SalesPrescriptionTargetReport from "./components/sales/SalesPrescriptionTargetReport";
import CrmCallDetails from "./components/crm/CrmCallDetails";
import Messages from "./components/message/Messages";
import InstituteTherapies from "./components/institute/InstituteTherapies";
import DoctorSalesActivityCall from "./components/users/doctor/DoctorSalesActivityCall";
import MedicalExamination from "./components/examination/MedicalExamination";
import AuditForm from "./components/auditReports/AuditForm";
import AuditList from "./components/auditReports/AuditList";
import AuditView from "./components/auditReports/AuditView";
// import Dashbaord from "./components/dashboard/Dashbaord";
// import OCCReportView from "./view/pages/new/reports/occ";

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: "/dashboard",
            component: () => import("@/view/layout/Layout"),
            meta: true,
            children: [
                {
                    path: "/access-denied",
                    name: "access-denied",
                    component: () => import("./components/reusable-component/AccessDenied"),
                    meta: {
                        breadCrumb: '404'
                    }
                },
                {
                    path: "/dashboard/old",
                    name: "dashboard",
                    component: Dashboard,
                    meta: {
                        auth: true,
                        breadCrumb: 'Dashboard'
                    }
                },
                {
                    path: "/dashboard",
                    name: "OrkoDashboard",
                    component: () => import("./view/pages/new/dashboard/OrkoDashboard"),
                    meta: {
                        auth: true,
                        breadCrumb: 'ORKO Dashboard'
                    }
                },
                {
                    path: "/doctor-users",
                    name: "doctor",
                    component: DoctorList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctors'
                    }
                },
                {
                    path: "/unverified/doctors",
                    name: "Unverified Doctor",
                    component: UnverifiedDoctor,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctors'
                    }
                },
                {
                    path: "/user/points/:id",
                    name: "points",
                    component: UserDetails,
                    meta: {
                        auth: true,
                        breadCrumb: 'User details'
                    }
                },
                {
                    path: "/patient-users",
                    name: "patient",
                    component: () => import("./components/users/patient/List"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Patient'
                    }
                },
                {
                    path: "/patient/prescription/:id",
                    name: "patient-prescription",
                    component: () => import("./components/users/patient/Prescription"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Patient'
                    }
                },
                {
                    path: "/user/filter",
                    name: "user-filter",
                    component: () => import("./components/users/UserFilter"),
                    meta: {
                        auth: true,
                        breadCrumb: 'User filter'
                    }
                },
                {
                    path: "/patient/documents/:id",
                    name: "patient-document",
                    component: () => import("./components/users/patient/Documents"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Patient'
                    }
                },
                {
                    path: "/agent-pharmacy",
                    name: "agent-pharmacy",
                    component: OrkoUsers,
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent pharmacy'
                    }
                },
                {
                    path: "/agent",
                    name: "agent",
                    component: OrkoUsers,
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent'
                    }
                },
                {
                    path: "/agent_sales",
                    name: "agent_sales",
                    component: OrkoUsers,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales'
                    }
                },
                {
                    path: "/sales/dashboard",
                    name: "Sales Dashboard",
                    component: SalesDashboard,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Dashboard'
                    }
                },
                {
                    path: "/sales/daily/reports",
                    name: "Sales Daily Reports",
                    component: SalesDailyReports,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Activity'
                    }
                },
                {
                    path: "/sales/activity",
                    name: "Sales Activity",
                    component: SalesActivityList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Activity'
                    }
                },
                {
                    path: "/sales/activity-seal",
                    name: "Sales Activity Seal",
                    component: SalesActivitySeal,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Activity Seal'
                    }
                },
                {
                    path: "/sales/call/log",
                    name: "Sales Call Log",
                    component: SalesCallLog,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Call Log'
                    }
                },
                {
                    path: "/sales/pharmacy/survey",
                    name: "Pharmacy Survey",
                    component: PharmacySurvey,
                    meta: {
                        auth: true,
                        breadCrumb: 'Pharmacy Survey'
                    }
                },
                {
                    path: "/sales-activities",
                    name: "Sales Activity Doctor List",
                    component: SalesActivityDoctorList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Activity Doctor List'
                    }
                },
                {
                    path: "/sales/activity-logs",
                    name: "Sales Activity Logs",
                    component: SalesActivityLogs,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Activity Logs'
                    }
                },
                {
                    path: "/sales/activity-reports",
                    name: "Sales Activity",
                    component: SalesActivityList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Activity'
                    }
                },
                {
                    path: "/sales/activity/:id",
                    name: "Sales Activity",
                    component: SalesActivityList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Activity'
                    }
                },
                {
                    path: "/sales/team/log",
                    name: "Sales Team Log",
                    component: SalesTeamLog,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Activity'
                    }
                },
                {
                    path: "/agent-wise-patient-list/:id",
                    name: "agent-wise-patient-list",
                    component: () => import("./components/users/agent/PatientList"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise patient list'
                    }
                },
                {
                    path: "/agent-wise-sub-agent-list/:id",
                    name: "agent-wise-sub-agent-list",
                    component: () => import("./components/users/agent/SubAgentList"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise sub agent list'
                    }
                },
                {
                    path: "/agent-wise/doctor-search/:id",
                    name: "agent-doctor-search",
                    component: () => import("./components/users/agent/DoctorSearch"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agents doctor'
                    }
                },
                {
                    path: "/agent-wise-doctors-list/:id",
                    name: "agent-wise-doctors-list",
                    component: () => import("./components/users/agent/DoctorList"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise doctors list'
                    }
                },
                {
                    path: "/agent-wise-prescription-list/:id",
                    name: "agent-wise-prescription-list",
                    component: () => import("./components/users/agent/PrescriptionList"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise prescription list'
                    }
                },
                {
                    path: "/agent-wise-transactions-list/:id",
                    name: "agent-wise-transactions-list",
                    component: () => import("./components/users/agent/TransactionHistoryList"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise transactions list'
                    }
                },
                {
                    path: "/agent-wise-statements/:id",
                    name: "agent-wise-statements",
                    component: () => import("./components/users/agent/TransactionStatementAgent"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise statements'
                    }
                },
                {
                    path: "/agent-pharmacy-wise-statements/:id",
                    name: "agent-pharmacy-wise-statements",
                    component: () => import("./components/users/agent/TransactionStatement"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise statements'
                    }
                },
                {
                    path: "/edit/agent/:id",
                    name: "edit_agent",
                    component: () => import("./components/users/agent/EditAgent"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Edit agent'
                    }
                },
                {
                    path: "/users",
                    name: "users",
                    component: () => import("./components/users/Index"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Executive users'
                    }
                },
                {
                    path: "/nurses",
                    name: "nurses",
                    component: () => import("./components/users/Nurse"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Nurse users'
                    }
                },
                {
                    path: "/cashiers",
                    name: "cashiers",
                    component: () => import("./components/users/Cashier"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Cashier users'
                    }
                },
                {
                    path: "/aide",
                    name: "aide",
                    component: () => import("./components/users/aide/Index"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Aide users'
                    }
                },
                {
                    path: "/user/details/:id",
                    name: "user-details",
                    component: UserDetails,
                    meta: {
                        auth: true,
                        breadCrumb: 'User details',
                    }
                },
                {
                    path: "/roles",
                    name: "roles",
                    component: () => import("./components/users/Roles"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Roles',
                    }
                },
                {
                    path: "/roles/show/:id",
                    name: "roles-show",
                    component: () => import("./components/users/RoleDetails"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Role details',
                    }
                },
                {
                    path: "/assign-permissions",
                    name: "assign-permissions",
                    component: () => import("./components/users/AssignPermissions"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Permissions assign',
                    }
                },
                {
                    path: "/assign-permissions/:id",
                    name: "assign-permissions-role",
                    component: AssignPermissionsByRole,
                    meta: {
                        auth: true,
                        breadCrumb: 'Permission details',
                    }
                },
                {
                    path: "/permissions",
                    name: "permissions",
                    component: () => import("./components/users/Permissions"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Permission'
                    }
                },
                {
                    path: "/assign-roles",
                    name: "assign-roles",
                    component: () => import("./components/users/AssignRoles"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Assign role'
                    }
                },
                {
                    path: "/profile",
                    name: "profile",
                    component: () => import("./components/users/Profile"),
                    meta: {
                        auth: true,
                        breadCrumb: 'User profile'
                    }
                },

                // level
                {
                    path: "/level",
                    name: "level",
                    component: () => import("./components/users/level/Level"),
                    meta: {
                        auth: true,
                        breadCrumb: 'Level'
                    }
                },

                //    Reports Routes
                {
                    path: "/general-bi-overview",
                    name: "general-bi-overview",
                    component: BiGeneralReport,
                    meta: {
                        auth: true,
                        breadCrumb: 'General bi overview'
                    }
                },
                {
                    path: "/transaction-report",
                    name: "transaction-report",
                    component: OrkoTransactionsReport,
                    meta: {
                        auth: true,
                        breadCrumb: 'Transaction report'
                    }
                },
                {
                    path: "/financial-report",
                    name: "financial-report",
                    component: FinancialReport,
                    meta: {
                        auth: true,
                        breadCrumb: 'Financial report'
                    }
                },
                {
                    path: "/financial-statement",
                    name: "financial-statement",
                    component: FinancialStatement,
                    meta: {
                        auth: true,
                        breadCrumb: 'Financial statement'
                    }
                },
                {
                    path: "/connection-request-pending-list",
                    name: "connection-request-pending-list",
                    component: ConnectionRequestPendingList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Connection request pending list'
                    }
                },
                {
                    path: "/feedback-analysis-report",
                    name: "feedback-analysis-report",
                    component: FeedbackAnalysisReport,
                    meta: {
                        auth: true,
                        breadCrumb: 'Feedback analysis report'
                    }
                },
                {
                    path: "/locality-analysis-report",
                    name: "locality-analysis-report",
                    component: LocalityAnalysis,
                    meta: {
                        auth: true,
                        breadCrumb: 'Locality analysis report'
                    }
                },
                {
                    path: "/nature-of-service-report",
                    name: "nature-of-service-report",
                    component: NatureOfServiceReport,
                    meta: {
                        auth: true,
                        breadCrumb: 'Nature of service report'
                    }
                },
                {
                    path: "/centre-wise-patient-list",
                    name: "Center wise patient list",
                    component: CenterWisePatientList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Center wise patient list'
                    }
                },
                {
                    path: "/center-wise-income",
                    name: "Center wise income",
                    component: CenterWiseIncome,
                    meta: {
                        auth: true,
                        breadCrumb: 'Center wise income'
                    }
                },
                {
                    path: "/center-wise-prescription-list",
                    name: "Center wise prescription list",
                    component: CenterPrescriptionList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Center wise prescription list'
                    }
                },
                {
                    path: "/user-statement",
                    name: "user-statement",
                    component: UserStatement,
                    meta: {
                        auth: true,
                        breadCrumb: 'User statement'
                    }
                },
                {
                    path: "/area-wise-doctor",
                    name: "area wise doctor",
                    component: AreaWiseDoctorChart,
                    meta: {
                        auth: true,
                        breadCrumb: 'area wise doctor'
                    }
                },
                {
                    path: "/city-wise-doctor/:id",
                    name: "City wise doctor list",
                    component: CityWiseDoctorList,
                    meta: {
                        auth: true,
                        breadCrumb: 'area wise doctor'
                    }
                },
                {
                    path: "/organizations/report",
                    name: "organization",
                    component: Organization,
                    meta: {
                        auth: true,
                        breadCrumb: 'area wise doctor'
                    }
                },

                //    Notification template
                {
                    path: "/notification-type",
                    name: "notification-type",
                    component: NotificationType,
                    meta: {
                        auth: true,
                        breadCrumb: 'Notification type'
                    }
                },
                {
                    path: "/notification-template",
                    name: "notification-template",
                    component: NotificationTemplate,
                    meta: {
                        auth: true,
                        breadCrumb: 'Notification template'
                    }
                },
                {
                    path: "/notification-send",
                    name: "notification-send",
                    component: NotificationSend,
                    meta: {
                        auth: true,
                        breadCrumb: 'Notification send'
                    }
                },
                {
                    path: "/notification-list",
                    name: "notification-list",
                    component: NotificationList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Notification list'
                    }
                },
                {
                    path: "/custom-notification-list",
                    name: "custom-notification-list",
                    component: CustomNotificationList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Notifications'
                    }
                },

                //    Prescription

                {
                    path: "/prescriptions",
                    name: "prescriptions",
                    component: Prescriptions,
                    meta: {
                        auth: true,
                        breadCrumb: 'Prescription'
                    }
                },
                {
                    path: "/doctor-wise-prescription-list",
                    name: "doctor-wise-prescriptions",
                    component: DoctorWisePrescriptionList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctor wise prescription list'
                    }
                },
                {
                    path: "/doctor-prescription/:id",
                    name: "doctor-prescriptions",
                    component: PerDoctorWisePrescription,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctor wise prescription list'
                    }
                },
                {
                    path: "/doctor/transaction/statement/:id",
                    name: "doctor-transaction-statement",
                    component: DoctorTransactionStatement,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctor transaction statement'
                    }
                },
                {
                    path: "/doctor/transaction/history/:id",
                    name: "doctor-transaction-history",
                    component: DoctorTransactionHistoryList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctor transaction history'
                    }
                },
                {
                    path: "/medical-records",
                    name: "medical-records",
                    component: MedicalRecord,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medical records'
                    }
                },

                //    Bank
                {
                    path: "/bank",
                    name: "bank",
                    component: BankList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Bank'
                    }
                },
                //    Institute
                {
                    path: "/institute-type",
                    name: "institute-type",
                    component: InstituteType,
                    meta: {
                        auth: true,
                        breadCrumb: 'Institute type'
                    }
                },
                {
                    path: "/institute",
                    name: "institute",
                    component: Institute,
                    meta: {
                        auth: true,
                        breadCrumb: 'Institute'
                    }
                },
                {
                    path: "/medicine/generic/advices",
                    name: "Medicine Generic Advice",
                    component: MedicineGenericAdvice,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medicine Generic Advice'
                    }
                },
                //    Nature of service
                {
                    path: "/nature-of-service",
                    name: "nature-of-service",
                    component: NatureOfService,
                    meta: {
                        auth: true,
                        breadCrumb: 'Nature of service'
                    }
                },

                //    Faq
                {
                    path: "/faq-type",
                    name: "faq-type",
                    component: FaqType,
                    meta: {
                        auth: true,
                        breadCrumb: 'Faq type'
                    }
                },
                {
                    path: "/faq",
                    name: "faq",
                    component: FaqType,
                    meta: {
                        auth: true,
                        breadCrumb: 'Faq type'
                    }
                },
                //    Symptomps
                {
                    path: "/orkospecialities",
                    name: "orkospecialities",
                    component: Specialities,
                    meta: {
                        auth: true,
                        breadCrumb: 'Specialities'
                    }
                },
                {
                    path: "/orkospecialitywisesymptomps",
                    name: "orkospecialitywisesymptomps",
                    component: Symptomps,
                    meta: {
                        auth: true,
                        breadCrumb: 'Symptoms'
                    }
                },

                //    payment
                {
                    path: "/withdrawal/requests",
                    name: "withdrawal-requests",
                    component: WithdrawalRequests,
                    meta: {
                        auth: true,
                        breadCrumb: 'Withdrawal request'
                    }
                },
                {
                    path: "/withdrawal/disbursed/list",
                    name: "disbursed-list",
                    component: DisbursedList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Disbursed list'
                    }
                },
                {
                    path: "/patient/withdrawal/list",
                    name: "patient-withdrawal-list",
                    component: PatientWithDrawalList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Patient withdrawal list'
                    }
                },

                //    Crm

                {
                    path: "/call-log",
                    name: "call-log",
                    component: CallLogDetails,
                    meta: {
                        auth: true,
                        breadCrumb: 'Call log',
                    }
                },
                {
                    path: "/crm/social/call/log",
                    name: "crm social call log",
                    component: SocialNetworkCallLog,
                    meta: {
                        auth: true,
                        breadCrumb: 'crm social call log',
                    }
                },
                {
                    path: "/report/pending/patient/list",
                    name: "report pending patient list",
                    component: PendingReportPatientList,
                    meta: {
                        auth: true,
                        breadCrumb: 'report pending patient list',
                    }
                },
                {
                    path: "/audit/form",
                    name: "audit form",
                    component: AuditForm,
                    meta: {
                        auth: true,
                        breadCrumb: 'audit form',
                    }
                },
                {
                    path: "/audit/form/update/:id",
                    name: "audit form update",
                    component: AuditForm,
                    meta: {
                        auth: true,
                        breadCrumb: 'audit form update',
                    }
                },
                {
                    path: "/audit/list",
                    name: "audit list",
                    component: AuditList,
                    meta: {
                        auth: true,
                        breadCrumb: 'audit list',
                    }
                },
                {
                    path: "/audit/application/view/:id",
                    name: "audit view",
                    component: AuditView,
                    meta: {
                        auth: true,
                        breadCrumb: 'audit view',
                    }
                },
                //    Appointment
                {
                    path: "/book-appointment",
                    name: "book-appointment",
                    component: Appointment,
                    meta: {
                        auth: true,
                        breadCrumb: 'Book appointments',
                    }
                },
                {
                    path: "/appointment-list",
                    name: "appointment-list",
                    component: AppointmentList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Appointment list',
                    }
                },
                {
                    path: "/follow-up-appointment-list",
                    name: "follow-up-appointment-list",
                    component: FollowUpList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Follow up list',
                    }
                },
                {
                    path: "/appointment-history",
                    name: "appointment-history",
                    component: AppointmentHistory,
                    meta: {
                        auth: true,
                        breadCrumb: 'Appointment history',
                    }
                },
                {
                    path: "/appointment-history/:id",
                    name: "appointment-history-details",
                    component: AppointByHistory,
                    meta: {
                        auth: true,
                        breadCrumb: 'Appointment history details',
                    }
                },

                //    Medicine
                {
                    path: "/medicine-company",
                    name: "Medicine company",
                    component: MedicineCompany,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medicine company'
                    }
                },
                {
                    path: "/medicine-class",
                    name: "Medicine Class",
                    component: MedicineClass,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medicine class',
                    }
                },
                {
                    path: "/medicine-generics",
                    name: "Medicine generics",
                    component: MedicineGenerics,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medicine generics',
                    }
                },
                {
                    path: "/medicine-generics/:id",
                    name: "Medicine generics",
                    component: MedicineGenericsDetails,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medicine generics',
                    }
                },
                {
                    path: "/medicine",
                    name: "medicine",
                    component: Medicine,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medicine',
                    }
                },
                {
                    path: "/medicine",
                    name: "medicine",
                    component: Medicine,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medicine',
                    }
                },
                {
                    path: "/medicine/details/:id",
                    name: "Medicine Details",
                    component: MedicineDetails,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medicine',
                    }
                },
                {
                    path: "/manage-diagnostic",
                    name: "diagnostic",
                    component: ManageDynogonestic,
                    meta: {
                        auth: true,
                        breadCrumb: 'diagnostic',
                    }
                },
                {
                    path: "/pharmaceuticals",
                    name: "pharmaceuticals",
                    component: ManagePharmaceuticals,
                    meta: {
                        auth: true,
                        breadCrumb: 'pharmaceuticals',
                    }
                },
                {
                    path: "/therapy",
                    name: "therapy",
                    component: ManageTherapy,
                    meta: {
                        auth: true,
                        breadCrumb: 'therapy',
                    }
                },
                {
                    path: "/diagnosis",
                    name: "diagnosis",
                    component: ManageDiagnosis,
                    meta: {
                        auth: true,
                        breadCrumb: 'diagnostic',
                    }
                },

                //    Subscription
                {
                    path: "/subscription-offer",
                    name: "Subscription offer",
                    component: SubscriptionOffer,
                    meta: {
                        auth: true,
                        breadCrumb: 'Subscription offer'
                    }
                },

                {
                    path: "/subscription-package",
                    name: "Subscription package",
                    component: SubscriptionPackage,
                    meta: {
                        auth: true,
                        breadCrumb: 'Subscription package'
                    }
                },

                {
                    path: "/subscription-feature",
                    name: "Subscription feature",
                    component: SubscriptionFeature,
                    meta: {
                        auth: true,
                        breadCrumb: 'Subscription feature'
                    }
                },

                {
                    path: "/subscriber-users",
                    name: "Subscriber users",
                    component: SubscriberUsers,
                    meta: {
                        auth: true,
                        breadCrumb: 'Subscriber users'
                    }
                },

                //    miscellaneous
                {
                    path: "/advice",
                    name: "advice",
                    component: Advice,
                    meta: {
                        auth: true,
                        breadCrumb: 'Advice'
                    }
                },
                {
                    path: "/investigation",
                    name: "investigation",
                    component: Investigation,
                    meta: {
                        auth: true,
                        breadCrumb: 'Investigation'
                    }
                },
                {
                    path: "/investigation-service",
                    name: "investigation-service",
                    component: InvestigationService,
                    meta: {
                        auth: true,
                        breadCrumb: 'Investigation service'
                    }
                },
                {
                    path: "/symptoms",
                    name: "symptoms",
                    component: Symptomps,
                    meta: {
                        auth: true,
                        breadCrumb: 'Symptoms'
                    }
                },
                {
                    path: "/otp/list",
                    name: "otp",
                    component: OtpListt,
                    meta: {
                        auth: true,
                        breadCrumb: 'Otp'
                    }
                },
                {
                    path: "/backoffice/notification/list",
                    name: "backoffice-notification-list",
                    component: BackofficeNotificationList,
                    meta: {
                        auth: true,
                        breadCrumb: 'Notification list'
                    }
                },
                {
                    path: "/questionaries/group",
                    name: "questionaries-group",
                    component: QuestionariesGroup,
                    meta: {
                        auth: true,
                        breadCrumb: 'Questionaries group'
                    }
                },
                {
                    path: "/questionaries",
                    name: "questionaries",
                    component: Questionaries,
                    meta: {
                        auth: true,
                        breadCrumb: 'Questionaries'
                    }
                },
                {
                    path: "/chat-bot-query",
                    name: "chat-bot-query",
                    component: ChatBotQuery,
                    meta: {
                        auth: true,
                        breadCrumb: 'Chat bot query'
                    }
                },
                {
                    path: "/orko-profession",
                    name: "orko-profession",
                    component: OrkoProfession,
                    meta: {
                        auth: true,
                        breadCrumb: 'Orko profession'
                    }
                },
                {
                    path: "/statement-pdf",
                    name: "statement-pdf",
                    component: TransactionStatementPdfWithEmpty,
                    meta: {
                        auth: true,
                        breadCrumb: 'statement-pdf'
                    }
                },
                {
                    path: "/products",
                    name: "product",
                    component: ManageProduct,
                    meta: {
                        auth: true,
                        breadCrumb: 'product'
                    }
                },
                {
                    path: "/product/permissions/:id",
                    name: "product-permissions",
                    component: ManageProductPermissions,
                    meta: {
                        auth: true,
                        breadCrumb: 'product'
                    }
                },
                {
                    path: "/user/product",
                    name: "user-product",
                    component: UserProduct,
                    meta: {
                        auth: true,
                        breadCrumb: 'user product'
                    }
                },
                {
                    path: "/product/users",
                    name: "product-users",
                    component: ProductUsers,
                    meta: {
                        auth: true,
                        breadCrumb: 'user product'
                    }
                },
                {
                    path: "/manage-permissions",
                    name: "manage-permissions",
                    component: ManagePermissions,
                    meta: {
                        auth: true,
                        breadCrumb: 'manage permission'
                    }
                },
                {
                    path: "/chief-complaints",
                    name: "chief-complaints",
                    component: CheafComplements,
                    meta: {
                        auth: true,
                        breadCrumb: 'chief complaints'
                    }
                },
                {
                    path: "/menu/short-cut",
                    name: "Menu shortcut",
                    component: ShortCutMenu,
                    meta: {
                        auth: true,
                        breadCrumb: 'Menu shortcut'
                    }
                },
                {
                    path: "/sales/call/target",
                    name: "Sales Call Target",
                    component: SalesCallTarget,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Call Target'
                    }
                },
                {
                    path: "/sales/prescription/target",
                    name: "Sales Prescription Target",
                    component: SalesPrescriptionTarget,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Prescription Target'
                    }
                },
                {
                    path: "/doctor/movement/tracking",
                    name: "Doctor movement tracking",
                    component: UserMovementTracking,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctor movement tracking'
                    }
                },
                {
                    path: "/doctor/movement/tracking/:doctorId",
                    name: "Doctor movement tracking details",
                    component: UserMovementTrackingDetail,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctor movement tracking details'
                    }
                },
                {
                    path: "/crm/outbound/calls",
                    name: "CRM Outbound Calls",
                    component: OutboundCalls,
                    meta: {
                        auth: true,
                        breadCrumb: 'CRM Outbound Calls'
                    }
                },
                {
                    path: "/crm/outbound/calls/:id",
                    name: "CRM Outbound Calls Details",
                    component: CrmCallDetails,
                    meta: {
                        auth: true,
                        breadCrumb: 'CRM Call Details'
                    }
                },
                {
                    path: "/sales/call/target-reports",
                    name: "Sales Target Report",
                    component: SalesCallTargetReport,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Target Report'
                    }
                },
                {
                    path: "/sales/prescription/target-reports",
                    name: "Sales Prescription Target Report",
                    component: SalesPrescriptionTargetReport,
                    meta: {
                        auth: true,
                        breadCrumb: 'Sales Prescription Target Report'
                    }
                },
                {
                    path: "/messages",
                    name: "messages",
                    component: Messages,
                    meta: {
                        auth: true,
                        breadCrumb: 'messages'
                    }
                },
                {
                    path: "/institute/therapies/:id",
                    name: "Institute Therapies",
                    component: InstituteTherapies,
                    meta: {
                        auth: true,
                        breadCrumb: 'messages'
                    }
                },
                {
                    path: "/sales/activity/call/by/doctor",
                    name: "Doctor sales activity call",
                    component: DoctorSalesActivityCall,
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctor sales activity call'
                    }
                },
                {
                    path: "/medical/examinations",
                    name: "medical examination",
                    component: MedicalExamination,
                    meta: {
                        auth: true,
                        breadCrumb: 'Medical examination'
                    }
                },
                {
                    path: "/center-wise-dashboard",
                    name: "center wise  dashboard",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/occ/Dashboard'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Center wise dashboard'
                    }
                },

                {
                    path: "/center-wise-patient-list",
                    name: "center wise  patient list",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/occ/CenterWisePatientList'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Center wise patient list'
                    }
                },
                {
                    path: "/service-wise-report",
                    name: "service wise report",
                    component: () => import(/* webpackChunkName: "ServiceWiseReport" */'../src/components/occ/ServiceWiseReport'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Service wise report'
                    }
                },
                {
                    path: "/center-wise-appointment",
                    name: "service wise appointments",
                    component: () => import(/* webpackChunkName: "ServiceWiseReport" */'../src/components/occ/AppointmentWiseReport'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Service wise report'
                    }
                },
                {
                    path: "/occ/target",
                    name: "occ target",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/occ/OrkoCenterTarget'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC target'
                    }
                },
                {
                    path: "/occ/team/target",
                    name: "occ team target",
                    component: () => import(/* webpackChunkName: "OrkoTeamTarget" */'../src/components/occ/OrkoTeamTarget'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC Team target'
                    }
                },
                {
                    path: "/call/center/target",
                    name: "call center target",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/occ/CallCenterTargets'),
                    meta: {
                        auth: true,
                        breadCrumb: 'call center target'
                    }
                },
                {
                    path: "/service/feedbacks",
                    name: "service feedbacks",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/occ/ServiceFeedbacks'),
                    meta: {
                        auth: true,
                        breadCrumb: 'service feedbacks'
                    }
                },
                {
                    path: "/agent-wise-patient",
                    name: "Agent wise patient",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/AgentWisePatient'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise patient'
                    }
                },
                {
                    path: "/occ/agent-wise-patient-list/:id",
                    name: "Agent wise patient list",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/AgentWisePatientList'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise patient list'
                    }
                },
                {
                    path: "/occ/center/template",
                    name: "OCC center template",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/occ/CenterTemplate'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Agent wise patient list'
                    }
                },
                {
                    path: "/employee-referral-list",
                    name: "Employee referral list",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/EmployeeReferralList'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Employee referral list'
                    }
                },
                {
                    path: "/employee/:id/referral-patients",
                    name: "Referral patients by employee",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/ReferralPatientByEmployee'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Referral patients by employee'
                    }
                },
                {
                    path: "/report/invoice/list",
                    name: "invoice list",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/InvoiceList'),
                    meta: {
                        auth: true,
                        breadCrumb: 'invoice list'
                    }
                },
                {
                    path: "/report/doctor-referral/list",
                    name: "Doctor Referral list",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/DoctorReferral'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Doctor Referral List'
                    }
                },
                {
                    path: "/condition-we-treat",
                    name: "invoice list",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/conditionWeTreat/ConditionWeTreat'),
                    meta: {
                        auth: true,
                        breadCrumb: 'invoice list'
                    }
                },
                {
                    path: "/blogs/category",
                    name: "Blogs category",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/blogs/BlogCategory'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Blogs category'
                    }
                },
                {
                    path: "/blogs/posts",
                    name: "Blogs posts",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/blogs/BlogPosts'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Blogs posts'
                    }
                },
                {
                    path: "/blogs/posts/make",
                    name: "Blogs posts",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/blogs/CreateEditPost'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Blogs posts'
                    }
                },
                {
                    path: "/blog/post/edit/:slug",
                    name: "Blogs posts",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/blogs/CreateEditPost'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Blogs posts'
                    }
                },
                {
                    path: "/manage/services",
                    name: "Services",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/services/Services'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Services'
                    }
                },
                {
                    path: "/donors",
                    name: "donors",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/components/donors/Donors'),
                    meta: {
                        auth: true,
                        breadCrumb: 'donors'
                    }
                },
                {
                    path: "/hvf/target",
                    name: "hvf target",
                    component: () => import(/* webpackChunkName: "hvfExport" */'../src/view/pages/new/hvf/Targets'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf target'
                    }
                },
                {
                    path: "/hvf/target/create",
                    name: "hvf target create",
                    component: () => import(/* webpackChunkName: "hvfExport" */'../src/view/pages/new/hvf/TargetAddEditForm'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf create'
                    }
                },
                {
                    path: "/hvf/target/:id/edit",
                    name: "hvf target update",
                    component: () => import(/* webpackChunkName: "hvfExport" */'../src/view/pages/new/hvf/TargetAddEditForm'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf update'
                    }
                },
                {
                    path: "/welfare-application",
                    name: "welfare application",
                    component: () => import(/* webpackChunkName: "welfare application" */'../src/view/pages/new/reports/WelfareApplication'),
                    meta: {
                        auth: true,
                        breadCrumb: 'welfare application'
                    }
                },
                {
                    path: "/service/age-wise-service",
                    name: "Age wise Service",
                    component: () => import(/* webpackChunkName: "ServiceWiseReport" */'../src/components/occ/AgeWiseService'),
                    meta: {
                        auth: true,
                        breadCrumb: 'Age wise Service report'
                    }
                },
            ]

        },
        {
            path: "/new",
            component: () => import("@/view/layout/OccNewLayout"),
            meta: true,
            children: [
                {
                    path: "/new/service/report",
                    name: "new service report",
                    component: () => import(/* webpackChunkName: "OCCServiceReport" */'../src/view/pages/new/reports_new/Service'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC Service Report'
                    }
                },
            ]
        },
        {
            path: "/new",
            component: () => import("@/view/layout/OccNewLayout"),
            meta: true,
            children: [
                {
                    path: "/new/center/report",
                    name: "new center report",
                    component: () => import(/* webpackChunkName: "OCCCenterReport" */'../src/view/pages/new/reports_new/Center'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC Center Report'
                    }
                },
            ]
        },
        {
            path: "/new",
            component: () => import("@/view/layout/OccNewLayout"),
            meta: true,
            children: [
                {
                    path: "/new/appointment/report",
                    name: "new appointment report",
                    component: () => import(/* webpackChunkName: "OCCAppointmentReport" */'../src/view/pages/new/reports_new/Appointment'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC Appointment Report'
                    }
                },
            ]
        },
        {
            path: "/occ",
            component: () => import("@/view/layout/OccLayout"),
            meta: true,
            children: [
                {
                    path: "/occ/report",
                    name: "occ report",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/occ'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC Report'
                    }
                },
                {
                    path: "/occ/report/zcf-package",
                    name: "occ ZCF Package report",
                    component: () => import(/* webpackChunkName: "OccServiceReportNew" */'../src/view/pages/new/reports/OccServiceReportNew'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC Report'
                    }
                },
                {
                    path: "/occ/report/new",
                    name: "occ report",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/occNew'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC Report'
                    }
                },
                {
                    path: "/occ/report/center",
                    name: "occ report center",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/center'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC Center-wise Report'
                    }
                },
                {
                    path: "/occ/account",
                    name: "occ report",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/occ'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC patient Report'
                    }
                },
                {
                    path: "/occ/patient/report",
                    name: "occ patient report",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/Patient'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC patient Report'
                    }
                },
                {
                    path: "/occ/call/center",
                    name: "occ call center",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/CallCenter'),
                    meta: {
                        auth: true,
                        breadCrumb: 'OCC call center'
                    }
                },
                {
                    path: "/app/activity/reports",
                    name: "app activity reports",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/InappActivity'),
                    meta: {
                        auth: true,
                        breadCrumb: 'app activity reports'
                    }
                },
                {
                    path: "/occ/report/quality-control",
                    name: "occ report quality-control",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/QuilityControlReport'),
                    meta: {
                        auth: true,
                        breadCrumb: 'occ report quality-control'
                    }
                },
                {
                    path: "/occ/quality-control/:id",
                    name: "occ report quality-control view",
                    component: () => import(/* webpackChunkName: "occDahboard" */'../src/view/pages/new/reports/ViewQualityControl'),
                    meta: {
                        auth: true,
                        breadCrumb: 'occ report quality-control view'
                    }
                },

            ]
        },
        {
            path: "/hvf",
            component: () => import("@/view/layout/HVFLayout"),
            meta: true,
            children: [
                {
                    path: "/hvf/retail/reports",
                    name: "hvf retail",
                    component: () => import(/* webpackChunkName: "hvfRetail" */'../src/view/pages/new/hvf/Retail'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf retail'
                    }
                },
                {
                    path: "/hvf/retail/reports/sku",
                    name: "hvf retail sku view",
                    component: () => import(/* webpackChunkName: "hvfRetail" */'../src/view/pages/new/hvf/RetailSku'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf retail sku'
                    }
                },
                {
                    path: "/hvf/corporate/reports",
                    name: "hvf corporate",
                    component: () => import(/* webpackChunkName: "hvfCorporate" */'../src/view/pages/new/hvf/Corporate'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf corporate'
                    }
                },
                {
                    path: "/hvf/export/reports",
                    name: "hvf export",
                    component: () => import(/* webpackChunkName: "hvfExport" */'../src/view/pages/new/hvf/Export'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf export'
                    }
                },
                {
                    path: "/hvf/budget",
                    name: "hvf budget",
                    component: () => import(/* webpackChunkName: "hvfBudget" */'../src/view/pages/new/hvf/Budget'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf budget'
                    }
                },
                {
                    path: "/hvf/costs",
                    name: "hvf costs",
                    component: () => import(/* webpackChunkName: "hvfcosts" */'../src/view/pages/new/hvf/Costs'),
                    meta: {
                        auth: true,
                        breadCrumb: 'hvf costs'
                    }
                },
                
                

            ]
        },

        {
            path: "/",
            component: () => import("./view/pages/auth/Login"),
            children: [
                {
                    name: "login",
                    path: "/login",
                    component: () => import("./view/pages/auth/Login")
                },
                {
                    name: "register",
                    path: "/register",
                    component: () => import("./view/pages/auth/Register")
                }
            ]
        },
        {
            path: "*",
            redirect: "/404"
        },
        {
            path: "/404",
            redirect: "/404",
            component: () => import("@/view/layout/Layout"),
            children: [
                {
                    path: "/404",
                    name: "404",
                    component: () => import("@/view/pages/error/Error-1.vue")
                },
            ]

        }
    ]
});
