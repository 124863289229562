<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3 d-flex justify-content-end">
              <div class="mx-2">
                <!-- <button
                    type="button"
                    @click="exportData"
                    class="btn btn-primary">
                  <i class="fas fa-file-excel"></i> Export Excel
                </button> -->
              </div>
              <div>
                <!-- <b-button @click="dateRangeClear" variant="danger">Clear</b-button> -->
              </div>
            </div>
            <div>
              <div class="row justify-content-between">
                <div class="col-md-1 mt-9">
                  <div class="">
                    <b-form-group
                        class="mb-0">
                      <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="">From</label>
                      <input type="date" v-model="start_date" class="form-control">
                    </div>
                    <div class="col-md-6">
                      <label for="">To</label>
                      <input type="date" v-model="end_date" class="form-control">
                    </div>
                  </div>
                </div>


                <div class="col-md-3">
                  <label for="">Select TSO</label>
                  <b-form-group>
                    <v-select
                        :options="sales_users"
                        :reduce="sales_users => sales_users.id"
                        v-model="seller_id"
                        label="name">
                    </v-select>
                  </b-form-group>
                </div>
                <div class="col-md-2 mt-8">
                  <!-- <b-form-group
                      label-for="filter-input"
                      label-cols-sm="1"
                      label-align-sm="right"
                      label-size="lg"
                      class="mb-0">
                    <b-input-group size="sm">
                      <b-form-input
                          id="filter-input"
                          v-model="search"
                          type="search"
                          placeholder="Type to Search"/>
                    </b-input-group>
                  </b-form-group> -->
                </div>
                <div class="col-md-1 mt-8">
                  <!-- <b-form-checkbox
                      id="unique-visits-checkbox"
                      v-model="unique_visits"
                      name="unique-visits-checkbox"
                      :value="true"
                      :unchecked-value="false"
                      size="lg"
                      @change="getUser"
                  >
                    Unique Visits
                  </b-form-checkbox> -->
                </div>
                <div class="col-md-1 mt-8">
                  <button type="button" class="btn btn-info" @click="getUser">Filter</button>
                </div>
              </div>
              <!-- Table -->
              <div class="row">
                <div class="col-12">
                  <b-table
                      striped hover responsive
                      :items="items"
                      :fields="computedFields"
                      :current-page="currentPage"
                      thead-class="bg-primary text-white"
                      class="mt-5"
                      show-empty>


                    <template #cell(seller_id)="row">
                      <user-card :image="row.item.seller_image" :name="row.item.seller_name"
                                 :phone_number="row.item.seller_phone_number" :user_id="row.item.seller_id"/>
                    </template>

                    <template #cell(full_name)="row">
                      <user-card :name="row.item.full_name"
                                 :phone_number="row.item.phone_no">
                        <p style="margin-bottom: 0" v-if="row.item.speciality">{{ row.item.speciality }}</p>
                        <p v-if="row.item.bmdc_reg_no">BMDC Reg: {{ row.item.bmdc_reg_no }}</p>
                      </user-card>
                    </template>


                    <template #cell(update_history)="row">
                      <ol class="history-list" type="disc">
                        <li v-for="newItem in row.item.update_history" :key="newItem.id">
                          <strong>{{ createdAtFormat(newItem.created_at) }}</strong>
                          <ul>
                            <li v-for="(log, index) in newItem.logs" :key="index">
                              <strong>{{ log.field_name }}: </strong>
                              "<del>{{ log.old_value }}</del>" to "{{ log.new_value }}"
                            </li>
                          </ul>
                        </li>  
                      </ol>

                      <!-- <table>
                        <tr>
                          <th>Updated At</th>
                          <th>Field Name</th>
                          <th>Old Value</th>
                          <th>New Value</th>
                        </tr>
                        <tr v-for="newItem in row.item.update_history" :key="newItem.id">
                          <td :rowspan="newItem.logs.length">{{ createdAtFormat(newItem.created_at) }}</td>
                          <td colspan="3">
                            <table>
                              <tr v-for="(log, index) in newItem.logs" :key="index">
                                <td>{{ log.field_name }}</td>
                                <td>{{ log.old_value }}</td>
                                <td>{{ log.new_value }}</td>
                              </tr>
                            </table>
                          </td>
                         
                        </tr>
                      </table> -->
                    </template>

                    <template #cell(updated_at)="row">
                      {{ createdAtFormat(row.item.updated_at) }}
                    </template>

    

                  </b-table>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="total_activity"
                      :per-page="perPage"
                      size="lg"
                      @change="handlePageChange"
                      class="my-0"
                      aria-controls="doctorList"
                  ></b-pagination>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {SalesActivityLogMixin} from "../../mixins/SalesActivityLogMixin";
import {DateFormat} from "../../mixins/core/DateFormat";
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";

export default {
  name: "SalesActivityList",
  title: 'Sales Activity Log',
  mixins: [SalesActivityLogMixin, DateFormat, SalesUserMixins],
}
</script>
<style scoped>
.squre-img {
  height: 50px;
}
</style>
<style lang="scss" scoped>
.history-list {
  li {
    margin-bottom: 15px;
    ul {
      padding-left: 20px;
      li {
        line-height: 1.3;
        margin-bottom: 0;
      }
    }
  }
}
</style>