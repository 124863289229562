<template>
    <div class="card">
        <div class="card-body">
            <b-btn variant="info" @click="$router.push('/audit/form')">Create new application</b-btn>
            <div class="row">
                <div class="col-md-12">
                <b-table
                    striped hover responsive
                    :items="items"
                    :fields="fields"
                    thead-class="bg-primary text-white"
                    show-empty
                    class="mt-5">
                    <template #cell(action)="row">
                        <div>
                            <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                                        class="m-2">
                            <template #button-content>
                                <i class="fas fa-ellipsis-v"></i>
                            </template>
                            <div>
                                <b-dropdown-item href="#" @click="$router.push('/audit/application/view/'+row.item.id)">
                                <span class="pr-4"><i class="fas fa-eye"></i></span>View
                                </b-dropdown-item>
                                <b-dropdown-item href="#" @click="$router.push('/audit/form/update/'+row.item.id)">
                                <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                                </b-dropdown-item>
                            </div>
                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios  from "axios";
export default {
    name: 'AuditList',
    data() {
        return {
            items: [],
            fields: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Designation',
                    key: 'designation',
                },
                {
                    label: 'Department',
                    key: 'department',
                },
                {
                    label: 'Submission date',
                    key: 'submission_date'
                },
                {
                    label: 'Action',
                    key: 'action'
                },
            ]
        }
    },
    created() {
        localStorage.setItem('tabIndex', 9)
        this.$root.$emit('call-active-menu', 9);
        this.getItems();
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Audit List", route: "/audit/list"},
        ]);
    },
    methods: {
        getItems() {
            axios.get(`${process.env.VUE_APP_API_URL}/api/v2/orko/center/audit/reports?api_token=${localStorage.getItem('api_token')}`)
            .then(res => {
                this.items = res.data.data;
            })
        }
    }
}
</script>