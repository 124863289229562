import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import './import';
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");
