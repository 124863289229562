<template>
  <div class="row">
    <div class="col-md-12">
      <!--begin::Card-->
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <div>
            <!--begin::Details-->
            <div class="d-flex mb-9">
              <!--begin::Info-->
              <div class="flex-grow-1">

                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <h3 class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3">
                      Scientific conference
                    </h3>
                  </div>
                </div>
                <!--end::Title-->

                <div class="row mt-10" v-if="scientific_conferences.length">
                  <div class="col-md-6 mb-5" v-for="(scientific_conference, index) in scientific_conferences"
                       :key="index">
                    <b-card class=""
                            :img-src="scientific_conference.certificate_image ? scientific_conference.certificate_image :''"
                            img-left>
                      <b-card-text>
                        <h3>{{ scientific_conference.conference_tile }}
                          ({{ scientific_conference.attended_date }} - {{ scientific_conference.end_date }}) </h3>

                        <p class="mb-2">Department : {{
                            scientific_conference.department_name ? scientific_conference.department_name : 'Not yet set'
                          }} </p>
                        <p class="mb-2">Institute: <b> {{
                            scientific_conference.institute_name ? scientific_conference.institute_name : 'Not yet set'
                          }} </b></p>
                        <p class="mb-2">Location
                          <b>{{ scientific_conference.location ? scientific_conference.location : 'Not yet set' }}</b>
                        </p>
                      </b-card-text>
                    </b-card>
                  </div>
                </div>
                <div v-else> Not set yet</div>

              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
          </div>

        </div>
        <!--        <div v-else>-->
        <!--          <b-spinner variant="primary" label="Spinning"></b-spinner>-->
        <!--        </div>-->
      </div>
      <!--end::Card-->
    </div>
  </div>
</template>

<script>
export default {
  name: "ScientificConference",
  props: {
    scientific_conferences: {},
  },
  computed: {}
}
</script>

<style scoped>

</style>