<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Disbursed List</h2>
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="columns"
                  worksheet="My Worksheet"
                  name="Withdrawal Request">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="table-responsive mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions' || field.key == 'request_status'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>

              <template #cell(account_information)="row">
                <div class="">
                  <b-media tag="li" v-if="row.item.user_account_information">
                    <template #aside>
                      <div v-if="row.item.user_account_information.bank_account_type == 'Bank'">
                        <b-img
                            v-if="!imgError"
                            :src="userPhoto(row.item.user_account_information.bank_info ? row.item.user_account_information.bank_info.bank_logo : '' )"
                            class="user_image" @error="onImgError()">
                        </b-img>
                        <img src="../../assets/img/bank.png" class="user_image">
                      </div>
                      <div v-else>
                        <img src="../../assets/img/mobile_banking.png" class="user_image"/>
                      </div>


                    </template>

                    <h5 class="mt-0 mb-1" v-if="row.item.user_account_information.bank_account_type != 'Bank'">{{
                        row.item.user_account_information.bank_account_type ? row.item.user_account_information.bank_account_type : ''
                      }}</h5>
                    <p class="mb-0 font-weight-bold">{{
                        row.item.user_account_information.bank_info ? row.item.user_account_information.bank_info.bank_title : ''
                      }}</p>
                    <p class="mb-0 text-muted">{{
                        row.item.user_account_information ? row.item.user_account_information.bank_branch_name : ''
                      }}</p>
                    <p class="mb-0"><b>Ac. Name: </b> {{ row.item.account_information.account_name }}</p>
                    <p><b>Ac. No.: </b> {{ row.item.account_information.account_no }}</p>
                  </b-media>
                </div>
              </template>

              <template #cell(user)="row">

                <div class="">
                  <b-media tag="li">
                    <template #aside>
                      <div>
                        <b-img
                            :src="userPhoto(row.item.image)"
                            class="user_image">
                        </b-img>
                        <!--                        <img src="../../assets/img/blank.png" class="user_image">-->
                      </div>


                    </template>

                    <h5 class="mt-0 mb-1">{{ row.item.fullname }}</h5>
                    <p class="mb-0 font-weight-bold">{{ row.item.phone_number }}</p>
                  </b-media>
                </div>

              </template>
              <template #cell(bank_account_info)="row">
                <div class="white-space-nowrap-width">
                  <b-media tag="li" v-if="row.item.bank_account_info">
                    <template #aside>
                      <div v-if="row.item.bank_account_info.bank_account_type == 'Bank'">
                        <b-img
                            v-if="!imgError"
                            :src="userPhoto(row.item.bank_account_info.bank_info ? row.item.bank_account_info.bank_info.bank_logo : '' )"
                            class="user_image" @error="onImgError()">
                        </b-img>
                        <img src="../../assets/img/bank.png" class="user_image">
                      </div>
                      <div v-else>
                        <img src="../../assets/img/mobile_banking.png" class="user_image"/>
                      </div>


                    </template>

                    <h5 class="mt-0 mb-1">
                      {{ row.item.bank_account_info ? row.item.bank_account_info.bank_account_type : '' }}
                    </h5>
                    <p class="mb-0 font-weight-bold" v-if="row.item.bank_account_info.back_info">{{
                        row.item.bank_account_info.bank_info ? row.item.bank_account_info.bank_info.bank_title : ' n/a'
                      }} </p>
                    <p class="mb-0">{{
                        row.item.bank_account_info ? row.item.bank_account_info.bank_branch_name : ' n/a'
                      }}</p>
                    <p class="mb-0"><b>Ac. Name: </b>
                      {{ row.item.bank_account_info.account_name ? row.item.bank_account_info.account_name : ' n/a' }}
                    </p>
                    <p><b>Ac. No: </b>
                      {{ row.item.bank_account_info.account_no ? row.item.bank_account_info.account_no : ' n/a' }}</p>
                  </b-media>
                </div>
              </template>
              <template #cell(disbursed_by)="row">

                <div class="">
                  <b-media tag="li">
                    <template #aside>
                      <div>
                        <b-img
                            :src="userPhoto(row.item.action_taker_image)"
                            class="user_image">
                        </b-img>
                        <!--                        <img src="../../assets/img/blank.png" class="user_image">-->
                      </div>


                    </template>

                    <h5 class="mt-0 mb-1">{{ row.item.action_taker_fullname }}</h5>
                    <p class="mb-0 font-weight-bold">{{ row.item.action_taker_phone_number }}</p>
                  </b-media>
                </div>

              </template>

              <template #cell(request_status)="row">
                <div class="">
                  <b-badge variant="success" v-if="row.item.request_status == 'Disbursed'">Disbursed</b-badge>
                  <b-badge variant="danger" v-else-if="row.item.request_status == 'Rejected'">{{
                      row.item.request_status
                    }}
                  </b-badge>
                  <b-badge variant="primary" v-else-if="row.item.request_status == 'Verified'">
                    {{ row.item.request_status }}
                  </b-badge>
                  <b-badge variant="warning" v-else-if="row.item.request_status == 'Requested'">
                    {{ row.item.request_status }}
                  </b-badge>
                  <b-badge variant="info" v-else>{{ row.item.request_status }}</b-badge>
                </div>
              </template>

              <template #cell(actions)="row">
                <div v-if="row.item.request_status == 'Verified' || row.item.request_status == 'Requested' ">
                  <b-dropdown variant="primary" class="m-2" no-caret id="dropdown-dropleft" dropleft
                              text="Drop-Left">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <div v-if="row.item.request_status == 'Verified'">
                      <b-dropdown-item href="#" @click="sendInfo(row.item, '5')" v-b-modal.modal-disburse>
                        <span class="pr-4"><i class="fas fa-money-bill-alt"></i></span>Disburse
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="sendInfo(row.item, '6')" v-b-modal.modal-lg>
                        <span class="pr-4"><i class="fas fa-times-circle"></i></span>Reject
                      </b-dropdown-item>
                    </div>
                    <div v-if="row.item.request_status == 'Requested'">
                      <b-dropdown-item href="#" @click="sendInfo(row.item, '3')" v-b-modal.modal-lg>
                        <span class="pr-4"><i class="fas fa-check-circle"></i></span>Verify
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="sendInfo(row.item , '4')" v-b-modal.modal-lg>
                        <span class="pr-4"><i class="fas fa-window-close"></i></span>Unverify
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
                <div v-else>
                  <span class="badge badge-danger">No Action</span>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                size="lg"
                :total-rows="items.length"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {DisbursedListMixin} from '../../mixins/DisbursedListMixin';

export default {
  name: "DisbursedList",
  mixins: [DisbursedListMixin],
  title: 'Disbursed List',
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.user_icon i {
  font-size: 10px;
}

.user_info li {

  margin: 5px 0px;
}

.user_info li i {
  padding: 4px 6px;
  font-size: 10px;
  color: #44A0FF;
  border-radius: 50%;
  background-color: rgba(230, 242, 255, 0.9);
  margin-right: 5px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.user_image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  transition: .5s;
  object-position: center;
  object-fit: contain;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}

tr td:nth-child(2) {
  width: 300px;
}

.invalid-feedback {
  display: block !important;
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-space-nowrap-width {
  white-space: nowrap;
  width: 300px;
  overflow: scroll;
}
</style>