<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row mb-5 align-items-center">
          <div class="col-md-5">
            <label for="start-datepicker">Choose started date</label>
            <b-form-datepicker id="start-datepicker" v-model="startDate" class="mb-2"></b-form-datepicker>
          </div>
          <div class="col-md-5">
            <label for="end-datepicker">Choose end date</label>
            <b-form-datepicker id="end-datepicker" v-model="endDate" class="mb-2"></b-form-datepicker>
          </div>
          <div class="col-md-2">
            <b-button @click="getAppointmentList" variant="primary" class="mt-7 w-100">Search</b-button>
          </div>

        </div>
        <div class="row">
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-4">
                <b-form-select class="filter-font" :class="select.status ? 'bg-active' : ''" v-model="select.status" :options="statuses" @change="getAppointmentList"></b-form-select>
              </div>
              <div class="col-md-4">
                <b-form-select class="filter-font" :class="select.appointment_type ? 'bg-active' : ''" v-model="select.appointment_type" :options="appointment_type" @change="getAppointmentList"></b-form-select>
              </div>
              <div class="col-md-4">
                <b-form-select class="filter-font" :class="select.service_type ? 'bg-active' : ''" v-model="select.service_type" :options="service_type" @change="getAppointmentList"></b-form-select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row justify-content-end">
              <div class="col-md-2">
                <b-button @click="getAppointmentList" variant="info"><i class="fas fa-redo-alt"></i></b-button>
              </div>
              <div class="col-md-2">
                <b-button @click="clearFilter" variant="danger"><i class="fas fa-eraser"></i></b-button>
              </div>
            </div>
          </div>

        </div>
        <div class="row justify-content-between align-content-center align-self-center align-items-center text-center">
          <div class="col-md-1 mt-7">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-6 mt-7">

              <b-input-group size="md">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    size="md"
                    type="search"
                    placeholder="Type to Search"/>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                responsive
                striped hover
                :items="filtered"
                :fields="fields"
                :filter="filter"
                thead-class="bg-primary text-white"
                :current-page="currentPage"
                :per-page="0"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                class="mt-5"
                show-empty>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions' || field.key == 'image' || field.key=='otp' "></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>

              <template #cell(doctor_id)="row">
                <div>
                  <b-media tag="li" class="">
                    <template #aside>
                      <div class="text-center">
                        <b-img :src="userPhoto(row.item.doctor_image)" width="85" class="thumbnail" thumbnail></b-img>
                        <star-rating :value="`${row.item.doctor_review}`" :disabled="true"></star-rating>
                        <p class="mb-0">Exp: <b>{{ row.item.doctor_exp }} Year</b></p>
                      </div>
                    </template>
                    <h5 class="mt-0 mb-1"><router-link :to="`user/details/${row.item.doctor_id}`">{{ row.item.doctor_prefix }} {{ row.item.doctor_name }}</router-link></h5>
                    <p class="mb-0 font-weight-bold">{{ row.item.doctor_phone_number }}</p>
                    <p class="mb-0">{{ row.item.doctor_degree }}</p>
                    <p class="mb-0">{{ row.item.institute_name }}</p>
                  </b-media>
                </div>
              </template>

              <template #cell(patient_id)="row">
                <div>
                  <b-media tag="li">
                    <template #aside>
                      <b-img v-if="row.item.patient_image != '/storage/'" blank-color="#ccc"
                             :src="userPhoto(row.item.patient_image)" width="80" thumbnail></b-img>
                      <b-img v-else blank blank-color="#ccc" width="80"></b-img>
                    </template>
                    <h5 class="mt-0 mb-1"><router-link :to="`user/details/${row.item.patient_id}`">{{ row.item.patient_name }}</router-link></h5>
                    <p class="mb-0">{{ row.item.patient_age }}</p>
                    <p class="mb-0">{{ row.item.patient_phone }}</p>
                  </b-media>
                </div>
              </template>

              <template #cell(appointment_date)="row">
                <div>
                  <p>{{ row.item.appointment_date }}</p>
                </div>
              </template>

              <template #cell(service_type)="row">
                <div>
                  <p>{{ row.item.service_type }}</p>
                </div>
              </template>

              <template #cell(schedule_date)="row">
                <div>
                  <h4>{{ row.item.schedule_date }}</h4>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <b-badge variant="success">start: {{ row.item.schedule_started_at }}</b-badge>
                  <h4 class="mb-0">-</h4>
                  <b-badge variant="danger">end: {{ row.item.schedule_end_at }}</b-badge>
                </div>
              </template>

              <template #cell(is_paid)="row">
                <div>
                  <b-badge variant="success" v-if="row.item.is_paid == 1">Paid</b-badge>
                  <b-badge variant="warning" v-else-if="row.item.is_paid == 2">Partial paid (AP)</b-badge>
                  <b-badge variant="danger" class="mt-3 cursor-pointer" @click="showPaymentModal(row.item)" v-else>
                    Unpaid
                  </b-badge>
                </div>
              </template>

              <template #cell(appointment_status)="row">
                <div>
                  <b-badge variant="warning" v-if="row.item.appointment_status == 'expired'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="success" v-else-if="row.item.appointment_status == 'booked'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="danger" v-else-if="row.item.appointment_status == 'canceled'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="info" v-else-if="row.item.appointment_status == 'rescheduled'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="danger" v-else-if="row.item.appointment_status == 'patient-absent'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="primary" v-else-if="row.item.appointment_status == 'served'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="danger" class="mt-3" v-else>{{ row.item.appointment_status }}</b-badge>
                </div>
              </template>

              <template #cell(is_doctor_show)="row">
                <div>
                  <b-badge variant="success" v-if="row.item.is_doctor_show == 1">
                    Present
                  </b-badge>
                  <b-badge variant="danger" v-else>
                    Absent
                  </b-badge>
                </div>
              </template>

              <template #cell(is_patient_show)="row">
                <div>

                  <b-badge variant="success" v-if="row.item.is_patient_show == 1">
                    Present
                  </b-badge>
                  <b-badge variant="danger" v-else>
                    Absent
                  </b-badge>
                </div>
              </template>

              <template #cell(is_expire)="row">
                <div>

                  <b-badge variant="success" v-if="row.item.is_expire == true">
                    Not expired
                  </b-badge>
                  <b-badge variant="danger" v-else>
                    Expired
                  </b-badge>
                </div>
              </template>

              <template #cell(details)="row">
                <div>

                  <p variant="success" v-if="row.item.details">
                    {{ row.item.details }}
                  </p>
                  <p variant="danger" v-else>
                    N/A
                  </p>
                </div>
              </template>

              <template #cell(payable_amount)="row">
                <div>
                  <h4>
                    {{ row.item.payable_amount }}
                  </h4>
                </div>
              </template>

              <template #cell(penalty_amount)="row">
                <div>
                  <h4>
                    {{ row.item.penalty_amount }}
                  </h4>
                </div>
              </template>

              <template #cell(delay_time)="row">
                <div>
                  <h4 v-if="row.item.delay_time">
                    {{ row.item.delay_time }}
                  </h4>
                  <p v-else> 0 </p>
                </div>
              </template>

              <template #cell(appointment_type)="row">
                <div>
                  <p variant="success">
                    {{ underScoreToSpace(row.item.appointment_type) }}
                  </p>
                </div>
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-dropdown variant="primary" class="m-2" no-caret id="dropdown-dropleft" dropleft
                              text="Drop-Left">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <router-link class="dropdown-item" :to="`/appointment-history/${row.item.id}`" target="_blank">
                      <span class="pr-4"><i class="fas fa-history"></i></span>History
                    </router-link>
                    <p class="dropdown-item mb-0" href="#" @click="changeStatus(row.item)">
                      <span class="pr-4"><i class="fas fa-calendar-check"></i></span>Change status
                    </p>
                    <a href="#" class="dropdown-item mb-0 d-flex alignment-item-center" @click="cancelAppointment(row.item)">
                      <span class="pr-4"><i class="fas fa-calendar-times"></i></span>Cancel It
                    </a>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                size="lg"
                class="my-0"
                @change="handlePageChange"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!--    Change appointment status modal -->
    <b-modal
        id="statusModal"
        size="lg"
        title="Change appointment status"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
        <b-form-group>
          <label for="">Select status</label>
          <b-form-radio-group
              :options="statuses"
              v-model="selectedStatus"
              class="mb-3"
          ></b-form-radio-group>
        </b-form-group>
        <b-form-group>
          <label for="">Action comment <span class="text-danger">*</span></label>
          <b-form-textarea
              id="textarea"
              v-model="appointment.comment"
              placeholder="Enter something..."
              rows="3"
          ></b-form-textarea>
        </b-form-group>
      </form>
    </b-modal>

    <!--    Cancel modal -->
    <b-modal
        id="cancelModal"
        size="lg"
        title="Cancel appointment status"
        @show="resetModal"
        @hidden="resetModal"
        @ok="cancelAppointmentOk">
      <form ref="form" @submit.stop.prevent="handleCancelAppointmentSubmit" enctype="multipart/form-data">
        <b-form-group>
          <label for="">Action comment <span class="text-danger">*</span></label>
          <b-form-textarea
              id="textarea"
              v-model="appointment.comment"
              placeholder="Enter something..."
              rows="3"
          ></b-form-textarea>
        </b-form-group>
      </form>
    </b-modal>

    <!--    Appointment payment modal -->
    <b-modal
        id="paymentModal"
        size="lg"
        title="Payments"
        @show="resetModal"
        @hidden="resetModal"
        @ok="paymentOk">
      <form ref="form" @submit.stop.prevent="appointPayment">
        <b-form-group
            id="fieldset-1"
            label="Amount"
            label-for="input-1">
          <b-form-input id="input-1" v-model="payments.amount"></b-form-input>
        </b-form-group>

        <b-form-group
            label="Transaction type">
          <b-form-select v-model="payments.transaction_type" :options="transaction_type" required></b-form-select>
        </b-form-group>

        <b-form-group
            id="input-group-1"
            :label="`${payments.transaction_type}`"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="payments.banking_name"
              type="text"
              required
              :placeholder="`${payments.transaction_type == 'Mobile Banking' ? 'Mobile Banking Name' : 'Bank Name , Branch Name'}`"
          ></b-form-input>
        </b-form-group>

        <div>
          <b-form-group label="Payment options" v-slot="{ ariaDescribedby }">
            <b-form-radio v-model="payments.option" :aria-describedby="ariaDescribedby" name="some-radios" value="1">
              Paid
            </b-form-radio>
            <b-form-radio v-model="payments.option" :aria-describedby="ariaDescribedby" name="some-radios" value="2">
              Partial
              payment
            </b-form-radio>
          </b-form-group>
        </div>
      </form>
    </b-modal>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>


import {AppointmentListMixin} from "../../mixins/appointment/AppointmentListMixin";

export default {
  name: "AppointmentList",
  mixins: [AppointmentListMixin],
  title: 'Appointment List',
}
</script>

<style lang="css" scoped>

/deep/ tr td:nth-child(2) h5 {
  white-space: nowrap;
}

.my-class {
  width: 400px !important;
}

.test {
  color: darkred;
}

.media-aside {
  width: 100px;
}

.media {
  display: flex;
  align-items: flex-start;
  align-items: center;
}
.text-danger{
  color: darkred;
}
.filter-font {
  font-size: 14px;
  font-weight: 700;
  border-radius: 30px;
}
.bg-active {
  background-color: #037642;
  color: white;
}
</style>