import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export const PermissionsMixin = {
    data() {
        return {
            breadcrumbs: 'users',
            localActive: false,
            activeItemIndex: null,
            isOpen: false,
            items: {},
            form: {
                role_id: '',
                permission_ids: ''
            },
            permissions_id: [],
            permission_data: [],
        }
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Permissions", route: "permissions"},
        ]);
    },

    created() {
        localStorage.setItem('tabIndex', 13)
        this.$root.$emit('call-active-menu', 13);
        this.getPermissions();
        this.getRolesData();
    },

    methods: {
        getPermissions() {
            axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
            this.$store.dispatch('auth/getPermissions');
        },
        getRolesData() {
            axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
            axios.get(`/api/v1/role/list`)
                .then(response => {
                    this.items = response.data.data
                    this.loading = true;
                })
        },
        setPermission() {

            axios
                .post(`/api/v1/permission/set?api_token=${localStorage.getItem('api_token')}`, this.form)
                .then(response => {
                    console.log(response)
                })
        },

        checkPermissions(event, key) {

            if (event) {

                this.permission_data.push(key);
                let data = this.permission_data.join(',')
                this.form.permission_ids = data;

            } else {
                const index = this.permission_data.indexOf(key);
                this.permission_data.splice(index, 1);
                let data = this.permission_data.join(',')
                this.form.permission_ids = data;
            }

            // this.permission_data.push(key);
            // let data =  this.permission_data.join();
            // this.form.permission_ids = data;
        },

        toggle(index) {
            if (this.activeItemIndex == index) {
                this.isOpen = index;
                return this.activeItemIndex = null;
            }
            this.isOpen = index;
            this.activeItemIndex = index
        }
    },
    computed: {
        permissions: {
            get() {
                return this.$store.state.auth.permissions;
            }
        }
    }
}
