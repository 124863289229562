<template>
  <!--begin::Card-->
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div>
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin::Info-->
          <div class="flex-grow-1">

            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <h3 class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3">
                  Volunteer service
                </h3>
              </div>
            </div>
            <!--end::Title-->

            <div class="row mt-10" v-if="volunteers.length">
              <div class="col-md-6 mb-5" v-for="(volunteer, index) in volunteers" :key="index">
                <b-card class="">
                  <b-card-text>
                    <h3> {{ volunteer.title ? volunteer.title : 'Not yet set' }}({{ volunteer.start_date }} -
                      {{ volunteer.end_date }})</h3>

                    <p class="mb-2">Institute: <b>{{ volunteer.institute ? volunteer.institute : 'Not yet set' }} </b>
                    </p>
                    <p class="mb-2">Department: <b>{{
                        volunteer.department_name ? volunteer.department_name : 'Not yet set'
                      }} </b></p>
                    <p class="mb-2">Nature of service: <b>{{ volunteer.service ? volunteer.service : 'Not yet set' }} </b></p>
                  </b-card-text>
                </b-card>
              </div>
            </div>
            <div v-else> Not set yet</div>
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
      </div>

    </div>
    <!--    <div v-else>-->
    <!--      <b-spinner variant="primary" label="Spinning"></b-spinner>-->
    <!--    </div>-->
  </div>
  <!--end::Card-->
</template>

<script>
export default {
  name: "Volunteer",
  props: {
    volunteers: {},
  }
}
</script>

<style scoped>

</style>