import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const NotificationListMixin = {
    data() {
        return {
            bookType: 'xlsx',
            list: null,
            listLoading: true,
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            items: [],
            notification_types: [],
            loading: true,
            name: '',
            notification_template_nameState: null,
            notificationTypeState: null,
            notification_type_nameState: null,
            actionPointState: null,
            submittedNames: [],
            groups: [
                {
                    id: 1,
                    name: "Users"
                }
            ],
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'notification_master_template',
                    label: 'Notification master',
                    sortable: true
                },
                // {
                //     key: 'initiated_by_name',
                //     label: 'Sender',
                //     sortable: true
                // },
                {
                    key: 'initiated_for_name',
                    label: 'Receiver',
                    sortable: true
                },
                {
                    key: 'action_status',
                    label: 'Action status',
                    sortable: true
                },
                {
                    key: 'created_at',
                    label: 'Created at',
                    sortable: true
                },
            ],
            filter: null,
            filterOn: [],
            filters: {
                index: '',
                initiated_by: '',
                notification_master_id: '',
                initiated_for: '',
                action_status: '',
                created_at: '',
                updated_at: '',

            },
            json_fields: {
                "initiated by": {
                    field: "initiated_by",
                    callback: (value) => {
                        return `${value.fullname}`;
                    },
                },
                "initiated for": {
                    field: "initiated_for",
                    callback: (value) => {
                        return `${value.fullname}`;
                    },
                },
                "Action status": {
                    field: "action_status",
                },
                "Created at": {
                    field: "created_at",
                },
                "Updated at": {
                    field: "updated_at",
                },
            },
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            update_id: '',
            action_point: '',
            total_count: 0,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Notification list", route: "notification-list"},
        ]);

        this.getNotificationTemplate();
    },
    created() {
        localStorage.setItem('tabIndex', 5)
        this.$root.$emit('call-active-menu', 5);
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            const filtered = this.items.filter(item => {

                return Object.keys(this.filters).every(key =>

                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                index: '',
                initiated_by: '',
                notification_master_id: '',
                initiated_for: '',
                action_status: '',
                created_at: '',
                updated_at: '',

            }]
        },
        actionPointComputed(item) {
            console.log('from computed', item)
        }
    },
    methods: {
        getNotificationTemplate() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/notification/list?api_token=${localStorage.getItem('api_token')}&offset=0&limit=${this.perPage}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.total_count = response.data.total_count;
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/notification/list?api_token=${localStorage.getItem('api_token')}&offset=${correctValue}&limit=${this.perPage}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },

        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return '../../../public/media/users/blank.png'
            }
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.notification_template_nameState = valid
            this.notificationTypeState = valid
            this.actionPointState = valid
            return valid
        },

        resetModal() {
            this.form = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        editData(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.updateNotification()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();
            form.append("notification_template", this.form.notification_template);
            form.append("notification_type_id", this.form.notification_type_id);
            form.append("action_point", this.form.action_point);
            form.append("orko_chat_bot_template", this.form.orko_chat_bot_template);
            form.append("orko_chat_bot_action_point", this.form.orko_chat_bot_action_point);
            form.append("status", '1');
            form.append("is_system_initiated", '1');

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/notification/manage/template/add?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getNotificationTemplate();
                        this.$bvModal.hide('modal')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            // this.currentPage = 1
        },
        showRolesDetails(id) {
            this.$router.push(`/roles/show/${id}`);
        },
        updateData(row) {
            this.$bvModal.show('updateModal')
            this.form = row.item;
            this.update_id = row.item.id
        },
        updateNotification() {
// Exit when the form isn't valid

            // Push the name to submitted names
            let form = new FormData();
            form.append("notification_template", this.form.notification_template);
            form.append("notification_type_id", this.form.notification_type_id);
            form.append("action_point", this.form.action_point);
            form.append("orko_chat_bot_template", this.form.orko_chat_bot_template);
            form.append("orko_chat_bot_action_point", this.form.orko_chat_bot_action_point);
            form.append("status", '1');
            form.append("is_system_initiated", '1');

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/notification/manage/template/update/${this.update_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getNotificationTemplate();
                        this.$bvModal.hide('updateModal')
                        this.$snotify.success('Successfully Updated');
                    })
            })
        },
        actionPoint(item) {
            if (item.length > 1) {
                let newItem = JSON.stringify(item)
                let tst = JSON.parse(newItem)
                this.action_point = tst;
            }
        }
    }
}