<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-end">
          <div class="col-md-4">
            <b-form-group
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                show-empty
                thead-class="bg-primary text-white"
                class="mt-2">

              <template #cell(index)="data">
                <div class="d-flex justify-content-between align-content-center align-items-center">
                  <p class="mb-0">{{ data.index + 1 }}</p>

                </div>
              </template>


            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
  name: "NatureOfServiceReport",
  title: 'Nature of Service Report',
  data() {
    return {
      items: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      fields: [
        {
          label: 'SL.',
          key: 'index'
        },
        {
          label: 'Title',
          key: 'title',
          sortable: true
        },
        {
          label: 'Verified doctor count',
          key: 'verified_doctor_count',
          sortable: true
        },
        {
          label: 'For whom',
          key: 'for_whom',
          sortable: true
        },
      ],
    }
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    this.getNatureOfService();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Nature of service", route: "/nature-of-service-report"},
    ]);
  },
  methods: {
    getNatureOfService() {
      axios
      .get(`${process.env.VUE_APP_URL}/api/v1/medical/services/list?for_whom=doctor`)
      .then(res => {
        this.items = res.data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>