import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const OtpMixin = {
    data() {
        return {
            form: {
                name: '',
            },

            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],

            items: [],
            loading: true,
            name: '',
            nameState: null,
            submittedNames: [],
            groups: [
                {
                    id: 1,
                    name: "Users"
                }
            ],
            fields: [
                {
                    label: "Phone number",
                    key: 'phone_number',
                    sortable: true
                },
                {
                    label: "Email",
                    key: 'email',
                    sortable: true
                },
                {
                    label: "Otp",
                    key: 'otp_token',
                    sortable: true
                },
                {
                    label: "User type",
                    key: 'user_type',
                    sortable: true
                },
                {
                    label: "Expires Time",
                    key: 'expires_at',
                    sortable: true
                },
                {
                    label: "Generated time",
                    key: 'created_at',
                    sortable: true
                }
            ],
            filter: null,
            filterOn: [],
        }
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Otp", route: "otp"},
        ]);
    },

    created() {
        localStorage.setItem('tabIndex', 13)
        this.$root.$emit('call-active-menu', 13)
        this.getOtpList();
    },

    methods: {
        getOtpList() {
            this.loading = true;
            axios.get(`${process.env.VUE_APP_URL}/api/v2/otp/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.loading = false;
                        this.items = response.data.data
                    }
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.name = '';
            this.nameState = null;
            this.form = {};
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            this.submittedNames.push(this.name)
            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/role/create`, this.form)
                    .then(response => {
                        this.resetModal();
                        this.getRolesData();
                        this.$bvModal.hide('modal')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        showRolesDetails(id) {
            this.$router.push(`/roles/show/${id}`);
        }
    },

    computed: {
        roles: {
            get() {
                return this.$store.state.auth.roles;
            },
            rows() {
                return this.items.length
            },
        },
        permissions: {
            get() {
                return this.$store.state.auth.permissions;
            }
        }
    }
}
