<template>
  <div>

    <div class="card mb-5">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div>
              <h4 class="mb-10">Connect a doctor with</h4>
              <br>
              <!--   For filter  start -->
              <b-form-group label="Filter by" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                    id="radio-slots"
                    v-model="filter_value"
                    :options="filterOptions"
                    :aria-describedby="ariaDescribedby"
                    name="radio-options-slots">

                </b-form-radio-group>
              </b-form-group>
              <!--   For filter  end -->

              <b-form @submit.prevent="searchDoctor" @reset="onReset">
                <b-input-group>
                  <b-form-input type="text" v-model="search_text"
                                :placeholder="`Search ${filter_value}`"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="outline-primary" type="submit">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <b-badge variant="primary" class="mt-5 mb-5">Search Text : {{ search_text }}</b-badge>
        <div class="row">
          <div class="col-md-12">
            <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
            <b-table
                responsive
                striped hover
                :items="items"
                :fields="fields"
                thead-class="bg-primary text-white"
                class="mt-5"
                show-empty
                v-else>
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(image)="row">
                <div>
                  <b-img :src="userPhoto(row.item.details.image)" class="user_image"
                         v-if="row.item.details.image"></b-img>
                  <img src="../../assets/img/blank.png" class="user_image" v-else/>
                </div>
              </template>

              <template #cell(image)="row">
                <div class="">
                  <b-media tag="li">
                    <template #aside>
                      <b-img :src="userPhoto(row.item.details.image)" class="user_image"
                             v-if="row.item.details.image"></b-img>
                      <img src="../../assets/img/blank.png" class="user_image" v-else/>
                    </template>
                    <h5 class="mt-0 mb-1">{{ row.item.details.name }}</h5>
                    <p class="mt-0 mb-1">{{ row.item.details.phone_number }}</p>

                  </b-media>

                </div>
              </template>

              <template #cell(name)="row">
                <div>
                  {{ row.item.details.name }}
                  {{ row.item.details.sub_type ? '(' + row.item.details.sub_type + ')' : '' }}
                </div>
              </template>

              <template #cell(card_intro)="row">
                <div>
                  {{ row.item.details.card_intro }}
                </div>
              </template>

              <template #cell(phone_number)="row">
                <div>
                  {{ row.item.details.phone_number }}
                </div>
              </template>

              <template #cell(nature_of_service)="row">
                <div>
                  {{ row.item.details.nature_of_service }}
                </div>
              </template>

              <template #cell(visit_fee)="row">
                <div>
                  <p mb-0>Virtual Fee: {{ row.item.details.call_fee }}</p>
                  <p mb-0>Visit Fee: {{ row.item.details.visit_fee }}</p>
                </div>
              </template>

              <template #cell(degrees)="row">
                <div>
                  {{ row.item.details.degrees }}
                </div>
              </template>

              <template #cell(education_institute)="row">
                <div>
                  {{ row.item.details.education_institute }}
                </div>
              </template>

              <template #cell(experience)="row">
                <div>
                  {{ row.item.details.experience }}
                </div>
              </template>

              <template #cell(location)="row">
                <div>
                  {{ row.item.details.location }}
                </div>
              </template>

              <template #cell(specialities)="row">
                <div>
                  {{ row.item.details.specialities }}
                </div>
              </template>

              <template #cell(workplace_institute)="row">
                <div>
                  {{ row.item.details.workplace_institute }}
                </div>
              </template>

              <template #cell(actions)="row">

                <button @click="schedule(row.item)" title="Check Schedule"
                        class="btn btn-info d-flex justify-content-between align-items-center ">
                  <span class=""><i class="fas fa-clipboard-list"></i></span>
                </button>

              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!--    schedule modal start -->
    <b-modal
        id="scheduleModal"
        title="Schedule list"
        @show="resetModal"
        @hidden="resetModal"
        size="lg"
        hide-footer
        @ok="handleOk">
      <form ref="form" @submit.stop.prevent="confirmBooking">
        <!--     booking start -->
        <div>
          <b-card no-body>
            <b-tabs pills card align="center">
              <!--  Virtual tab start -->
              <b-tab title="" active @click="vartual_apointment">
                <template #title>
                  <div class="text-center">
                    <div class="text-white">
                      <i class="fas fa-headset text-white"></i>
                    </div>
                    <div>
                      <strong>Virtual appointment</strong>
                    </div>
                  </div>
                </template>
                <b-card-text>
                  <div class="">

                    <div>
                      <label for="" class="text-left">Choose a date</label>
                      <b-form-datepicker
                          id="example-datepicker"
                          class="mb-2"
                          v-model="vartual.date"
                          :min="min"
                          @input="onContext">
                      </b-form-datepicker>
                    </div>
                    <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
                    <div v-else>

                      <div style="position: relative;z-index: 1"
                          v-if="vartual.avaiable_slot !== 'undefined' && vartual.avaiable_slot !== null && vartual.avaiable_slot.length > 0">
                        <div v-for="(slot, index) in all_slots" :key="index" v-if="vartual.date">
                          <div v-for="(single_slog, slot_index)  in slot.slots" :key="slot_index"
                               style="display: inline">
                            <button type="button" class="btn_choose_sent bg_btn_chose_1 mx-2 my-2"
                                    v-if="single_slog.status == 1">
                              <input type="radio" name="name" v-model="time_slot" :value="single_slog.slot_time"/>
                              {{ single_slog.slot_time }}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div v-else>
                        <b-badge pill variant="danger mx-3">Not found</b-badge>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
              <!--  Virtual tab end  -->

              <!-- visit tab start -->
              <b-tab @click="visit_apointment">
                <template #title>
                  <div class="text-center">
                    <div class="text-white">
                      <i class="fas fa-user-md text-white"></i>
                    </div>
                    <div>
                      <strong>Visit appointment</strong>
                    </div>
                  </div>
                </template>
                <b-card-text>

                  <div class="row">
                    <div class="col-md-12">
                      <div>
                        <label for="" class="text-left">Choose a date</label>
                        <b-form-datepicker
                            id="visit-datepicker"
                            class="mb-2"
                            v-model="vartual.date"
                            :min="min"
                            @input="onVisit">
                        </b-form-datepicker>
                        <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>
                        <div v-else>
                          <div
                              v-if="visit_chamber !== 'undefined' && visit_chamber !== null && visit_chamber.length > 0">
                            <carousel :dots="false">
                              <div v-for="(chamber, index) in visit_chamber" :key="index" class="mx-3 mt-4">
                                <div>
                                  <div class="card">
                                    <img :src="userPhoto(chamber.image)" class="card-img-top" alt="..."
                                         v-if="chamber.image">
                                    <img src="../../assets/img/blank.png" alt="" class="card-img-top" v-else>
                                    <div class="card-body">
                                      <h4 class="card-title">{{ chamber.institute_name }}</h4>
                                      <p class="card-text"> {{ chamber.address }}</p>
                                      <br>
                                      <b-button v-b-toggle="`collapse-${chamber.id}`" variant="primary" class="book_btn"
                                                @click="chamberId(chamber)">Book
                                      </b-button>
                                    </div>
                                  </div>
                                  <!--                                <b-card-->
                                  <!--                                    :title="`${chamber.institute_name}`"-->
                                  <!--                                    :img-src="userPhoto(chamber.image)"-->
                                  <!--                                    img-alt="Image"-->
                                  <!--                                    img-top-->
                                  <!--                                    tag="article"-->
                                  <!--                                    class="mb-2 card-height">-->
                                  <!--                                  <b-card-text>-->
                                  <!--                                    {{ chamber.address }}-->
                                  <!--                                  </b-card-text>-->

                                  <!--                                  <b-button v-b-toggle="`collapse-${chamber.id}`" variant="primary" class="book_btn"-->
                                  <!--                                            @click="chamberId(chamber)">Book-->
                                  <!--                                  </b-button>-->
                                  <!--                                </b-card>-->

                                </div>
                              </div>
                            </carousel>
                          </div>
                          <div v-else>
                            <b-badge pill variant="danger mx-3">Not found</b-badge>
                          </div>

                          <b-collapse :id="`collapse-${chamber_details.id}`" class="mt-2" v-if="vartual.date">
                            <b-card>
                              <div>
                                <div v-for="(slot, index) in chamber_details.working_schedules" :key="index">
                                  <div v-for="(single_slog, slot_index)  in slot.slots" :key="slot_index"
                                       style="display: inline">
                                    <button type="button" class="btn_choose_sent bg_btn_chose_1 mx-2 my-2"
                                            v-if="single_slog.status == 1">
                                      <input type="radio" name="name" v-model="time_slot"
                                             :value="single_slog.slot_time"/>
                                      {{ single_slog.slot_time }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </b-card>
                          </b-collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--   single card start    -->
                  <div>

                  </div>
                  <!--   single card end    -->
                </b-card-text>
              </b-tab>
              <!-- visit tab end -->
            </b-tabs>

          </b-card>
        </div>
        <!--     booking end -->

        <div class="row mt-10">
          <div class="col-md-9">
            <div class="mb-3">
              <label>Select patient</label>
              <v-select
                  :options="patients"
                  :reduce="patients => patients.id"
                  v-model="vartual.patient_id"
                  label="phone_number">
                <template slot="option" slot-scope="user">
                  {{ user.phone_number }} - ( {{ user.name }} )
                </template>
              </v-select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="mb-3">
              <label>If new</label> <br>
              <b-button variant="primary" v-b-modal.modal-multi-2>Add new patient</b-button>
            </div>
          </div>
        </div>

        <!--      Doctor details start -->
        <div v-if="vartual.patient_id">
          <div class="row mt-10" v-if="doctor_info !== 'undefined' || doctor_info !== null">
            <div class="col-md-12 text-center">
              <h5 class="text-center"> Do you want to book a <span v-if="click_visit"> visit</span> <span
                  v-else>call</span> appointment with
                <b-badge variant="primary">{{ doctor_info ? doctor_info.fullname : null }}</b-badge>
                on
              </h5>
              <b-badge variant="primary mx-5"> {{ vartual.date }}</b-badge>
              <b-badge variant="primary mx-5"> {{ time_slot }}</b-badge>
            </div>
            <br>
            <div class="col-md-12 text-center mt-10">
              <h2 v-if="click_virtual">Fee amount
                {{ doctor_info ? doctor_info.doctor_information.service_fee.call_fee : '' }}</h2>
              <h2 v-else>Fee amount {{ doctor_info ? doctor_info.doctor_information.service_fee.visit_fee : '' }}</h2>
              <b-button variant="primary" type="submit">Confirm Appointment</b-button>
            </div>
          </div>
        </div>
        <!--      Doctor details end -->
      </form>

    </b-modal>

    <!--    Add New patient modal -->
    <b-modal
        id="modal-multi-2"
        title="Add new patient"
        size="lg" ok-only
        @show="resetModalNewPatent"
        @hidden="resetModalNewPatent"
        @ok="handlePatient">
      <form ref="form" @submit.stop.prevent="saveNewPatient">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <b-form-group
                  class="mb-0">
                <label for="">First name <span class="text-danger">*</span></label>
                <b-form-input
                    id="input-formatter"
                    v-model="patient_register.first_name"
                    :state="patient_first_name_state"
                    placeholder="Enter first name"
                    required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <b-form-group
                  class="mb-0">
                <label for="">Last name <span class="text-danger">*</span></label>
                <b-form-input
                    id="input-formatter"
                    v-model="patient_register.last_name"
                    placeholder="Enter last name"
                    :state="patient_last_name_state"
                    required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <b-form-group
                  class="mb-0">
                <label for="">Age <span class="text-danger">*</span></label>
                <b-form-input
                    id="input-formatter"
                    type="date"
                    v-model="patient_register.age"
                    placeholder="Enter age"
                    :state="patient_age_state"
                    required
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-6">

            <b-form-group v-slot="{ ariaDescribedby }">
              <label for="">Select gender <span class="text-danger">*</span></label>
              <b-form-radio-group
                  id="radio-group-1"
                  v-model="patient_register.gender"
                  :options="gender"
                  :aria-describedby="ariaDescribedby"
                  name="radio-options"
                  required
                  :state="patient_gender_state"
              ></b-form-radio-group>
            </b-form-group>

          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Phone number <span class="text-danger">*</span></label>
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1">+88</span>

                <b-form-input
                    id="input-formatter"
                    v-model="patient_register.phone_number"
                    placeholder="Enter phone number"
                    :state="validation"
                    type="number"
                    required
                ></b-form-input>

                <b-form-invalid-feedback :state="validation">
                  Your phone number must be 11 characters long.
                </b-form-invalid-feedback>

                <b-form-valid-feedback :state="validation">
                  Looks Good.
                </b-form-valid-feedback>

              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <!--    schedule modal end   -->
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import {BookAppointmentMixin} from '../../mixins/BookAppointmentMixin';
import { ResponseMixin } from "../../mixins/core/ResponseMixin";

export default {
  name: "DoctorSearch",
  components: {carousel},
  mixins: [BookAppointmentMixin, ResponseMixin],
  title: 'Book Appointment',
}
</script>

<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}

.image_design {
  height: 200px;
  width: 200px;
  object-fit: contain;
  object-position: center;
}

.btn_choose_sent input {
  -webkit-appearance: none;
  display: block;
  margin: 10px;
  width: 18px;
  height: 18px;
  border-radius: 12px;
  cursor: pointer;
  vertical-align: middle;
  box-shadow: hsla(0, 0%, 100%, .15) 0 1px 1px, inset hsla(0, 0%, 0%, .5) 0 0 0 1px;
  background-color: hsla(0, 0%, 0%, .2);
  background-image: -webkit-radial-gradient(#fff 0%, #fff 15%, #fff 28%, #fff 70%);
  background-repeat: no-repeat;
  -webkit-transition: background-position .15s cubic-bezier(.8, 0, 1, 1),
  -webkit-transform .25s cubic-bezier(.8, 0, 1, 1);
  outline: none;
}

.btn_choose_sent input:checked {
  -webkit-transition: background-position .2s .15s cubic-bezier(0, 0, .2, 1),
  -webkit-transform .25s cubic-bezier(0, 0, .2, 1);
}

.btn_choose_sent input:active {
  -webkit-transform: scale(1.5);
  -webkit-transition: -webkit-transform .1s cubic-bezier(0, 0, .2, 1);
}


/* The up/down direction logic */

.btn_choose_sent input,
.btn_choose_sent input:active {
  background-position: 0 24px;
}

.btn_choose_sent input:checked {
  background-position: 0 0;
}

.btn_choose_sent input:checked ~ input,
.btn_choose_sent input:checked ~ input:active {
  background-position: 0 -24px;
}

.btn_choose_sent {
  background: #EF2D56;
  color: #fff;
  box-shadow: 0 10px 20px rgba(125, 147, 178, .3);
  border: none;
  border-radius: 3px;
  font-size: 16px;
  line-height: 10px;
  padding: 16px 20px 16px 38px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  margin-right: 30px;
  transition: all .3s;
  height: auto;
  cursor: pointer;
  position: relative;
  outline: none;
}

.btn_choose_sent input {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
  top: 2px;
}

.btn_choose_sent input:after {
  position: absolute;
  content: '';
  width: 15rem;
  left: 0;
  right: 0;
  /* background: red; */
  /* z-index: -1; */
  height: 40px;
  top: -10px;
}

.bg_btn_chose_1 {
  background-color: #4391d1 !important;
}


.bg_btn_chose_2 {
  background-color: #4e336fdb !important;
}


.bg_btn_chose_3 {
  background-color: #359dcc !important;
}


/*-=p=--=*/


.btn_choose_sent_check_b {
  background: #EF2D56;
  color: #fff;
  box-shadow: 0 10px 20px rgba(125, 147, 178, .3);
  border: none;
  border-radius: 3px;
  font-size: 16px;
  line-height: 10px;
  padding: 16px 20px 16px 46px;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  margin-right: 30px;
  transition: all .3s;
  height: auto;
  cursor: pointer;
  position: relative;
  outline: none;
}

.card-img-top {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.card-title {
  margin-bottom: 0px;
}

.card-height {
  height: 300px;
  overflow: scroll;
  position: relative;
}

.book_btn {
  position: absolute;
  bottom: 10px;
  width: 90%;
  left: 10px;
}
</style>