<template>
  <div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <h2>Institute</h2>
          <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new institute
          </b-button>
          <b-modal
              id="modal-1"
              size="lg"
              :title="`${is_edit ? 'Update' : 'Create a new'} institute`"
              scrollable
              @hidden="resetModal"
              @ok="handleOk">

            <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

              <b-form-group>
                <label for="">Parient institute</label>
                <v-select
                    :options="items"
                    :reduce="items => items.id"
                    v-model="form.parent_id"
                    label="name">
                </v-select>
              </b-form-group>

              <b-form-group
                  label="Name"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                  :state="title">
                <b-form-input
                    id="name-input"
                    v-model="form.name"
                    :state="title"
                    required/>
              </b-form-group>

              <b-form-group>
                <label for="">Institute type</label>
                <v-select
                    :options="institute_type"
                    :reduce="institute_type => institute_type.id"
                    v-model="form.institute_type_id"
                    label="name">
                </v-select>
              </b-form-group>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group>
                    <label for="">District</label>
                    <v-select
                        :options="districts"
                        :reduce="districts => districts.id"
                        v-model="form.city_id"
                        @input="getThana(form.city_id)"
                        label="name">
                    </v-select>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group>
                    <label for="">Thana</label>
                    <v-select
                        :options="thanas"
                        :reduce="thanas => thanas.id"
                        v-model="form.town_id"
                        label="name">
                    </v-select>
                  </b-form-group>
                </div>
              </div>

              <b-form-group>
                <label for="">Address</label>
                <b-form-textarea
                    id="textarea"
                    v-model="form.address"
                    placeholder="Enter address"
                    rows="3"

                    class="mt-3"
                ></b-form-textarea>
              </b-form-group>

              <b-form-group
                  label="Contact number"
                  label-for="name-input">
                <b-form-input
                    id="name-input"
                    v-model="form.contact_number"/>
              </b-form-group>

              <b-form-group>
                <label for="">Facilities</label>
                <v-select
                    multiple
                    :options="facilities"
                    :reduce="facilities => facilities.slug"
                    v-model="form.facilities"
                    label="title">
                </v-select>
              </b-form-group>
              <div class="row">
                <div class="col-md-6">
                  <b-form-checkbox
                      id="checkbox-1"
                      v-model="form.is_corporate"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                  >
                    Is partner
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-group
                      label="Discount(%)"
                      label-for="name-input">
                    <b-form-input
                        id="name-input"
                        v-model="form.discount"/>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group>
                    <label for="">Upload Institute image </label>
                    <b-form-file
                        class="mt-1"
                        ref="institute_image"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group>
                    <b-form-group
                        label="Website url"
                        label-for="name-input">
                      <b-form-input
                          id="name-input"
                          v-model="form.website_url"/>
                    </b-form-group>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group>
                    <b-form-group
                        label="Google map url"
                        label-for="name-input">
                      <b-form-input
                          id="name-input"
                          v-model="form.google_map_url"/>
                    </b-form-group>
                  </b-form-group>
                </div>

              </div>
            </form>
          </b-modal>
        </div>

        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>

              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Institute list"
                  name="Institute list">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>


            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                class="table-responsive mt-3">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(parent_id)="data">
                {{ data.item.parent ? data.item.parent.name : 'n/a' }}
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
                </td>
              </template>

              <template #cell(action)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">

                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>
                      <b-dropdown-item href="#" @click="editAction(row.item)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <b-dropdown-item :to="`/institute/therapies/${row.item.id}`">
                        <span class="pr-4"><i class="fas fa-stethoscope"></i></span>therapies
                      </b-dropdown-item>
                      <!--                      <b-dropdown-item href="#" @click="deleteAction(row.item)">Delete</b-dropdown-item>-->
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="items.length"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {InstituteMixin} from '../../mixins/InstituteMixin'
import {ResponseMixin} from "../../mixins/core/ResponseMixin";

export default {
  name: "Institute",
  mixins: [InstituteMixin, ResponseMixin],
}
</script>

<style scoped>

</style>
