<template>
  <div>
    <div class="row">
      <div :class="`${basic_info.is_doctor == 1 ? 'col-md-12 ' : 'col-md-12'}`">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-50 symbol-lg-120">
                  <img :src="currentUserPhoto(basic_info.image)" @click="showImage(basic_info.image)" alt="image"/>
                  <!--                  <img src="../../../assets/img/blank.png" alt="" v-else>-->

                  <i :class="basic_info.is_online === 1 ? 'symbol-badge is_active_icon' : 'symbol-badge is_warning_icon'"
                     v-b-tooltip.hover.bottom="'Online'"
                     id="tooltip-button-show-event"></i>

                  <!--                  <i class="is_warning_icon"-->
                  <!--                     v-if="basic_info.is_online == 2"-->
                  <!--                     v-b-tooltip.hover.bottom="'Online'"-->
                  <!--                     id="tooltip-button-show-event"></i>-->

                  <b-modal
                      id="update-online-status"
                      ref="modal"
                      title="Change online status"
                      @show="resetModal"
                      @hidden="resetModal"
                      @ok="handleOkUpdateStatus"
                  >
                    <form ref="form" @submit.stop.prevent="updateStatusComment">
                      <div class="row">
                        <div class="col-md-12">
                          <label for="">Action comment <span class="text-danger">*</span></label>
                          <b-form-textarea
                              id="textarea"
                              v-model="basic_info_form.comment"
                              placeholder="Enter reason ..."
                              rows="3"

                              :state="commentState"
                              required
                          ></b-form-textarea>
                          <b-form-invalid-feedback :state="commentState" v-if="!basic_info_form.comment">
                            Comment is required
                          </b-form-invalid-feedback>
                          <span class="text-danger"><b>Note: </b>Provide a valid reason/comment here. No dummy or irrelevant content will be acceptable</span>
                        </div>
                      </div>
                    </form>
                  </b-modal>


                  <b-tooltip ref="tooltip" target="tooltip-button-show-event">
                    <div>
                      <h5>Change status</h5>
                      <button class="btn btn-success btn-sm font-size-8 mb-3 mr-2" @click="updateStatus('1')">Online
                      </button>
                      <button class="btn btn-warning btn-sm font-size-8 mb-3 mr-2" @click="updateStatus('0')">Offline
                      </button>
                      <button class="btn btn-danger btn-sm font-size-8 mb-3" @click="updateStatus('2')">Busy</button>
                    </div>
                  </b-tooltip>

                  <div class="mt-3">
                    <!--  Confirm doctor-->
                    <a href="#" class="font-weight-bold" v-if="basic_info.confirmed === true"
                       v-b-tooltip.hover.bottom="'Confirmed'">
                      <i class="flaticon2-correct is_active font-size-h5 mr-2"></i>
                      <span class="font-weight-bold font-size-15 is_active">Verified</span>
                    </a>

                    <a href="#" class="text-danger font-weight-bold" v-else v-b-tooltip.hover.bottom="'Not confirm'">
                      <i class="flaticon2-correct is_inactive font-size-h5 mr-2"></i>
                      <span class="font-weight-bold font-size-15">Not verified</span>
                    </a>

                  </div>
                  <!--     otp varified      -->

                  <div class="mb-2">
                    <!--  Confirm doctor-->
                    <a href="#" class="font-weight-bold" v-if="basic_info.otp_verified === true"
                       v-b-tooltip.hover.bottom="'OTP Verified'"
                    ><i class="flaticon2-correct is_active font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15 is_active">OTP Verified</span></a>
                    <a href="#" class="text-danger font-weight-bold" v-else
                       v-b-tooltip.hover.bottom="'Otp not varified'"
                    ><i class="flaticon2-correct is_inactive font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15">Otp not varified</span></a>
                  </div>


                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between mt-1">
                  <div class=" mr-3">
                    <a
                        href="#"
                        class="text-dark-75  font-size-h5 font-weight-bold mr-3">
                      <span class="font-size-h3">{{
                          basic_info.doctor_information.prefix ? basic_info.doctor_information.prefix : ''
                        }} {{ basic_info.fullname ? basic_info.fullname : 'Not set yet' }}</span>
                      <span v-b-tooltip.hover.bottom="'User type'"> ({{
                          basic_info.user_type ? basic_info.user_type : 'Not set yet'
                        }})</span>
                      <span v-b-tooltip.hover.bottom="'User sub type'"> ({{ basic_info.sub_type }})</span>
                      <br>
                    </a>
                    <a
                        href="#"
                        class="text-dark-75  font-size-h5 font-weight-bold mr-3">
                    <span v-if="basic_info.is_doctor" class="font-size-h6">{{
                        basic_info.doctor_information.card_intro ? basic_info.doctor_information.card_intro : 'Not set yet'
                      }}</span>
                    </a>

                    <div class="mt-5 d-flex">
                      <p class="mb-0 text-info mb-2 d-flex align-items-center" v-b-tooltip.hover.bottom="'Level'"><i
                          class="fas fa-medal text-info pr-2 font-weight-boldest"></i> <span
                          class="badge badge-info font-weight-boldest">{{
                          basic_info.doctor_information.level ? basic_info.doctor_information.level : '0'
                        }}</span></p>
                      <p class="text-danger d-flex align-items-center mb-2 ml-3" v-b-tooltip.hover.bottom="'Score'"><i
                          class="fas fa-trophy text-danger pr-2"></i> <span
                          class="badge badge-danger font-weight-boldest"> {{
                          basic_info.doctor_information.score ? basic_info.doctor_information.score : '0'
                        }}</span></p>
                      <p class="text-primary mb-0 d-flex align-items-center ml-3" v-b-tooltip.hover.bottom="'Product'" v-if="basic_info.user_products"><i
                          class="fab fa-product-hunt text-primary pr-2 "></i> <span
                          class="badge badge-primary font-weight-boldest"> {{
                          basic_info.user_products ? basic_info.user_products.product_name : ''
                        }}</span></p>
                    </div>

                    <!-- Online -->
                  </div>
                  <div class="my-lg-0 my-3">


                    <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"
                              v-if="basic_info.is_doctor == 1"
                              v-b-modal.basicInfo @click="editBasicInfo">Edit profile
                    </b-button>
                    <b-button class="btn btn-sm btn-light-info font-weight-bolder text-uppercase mr-3"
                              v-if="basic_info.is_doctor == 1"
                               @click="changePhoneNumber">Change phone number
                    </b-button>

                    <b-modal
                        id="changePhoneNumber"
                        ref="modal"
                        title="Change phone number"
                        @show="resetModal"
                        @hidden="resetModal"
                        @ok="handleOkChangePhonenumber"
                    >
                      <form ref="form" @submit.stop.prevent="handleSubmitChangePhonenumber">
                        <b-form-group
                            label="Old phone number"
                            label-for="name-input"
                            invalid-feedback="Old phone number is required"
                            :state="oldPhoneNumber"
                        >
                          <b-form-input
                              id="name-input"
                              v-model="change_phone.old_phone_number"
                              :state="oldPhoneNumber"
                              required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="New phone number"
                            label-for="name-input"
                            invalid-feedback="Name is required"
                            :state="newPhoneNumber"
                        >
                          <b-form-input
                              id="name-input"
                              v-model="change_phone.new_phone_number"
                              :state="newPhoneNumber"
                              required
                          ></b-form-input>
                        </b-form-group>
                        <sm class="text-danger">Node: Using +880 </sm>
                      </form>
                    </b-modal>


                    <b-modal
                        id="basicInfo"
                        title="Basic info "
                        @hidden="resetModal"
                        size="lg"
                        @ok="handleOk">
                      <b-spinner variant="primary" label="Spinning" v-if="modal_loading"></b-spinner>
                      <form ref="form" @submit.stop.prevent="saveDoctorBasicInfo">

                        <div class="row">
                          <div class="col-4">
                            <b-form-group
                                label-for="firstname"
                                class="mb-0"
                                :state="firstNameState">
                              <label for="firstname">First name <span class="text-danger">*</span></label>
                              <b-form-input
                                  id="firstname"
                                  v-model="basic_info_form.first_name"
                                  :state="firstNameState"
                                  required
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label-for="lastname"
                                class="mb-0"
                            >
                              <label>Last name</label>
                              <b-form-input
                                  id="lastname"
                                  v-model="basic_info_form.last_name"

                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label-for="email"
                                class="mb-0">
                              <label>Email </label>
                              <b-form-input
                                  id="email"
                                  v-model="basic_info_form.email"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-4">
                            <b-form-group
                                label-for="phone"
                                class="mb-0">
                              <label>Phone <span class="text-danger">*</span></label>
                              <b-form-input
                                  id="phone"
                                  v-model="basic_info_form.phone_number"
                                  :state="phoneState"
                                  required
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Gender"
                                label-for="gender"
                                class="mb-0">
                              <select name="" class="form-control" v-model="basic_info_form.gender">
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Is Surgeon?"
                                label-for="is_surgeon"
                                class="mb-0">
                                <b-form-checkbox
                                  id="is_surgeon"
                                  v-model="basic_info_form.is_surgeon"
                                  name="is_surgeon"
                                  class="mt-3"
                                  value="1"
                                  unchecked-value="0">
                                Yes
                              </b-form-checkbox>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Date of birth"
                                label-for="dateofbirth"
                                class="mb-0">
                              <!--                              <b-form-input-->
                              <!--                                  type="date"-->
                              <!--                                  id="dateofbirth"-->
                              <!--                                  v-model="basic_info_form.dob"-->
                              <!--                              ></b-form-input>-->

                              <b-form-datepicker id="example-datepicker" v-model="basic_info_form.dob"
                                                 class="mb-2"></b-form-datepicker>


                            </b-form-group>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-4">
                            <b-form-group
                                label="Blood group"
                                label-for="gender"
                                class="mb-0">
                              <select name="" id="gender" class="form-control" v-model="basic_info_form.blood_group">
                                <option value="A+">A(+)ve</option>
                                <option value="B+">B(+)ve</option>
                                <option value="O+">O(+)ve</option>
                                <option value="AB+">AB(+)ve</option>
                                <option value="A-">A(-)ve</option>
                                <option value="B-">B(-)ve</option>
                                <option value="O-">O(-)ve</option>
                                <option value="AB-">AB(-)ve</option>
                              </select>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group>
                              <label for="">District</label>
                              <v-select
                                  :options="districts"
                                  :reduce="districts => districts.id"
                                  v-model="basic_info_form.region_id"
                                  @input="getThana(basic_info_form.region_id)"
                                  label="name">
                              </v-select>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group>
                              <label for="">Thana</label>
                              <v-select
                                  :options="thanas"
                                  :reduce="thanas => thanas.id"
                                  v-model="basic_info_form.town_id"
                                  label="name">
                              </v-select>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label-for="location"
                                class="mb-0">
                              <label>Location </label>
                              <b-form-textarea
                                  id="location"
                                  v-model="basic_info_form.location"
                              ></b-form-textarea>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group label="Profile image">
                              <b-form-file
                                  id="profile_image"
                                  ref="file"
                                  accept=".png,.jpg,.jpeg">
                              </b-form-file>
                            </b-form-group>
                          </div>

                        </div>
                        <div class="row mt-5">
                          <div class="col">
                            <b-form-group
                                label-for="nid"
                                class="mb-0">
                              <label>Card type </label>
                              <b-form-select
                                  v-model="basic_info_form.identity_card_type"
                                  :options="identity_card_type">
                              </b-form-select>
                            </b-form-group>

                          </div>
                          <div class="col">
                            <b-form-group
                                label-for="nid"
                                class="mb-0">
                              <label>Card number</label>
                              <b-form-input
                                  id="nid"
                                  v-model="basic_info_form.identity_card_no"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <hr class="mt-10">
                        <div class="row">'
                          <div class="col-md-12">
                            <label for="">Action comment <span class="text-danger">*</span></label>
                            <b-form-textarea
                                id="textarea"
                                v-model="basic_info_form.comment"
                                placeholder="Enter reason ..."
                                rows="3"

                                :state="commentState"
                                required
                            ></b-form-textarea>

                          </div>
                        </div>

                      </form>
                    </b-modal>
                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <!--                    <div class="d-flex flex-wrap mb-4">-->
                    <div>
                      <div class="row">
                        <div class="col-md-10">
                          <div class="d-flex flex-wrap">
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Email'"
                            ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                            >{{ basic_info.email ? basic_info.email : 'Not set yet' }}</a>
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Location'"
                            ><i class="fas fa-map-marker-alt mr-2 font-size-lg"></i>
                              {{ basic_info.location ? basic_info.location : 'Not set yet' }} .
                              <span><b>Town</b>: {{ basic_info.town_name ? basic_info.town_name + ' , ' : ' ' }}</span>
                              <span><b>Region</b>: {{ basic_info.region ? basic_info.region + ' , ' : ' ' }}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-9">
                          <div class="d-flex flex-wrap mb-4">
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Phone number'"
                            ><i class="flaticon2-phone mr-2 font-size-lg"></i>
                              {{ basic_info.phone_number ? basic_info.phone_number : 'Not set yet' }}
                            </a>
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Gender'"
                            ><i class="fas fa-transgender mr-3 font-size-lg"></i>
                              {{ basic_info.gender ? basic_info.gender : 'Not set yet' }}
                            </a>
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Birth day'"
                            ><i class="fas fa-birthday-cake mr-2 font-size-lg"></i>DOB: {{
                                basic_info.dob ? basic_info.dob : 'Not set yet'
                              }}
                            </a>
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Blood group'"
                            ><i class="fas fa-vial mr-2 font-size-lg"></i> {{
                                basic_info.blood_group ? basic_info.blood_group : 'Not set yet'
                              }}
                            </a>
                            <a
                                href="#"
                                @click="showImage(basic_info.identity_card)"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="`${basic_info.identity_card_type ? basic_info.identity_card_type : 'Not set yet'}`"
                            ><i class="fas fa-id-card mr-2 font-size-lg"></i>
                              <span class="text-capitalize">{{
                                  basic_info.identity_card_type ? basic_info.identity_card_type : basic_info.identity_card_type
                                }}</span>
                              :
                              {{ basic_info.identity_card_no ? basic_info.identity_card_no : 'Not set yet' }}

                              <span><i class="far fa-eye"></i></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
          </div>
        </div>
      </div>
    </div>

    <div class="row " v-if="basic_info.is_doctor == 1">
      <div class="col-md-12">
        <doctor-confirmation :basic_info="basic_info" @clicked="basic_info"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BasicInfo",
  props: {
    basic_info: {},
  },
  data() {
    return {
      loading: false,
      basic_data: {
        id_type: '',
        basic_info: '',
        id_image: '',
        id_no: '',
        bmdc_no: '',
        registration_year: '',
        authority_name: '',
        blood_group: '',
        registration_authority_id: '',

      },
      id_types: [
        {
          value: 'nid',
          text: 'NID',
        },
        {
          value: 'passport',
          text: 'Passport',
        },
      ],
      authority_names: [
        {
          value: '1' +
              '',
          text: 'BMDC',
        },
        {
          value: '2',
          text: 'DGHS',
        },
      ],
      status: '',
      modal_loading: false,
      blood: [
        {
          id: 'A(+)ve',
          name: 'A(+)ve',
        },
        {
          id: 'B(+)ve',
          name: 'B(+)ve',
        },
        {
          id: 'O(+)ve',
          name: 'O(+)ve',
        },
        {
          id: 'AB(+)ve',
          name: 'AB(+)ve',
        },
        {
          id: 'A(-)ve',
          name: 'A(-)ve',
        },
        {
          id: 'B(-)ve',
          name: 'B(-)ve',
        },
        {
          id: 'O(-)ve',
          name: 'O(-)ve',
        },
        {
          id: 'AB(-)ve',
          name: 'AB(-)ve',
        },
      ],
      basic_info_form: {
        first_name: "",
        last_name: "",
        gender: '',
        is_surgeon: 0,
        location: '',
        blood_group: '',
        phone_number: '',
        email: '',
        dob: null,
        identity_card_no: '',
        identity_card_type: '',
        comment: '',
        region_id: '',
        town_id: '',
      },
      user_api_toke: '',
      identity_card_type: [
        {
          text: 'Passport',
          value: 'passport'
        },
        {
          text: 'NID',
          value: 'nid'
        },
      ],
      commentState: null,
      firstNameState: null,
      lastNameState: null,
      emailState: null,
      phoneState: null,
      locationState: null,
      is_online_status: '',
      oldPhoneNumber: null,
      newPhoneNumber: null,
      change_phone: {
        new_phone_number: '',
        old_phone_number: '',
      },
      districts: [],
      thanas: [],
    }
  },

  methods: {
    currentUserPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
      return process.env.BASE_URL + 'media/logos/logo.svg';
    },
    resetModal() {
      // this.name = ''
      this.departmentState = null
      this.is_online_status = '';
      this.commentState = null;
      this.basic_info_form.comment = '';
    },
    checkValidationForAddPatient() {
      const valid = this.$refs.form.checkValidity()
      this.commentState = valid;
      return valid;
    },
    checkValidationChangePhoneNumber() {
      const valid = this.$refs.form.checkValidity()
      this.oldPhoneNumber = valid;
      this.newPhoneNumber = valid;
      return valid;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.saveDoctorBasicInfo()
    },
    handleOkChangePhonenumber(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmitChangePhonenumber()
    },
    handleOkUpdateStatus(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.updateStatusComment()
    },
    getDistrict() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=city&parent_id=18`)
          .then(response => {
            this.districts = response.data.location;
          })
    },
    getThana(district_id) {
      this.basic_info_form.town_id = '';
      axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=town&parent_id=${district_id}`)
          .then(response => {
            this.thanas = response.data.location;
          })
    },
    saveDoctorBasicInfo() {
      if (!this.checkValidationForAddPatient()) {
        return
      }
      let form = new FormData();
      let profile_image = document.getElementById('profile_image').files[0]
      form.append("first_name", this.basic_info_form.first_name);
      if (this.basic_info_form.last_name) {
        form.append("last_name", this.basic_info_form.last_name);
      }
      if (this.basic_info_form.email) {
        form.append("email", this.basic_info_form.email);
      }

      form.append("phone_number", this.basic_info_form.phone_number);
      if (this.basic_info_form.gender) {
        form.append("gender", this.basic_info_form.gender);
      }

      form.append("is_surgeon", this.basic_info_form.is_surgeon);

      if (this.basic_info_form.dob) {
        form.append("dob", this.basic_info_form.dob);
      }
      if (this.basic_info_form.blood_group) {
        form.append("blood_group", this.basic_info_form.blood_group);
      }
      if (this.basic_info_form.location) {
        form.append("location", this.basic_info_form.location);
      }

      if (this.basic_info_form.identity_card_no) {
        form.append("identity_card_no", this.basic_info_form.identity_card_no);
      }
      if (this.basic_info_form.identity_card_type) {
        form.append("identity_card_type", this.basic_info_form.identity_card_type);
      }
      if (this.basic_info_form.region_id) {
        form.append("city_id", this.basic_info_form.region_id);
      }
      if (this.basic_info_form.town_id) {
        form.append("town_id", this.basic_info_form.town_id);
      }
      form.append("comment", this.basic_info_form.comment);
      if (profile_image) {
        form.append("profile_img", profile_image);
      }


      axios
          .post(`${process.env.VUE_APP_URL}/api/v1/doctor/profile/overview/update?api_token=${localStorage.getItem('api_token')}&platform=backoffice&user_id=${this.basic_info.id}`, form)
          .then(response => {
            if (response.data.status_code == 400) {
              return this.$snotify.error(response.data.message);
            }
            this.$emit('call_basic_info')
            this.resetModal();
            this.$bvModal.hide('basicInfo')
            this.$snotify.success('User basic information has been updated successfully');
          })

    },
    editBasicInfo() {
      this.$bvModal.show('basicInfo');
      this.modal_loading = true;

      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.getDistrict();
            this.getThana(response.data.data.region_id)
            this.modal_loading = false
            this.basic_info_form = response.data.data;

            this.user_api_toke = response.data.data.api_token;
          })
    },
    showImage(img) {
      const h = this.$createElement
      // Using HTML string
      // const titleVNode = h('div', {domProps: {innerHTML: 'Signature'}})


      // More complex structure
      const messageVNode = h('div', {class: ['foobar']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
            thumbnail: true,
            center: false,
          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        // title: [titleVNode],
        buttonSize: 'sm',
        centered: true, size: 'lg'
      })
    },
    updateStatusComment() {
      if (!this.checkValidationForAddPatient()) {
        return
      }
      let form = new FormData();
      form.append('is_online', this.is_online_status);
      form.append("comment", this.basic_info_form.comment);

      axios
          .post(`${process.env.VUE_APP_URL}/api/v1/doctor/profile/overview/update?user_id=${this.basic_info.id}&api_token=${localStorage.getItem('api_token')}&platform=backoffice`, form)
          .then(response => {
            if (response.data.status_code == 400) {
              return this.$snotify.error(response.data.message);
            }
            this.$emit('call_basic_info')
            this.resetModal();
            this.$bvModal.hide('update-online-status')
            this.$snotify.success('Your online status has been updated successfully');
          })
    },

    updateStatus(status) {
      this.$bvModal.show('update-online-status');
      this.is_online_status = status;
    },
    changePhoneNumber() {
      this.$bvModal.show('changePhoneNumber');
    },
    handleSubmitChangePhonenumber() {
      if(!this.checkValidationChangePhoneNumber){
        return
      }
      let form = new FormData();
      form.append('phone_number', this.change_phone.new_phone_number);
      form.append('current_phone_number', this.change_phone.old_phone_number);
      form.append('platform', 'backoffice');
      axios
          .post(`${process.env.VUE_APP_URL}/api/v2/user/profile/update-credential?user_id=${this.basic_info.id}&api_token=${localStorage.getItem('api_token')}&platform=backoffice`, form)
          .then(response => {
            if (response.data.status_code == 400) {
              return this.$snotify.error(response.data.message);
            }
            this.$emit('call_basic_info')
            this.resetModal();
            this.$bvModal.hide('changePhoneNumber')
            this.$snotify.success('Phone number changed successfully');
          })
    }

  },
  watch: {
    'this.basic_info_form.date_of_birth': function () {
    }
  }
}
</script>

<style scoped>
.font-size-8 {
  font-size: 10px;
  font-width: bold;
  padding: 2px 5px;
}
</style>
