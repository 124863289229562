<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button type="button" class="btn btn-primary" @click="addNewTarget">Add new Target</button>
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-3">
          <label for="">Select TSM</label>
          <b-form-select
              id="doctor_type"
              v-model="tsm_id"
              :options="sales_users"
              text-field="name"
              value-field="id">
          </b-form-select>
        </div>
        <div class="col-md-2">
          <label for="">Select Year</label>
          <b-form-select
              id="doctor_type"
              v-model="year"
              :options="years">
          </b-form-select>
        </div>
        <div class="col-md-2">
          <label for="">Select Month</label>
          <b-form-select id="doctor_type" v-model="month" :options="months"></b-form-select>
        </div>
        <div class="col-md-3">
          <label for="">Select Doctor type</label>
          <b-form-select
              id="doctor_type"
              v-model="doctor_type"
              :options="doctor_types">
          </b-form-select>
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems" v-if="year && month">Filter</button>
          <button class="btn btn-success" disabled v-else>Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(doctor_type)="row">
              {{ (doctor_type_converter[row.item.doctor_type]) ?  doctor_type_converter[row.item.doctor_type] : '-'}}
            </template>
          </b-table>
        </div>
      </div>
      <b-modal
          id="addTarget"
          ref="modal"
          title="Add new target"
          @hidden="resetModal"
          @ok="handleOk"
          size="lg">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="6" class="my-5">
              <label for="doctor_type">Select TSM</label>
              <b-form-select
                  id="doctor_type"
                  v-model="form.user_id"
                  :options="sales_users"
                  text-field="name"
                  value-field="id">
              </b-form-select>
            </b-col>
            <b-col cols="6" class="my-5">
              <label for="doctor_type">Doctor Type</label>
              <b-form-select id="doctor_type" v-model="form.doctor_type" :options="doctor_types"></b-form-select>
            </b-col>
            <b-col cols="6">
              <label for="doctor_type">Month</label>
              <b-form-select id="doctor_type" v-model="form.month" :options="months"></b-form-select>
            </b-col>
            <b-col cols="6">
              <label for="year">Year</label>
              <b-form-select
                  id="doctor_type"
                  v-model="form.year"
                  :options="years">
              </b-form-select>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Target</label>
              <b-form-input v-model="form.target"></b-form-input>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";

export default {
  name: "SalesCallTarget",
  mixins: [SalesUserMixins],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales Call Target", route: "/sales/call/target"},
    ]);
  },
  data() {
    let all_months = [
      {
        text: 'January',
        value: 'January'
      },
      {
        text: 'February',
        value: 'February'
      },
      {
        text: 'March',
        value: 'March'
      },
      {
        text: 'April',
        value: 'April'
      },
      {
        text: 'May',
        value: 'May'
      },
      {
        text: 'June',
        value: 'June'
      },
      {
        text: 'July',
        value: 'July'
      },
      {
        text: 'August',
        value: 'August'
      },
      {
        text: 'September',
        value: 'September'
      },
      {
        text: 'October',
        value: 'October'
      },
      {
        text: 'November',
        value: 'November'
      },
      {
        text: 'December',
        value: 'December'
      },
    ];
    return {
      items: [],
      fields: [
        {
          key: 'user_name',
          label: 'TSM name',
          sortable: true,
        },
        {
          key: 'doctor_type',
          label: 'Doctor type',
          sortable: true,
        },
        {
          key: 'month',
          label: 'Month',
          sortable: true,
        },
        {
          key: 'year',
          label: 'Year',
          sortable: true,
        },
        {
          key: 'target',
          label: 'Target',
          sortable: true,
        },
        {
          key: 'achievement',
          label: 'Achievement',
          sortable: true,
        },
      ],
      loading: false,
      form: {
        user_id: '',
        user_name: '',
        doctor_type: '',
        month: '',
        year: '',
        target: '',
      },
      doctor_types: [
        {
          text: 'Celebrity',
          value: 'celebrity doctors'
        },
        {
          text: 'Senior',
          value: 'senior doctors'
        },
        {
          text: 'Mid',
          value: 'mediocre doctors'
        },
        {
          text: 'Junior',
          value: 'junior doctors'
        },
      ],
      months: all_months,
      nameState: null,
      tsm_id: '',
      // month: '',
      // year: '',
      doctor_type: '',
      current_year: new Date,
      start_year: 2022,
      month: all_months[new Date().getMonth()].value,
      year: new Date().getFullYear(),
      years: [],
      doctor_type_converter: {
        'celebrity doctors': 'Celebrity',
        'senior doctors': 'Senior',
        'mediocre doctors': 'Mid',
        'junior doctors': 'Junior',
      }
    }
  },
  created() {
    localStorage.setItem('tabIndex', 14)
    this.$root.$emit('call-active-menu', 14);
    this.getItems();
    this.setYears(15);
  },
  methods: {
    setQueryParams() {
      let params = {};
      if (this.tsm_id) {
        params.user_id = this.tsm_id;
      }
      if (this.month) {
        params.month = this.month;
      }
      if (this.year) {
        params.year = this.year;
      }
      if (this.doctor_type) {
        params.doctor_type = this.doctor_type;
      }
      return params;
    },
    getItems() {
      this.loading = true;
      let url = `${process.env.VUE_APP_URL}/api/v2/sales/team/sales/target/list?api_token=${localStorage.getItem('api_token')}${this.tsm_id ? '&user_id='+this.tsm_id : ''}${this.month ? '&month='+this.month : ''}${this.year ? '&year='+this.year : ''}${this.doctor_type ? '&doctor_type='+this.doctor_type : ''}`;
      this.$router.replace({
        query: this.setQueryParams()
      })
      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    addNewTarget() {
      this.$bvModal.show('addTarget')
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.form = {};
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      let sales_user_name = this.sales_users.find(item => item.id == this.form.user_id);
      this.form.user_name = sales_user_name.name;
      this.$nextTick(() => {
        axios.post(`${process.env.VUE_APP_API_URL}/api/v2/sales/team/sales/target/set?api_token=${localStorage.getItem('api_token')}`, this.form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addTarget')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
    setYears(num) {
     for(let i = 0; i<num; i++) {
       this.years.push(this.start_year + i);
     }
    },
    clear(){
      this.tsm_id = '';
      this.month = '';
      this.year = '';
      this.doctor_type = '';
      this.getItems();
    },
  }
}
</script>

<style scoped>

</style>
