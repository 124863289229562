<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 d-flex align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
        > Latest prescription (10)</span>
        </h3>
        <router-link to="/prescriptions" class="text-success">View</router-link>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">


        <div class="tab-content">
          <!--begin::Table-->
          <div class="table-responsive">
            <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
              <tr class="text-left">
                <th style="min-width: 120px" class="pl-7">
                  <span class="text-dark-75">Doctor</span>
                </th>
                <th style="min-width: 120px">Patient</th>
                <th style="min-width: 100px">Prescription identifier</th>
                <th style="min-width: 100px">Payment status</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(item, i) in items">
                <tr v-bind:key="i">
                  <td class="pl-0 py-4">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="">
                          <img
                              v-if="item.doctor_image"
                              :src="userPhoto(item.doctor_image)"
                              class="user_image align-self-end"
                              alt=""
                          />
                          <img src="../../../assets/img/blank.png" class="user_image" v-else>
                        </span>
                      </div>
                      <div>
                        <p
                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {{ item.doctor_name }}
                        </p>
                        <div>
                          <span>{{ item.doctor_phone_number }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="pl-0 py-4">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="">
                          <img
                              v-if="item.patient_image"
                              :src="userPhoto(item.patient_image)"
                              class="user_image align-self-end"
                              alt=""
                          />
                          <img src="../../../assets/img/blank.png" class="user_image" v-else>
                        </span>
                      </div>
                      <div>
                        <p
                                     class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {{ item.patient_name }}
                        </p>
                        <div>
                          <span>{{ item.patient_phone_number }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a target="_blank" :href="`/${item.url}`" class="text-dark-75 font-weight-bolder d-block font-size-lg">{{
                        item.prescription_identifier ? item.prescription_identifier : '-'
                      }}</a>
                  </td>
                  <td>
                    <b-badge pill variant="success" v-if="item.is_paid == 1">Paid</b-badge>
                    <b-badge pill variant="danger" v-else>Unpaid</b-badge>

<!--                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{-->
<!--                        item.is_paid == 1 ? 'Paid' : 'Unpaid'-->
<!--                      }}</span>-->
                  </td>


                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "LatestPrescription",
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.getTopScorer();
  },
  methods: {
    getTopScorer() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/prescription/latest?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.items = res.data.data;
          })
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
    },
  }
}
</script>

<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>