<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h1 class="mb-10">
          {{ user.fullname }}'s
          <span class="text-muted">transaction statement</span>
        </h1>
        <div class="row mt-3 justify-content-between align-items-center">
          <!--          <div class="col-md-2">-->
          <!--            <div class="">-->
          <!--              <b-form-group-->
          <!--                  class="mb-0">-->
          <!--                <b-form-select-->
          <!--                    id="per-page-select"-->
          <!--                    v-model="perPage"-->
          <!--                    :options="pageOptions"-->
          <!--                    size="sm"-->
          <!--                ></b-form-select>-->
          <!--              </b-form-group>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-md-2">
            <a href="#" class="btn btn-success" @click="generateReport()">
              <i class="fas fa-file-pdf"></i> Download
            </a>
          </div>
          <div class="col-md-7">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

          </div>
          <div class="col-md-1">
            <b-button @click="getTransactionStatement()" variant="info"><i class="fas fa-redo-alt"></i></b-button>
          </div>
        </div>
        <vue-html2pdf
            :html-to-pdf-options="html_options"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="false"
            :manual-pagination="false"
            pdf-format="a4"
            :paginate-elements-by-height="1400"
            @beforeDownload="beforeDownload($event)"
            ref="html2Pdf">

          <section slot="pdf-content">
            <div class="body" id="download"
                 style="margin:0;padding:0;width: 100%; background-color: #FFFFFF;font-size: 12px; font-family: Raleway;">
              <div class="header"
                   style="background-color: #DDDDDD; color:#000; display: inline-block; width:100%; margin-bottom: 0px; min-height: 255px; ">
                <div class="row pheader" style="margin-top: 10px;  margin-bottom: 70px;">
                  <div class="col-md-12 pheader-content-intro" style="margin-top: 50px; margin-left: 50px">
                    <div class="column" style="float: left;flex: 50%;">
                      <div
                          style="margin-left:10px; margin-top: 5px; position: relative; display: inline-block; max-width: 250px; vertical-align: top;">
                        <strong style="font-size: 16px;">Address</strong><br>
                        <span style="word-wrap: break-word;">Orko Health Ltd., <br> YN Centre, House 6/A, Road 113, 2nd floor, Gulshan Avenue <br>Phone: +81 720 2212 <br>Email: ask@myorko.com</span>
                      </div>
                    </div>

                    <div class="column" style="float: left;flex: 40%;margin-top: 40px">
                      <h1 style="white-space: nowrap; white-space: nowrap; font-size: 20px; margin-left: 25%;">
                        Transaction
                        Statement</h1>
                    </div>
                    <div class="column"
                         style="float:right; margin-right: 20px; display: inline-block; flex: 50%; padding-bottom: 7%;">
                      <div
                          style=" background-size:contain; background-repeat:no-repeat;">
                        <img src="../../../assets/img/logo.svg" alt=""
                             style="float: right; width: 140px; margin-right: 60px;">
                      </div>
                      <br>
                      <span style="font-weight:bold; color: #F9A91A;">Connect. Care</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 pheader-secondary" style="background-color: #F9A91A;
                display: inline-block; margin-top:-70px ;margin-bottom: 0px;
                width:100%; padding:0;min-height: 80px; border-radius: 35px 35px 0px 0px">
                <div style="margin-top: 15px;margin-bottom: 50px;">
                  <div class="column" style="float: left;flex: 70%; width: 70%">
                    <span style="margin-top: 55px; margin-left: 60px;"> <strong>Name: </strong> {{
                        user.prefix ? user.prefix : 'Dr.'
                      }} {{ user.fullname }}</span>
                    <span style="display: block;margin-left: 60px;">Phone number: {{ user.phone_number }}</span>
                  </div>
                  <div class="column" style="float: right;flex: 30%;width: 30%">
                    <span style="float: left; margin-left: 10%;"> Statement from: <b>{{
                        new Date() | moment("D/M/Y") | subtract(1, 'months')
                      }}</b> to <b> {{ new Date() | moment("D/M/Y") }}</b> </span>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div>
                  <div>
                    <table class="table table-bordered print-friendly" style="width:100%; height: 50%;">
                      <thead style="">
                      <tr>
                        <th>Trxn date</th>
                        <th style="width: 35rem;">Description</th>
                        <th>Credit</th>
                        <th>Debit</th>

                        <th>Balance</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item, index) in items" :key="index" class="tr"
                          style="page-break-after: auto !important;page-break-inside: avoid !important;">
                        <td>{{ item.transaction_date }}</td>
                        <td>{{ item.description }}</td>
                        <td>{{ item.credit }}</td>
                        <td>{{ item.debit }}</td>

                        <td>{{ item.balance }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td>{{ totalCredit }}</td>
                        <td>{{ totalDebit }}</td>

                        <td>
                          {{ totalCredit - totalDebit }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div>
                <div style="float: left;width: 100%; text-align: right;">
                  <p style="margin-right: 60px;margin-bottom: 0px"><strong>Statement generation Date:</strong>
                    {{ new Date() | moment("D/M/Y") }}</p>
                  <img src="../../../assets/img/logo.svg" alt=""
                       style="float: right; height: 40px;text-align: right;margin-right: 60px;">
                </div>
              </div>
              <div>
                <div class="column" style="text-align: center;width: 100%;">
                  <p style="margin: 0px;text-align: center;"><strong>N.T:</strong>
                    This Statement is auto generated by Orko Health Ltd.
                  </p>
                </div>
              </div>
            </div>
            <!--            <TransactionStatementPdf :items="items" :user="user" :user-id="this.$route.params.id"/>-->
          </section>

        </vue-html2pdf>
        <div class="row mt-5 align-items-center">
          <div class="col-md-4">
            <label for="">From</label>
            <input type="date" v-model="startDate" class="form-control">
          </div>
          <div class="col-md-4">
            <label for="">To</label>
            <input type="date" v-model="endDate" class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                responsive
                :items="items"
                :fields="fields"
                :current-page="currentPage"

                :sort-by.sync="sortBy"
                :filter="filter"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-10">


              <template #cell(transactions)="row">
                <div v-if="row.item.transaction_date">
                  <div>
                    {{ row.item.transaction_date }}
                  </div>
                </div>
                <div v-else>
                  <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
                </div>
              </template>

              <template #cell(description)="row">
                <div v-if="row.item.description">
                  <div>
                    {{ row.item.description }}
                  </div>
                </div>
                <div v-else>
                  <b-badge variant="danger" class="font-size-10"> Data not found</b-badge>
                </div>
              </template>

              <template #cell(debit)="row">
                <div v-if="row.item.debit">
                  <div>
                    {{ row.item.debit }}
                  </div>
                </div>
                <div v-else>
                  0.00
                </div>
              </template>

              <template #cell(credit)="row">
                <div v-if="row.item.credit">
                  <div>
                    {{ row.item.credit }}
                  </div>
                </div>
                <div v-else>
                  0.00
                </div>
              </template>

              <template #cell(balance)="row">
                <div v-if="row.item.balance">
                  <div>
                    {{ row.item.balance }}
                  </div>
                </div>
                <div v-else>
                  0.00
                </div>
              </template>

            </b-table>
          </div>
        </div>

        <!--        <div class="row mt-3" v-if="items">-->
        <!--          <div class="col-md-12">-->
        <!--            <b-pagination-->
        <!--                v-model="currentPage"-->
        <!--                :total-rows="items.length"-->
        <!--                :per-page="perPage"-->
        <!--                size="lg"-->
        <!--                class="my-0"-->
        <!--            ></b-pagination>-->
        <!--          </div>-->
        <!--        </div>-->

      </div>
    </div>
    <loader-component v-if="loading"/>
  </div>
</template>


<script>
import {DoctorTransactionStatementMixin} from "../../../mixins/DoctorTransactionStatementMixin";

export default {
  name: "DoctorTransactionStatement",
  mixins: [DoctorTransactionStatementMixin],

}
</script>

<style scoped>


</style>