<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      
      <div class="row mt-5">
        <div class="col-12">
          <div class="d-flex justify-content-between align-content-center align-items-center">
            <h3>Chamber info</h3>
            <b-button class="btn btn-sm btn-light-info font-weight-bolder text-uppercase mr-3"
                      @click="addNewChamber">
              Add chamber

            </b-button>
          </div>

          <b-modal
              id="addChamber"
              :title="`${is_edit ?  'Edit' : 'Add'} Chamber`"
              @hidden="resetModal"
              size="lg"
              @ok="handleChamber">
            <b-spinner variant="primary" label="Spinning" v-if="is_modal_loading"></b-spinner>

            <form ref="form" v-else @submit.stop.prevent="saveChamber">
              <div>

                <div class="row align-items-center">
                  <div class="col-md-9">
                    <b-form-group>
                      <label for="">Select institute <span class="text-danger"> * </span></label>
                      <v-select
                          :options="institutes"
                          :reduce="institutes => institutes.id"
                          v-model="chamber.institute_id"
                          label="name"
                          class="h-100"
                          name="id"
                          required>
                      </v-select>
                      <p class="text-danger"> {{ institute_is_required }} </p>
                    </b-form-group>
                  </div>
                  <div class="col-md-3">
                    <button class="btn btn-dark" @click="addNewInstitute">Add new institute</button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <b-form-group
                        label="Primary Contact no.">
                      <b-form-input v-model="chamber.primary_contact_no"></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-md-6">
                    <b-form-group
                        label="Secondary Contact no.">
                      <b-form-input v-model="chamber.secondary_contact_no"></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-md-6" v-if="is_edit">
                    <b-form-group
                        id="fieldset-1"
                        label="Select Active Status">
                        <b-form-select v-model="chamber.is_active" :options="activeOptions"></b-form-select>
                    </b-form-group>

                  </div>
                </div>


                <b-form-group
                    id="fieldset-1"
                    label="Time slot">
                  <b-form-input id="input-1" v-model="telimedicine.time_slot" required></b-form-input>
                  <p class="text-danger"> {{ slot_is_required }} </p>
                </b-form-group>

                <b-button-group size="sm" style="width: 100%">
                  <b-button
                      v-for="(btn, idx) in weeks"
                      :key="idx"
                      @click="clickDay(btn.caption)"
                      :pressed.sync="btn.state"
                      variant="primary"
                  >
                    {{ btn.caption }}
                  </b-button>
                </b-button-group>

                <!--       Sunday slot         -->
                <div v-if="chamber_is_sunday" class="row">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <b-card-text class="">
                        <h5>Sunday</h5>
                        <div class="row align-content-center align-items-center mb-5"
                             v-for="(sun, index) in sunday" :key="index">

                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="Start time slot"
                                label-for="sunday_  start_time"
                                class="mb-0">
                              <b-form-input id="sunday_start_time" type="time"
                                            v-model="sun.sunday_start_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="End time slot"
                                label-for="sunday_end_time"
                                class="mb-0"
                                type="time">
                              <b-form-input id="sunday_end_time" type="time"
                                            v-model="sun.sunday_end_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-2">
                            <button type="button" class="btn btn-danger mt-8" @click="removeItem(sun)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <b-button variant="info" class="float-right mt-2" @click="AddItem()" v-if="sunday.length < 3">
                          Add more
                        </b-button>
                      </b-card-text>
                    </div>

                  </div>
                </div>

                <!--       Monday slot         -->
                <div v-if="chamber_is_monday" class="row mt-2">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <b-card-text class="">
                        <h5>Monday</h5>
                        <div class="row align-content-center align-items-center mb-5"
                             v-for="(mon, index) in monday" :key="index">

                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="Start time slot"
                                label-for="sunday_  start_time"
                                class="mb-0">
                              <b-form-input id="sunday_start_time" type="time"
                                            v-model="mon.monday_start_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="End time slot"
                                label-for="sunday_end_time"
                                class="mb-0"
                                type="time">
                              <b-form-input id="sunday_end_time" type="time"
                                            v-model="mon.monday_end_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-2">
                            <button type="button" class="btn btn-danger mt-8" @click="removeMonday(mon)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <b-button variant="info" class="float-right mt-2" @click="AddMondayItem()"
                                  v-if="monday.length < 3">Add more
                        </b-button>
                      </b-card-text>
                    </div>

                  </div>
                </div>

                <!--       Tuesday slot         -->
                <div v-if="chamber_is_tuesday" class="row mt-2">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <b-card-text class="">
                        <h5>Tuesday</h5>
                        <div class="row align-content-center align-items-center mb-5"
                             v-for="(tues, index) in tuesday" :key="index">

                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="Start time slot"
                                label-for="sunday_  start_time"
                                class="mb-0">
                              <b-form-input id="sunday_start_time" type="time"
                                            v-model="tues.tuesday_start_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="End time slot"
                                label-for="sunday_end_time"
                                class="mb-0"
                                type="time">
                              <b-form-input id="sunday_end_time" type="time"
                                            v-model="tues.tuesday_end_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-2">
                            <button type="button" class="btn btn-danger mt-8" @click="removeTuesday(tues)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <b-button variant="info" class="float-right mt-2" @click="AddTuesdayItem()"
                                  v-if="tuesday.length < 3">Add more
                        </b-button>
                      </b-card-text>
                    </div>

                  </div>
                </div>

                <!--       Wednesday slot         -->
                <div v-if="chamber_is_wednesday" class="row mt-2">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <b-card-text class="">
                        <h5>Wednesday</h5>
                        <div class="row align-content-center align-items-center mb-5"
                             v-for="(wed, index) in wednesday" :key="index">

                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="Start time slot"
                                label-for="sunday_  start_time"
                                class="mb-0">
                              <b-form-input id="sunday_start_time" type="time"
                                            v-model="wed.wednesday_start_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="End time slot"
                                label-for="sunday_end_time"
                                class="mb-0"
                                type="time">
                              <b-form-input id="sunday_end_time" type="time"
                                            v-model="wed.wednesday_end_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-2">
                            <button type="button" class="btn btn-danger mt-8" @click="removeWednesday(wed)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <b-button variant="info" class="float-right mt-2" @click="AddWednesdayItem()"
                                  v-if="wednesday.length < 3">Add more
                        </b-button>
                      </b-card-text>
                    </div>

                  </div>
                </div>

                <!--       Thursday slot         -->
                <div v-if="chamber_is_thursday" class="row mt-2">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <b-card-text class="">
                        <h5>Thursday</h5>
                        <div class="row align-content-center align-items-center mb-5"
                             v-for="(thur, index) in thursday" :key="index">

                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="Start time slot"
                                label-for="sunday_  start_time"
                                class="mb-0">
                              <b-form-input id="sunday_start_time" type="time"
                                            v-model="thur.thursday_start_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="End time slot"
                                label-for="sunday_end_time"
                                class="mb-0"
                                type="time">
                              <b-form-input id="sunday_end_time" type="time"
                                            v-model="thur.thursday_end_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-2">
                            <button type="button" class="btn btn-danger mt-8" @click="removeThursday(thur)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <b-button variant="info" class="float-right mt-2" @click="AddThursdayItem()"
                                  v-if="thursday.length < 3">Add more
                        </b-button>
                      </b-card-text>
                    </div>

                  </div>
                </div>

                <!--       Friday slot         -->
                <div v-if="chamber_is_friday" class="row mt-2">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <b-card-text class="">
                        <h5>Friday</h5>
                        <div class="row align-content-center align-items-center mb-5"
                             v-for="(fri, index) in friday" :key="index">

                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="Start time slot"
                                label-for="sunday_start_time"
                                class="mb-0">
                              <b-form-input id="sunday_start_time" type="time"
                                            v-model="fri.friday_start_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="End time slot"
                                label-for="sunday_end_time"
                                class="mb-0"
                                type="time">
                              <b-form-input id="sunday_end_time" type="time"
                                            v-model="fri.friday_end_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-2">
                            <button type="button" class="btn btn-danger mt-8" @click="removeFriday(fri)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <b-button variant="info" class="float-right mt-2" @click="AddFridayItem()"
                                  v-if="friday.length < 3">Add more
                        </b-button>
                      </b-card-text>
                    </div>

                  </div>
                </div>

                <!--       Saturday slot         -->
                <div v-if="chamber_is_saturday" class="row mt-2">
                  <div class="col-md-12">
                    <div class="card p-5">
                      <b-card-text class="">
                        <h5>Saturday</h5>
                        <div class="row align-content-center align-items-center mb-5"
                             v-for="(sat, index) in saturday" :key="index">

                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="Start time slot"
                                label-for="sunday_start_time"
                                class="mb-0">
                              <b-form-input id="sunday_start_time" type="time"
                                            v-model="sat.saturday_start_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-5">
                            <b-form-group
                                id="fieldset-1"
                                label="End time slot"
                                label-for="sunday_end_time"
                                class="mb-0"
                                type="time">
                              <b-form-input id="sunday_end_time" type="time"
                                            v-model="sat.saturday_end_time"></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-2">
                            <button type="button" class="btn btn-danger mt-8" @click="removeSaturDay(sat)">
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                        <b-button variant="info" class="float-right mt-2" @click="AddSaturdayItem()"
                                  v-if="saturday.length < 3">Add more
                        </b-button>
                      </b-card-text>
                    </div>

                  </div>
                </div>

              </div>
            </form>

          </b-modal>

          <!--     Add new institute      -->
          <b-modal
              id="addNewInstitute"
              size="lg"
              title="Add new institute"
              @hidden="resetModalAddInstituteModal"
              @ok="handleInstitute">
            <form ref="form" @submit.stop.prevent="saveInstitute">
              <b-form-group
                  id="fieldset-1"
                  label="Name">
                <b-form-input id="input-1" v-model="institute.name" required></b-form-input>
              </b-form-group>

            </form>
          </b-modal>

          <div v-if="chambers">
            <div v-for="(cham, index) in chambers" :key="index">

              <div style="background: #f1f1f1;border: 2px solid #3c84d2;margin: 10px 0px">
                <div class="d-flex justify-content-end m-5 align-items-center">
                  <h3 class="mb-0" v-if="cham.is_active"><b-badge pill variant="success" class="mr-5">Active</b-badge></h3>
                  <h3 class="mb-0" v-else><b-badge pill variant="danger" class="mr-5">Inactive</b-badge></h3>
                  <button class="btn btn-dark" @click="editChamber(cham)"><i class="far fa-edit"></i></button>
                </div>
                <div class="row justify-content-between align-item-center p-5">

                  <div class="col-md-4">
                    <h4>{{ cham.institute_name }}</h4>
                    <h6>{{ cham.address }}</h6>
                    <!-- <p>
                      <span class="mr-2"><i class="fas fa-phone-volume font-awesome-desgin"></i></span>
                      {{ cham.contact_number }}
                    </p> -->

                    <p v-if="cham.primary_contact_no">
                      <span class="mr-2"><i class="fas fa-phone-volume font-awesome-desgin"></i></span>
                      {{ cham.primary_contact_no }}
                    </p>
                    <p v-if="cham.secondary_contact_no">
                      <span class="mr-2"><i class="fas fa-phone-volume font-awesome-desgin"></i></span>
                      {{ cham.secondary_contact_no }}
                    </p>
                  </div>
                  <div class="col-md-4 d-flex">
                    <div>
                      <i class="fas fa-user-md font-awesome-desgin mr-2"></i>
                    </div>
                    <div>
                      <h3>Doctor Fee</h3>
                      <p class="mb-0"><b>Virtual fee:</b> {{ cham.call_fee }}</p>
                      <p class="mb-0"><b>Visit fee:</b> {{ cham.visit_fee }}</p>
                    </div>
                  </div>
                  <div class="col-md-4 d-flex align-content-center">
                    <div>
                      <i class="fas fa-ice-cream  font-awesome-desgin mr-2"></i>
                    </div>
                    <div>
                      <h4>Chamber facilities</h4>
                      <ul class="mr-2" style="list-style: circle" v-if="cham.amanities">
                        <li v-for="(faciliti, index) in cham.amanities.split(',')" :key="index" class="mb-0 ml-5">
                          {{ faciliti.replace(/[^a-zA-Z ]/g, " ") }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="mt-3">
                  <div>
                    <div v-if="cham.working_schedules.length">
                      <div class="table-responsive bg-white">
                        <b-table hover bordered class="mt-5" :items="cham.working_schedules"
                                 :fields="chamber_workplace_shedule">
                          <template #cell(slots)="row" width="200">

                            <span class="mx-2 my-2" :class="slot.status == 2 ? 'badge badge-danger' : ''"
                                  v-for="(slot, index) in row.item.slots"
                                  :key="index">{{ slot.slot_time }}  </span>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ChamberMixin} from "../../../../mixins/doctor/ChamberMixin";

export default {
  name: "Chamber",
  mixins: [ChamberMixin]
}
</script>

<style scoped>
.slots {
  width: 200px;
}
</style>
