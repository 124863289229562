import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {dateFormate} from "../healper/dateTimeFormet";
import {ExportMixin} from "./core/ExportMixin";

export const SalesActivityMixin = {
    mixins: [ExportMixin],
    data() {
        let route_name = this.$route.name;
        return {
            bookType: "xlsx",
            breadcrumbs: route_name + "users",
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            items: [],
            fields: [
                {
                    key: "seller_id",
                    label: "TSO/ASO",
                    sortable: true,
                },
                {
                    key: "full_name",
                    label: "Doctor info",
                    sortable: true,
                },
                {
                    key: "hospital_id",
                    label: "Chamber info",
                    sortable: true,
                },
                {
                    key: "log_address",
                    label: "Entry point",
                    sortable: true,
                },
                {
                    key: "visiting_days",
                    label: "Visiting days",
                    sortable: true,
                },
                {
                    key: "check_in_time",
                    label: "Check in time",
                    sortable: true,
                },
                {
                    key: "check_out_time",
                    label: "Check out time",
                    sortable: true,
                },
                {
                    key: "unique_visits",
                    label: "Unique Visits",
                    isUniqueVisits: true,
                    sortable: true,
                },
                {
                    key: "is_selected",
                    label: "Is selected",
                    sortable: true,
                },
                {
                    key: "is_confirmed",
                    label: "Doctor status",
                    sortable: true,
                },
                {
                    key: "registered_at",
                    label: "Registered at",
                    sortable: true,
                },
                {
                    key: "is_senior_doctor",
                    label: "Doctor(Sr./Jr.)",
                    sortable: true,
                },
                {
                    key: "created_at",
                    label: "Entry date",
                    sortable: true,
                },
                {
                    key: "deleted_at",
                    label: "Deleted Date",
                    sortable: true,
                },
                {
                    key: "actions",
                },
            ],
            filter: null,
            filterOn: [],
            filters: {
                full_name: "",
                log_address: "",
                seller_id: "",
                created_at: "",
            },
            show: true,
            base_url: process.env.VUE_APP_API_URL,
            user_id: "",
            total_user: "",
            start_date: dateFormate().weekly_first_day,
            end_date: dateFormate().weekly_last_day,
            seller_id: "",
            unique_visits: false,
            activities_details: null,
            form: {
                id: '',
                full_name: '',
                designation_id: '',
                phone_no: '',
                designation: '',
                bmdc_reg_no: '',
                speciality: '',
                hospital_id: '',
                hospital_name: '',
                chamber_address: '',
                visiting_card: '',
                lat: '',
                lon: '',
                log_address: '',
                review: '',
                prescription_mode: '',
                has_other_software: '',
                vendor_name: '',
                mobile_device: '',
                preference: '',
                daily_patient: '',
                doctor_type: '',
                connectivity: '',
                has_pc: '',
                has_printer: '',
                has_laptop: '',
                followup_patient: '',
                new_patient: '',
                other_area: '',
                local_area: '',
                avg_time_prescription: '',
                avg_time_patient: '',
                transportation_cost: '',
                patient_for_report: '',
                visit_other_district: '',
                how_frequent: '',
                served_remote_patient: '',
                stump_image: '',
                chamber_image: '',
                doctor_image: '',

            },
            fullNameState: null,
            designationIdState: null,
            designationState: null,
            bmdcRegNoState: null,
            phoneNoState: null,
            hospitalIdState: null,
            hospitalNameState: null,
            latState: null,
            lonState: null,
            logAddressState: null,
            prescriptionModeState: null,
            hasOtherSoftware: null,
            vendorNameState: null,
            mobileDeviceState: null,
            preferenceState: null,
            dailyPatientState: null,
            connectivityState: null,
            prescription_modes: [
                {value: 'mobile', text: 'Mobile'},
                {value: 'pc', text: 'PC'},
                {value: 'laptop', text: 'Laptop'},
                {value: 'hr', text: 'HR'},
            ],
            institutes: [],
            designations: [],
            specilities: [],
            connectivities: [
                {value: 'wifi', text: 'wifi'},
                {value: 'own', text: 'own'},
                {value: 'both', text: 'both'},
            ],
            mobile_devices: [
                {value: 'android', text: 'android'},
                {value: 'ios', text: 'ios'},
                {value: 'both', text: 'both'},
            ],
            preferences: [
                {value: 'mobile', text: 'Mobile'},
                {value: 'tab', text: 'tab'},
                {value: 'laptop', text: 'laptop'},
            ],
            how_frequents: [
                {value: 'once a week', text: 'Once a week'},
                {value: 'once a month', text: 'Once a month'},
                {value: 'occasionally', text: 'Occasionally'},
            ],
            submittedNames: [],
            phone_no_validation_msg: '',
            total_activity: 0,
            search: '',
            is_selected: 1,
        };
    },
    created() {
        localStorage.setItem("tabIndex", 14);
        this.$root.$emit("call-active-menu", 14);
        if (this.$route.params.id) {
            this.seller_id = parseInt(this.$route.params.id)
        }
        this.getUser();
        this.userPhoto();
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Sales activity reports", route: "/sales/activity"},
        ]);
    },

    methods: {
        acceptNumber() {
            var phoneno = /(^(\+88)?(01){1}[3456789]{1}(\d){8})$/;
            if (this.form.phone_no.match(phoneno)) {
                return this.phone_no_validation_msg = "";
            } else {
                return this.phone_no_validation_msg = "Phone Number Invalid"
            }
        },
        getUser() {
            this.loading = true;
            this.currentPage = 1;
            var url = `${
                process.env.VUE_APP_URL
            }/api/v2/sales/team/activity/list?api_token=${localStorage.getItem(
                "api_token"
            )}&platform=backoffice&limit=${this.perPage}&offset=0${
                this.start_date ? "&start_date=" + this.start_date : ""
            }${this.end_date ? "&end_date=" + this.end_date : ""}${
                this.seller_id ? "&seller_id=" + this.seller_id : ""
            }${
                this.unique_visits ? "&unique_visits=" + this.unique_visits : ""
            }${this.search ? "&q="+this.search : ''}${this.is_selected ? "&is_selected="+this.is_selected : "" }`;
            axios.get(url).then((response) => {
                if (response.data.status_code == "500") {
                    this.$router.push("/access-denied");
                } else {
                    if (response.data.data) {
                        this.items = response.data.data;
                        this.total_activity = response.data.total_count;
                    }
                    this.loading = false;
                }
            });
        },
        handlePageChange(value) {
            this.loading = true
            var offset = value - 1;
            var correctValue = offset * this.perPage;

            var url = `${
                process.env.VUE_APP_URL
            }/api/v2/sales/team/activity/list?api_token=${localStorage.getItem(
                "api_token"
            )}&platform=backoffice&limit=${this.perPage}&offset=${correctValue}${
                this.start_date ? "&start_date=" + this.start_date : ""
            }${this.end_date ? "&end_date=" + this.end_date : ""}${
                this.seller_id ? "&seller_id=" + this.seller_id : ""
            }${
                this.unique_visits ? "&unique_visits=" + this.unique_visits : ""
            }${this.search ? "&q="+this.search : ''}${this.is_selected ? "&is_selected="+this.is_selected : "" }`;
            axios.get(url)
                .then(response => {
                    this.loading = false;
                    if (response.data.status_code == "500") {
                        this.$router.push("/access-denied");
                    } else {
                        if (response.data.data) {
                            this.items = response.data.data;
                            this.total_activity = response.data.total_count;
                        }

                    }
                })
        },

        changeDoctorType(activity) {
            this.$snotify.clear();
            this.$snotify.confirm(
                activity.is_senior_doctor == 1 ? 'You want to set junior' : 'You want to set senior',
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                let form = new FormData();
                                form.append('is_senior_doctor', activity.is_senior_doctor == 1 ? 0 : 1);
                                form.append('phone_no', activity.phone_no);
                                form.append('_method', 'put');
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v2/sales/team/activity/update?api_token=${localStorage.getItem('api_token')}`, form)
                                    .then(response => {
                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully set",
                                            "Success"
                                        );
                                        this.getUser();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },

        userDetails(user) {
            this.activities_details = user;
            this.$bvModal.show("sales-activities-details");
        },

        exportData() {

            var url = `${process.env.VUE_APP_URL}/api/v2/sales/team/activity/list/export?api_token=${localStorage.getItem('api_token')}&platform=backoffice${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}${this.seller_id ? "&seller_id=" + this.seller_id : ""}${this.unique_visits ? '&unique_visits=' + this.unique_visits : ''}${this.search ? "&q="+this.search : ''}`;
            axios.get(url, {responseType: 'blob'})
                .then(response => {
                    this.exportLink(response)
                })
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        localizeDate(date) {
            if (!date || !date.includes("-")) return date;
            const [yyyy, mm, dd] = date.split("-");
            return new Date(`${mm}/${dd}/${yyyy}`);
        },

        formatDate(date) {
            return new Intl.DateTimeFormat("en-US", {dateStyle: "long"}).format(
                new Date(date)
            );
        },

        sendInfo(item) {
            this.user_id = item.id;
        },

        onReset(event) {
            event.preventDefault();
            // Reset our form values
            this.form.email = "";
            this.form.name = "";
            this.form.food = null;
            this.form.checked = [];
            // Trick to reset/clear native browser form validation state
            this.show = false;
            this.form.password = "";
            this.form.comment = "";
            this.call_history = {};
            (this.call_log = {}),
                this.$nextTick(() => {
                    this.show = true;
                });
        },

        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../../public/media/users/blank.png";
            }
        },

        dateRangeClear() {
            this.startDate = "";
            this.endDate = "";
            this.start_date = null;
            this.end_date = null;
            this.getUser();
        },

        showImage(img) {
            const h = this.$createElement;
            // More complex structure
            const messageVNode = h("div", {class: ["foobar"]}, [
                h("b-img", {
                    props: {
                        src: `${process.env.VUE_APP_API_URL}/${img}`,
                        thumbnail: true,
                        center: false,
                    },
                }),
            ]);
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                buttonSize: "sm",
                centered: true,
                size: "lg",
            });
        },

        resetModal() {
            this.activities_details = null;
            this.form = {};
            this.fullNameState = null;
            this.designationIdState = null;
            this.designationState = null;
            this.bmdcRegNoState = null;
            this.phoneNoState = null;
            this.hospitalIdState = null;
            this.hospitalNameState = null;
            this.latState = null;
            this.lonState = null;
            this.logAddressState = null;
            this.prescriptionModeState = null;
            this.hasOtherSoftware = null;
            this.vendorNameState = null;
            this.mobileDeviceState = null;
            this.preferenceState = null;
            this.dailyPatientState = null;
            this.connectivityState = null;
        },

        editActivity(item) {
            this.$bvModal.show('edit-activity')
            this.getDesignation();
            this.getInstitute();
            this.form = item;

        },
        getDesignation() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/designation`)
                .then(response => {
                    this.designations = response.data.data;
                })
        },

        getInstitute() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000`)
                .then(response => {
                    this.institutes = response.data.data
                })
        },

        getSpeialitiesist() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/speciality?show_all=1&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {

                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.specilities = response.data.data;
                    }
                })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.fullNameState = this.form.full_name ? '' : valid
            return valid
        },

        getDesignationName(id) {
            let designation = this.designations.find((item) => item.id === id)
            return designation.name
        },

        getInstituteName(id) {
            let institute = this.institutes.find((item) => item.id === id)
            return institute.name
        },


        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {

            if (!this.checkFormValidity()) {
                return
            }

            if (this.phone_no_validation_msg) {
                return this.phone_no_validation_msg = "Please give valid phone number"
            }

            this.submittedNames.push(this.form.full_name)


            // $salesActivity->chamber_image = $request->chamber_image;
            // $salesActivity->doctor_type = $request->doctor_type;
            // $salesActivity->stump_image = $request->stump_image;
            let chamber_image = this.$refs.chamber_image.files[0];
            let stump_image = this.$refs.stump_image.files[0];
            let visiting_card = this.$refs.visiting_card.files[0];
            let doctor_image = this.$refs.doctor_image.files[0];
            // eslint-disable-next-line no-unused-vars
            let doctor_type = '';
            if (this.daily_patient < 10) {
                doctor_type = 'junior doctors'
            } else if (this.daily_patient < 20) {
                doctor_type = 'mediocre doctors'
            } else if (this.daily_patient < 30) {
                doctor_type = 'senior doctors'
            } else {
                doctor_type = 'celebrity doctors'
            }
            let form = new FormData();
            form.append('full_name', this.form.full_name);
            form.append('designation_id', this.form.designation_id);
            form.append('designation', this.getDesignationName(this.form.designation_id));
            form.append('bmdc_reg_no', this.form.bmdc_reg_no ? this.form.bmdc_reg_no : '');
            form.append('speciality', this.form.speciality);
            form.append('phone_no', this.form.phone_no);
            form.append('hospital_id', this.form.hospital_id);
            form.append('hospital_name', this.getInstituteName(this.form.hospital_id));
            form.append('chamber_address', this.form.chamber_address);
            form.append('lat', this.form.lat ? this.form.lat : '');
            form.append('lon', this.form.lon ? this.form.lon : '');
            form.append('log_address', this.form.log_address ? this.form.log_address : '');
            form.append('review', this.form.review ? this.form.review : '');
            form.append('prescription_mode', this.form.prescription_mode ? this.form.prescription_mode : '');
            form.append('has_other_software', this.form.has_other_software ? this.form.has_other_software : 0);
            form.append('vendor_name', this.form.vendor_name ? this.form.vendor_name : '');
            form.append('mobile_device', this.form.mobile_device ? this.form.mobile_device : '');
            form.append('preference', this.form.preference ? this.form.preference : '');
            form.append('mobile_device', this.form.mobile_device ? this.form.mobile_device : '');
            form.append('daily_patient', this.form.daily_patient ? this.form.daily_patient : '');
            form.append('connectivity', this.form.connectivity ? this.form.connectivity : '');
            form.append('has_pc', this.form.has_pc ? this.form.has_pc : 0);
            form.append('has_printer', this.form.has_printer ? this.form.has_printer : 0);
            form.append('has_laptop', this.form.has_laptop ? 1 : 0);
            form.append('followup_patient', this.form.followup_patient ? this.form.followup_patient : 0);
            form.append('new_patient', this.form.new_patient ? this.form.new_patient : 0);
            form.append('other_area', this.form.other_area ? this.form.other_area : 0);
            form.append('local_area', this.form.local_area ? this.form.local_area : 0);
            form.append('avg_time_prescription', this.form.avg_time_prescription ? this.form.avg_time_prescription : '');
            form.append('avg_time_patient', this.form.avg_time_patient ? this.form.avg_time_patient : '');
            form.append('transportation_cost', this.form.transportation_cost ? this.form.transportation_cost : '');
            form.append('patient_for_report', this.form.patient_for_report ? this.form.patient_for_report : '');
            form.append('visit_other_district', this.form.visit_other_district ? this.form.visit_other_district : 0);
            form.append('how_frequent', this.form.how_frequent ? this.form.how_frequent : '');
            form.append('served_remote_patient', this.form.served_remote_patient ? this.form.served_remote_patient : 0);
            form.append('stump_image', stump_image);
            form.append('chamber_image', chamber_image);
            form.append('visiting_card', visiting_card);
            form.append('doctor_type', doctor_type);
            form.append('doctor_image', doctor_image);

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/sales/team/activity/${this.form.id}/update?api_token=${localStorage.getItem('api_token')}&_method=put`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.$bvModal.hide('edit-activity')
                        // this.$emit('doctorOverviewUpdate')
                        this.getUser();
                        this.$snotify.success('Successfully updated');

                    })

            })
        },

        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v2/sales/team/activity/${row.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }

                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getUser();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        }

    },

    computed: {

        computedFields() {
            if (!this.unique_visits)
                return this.fields.filter(field => !field.isUniqueVisits);
            else
                return this.fields;
        },
        sortOptions() {
            // Create an options list from our fields
            return this.computedFields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {text: f.label, value: f.key};
                });
        },

        filtered() {
            const filtered = this.items.filter((item) => {
                return Object.keys(this.filters).every((key) =>
                    String(item[key]).includes(this.filters[key])
                );
            });

            return filtered.length > 0
                ? filtered
                : [
                    {
                        seller_name: "",
                        seller_phone_number: "",
                        phone_no: "",
                        full_name: "",
                        hospital_name: "",
                        created_at: "",
                    },
                ];
        },

        routeName() {
            return this.$route.name;
        },


    },

    currentPage: {
        handler: function (value) {
            this.getUser().catch((error) => {
                console.error(error);
                console.error(value);
            });
        },
    },
};
