import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const CallLogMixin = {
    data() {
        return {
            filter: null,
            filterOn: [],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            fields: [
                {
                    label: 'Sl',
                    key: 'index',
                    sortable: true,
                },
                {
                    label: 'Orko user',
                    key: 'fullname',
                    sortable: true,
                },
                {
                    label: 'User type',
                    key: 'user_type',
                    sortable: true,
                },
                {
                    label: 'Call category',
                    key: 'call_category',
                    sortable: true,
                },
                {
                    label: 'Call Type',
                    key: 'call_type',
                    sortable: true,
                },
                {
                    label: 'Call Status',
                    key: 'call_status',
                    sortable: true,
                },
                {
                    label: 'Call Response',
                    key: 'call_response',
                    sortable: true,
                },
                {
                    label: 'Reminder date',
                    key: 'reminder_date',
                    sortable: true,
                },
                {
                    label: 'Call action date',
                    key: 'call_action_date',
                    sortable: true
                },
                {
                    label: 'Remarks',
                    key: 'remarks',
                    sortable: true
                },
                {
                    label: 'Created date',
                    key: 'created_at',
                    sortable: true
                }

            ],
            columns: {
                "Full name": {
                    field: 'fullname'
                },
                "User type": {
                    field: 'user_type'
                },
                "Call category": {
                    field: 'call_category'
                },
                "Call type": {
                    field: 'call_action_status'
                },
                "Call response": {
                    field: 'call_response'
                },
                "Reminder date": {
                    field: 'reminder_date'
                },
                "Call action date": {
                    field: 'call_action_date'
                },

                "Remarks": {
                    field: 'remarks'
                },
                "Created date": {
                    field: 'created_at'
                },

            },
            items: [],
            filters: {
                id: '',
                index: '',
                fullname: '',
                user_type: '',
                call_category: '',
                call_action_status: '',
                call_response: '',
                reminder_date: '',
                call_action_date: '',
                remarks: '',
                created_at: '',
            }
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Call log", route: "/call-log"},
        ]);
    },
    metaInfo: {
        title: 'Orko Health Ltd | Call Log',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.created_at)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                index: '',
                fullname: '',
                user_type: '',
                call_category: '',
                call_action_status: '',
                call_response: '',
                reminder_date: '',
                call_action_date: '',
                remarks: '',
                created_at: '',
            }]
        },
        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.created_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
    },
    created() {
        localStorage.setItem('tabIndex', 9)
        this.$root.$emit('call-active-menu', 9);
        this.getCallLog();
    },
    methods: {
        getCallLog() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        handlePageChange(value) {
            this.page = value;
            let offset = 0;
            if (this.currentPage > 1) {
                offset = this.page * this.perPage
            }
            axios.get(`${process.env.VUE_APP_URL}/api/v1/administrator/prescription/list?api_token=${localStorage.getItem('api_token')}&limit=100&offset=${offset}`)
                .then(response => {
                    this.items = response.data.data.detail
                    this.totalItems = response.data.data.counter.total_count
                    this.loading = false;
                })

        },
        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
    }
}
