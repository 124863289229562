import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const NatureOfServiceMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
                "Verified doctor counte": {
                    field: "verified_doctor_count",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'icon',
                    label: 'Icon',
                    sortable: true
                },
                {
                    key: 'details',
                    label: 'Details',
                    sortable: true
                },
                {
                    key: 'for_whom',
                    label: 'For whom',
                    sortable: true
                },
                {
                    key: 'verified_doctor_count',
                    label: 'Verified doctor count',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Actions',
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
                details: '',
                verified_doctor_count: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            details: '',
            form: {
                title: '',
                details: '',
                for_whom: "doctor",
            },
            loading: true,
            is_edit: false,
            specialities_id: '',
            options: [
                {
                    value: 'doctor',
                    text: 'For doctor',
                },
                {
                    value: 'patient',
                    text: 'For patient',
                },
            ],
            for_whom: 'doctor'
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Medical service", route: "/nature-of-service"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getMedicalService();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Nature of service | Medical services',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
                verified_doctor_count: ''
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getMedicalService() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medical/services/list`)
                .then(response => {
                    this.items = response.data.data
                    this.loading = false;
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.specialities_id = '';
            this.is_edit = false;
            this.titleState = null;
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            if (this.is_edit) {
                return this.update();
            }
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();
            let icon = this.$refs.icon.files[0];
            form.append("title", this.form.title);
            form.append("for_whom", this.for_whom);
            if (this.form.details) {
                form.append("details", this.form.details);
            }
            if(icon){
                form.append("icon", icon);
            }

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medical/services/add?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicalService();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        editAction(row) {
            this.$bvModal.show("modal-1");
            this.form = row;
            this.for_whom = row.for_whom;
            this.specialities_id = row.id;
            this.is_edit = true;

        },
        update() {
            if (!this.checkFormValidity()) {
                return
            }

            let form = new FormData();
            let icon = this.$refs.icon.files[0];
            form.append("title", this.form.title);
            if (this.form.description) {
                form.append("details", this.form.details);
            }
            if(icon){
                form.append("icon", icon);
            }
            form.append("for_whom", this.for_whom);
            form.append('ms_id', this.specialities_id);
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medical/services/update?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicalService();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },

        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/medical/services/delete/${row.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }

                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getMedicalService();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        }
    }
}