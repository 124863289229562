<template>
  <div>
    <div class="card mb-3">
      <div class="row g-0 card-body">
        <div class="col-md-4">
          <b-img thumbnail fluid :src="userPhoto(agent_info.identity_card)" alt="Image 2"
                 v-if="agent_info.identity_card" @click="showImage(agent_info.identity_card)"></b-img>
          <b-img blank blank-color="#ccc" width="280" height="120" v-else></b-img>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <p class="mb-0"><b> Identity card type </b> <br>{{ agent_info.identity_card_type }}</p>
            <p class="mb-0"><b> Identity card no </b> <br>
              {{ agent_info.identity_card_no ? agent_info.identity_card_no : 'not set yet' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AideInfo",
  props: {
    agent_info: {},
  },
  methods: {
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return "../../assets/img/blank.png";
      }
    },
    showImage(img) {
      const h = this.$createElement
      // Using HTML string

      // More complex structure
      const messageVNode = h('div', {class: ['foobar']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
            thumbnail: true,
            center: false,
          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        buttonSize: 'sm',
        centered: true, size: 'lg'
      })
    },
  }
}
</script>

<style scoped>
.basic-info {
  line-height: 25px;
}
</style>