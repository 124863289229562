<template>
  <div>
    <div class="single-module">
      <h5 @click="toggle" class="px-4 b mb-0 d-flex justify-content-between padding-bottom-10">
        <div class="d-flex">
          <b-form-checkbox
              size="lg">

          </b-form-checkbox>
          {{ groupName }}
        </div>
        <div>
          <span v-if="isOpen" class="px-5"> <i class="fas fa-chevron-up"></i> </span>
          <span v-else class="px-5"> <i class="fas fa-chevron-down"></i> </span>
        </div>
      </h5>
      <div v-show="isOpen" class="p-3 accordion-description">
        <div v-for="(permission, index) in item" :key="index">
          <b-form-checkbox
              size="lg"
              v-model="checkedPermissions[permission[0]]"
              @change="checkPermission($event,permission[0])"
          >{{ permission[1] }}
          </b-form-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Accordion-item",
  props: {
    item: {},
    isOpen: {},
    groupName: {},
  },

  data() {
    return {
      form: {
        permission_ids: [],
      },
      permissions: [],
      checkedPermissions: {},
      main_data: '',
    }
  },

  methods: {
    toggle() {
      this.$emit('toggled', this.$vnode.key, this.form);
    },
    checkPermission(event,key) {

      if (event) {

        this.permissions.push(key);
        let data = this.permissions.join(',')

      } else {
        const index = this.permissions.indexOf(key);
        this.permissions.splice(index, 1);
        let data = this.permissions.join(',')
      }


    }

  },

}
</script>
<style scoped>
.single-module {
  border: 1px solid #ddd;
  padding: 10px 0px;
  margin: 10px 0px;
  cursor: pointer;
}

.accordion-description {
  border-top: 1px solid #dddddd;
  margin-top: 10px !important;
}
</style>
