import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const UserDetailsMixin = {
    data() {
        return {
            user: {},
            loading: true,
            doctor_scientific_conference: {},
        }

    },

    metaInfo: {
        title: 'Orko Health Ltd | User details',
        htmlAttrs: {
            lang: 'en-US'
        }
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: 'User details', route: "user-details"},
        ]);
        this.$root.$on('change_telemedicine_value', this.getUserData)
    },

    beforeDestroy() {
        this.$root.$off('global-change_telemedicine_value', this.getUserData)
    },

    async created() {
        await this.getUserData();
    },

    methods: {

        async getUserData() {
            await axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?platform=backoffice&doctor_detail=yes&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.user = response.data.data
                    this.doctor_scientific_conference = response.data.data.doctor_information
                    let str = "response.data.data.doctor_information.scientific-conference";
                    str = str.replace(/-/g, '_');
                    this.loading = false;
                })
            // console.log('oks')
        },

        getUserExprience() {
            this.getUserData();
        },

        salesActivityCall(phone_number) {
            console.log('pp', phone_number)
            const routeData = this.$router.resolve({
                path: '/sales/activity/call/by/doctor',
                query: {phone_number: phone_number}
            });
            window.open(routeData.href, '_blank');

        }
    },
}
