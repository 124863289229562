import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {ExportMixin} from "./core/ExportMixin";

export const SalesActivityDoctorMixin = {
    mixins: [ExportMixin],
    data() {
        let route_name = this.$route.name;
        return {
            bookType: "xlsx",
            breadcrumbs: route_name + "users",
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            items: [],
            fields: [
                {
                    key: "seller_id",
                    label: "TSO/ASO",
                    sortable: true,
                },
                {
                    key: "full_name",
                    label: "Doctor info",
                    sortable: true,
                },
                {
                    key: "hospital_id",
                    label: "Chamber info",
                    sortable: true,
                },
                {
                    key: "log_address",
                    label: "Entry point",
                    sortable: true,
                },
                {
                    key: "unique_visits",
                    label: "Unique Visits",
                    isUniqueVisits: true,
                    sortable: true,
                },
                {
                    key: "is_confirmed",
                    label: "Doctor status",
                    sortable: true,
                },
                {
                    key: "registered_at",
                    label: "Registered at",
                    sortable: true,
                },
                {
                    key: "is_senior_doctor",
                    label: "Doctor(Sr./Jr.)",
                    sortable: true,
                },
                {
                    key: "created_at",
                    label: "Entry date",
                    sortable: true,
                },
            ],
            filter: null,
            filterOn: [],
            filters: {
                full_name: "",
                log_address: "",
                seller_id: "",
                created_at: "",
            },
            show: true,
            base_url: process.env.VUE_APP_API_URL,
            user_id: "",
            total_user: "",
            start_date: this.$route.query.start_date ? this.$route.query.start_date : '',
            end_date: this.$route.query.end_date ? this.$route.query.end_date : '',
            seller_id: this.$route.query.tsm ? parseInt(this.$route.query.tsm) : '',
            chart_name: '',
            chart_value: '',
            total_activity: 0,
            search: '',
            filtersKay: [
                {
                    text: 'Prescription mode',
                    value: 'prescription_mode',
                },
                {
                    text: 'Mobile Device',
                    value: 'mobile_device'
                },
                {
                    text: 'Device Preference',
                    value: 'device_preference'
                },
                {
                    text: 'Patient Segments',
                    value: 'patient_segments'
                },
                {
                    text: 'Area-wise patient',
                    value: 'area_wise_patient'
                },
                {
                    text: 'Connectivity',
                    value: 'connectivity'
                },
                {
                    text: 'Doctor type',
                    value: 'doctor_type'
                },
                {
                    text: 'Speciality',
                    value: 'speciality'
                },
            ],
            filtersValue: {
                "prescription_mode": [
                    {
                        text: 'Mobile',
                        value: 'mobile',
                    },
                    {
                        text: 'PC',
                        value: 'pc',
                    },
                    {
                        text: 'HR',
                        value: 'hr'
                    }
                ],
                "mobile_device": [
                    {
                        text: 'Android',
                        value: 'android',
                    },
                    {
                        text: 'IOS',
                        value: 'ios',
                    },
                    {
                        text: 'Both',
                        value: 'both'
                    }
                ],
                "device_preference": [
                    {
                        text: 'Laptop',
                        value: 'laptop',
                    },
                    {
                        text: 'Mobile',
                        value: 'mobile',
                    }
                ],
                "patient_segments": [
                    {
                        text: 'Total new patient',
                        value: 'total_new_patient',
                    },
                    {
                        text: 'Total followup patient',
                        value: 'total_follow_up_patient',
                    },
                    {
                        text: 'Total report patient',
                        value: 'total_report_patient'
                    }
                ],
                "area_wise_patient": [
                    {
                        text: 'Local area',
                        value: 'local_area'
                    },
                    {
                        text: 'Other area',
                        value: 'other_area'
                    }
                ],
                "connectivity": [
                    {
                        text: 'WIFI',
                        value: 'wifi'
                    },
                    {
                        text: 'Own',
                        value: 'own'
                    }
                ],
                "doctor_type": [
                    {
                        text: 'Celebrity doctors',
                        value: 'celebrity doctors'
                    },
                    {
                        text: 'Senior doctors',
                        value: 'senior doctors'
                    },
                    {
                        text: 'Mediocre doctors',
                        value: 'mediocre doctors'
                    },
                    {
                        text: 'Junior doctors',
                        value: 'junior doctors'
                    }
                ]
            },
            chart_type: this.$route.query.name ? this.$route.query.name : '',
            // chart_value: this.$route.query.name ? this.$route.query.name : '',
            specialities: [],
        };
    },
    created() {
        localStorage.setItem("tabIndex", 14);
        this.$root.$emit("call-active-menu", 14);
        if (this.$route.params.id) {
            this.seller_id = parseInt(this.$route.params.id)
        }
        this.chart_name = this.$route.query.name ? this.$route.query.name : '';
        this.chart_value = this.$route.query.value ? this.$route.query.value : '';
        this.getUser();
        this.getSpeciality();
        this.userPhoto();
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Sales activity reports", route: "/sales/activity"},
        ]);

    },

    methods: {

        getUser() {
            this.loading = true;
            this.currentPage = 1;
            if (this.chart_value && this.chart_name == 'speciality') {
                this.chart_value
            }else if(this.chart_name == 'doctor_type') {
                this.chart_value
            } else {
                this.chart_value = this.chart_value.replace(/\s/g, '_').toLowerCase()
            }
            var url = `${
                process.env.VUE_APP_URL
            }/api/v2/sales/team/activity/list?api_token=${localStorage.getItem(
                "api_token"
            )}&platform=backoffice&limit=${this.perPage}&offset=0${
                this.start_date ? "&start_date=" + this.start_date : ""
            }${this.end_date ? "&end_date=" + this.end_date : ""}${
                this.seller_id ? "&seller_id=" + this.seller_id : ""
            }${this.chart_name ? '&chart_name=' + this.chart_name : ''}
            ${this.chart_value ? '&chart_value=' + this.chart_value : ''}`;
            axios.get(url).then((response) => {
                if (response.data.status_code == "500") {
                    this.$router.push("/access-denied");
                } else {
                    if (response.data.data) {
                        this.items = response.data.data;
                        this.total_activity = response.data.total_count;
                    }
                    this.loading = false;
                }
            });
        },
        handlePageChange(value) {
            this.loading = true
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            if (this.chart_value && this.chart_name == 'speciality') {
                this.chart_value = this.$route.query.value
            } else {
                this.chart_value = this.$route.query.value.replace(/\s/g, '_').toLowerCase()
            }
            var url = `${
                process.env.VUE_APP_URL
            }/api/v2/sales/team/activity/list?api_token=${localStorage.getItem(
                "api_token"
            )}&platform=backoffice&limit=${this.perPage}&offset=${correctValue}${
                this.start_date ? "&start_date=" + this.start_date : ""
            }${this.end_date ? "&end_date=" + this.end_date : ""}${
                this.seller_id ? "&seller_id=" + this.seller_id : ""
            }${this.chart_name ? '&chart_name=' + this.chart_name : ''}
            ${this.chart_value ? '&chart_value=' + this.chart_value : ''}`;
            axios.get(url)
                .then(response => {
                    this.loading = false;
                    if (response.data.status_code == "500") {
                        this.$router.push("/access-denied");
                    } else {
                        if (response.data.data) {
                            this.items = response.data.data;
                            this.total_activity = response.data.total_count;
                        }

                    }
                })
        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../../public/media/users/blank.png";
            }
        },
        dateRangeClear() {
            this.startDate = "";
            this.endDate = "";
            this.start_date = null;
            this.end_date = null;
            this.getUser();
        },
        getSpeciality() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/sales/team/activity/specialities?api_token=${localStorage.getItem('api_token')}`)
                .then(res => {
                    this.specialities = res.data.data
                })
        }
    },

    computed: {

        computedFields() {
            if (!this.unique_visits)
                return this.fields.filter(field => !field.isUniqueVisits);
            else
                return this.fields;
        },
        sortOptions() {
            // Create an options list from our fields
            return this.computedFields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {text: f.label, value: f.key};
                });
        },

        filtered() {
            const filtered = this.items.filter((item) => {
                return Object.keys(this.filters).every((key) =>
                    String(item[key]).includes(this.filters[key])
                );
            });

            return filtered.length > 0
                ? filtered
                : [
                    {
                        seller_name: "",
                        seller_phone_number: "",
                        phone_no: "",
                        full_name: "",
                        hospital_name: "",
                        created_at: "",
                    },
                ];
        },

    },

    currentPage: {
        handler: function (value) {
            this.getUser().catch((error) => {
                console.error(error);
                console.error(value);
            });
        },
    },
};
