import axios from "axios";

export const ChamberMixin = {
    props: {
        workplaces: {},
        doctor_id: {},
        doctor: {},
        doctor_api_token: {},
    },

    created() {
        this.todayDate();
        this.getChamberInfo();
        this.getFacilites();
    },

    data() {
        return {
            medical_registration_authority: {},
            days_week: [
                {
                    text: 'Monday',
                    value: 'monday',
                    sl: '1',
                },
                {
                    text: 'Tuesday',
                    value: 'tuesday',
                    sl: '2',
                },
                {
                    text: 'Wednesday',
                    value: 'wednesday',
                    sl: '3',
                },
                {
                    text: 'Thursday',
                    value: 'thursday',
                    sl: '4',
                },
                {
                    text: 'Friday',
                    value: 'friday',
                    sl: '5',
                },
                {
                    text: 'Saturday',
                    value: 'saturday',
                    sl: '6',
                },
                {
                    text: 'Sunday',
                    value: 'sunday',
                    sl: '7',
                },
            ],
            chambers: [],
            activeOptions: [
                {value:'', text: 'Select Status'},
                {value: 1, text: 'Active'},
                {value: 0, text: 'In-Active'},
            ],
            visit: {
                date: null,
            },

            today: null,
            faciliti: '',
            sunday: [],
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            item: {},
            sunday_activities: [],
            time_slots: '',
            final_work_days: '',
            is_day_validation_pass: false,

            chamberName: null,
            chamber: {
                institute_id: '',
                address: '',
                primary_contact_no: '',
                secondary_contact_no: '',
                time_slot: '',
                amanities: [],
                is_active: 1
            },
            activeBtn: '',
            myToggle: false,

            weeks: [
                {caption: 'Sun', state: false},
                {caption: 'Mon', state: false},
                {caption: 'Tue', state: false},
                {caption: 'Wed', state: false},
                {caption: 'Thu', state: false},
                {caption: 'Fri', state: false},
                {caption: 'Sat', state: false},
            ],

            chamber_is_sunday: false,
            chamber_is_monday: false,
            chamber_is_tuesday: false,
            chamber_is_wednesday: false,
            chamber_is_thursday: false,
            chamber_is_friday: false,
            chamber_is_saturday: false,
            institutes: [],

            telimedicine: {
                time_slot: '',
            },

            telimidicine_chambers: [],
            is_edit: false,
            update_id: '',
            chamber_workplace_shedule: [
                {
                    label: 'Id',
                    key: 'id',
                },
                {
                    label: 'Day',
                    key: 'day',
                },
                {
                    label: 'Start time',
                    key: 'start_time',
                },
                {
                    label: 'End time',
                    key: 'end_time'
                },
                {
                    label: 'Time per slot',
                    kay: 'time_per_slot'
                },
                {
                    label: 'Slots',
                    key: 'slots',
                    tdClass: 'slots'
                }
            ],
            is_modal_loading: false,
            institute_is_required: '',
            slot_is_required: '',
            facilities: [],
            institute: {
                name: ''
            },

        }
    },

    methods: {
        getChamberInfo() {
            this.is_modal_loading = true;
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/doctor/chamber/list?doctor_id=${this.doctor_id}&api_token=${localStorage.getItem('api_token')}&show_all=1&platform=backoffice`)
                .then(res => {
                    this.chambers = res.data.data;
                    this.is_modal_loading = false;
                })
        },

        getFacilites(){
            this.facilities = [
                {
                    title: 'Parking',
                    slug: 'Parking'
                },
                {
                    title: 'Food Corner',
                    slug: 'Food_Corner'
                },
                {
                    title: 'Tea Or Coffee Corner',
                    slug: 'Tea_Or_Coffee_Corner'
                },
                {
                    title: 'Water Dispenser',
                    slug: 'Water_Dispenser'
                },
                {
                    title: 'Washrooms',
                    slug: 'Washrooms'
                },
                {
                    title: 'Televisions',
                    slug: 'Televisions'
                },
                {
                    title: 'Newspapers',
                    slug: 'Newspapers'
                },
                {
                    title: 'ATM Booth',
                    slug: 'ATM_Booth'
                },
                {
                    title: 'Pharmacy',
                    slug: 'Pharmacy'
                },
                {
                    title: 'Waiting Rooms',
                    slug: 'Waiting_Rooms'
                },
                {
                    title: 'Internet Access',
                    slug: 'Internet_Access'
                },
                {
                    title: 'Wheel Chair Accessible',
                    slug: 'Wheel_Chair_Accessible'
                },
                {
                    title: 'Changing Room',
                    slug: 'Changing_Room'
                },
                {
                    title: 'Child Friendly',
                    slug: 'Child_Friendly'
                },
                {
                    title: 'Day Care Surgery',
                    slug: 'Day_Care_Surgery'
                },
                {
                    title: 'Examination Room',
                    slug: 'Examination_Room'
                },
                {
                    title: 'Disability Care Service',
                    slug: 'Disability_Care_Service'
                },
                {
                    title: 'Health Checkup',
                    slug: 'Health_Checkup'
                },
                {
                    title: 'Prayer Room',
                    slug: 'Prayer_Room'
                },
                {
                    title: 'Reception',
                    slug: 'Reception'
                },
                {
                    title: 'Supporting Staff',
                    slug: 'Supporting_Staff'
                },
                {
                    title: 'Drinking Water',
                    slug: 'Drinking_Water'
                },
                {
                    title: 'Wound Care',
                    slug: 'Wound_Care'
                },
                {
                    title: 'Breastfeeding',
                    slug: 'Breastfeeding'
                },
                {
                    title: 'Baby Changing Room',
                    slug: 'Baby_Changing_Room'
                },
                {
                    title: 'Air conditioning',
                    slug: 'Air_Conditioning'
                },
            ];
        },
        resetModalAddInstituteModal(){
            this.institute.name = '';
        },
        resetModal() {
            this.chamber = {
                institute_id: '',
                address: '',
                primary_contact_no: '',
                secondary_contact_no: '',
                time_slot: '',
                amanities: [],
                is_active: 1
            };
            // this.chamber = {};
            this.final_work_days = '';
            this.institute_is_required = '';
            this.slot_is_required = '';
            this.sunday = [];
            this.monday = [];
            this.saturday = [];
            this.tuesday = [];
            this.wednesday = [];
            this.thursday = [];
            this.friday = [];

            this.is_edit = false;
            this.chamber_is_sunday = false;
            this.chamber_is_monday = false;
            this.chamber_is_tuesday = false;
            this.chamber_is_wednesday = false;
            this.chamber_is_thursday = false;
            this.chamber_is_friday = false;
            this.chamber_is_saturday = false;
            this.weeks = [
                {caption: 'Sun', state: false},
                {caption: 'Mon', state: false},
                {caption: 'Tue', state: false},
                {caption: 'Wed', state: false},
                {caption: 'Thu', state: false},
                {caption: 'Fri', state: false},
                {caption: 'Sat', state: false},
            ];
        },

        todayDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;
            this.today = today;
        },

        facilitis(items) {
            this.faciliti = items.split(',');
        },

        AddItem() {
            this.sunday.push({
                sunday_start_time: '',
                sunday_end_time: '',
            });
        },

        AddMondayItem() {
            this.monday.push({
                monday_start_time: '',
                monday_end_time: '',
            });
        },

        AddTuesdayItem() {
            this.tuesday.push({
                tuesday_start_time: '',
                tuesday_end_time: '',
            });
        },

        AddWednesdayItem() {
            this.wednesday.push({
                wednesday_start_time: '',
                wednesday_end_time: '',
            });
        },

        AddThursdayItem() {
            this.thursday.push({
                thursday_start_time: '',
                thursday_end_time: '',
            });
        },

        AddFridayItem() {
            this.friday.push({
                friday_start_time: '',
                friday_end_time: '',
            });
        },

        AddSaturdayItem() {
            this.saturday.push({
                saturday_start_time: '',
                saturday_end_time: '',
            });
        },

        removeItem(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.sunday.findIndex(
                u => u === item
            );
            this.sunday.splice(index, 1);
        },

        removeMonday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.monday.findIndex(
                u => u === item
            );
            this.monday.splice(index, 1);
        },

        removeTuesday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.tuesday.findIndex(
                u => u === item
            );
            this.tuesday.splice(index, 1);
        },

        removeWednesday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.wednesday.findIndex(
                u => u === item
            );
            this.wednesday.splice(index, 1);
        },

        removeThursday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.thursday.findIndex(
                u => u === item
            );
            this.thursday.splice(index, 1);
        },

        removeFriday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.friday.findIndex(
                u => u === item
            );
            this.friday.splice(index, 1);
        },

        removeSaturDay(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.saturday.findIndex(
                u => u === item
            );
            this.saturday.splice(index, 1);
        },

        removeComma(given_str) {
            return given_str.replace(/(^,)|(,$)/g, "");
        },


        // For add chamber
        addNewChamber() {
            this.getInstitute();
            this.$bvModal.show('addChamber');
            // workpleace
        },

        getInstitute() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.institutes = response.data.data
                        this.loading = false;
                        this.is_modal_loading = false;
                    }
                })
        },

        clickDay(day) {
            if (day === 'Sun') {
                this.chamber_is_sunday = true;
                if (this.sunday.length < 3) {
                    this.sunday.push({
                        sunday_start_time: '',
                        sunday_end_time: '',
                    });
                }

            }
            if (day == 'Mon') {
                this.chamber_is_monday = true;

                if(this.monday.length < 3) {
                    this.monday.push({
                        monday_start_time: '',
                        monday_end_time: '',
                    });
                }

            }
            if (day == 'Tue') {
                this.chamber_is_tuesday = true;
                if(this.tuesday.length < 3) {
                    this.tuesday.push({
                        tuesday_start_time: '',
                        tuesday_end_time: '',
                    });
                }
            }
            if (day == 'Wed') {
                this.chamber_is_wednesday = true;
                if(this.wednesday.length < 3) {
                    this.wednesday.push({
                        wednesday_start_time: '',
                        wednesday_end_time: '',
                    });
                }
            }
            if (day == 'Thu') {
                this.chamber_is_thursday = true;
                if(this.thursday.length < 3) {
                    this.thursday.push({
                        thursday_start_time: '',
                        thursday_end_time: '',
                    });
                }
            }
            if (day == 'Fri') {
                this.chamber_is_friday = true;
                if(this.friday.length < 3) {
                    this.friday.push({
                        friday_start_time: '',
                        friday_end_time: '',
                    });
                }
            }
            if (day == 'Sat') {
                this.chamber_is_saturday = true;
                if(this.saturday.length < 3) {
                    this.saturday.push({
                        saturday_start_time: '',
                        saturday_end_time: '',
                    });
                }
            }
        },

        handleChamber(bvModalEvt) {
            bvModalEvt.preventDefault()
            if (this.is_edit) {
                return this.updateChamber();
            }
            this.saveChamber()
        },

        handleInstitute(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.saveInstitute()
        },

        saveChamber() {
            if (this.chamber.institute_id == '' || this.chamber.institute_id == null) {
                return this.institute_is_required = 'Institute  is required';
            }

            if (this.telimedicine.time_slot == '' || this.telimedicine.time_slot == null) {
                return this.slot_is_required = "Slot is required";
            }
            let form = new FormData();

            // Sunday
            const sun_start_time = this.sunday.map(item => item.sunday_start_time);
            const sun_end_time = this.sunday.map(item => item.sunday_end_time);

            // monday
            const mon_start_time = this.monday.map(item => item.monday_start_time);
            const mon_end_time = this.monday.map(item => item.monday_end_time);

            // Tuesday
            const tues_start_time = this.tuesday.map(item => item.tuesday_start_time);
            const tues_end_time = this.tuesday.map(item => item.tuesday_end_time);

            // Wednesday
            const wed_start_time = this.wednesday.map(item => item.wednesday_start_time);
            const wed_end_time = this.wednesday.map(item => item.wednesday_end_time);

            // Thursday
            const thurs_start_time = this.thursday.map(item => item.thursday_start_time);
            const thurs_end_time = this.thursday.map(item => item.thursday_end_time);

            // Friday
            const fri_start_time = this.friday.map(item => item.friday_start_time);
            const fri_end_time = this.friday.map(item => item.friday_end_time);

            // Saturday
            const sat_start_time = this.saturday.map(item => item.saturday_start_time);
            const sat_end_time = this.saturday.map(item => item.saturday_end_time);

            if (sun_start_time.length && sun_end_time.length) {
                form.append("chamber[0][start_time][sunday]", sun_start_time.join(','));
                form.append("chamber[0][end_time][sunday]", sun_end_time.join(','));
                this.final_work_days += "sunday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (mon_start_time.length && mon_end_time.length) {
                form.append("chamber[0][start_time][monday]", mon_start_time.join(','));
                form.append("chamber[0][end_time][monday]", mon_end_time.join(','));
                this.final_work_days += "monday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (tues_start_time.length && tues_end_time.length) {
                form.append("chamber[0][start_time][tuesday]", tues_start_time.join(','));
                form.append("chamber[0][end_time][tuesday]", tues_end_time.join(','));
                this.final_work_days += "tuesday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (wed_start_time.length && wed_end_time.length) {
                form.append("chamber[0][start_time][wednesday]", wed_start_time.join(','));
                form.append("chamber[0][end_time][wednesday]", wed_end_time.join(','));
                this.final_work_days += "wednesday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (thurs_start_time.length && thurs_end_time.length) {
                form.append("chamber[0][start_time][thursday]", thurs_start_time.join(','));
                form.append("chamber[0][end_time][thursday]", thurs_end_time.join(','));
                this.final_work_days += "thursday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (fri_start_time.length && fri_end_time.length) {
                form.append("chamber[0][start_time][friday]", fri_start_time.join(','));
                form.append("chamber[0][end_time][friday]", fri_end_time.join(','));
                this.final_work_days += "friday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (sat_start_time.length && sat_end_time.length) {
                form.append("chamber[0][start_time][saturday]", sat_start_time.join(','));
                form.append("chamber[0][end_time][saturday]", sat_end_time.join(','));
                this.final_work_days += "saturday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            form.append("chamber[0][type]", "private");
            form.append("chamber[0][institute_id]", this.chamber.institute_id);
            form.append("chamber[0][primary_contact_no]", this.chamber.primary_contact_no);
            form.append("chamber[0][secondary_contact_no]", this.chamber.secondary_contact_no);
            // form.append("chamber[0][is_active]", this.chamber.is_active);
            form.append("chamber[0][slot]", this.removeComma(this.time_slots));
            form.append("chamber[0][working_day]", this.removeComma(this.final_work_days));
            form.append("platform", 'backoffice');

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/doctor/chamber/add?api_token=${localStorage.getItem('api_token')}&doctor_id=${this.doctor_id}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            this.$snotify.error(response.data.message);
                        } else {
                            this.$emit('call_to_workpleace');
                            this.getChamberInfo();
                            // location.reload();
                            this.$bvModal.hide('addChamber');
                            this.$snotify.success('Successfully saved');
                        }
                    })
            })
        },

        updateChamber() {

            if (this.chamber.institute_id == '' || this.chamber.institute_id == null) {
                return this.institute_is_required = 'Institute  is required';
            }

            if (this.telimedicine.time_slot == '' || this.telimedicine.time_slot == null) {
                return this.slot_is_required = "Slot is required";
            }

            let form = new FormData();

            // Sunday
            const sun_start_time = this.sunday.map(item => item.sunday_start_time);
            const sun_end_time = this.sunday.map(item => item.sunday_end_time);

            // monday
            const mon_start_time = this.monday.map(item => item.monday_start_time);
            const mon_end_time = this.monday.map(item => item.monday_end_time);

            // Tuesday
            const tues_start_time = this.tuesday.map(item => item.tuesday_start_time);
            const tues_end_time = this.tuesday.map(item => item.tuesday_end_time);

            // Wednesday
            const wed_start_time = this.wednesday.map(item => item.wednesday_start_time);
            const wed_end_time = this.wednesday.map(item => item.wednesday_end_time);

            // Thursday
            const thurs_start_time = this.thursday.map(item => item.thursday_start_time);
            const thurs_end_time = this.thursday.map(item => item.thursday_end_time);

            // Friday
            const fri_start_time = this.friday.map(item => item.friday_start_time);
            const fri_end_time = this.friday.map(item => item.friday_end_time);

            // Saturday
            const sat_start_time = this.saturday.map(item => item.saturday_start_time);
            const sat_end_time = this.saturday.map(item => item.saturday_end_time);

            if (sun_start_time.length && sun_end_time.length) {
                form.append("start_time[sunday]", sun_start_time.join(','));
                form.append("end_time[sunday]", sun_end_time.join(','));
                this.final_work_days += "sunday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (mon_start_time.length && mon_end_time.length) {
                form.append("start_time[monday]", mon_start_time.join(','));
                form.append("end_time[monday]", mon_end_time.join(','));
                this.final_work_days += "monday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (tues_start_time.length && tues_end_time.length) {
                form.append("start_time[tuesday]", tues_start_time.join(','));
                form.append("end_time[tuesday]", tues_end_time.join(','));
                this.final_work_days += "tuesday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (wed_start_time.length && wed_end_time.length) {
                form.append("start_time[wednesday]", wed_start_time.join(','));
                form.append("end_time[wednesday]", wed_end_time.join(','));
                this.final_work_days += "wednesday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (thurs_start_time.length && thurs_end_time.length) {
                form.append("start_time[thursday]", thurs_start_time.join(','));
                form.append("end_time[thursday]", thurs_end_time.join(','));
                this.final_work_days += "thursday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (fri_start_time.length && fri_end_time.length) {
                form.append("start_time[friday]", fri_start_time.join(','));
                form.append("end_time[friday]", fri_end_time.join(','));
                this.final_work_days += "friday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (sat_start_time.length && sat_end_time.length) {
                form.append("start_time[saturday]", sat_start_time.join(','));
                form.append("end_time[saturday]", sat_end_time.join(','));
                this.final_work_days += "saturday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }


            form.append("type", "private");
            form.append("institute_id", this.chamber.institute_id);
            form.append("is_active", this.chamber.is_active);
            form.append("primary_contact_no", this.chamber.primary_contact_no);
            form.append("secondary_contact_no", this.chamber.secondary_contact_no);
            form.append("slot", this.removeComma(this.time_slots));
            form.append("working_day", this.removeComma(this.final_work_days));
            form.append("platform", 'backoffice');
            form.append("_method", 'put');
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/doctor/chamber/update/${this.update_id}?api_token=${localStorage.getItem('api_token')}&doctor_id=${this.doctor_id}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            this.$snotify.error(response.data.message);
                        } else {
                            this.$emit('call_to_workpleace');
                            this.getChamberInfo();
                            // window.location.reload();
                            this.$bvModal.hide('addChamber');
                            this.$snotify.success('Successfully saved');
                        }
                    })
            })
        },

        editChamber(chamber) {
            this.is_modal_loading = true;
            this.getFacilites();
            this.getInstitute();
            this.is_edit = true;
            this.update_id = chamber.id;
            this.$bvModal.show('addChamber');
            // this.chamber = chamber
            this.chamber.amanities =  chamber.amanities?JSON.parse(chamber.amanities):"";
            // this.chamber.contact_number = chamber.contact_number;
            this.chamber.primary_contact_no = chamber.primary_contact_no?chamber.primary_contact_no : '';
            this.chamber.secondary_contact_no = chamber.secondary_contact_no?chamber.secondary_contact_no:'';
            this.chamber.address = chamber.address
            this.chamber.institute_id = chamber.institute_id
            this.chamber.is_active = chamber.is_active
            let first = chamber.working_schedules.slice(0, 1).shift();
            this.telimedicine.time_slot = first ? first.time_per_slot : '';
            chamber.working_schedules.map(item => {
                // this.time_slots = item.time_per_slot;
                if (item.day == 'sunday') {
                    this.chamber_is_sunday = true;
                    this.weeks[0].state = true;
                    // this.sunday = [];
                    this.sunday.push(
                        {
                            sunday_start_time: item.start_time,
                            sunday_end_time: item.end_time,
                        }
                    )

                }

                if (item.day == 'monday') {
                    this.chamber_is_monday = true;
                    this.weeks[1].state = true;
                    // this.monday = [];
                    this.monday.push(
                        {
                            monday_start_time: item.start_time,
                            monday_end_time: item.end_time,
                        }
                    )
                }

                if (item.day == 'tuesday') {
                    this.chamber_is_tuesday = true;
                    this.weeks[2].state = true;
                    // this.tuesday = [];
                    this.tuesday.push(
                        {
                            tuesday_start_time: item.start_time,
                            tuesday_end_time: item.end_time,
                        }
                    )
                }

                if (item.day == 'wednesday') {
                    this.chamber_is_wednesday = true;
                    this.weeks[3].state = true;
                    // this.wednesday = [];
                    this.wednesday.push(
                        {
                            wednesday_start_time: item.start_time,
                            wednesday_end_time: item.end_time,
                        }
                    )
                }

                if (item.day == 'thursday') {
                    this.chamber_is_thursday = true;
                    this.weeks[4].state = true;
                    // this.thursday = [];
                    this.thursday.push(
                        {
                            thursday_start_time: item.start_time,
                            thursday_end_time: item.end_time,
                        }
                    )
                }

                if (item.day == 'friday') {
                    this.chamber_is_friday = true;
                    // this.friday = [];
                    this.weeks[5].state = true;
                    this.friday.push(
                        {
                            friday_start_time: item.start_time,
                            friday_end_time: item.end_time,
                        }
                    )
                }

                if (item.day == 'saturday') {
                    this.chamber_is_saturday = true;
                    // this.saturday = [];
                    this.weeks[6].state = true;
                    this.saturday.push(
                        {
                            saturday_start_time: item.start_time,
                            saturday_end_time: item.end_time,
                        }
                    )
                }
            })
        },
        addNewInstitute(){
            this.$bvModal.show('addNewInstitute')
        },
        saveInstitute(){
            let form = new FormData();
            form.append('name', this.institute.name)
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/institute/create?api_token=${this.doctor_api_token}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            this.$snotify.error(response.data.message);
                        } else {

                            this.$bvModal.hide('addNewInstitute');
                            this.$snotify.success('Successfully saved');
                            this.getInstitute();
                        }
                    })
            })
        }
    },
}
