<template>
  <div class="card">
    <div class="card-body">
      <b-button v-b-toggle.collapse-1 variant="default" @click="toggleView"
                class="btn btn-lg d-block w-100 d-flex justify-content-between">
        <span class="text-dark">Prescriptions Dashboard <span class="text-info">{{ text }}</span></span> <i
          class="fas fa-chevron-down ml-5" v-if="expanded_icon"></i>
        <i class="fas fa-chevron-up ml-5" v-else></i>
      </b-button>
      <div>
        <b-collapse visible id="collapse-1" class="mt-2">
          <b-card>
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="">
                  <template>
                    <!--begin: Item Total-->
                    <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-info">
                      <span class="svg-icon-primary mr-5">
                        <i class="fas fa-calendar-week align-self-center text-primary"></i>
                      </span>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#"
                           class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                          Total count
                        </a>
                        <span class="font-size-lg"></span>
                      </div>
                      <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ counter.total_count }}
                    </span>
                  </span>
                    </div>
                    <!--end: Item -->
                  </template>
                </div>
              </div>
              <div class="col-md-4">
                <div class="">
                  <template>
                    <!--begin: Item Total-->
                    <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-success">
                      <span class="svg-icon-primary mr-5">
                        <i class="fas fa-calendar-week align-self-center text-primary"></i>
                      </span>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#"
                           class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                          Total valid
                        </a>
                        <span class="font-size-lg"></span>
                      </div>
                      <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ counter.total_valid }}
                    </span>
                  </span>
                    </div>
                    <!--end: Item -->
                  </template>
                </div>
              </div>
              <div class="col-md-4">
                <div class="">
                  <template>
                    <!--begin: Item Total-->
                    <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-danger">
                      <span class="svg-icon-primary mr-5">
                        <i class="fas fa-calendar-week align-self-center text-primary"></i>
                      </span>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#"
                           class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                          Total invalid
                        </a>
                        <span class="font-size-lg"></span>
                      </div>
                      <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ counter.total_invalid }}
                    </span>
                  </span>
                    </div>
                    <!--end: Item -->
                  </template>
                </div>
              </div>
              <div class="col-md-4">
                <div class="">
                  <template>
                    <!--begin: Item Total-->
                    <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-success">
                      <span class="svg-icon-primary mr-5">
                        <i class="fas fa-calendar-week align-self-center text-primary"></i>
                      </span>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#"
                           class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                          Total paid
                        </a>
                        <span class="font-size-lg"></span>
                      </div>
                      <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ counter.total_paid }}
                    </span>
                  </span>
                    </div>
                    <!--end: Item -->
                  </template>
                </div>
              </div>
              <div class="col-md-4">
                <div class="">
                  <template>
                    <!--begin: Item Total-->
                    <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-danger">
                      <span class="svg-icon-primary mr-5">
                        <i class="fas fa-calendar-week align-self-center text-primary"></i>
                      </span>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#"
                           class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                          Total unpaid
                        </a>
                        <span class="font-size-lg"></span>
                      </div>
                      <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ counter.total_nonpaid }}
                    </span>
                  </span>
                    </div>
                    <!--end: Item -->
                  </template>
                </div>
              </div>
              <div class="col-md-4">
                <div class="">
                  <template>
                    <!--begin: Item Total-->
                    <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-info">
                      <span class="svg-icon-primary mr-5">
                        <i class="fas fa-calendar-week align-self-center text-primary"></i>
                      </span>
                      <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a href="#"
                           class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                          Total partial paid
                        </a>
                        <span class="font-size-lg"></span>
                      </div>
                      <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ counter.total_partial_paid }}
                    </span>
                  </span>
                    </div>
                    <!--end: Item -->
                  </template>
                </div>
              </div>
            </div>
          </b-card>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrescriptionDashboard",
  props: {
    counter: {},
  },
  data(){
    return {
      text: "View details.",
      expanded_icon: false,
    }
  },
  methods: {
    toggleView() {
      this.expanded = !this.expanded;

      if (this.expanded) {
        this.text = "Hide details.";
        this.expanded_icon = true

      } else {
        this.text = "View details.";
        this.expanded_icon = false;
      }
    },
  }
}
</script>

<style scoped>

</style>