<template>
  <div>
    <div class="row">
      <div class="col-md-2 pr-0">
        <div class="card-box text-center" style="height: 126px;">
          <small class="text-uppercase m-b-20" style="font-weight: 600;"
          >Total Doctors</small
          >
          <h2 class="m-b-20 mt-3 text-primary" data-plugin="counterup">
            {{ total_doctor }}
          </h2>
        </div>
      </div>

      <div class="col-md-10">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row justify-content-between align-items-center">
              <div class="col-md-11">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="w-100">
                    <label for="">From</label>
                    <input
                        type="date"
                        v-model="start_date"
                        class="form-control"
                    />
                  </div>
                  <div class="w-100 mx-2">
                    <label for="">To</label>
                    <input
                        type="date"
                        v-model="end_date"
                        class="form-control"
                    />
                  </div>
                  <div class="w-100 mx-2">
                    <label for="">Select TSM</label>
                    <v-select
                        :options="sales_users"
                        :reduce="(sales_users) => sales_users.id"
                        v-model="seller_id"
                        @input="getFilterData"
                        label="fullname"
                    >
                    </v-select>
                  </div>
                  <div class="mt-8 ml-3">
                    <button
                        type="button"
                        class="btn btn-info"
                        @click="getFilterData()"
                    >
                      Filter
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-md-1 mt-8">
                <div>
                  <div class="d-flex justify-content-end">
                    <div class="mx-2">
                      <b-button variant="danger" class="btn-sm" @click="refresh"
                      >Clear
                      </b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  Specialists -->
    <div class="card-box">
      <b-tabs v-model="tabIndex" card>
        <b-tab title="Analytics">
          <div class="row">
            <div
                class="col-md-6"
                v-if="prescriptionModeChartLoading && prescription_mode.length"
            >
              <div class="card-box">
                <h3 class="text-center">
                  Prescription mode ({{ getTotal(prescription_mode) }})
                </h3>
                <SalesChart
                    @on-receive="update"
                    style="width: 80%; margin:0 auto"
                    :data="prescriptionModeChartData"
                    name="prescription_mode"
                    class="small"
                />
              </div>
            </div>
            <div
                class="col-md-6"
                v-if="mobileDeviceChartLoading && mobile_devices.length"
            >
              <div class="card-box">
                <h3 class="text-center">
                  Mobile Device ({{ getTotal(mobile_devices) }})
                </h3>
                <SalesChart
                    @on-receive="update"
                    style="width: 80%; margin:0 auto"
                    :data="mobileDeviceChartData"
                    name="mobile_device"
                    class="small"
                />
              </div>
            </div>
            <div
                class="col-md-6"
                v-if="devicePreferenceChartLoading && preferences.length"
            >
              <div class="card-box">
                <h3 class="text-center">
                  Device Preference ({{ getTotal(preferences) }})
                </h3>
                <SalesChart
                    @on-receive="update"
                    style="width: 80%; margin:0 auto"
                    :data="devicePreferenceChartData"
                    name="preference"
                    class="small"
                />
              </div>
            </div>
            <div class="col-md-6" v-if="patientSegmentChartLoading">
              <div class="card-box">
                <h3 class="text-center">
                  Patient Segments ({{
                    this.total_followup_patient +
                    this.total_new_patient +
                    this.total_report_patient
                  }})
                </h3>
                <SalesChart
                    @on-receive="update"
                    style="width: 80%; margin:0 auto"
                    :data="patientSegmentChartData"
                    name="patient_segments"
                    class="small"
                />
              </div>
            </div>

            <div class="col-md-6" v-if="areaWisePatientChartLoading">
              <div class="card-box">
                <h3 class="text-center">
                  Area-wise patient ({{
                    this.total_local_area + this.total_other_area
                  }})
                </h3>
                <SalesChart
                    @on-receive="update"
                    style="width: 80%; margin:0 auto"
                    :data="areaWisePatientChartData"
                    name="area_wise_patient"
                    class="small"
                />
              </div>
            </div>

            <div
                class="col-md-6"
                v-if="connectivityChartLoading && connectivities.length"
            >
              <div class="card-box">
                <h3 class="text-center">
                  Connectivity ({{ getTotal(connectivities) }})
                </h3>
                <SalesChart
                    @on-receive="update"
                    style="width: 80%; margin:0 auto"
                    :data="connectivityChartData"
                    name="connectivity"
                    class="small"
                />
              </div>
            </div>
            <div
                class="col-md-6"
                v-if="doctorTypeChartLoading && doctor_type.length"
            >
              <div class="card-box">
                <h3 class="text-center">
                  Doctor type ({{ getTotal(doctor_type) }})
                </h3>
                <SalesChart
                    @on-receive="update"
                    style="width: 80%; margin:0 auto"
                    :data="doctorTypeChartData"
                    name="doctor_type"
                    class="small"
                />
              </div>
            </div>
          </div>

          <div class="card-box" v-if="varchart_loader && specialities.length">
            <h3>Specialists</h3>
            <div class="row">
              <div class="col-md-12">
                  <apexchart
                    class="card-rounded-bottom"
                    :options="chartOptions"
                    :series="series"
                    type="bar"
                ></apexchart>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab title="Specialities">
          <div class="row text-center" v-if="specialities.length">
            <div
                class="col-md-2"
                v-for="(speciality, index) in specialities"
                :key="index"
            >
              <div class="bg-light-primary m-1 py-1">
                <small class="text-uppercase m-b-20">{{
                    speciality.speciality
                  }}</small>
                <h3 class="m-b-20" data-plugin="counterup">
                  {{ speciality.total }}
                </h3>
              </div>
            </div>
          </div>
          <div class="row text-center" v-else>
            <div class="col-md-12">
              <h5>No Data Available</h5>
            </div>
          </div>
        </b-tab>
        <b-tab title="Prescription Mode">
          <div class="row text-center" v-if="prescription_mode.length">
            <div
                class="col-md-2"
                v-for="(prescription, index) in prescription_mode"
                :key="index"
            >
              <div class="m-1 bg-light-primary py-1">
                <small class="text-uppercase m-b-20">{{
                    prescription.prescription_mode
                  }}</small>
                <h3 class="m-b-20" data-plugin="counterup">
                  {{ prescription.total }}
                </h3>
              </div>
            </div>
          </div>
          <div class="row text-center" v-else>
            <div class="col-md-12">
              <h5>No Data Available</h5>
            </div>
          </div>
        </b-tab>

        <b-tab title="Mobile Device">
          <div class="row text-center" v-if="mobile_devices.length">
            <div
                class="col-md-2"
                v-for="(mobile_device, index) in mobile_devices"
                :key="index"
            >
              <div class="m-1 bg-light-primary py-1">
                <small class=" text-uppercase m-b-20">{{
                    mobile_device.mobile_device
                  }}</small>
                <h3 class="m-b-20" data-plugin="counterup">
                  {{ mobile_device.total }}
                </h3>
              </div>
            </div>
          </div>
          <div class="row text-center" v-else>
            <div class="col-md-12">
              <h5>No Data Available</h5>
            </div>
          </div>
        </b-tab>

        <b-tab title="Doctor Type">
          <div class="row text-center" v-if="doctor_type.length">
            <div
                class="col-md-2"
                v-for="(type, index) in doctor_type"
                :key="index"
            >
              <div class="m-1 bg-light-primary py-1">
                <small class="text-uppercase m-b-20">{{
                    (doctorType[type.doctor_type])? doctorType[type.doctor_type]:type.doctor_type
                  }}</small>
                <h3 class="m-b-20" data-plugin="counterup">
                  {{ type.total }}
                </h3>
              </div>
            </div>
          </div>
          <div class="row text-center" v-else>
            <div class="col-md-12">
              <h5>No Data Available</h5>
            </div>
          </div>
        </b-tab>

        <b-tab title="Preference">
          <div class="row text-center" v-if="preferences.length">
            <div
                class="col-md-2"
                v-for="(preference, index) in preferences"
                :key="index"
            >
              <div class="m-1 bg-light-primary py-1">
                <small class=" text-uppercase m-b-20">{{
                    preference.preference
                  }}</small>
                <h3 class="m-b-20" data-plugin="counterup">
                  {{ preference.total }}
                </h3>
              </div>
            </div>
          </div>
          <div class="row text-center" v-else>
            <div class="col-md-12">
              <h5>No Data Available</h5>
            </div>
          </div>
        </b-tab>

        <b-tab title="Speciality wise patients">
          <div class="row">
            <div class="col-md-4">
              <div class="card-box text-center">
                <small class=" text-uppercase m-b-20">Total new patient</small>
                <h3 class="m-b-20 text-primary" data-plugin="counterup">
                  {{ total_new_patient }}
                </h3>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card-box text-center">
                <small class=" text-uppercase m-b-20"
                >Total followup patient</small
                >
                <h3 class="m-b-20 text-primary" data-plugin="counterup">
                  {{ total_followup_patient }}
                </h3>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card-box text-center">
                <small class=" text-uppercase m-b-20"
                >Total report patient</small
                >
                <h3 class="m-b-20 text-primary" data-plugin="counterup">
                  {{ total_report_patient }}
                </h3>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card-box text-center">
                <small class=" text-uppercase m-b-20">Total local area</small>
                <h3 class="m-b-20 text-primary" data-plugin="counterup">
                  {{ total_local_area }}
                </h3>
              </div>
            </div>

            <div class="col-md-4">
              <div class="card-box text-center">
                <small class=" text-uppercase m-b-20">Total other area</small>
                <h3 class="m-b-20 text-primary" data-plugin="counterup">
                  {{ total_other_area }}
                </h3>
              </div>
            </div>

            <div class="col-md-12" v-if="speciality_wise_patient.length">
              <b-table
                  striped
                  bordered
                  hover
                  :items="speciality_wise_patient"
              ></b-table>
            </div>
            <div v-else class="col-md-12 text-center">
              <h5>No Data Available</h5>
            </div>
          </div>
        </b-tab>

        <b-tab title="Logistics cost">
          <div class="row">
            <div class="col-md-4">
              <div class="card-box text-center">
                <span class="text-uppercase m-b-20">Logistics cost</span>
                <h3 class="m-b-20 text-primary" data-plugin="counterup">
                  {{ total_transport_cost }}
                </h3>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card-box text-center">
                <span class="text-uppercase m-b-20">Avg. cost per doctor</span>
                <h3 class="m-b-20 text-primary" data-plugin="counterup">
                  {{ (total_transport_cost / total_doctor).toFixed(2) }}
                </h3>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card-box text-center">
                <span class="text-uppercase m-b-20">Avg. cost per patient</span>
                <h3 class="m-b-20 text-primary" data-plugin="counterup">
                  {{ (total_transport_cost / total_daily_patient).toFixed(2) }}
                </h3>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import SalesChart from "./chart/SalesChart";
import {mapGetters} from "vuex";
// import { dateFormate } from "../../healper/dateTimeFormet";

export default {
  name: "SalesDashboard",
  components: {
    SalesChart,
  },
  data() {
    return {
      tabIndex: 0,
      doctorType: {"mediocre doctors": "mid-level doctors"},
      loading: false,
      sales_users: [],
      seller_id: "",
      start_date: "",
      end_date: "",
      prescription_mode: [],
      specialities: [],
      mobile_devices: [],
      preferences: [],
      connectivities: [],
      doctor_type: [],
      total_new_patient: 0,
      total_doctor: 0,
      total_followup_patient: 0,
      total_report_patient: 0,
      total_other_area: 0,
      total_local_area: 0,
      total_transport_cost: 0,
      speciality_wise_patient: [],
      prescriptionModeChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["red", "green", "blue", "yellow"],
            data: [],
          },
        ],
      },
      mobileDeviceChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["red", "green", "blue", "yellow"],
            data: [],
          },
        ],
      },
      devicePreferenceChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["red", "green", "blue", "yellow"],
            data: [],
          },
        ],
      },
      patientSegmentChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["red", "green", "blue"],
            data: [],
          },
        ],
      },
      areaWisePatientChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["orange", "blue"],
            data: [],
          },
        ],
      },
      connectivityChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["yellow", "blue", "brown"],
            data: [],
          },
        ],
      },
      doctorTypeChartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ["red", "blue", "brown"],
            data: [],
          },
        ],
      },
      prescriptionModeChartLoading: false,
      mobileDeviceChartLoading: false,
      devicePreferenceChartLoading: false,
      patientSegmentChartLoading: false,
      areaWisePatientChartLoading: false,
      connectivityChartLoading: false,
      doctorTypeChartLoading: false,
      varchart_loader: false,
      chartOptions: {
        chart: {
          events: {
            click: (event, chartContext, config) => {
              this.$router.push({path: '/sales-activities', query: {name: chartContext.opts.series[0].name, value: config.config.xaxis.categories[config.dataPointIndex], start_date: this.start_date, end_date: this.end_date, tsm:this.seller_id }})
              // console.log('valobasa', chartContext.opts.series[0].name, 'index',config.dataPointIndex, 'category arrays',config.config.xaxis.categories[config.dataPointIndex]);
            }
          }
        },
        xaxis: {
          categories: [],
        },

      },
      series: [
        {
          name: "speciality",
          data: [],
        },
      ],
      single_speciality: null,
      total_daily_patient: 0,
    };
  },
  created() {
    // this.getSalesUsers();
    localStorage.setItem('tabIndex', 14)
    this.$root.$emit('call-active-menu', 14);
    this.getFilterData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales Dashboard", route: "sales-dashboard"},
    ]);
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    update(data) {
      let routeData = this.$router.resolve({path: '/sales-activities', query: {name: data.name, value: data.value.replace(' (%)', ''), start_date: this.start_date, end_date: this.end_date, tsm:this.seller_id }})
      window.open(routeData.href, '_blank');

    },
    refresh() {
      this.seller_id = "";
      this.start_date = "";
      this.end_date = "";
      this.getFilterData();
    },
    getTotal(arrayData) {
      return arrayData.reduce((n, {total}) => n + total, 0);
    },
    getFilterData() {
      this.prescriptionModeChartLoading = false;
      this.mobileDeviceChartLoading = false;
      this.devicePreferenceChartLoading = false;
      this.patientSegmentChartLoading = false;
      this.areaWisePatientChartLoading = false;
      this.connectivityChartLoading = false;
      this.doctorTypeChartLoading = false;
      this.varchart_loader = false;
      axios
          .get(
              `${
                  process.env.VUE_APP_URL
              }/api/v2/sales/team/activity/dashboard/counts?api_token=${localStorage.getItem(
                  "api_token"
              )}
      ${this.seller_id ? "&seller_id=" + this.seller_id : ""}
      ${this.start_date ? "&start_date=" + this.start_date : ""}
      ${this.end_date ? "&end_date=" + this.end_date : ""}
      `
          )
          .then((response) => {
            if (response.data.status_code == "500") {
              this.$router.push("/access-denied");
            } else {
              this.sales_users = response.data.data.users;
              this.prescription_mode = response.data.data.prescription_mode;
              this.specialities = response.data.data.speciality;
              this.mobile_devices = response.data.data.mobile_device;
              this.preferences = response.data.data.preference;
              this.connectivities = response.data.data.connectivity;
              this.doctor_type = response.data.data.doctor_type;
              this.total_doctor = response.data.data.total_doctor;
              this.total_local_area = response.data.data.total_local_area;
              this.total_other_area = response.data.data.total_other_area;
              this.total_transport_cost = response.data.data.total_transportation_cost;
              this.total_new_patient = response.data.data.total_new_patient;
              this.total_daily_patient = response.data.data.total_daily_patient;
              this.total_followup_patient =
                  response.data.data.total_followup_patient;
              this.total_report_patient = response.data.data.total_report_patient;
              this.speciality_wise_patient =
                  response.data.data.speciality_wise_patient;

              setTimeout(() => {
                this.prescriptionModeChart();
                this.mobileDeviceChart();
                this.devicePreferenceChart();
                this.connectivityChart();
                this.patientSegmentChart();
                this.areaWisePatientChart();
                this.specialitisBar();
                this.doctorTypeChart();
              }, 200);
            }
          });
    },
    prescriptionModeChart() {
      var prescription_mode = [];
      var prescription_mode_type = [];
      var total = this.prescription_mode.reduce((n, {total}) => n + total, 0);
      this.prescription_mode.forEach((item) => {
        prescription_mode.push(
            parseFloat((item.total * 100) / total).toFixed(2)
        );
        prescription_mode_type.push(item.prescription_mode + " (%)");
      });
      this.prescriptionModeChartData.datasets[0].data = [];
      this.prescriptionModeChartData.datasets[0].data = prescription_mode;
      this.prescriptionModeChartData.labels = [];
      this.prescriptionModeChartData.labels = prescription_mode_type;
      this.prescriptionModeChartLoading = true;
    },
    mobileDeviceChart() {
      var mobile_device = [];
      var mobile_device_type = [];
      var total = this.mobile_devices.reduce((n, {total}) => n + total, 0);
      this.mobile_devices.forEach((item) => {
        mobile_device.push(parseFloat((item.total * 100) / total).toFixed(2));
        mobile_device_type.push(item.mobile_device + " (%)");
      });

      this.mobileDeviceChartData.datasets[0].data = [];
      this.mobileDeviceChartData.datasets[0].data = mobile_device;
      this.mobileDeviceChartData.labels = [];
      this.mobileDeviceChartData.labels = mobile_device_type;
      this.mobileDeviceChartLoading = true;
    },
    devicePreferenceChart() {
      var device_preference = [];
      var device_preference_type = [];
      var total = this.preferences.reduce((n, {total}) => n + total, 0);
      this.preferences.forEach((item) => {
        device_preference.push(
            parseFloat((item.total * 100) / total).toFixed(2)
        );
        device_preference_type.push(item.preference + " (%)");
      });

      this.devicePreferenceChartData.datasets[0].data = [];
      this.devicePreferenceChartData.datasets[0].data = device_preference;
      this.devicePreferenceChartData.labels = [];
      this.devicePreferenceChartData.labels = device_preference_type;
      this.devicePreferenceChartLoading = true;
    },
    connectivityChart() {
      var connectivity = [];
      var connectivity_type = [];
      var total = this.connectivities.reduce((n, {total}) => n + total, 0);
      this.connectivities.forEach((item) => {
        connectivity.push(parseFloat((item.total * 100) / total).toFixed(2));
        connectivity_type.push(item.connectivity + " (%)");
      });

      this.connectivityChartData.datasets[0].data = [];
      this.connectivityChartData.datasets[0].data = connectivity;
      this.connectivityChartData.labels = [];
      this.connectivityChartData.labels = connectivity_type;
      this.connectivityChartLoading = true;
    },
    doctorTypeChart() {
      var doctor = [];
      var doctor_type = [];
      var total = this.doctor_type.reduce((n, {total}) => n + total, 0);
      this.doctor_type.forEach((item) => {
        doctor.push(parseFloat((item.total * 100) / total).toFixed(2));
        doctor_type.push((this.doctorType[item.doctor_type])?this.doctorType[item.doctor_type]:item.doctor_type + " (%)");
      });

      this.doctorTypeChartData.datasets[0].data = [];
      this.doctorTypeChartData.datasets[0].data = doctor;
      this.doctorTypeChartData.labels = [];
      this.doctorTypeChartData.labels = doctor_type;
      this.doctorTypeChartLoading = true;
    },
    patientSegmentChart() {
      var total =
          this.total_new_patient +
          this.total_followup_patient +
          this.total_report_patient;
      this.patientSegmentChartData.datasets[0].data = [
        ((this.total_new_patient * 100) / total).toFixed(2),
        ((this.total_followup_patient * 100) / total).toFixed(2),
        ((this.total_report_patient * 100) / total).toFixed(2),
      ];
      this.patientSegmentChartData.labels = [
        "Total new patient (%)",
        "Total follow up patient (%)",
        "Total report patient (%)",
      ];
      if (total > 0) {
        this.patientSegmentChartLoading = true;
      }
    },
    areaWisePatientChart() {
      var total = this.total_local_area + this.total_other_area;
      this.areaWisePatientChartData.datasets[0].data = [
        ((this.total_local_area * 100) / total).toFixed(2),
        ((this.total_other_area * 100) / total).toFixed(2),
      ];
      this.areaWisePatientChartData.labels = [
        "Local Area (%)",
        "Other Area (%)",
      ];
      if (total > 0) {
        this.areaWisePatientChartLoading = true;
      }
    },
    specialitisBar() {
      this.series[0].data = this.specialities.map((item) => item.total);
      this.chartOptions.xaxis.categories = this.specialities.map(
          (item) => item.speciality
      );
      this.varchart_loader = true;
    },
  },
};
</script>

<style scoped>
.card-box {
  padding: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
}

.tilebox-one i {
  font-size: 30px;
}

.tilebox-two i {
  font-size: 48px;
  opacity: 0.2;
  margin-top: 20px;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.box-item {
  background: #f2f5f8;
  padding: 7px;
  border-radius: 7px;
  margin: 0px 5px;
}

.card-box {
  overflow: scroll;
}
</style>
