<template>
  <div>
    <span class="sub_title" v-if="sub_title">
    {{ sub_title }}
    </span>
    <h3 v-if="item">  <slot></slot> {{ item }}</h3>
  </div>
</template>

<script>
export default {
  name: "ValueWithSubTitle",
  props: {
    sub_title: {},
    item: {}
  },
}
</script>

<style scoped>
.sub_title {
  color: #313131 !important;
  font-size: 10px !important;
}
</style>
