<template>
  <div class="row my-10">
    <div class="col-md-6">
      <div class="card card-box">
        <div class="card-body">
          <h3 class="mb-10 text-center">Orko Users</h3>
          <PieChart :data="chartData" :options="{responsive: true, maintainAspectRatio: false}" class="small"></PieChart>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card card-box">
        <div class="card-body">
          <h3 class="mb-10 text-center">Appointments</h3>
<!--          <pie-chart :data="chartData" :options="chartOptions" class="small"></pie-chart>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PieChart from "./PieChart";
// import axios from "axios";

export default {
  name: "Pie",
  components: {
    PieChart
  },
  data() {
    return {
      chartData: {
        labels: ["Upvotes", "Shares", "Views"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "blue"],
            data: []
          }
        ]
      }
    };
  },
  mounted() {
    // this.getData();
    this.showStats();
  },

  methods: {
    showStats() {
      this.showChart = true;
      let rd = Math.floor(Math.random() * 10 + 1);
      this.chartData.datasets[0].data = [];
      this.chartData.datasets[0].data = [5, 8, 2];

    },
  }
}
</script>

<style scoped>
.small {
  height: 300px;
  width: 300px;
  margin: 0 auto;
}

.card-box {
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
}
</style>