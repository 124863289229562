import axios from "axios";

export const BookAppointmentMixin = {
    data() {
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        // 15th two months prior
        const minDate = new Date(today)
        return {
            search_text: '',
            items: [],
            fields: [
                {
                    key: 'actions',
                    label: 'Check Schedule',
                    sortable: true,
                },
                {
                    key: 'image',
                    label: 'Doctor info',
                    sortable: true
                },
                {
                    key: 'card_intro',
                    label: 'Card intro',
                    sortable: true,
                },
                {
                    key: 'degrees',
                    label: 'Degree',
                    sortable: true,
                },
                {
                    key: 'education_institute',
                    label: 'Education Institute',
                    sortable: true,
                },
                {
                    key: 'experience',
                    label: 'Experience',
                    sortable: true,
                },
                {
                    key: 'location',
                    label: 'Location',
                    sortable: true,
                },
                {
                    key: 'nature_of_service',
                    label: 'Nature of service',
                    sortable: true,
                },
                {
                    key: 'specialities',
                    label: 'Specialities',
                    sortable: true,
                },
                {
                    key: 'visit_fee',
                    label: 'Visit fee',
                    sortable: true,
                },
                {
                    key: 'workplace_institute',
                    label: 'Workplace institute',
                    sortable: true,
                },

            ],
            patients: [],
            form: {
                user_id: '',
            },
            patient_register: {
                first_name: '',
                last_name: '',
                phone_number: '',
                age: '',
                gender: 'male',
            },
            doctor_info: null,
            vartual: {
                date: '',
                avaiable_slot: null,
                schedule_date: '',
                schedule_started_at: '',
                schedule_end_at: '',
                doctor_id: '',
                patient_id: '',
                payable_amount: '',
                workplace_id: '',
            },
            visit: {
                schedule_date: '',
            },
            loading: false,
            avaible_slot: null,
            selected: '',
            time_slot: '',
            schedule_end_at: '',
            filterOptions: [
                {text: 'Doctor name', value: 'name'},
                {text: 'Doctor phone number', value: 'phone_number'},
                {text: 'Location', value: 'location'},
                {text: 'Nature of service', value: 'nature_of_service'},
                {text: 'Workplace institute', value: 'workplace_institute'},
            ],
            filter_value: 'name',
            all_slots: '',
            visit_chamber: [],
            visible: false,
            chamber_details: '',
            not_data_found: '',
            min: minDate,
            gender: [
                {text: 'Male', value: 'male'},
                {text: 'Female', value: 'female'},
                {text: 'Other', value: 'other'},
            ],
            // max: maxDate
            patient_first_name_state: null,
            patient_last_name_state: null,
            patient_age_state: null,
            patient_gender_state: null,
            patient_phone_number_state: null,
            service_type: '',
            click_virtual: true,
            click_visit: false,
            get_payable_amount_call: '',
            get_payable_amount_visit: '',
        }
    },
    created() {
        localStorage.setItem('tabIndex', 10)
        this.$root.$emit('call-active-menu', 10);
        this.getPatients();
    },
    computed: {
        validation() {
            return this.patient_register.phone_number.length == 11
        }
    },
    methods: {
        onReset() {

        },
        searchDoctor() {
            this.loading = true;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/search/information?type=user&search_data_type=${this.filter_value}&searched_text=${this.search_text}&api_token=${localStorage.getItem('api_token')}&platform=backoffice`)
                .then(response => {
                    this.items = response.data.data
                    this.loading = false;

                })
        },
        getPatients() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?user_type=patient&limit=-1&platform=backoffic&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.patients = response.data.data
                })
        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../../assets/img/blank.png";
            }
        },
        schedule(item) {
            this.click_virtual = true;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${item.category_id}?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.doctor_info = response.data.data;
                    this.$bvModal.show('scheduleModal');


                    this.get_payable_amount_call = response.data.data.doctor_information.service_fee.call_fee;
                    this.get_payable_amount_visit = response.data.data.doctor_information.service_fee.visit_fee;


                    this.vartual.doctor_id = response.data.data.id;
                    response.data.data.doctor_information.workplace.map(item => {
                        if (item.name === 'Telemedicine') {
                            this.avaible_slot = item.chamber_schedules;
                        }
                    });

                })

        },
        resetModal() {
            this.visit_apointment();
            // this.vartual = {};
            this.vartual.date = '';
            this.vartual.avaiable_slot = null;
            this.vartual.patient_id = '';
            this.click_virtual = true;
            this.click_visit = false;
            this.vartual.avaiable_slot = '';
            this.vartual.date = '';
            this.vartual.avaiable_slot = null;
            this.vartual.schedule_date = '';
            this.vartual.schedule_started_at = '';
            this.vartual.schedule_end_at = '';
            this.vartual.doctor_id = '';
            this.vartual.patient_id = '';
            this.vartual.payable_amount = '';
            this.vartual.workplace_id = '';
            this.get_payable_amount_visit = '';
            this.get_payable_amount_call = '';

            this.visit_chamber = '';
            // this.vartual={};
        },
        resetModalNewPatent() {
            this.patient_register = {
                first_name: '',
                last_name: '',
                phone_number: '',
                age: '',
                gender: 'male',
            };
            this.patient_first_name_state = null;
            this.patient_last_name_state = null;
            this.patient_age_state = null;
            this.patient_gender_state = null;
            this.patient_phone_number_state = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.confirmBooking()
        },
        handlePatient(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.saveNewPatient()
        },
        checkValidationForAddPatient() {
            const valid = this.$refs.form.checkValidity()
            this.patient_first_name_state = valid
            this.patient_last_name_state = valid
            this.patient_age_state = valid
            this.patient_gender_state = valid

            return valid
        },
        saveNewPatient() {
            if (!this.checkValidationForAddPatient()) {
                return
            }
            if (!this.validation) {
                return "Phone number 11 digit required";
            }
            let form = new FormData();
            form.append("first_name", this.patient_register.first_name);
            form.append("last_name", this.patient_register.last_name);
            form.append("dob", this.patient_register.age);
            form.append("gender", this.patient_register.gender);
            // form.append("email", "");
            form.append("phone_number", "+88" + this.patient_register.phone_number);
            form.append("user_type", 'patient');
            form.append("password", this.patient_register.phone_number);
            // form.append("platform", "register");
            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/patient/create`, form)
                .then(response => {

                    this.checkResponse(response.data.message, response.data.status_code);
                    this.getPatients();
                    this.$bvModal.hide('modal-multi-2')

                });
        },
        onContext() {
            this.loading = true;
            this.service_type = 'virtual';
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/doctor/chamber/list?api_token=${this.doctor_info.api_token}&schedule_date=${this.vartual.date}&type=telemedicine&doctor_id=${this.doctor_info.id}`)
                .then(res => {
                    if (res.data.data[0].working_schedules.length) {
                        this.vartual.workplace_id = res.data.data[0].working_schedules[0].workplace_id
                    } else {
                        alert('workpleace id not found');
                        this.vartual.avaiable_slot = '';
                        this.vartual.date = '';
                        this.loading = false;
                        return true;

                    }
                    this.vartual.avaiable_slot = '';
                    this.vartual.workplace_id = res.data.data[0].working_schedules[0].workplace_id
                    let items = [];
                    if (res.data.data[0].working_schedules.length) {
                        res.data.data[0].working_schedules.map(item => {
                            items.push(item);
                            this.vartual.avaiable_slot = item.slots;
                            this.vartual.schedule_end_at = item.time_per_slot
                            this.loading = false;
                        })
                    }
                    this.loading = false;
                    this.all_slots = items;
                })
        },
        onVisit() {

            this.loading = true;
            this.service_type = 'visit';
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/doctor/chamber/list?api_token=${this.doctor_info.api_token}&schedule_date=${this.vartual.date}&doctor_id=${this.doctor_info.id}`)
                .then(res => {
                    this.vartual.avaiable_slot = '';
                    this.visit_chamber = res.data.data;
                    res.data.data.map(item => {
                        if (item.working_schedules.length) {
                            this.vartual.workplace_id = item.working_schedules[0].workplace_id
                            return true
                        } else {
                            // alert('workpleace id not found');
                            this.vartual.avaiable_slot = '';
                            this.loading = false;
                            return true;
                        }
                    })
                    // if (res.data.data.working_schedules) {
                    //     this.vartual.workplace_id = res.data.data[0].working_schedules[0].workplace_id
                    // } else {
                    //     alert('workpleace id not found');
                    //     this.vartual.avaiable_slot = '';
                    //     this.loading = false;
                    //     return true;
                    //
                    // }

                    let items = [];
                    if (res.data.data[0].working_schedules.length) {
                        res.data.data[0].working_schedules.map(item => {
                            items.push(item);
                            this.vartual.avaiable_slot = item.slots;
                            this.vartual.schedule_end_at = item.time_per_slot
                            this.loading = false;
                        })
                    }
                    // this.vartual.avaiable_slot = "";
                    this.loading = false;
                    this.all_slots = items;
                })
        },
        confirmBooking() {

            let form = new FormData();
            form.append('schedule_date', this.vartual.date);
            form.append('schedule_started_at', this.time_slot);
            form.append('workplace_id', this.vartual.workplace_id);
            form.append('doctor_id', this.doctor_info.id);
            form.append('schedule_end_at', this.vartual.schedule_end_at);
            form.append('type', 'appointment_booking_request_sent');
            form.append('appointment_status', 'booked');
            form.append('service_type', this.service_type);
            form.append('appointment_type', 'booked_appointment');
            form.append('patient_id', this.vartual.patient_id);
            form.append('payable_amount', this.vartual.payable_amount);
            form.append('api_version', 'v2');
            axios
                .post(`${process.env.VUE_APP_URL}/api/v2/appointment/book?api_token=${localStorage.getItem('api_token')}`, form)
                .then(res => {
                    if (res.data.status_code == 400) {
                        this.$snotify.error(res.data.message);
                    } else {
                        this.$bvModal.hide('scheduleModal');
                        this.$snotify.success('Successfully saved');
                        // this.vartual = {};
                    }
                });
        },
        chamberId(chamber) {
            this.chamber_details = chamber;
        },
        visit_apointment() {
            this.vartual.date = null;
            this.click_visit = true;
            this.click_virtual = false;
            this.vartual.patient_id = ''
            this.vartual.payable_amount = this.get_payable_amount_visit;
        },
        vartual_apointment() {
            this.time_slot = '';
            this.vartual.date = null;
            this.click_visit = false;
            this.click_virtual = true;
            this.vartual.patient_id = '';
            this.vartual.payable_amount = this.get_payable_amount_call;
        }
    }
}
