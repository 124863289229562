<template>
  <div>
    <b-modal id="modal-multi-2"
             title="Add institute"
             @show="resetModal"
             @hidden="instituteModalHide"
             @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div>
          <b-form-group
              label="Institute name"
              label-for="name-input"
              invalid-feedback="Institute name is required"
              :state="nameState">
            <b-form-input
                id="name-input"
                v-model="form.name"
                :state="nameState"
                required
            ></b-form-input>
          </b-form-group>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AddInstitute",
  mounted() {
    this.$bvModal.show('modal-multi-2');
  },
  data() {
    return {
      form: {
        name: '',
      },
      nameState: null,
    }
  },
  methods: {
    instituteModalHide() {
      this.add_institute = false;
      this.$emit('hideInstituteModal', false);
      this.$bvModal.hide('modal-multi-2');
    },
    resetModal() {
      this.nameState = null
      this.$bvModal.hide('modal-multi-2');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.$nextTick(() => {
        let form = new FormData();
        form.append("name", this.form.name);
        axios
            .post(`${process.env.VUE_APP_URL}/api/v1/institute/create?api_token=${localStorage.getItem('api_token')}`, form)
            .then(response => {
              if (response.data.status_code == 400) {
                return this.$snotify.error('Something worng');
              }
              this.$bvModal.hide('modal-multi-2')
              this.$snotify.success('Exprience added Successfully saved');
            })
      })
    }
  }
}
</script>

<style scoped>

</style>