import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const CenterWisePatientListReportMixin = {
    data() {
        return {
            json_fields: {
                "Patient name": {
                    field: "user_name",
                },
                "Patient phone number": {
                    field: "user_phone_number",
                },
                "Patient age": {
                    field: "user_age",
                },
                "Patient gender": {
                    field: "user_gender",
                },
                "Patient blood group": {
                    field: "blood_group",
                },
                "Orko center": {
                    field: "orko_center",
                },
                "Is block ": {
                    field: 'is_block',
                    callback: (value) => {
                        return value == true ? 'Unblocked' : 'Blocked';
                    },
                },
            },
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            currentPage: 1,
            perPage: 30,
            pageOptions: [30, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                index: '',
                transaction_date: '',
                doctor_name: '',
                unique_id: '',
                doctor_phone: '',
                prescription_identifier: '',
                service_type: '',
                discount: '',
                paid_amount: '',
                transaction_amount: '',
                transaction_id: '',
            },
            items: [],
            loading: true,

            fields: [
                {
                    key: 'index',
                    label: 'SL#',
                    sortable: true
                },
                {
                    key: 'user_info',
                    label: 'Patient info',
                    sortable: true
                },
                {
                    key: 'user_age',
                    label: 'Patient age',
                    sortable: true
                },
                {
                    key: 'user_gender',
                    label: 'Gender',
                    sortable: true
                },
                {
                    key: 'blood_group',
                    label: 'Blood group',
                    sortable: true
                },
                {
                    key: 'is_connection_accepted',
                    label: 'Connection accepted',
                    sortable: true
                },
                {
                    key: 'is_block',
                    label: 'Block',
                    sortable: true
                },
                {
                    key: 'walk_in_patient',
                    label: 'Walk in patient',
                    sortable: true
                },
                {
                    key: 'chronic_diseases',
                    label: 'Chronic diseases',
                    sortable: true
                },

            ],
            filter: null,
            filterOn: [],
            startDate: null,
            endDate: null,

            from_date: '',
            to_date: '',
            total_count: 0,
            centers: [],
            patient_count: 0,
            center_id: '',

        }
    },
    mounted() {
        this.agentList();
        this.getCenterWisePatientList();
    },
    created() {
        localStorage.setItem('tabIndex', 4)
        this.$root.$emit('call-active-menu', 4);
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Center wise patient list", route: "/transaction-report"},
        ]);
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        rows() {
            return this.items.length
        },
        filtered() {

            const filtered = this.items.filter(item => {

                return Object.keys(this.filters).every(key =>

                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                index: '',
                transaction_date: '',
                doctor_name: '',
                unique_id: '',
                doctor_phone: '',
                prescription_identifier: '',
                service_type: '',
                paid_amount: '',
                transaction_amount: '',
                discount: '',
                transaction_id: '',
            }]
        },

        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        }
    },
    methods: {
        agentList() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/orkocenter/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false
                        this.centers = response.data.data
                    }
                })
        },
        getCenterWisePatientList() {

            this.loading = true;
            axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/patient/list?api_token=${localStorage.getItem('api_token')}${this.center_id ? '&center_id=' + this.center_id : ''}${this.startDate ? '&start_date=' + this.startDate : ''}${this.endDate ? '&end_date=' + this.endDate : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false

                        if (response.data.data == null) {
                            this.items = [];
                        } else {
                            this.items = response.data.data
                        }
                        this.patient_count = response.data.count

                    }
                })
        },
        handlePageChange(value) {
            this.loading = true;

            var offset = value - 1;
            var correctValue = offset * this.perPage;

            axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/transaction/report?start_from=${this.startDate}&end_to${this.endDate}&api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=${correctValue}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false
                        this.items = response.data.data.report;
                        this.paid_amount = response.data.data.paid_amount_sum;
                        this.trxn_amount_sum = response.data.data.trxn_amount_sum;
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },
        clearDate() {
            this.startDate = null;
            this.endDate = null;
            this.getCenterWisePatientList();
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return process.env.BASE_URL + 'media/users/blank.png';
            }
        },
    },

}