<template>
  <div class="my-3 d-flex justify-content-between">

    <b-button
        pill
        variant="outline-secondary"
        @click="$router.go(-1)">
      <span class="mr-3">
        <i class="fas fa-arrow-left"></i>
      </span>Back
    </b-button>
    <!--    <breadcrumbs class="row justify-content-center mt-4" :crumbs="crumbs" />-->
    <b-breadcrumb :items="breadCrumbs" v-if="breadCrumbs"></b-breadcrumb>

  </div>
</template>

<script>
export default {
  name: "BackButton",
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
      vm.nextRoute = next;
    })
  },
  data() {
    return {
      items: [
        {
          text: 'Dashboard',
          href: '/dashboard',
        },
        {
          text: "test",
          href: 'test',
        },
        {
          text: this.nextRoute,
          active: true
        }

      ],
      prevRoute: null,
      nextRoute: null,

    }
  },
  computed: {
    breadCrumbs () {
      let pathArray = this.$route.path.split('/')
      pathArray.shift()
      const breadCrumbs = []
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = ''
      let lastIndexFound = 0
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${'/'}${pathArray[i]}`
        if (this.$route.matched[i] &&
            Object.hasOwnProperty.call(this.$route.matched[i], 'meta') &&
            Object.hasOwnProperty.call(this.$route.matched[i].meta, 'breadCrumb')) {
          breadCrumbs.push({
            href: i !== 0 && pathArray[i - (i - lastIndexFound)]
                ? '/' + pathArray[i - (i - lastIndexFound)] + breadcrumb
                : breadcrumb,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          })
          lastIndexFound = i
          breadcrumb = ''
        }
      }
      return breadCrumbs
    }
  },

}
</script>

<style scoped>

</style>