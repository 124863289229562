<template>
  <div>


    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <h2>Medicine Company</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add medicine
              company
            </b-button>
            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a new medicine company"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Company name is required"
                    :state="titleState">
                  <label for="title-input"> Company name <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.title"
                      :state="titleState"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="address-input" invalid-feedback="Company address is required"
                    :state="addressState">
                  <label for="address-input"> Company address </label>
                  <b-form-input
                      id="title-input"
                      v-model="form.address"
                      :state="addressState"
                      required

                  />
                </b-form-group>

                <b-form-group invalid-feedback="Company type is required"
                              :state="typeState">
                  <label for="">Select company type</label>
                  <b-form-select v-model="form.company_type" :options="company_type" :state="typeState" required></b-form-select>
                </b-form-group>


              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Medicine company"
                  name="Medicine company">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class=" mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(company_address)="data">
                <p v-if="data.item.company_address">{{ data.item.company_address }}</p>
                <p v-else> n/a </p>
              </template>

              <template #cell(man_lic_bio)="data">
                <p v-if="data.item.man_lic_bio">{{ data.item.man_lic_bio }}</p>
                <p v-else> n/a </p>
              </template>

              <template #cell(man_lic_non_bio)="data">
                <p v-if="data.item.man_lic_non_bio">{{ data.item.man_lic_non_bio }}</p>
                <p v-else> n/a </p>
              </template>

              <template #cell(company_type)="data">
                <p v-if="data.item.company_type">{{ data.item.company_type }}</p>
                <p v-else> n/a </p>
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">

                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>
                      <b-dropdown-item href="#" @click="editAction(row)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="deleteAction(row)">
                        <span class="pr-4"><i class="fas fa-trash"></i></span> Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {MedicineCompanyMixin} from "../../mixins/medicine/MedicineCompanyMixin";

export default {
  name: "MedicineCompany",
  mixins: [MedicineCompanyMixin],
}
</script>

<style scoped>

</style>