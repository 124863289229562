<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <b-button v-b-modal.modal variant="primary"> <span class="pr-4"><i class="fas fa-user-cog"></i></span>Assign Permissions</b-button>
          <b-button variant="warning" @click="permissionGenerate">
            <span class="pr-4"><i class="fas fa-random"></i></span>Permission generate </b-button>
        </div>
        <div>
          <!--  add role wise permission modal start -->
          <b-modal
              id="modal"
              title="Assign Permissions"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk"
              size="lg">
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <label for="">Select role</label>
              <b-form-select v-model="form.role_id" class="mb-3">
                <b-form-select-option :value="item.id" v-for="(item, index) in items" :key="index">
                  {{ item.name }}
                </b-form-select-option>
              </b-form-select>


              <div class="single-module" v-for="(permission,index) in permissions" :key="index">
                <h5 @click="toggle(index)" class="px-4 b mb-0 d-flex justify-content-between padding-bottom-10">
                  <div class="d-flex">
                    <b-form-checkbox
                        size="lg">
                    </b-form-checkbox>
                    {{ index }}
                  </div>
                  <div>
                    <span class="px-5"> <i class="fas fa-chevron-up"></i> </span>

                  </div>
                </h5>
                <div v-show="isOpen" class="p-3 px-4 accordion-description">
                  <div v-for="(permission_data, dfs) in permission" :key="dfs">
                    <b-form-checkbox
                        size="lg"
                        :value="permission_data[0]"
                        v-model="permissions_id[permission_data[0]]"
                        @change="checkPermissions($event, permission_data[0])">
                      {{ permission_data[1] }} <br>
                      <span class="api_url">{{ permission_data[3] }}</span>
                    </b-form-checkbox>
                  </div>
                </div>

              </div>

            </form>
          </b-modal>
          <!--  add role wise permission modal start -->

          <table class="table table-striped table-hover mt-5">
            <thead>
            <tr class="bg-primary">
              <th>Sl.</th>
              <th>Role</th>
              <th>Permissions</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td> {{ index + 1 }}</td>
              <td> {{ item.name }}</td>
              <td>
<!--                <button type="button" class="btn btn-primary btn-sm" @click="roleWisePermissionUpdate(item)">Manage-->
<!--                </button>-->

                <router-link :to="`assign-permissions/${item.id}`" class="btn btn-primary btn-sm">
                  <span class="pr-4"><i class="fas fa-eye"></i></span>Manage permission
                </router-link>
              </td>

              <td>
                <router-link :to="`/roles/show/${item.id}`" class="btn btn-primary btn-sm mx-1 "><i class="fas fa-eye"></i></router-link>
              </td>
            </tr>
            </tbody>
          </table>

          <!-- update permission modal start  -->
          <b-modal
              id="modal-update"
              title="Update Permissions"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk"
              size="lg">
            <b-spinner variant="primary" label="Spinning" v-if="modal_loading"></b-spinner>
            <form ref="form" @submit.stop.prevent="handleSubmit" v-else>
              <label for="">Select role</label>
              <b-form-select v-model="form.role_id" class="mb-3" disabled>
                <b-form-select-option :value="item.id" v-for="(item, index) in items" :key="index">
                  {{ item.name }}
                </b-form-select-option>
              </b-form-select>
              <!--              <p>{{ updated_permission_data }}</p>-->
              <div class="single-module" v-for="(permission,index) in permissions" :key="index">


                <h5 @click="toggle(index)" class="px-4 b mb-0 d-flex justify-content-between padding-bottom-10">
                  <div class="d-flex">
                    <b-form-checkbox
                        size="lg">
                    </b-form-checkbox>
                    {{ index }}
                  </div>
                  <div>
                    <span class="px-5"> <i class="fas fa-chevron-up"></i> </span>
                  </div>
                </h5>
                <div v-show="isOpen" class="p-3 px-4 accordion-description">
                  <div v-for="(permission_data, dfs) in permission" :key="dfs">

                    <b-form-checkbox
                        size="lg"
                        :value="permission_data[0]"
                        v-model="permissions_id">

                      {{ permission_data[1] }} <br>
                      <span class="api_url">{{ permission_data[3] }}</span>

                    </b-form-checkbox>
                  </div>
                </div>

              </div>

            </form>
          </b-modal>
          <!-- update permission modal start  -->

        </div>
      </div>
    </div>

    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {AssignPermission} from "../../mixins/AssignPermission";

export default {
  name: "AssignPermissions",
  mixins: [AssignPermission],
  title: 'Assign permission',
}
</script>

<style scoped>
.accordion-description {
  margin-left: 30px;
}

.api_url {
  font-size: 10px;
  background: #00aff0;
  padding: 2px;
  border-radius: 7px;
}
</style>