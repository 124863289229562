<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <h2>Investigation</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new
              investigation
            </b-button>
            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                :title="`${edit ? 'Update' : 'Create a new'} investigation`"
                @show="resetModal"
                @hidden="resetModal"
                scrollable
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data" style="height: 50vh;">

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Title is required"
                    :state="titleState">
                  <label for="title-input">Title <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.title"
                      :state="titleState"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Title is required"
                    :state="titleState">
                  <label for="title-input">Select investigation service <span class="text-danger">*</span></label>
                  <v-select
                      :options="services"
                      :reduce="services => services.id"
                      label="name"
                      v-model="form.investigation_service_id"
                      name="name"
                      required
                  >
                  </v-select>
                </b-form-group>
              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Investigation"
                  name="Investigation">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class=" mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>


              <template #cell(investigation_service)="data">
                {{ data.item.investigation_service ? data.item.investigation_service.name : 'N/A' }}
              </template>


              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">

                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>
                      <b-dropdown-item href="#" @click="editAction(row)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="deleteAction(row)">Delete</b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {InvestigationMixin} from "../../mixins/miscellaneous/InvestigationMixin";

export default {
  name: "Investigation",
  mixins: [InvestigationMixin]
}
</script>

<style scoped>

</style>