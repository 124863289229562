<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div>
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div
                class="d-flex justify-content-between align-items-center mr-3"
              >
                <h3
                  class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3"
                >
                  Medical Registration
                </h3>

                <b-button
                    class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3 edit_btn"
                    @click="addBMDC()"
                  >
                    Add
                  </b-button>

                <!--      BMDC screeshot modal         -->
                <b-modal
                  id="upload-bmdc"
                  ref="modal"
                  size="lg"
                  title="Submit bmdc screenshot"
                  @show="resetModal"
                  @hidden="resetModal"
                  @ok="handleBMDCScreenShot"
                >
                  <form
                    ref="form"
                    @submit.stop.prevent="handleBMDCScreenshotSubmit"
                  >
                    <div class="preview text-center">
                      <img
                        v-if="image_preview"
                        class="preview-img"
                        :src="image_preview"
                        alt="Preview Image"
                        width="200"
                        height="200"
                      />

                      <img
                        v-else-if="exist_image"
                        class="preview-img"
                        :src="userPhoto(exist_image)"
                        alt="Preview Image"
                        width="200"
                        height="200"
                      />

                      <img
                        v-else
                        class="preview-img"
                        :src="
                          image_preview
                            ? image_preview
                            : `https://cdn.getshifter.co/9ffafe227584833e1d83fe8314723e0fe39da375/uploads/2017/11/null25-1024x759.jpeg`
                        "
                        alt="Preview Image"
                        width="300"
                        height="300"
                      />
                      <div class="browse-button">
                        <i class="fa fa-pencil-alt"></i>
                        <input
                          class="browse-input"
                          @change="onFileChange"
                          type="file"
                          name="UploadedFile"
                          id="cimage"
                          ref="screenshot_image"
                          accept=".png,.jpg,.jpeg"
                        />
                      </div>
                      <span class="Error"></span>
                    </div>
                  </form>
                </b-modal>
                <!--      Edit  modal        -->
                <b-modal
                  id="editBmdc"
                  title="Edit medical registration"
                  @hidden="resetModal"
                  size="lg"
                  @ok="handleOk"
                >
                  <form ref="form" @submit.stop.prevent="editBmdcNumber">
                    <div class="mb-4">
                      <h3 for="cimage">
                        Certificate image <span class="text-danger">*</span>
                      </h3>
                      <div class="preview text-center">
                        <img
                          v-if="image_preview"
                          class="preview-img"
                          :src="image_preview"
                          alt="Preview Image"
                          width="200"
                          height="200"
                        />

                        <img
                          v-else-if="exist_image"
                          class="preview-img"
                          :src="userPhoto(exist_image)"
                          alt="Preview Image"
                          width="200"
                          height="200"
                        />

                        <img
                          v-else
                          class="preview-img"
                          :src="
                            image_preview
                              ? image_preview
                              : `https://cdn.getshifter.co/9ffafe227584833e1d83fe8314723e0fe39da375/uploads/2017/11/null25-1024x759.jpeg`
                          "
                          alt="Preview Image"
                          width="200"
                          height="200"
                        />
                        <div class="browse-button">
                          <i class="fa fa-pencil-alt"></i>
                          <input
                            class="browse-input"
                            @change="onFileChange"
                            type="file"
                            name="UploadedFile"
                            id="cimage"
                            ref="certificate_image"
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                        <span class="Error"></span>
                      </div>
                    </div>
                    <b-form-group label-for="bmdc_no" class="mb-4">
                      <label for="bmdc_no"
                        >BMDC No <span class="text-danger">*</span></label
                      >
                      <b-form-input
                        id="bmdc_no"
                        v-model="form.bmdc_no"
                      ></b-form-input>
                    </b-form-group>


                    <div class="row">
                      <div class="col-md-6">
                        <b-form-group label-for="res_year" class="mb-4">
                          <label for="res_year"
                            >Registration year
                            <span class="text-danger">*</span></label
                          >
                          <b-form-input
                            type="number"
                            id="res_year"
                            v-model="form.reg_year"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group label-for="exp_year" class="mb-4">
                          <label for="exp_year"
                            >Expired year
                            <span class="text-danger">*</span></label
                          >
                          <b-form-input
                            id="exp_year"
                            v-model="form.expired_date"
                            type="number"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>


                    <b-form-group label-for="name" class="mb-4">
                      <label for="name"
                        >Authority name
                        <span class="text-danger">*</span></label
                      >
                      <v-select
                        :options="medical_registration_authority"
                        :reduce="
                          (medical_registration_authority) =>
                            medical_registration_authority.id
                        "
                        v-model="form.registration_authority_id"
                        label="name"
                        name="id"
                      >
                      </v-select>
                      <p class="text-danger">
                        {{ medical_registration_authority_is_required }}
                      </p>
                      <!--                      <b-form-input-->
                      <!--                          id="name"-->
                      <!--                          v-model="form.authority_name"-->
                      <!--                      ></b-form-input>-->
                    </b-form-group>

                    <div class="mb-4">
                      <h3 for="simage">Screenshot upload</h3>

                      <div class="preview text-center">
                        <img
                          v-if="screenshot_image_preview"
                          class="preview-img"
                          :src="screenshot_image_preview"
                          alt="Preview Image"
                          width="200"
                          height="200"
                        />

                        <img
                          v-else-if="exist_screenshot"
                          class="preview-img"
                          :src="userPhoto(exist_screenshot)"
                          alt="Preview Image"
                          width="200"
                          height="200"
                        />

                        <img
                          v-else
                          class="preview-img"
                          :src="
                            screenshot_image_preview
                              ? screenshot_image_preview
                              : `https://cdn.getshifter.co/9ffafe227584833e1d83fe8314723e0fe39da375/uploads/2017/11/null25-1024x759.jpeg`
                          "
                          alt="Preview Image"
                          width="300"
                          height="300"
                        />
                        <div class="browse-button">
                          <i class="fa fa-pencil-alt"></i>
                          <input
                            class="browse-input"
                            @change="onScreenChange"
                            type="file"
                            name="UploadedFile"
                            id="simage"
                            ref="screenshot_image"
                            accept=".png,.jpg,.jpeg"
                          />
                        </div>
                        <span class="Error"></span>
                      </div>
                    </div>
                  </form>
                </b-modal>
              </div>
            </div>
            <div class="row mt-10" v-if="medical_registration">
              <div
                class="col-md-4 mb-5"
                v-for="(medical_reg, index) in medical_registration"
                :key="index"
              >
                <div class="card position-relative">
                  <img
                    v-if="medical_reg.image"
                    :src="
                      doctorSignature(
                        medical_reg.image ? medical_reg.image : ''
                      )
                    "
                    class="card-img-top img-fluid card-img"
                    alt="orko"
                    @click="showImage(medical_reg.image)"
                  />
                  <b-button
                    class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3 edit_btn"
                    @click="editBmdc"
                  >
                    Edit
                  </b-button>
                  <div class="card-body">
                    <h3>{{ medical_reg.authority_name }}</h3>
                    <h3 class="mb-3">
                      Validity year :
                      <b class="text-dark">{{
                        medical_reg.expired_date
                      }}</b>
                    </h3>
                    <h3>
                      Code : <b>{{ medical_reg.code }}</b>
                    </h3>
                    <div v-if="medical_reg.screenshot">
                      <p class="mb-0">Doctor BMDC screenshot</p>
                      <img
                        :src="doctorSignature(medical_reg.screenshot)"
                        class="img-fluid"
                        alt="..."
                        @click="showImage(medical_reg.screenshot)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>Not set yet</div>

            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MedicalRegistration",
  props: {
    medical_registration: {},
    user: {},
  },
  data() {
    return {
      form: {
        bmdc_no: "",
        reg_year: "",
        expired_date: "",
        registration_authority_id: "",
      },
      image_preview: null,
      screenshot_image_preview: null,
      exist_image: "",
      exist_screenshot: "",
      medical_registration_authority: [],
      medical_registration_authority_is_required: "",
    };
  },
  mounted() {
    this.getMedicalRegistration();
  },
  methods: {
    doctorSignature(img) {
      if (img) {
        return `${process.env.VUE_APP_URL}/${img}`;
      }
    },
    addBMDC(){
      this.$bvModal.show("editBmdc");
    },
    getMedicalRegistration() {
      axios
        .get(
          `${process.env.VUE_APP_URL}/api/v1/medical-registration-authority/list`
        )
        .then((response) => {
          this.medical_registration_authority = response.data.data;
        });
    },
    userPhoto(img) {
      if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return "../../assets/img/blank.png";
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.image_preview = URL.createObjectURL(file);
    },
    onScreenChange(e) {
      const file = e.target.files[0];
      this.screenshot_image_preview = URL.createObjectURL(file);
    },
    editBmdc() {
      // this.form = this.medical_registration[0];
      this.form.bmdc_no = this.medical_registration[0].code;
      this.form.reg_year = this.medical_registration[0].registration_year;
      this.form.expired_date = this.medical_registration[0].expired_date;
      this.exist_image = this.medical_registration[0].image;
      this.exist_screenshot = this.medical_registration[0].screenshot;
      // this.form.registration_authority_id = this.medical_registration[0].registration_authority_id;
      this.medical_registration_authority.map((item) => {
        if (item.name == this.medical_registration[0].authority_name) {
          return (this.form.registration_authority_id = item.id);
        }
      });
      this.$bvModal.show("editBmdc");
    },
    showImage(img) {
      const h = this.$createElement;

      const messageVNode = h("div", { class: ["body_image"] }, [
        h("b-img", {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
          },
        }),
      ]);

      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        // title: [titleVNode],
        title: " ",
        size: "xl",
        hideHeaderClose: false,
        footerClass: "d-none",
        bodyClass: "p-0 m-0",
      });
    },
    resetModal() {
      this.form.bmdc_no = "";
      this.form.reg_year = "";
      // this.form.registration_authority_id = this.medical_registration[0].registration_authority_id;
      this.form.registration_authority_id = "";
      this.medical_registration_authority_is_required = "";
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.editBmdcNumber();
    },
    handleBMDC(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.editBmdcNumber();
    },
    handleBMDCScreenShot(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleBMDCScreenshotSubmit();
    },
    handleBMDCSubmit() {
      let form = new FormData();
      let cimage = this.$refs.certificate_image.files[0];
      if (cimage) {
        form.append("authority[0][image]", cimage);
      }
      axios
        .post(
          `${
            process.env.VUE_APP_URL
          }/api/v1/medical-registration-authority/set?user_id=${
            this.user.id
          }&platform=backoffice&api_token=${localStorage.getItem("api_token")}`,
          form
        )
        .then((response) => {
          if (response.data.status_code == 400) {
            return this.$snotify.error(response.data.message);
          }
          this.$bvModal.hide("editBmdc");
          this.$snotify.success("Successfully updated");
          this.$emit("call_medical_reg");
        });
    },
    handleBMDCScreenshotSubmit() {
      let form = new FormData();
      let cimage = this.$refs.screenshot_image.files[0];
      if (
        this.form.registration_authority_id == "" ||
        this.form.registration_authority_id == null
      ) {
        return (this.medical_registration_authority_is_required =
          "Medical registration authority is required");
      }
      form.append("authority[0][registration_code]", this.form.bmdc_no);
      form.append("authority[0][registration_year]", this.form.reg_year);
      form.append("authority[0][expired_date]", this.form.expired_date);
      form.append(
        "authority[0][registration_authority_id]",
        this.form.registration_authority_id
      );

      if (cimage) {
        form.append("authority[0][screenshot]", cimage);
      }
      axios
        .post(
          `${
            process.env.VUE_APP_URL
          }/api/v1/medical-registration-authority/set?user_id=${
            this.user.id
          }&platform=backoffice&api_token=${localStorage.getItem("api_token")}`,
          form
        )
        .then((response) => {
          if (response.data.status_code == 400) {
            return this.$snotify.error(response.data.message);
          }
          this.$bvModal.hide("upload-bmdc");
          this.$snotify.success("Successfully updated");
          this.$emit("call_medical_reg");
        });
    },
    editBmdcNumber() {
      if (
        this.form.registration_authority_id == "" ||
        this.form.registration_authority_id == null
      ) {
        return (this.medical_registration_authority_is_required =
          "Medical registration authority is required");
      }
      let cimage = this.$refs.certificate_image.files[0];
      let screenshot_image = this.$refs.screenshot_image.files[0];

      let form = new FormData();
      form.append("authority[0][registration_code]", this.form.bmdc_no);
      form.append("authority[0][registration_year]", this.form.reg_year);
      form.append("authority[0][expired_date]", this.form.expired_date);
      form.append(
        "authority[0][registration_authority_id]",
        this.form.registration_authority_id
      );
      if (cimage) {
        form.append("authority[0][image]", cimage);
      }
      if (screenshot_image) {
        form.append("authority[0][screenshot]", screenshot_image);
      }
      form.append("is_walk_through", "1");
      axios
        .post(
          `${
            process.env.VUE_APP_URL
          }/api/v1/medical-registration-authority/set?user_id=${
            this.user.id
          }&platform=backoffice&api_token=${localStorage.getItem("api_token")}`,
          form
        )
        .then((response) => {
          if (response.data.status_code == 400) {
            return this.$snotify.error(response.data.message);
          }
          this.$bvModal.hide("editBmdc");
          this.$snotify.success("Successfully updated");
          this.$emit("call_medical_reg");
        });
    },
  },
};
</script>

<style scoped>
.card-img-left {
  /*width: 100%;*/
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.card-img {
  height: 300px;
}

.edit_btn {
  position: absolute;
  right: 0;
  top: 10px;
}

.modal-body img {
  height: 200px;
  width: 200px !important;
}

.body_image img {
  height: auto !important;
  width: -webkit-fill-available !important;
}

.preview {
  padding: 10px;
  position: relative;
}

.preview i {
  color: white;
  font-size: 35px;
  transform: translate(50px, 130px);
}

.preview-img {
  border-radius: 100%;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.7);
}

.preview-img-nid {
  /*border-radius: 100%;*/
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.7);
}

.browse-button {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  position: absolute; /* Tweak the position property if the element seems to be unfit */
  top: 10px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, transparent, black);
  opacity: 0;
  transition: 0.3s ease;
}

.browse-button-nid {
  width: 200px;
  height: 200px;
  /*border-radius: 100%;*/
  position: absolute; /* Tweak the position property if the element seems to be unfit */
  top: 10px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(180deg, transparent, black);
  opacity: 0;
  transition: 0.3s ease;
}

.browse-button:hover {
  opacity: 1;
}

.browse-button-nid:hover {
  opacity: 1;
}

.browse-input {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  transform: translate(-1px, -26px);
  opacity: 0;
}

.browse-input-nid {
  width: 200px;
  height: 200px;
  /*border-radius: 100%;*/
  transform: translate(-1px, -26px);
  opacity: 0;
}
</style>
