<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div v-if="institute_loading">Loading...</div>
            <b-form-group v-else>
              <label for="">Select organization</label>
              <v-select
                  :options="institutes"
                  :reduce="institutes => institutes.id"
                  v-model="institute_id"
                  @input="getAllApi"
                  label="name">
              </v-select>
            </b-form-group>
          </div>
        </div>


      </div>
    </div>
    <loader-component v-if="loading"/>
    <div v-else>
      <div>
        <div class="card mt-3 mb-3">
          <div class="card-body">
            <div class="row  justify-content-between">
              <div class="col-md-2">
                <div>
                  <label for="">Select filter</label>
                  <select name="" v-model="filter_data" class="form-control" id="" @change="getAllApi()">
                    <option value="weekly">Weekly</option>
                    <option value="today">Today</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="all">All</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label for="">From</label>
                <input type="date" v-model="start_date" class="form-control">
              </div>
              <div class="col-md-3">
                <label for="">To</label>
                <input type="date" v-model="end_date" class="form-control">
              </div>
              <div class="col-md-3 mt-8">
                <button @click="getAllApi" class="btn btn-info" type="button">Filter</button>
                <button @click="clear" class="btn btn-danger ml-3" type="button">Clear</button>
              </div>

            </div>
          </div>
        </div>

        <div v-if="institute_id" class="row justify-content-center">
          <div class="col-md-2">
            <div class="card-box tilebox-one">
              <router-link to="#">
                <div>
                  <div class="d-flex justify-content-between align-items-cente">
                    <h6 class="text-muted text-uppercase m-b-20">Total doctor</h6>
                  </div>
                  <div>
                    <h1>{{ counter.doctor_count }}</h1>
                  </div>

                </div>
              </router-link>
            </div>
          </div>
          <div class="col-md-2">
            <div class="card-box tilebox-one">
              <router-link to="#">
                <div>
                  <div class="d-flex justify-content-between align-items-cente">
                    <h6 class="text-muted text-uppercase m-b-20">Total patient</h6>
                  </div>
                  <div>
                    <h1>{{ counter.patient_count }}</h1>
                  </div>

                </div>
              </router-link>
            </div>
          </div>
          <div class="col-md-2">
            <div class="card-box tilebox-one">
              <router-link to="#">
                <div>
                  <div class="d-flex justify-content-between align-items-cente">
                    <h6 class="text-muted text-uppercase m-b-20">Total prescription</h6>
                  </div>
                  <div>
                    <h1>{{ counter.no_of_prescription }}</h1>
                  </div>

                </div>
              </router-link>
            </div>
          </div>
          <div class="col-md-2">
            <div class="card-box tilebox-one">
              <router-link to="#">
                <div>
                  <div class="d-flex justify-content-between align-items-cente">
                    <h6 class="text-muted text-uppercase m-b-20">Total Income</h6>
                  </div>
                  <div>
                    <h1>{{ counter.total_income }}</h1>
                  </div>

                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div v-if="institute_id" class="row my-3">
          <div class="col-md-12">

            <div class="card border-0">
              <div class="card-body">
                <b-tabs content-class="mt-3" justified>
                  <b-tab title="Doctors" active>
                    <div class="row">
                      <div class="col-md-12">
                        <b-table
                            striped hover responsive
                            :items="doctors"
                            :fields="doctorFields"
                            thead-class="bg-primary text-white"
                            show-empty
                            class="mt-5">

                          <template #cell(fullname)="row">
                            <div class="">
                              <b-media tag="li">
                                <template #aside>
                                  <b-img :src="userPhoto(row.item.image)" class="user_image" alt="placeholder"
                                         v-if="row.item.image"></b-img>
                                  <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>
                                </template>

                                <h5 class="mt-0 mb-1">
                                  <router-link target="_blank" :to="`/user/details/${row.item.id}`">{{
                                      row.item.fullname
                                    }}
                                  </router-link>
                                </h5>
                                <ul class="mb-0 user_info">
                                  <li>
                                    {{ row.item.phone_number }}
                                  </li>
                                </ul>
                              </b-media>
                            </div>
                          </template>

                          <template #cell(otp)="row">
                            <div class="is_online text-center">
                              <p v-if="row.item.otp===true"><i class="fas fa-check-circle text-success"></i></p>
                              <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                            </div>
                          </template>

                          <template #cell(confirmation)="row">
                            <div class="is_online text-center">
                              <p v-if="row.item.confirmation===true"><i class="fas fa-check-circle text-success"></i>
                              </p>
                              <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                            </div>
                          </template>

                          <template #cell(doctor_information)="row">
                            <p>{{
                                row.item.doctor_information ? row.item.doctor_information.no_of_prescription : '-'
                              }}</p>
                          </template>

                        </b-table>
                      </div>
                    </div>
                  </b-tab>
                  <!--   Patients -->
                  <b-tab title="Patients">
                    <div class="row">
                      <div class="col-md-12">
                        <b-table
                            striped hover responsive
                            :items="patients"
                            :fields="fields"
                            thead-class="bg-primary text-white"
                            show-empty
                            class="mt-5">

                          <template #cell(user_name)="row">
                            <div class="">
                              <b-media tag="li">
                                <template #aside>
                                  <b-img :src="userPhoto(row.item.user_image)" class="user_image" alt="placeholder"
                                         v-if="row.item.user_image"></b-img>
                                  <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>
                                </template>

                                <h5 class="mt-0 mb-1">
                                  <router-link target="_blank" :to="`/user/details/${row.item.user_id}`">{{
                                      row.item.user_name
                                    }}
                                  </router-link>
                                </h5>
                                <ul class="mb-0 user_info">
                                  <li>
                                    {{ row.item.user_phone_number }}
                                  </li>
                                </ul>
                              </b-media>
                            </div>
                          </template>

                        </b-table>
                      </div>
                    </div>
                  </b-tab>
                  <!--   Prescription -->
                  <b-tab title="Prescription">
                    <div class="row">
                      <div class="col-md-12">
                        <b-table
                            striped hover responsive
                            :items="prescriptions"
                            :fields="prscriptionFileds"
                            thead-class="bg-primary text-white"
                            show-empty
                            class="mt-5">

                          <template #cell(patient_id)="row">
                            <div class="">
                              <b-media tag="li">
                                <template #aside>
                                  <b-img :src="userPhoto(row.item.patient_image)" class="user_image" alt="placeholder"
                                         v-if="row.item.patient_image"></b-img>
                                  <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>
                                </template>

                                <h5 class="mt-0 mb-1">
                                  <router-link target="_blank" :to="`/user/details/${row.item.patient_id}`">{{
                                      row.item.patient_name
                                    }}
                                  </router-link>
                                </h5>
                              </b-media>
                            </div>
                          </template>

                          <template #cell(prescription_identifier)="row">

                            <a target="_black" :href="prescriptionUrl(row.item.url)"><span
                                class="ml-2 text-light-primary"><i
                                class="fas fa-external-link-alt"></i></span>{{ row.item.prescription_identifier }}</a>
                          </template>

                          <template #cell(is_paid)="row">
                            <b-badge variant="success" v-if="row.item.is_paid == 1">Paid</b-badge>
                            <b-badge variant="danger" v-else>Unpaid</b-badge>
                          </template>

                          <template #cell(service_details)="row">
                            <p class="mb-0">{{ row.item.service_details.service_type }}</p>
                            <p class="mb-0">{{ row.item.service_details.service_fee }}</p>
                          </template>

                        </b-table>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {OrganizationMixin} from "../../mixins/OrganizationMixin";

export default {
  name: "Organization",
  mixins: [OrganizationMixin],

}
</script>


<style scoped>
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #4591d1;
  border-color: #E4E6EF #E4E6EF #ffffff;
}

.card-box {
  padding: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
}

.tilebox-one i {
  font-size: 30px;
}

.tilebox-two i {
  font-size: 48px;
  opacity: 0.2;
  margin-top: 20px;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.box-item {
  background: #f2f5f8;
  padding: 7px;
  border-radius: 7px;
  margin: 0px 5px;
}

.card-box {
  /*overflow: scroll;*/
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.user_icon i {
  font-size: 10px;
}

.user_info li {

  margin: 5px 0px;
}

.user_info li i {
  padding: 4px 6px;
  font-size: 10px;
  color: #44A0FF;
  border-radius: 50%;
  background-color: rgba(230, 242, 255, 0.9);
  margin-right: 5px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}

.pdf-viewer {
  display: none;
  position: absolute;
  height: 500px;
  width: 500px;
  z-index: 9999;

}

.viewer a:hover + .pdf-viewer {
  display: block;
}

.snotify-confirm {
  background-color: red !important;
}
</style>