import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const SubscriptionPackageMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
                "Price": {
                    field: "price",
                },
                "Slug": {
                    field: "slug",
                },
                "End date": {
                    field: "end_date",
                },
                "Status": {
                    field: "status",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Slug',
                    key: 'slug',
                    sortable: true,
                },
                {
                    label: 'Price',
                    key: 'price',
                    sortable: true,
                },
                {
                    label: 'Offer',
                    key: 'offer_id',
                    sortable: true,
                },
                {
                    label: 'Discount',
                    key: 'discount',
                    sortable: true,
                },
                {
                    label: 'Expiry duration',
                    key: 'expiry_duration',
                    sortable: true,
                },
                {
                    label: 'Package duration type',
                    key: 'package_duration_type',
                    sortable: true,
                },

                {
                    label: 'Status',
                    key: 'status',
                    sortable: true,
                },
                {
                    label: 'Created date',
                    key: 'created_at',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            form: {
                title: '',
                price: '',
                description: '',
                discount_type: '',
                discount: '',
                offer_id: '',
                expiry_duration: '',
                package_duration_type: '',
                status: '',
            },
            edit: false,
            edit_id: '',
            loading: true,
            discount_type: [
                {
                    text: 'percentage',
                    value: 'percentage'
                },
                {
                    'text': 'cash',
                    'value': 'cash'
                }
            ],
            offers: [],
            package_duration_type: [
                {
                    text: 'Daily',
                    value: 'Daily'
                },
                {
                    text: 'Monthly',
                    value: 'Monthly'
                },
                {
                    text: 'Yearly',
                    value: 'Yearly'
                },
            ],
            status: [
                {
                    text: 'Active',
                    value: '1'
                },
                {
                    text: 'Inactive',
                    value: '0'
                }
            ],
            features: [],
            feature_all: [],
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Subscription package", route: "/subscription-package"},
        ]);
    },
    created() {
        this.getMedicineClass();
        this.getOffer();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Subscription package',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getMedicineClass() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/subscription/package/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;

                    }
                })
        },
        getOffer() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/subscription/offer/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.offers = response.data.data;
                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.titleState = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if (this.edit) {
                return this.updateData();
            }
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();

            form.append("title", this.form.title);
            form.append("price", this.form.price);
            form.append("description", this.form.description);
            form.append("discount_type", this.form.discount_type);
            form.append("discount", this.form.discount);
            form.append("offer_id", this.form.offer_id);
            form.append("expiry_duration", this.form.expiry_duration);
            form.append("package_duration_type", this.form.package_duration_type);
            form.append("status", this.form.status);

            // Features
            form.append('features[]', this.features)
            form.append("feature_title[]", this.features.title);
            form.append("feature_description[]", this.features.description);
            form.append("feature_target_count[]", this.features.target_count);
            form.append("feature_per_target_cost[]", this.features.per_target_cost);
            form.append("feature_per_status[]", this.features.status);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/subscription/package/store?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicineClass();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        updateData() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);
            form.append("price", this.form.price);
            form.append("description", this.form.description);
            form.append("discount_type", this.form.discount_type);
            form.append("discount", this.form.discount);
            form.append("offer_id", this.form.offer_id);
            form.append("expiry_duration", this.form.expiry_duration);
            form.append("package_duration_type", this.form.package_duration_type);
            form.append("status", this.form.status);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/subscription/package/update/${this.edit_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicineClass();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully updated');
                    })
            })
        },

        editAction(row) {
            this.edit = true;
            this.$bvModal.show('modal-1');
            this.form = row.item
            this.edit_id = row.item.id;
        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/medicine/class/remove/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getMedicineClass();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        AddItem() {
            this.features.push({
                title: null,
                target_count: null,
                per_target_cost: null,
                description: null,
                status: null,
            });
        },
        removeItem(item) {
            let index = this.features.findIndex(
                u => u === item
            );
            this.features.splice(index, 1);
        },
    }
}