import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const ProductUsersMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Fullname": {
                    field: "fullname",
                },
                "Phone number": {
                    field: "phone_number",
                },
                "Product": {
                    field: "product",
                    callback: (product) => {
                        return product.title
                    },
                },
                "Confirmation status": {
                    field: "confirmation_status",
                    callback: (value) => {
                        return value == 1 ? 'Active' : 'Inactive'
                    },
                },

            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'user',
                    label: 'User',
                    sortable: true
                },
                {
                    key: 'confirmation_status',
                    label: 'Confirmation Status',
                    sortable: true
                },
                {
                    key: 'product',
                    label: 'Product',
                    sortable: true
                },
                {
                    key: 'user_product_updated_at',
                    label: 'Last Modified At',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Actions'
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                name: '',
            },
            filter: null,
            filterOn: [],
            title: null,
            details: '',
            pro: {
                product_id: '',
                user_id: '',
                comment: '',
            },
            loading: true,
            row_id: '',
            is_edit: false,
            products: [],
            users: [],
            update_user_id: '',
            update_product_id: '',

        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Manage product user", route: "/product/users"},
        ]);


    },
    created() {
        localStorage.setItem('tabIndex', 2)
        this.$root.$emit('call-active-menu', 2);
        this.productUsers();
    },
    metaInfo: {
        title: 'Manage product | Orko Health Ltd',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                address: '',
                contact_number: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        productUsers() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/product/user/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                        this.loading = false;
                    }
                })
        },

        getRolesData() {
            // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
            axios.get(`${process.env.VUE_APP_URL}/api/v1/product/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.products = response.data.data
                    }
                    // this.loading = false;
                })
        },

        getUser() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.users = response.data.data
                        this.loading = false;
                    }

                })
        },

        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../../assets/img/blank.png";
            }
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            // this.form.comment = valid
            return valid
        },

        resetModal() {
            this.title = null;
            this.details = null;
            this.pro = {};
            this.update_user_id = '';
        },

        handleOkEdit(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.is_edit) {
                return this.updateProduct();
            }
            this.handleSubmitEdit()
        },

        handleSubmitEdit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();

            form.append("title", this.form.title);
            if (this.form.description) {
                form.append("description", this.form.description);
            }
            if (this.form.price) {
                form.append("price", this.form.price);
            }

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/product/store?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {

                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.products();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },

        editAction(row) {
            this.getRolesData();
            this.$bvModal.show("modal-1");
            this.pro = row;
            this.is_edit = true;
            this.row_id = row.id;
            this.update_user_id = row.id;
            this.update_product_id = row.product ? row.product.id : null;
        },

        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v2/user/${row.id}/confirm-status/update?api_token=${localStorage.getItem('api_token')}`, {
                                        'confirmation_status': 1
                                    })
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getSpeialitiesist();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },

        changeStatus(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                row.confirmation_status == 1 ? 'You want to  Inactive user' : 'You want to  Active user',
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v2/user/${row.id}/confirm-status/update?api_token=${localStorage.getItem('api_token')}`, {
                                        'confirmation_status': row.confirmation_status == 1 ? 0 : 1
                                    })
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully updated",
                                            "Success"
                                        );
                                        this.productUsers();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },

        updateProduct() {

            if (!this.checkFormValidity()) {
                return
            }

            let form = new FormData();
            form.append('action_type', 'product');
            form.append('module_type', 'product');
            form.append("product_id", this.pro.product_id);
            form.append("comment", this.pro.comment);


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/product/user/update/${this.update_user_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.productUsers();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully updated');
                    })
            })

        },

        assignPermissions(row) {
            console.log('row', row)
        }

    }
}