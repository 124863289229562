<template>
  <div>
    <div
        class="subheader subheader-transparent"
        v-bind:class="subheaderClasses"
        id="kt_subheader">

      <div
          class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
          v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
      >
        <div class="d-flex align-items-baseline mr-5">
          <!--begin::Page Title-->
          <h2 class="subheader-title text-white font-weight-bold my-2 mr-3">
            {{ title }}
          </h2>

          <!--end::Page Title-->
          <!--begin::Breadcrumb-->
          <ul
              class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold my-2 p-0"
          >
            <template v-for="(breadcrumb, i) in breadcrumbs">
              <li :key="`${i}-${breadcrumb.id}`" class="breadcrumb-item">
                <router-link
                    :key="`${i}-${breadcrumb.id}`"
                    v-if="breadcrumb.route"
                    :to="breadcrumb.route"
                    class="text-white"
                >
                  {{ breadcrumb.title }}
                </router-link>
                <span
                    class="text-muted"
                    :key="`${i}-${breadcrumb.id}`"
                    v-if="!breadcrumb.route"
                >
                {{ breadcrumb.title }}


              </span>
              </li>
            </template>
          </ul>
          <div class="mx-10">
            <router-link to="/menu/short-cut" class="btn btn-default btn-pill text-dark">
              Shortcut
            </router-link>
          </div>
          <!--end::Breadcrumb-->
        </div>
      </div>

      <!--      <div class="pr-10 ">-->
      <!--        <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" class="" no-caret>-->
      <!--          <template #button-content>-->
      <!--            <i class="far fa-bell"></i> <span class="notification">{{ notifications.length }}</span><span-->
      <!--              class="sr-only">Search</span>-->
      <!--          </template>-->
      <!--          <div v-if="notifications.length">-->
      <!--            <b-dropdown-item href="#" v-for="(notification, index) in notifications" :key="index"-->
      <!--                             class="custom-notification-div">-->
      <!--              <span class="work-speacing" v-html="notification.data.data"></span>-->
      <!--            </b-dropdown-item>-->
      <!--          </div>-->
      <!--        </b-dropdown>-->
      <!--      </div>-->

      <div class="input-group mb-3">
        <form @submit.prevent="userSearch" class="w-100 d-flex align-items-center mt-3 mr-10">
          <input type="text" class="form-control border-redius-0" v-model="search_text"
                 placeholder="Search Users: Exp: name, phone number" aria-label="Recipient's username"
                 aria-describedby="button-addon2">
          <button class="btn btn-info border-redius-0" type="submit" id="button-addon2">Search</button>
        </form>
      </div>


      <div class="mr-20">
        <b-dropdown size="lg" class="m-2 custom-dropdown rounded-circle btn-circle" toggle-class="rounded-circle"
                    id="dropdown-right" right no-caret>
          <template #button-content class="custom-dropdown">
          <span class="name">

            {{ getSingleLettter(currentUser.fullname) }}
          </span>
          </template>
          <b-dropdown-item-button @click="onLogout" class="custom-dropdown-item">
            <div class="d-flex align-items-center justify-content-center">
              <span class="pr-5 pt-2"><i class="fas fa-sign-out-alt"></i></span> <span>Logout</span>
            </div>
          </b-dropdown-item-button>
        </b-dropdown>

      </div>

    </div>
    <div class="m-8">

    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import axios from 'axios';
import {getSingleLettter} from "../../../healper/nameFormating";

export default {
  name: "KTSubheader",
  data() {
    return {
      notifications: [],
      nameMenufulate: '',
      search_text: '',
    }
  },
  props: {
    breadcrumbs: Array,
    title: String
  },
  mounted() {
    this.getUserPermissions();
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    currentUser: {
      get() {
        return this.$store.state.auth.userData;
      },
    },

    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }

        if (this.layoutConfig("subheader.clear")) {
          classes.push("mb-0");
        }
      }
      return classes.join(" ");
    }
  },
  methods: {
    ...mapActions('auth', ['getUserPermissions']),
    getSingleLettter,
    onLogout() {
      this.$router.push('/login');
      localStorage.removeItem('api_token');
    },

    fullnameMenupulate() {
      var str = this.currentUser.fullname;
      var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      return matches.join(''); // JSON

    },

    getNotification() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/bo-notification/list?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            if (res.data.status_code == 400) {
              this.notifications = res.data.data;
            } else {
              this.notifications = res.data.data;
            }

          })
    },
    userSearch() {

      this.$root.$emit('search_text', this.search_text);
      const path = `/user/filter?search_text=${this.search_text}`;

      if (this.$route.path !== path)
        this.$router.push(path)
      else
          // this.$root.$emit('router', this.search_text);
        this.$router.push({path: '/user/filter', query: {search_text: this.search_text}})

    },
  }
};
</script>
<style scoped>
.dropdown-menu {
  left: -62px !important;
}

.notification {
  background: red;
  padding: 3px 8px;
  border-radius: 7px;
  position: relative;
  top: -13px;
  left: -6px;
  font-weight: 500;
}

.custom-notification-div {
  width: 200px !important;
  border-bottom: 1px solid #DDDDDD;
  /*overflow: hidden;*/
}

.custom-notification-div .dropdown-item {
  white-space: inherit !important;
}

.work-speacing {
  white-space: normal !important;
}

.custom-dropdown button {
  border-radius: 61px !important;
  padding: 9px 6px !important;
  font-weight: bold;
}

.custom-dropdown-item {
  top: 16px;
  left: -44px;
}

.dropdown-toggle {
  border-radius: 70px;
}

/deep/ .btn.btn-secondary {
  background: #fcd603;
  font-size: 25px;
  font-weight: bold;
  padding: 3px 9px;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  overflow: hidden;
}

.border-redius-0 {
  border-radius: 0px;
}
</style>
