<template>
  <div>
    <div class="card" v-if="loading">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center bg-light-success p-5">
          <h3 class="mb-0">Roles lists</h3>
          <b-button v-b-modal.modal variant="primary"><span class="pr-4"><i class="fas fa-user-plus"></i></span> Add new
            roles
          </b-button>
        </div>
        <b-modal
            id="modal"
            title="Add roles"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
                label="Name"
                label-for="name-input"
                invalid-feedback="Name is required"
                :state="validateState('name')"
            >
              <b-form-input
                  id="name-input"
                  v-model="form.name"
                  :state="validateState('name')"
                  required
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>

        <b-input-group class="mt-5" size="md">
          <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search id, name, guard name"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-table
            striped hover
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            class="mt-5"
            thead-class="bg-primary text-white"
            @filtered="onFiltered">

          <template #cell(actions)="row">

            <b-button size="sm" class="mr-1" variant="primary" @click="showRolesDetails(row.item.id)">
              <span class="pr-4"><i class="fas fa-eye"></i></span> show
            </b-button>

            <b-button size="sm" class="mr-1" variant="primary" @click="edit(row.item)">
               Edit
            </b-button>

          </template>
        </b-table>

        <b-pagination
            v-model="currentPage"
            :total-rows="items.length"
            :per-page="perPage"
            size="lg"
            class="my-0"
        ></b-pagination>

      </div>
    </div>
    <div v-else>
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>

import {RoleMixin} from "../../mixins/RoleMixin";
import {validationMixin} from "vuelidate";

export default {
  name: "Roles",
  mixins: [RoleMixin, validationMixin],
  title: 'Manage role',
}
</script>

<style scoped>

</style>