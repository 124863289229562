import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const UnverifiedDoctorListMixin = {

    data() {

        return {
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            localActive: false,
            nameState: null,
            emailState: null,
            users: [],
            items: [],
            fields: [
                {
                    key: 'bulk_trending',
                    label: '',
                },
                {
                    key: 'image',
                    label: 'Image',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'score',
                    label: 'Score',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'phone_number',
                    label: 'Phone number',
                    sortable: true
                },
                {
                    key: 'confirmation',
                    label: 'Is confirmed?',
                    sortable: true,
                },
                {
                    key: 'otp',
                    label: 'Is otp verified?',
                    sortable: true,
                },

                {
                    key: 'registration_date',
                    label: 'Reg date',
                    sortable: true,
                },

            ],
            filter: null,
            filterOn: [],
            filters: {
                id: '',
                name: '',
                username: '',
                phone_number: '',
                confirmation: '',
                otp: '',
                registration_date: '',
                slug: '',
            },
            startDate: null,
            endDate: null,
            show: true,
            base_url: process.env.VUE_APP_API_URL,

            user_name: '',
            user_phone_number: '',
            user_image: null,
            doctor_id: '',
            total_doctor: 0,
            start_date: null,
            end_date: null,
        }
    },


    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Unverified Doctor list", route: "/doctor-users"},
        ]);
        this.getUser();
        this.userPhoto();
    },

    created() {
        localStorage.setItem('tabIndex', 1)
        this.$root.$emit('call-active-menu', 1);
    },

    methods: {
        getUser() {
            this.loading = true
            this.currentPage = 1;
            const url = `${process.env.VUE_APP_URL}/api/v1/user/list/not-verified/has-bmdc?api_token=${localStorage.getItem('api_token')}&user_type=doctor&platform=backoffice${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`
            axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.total_doctor = response.data.total_count;

                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },


        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            // this.currentPage = 1
        },

        userDetails(id) {
            this.$router.push(`/user/details/${id}`);
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },

        sendInfo(item) {
            this.user_id = item.id
        },


        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return '../../../public/media/users/blank.png'
            }
        },

        dateRangeClear() {
            this.startDate = '';
            this.endDate = '';
            this.start_date = null;
            this.end_date = null;
        },
        showImage(img) {
            const h = this.$createElement
            // More complex structure
            const messageVNode = h('div', {class: ['foobar']}, [
                h('b-img', {
                    props: {
                        src: `${process.env.VUE_APP_API_URL}/${img}`,
                        thumbnail: true,
                        center: false,
                    }
                })
            ])
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                buttonSize: 'sm',
                centered: true, size: 'lg'
            })
        },

        onRowSelected(items) {
            this.selected = items
        },

    },

    computed: {

        computedFields() {
            if (this.has_chamber == 0)
                return this.fields.filter(field => !field.has_chamber);
            else
                return this.fields;
        },
        rows() {
            return this.users.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },

        filtered() {
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.registration_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }

                if (this.trendy) {
                    return item.is_trending == 1;
                }

                if (this.qc) {

                    return item.available_qc == 1
                }

                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                phone_number: '',
                confirmation: '',
                otp: '',
                registration_date: '',
                slug: '',
            }]
        },

        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.registration_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        },

    },
}
