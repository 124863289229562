<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <div class="mx-2">
                <b-button @click="dateRangeClear" variant="danger">Clear</b-button>
              </div>
            </div>
            <!--            Add new doctor modal start   -->

            <!--  Add new doctor modal end   -->
            <div>
              <!-- Filter -->
              <div class="row  justify-content-between">
                <div class="col-md-1 mt-8">
                  <div class="">
                    <b-form-group
                        class="mb-0">
                      <b-form-select
                          id="per-page-select"
                          @input="getUser"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-md-3 ">
                  <label for="">From</label>
                  <input type="date" v-model="start_date" class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="">To</label>
                  <input type="date" v-model="end_date" class="form-control">
                </div>
                <div class="col-md-1 mt-8">
                  <button type="button" class="btn btn-info" @click="getUser">Filter</button>
                </div>

              </div>
              <!-- Table -->
              <div class="row">
                <div class="col-12">

                  <b-table
                      striped hover responsive
                      :items="items.length ? filtered : items"
                      :fields="computedFields"
                      :current-page="currentPage"
                      :per-page="0"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      @filtered="onFiltered"
                      :filter-included-fields="filterOn"
                      thead-class="bg-primary text-white"
                      class="mt-5"
                      id="doctorList"
                      ref="selectableTable"
                      show-empty>

                    <template #cell(image)="row">
                      <div class="is_online">
                        <img :src="userPhoto(row.item.image)" alt="image"
                             class="user_image" v-if="row.item.image" @click="showImage(row.item.image)"/>
                        <img src="../../../assets/img/blank.png" alt="" class="user_image" v-else>
                        <i :class="`${row.item.is_online == '1' ? 'text-green ' : 'text-danger'} fas fa-circle is_online_icon`"></i>
                      </div>
                    </template>

                    <template #cell(otp)="row">
                      <div class="is_online text-center">
                        <p v-if="row.item.otp===true"><i class="fas fa-check-circle text-success"></i></p>
                        <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                      </div>
                    </template>

                    <template #cell(confirmation)="row">
                      <div class="is_online text-center">
                        <p v-if="row.item.confirmation===true"><i class="fas fa-check-circle text-success"></i></p>
                        <p v-else><i class="fas fa-times-circle text-danger"></i></p>
                      </div>
                    </template>

                    <template #cell(registration_date)="row">
                      <p>{{ row.item.registration_date }}</p>
                    </template>

                    <template #cell(email)="row">
                      <span> {{ row.item.email ? row.item.email : 'n/a' }}</span>
                    </template>

                    <template #cell(score)="row">
                      <b-badge :variant="`${row.item.score > 0 ? 'success' : 'warning'}`">{{ row.item.score ? row.item.score : '0' }}</b-badge>
                    </template>



                    <template #cell(name)="row">
                      <div v-if="row.item.parent_id">
                        <user-parent-info :parent_id="row.item.parent_id" :parent_name="row.item.parent"/>
                      </div>
                      <router-link :to="`/user/details/${row.item.id}`" class="font-weight-bold"> {{
                          row.item.name
                        }} {{ row.item.user_sub_type ? '(' + row.item.user_sub_type + ')' : '' }}
                      </router-link>
                      <br>
                      <span class="text-muted">{{ row.item.nature_of_service}}</span>
                      <div>
                        <b-badge>{{ row.item.id }}</b-badge>
                      </div>
                    </template>


                    <template #cell(username)="row">
                      <span> {{ row.item.username }}</span>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  View points  -->


    <!--    Reset password modal -->


    <!--    Call history -->

    <vue-snotify></vue-snotify>

    <loader-component v-if="loading"/>

  </div>
</template>

<script>
import {UnverifiedDoctorListMixin} from "../../../mixins/UnverifiedDoctorListMixin";

export default {
  name: "UnverifiedDoctor",
  mixins: [UnverifiedDoctorListMixin],

  title: 'Unverified Doctor',
}
</script>

<style scoped>
.font-size-12 {
  font-size: 12px;
}
.floating_btn_for_trending {
  position: absolute;
  bottom: 30px;
  right: 55px;
}

.trendy-icon {
  font-size: 24px;
  text-align: center;
}

.qc-no {
  border: 2px solid red;
  border-radius: 100%;
  padding: 3px 9px;
  font-size: 18px;
  font-weight: 500;
}

.qc-yes {
  border: 2px solid green;
  border-radius: 100%;
  padding: 3px 10px;
  font-size: 18px;
  font-weight: 500;
}

.active {
  font-weight: bold;
}
</style>
