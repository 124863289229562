<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <h2>Orko specialities </h2>
          <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i>
            Add Orko specialities
          </b-button>
          <b-modal
              id="modal-1"
              size="lg"
              title="Creata a new orko Specialities"
              @show="resetModal"
              @hidden="resetModal"
              scrollable
              @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

              <b-form-group>
                <label for="">Parent speciality</label>
                <v-select
                    :options="items"
                    :reduce="items => items.id"
                    v-model="form.parent_id"
                    label="name">
                </v-select>
              </b-form-group>

              <b-form-group
                  label-for="name-input"
                  invalid-feedback="Name is required"
                  :state="nameState">
                <label for="">Name <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.name"
                    :state="nameState"
                    required/>
              </b-form-group>

              <b-form-group
                  label="Description">
                <b-form-textarea
                    id="textarea"
                    v-model="form.description"
                    placeholder="Enter Details"
                    rows="3"
                    class="mt-3"
                ></b-form-textarea>
              </b-form-group>

            </form>
          </b-modal>
        </div>


        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>

              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Specialities"
                  name="Specialities">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>


            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                class="mt-3">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <!--              <template #cell(parent_speciality_name)="data">-->
              <!--                {{ data.item }} d-->
              <!--              </template>-->

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions' "></p>
                  <input v-else v-model="filters[field.key]" :placeholder="field.label" class="form-control">
                </td>
              </template>


              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">

                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>

                      <div>
                        <b-dropdown-item href="#" @click="editAction(row.item)">
                          <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                        </b-dropdown-item>
                        <b-dropdown-item href="#" @click="deleteAction(row.item)">
                          <span class="pr-4"><i class="fas fa-trash"></i></span>Delete
                        </b-dropdown-item>
                      </div>

                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                size="lg"
                :total-rows="items.length"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {SpecialitiesMixins} from '../../mixins/SpecialitiesMixin';

export default {
  name: "Specialities",
  mixins: [SpecialitiesMixins],
}
</script>

<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>