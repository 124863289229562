<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-6">
            <div class="d-flex align-items-center justify-content-between">
              <div class="w-100">
                <label for="">From</label>
                <input type="date" v-model="start_date" class="form-control">
              </div>
              <div class="w-100 mx-2">
                <label for="">To</label>
                <input type="date" v-model="end_date" class="form-control">
              </div>
              <div class="mt-8">
                <button type="button" class="btn btn-info" @click="getData(tab)">Filter</button>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-8">
            <div>
              <div class="d-flex justify-content-end">
                <div class="mx-2">
                  <button
                      @click="exportReport()"
                      class="btn btn-primary btn-sm">
                    <i class="fas fa-file-excel"></i> Export Excel
                  </button>
                </div>
                <div class="mx-2">
                  <b-button variant="danger" class="btn-sm" @click="refresh">Clear</b-button>
                </div>
                <div>
                  <b-button variant="info" class="btn-sm" @click="getData(tab)"><i class="fas fa-redo-alt"></i>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs v-model="tabIndex">
              <b-tab title="Sales team" class="single_tab" @click="getData('sales')">
                <div class="row">
                  <div class="col-md-12 mt-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Call (Visit)</h5>
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.visits ? items.visits.total : '' }}</h3>
                        </div>

                        <div class="box-item bg-light-info w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3"> Total Sr. Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">
                            {{ items.visits ? items.visits.total_senior : '' }}</h3>
                        </div>

                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Jr. Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{
                              items.visits ? items.visits.total_junior : ''
                            }}</h3>
                        </div>

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{
                              items.visits ? items.visits.total_today : ''
                            }}</h3>
                        </div>


                        <div class="box-item bg-light-success w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Sr. Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">
                            {{ items.visits ? items.visits.total_senior_today : '' }}</h3>
                        </div>


                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Jr. Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">
                            {{ items.visits ? items.visits.total_junior_today : '' }}</h3>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Registration</h5>
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.registrations.total }}</h3>
                        </div>

                        <div class="box-item bg-light-info w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3"> Total Sr. Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.registrations.total_senior }}</h3>
                        </div>

                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Jr. Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.registrations.total_junior }}</h3>
                        </div>

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.registrations.total_today }}</h3>
                        </div>


                        <div class="box-item bg-light-success w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Sr. Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.registrations.total_senior_today }}</h3>
                        </div>


                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Jr. Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.registrations.total_junior_today }}</h3>
                        </div>

                      </div>
                    </div>
                    <!--                    </div>-->
                  </div>
                  <div class="col-md-12 mt-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Practice</h5>
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.practice.total }}</h3>
                        </div>

                        <div class="box-item bg-light-info w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3"> Total Sr. Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.practice.total_senior }}</h3>
                        </div>

                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Jr. Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.practice.total_junior }}</h3>
                        </div>

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.practice.total_today }}</h3>
                        </div>


                        <div class="box-item bg-light-success w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Sr. Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.practice.total_senior_today }}</h3>
                        </div>

                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Jr. Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.practice.total_junior_today }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Prescriptions</h5>
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total </p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total }}</h3>
                        </div>
                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">USHA</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total_usha }}</h3>
                        </div>
                        <div class="box-item bg-light-info w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">PROVA</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total_prova }}</h3>
                        </div>
                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">DUTI</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total_duti }}</h3>
                        </div>
                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total (Today) </p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total_today }}</h3>
                        </div>
                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">USHA (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{
                              items.product_prescriptions.total_usha_today
                            }}</h3>
                        </div>
                        <div class="box-item bg-light-success w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">PROVA (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{
                              items.product_prescriptions.total_prova_today
                            }}</h3>
                        </div>
                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">DUTI (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{
                              items.product_prescriptions.total_duti_today
                            }}</h3>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mt-5">
                    <!--                    <div class="card-box tilebox-one">-->
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Sales value</h5>
                      <!--            <span class="h1 float-right text-primary">{{ items.visits.total }}</span>-->
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Sales value</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.total_service_fee }}</h3>
                        </div>

                      </div>
                    </div>
                    <!--                    </div>-->
                  </div>
                </div>
              </b-tab>
              <b-tab class="single_tab" @click="getData('overAll')" title="Over All">
                <div class="row">

                  <div class="col-md-12 mt-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Registration</h5>
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.registrations.total }}</h3>
                        </div>

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.registrations.total_today }}</h3>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Practice</h5>
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.practice.total }}</h3>
                        </div>

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total Doctors (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.practice.total_today }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-5">
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Prescriptions</h5>
                      <!--            <span class="h1 float-right text-primary">{{ items.visits.total }}</span>-->
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total </p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total }}</h3>
                        </div>

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">USHA</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total_usha }}</h3>
                        </div>

                        <div class="box-item bg-light-info w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">PROVA</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total_prova }}</h3>
                        </div>

                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">DUTI</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total_duti }}</h3>
                        </div>

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Total (Today) </p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.product_prescriptions.total_today }}</h3>
                        </div>

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">USHA (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{
                              items.product_prescriptions.total_usha_today
                            }}</h3>
                        </div>


                        <div class="box-item bg-light-success w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">PROVA (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{
                              items.product_prescriptions.total_prova_today
                            }}</h3>
                        </div>


                        <div class="box-item bg-light-danger w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">DUTI (Today)</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{
                              items.product_prescriptions.total_duti_today
                            }}</h3>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mt-5">
                    <!--                    <div class="card-box tilebox-one">-->
                    <div class="d-flex justify-content-between align-items-center">
                      <h5 class="text-muted text-uppercase m-b-20 font-weight-bold">Sales value</h5>
                      <!--            <span class="h1 float-right text-primary">{{ items.visits.total }}</span>-->
                    </div>
                    <div class="mt-2">
                      <div class="d-flex text-center justify-content-between">

                        <div class="box-item bg-light-primary w-100">
                          <p class="p text-uppercase m-b-20 font-weight-bold mb-3">Sales value</p>
                          <h3 class="m-b-20" data-plugin="counterup">{{ items.total_service_fee }}</h3>
                        </div>

                      </div>
                    </div>
                    <!--                    </div>-->
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>


        </div>
      </div>
    </div>

    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import  {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {dateFormate} from "../../healper/dateTimeFormet";
import {ExportMixin} from "../../mixins/core/ExportMixin";

export default {
  name: "SalesDailyReports",
  mixins: [ExportMixin],
  data() {
    return {
      start_date: dateFormate().monthlay_first_day,
      end_date: dateFormate().monthlay_last_day,
      loading: false,
      items: {
        practice: {},
        product_prescriptions: {},
        registrations: {},
        visits: {},
      },
      tabIndex: 0,
      tab: 'sales'
    }
  },

  created() {
    this.getData(this.tab);
    localStorage.setItem('tabIndex', 14)
    this.$root.$emit('call-active-menu', 14);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales daily report", route: "/sales/daily/reports"},
    ]);
  },

  methods: {
    getData(type) {
      this.tab = type
      this.loading = true;
      let url = '';
      if (type === 'sales') {
        url = `/api/v2/administration/sales/daily/report?api_token=${localStorage.getItem('api_token')}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`;
      } else {
        url = `/api/v2/administration/doctors/daily/report?api_token=${localStorage.getItem('api_token')}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`;
      }

      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else if (response.data.status_code === 400) {
              this.$snotify.error('Something wrong');
              this.loading = false;
            } else {
              this.items = response.data.data
              this.loading = false;
            }
          })
    },

    exportReport() {
      const url = `/api/v2/administration/sales/daily/report/export?api_token=${localStorage.getItem('api_token')}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`;

      axios.get(url, {responseType: 'blob'})
          .then(response => {
            this.exportLink(response);
          })
    },
    refresh() {
      this.start_date = null;
      this.end_date = null;
      this.getData(this.tab);
    }
  }
}
</script>

<style scoped>
.card-box {
  padding: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
}

.tilebox-one i {
  font-size: 30px;
}

.tilebox-two i {
  font-size: 48px;
  opacity: 0.2;
  margin-top: 20px;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.box-item {
  background: #f2f5f8;
  padding: 7px;
  border-radius: 7px;
  margin: 0px 5px;
}

.card-box {
  overflow: scroll;
}

.nav .show > .nav .nav-link:hover {
  color: #fff !important;
}
</style>
