<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <div class="d-flex justify-content-end">
          <div class="mx-2">
            <b-button variant="primary" v-b-modal.modal-2><i class="fas fa-plus"></i> Add new Therapy
            </b-button>
          </div>
          <div>
            <download-excel
                :data="items"
                class="btn btn-primary"
                :fields="json_fields"
                worksheet="Diagnostic"
                name="Diagnostic">
              <i class="fas fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">

            <!--   modal -->
            <b-modal
                id="modal-2"
                size="lg"
                title="Create a new Therapy"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group>
                  <label for="">Parient Therapy</label>
                  <v-select
                      :options="items"
                      :reduce="items => items.id"
                      v-model="form.parent_id"
                      label="title">
                  </v-select>
                </b-form-group>

                <b-form-group
                    label="Title"
                    label-for="name-input"
                    invalid-feedback="Title is required">
                  <b-form-input
                      id="name-input"
                      v-model="form.title"
                      required/>
                </b-form-group>


                <b-form-group>
                  <label for="">Description</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.description"
                      placeholder="Enter Description"
                      rows="3"
                      class="mt-3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group label="Status">
                  <b-form-radio-group
                      id="radio-group-1"
                      v-model="form.is_active"
                      :options="status"
                      name="radio-options"
                  ></b-form-radio-group>
                </b-form-group>

              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(parent)="data">
                <div v-if="data.item.parent">
                  {{ data.item.parent ? data.item.parent.title : 'n/a'}}
                </div>
                <div v-else> n/a </div>
              </template>

              <template #cell(is_active)="data">
                <b-badge variant="success" v-if="data.item.is_active == 1">Active</b-badge>
                <b-badge variant="danger" v-else>In active</b-badge>
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>


              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <div>
                      <b-dropdown-item href="#" @click="editAction(row)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="deleteAction(row)">
                        <span class="pr-4"><i class="fas fa-trash"></i></span> Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {TherapyMixin} from "../../mixins/medicine/TherapyMixin";

export default {
  name: "ManageTherapy",
  mixins: [TherapyMixin]
}
</script>

<style scoped>

</style>