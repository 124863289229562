<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h2>{{ genericInfo.title }} <span class="text-muted" style="font-size: 15px;">details</span></h2>
        </div>

        <br>
        <div class="row mt-5">

          <div class="col-md-6">
            <div class="basic-info">
              <!--              <h4 class="mb-0">{{ agent_info.fullname }}</h4>-->
              <!--              <p class="text-muted mb-0">Username: <b>{{ agent_info.username }}</b></p>-->
              <!--              <p class="text-muted">Secondary phone no: <b>{{-->
              <!--                  agent_info.pharmacy_details.secondary_phone_no ? agent_info.pharmacy_details.secondary_phone_no : 'not set yet'-->
              <!--                }}</b></p>-->

              <h5><b> Medicine class : </b> {{ genericInfo.medicine_class ? genericInfo.medicine_class.title : 'n/a'}}</h5>
              <p class="mb-0 font-size-16"><b>Dosage:</b> {{ genericInfo.dosage }}</p>
              <p class="mb-0 font-size-16"><b>Dosage admin:</b> {{ genericInfo.dosage_n_admin }}</p>
              <p class="mb-0 font-size-16"><b>Dose adult:</b> {{ genericInfo.dose_adult }}</p>
              <p class="mb-0 font-size-16"><b>Dose Child:</b> {{ genericInfo.dose_child }}</p>
              <p class="mb-0 font-size-16"><b>Administation:</b> {{ genericInfo.administation }}</p>
              <p class="mb-0 font-size-16"><b>Compositions:</b> {{ genericInfo.compositions }}</p>
              <p class="mb-0 font-size-16"><b>Contraindications:</b> {{ genericInfo.contraindications }}</p>
              <p class="mb-0 font-size-16"><b>Definition or pharmacology:</b> {{ genericInfo.definition_or_pharmacology }}</p>
              <p class="mb-0 font-size-16"><b>Description:</b> {{ genericInfo.description }}</p>
              <p class="mb-0 font-size-16"><b>Duration of treatment:</b> {{ genericInfo.duration_of_treatment }}</p>
              <p class="mb-0 font-size-16"><b>Indications:</b> {{ genericInfo.indications }}</p>
              <p class="mb-0 font-size-16"><b>Mode of action:</b> {{ genericInfo.mode_of_action }}</p>
              <p class="mb-0 font-size-16"><b>Overdose effects:</b> {{ genericInfo.overdose_effects }}</p>
              <p class="mb-0 font-size-16"><b>Precautions:</b> {{ genericInfo.precautions }}</p>
              <p class="mb-0 font-size-16"><b>Pregnancy lactation:</b> {{ genericInfo.pregnancy_n_lactation }}</p>
              <p class="mb-0 font-size-16"><b>Reconstitutions:</b> {{ genericInfo.reconstitutions }}</p>
              <p class="mb-0 font-size-16"><b>Side effects:</b> {{ genericInfo.side_effects }}</p>
              <p class="mb-0 font-size-16"><b>Storage conditions:</b> {{ genericInfo.storage_conditions }}</p>
              <p class="mb-0 font-size-16"><b>Use in special population:</b> {{ genericInfo.use_in_special_population }}</p>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MedicineGenericsDetails",
  data(){
    return {
      genericInfo: {},
    }
  },
  mounted() {
    this.getGenericinfoDetails();
  },
  methods: {
    getGenericinfoDetails(){
      axios.get(`${process.env.VUE_APP_API_URL}/api/v1/medicine/generics/show/${this.$route.params.id}}`)
      .then(res => {
        this.genericInfo =  res.data.data;
      })
    }
  }
}
</script>

<style scoped>

</style>