// Bank
export const BACK_LIST = `${process.env.VUE_APP_URL}/api/v1/orkobank/list?limit=600&api_token=${localStorage.getItem('api_token')}`;
export const BANK_TYPE = `${process.env.VUE_APP_URL}/api/v1/bank/type/list?${localStorage.getItem('api_token')}`;

// Back account
// export const BANK_ACCOUNT_ADD = `${process.env.VUE_APP_URL}/api/v1/payment/user/account/add?api_token=${localStorage.getItem('api_token')}`
// Dashboard Api
export const USER_REPORT = `${process.env.VUE_APP_URL}/api/v2/administration/system/user/report?api_token=${localStorage.getItem('api_token')}`; // get

// User module
export const EXECUTIVE_USER = `${process.env.VUE_APP_URL}/api/v1/user/list?user_type=executive&limit=-1&platform=backoffice&api_token=${localStorage.getItem('api_token')}`; // get
export const CREATE_NEW_USER = `${process.env.VUE_APP_URL}/api/v1/register`; // post


// Payment
export const PATIENT_WITHDRAWAL_LIST = `${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/patient/list?api_token=${localStorage.getItem('api_token')}`;


// Medical Advice
export const MEDICAL_ADVICE_LIST = `${process.env.VUE_APP_URL}/api/v1/medical/advice/list?api_token=${localStorage.getItem('api_token')}`;
export const MEDICAL_ADVICE_STORE = `${process.env.VUE_APP_URL}/api/v1/medical/advice/store?api_token=${localStorage.getItem('api_token')}`;
export const MEDICAL_ADVICE_UPDATE = `${process.env.VUE_APP_URL}/api/v1/medical/advice/update`;

// Examinations
export const MEDICAL_EXAMINATION = `${process.env.VUE_APP_URL}/api/v2/medical-exams`;


// chief complaints
export const CHIEF_COMPLAINTS_LIST = `${process.env.VUE_APP_URL}/api/v1/medical-health-problem/list?api_token=${localStorage.getItem('api_token')}&limit=10000`;
export const CHIEF_COMPLAINTS_STORE = `${process.env.VUE_APP_URL}/api/v1/medical-health-problem/store?api_token=${localStorage.getItem('api_token')}`;
export const CHIEF_COMPLAINTS_UPDATE = `${process.env.VUE_APP_URL}/api/v1/medical-health-problem/update?api_token=${localStorage.getItem('api_token')}`;
export const CHIEF_COMPLAINTS_DELETE = `${process.env.VUE_APP_URL}/api/v1/medical-health-problem/delete`;
export const CHIEF_COMPLAINTS_MULTIPLE_DELETE = `${process.env.VUE_APP_URL}/api/v1/medical-health-problem/multiple/delete?api_token=${localStorage.getItem('api_token')}`;


// Appointment
export const APPOINTMENT_LIST = `${process.env.VUE_APP_URL}/api/v2/administrator/appointment/list?api_token=${localStorage.getItem('api_token')}`;

// Chat bot
export const CHAT_BOT_QUERY = `${process.env.VUE_APP_URL}/api/v2/chatbot/query/list?api_token=${localStorage.getItem('api_token')}`;

// Orko profession

export const ORKO_PROFESSION = `${process.env.VUE_APP_URL}/api/v2/doctor/profession/list?api_token=${localStorage.getItem('api_token')}`;
