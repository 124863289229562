import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import { BACK_LIST} from "../api/ApiUrl";

export const BankMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Bank title": {
                    field: "bank_title",
                },
                "Bank address": {
                    field: "address",
                },
                "Country": {
                    field: "country_name",
                },
                "Status": {
                    field: "status",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Bank Logo',
                    key: 'bank_logo',
                },
                {
                    label: 'Bank Title',
                    key: 'bank_title'
                },
                {
                    label: 'Bank Address',
                    key: 'address'
                },
                {
                    label: 'Country',
                    key: 'country_name'
                },
                {
                    label: 'Status',
                    key: 'status'
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                bank_logo: '',
                bank_title: '',
                country_name: '',
                address: '',
                status: '',
            },
            filter: null,
            filterOn: [],
            bank_name_state: null,
            bank_address_state: null,
            bankImageState: null,
            form: {
                bank_name: '',
                bank_address: '',
                status: '',
                file: ''
            }
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Bank", route: "/bank"},
        ]);
    },
    created() {
        this.getBankList();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Bank List',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                bank_logo: '',
                bank_title: '',
                country_name: '',
                address: '',
                status: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getBankList() {
            axios.get(BACK_LIST)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.bank_title = valid
            this.bank_name_state = valid
            this.bankImageState = valid
            this.bank_address_state = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.bank_name_state = null;
            this.bank_address_state = null;
            this.bankImageState = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let bank_logo = this.$refs.bank_logo.files[0];
            let form = new FormData();
            form.append("bank_title", this.form.bank_name);
            form.append("address", this.form.bank_address);
            form.append("relationship_column", this.form.relationship_column);
            form.append("status", '1');
            form.append("country_id", '18');
            form.append("bank_logo", bank_logo);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/orkobank/add?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getBankList();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
    }
}