<template>

  <div class="body" id="download"
       style="margin:0;padding:0;width: 100%; background-color: #FFFFFF;font-size: 12px; font-family: Raleway; min-height: 942px; ">

    <div class="header"
         style="background-color: #DDDDDD; color:#000; display: inline-block; width:100%; margin-bottom: 0px; min-height: 255px; ">
      <div class="row pheader" style="margin-top: 10px;  margin-bottom: 70px;">
        <div class="col-md-12 pheader-content-intro" style="margin-top: 50px; margin-left: 50px">
          <div class="column" style="float: left;flex: 50%;">
            <div
                style="margin-left:10px; margin-top: 5px; position: relative; display: inline-block; max-width: 250px; vertical-align: top;">
              <strong style="font-size: 16px;">Address</strong><br>
              <span style="word-wrap: break-word;">Orko Health Ltd., <br> YN Centre, House 6/A, Road 113, 2nd floor, Gulshan Avenue <br>Phone: +81 720 2212 <br>Email: ask@myorko.com</span>
            </div>
          </div>

          <div class="column" style="float: left;flex: 40%;margin-top: 40px">
            <h1 style="white-space: nowrap; white-space: nowrap; font-size: 20px; margin-left: 25%;">Transaction
              Statement</h1>
          </div>
          <div class="column"
               style="float:right; margin-right: 20px; display: inline-block; flex: 50%; padding-bottom: 7%;">
            <div
                style=" background-size:contain; background-repeat:no-repeat;">
              <img src="../../../assets/img/logo.svg" alt="" style="float: right; width: 140px; margin-right: 60px;">
            </div>
            <br>
            <span style="font-weight:bold; color: #F9A91A;">Connect. Care</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12 pheader-secondary" style="background-color: #F9A91A;
                display: inline-block; margin-top:-70px ;margin-bottom: 0px;
                width:100%; padding:0;min-height: 80px; border-radius: 35px 35px 0px 0px">
      <div style="margin-top: 15px;margin-bottom: 50px;">
        <div class="column" style="float: left;flex: 70%; width: 70%">
          <span style="margin-top: 55px; margin-left: 60px;"> <strong>Name: </strong>Dr. Iqbal Ahmed Tuhin</span>
          <span style="display: block;margin-left: 60px;">Phone number: 4095u43906</span>
        </div>
        <div class="column" style="float: right;flex: 30%;width: 30%">
          <span style="float: left; margin-left: 10%;"> Statement from: <b>40/40/4004</b> to <b> 40/40/4004</b> </span>
        </div>
      </div>
    </div>

    <div class="col-md-12 pheader-secondary" style=" margin-top:-30px ;background-color: #ffffff;
                    margin-left: 0px; margin-right:0px; margin-bottom: 0px; display: inline-block; width:100%;
                    padding:0px; border-radius: 30px 30px 0px 0px">
      <div style="margin-left: 100px; display: flex; margin-top: 20px;">
        <div class="column" style="float: left; margin-left: -3rem;">

          <table class="table table-bordered" style="width:100%">
            <thead style="">
            <tr>
              <th>Trxn date</th>
              <th style="width: 35rem;">Description</th>
              <th>Debit</th>
              <th>Credit</th>
              <th>Balance</th>
            </tr>
            </thead>
            <tbody>

            <tr>
              <td> 30/995/4404</td>
              <td> Lorem ipsum dolor sit amet.</td>
              <td>400</td>
              <td>500</td>
              <td>900</td>
            </tr>
            <tr>
              <td> 30/995/4404</td>
              <td> Lorem ipsum dolor sit amet.</td>
              <td>400</td>
              <td>500</td>
              <td>900</td>
            </tr>


            </tbody>
          </table>
        </div>
      </div>

    </div>
<!-- footer -->
    <div>
      <div style="position: fixed;bottom: 0px;width: 70%;margin: 0px 60px;">
        <div style="display:flex;justify-content: space-between">
          <p><strong>N.T:</strong>
                This Statement is auto generated by Orko Health Ltd.
          </p>
          <p>
                <strong>Statement generation Date:</strong>
               {{ new Date() | moment("dddd, MMMM Do YYYY") }}
        </p>
        </div>
        <div style="text-align: right">
          <img src="../../../assets/img/logo.svg" alt="" style="height: 50px">
          <p>Connect.Care</p>

        </div>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "TransactionStatementPdf",
  props: {
    items: {},
    user: {},
  },
  computed: {
    totalDebit() {
      if (this.items) {
        let debitTotal = this.items.reduce(function (sum, item) {
          return sum += item.debit;
        }, 0);
        return debitTotal
      }
      return 0;
    },
    totalCredit() {
      if (this.items) {
        let creditTotal = this.items.reduce(function (sum, item) {
          return sum += item.credit;
        }, 0);
        return creditTotal;
      }

      return 0;
    },
    totalBalance() {
      if (this.items) {
        let balanceTotal = this.items.reduce(function (sum, item) {
          return sum += item.balance;
        }, 0);
        return balanceTotal;
      }
      return 0;
    },
  },
}
</script>

<style scoped>

@bottom-center
{
  content: element(pageFooter)
;
}
#footer {
  position: running(pageFooter);
}

.body {
  height: 842px;
  width: 595px;
  margin: 50px;
}

#download {
  height: 842px;
  width: 595px;
  margin-left: auto;
  margin-right: auto;
}

thead th {
  text-align: center;
}

tbody td {
  height: 3rem;
  text-align: center;
  vertical-align: middle;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
}

/*#footer {*/
/*  position: fixed;*/
/*  width: 100%;*/
/*  bottom: 0;*/
/*  left: 0;*/
/*  right: 0;*/
/*}*/
</style>