import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const TherapyMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title"
                },
                "Description": {
                    field: "description"
                },
                "Status": {
                    field: "is_active"
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Parent',
                    key: 'parent',
                    sortable: true,
                },
                {
                    label: 'Description',
                    key: 'description',
                    sortable: true,
                },

                {
                    label: 'Status',
                    key: 'is_active',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
                is_active: '',
            },
            filter: null,
            filterOn: [],
            form: {
                title: '',
                parent_id: '',
                is_active: 1,
                description: '',
            },
            edit: false,
            edit_id: '',
            loading: true,
            status: [
                {text: 'Active', value: 1},
                {text: 'In active', value: 0},
            ]
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Therapy", route: "/therapy"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getTherapy();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Therapy',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },

    methods: {

        getTherapy() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/partner/therapy/list?platform=backoffice&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            this.unit_priceState = valid
            this.strengthState = valid
            this.dosagesState = valid
            this.useForState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.edit = false;
            this.edit_id = '';
        },

        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler

            if (this.edit) {
                return this.updatedata();
            }
            this.handleSubmit()
        },

        handleSubmit() {

            let form = new FormData();
            form.append(`title`, this.form.title);
            if (this.form.description) {
                form.append(`description`, this.form.description);
            }

            // form.append(`is_active`, this.form.is_active);
            if (this.form.parent_id) {
                form.append(`parent_id`, this.form.parent_id);
            }

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/partner/therapy/store?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getTherapy();
                        this.$bvModal.hide('modal-2')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        editAction(row) {
            this.getTherapy();
            this.$bvModal.show('modal-2');
            this.edit = true;
            this.edit_id = row.item.id;
            this.form.title = row.item.title;
            this.form.description = row.item.description;
            this.form.is_active = row.item.is_active;
            this.form.parent_id = row.item.parent.id;
            // this.form.parent_id = row.item.id
        },
        updatedata() {
            let form = new FormData();
            form.append(`title`, this.form.title);
            form.append(`is_active`, this.form.is_active);
            if (this.form.description) {
                form.append(`description`, this.form.description);
            }

            // form.append(`is_active`, this.form.is_active);
            if (this.form.parent_id) {
                form.append(`parent_id`, this.form.parent_id);
            }
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/partner/therapy/update/${this.edit_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.getTherapy()
                        this.$bvModal.hide('modal-2')
                        this.$snotify.success('Successfully updated');
                    })
            })
        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v2/partner/therapy/delete/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getTherapy();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },


    }
}