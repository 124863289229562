import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const NotificationTemplateMixin = {
    data() {
        return {
            form: {
                notification_template: '',
                notification_type_id: '',
                action_point: null,
                status: '',
                is_system_initiated: '',
                orko_chat_bot_template: '',
                orko_chat_bot_action_point: null,
                id: '',
            },
            bookType: 'xlsx',
            list: null,
            listLoading: true,
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            items: [],
            notification_types: [],
            loading: false,
            name: '',
            notification_template_nameState: null,
            notificationTypeState: null,
            notification_type_nameState: null,
            actionPointState: null,
            submittedNames: [],
            groups: [
                {
                    id: 1,
                    name: "Users"
                }
            ],
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'notification_template',
                    label: 'Notification template',
                    sortable: true
                },
                {
                    key: 'notification_type',
                    label: 'Notification type term',
                    sortable: true
                },
                {
                    key: 'action_point',
                    label: 'Action point',
                    sortable: true
                },
                {
                    key: 'orko_chat_bot_template',
                    label: 'Orko chat bot template',
                    sortable: true
                },
                {
                    key: 'orko_chat_bot_action_point',
                    label: 'Orko chat bot action point',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                },
                {
                    key: 'is_system_initiated',
                    label: 'Is system initiated',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Action',
                }
            ],
            filter: null,
            filterOn: [],
            filters: {
                index: '',
                notification_template: '',
                notification_type: '',
                action_point: '',
                status: '',
                is_system_initiated: '',
            },
            json_fields: {
                "Notification template": {
                    field: "notification_template"
                },
                "Notification type": {
                    field: "notification_type"
                },
                "Action point": {
                    field: "action_point"
                },
                "Is system initiated": {
                    field: "is_system_initiated"
                },
            },
            columns: [
                {
                    key: 'index',
                    label: 'SL.',
                },
                {
                    key: 'notification_template',
                    label: 'Notification template',
                },
                {
                    key: 'notification_type',
                    label: 'Notification type',
                },
                {
                    key: 'action_point',
                    label: 'Action point',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
                {
                    key: 'is_system_initiated',
                    label: 'Is system initiated',
                }
            ],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            update_id: '',
            action_point: '',
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Notification template", route: "notification-template"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 5)
        this.$root.$emit('call-active-menu', 5);
        this.getNotificationType();
        this.getNotificationTemplate();
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            const filtered = this.items.filter(item => {

                return Object.keys(this.filters).every(key =>

                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                index: '',
                notification_template: '',
                notification_type: '',
                action_point: '',
                status: '',
                is_system_initiated: '',
            }]
        },
        actionPointComputed(item) {
            return item;
        }
    },
    methods: {
        getNotificationTemplate() {
            // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
            axios.get(`${process.env.VUE_APP_URL}/api/v1/notification/manage/template/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                        this.loading = true;
                    }
                })
        },

        getNotificationType() {
            // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
            axios.get(`${process.env.VUE_APP_URL}/api/v1/notification/manage/type/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.notification_types = response.data.data
                    this.loading = true;
                })
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            // this.notification_template_nameState = valid
            this.notificationTypeState = valid
            // this.actionPointState = valid
            return valid
        },
        resetModal() {
            this.form = {};
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        editData(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.updateNotification()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }


            // Push the name to submitted names
            let form = new FormData();
            form.append("notification_template", this.form.notification_template);
            form.append("notification_type_id", this.form.notification_type_id);
            // if (this.form.action_point) {
            form.append("action_point", this.form.action_point ? this.form.action_point : null);
            // }
            // if (this.form.orko_chat_bot_template) {
            form.append("orko_chat_bot_template", this.form.orko_chat_bot_template);
            // }
            // if( this.form.orko_chat_bot_action_point) {
            form.append("orko_chat_bot_action_point", this.form.orko_chat_bot_action_point ? this.form.orko_chat_bot_action_point : null);
            // }

            form.append("status", '1');
            form.append("is_system_initiated", '1');

            // Hide the modal manually
            this.$nextTick(() => {
                var url = '';
                if (this.form.id) {
                    url = `${process.env.VUE_APP_URL}/api/v1/notification/manage/template/update/${this.form.id}?api_token=${localStorage.getItem('api_token')}`
                } else {
                    url = `${process.env.VUE_APP_URL}/api/v1/notification/manage/template/add?api_token=${localStorage.getItem('api_token')}`;
                }
                axios
                    .post(url, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getNotificationTemplate();
                        this.$bvModal.hide('modal')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        showRolesDetails(id) {
            this.$router.push(`/roles/show/${id}`);
        },
        updateData(row) {

            this.$bvModal.show('modal')
            this.form = row.item;

        },
        updateNotification() {
// Exit when the form isn't valid

            // Push the name to submitted names
            let form = new FormData();
            form.append("notification_template", this.form.notification_template);
            form.append("notification_type_id", this.form.notification_type_id);
            form.append("action_point", this.form.action_point);
            form.append("orko_chat_bot_template", this.form.orko_chat_bot_template);
            form.append("orko_chat_bot_action_point", this.form.orko_chat_bot_action_point);
            form.append("status", '1');
            form.append("is_system_initiated", '1');

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/notification/manage/template/update/${this.update_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getNotificationTemplate();
                        this.$bvModal.hide('updateModal')
                        this.$snotify.success('Successfully Updated');
                    })
            })
        },
        actionPoint(item) {
            if (item.length > 1) {
                let newItem = JSON.stringify(item)
                let tst = JSON.parse(newItem)
                this.action_point = tst;
            }
        }
    }
}
