<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <label for="">Select role</label>
          <b-form-select v-model="form.product_id" class="mb-3" disabled>
            <b-form-select-option :value="item.id" v-for="(item, index) in items" :key="index">
              {{ item.title }}
            </b-form-select-option>
          </b-form-select>
          <div class="single-module" v-for="(permission,index) in permissions" :key="index">


            <h4 @click="toggle(index)" class="px-4 b mb-0 d-flex justify-content-between padding-bottom-10">
              <div class="d-flex">
                <b-form-checkbox
                    size="lg">
                </b-form-checkbox>
                {{ index }}
              </div>
              <div>
                <span class="px-5"> <i class="fas fa-chevron-up"></i> </span>
              </div>
            </h4>
            <div v-show="isOpen" class="p-3 px-4 accordion-description">
              <div v-for="(permission_data, dfs) in permission" :key="dfs">
                {{ permission_data[2] }}
                <b-form-checkbox
                    size="lg"
                    :value="permission_data[0]"
                    v-model="permissions_id">

                  {{ permission_data[1] }} <br>
                  <span class="api_url">{{ permission_data[3] }}</span>

                </b-form-checkbox>
              </div>
            </div>

          </div>
          <button type="submit" class="btn btn-primary flot-icon"><i class="fas fa-pencil-alt"></i></button>
        </form>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "ManageProductPermissions",
  data(){
    return {
      items: [],
      loading: false,
      roleIdState: null,
      submittedNames: [],
      isOpen: false,
      form: {
        product_id: '',
        permission_ids: ''
      },
      permissions_id: [],
      permission_data: [],
      modal_loading: false,
      updated_permission_data: '',
      isCheckedCheckbox: {},
    }
  },
  created() {
    localStorage.setItem('tabIndex', 2)
    this.$root.$emit('call-active-menu', 2);
    this.getRolesData();
    this.getPermissions();
    this.roleWisePermissionUpdate();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Assign permissions", route: "assign-permissions"},
    ]);
  },
  computed: {
    roles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },
    permissions: {
      get() {
        return this.$store.state.auth.permissions;
      }
    }
  },
  methods: {
    getRolesData() {
      // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
      axios.get(`${process.env.VUE_APP_URL}/api/v1/product/list?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied');
            } else {
              this.items = response.data.data
              this.loading = true;
            }
          })
    },
    getPermissions() {
      this.$store.dispatch('auth/getPermissions');
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.roleIdState = valid
      return valid
    },
    checkPermissions(event, key) {
      if (event) {
        this.permission_data.push(key);
        let data = this.permission_data.join(',')
        this.form.permission_ids = data;
      } else {
        const index = this.permission_data.indexOf(key);
        this.permission_data.splice(index, 1);
        let data = this.permission_data.join(',')
        this.form.permission_ids = data;
      }
    },
    resetModal() {
      this.name = '';
      this.roleIdState = null;
      this.form.product_id = '';
      this.form.permission_ids = '';
      this.permissions_id = [];
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        // this.form.permission_ids = this.permissions_id
        let form = new FormData();

        form.append("product_id", this.form.product_id);
        form.append("permission_id", this.permissions_id);
        axios
            .post(`${process.env.VUE_APP_URL}/api/v1/product/permissions/store`, form)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.form.permission_ids = '';
              this.$bvModal.hide('modal');
              this.$bvModal.hide('modal-update');
              this.$snotify.success('Successfully saved');
            })
      })
    },
    toggle(index) {
      if (this.activeItemIndex == index) {
        this.isOpen = index;
        return this.activeItemIndex = null;
      }
      this.isOpen = index;
      this.activeItemIndex = index
    },
    roleWisePermissionUpdate() {
      this.$bvModal.show('modal-update');
      this.modal_loading = true;
      axios
          .get(`${process.env.VUE_APP_URL}/api/v1/product/show/${this.$route.params.id}`)
          .then(respose => {
            this.modal_loading = false;
            this.form.product_id = respose.data.data.id;
            respose.data.data.permissions.map(permission => {

              this.permissions_id.push(permission.permission_id);
              // for (const [key] of Object.entries(permission)) {
              //   this.permissions_id.push(key);
              // }
            });
          })
    },
    permissionGenerate() {

      this.$snotify.clear();
      this.$snotify.confirm(
          "You want generate permission",
          "Are you sure?",
          {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: "Yes",
                action: toast => {
                  this.$snotify.remove(toast.id);
                  axios
                      .get(`${process.env.VUE_APP_URL}/api/v1/permission/list/generate?api_token=${localStorage.getItem('api_token')}`)
                      .then(response => {

                        if (response.data.status_code == 400) {
                          return this.$snotify.error(
                              response.data.message,
                              "Error"
                          );
                        }
                        this.$snotify.success(
                            "Successfully generate",
                            "Success"
                        );

                      })
                      .catch(e => {
                        this.$snotify.error(
                            e
                        );
                      });
                },
                bold: true
              },
              {
                text: "No",
                action: toast => {
                  this.$snotify.remove(toast.id);
                },
                bold: true
              }
            ]
          }
      );
    }

  },
}
</script>

<style scoped>
.flot-icon {
  position: fixed;
  bottom: 65px;
  right: 25px;
  margin: 15px;
  height: 70px;
  width: 70px;
  border-radius: 100px;
  cursor: pointer;
  z-index: 9999;
}
.flot-icon i {
  font-size: 25px;
}
.accordion-description {
  margin-left: 30px;
}
</style>