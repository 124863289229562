<template>
  <div>
    <div>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-end">
            <div class="mx-3">
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Finacial Statement"
                  name="Finacial Statement">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
            <div>
              <button class="btn btn-danger" @click="clearDate">Clear</button>
            </div>
            <div>

            </div>
            <div>

            </div>
          </div>

          <div class="row mt-5 align-items-center justify-content-between">
            <div class="col-md-1 mt-10">
              <div class="">
                <b-form-group
                    class="mb-0">
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="col-md-3 mt-10">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-md-3">

              <label for="">From</label>
              <input type="date" v-model="startDate" class="form-control">

            </div>
            <div class="col-md-3">
              <label for="">To</label>
              <input type="date" v-model="endDate" class="form-control">
            </div>
          </div>

          <b-table
              striped hover
              :items="items"
              :fields="fields"
              :current-page="currentPage"
              :per-page="0"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              thead-class="bg-primary text-white"
              show-empty
              class="table-responsive mt-5">

            <template slot="top-row" slot-scope="{ fields }">
              <td v-for="field in fields" :key="field.key">
                <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
              </td>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(action)="row">
              <div>
                <b-button @click="sendInfo(row.item)" variant="primary"><i class="fas fa-eye"></i></b-button>

              </div>
            </template>

            <template #cell(user_fullname)="row">
              <div class="">
                <b-media tag="li">
                  <template #aside>
                    <b-img :src="userPhoto(row.item.user_image)" class="user_image" alt="placeholder"
                           v-if="row.item.user_image"></b-img>
                    <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>
                  </template>

                  <h5 class="mt-0 mb-1">
                    <router-link to="#">{{
                        row.item.user_fullname
                      }}
                    </router-link>
                  </h5>
                  <ul class="mb-0 user_info">
                    <li>
                      {{ row.item.user_phone }}
                    </li>
                  </ul>
                </b-media>
              </div>
            </template>
          </b-table>
          <div class="description found"> Total : {{ total_item }}</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="total_item"
              :per-page="perPage"
              size="lg"
              class="my-0"
              @change="handlePageChange"
          ></b-pagination>
        </div>
      </div>

    </div>
    <div>
      <!--   Details modal   -->

      <b-modal id="payment_details" size="lg" @show="resetModal"
               @hidden="resetModal" title="Payment details">
        <div v-if="payment_details">

          <p class="mb-2">Card: <b>{{ payment_details.card_brand ? payment_details.card_brand : 'n/a' }}</b></p>
          <p class="mb-2">Card issuer: <b>{{ payment_details.card_issuer ? payment_details.card_issuer : 'n/a' }}</b>
          </p>
          <p class="mb-2">Bank transaction id:<b> {{
              payment_details.bank_tran_id ? payment_details.bank_tran_id : 'n/a'
            }}</b></p>
          <p class="mb-2">Order id: <b>{{ payment_details.order_id ? payment_details.order_id : 'n/a' }}</b></p>
          <p class="mb-2">Gateway transaction id:
            <b>{{ payment_details.gateway_transaction_id ? payment_details.gateway_transaction_id : 'n/a' }}</b></p>
          <p class="mb-2">Actual payment: <b>{{
              payment_details.actual_payment ? payment_details.actual_payment : 'n/a'
            }}</b></p>
          <p class="mb-2">Amount: <b>{{ payment_details.amount ? payment_details.amount : 'n/a' }}</b></p>
          <p class="mb-2">Service description:
            <b>{{ payment_details.service_description ? payment_details.service_description : 'n/a' }}</b></p>
          <p class="mb-2">Service fee: <b>{{ payment_details.service_fee ? payment_details.service_fee : 'n/a' }}</b>
          </p>
          <p class="mb-2">Service type: <b>{{ payment_details.service_type ? payment_details.service_type : 'n/a' }}</b>
          </p>
          <p class="mb-2">Transaction date: <b>{{ payment_details.tran_date ? payment_details.tran_date : 'n/a' }}</b>
          </p>

        </div>
      </b-modal>
      <loader-component v-if="loading"/>

    </div>
  </div>
</template>

<script>
import {FinancialStatementReportMixin} from "../../mixins/reports/FinancialStatementMixin";

export default {
  name: "FinancialStatement",
  mixins: [FinancialStatementReportMixin],
  title: 'Financial Statement',
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

</style>