<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Symptoms</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new symptoms
            </b-button>
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a symptoms"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <div class="form-group">
                  <label for="">Select parent symptoms</label>
                  <v-select
                      :options="items"
                      :reduce="items => items.id"
                      v-model="form.parent_id"
                      label="short_tag"
                  >
                  </v-select>
                  <p class="text-danger">{{ spciality_required }}</p>
                </div>

                <b-form-group
                    label="Short tag"
                    label-for="name-input"
                    invalid-feedback="Short tag is required"
                    :state="titleState">
                  <b-form-input
                      id="name-input"
                      v-model="form.short_tag"
                      :state="titleState"
                      required/>
                </b-form-group>


                <div class="form-group">
                  <label for="">Select speciality</label>
                  <v-select
                      :options="speciality"
                      :reduce="speciality => speciality.id"
                      v-model="form.speciality_id"
                      label="name"
                      >
                  </v-select>
                  <p class="text-danger">{{ spciality_required }}</p>
                </div>

                <b-form-group
                    label="Select types"
                    label-for="name-input"
                    invalid-feedback="Types is required"
                    :state="statusState">
                  <b-form-select v-model="form.status" :options="types" :state="statusState" required></b-form-select>
                </b-form-group>

                <b-form-group
                    label="Select language"
                    label-for="name-input"
                    invalid-feedback="language is required"
                    :state="langState">
                  <b-form-select v-model="form.language" :options="langs" :state="langState" required></b-form-select>
                </b-form-group>

                <b-form-group
                    label="Sort"
                    label-for="name-input"
                    invalid-feedback="Sort is required"
                    :state="sortState">
                  <b-form-input
                      id="name-input"
                      type="number"
                      v-model="form.sort"
                      :state="sortState"
                      required/>
                </b-form-group>

                <b-form-checkbox
                    id="checkbox-1"
                    v-model="form.is_active"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0">
                  Status
                </b-form-checkbox>


              </form>
            </b-modal>
          </div>
        </div>
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Symptoms list"
                  name="Symptoms list">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                class="mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(is_active)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.is_active == 1">Active</b-badge>
                  <b-badge variant="danger" v-else>Inactive</b-badge>
                </div>
              </template>

              <template #cell(parent_id)="data">
                <div>
                  <p>{{ data.item.parent ? data.item.parent.short_tag : '-'}}</p>
                </div>
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
                </td>
              </template>

              <template #cell(bank_logo)="row">
                <div class="">
                  <b-img :src="userPhoto(row.item.bank_logo)" class="user_image" :alt="row.item.bank_logo"
                         v-if="row.item.bank_logo"></b-img>
                  <img src="../../assets/img/blank.png" class="user_image" v-else/>
                </div>
              </template>

              <template #cell(actions)="row">
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">

                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>

                  <b-dropdown-item href="#" @click="edit(row)">
                    <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                  </b-dropdown-item>

                </b-dropdown>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                size="lg"
                :total-rows="items.length"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {SymptompsMixin} from '../../mixins/SymptompsMixin';

export default {
  name: "Symptomps",
  mixins: [SymptompsMixin],
}
</script>

<style scoped>

</style>