<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              :current-page="0"
              :per-page="perPage"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">

            <template #cell(is_model_pharmacy)="row">
              <div>
                <b-badge variant="success" v-if="row.item.is_model_pharmacy==1">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </div>
            </template>

            <template #cell(prefer_prescription)="row">
              <div>
                <b-badge variant="success" v-if="row.item.prefer_prescription==1">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </div>
            </template>
            <template #cell(prefer_prescription)="row">
              <div>
                <b-badge variant="success" v-if="row.item.prefer_prescription==1">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </div>
            </template>
            <template #cell(online_care)="row">
              <div>
                <b-badge variant="success" v-if="row.item.online_care==1">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </div>
            </template>
            <template #cell(home_delivery)="row">
              <div>
                <b-badge variant="success" v-if="row.item.home_delivery==1">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </div>
            </template>
            <template #cell(avail_home_delivery)="row">
              <div>
                <b-badge variant="success" v-if="row.item.avail_home_delivery==1">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </div>
            </template>


          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              @change="handlePageChange"
              size="lg"
              class="my-0"
              aria-controls="doctorList"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "PharmacySurvey",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Pharmacy Survey", route: "/sales/call/log"},
    ]);

  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'seller_name',
          label: 'TSM name',
          sortable: true,
        },
        {
          key: 'owner_name',
          label: 'Own name',
          sortable: true,
        },
        {
          key: 'shop_name',
          label: 'Shop name',
          sortable: true,
        },
        {
          key: 'daily_patient',
          label: 'Daily patient',
          sortable: true,
        },
        {
          key: 'regular_customer',
          label: 'Regular customer',
          sortable: true,
        },
        {
          key: 'monthly_sales_amount',
          label: 'Monthly sale amount',
          sortable: true,
        },
        {
          key: 'prefer_prescription',
          label: 'Prefer prescription',
          sortable: true,
        },
        {
          key: 'is_model_pharmacy',
          label: 'Model Pharmacy',
          sortable: true,
        },
        {
          key: 'online_care',
          label: 'Online care',
          sortable: true,
        },
        {
          key: 'home_delivery',
          label: 'Home delivery',
          sortable: true,
        },
        {
          key: 'avail_home_delivery',
          label: 'Avail home delivery',
          sortable: true,
        },
      ],
      currentPage: 1,
      perPage: 20,
      total_count: 0,
      loading: false,
    }
  },
  created() {
    localStorage.setItem('tabIndex', 14)
    this.$root.$emit('call-active-menu', 14);
    this.getItems();
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/sales/team/pharmacy-surveys?api_token=${localStorage.getItem('api_token')}&offset=0&limit=${this.perPage}&platform=backoffice`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },

    handlePageChange(value){
      this.loading = true
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/sales/team/pharmacy-surveys?api_token=${localStorage.getItem('api_token')}&offset=${correctValue}&limit=${this.perPage}&platform=backoffice`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    }

  }
}
</script>

<style scoped>

</style>
