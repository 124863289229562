import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {MEDICAL_ADVICE_LIST, MEDICAL_ADVICE_STORE, MEDICAL_ADVICE_UPDATE} from "../../api/ApiUrl";

export const AdviceMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
            },
            fields: [
                {
                    key: 'id',
                    label: 'Id',
                    sortable: true
                },
                {
                    label: 'Image',
                    key: 'image',
                    sortable: true,
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Creator',
                    key: 'created_by',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            strengthState: null,
            useForState: null,
            darState: null,
            unit_priceState: null,
            dosages_desc_typeState: null,
            form: {
                id: '',
                title: '',
                parent_id: '',
                image_type: 'text_with_image',
            },
            is_image: false,
            edit: false,
            edit_id: '',
            medicine_generics: [],
            medicine_company: [],
            loading: true,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Advice", route: "/advice"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getAdvice();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Medical advice',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getAdvice() {
            axios.get(MEDICAL_ADVICE_LIST)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        chooseOption(value) {
            if(value == 'advice_image') {
               return this.is_image = true;
            }

            return this.is_image = false;
            
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            return valid
        },
        resetModal() {
            this.form = {
                title: '',
                parent_id: '',
                image_type: 'text_with_image',
            };
            this.titleState = null;
            this.edit_id = '';
            this.edit = false;
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
        
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            // if (!this.checkFormValidity()) {
            //     return
            // }
            // const profile_img = this.$refs.profile_img.files ? this.$refs.profile_img.files[0] : '';
           
            const advice_image = this.$refs.advice_image ? this.$refs.advice_image.files[0] : '';
            const text_with_image = this.$refs.text_with_image ?   this.$refs.text_with_image.files[0] : '';
            let form = new FormData();
            form.append("advice_text", this.form.title);
            if (this.form.parent_id) {
                form.append('parent_id', this.form.parent_id);
            }
            if(text_with_image) {
                form.append('advice_with_image', text_with_image)
            }

            if(advice_image) {
                form.append('advice_image', advice_image);
            }
            // Hide the modal manually
            this.$nextTick(() => {

                let url = '';
                if(this.form.id){
                    url = `${MEDICAL_ADVICE_UPDATE}/${this.form.id}?api_token=${localStorage.getItem('api_token')}`
                }else {
                    url = MEDICAL_ADVICE_STORE
                }
                axios
                    .post(url, form)
                    .then(response => {
                        this.checkResponse(response.data.message, response.data.status_code);
                        this.getAdvice();
                        this.$bvModal.hide('modal-1')
                    })
            })
        },
        
        editAction(row) {
            this.$bvModal.show('modal-1');
            this.edit = true;
            this.edit_id = row.item.id;
            this.form = row.item;
            this.form.title = row.item.title;
            this.form.medicine_generics = row.item.medicine_generics_id;
            this.form.manufacturer_id = row.item.manufacturer_id;
            if(row.item.type == 'image') {
                this.is_image = true;
                this.form.image_type = 'advice_image'
            }else {
                this.is_image = false;
                this.form.image_type = 'text_with_image'
            }

        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v1/medical/advice/delete/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }

                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getAdvice();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },

    }
}