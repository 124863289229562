<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-50 symbol-lg-120">
                  <img :src="currentUserPhoto(basic_info.image)" @click="showImage(basic_info.image)" alt="image"/>
                  <!--                  <img src="../../../assets/img/blank.png" alt="" v-else>-->
                  <i :class="basic_info.is_online === 1 ? ' symbol-badge is_active_icon' : 'is_inactive_icon'"
                     v-b-tooltip.hover.bottom="'Online'"></i>
                </div>

              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a
                        href="#"
                        class="text-dark-75  font-size-h5 font-weight-bold mr-3">
                      <span class="font-size-h3">{{ basic_info.fullname ? basic_info.fullname : 'Not set yet' }}</span>
                      <span v-b-tooltip.hover.bottom="'User type'"> ({{
                          basic_info.user_type ? basic_info.user_type : 'Not set yet'
                        }})</span>
                      <br>
                      <span class="font-size-h6">{{
                          basic_info.nature_of_service ? basic_info.nature_of_service : 'Not set yet'
                        }}</span>
                    </a>
                    <!--  Confirm doctor-->

                  </div>
                  <div class="my-lg-0 my-3">
                    <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"
                              @click="editPatientInfo">Edit profile
                    </b-button>
                    <b-modal
                        id="basicInfo"
                        title="Basic info "

                        @show="resetModal"
                        size="lg"
                        @ok="handleOk"
                        data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                        aria-labelledby="staticBackdropLabel" aria-hidden="true"
                    >
                      <b-spinner variant="primary" label="Spinning" v-if="modal_loading"></b-spinner>
                      <form ref="form" @submit.stop.prevent="saveDoctorBasicInfo" style="height: 80vh;">

                        <div class="row">
                          <div class="col-4">
                            <b-form-group
                                label="First name"
                                label-for="firstname"
                                class="mb-0">
                              <b-form-input
                                  id="firstname"
                                  v-model="basic_info_form.first_name"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Last name"
                                label-for="lastname"
                                class="mb-0">
                              <b-form-input
                                  id="lastname"
                                  v-model="basic_info_form.last_name"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Email"
                                label-for="email"
                                class="mb-0">
                              <b-form-input
                                  id="email"
                                  v-model="basic_info_form.email"
                                  disabled
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-4">
                            <b-form-group
                                label="Phone number"
                                label-for="phone"
                                class="mb-0">
                              <b-form-input
                                  id="phone"
                                  v-model="basic_info_form.phone_number"

                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Gender"
                                label-for="gender"
                                class="mb-0">
                              <select name="" class="form-control" v-model="basic_info_form.gender">
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Date of birth"
                                label-for="dateofbirth"
                                class="mb-0">
                              <b-form-input
                                  type="date"
                                  id="dateofbirth"
                                  v-model="basic_info_form.dob"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-4">
                            <b-form-group
                                label="Blood group"
                                label-for="gender"
                                class="mb-0">
                              <select name="" id="gender" class="form-control" v-model="basic_info_form.blood_group">
                                <option value="A+">A(+)ve</option>
                                <option value="B+">B(+)ve</option>
                                <option value="O+">O(+)ve</option>
                                <option value="AB+">AB(+)ve</option>
                                <option value="A-">A(-)ve</option>
                                <option value="B-">B(-)ve</option>
                                <option value="O-">O(-)ve</option>
                                <option value="AB-">AB(-)ve</option>
                              </select>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Location"
                                label-for="location"
                                class="mb-0">
                              <b-form-textarea
                                  id="location"
                                  v-model="basic_info_form.location"
                              ></b-form-textarea>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group label="Profile image">
                              <b-form-file
                                  id="profile_image"
                                  ref="file"
                                  accept=".png,.jpg,.jpeg">
                              </b-form-file>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <b-form-group
                                label="Chronic diseases"
                                label-for="name-input">
                              <v-select
                                  :options="disease"
                                  :reduce="disease => disease.id"
                                  v-model="basic_info_form.chronic_diseases"
                                  label="disease_title"
                                  class="h-100"
                                  scrollable
                                  multiple>
                              </v-select>
                            </b-form-group>
                          </div>
                        </div>
                      </form>
                    </b-modal>
                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <!--                    <div class="d-flex flex-wrap mb-4">-->
                    <div>
                      <div class="row">
                        <div class="col-md-10">
                          <div class="d-flex flex-wrap mb-4">
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Email'"
                            ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                            >{{ basic_info.email ? basic_info.email : 'Not set yet' }}</a>
                            <a
                                href="#"
                                class="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Location'"
                            ><i class="fas fa-map-marker-alt mr-2 font-size-lg"></i>
                              {{ basic_info.location ? basic_info.location : 'Not set yet' }} .
                              <span><b>Town</b>: {{ basic_info.town_name ? basic_info.town_name + ' , ' : ' ' }}</span>
                              <span><b>Region</b>: {{ basic_info.region ? basic_info.region + ' , ' : ' ' }}</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-9">
                          <div class="d-flex flex-wrap mb-4">
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Phone number'"
                            ><i class="flaticon2-phone mr-2 font-size-lg"></i>
                              {{ basic_info.phone_number ? basic_info.phone_number : 'Not set yet' }}
                            </a>
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Gender'"
                            ><i class="fas fa-transgender mr-3 font-size-lg"></i>
                              {{ basic_info.gender ? basic_info.gender : 'Not set yet' }}
                            </a>
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Birth day'"
                            ><i class="fas fa-birthday-cake mr-2 font-size-lg"></i>DOB: {{
                                basic_info.dob ? basic_info.dob : 'Not set yet'
                              }}
                            </a>
                            <a
                                href="#"
                                class="text-dark-50 font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Blood group'"
                            ><i class="fas fa-vial mr-2 font-size-lg"></i> {{
                                basic_info.blood_group ? basic_info.blood_group : 'Not set yet'
                              }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab title="FnF List" active>
          <div class="row mt-9 gutter-b">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h3>FnF List</h3>

                  <div>
                    <b-form-group
                        label-for="filter-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                    <b-table
                        striped hover responsive
                        :items="families"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter-included-fields="filterOn"
                        thead-class="bg-primary text-white"
                        show-empty
                        class="mt-10">

                      <template #cell(image)="row">
                        <div class="is_online">
                          <img :src="userPhoto(row.item.image)" alt="image"
                               class="user_image" v-if="row.item.image" @click="showImage(row.item.image)"/>
                          <img src="../../../assets/img/blank.png" alt="" class="user_image" v-else>
                          <i :class="`${row.item.is_online == '1' ? 'text-green ' : 'text-danger'} fas fa-circle is_online_icon`"></i>
                        </div>
                      </template>

                      <template #cell(fullname)="row">
                        <router-link :to="`/user/details/${row.item.id}`">{{ row.item.fullname }}</router-link>
                      </template>

                      <template #cell(actions)="row">
                        <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                                    class="m-2">

                          <template #button-content>
                            <i class="fas fa-ellipsis-v"></i>
                          </template>

                          <router-link :to="`agent-wise-doctors-list/${row.item.id}`" class="dropdown-item">
                            <span class="pr-4"><i class="fas fa-file-prescription"></i></span> Prescription
                          </router-link>

                          <router-link :to="`agent-wise-doctors-list/${row.item.id}`" class="dropdown-item">
                            <span class="pr-4"><i class="fas fa-file"></i></span> Reports
                          </router-link>

                          <b-dropdown-item href="#" @click="deleteAction(row.item)">
                            <span class="pr-4"><i class="fas fa-trash"></i></span>Delete
                          </b-dropdown-item>

                        </b-dropdown>

                      </template>

                    </b-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Known of case">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h3 class="mb-10">Known of case</h3>
                  <div v-if="basic_info.chronic_diseases">
                    <b-badge class="mr-3 my-3 common-badge" variant="secondary"
                             v-for="(diseases, index) in basic_info.chronic_diseases"
                             :key="index">
                      {{ diseases.disease_title }}
                    </b-badge>
                  </div>
                  <div v-else>
                    not found
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Appointments" @click="getAppointment">
          <div class="d-flex justify-content-center mb-3" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <b-table
                striped hover responsive
                :items="appointments"
                :fields="appointment_fields"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-10">
              <template #cell(doctor_id)="row">
                <div>
                  <b-media tag="li" class="">
                    <template #aside>
                      <div class="text-center">
                        <b-img :src="userPhoto(row.item.doctor_image)" width="85" class="thumbnail" thumbnail></b-img>
                        <!--                        <star-rating :value="`${row.item.doctor_review}`" :disabled="true"></star-rating>-->
                        <!--                        <p class="mb-0">Exp: <b>{{ row.item.doctor_exp }} Year</b></p>-->
                      </div>
                    </template>
                    <h5 class="mt-0 mb-1">
                      <router-link :to="`user/details/${row.item.doctor_id}`">{{ row.item.doctor_prefix }}
                        {{ row.item.doctor_name }}
                      </router-link>
                    </h5>
                    <p class="mb-0 font-weight-bold">{{ row.item.doctor_phone_number }}</p>
                    <!--                    <p class="mb-0">{{ row.item.doctor_degree }}</p>-->
                    <!--                    <p class="mb-0">{{ row.item.institute_name }}</p>-->
                  </b-media>
                </div>
              </template>

              <template #cell(appointment_date)="row">
                <div>
                  <p>{{ row.item.appointment_date }}</p>
                </div>
              </template>


              <template #cell(schedule_date)="row">
                <div>
                  <h4>{{ row.item.schedule_date }}</h4>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <b-badge variant="success">start: {{ row.item.schedule_started_at }}</b-badge>
                </div>
              </template>

              <template #cell(is_paid)="row">
                <div>
                  <b-badge variant="success" v-if="row.item.is_paid == 1">Paid</b-badge>
                  <b-badge variant="warning" v-else-if="row.item.is_paid == 2">Partial paid (AP)</b-badge>
                  <b-badge variant="danger" class="mt-3 cursor-pointer" v-else>
                    Unpaid
                  </b-badge>
                </div>
              </template>

              <template #cell(appointment_status)="row">
                <div>
                  <b-badge variant="warning" v-if="row.item.appointment_status == 'expired'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="success" v-else-if="row.item.appointment_status == 'booked'">
                    {{ row.item.appointment_status }}
                  </b-badge>

                  <b-badge variant="danger" v-else-if="row.item.appointment_status == 'canceled'">
                    {{ row.item.appointment_status }}
                  </b-badge>

                  <b-badge variant="info" v-else-if="row.item.appointment_status == 'rescheduled'">
                    {{ row.item.appointment_status }}
                  </b-badge>

                  <b-badge variant="danger" v-else-if="row.item.appointment_status == 'patient-absent'">
                    {{ row.item.appointment_status }}
                  </b-badge>

                  <b-badge variant="primary" v-else-if="row.item.appointment_status == 'served'">
                    {{ row.item.appointment_status }}
                  </b-badge>
                  <b-badge variant="danger" class="mt-3" v-else>{{ row.item.appointment_status }}</b-badge>
                  <br>
                  <b-badge variant="success" class="mt-3" v-if="row.item.is_followup==1">Followup</b-badge>
                </div>
              </template>


              <template #cell(payable_amount)="row">
                <div>
                  <h4>
                    {{ row.item.payable_amount }}
                  </h4>
                </div>
              </template>


              <template #cell(appointment_type)="row">
                <div>
                  <p variant="success">
                    {{ underScoreToSpace(row.item.appointment_type) }}
                  </p>

                </div>
              </template>

            </b-table>
            <b-pagination
                v-model="appointment_current_page"
                :total-rows="total_appointment"
                :per-page="perPage"
                size="lg"
                @change="getAppointment"
                class="my-0"
            ></b-pagination>
          </div>

        </b-tab>
        <b-tab title="Prescriptions" @click="getPrescriptions">
          <div class="d-flex justify-content-center mb-3" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <b-table
                striped hover responsive
                :items="prescriptions"
                :fields="prescriptions_fields"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-10">
              <template #cell(doctor_details)="row">
                <div>
                  <b-media tag="li" class="">
                    <template #aside>
                      <div class="text-center">
                        <b-img :src="userPhoto(row.item.doctor_details.image)" width="85" class="thumbnail responsive"
                               thumbnail></b-img>
                      </div>
                    </template>
                    <h5 class="mt-0 mb-1">
                      <router-link :to="`user/details/${row.item.doctor_details.id}`">
                        {{ row.item.doctor_details.doctor_prefix }}
                        {{ row.item.doctor_details.fullname }}
                      </router-link>
                    </h5>
                    <p class="mb-0 font-weight-bold">{{ row.item.doctor_details.phone_number }}</p>
                  </b-media>
                </div>
              </template>
              <template #cell(prescription_identifier)="row">
                <a class="text-info font-weight-bold d-flex"
                   :href="prescriptionUrl(row.item.prescription_url)"
                   target="_black">
                  {{ row.item.prescription_identifier }}</a>
              </template>
              <template #cell(is_paid)="row">
                <div class="">
                  <b-badge variant="success" v-if="row.item.is_paid == 1">Paid</b-badge>
                  <b-badge variant="info" v-else-if="row.item.is_paid == 2">Partial Paid</b-badge>
                  <b-badge variant="danger" v-else> Unpaid</b-badge>
                </div>
              </template>
              <template #cell(created_date)="row">
                <div class="">
                  {{ row.item.created_date }} {{ row.item.created_at }}
                </div>
              </template>
            </b-table>
            <b-pagination
                v-model="prescription_current_page"
                :total-rows="total_prescription"
                :per-page="perPage"
                size="lg"
                @change="getPrescriptions"
                class="my-0"
            ></b-pagination>
          </div>
        </b-tab>
        <b-tab title="Invoices" @click="getInvoice">
          <div class="d-flex justify-content-center mb-3" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <div v-else>
            <b-table
                striped hover responsive
                :items="invoices"
                :fields="invoice_fields"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-10">
              <template #cell(product_type)="row">
                <div class="text-center">
                  <b-badge variant="primary"
                           v-if="row.item.product_type == 'service'">
                    {{ row.item.product_type }}
                  </b-badge>
                  <b-badge
                      v-else-if="row.item.product_type == 'therapy'"
                      variant="info"
                  >
                    {{ row.item.product_type }}
                  </b-badge>
                  <b-badge
                      v-else
                      variant="success"
                  >
                    {{ row.item.product_type }}
                  </b-badge>
                </div>
              </template>
            </b-table>
            <b-pagination
                v-model="invoice_current_page"
                :total-rows="total_invoice"
                :per-page="perPage"
                size="lg"
                @change="getInvoice"
                class="my-0"
            ></b-pagination>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {underScoreToSpace} from "../../../healper/nameFormating";

export default {
  name: "BasicInfo",
  props: {
    basic_info: {},
  },
  data() {
    return {
      modal_loading: false,
      basic_info_form: {
        first_name: "",
        last_name: "",
        gender: '',
        location: '',
        blood_group: '',
        phone_number: '',
        email: '',
        dob: null,
        identity_card_no: '',
        identity_card_type: '',
        comment: '',
        chronic_diseases: [],
      },
      disease: [],
      families: [],
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, {value: 100, text: "All"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filters: {
        id: '',
        bank_logo: '',
        bank_title: '',
        country_name: '',
        address: '',
        status: '',
      },
      filter: null,
      filterOn: [],
      fields: [
        {
          label: 'Id',
          key: 'id',
          sortable: true
        },
        {
          label: 'Image',
          key: 'image',
          sortable: true
        },
        {
          label: 'Fullname',
          key: 'fullname',
          sortable: true
        },
        {
          label: 'Age',
          key: 'age',
          sortable: true
        },
        {
          label: 'Blood group',
          key: 'blood_group',
          sortable: true
        },
        {
          label: 'Gender',
          key: 'gender',
          sortable: true
        },
        {
          label: 'Relationship',
          key: 'relationship',
          sortable: true
        },
        {
          label: 'Location',
          key: 'location',
          sortable: true
        },
        {
          label: 'Actions',
          key: 'actions',
        },
      ],
      invoices: [],
      invoice_current_page: 1,
      total_invoice: 0,
      appointments: [],
      appointment_current_page: 1,
      total_appointment: 0,
      appointment_fields: [
        {
          key: 'doctor_id',
          label: 'Doctor/Physio',
          tdClass: 'test',
          'class': 'my-class',
          'no': {thStyle: {width: '400px !important'}},
          sortable: true,
        },
        {
          key: 'schedule_date',
          label: 'Schedule date',
          sortable: true,
        },

        {
          key: 'appointment_status',
          label: 'Appointment status',
          sortable: true,
        },
        {
          key: 'appointment_type',
          label: 'Appointment type',
          sortable: true,
        },
        {
          key: 'payable_amount',
          label: 'Payable amount',
          sortable: true,
        },
        {
          key: 'is_paid',
          label: 'Payment Status',
          sortable: true,
        },
      ],
      invoice_fields: [
        {
          key: 'uid',
          label: 'UID',
        },
        {
          key: 'product_type',
          label: 'Product Type',
        },
        {
          key: 'comment',
          label: 'Comment',
        },
        {
          key: 'comment',
          label: 'Comment',
        },
        {
          key: 'subtotal',
          label: 'Subtotal',
        },
        {
          key: 'discount_percentage',
          label: 'Discount percentage',
        },
        {
          key: 'discount_reason',
          label: 'Discount reason',
        },
        {
          key: 'total',
          label: 'Total',
        },
        {
          key: 'invoice_date',
          label: 'Invoice date',
        },
      ],
      prescriptions: [],
      prescriptions_fields: [
        {
          label: 'Doctor',
          key: 'doctor_details',
        },
        {
          label: 'Identifier',
          key: 'prescription_identifier',
        },
        {
          label: 'Payment Status',
          key: 'is_paid',
        },
        {
          label: 'Created Date',
          key: 'created_date',
        },
      ],
      prescription_current_page: 1,
      total_prescription: 0,
      loading: false,
    }
  },
  created() {
    this.getFamily();
  },
  computed: {
    rows() {
      return this.items.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {
      const filtered = this.items.filter(item => {
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })
      return filtered.length > 0 ? filtered : [{
        id: '',
        bank_logo: '',
        bank_title: '',
        country_name: '',
        address: '',
        status: '',
      }]
    },
    routeName() {
      return this.$route.name
    },
  },
  methods: {
    underScoreToSpace,
    getDisease() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/chronic/disease/list?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.disease = res.data.data;
          })
    },
    getFamily() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/patient/subordinate/list?patient_id=${this.basic_info.id}&api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.families = res.data.data;
          })
    },
    currentUserPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
      return process.env.BASE_URL + 'media/logos/logo.svg';
    },
    showImage(img) {
      const h = this.$createElement
      // Using HTML string
      // const titleVNode = h('div', {domProps: {innerHTML: 'Signature'}})


      // More complex structure
      const messageVNode = h('div', {class: ['foobar']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
            thumbnail: true,
            center: false,
          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        // title: [titleVNode],
        buttonSize: 'sm',
        centered: true, size: 'lg'
      })
    },
    editPatientInfo() {
      this.$bvModal.show('basicInfo');
    
      this.getDisease();
      this.basic_info_form = this.basic_info;
      this.basic_info_form.chronic_diseases = this.basic_info.chronic_diseases.map(item => {
        return item.id
      });
    },
    resetModal() {
      this.departmentState = null
      this.basic_info_form = {};
    },
    saveDoctorBasicInfo() {
      this.$nextTick(() => {
        let form = new FormData();
        let profile_image = document.getElementById('profile_image').files[0];
        if (this.basic_info_form.first_name) {
          form.append("first_name", this.basic_info_form.first_name);
        }
        form.append("last_name", this.basic_info_form.last_name ? this.basic_info_form.last_name : '');

        form.append("gender", this.basic_info_form.gender ? this.basic_info_form.gender : '');

        form.append("dob", this.basic_info_form.dob ? this.basic_info_form.dob : '');

        form.append("blood_group", this.basic_info_form.blood_group ? this.basic_info_form.blood_group : '');

        form.append("location", this.basic_info_form.location ? this.basic_info_form.location : '');

        form.append("email", this.basic_info_form.email ? this.basic_info_form.email : '');

        form.append("phone_number", this.basic_info_form.phone_number ? this.basic_info_form.phone_number : '');
        if (this.basic_info_form.chronic_diseases.length > 0) {
          form.append("chronic_disease_ids", this.basic_info_form.chronic_diseases);
        }

        if (profile_image) {
          form.append("profile_img", profile_image);
        }
        axios
            .post(`${process.env.VUE_APP_URL}/api/v1/patient/profile/update/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}&_method=put`, form)
            .then(response => {

              if (response.data.status_code == 400) {
                return this.$snotify.error('Something worng');
              }
              this.$bvModal.hide('basicInfo')
              this.$emit('call_basic_info')
              this.resetModal();
              this.$snotify.success('Successfully updated');
            })
      })
    },
    editBasicInfo() {
      this.$bvModal.show('basicInfo');
      this.modal_loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.modal_loading = false
            this.basic_info_form = response.data.data;
            this.user_api_toke = response.data.data.api_token;
          })
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.saveDoctorBasicInfo()
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return '../../../public/media/users/blank.png'
      }
    },
    deleteAction(row) {
      this.$snotify.clear();
      this.$snotify.confirm(
          "You want to delete",
          "Are you sure?",
          {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: "Yes",
                action: toast => {
                  this.$snotify.remove(toast.id);
                  axios
                      .post(`${process.env.VUE_APP_URL}/api/v2/user/fnf/remove/${row.id}?user_id=${this.basic_info.id}&platform=backoffice&api_token=${localStorage.getItem('api_token')}`)
                      .then(response => {

                        if (response.data.status_code == 400) {
                          return this.$snotify.error(
                              response.data.message,
                              "Error"
                          );
                        }

                        this.$snotify.success(
                            "Successfully deleted",
                            "Success"
                        );
                        this.getFamily();

                      })
                      .catch(e => {
                        this.$snotify.error(
                            e
                        );
                      });
                },
                bold: true
              },
              {
                text: "No",
                action: toast => {
                  this.$snotify.remove(toast.id);
                },
                bold: true
              }
            ]
          }
      );
    },
    getInvoice(value) {
      this.loading = true;
      if (Number(value)) {
        this.invoice_current_page = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/invoices?api_token=${localStorage.getItem('api_token')}&patient_id=${this.$route.params.id}&limit=20&offset=${correctValue ? correctValue : 0}`).then(res => {
        this.invoices = res.data.data;
        this.total_invoice = res.data.total_count;
        this.loading = false;
      })
    },
    getAppointment(value) {
      this.loading = true;
      if (Number(value)) {
        this.appointment_current_page = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/appointment/patients/${this.$route.params.id}/list?api_token=${localStorage.getItem('api_token')}&limit=20&offset=${correctValue ? correctValue : 0}`).then(res => {
        this.appointments = res.data.data;
        this.total_appointment = res.data.total_count;
        this.loading = false;
      })
    },
    getPrescriptions(value) {
      if (Number(value)) {
        this.prescription_current_page = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v1/administrator/prescription/list?api_token=${localStorage.getItem('api_token')}&patient_id=${this.$route.params.id}&limit=20&offset=${correctValue ? correctValue : 0}`).then(res => {
        this.prescriptions = res.data.data.detail;
        this.total_prescription = res.data.data.total;
        this.loading = false;
      })
    },
    prescriptionUrl(url) {
      if (url) {
        return `${process.env.VUE_APP_API_URL}/${url}`;
      } else {
        return ''
      }
    },
  }
}
</script>

<style scoped>
.responsive {
  height: 80px;
  object-fit: cover;
  object-position: center;
}
</style>
