import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {dateFormate} from "../healper/dateTimeFormet";

export const SalesTeamLogMixin = {
    data() {
        return {
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            items: [],
            fields: [
                {
                    key: 'date',
                    label: 'Date',
                    sortable: true,
                },
                {
                    key: 'user_name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'login_time',
                    label: 'Login time',
                    sortable: true
                },

                {
                    key: 'login_address',
                    label: 'Login address',
                    sortable: true
                },
                {
                    key: 'logout_time',
                    label: 'Logout time',
                    sortable: true
                },
                {
                    key: 'logout_address',
                    label: 'Logout address',
                    sortable: true
                },
                {
                    key: 'total_activity',
                    label: 'Total activity',
                    sortable: true,
                },
            ],
            filter: null,
            filterOn: [],
            filters: {
                seller_name: '',
                seller_phone_number: '',
                phone_no: '',
                full_name: '',
                hospital_name: '',
                created_at: '',

            },
            show: true,
            base_url: process.env.VUE_APP_API_URL,
            start_date: dateFormate().weekly_first_day,
            end_date: dateFormate().weekly_last_day,
        }
    },
    created() {
        localStorage.setItem('tabIndex', 14)
        this.$root.$emit('call-active-menu', 14);
        this.salesTeamLog();
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Sales team log", route: "/sales/team/log"},
        ]);
    },

    methods: {
        salesTeamLog() {
            this.loading = true
            var url = `${process.env.VUE_APP_URL}/api/v2/administration/sales/log/report?api_token=${localStorage.getItem('api_token')}&platform=backoffice${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}${this.sales_user_id ? '&userid=' + this.sales_user_id : ''}`;
            axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        if (response.data.data) {
                            this.items = response.data.data;
                        } else {
                            this.items = [];
                        }
                        this.loading = false;
                    }
                })
        },

        exportSalesLog() {
            var url = `${process.env.VUE_APP_URL}/api/v2/administration/sales/log/report/export?api_token=${localStorage.getItem('api_token')}&platform=backoffice${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}${this.sales_user_id ? '&userid=' + this.sales_user_id : ''}`;

            axios.get(url, {responseType: 'blob'})
                .then(response => {
                    this.exportLink(response);
                })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },

        dateRangeClear() {
            this.startDate = '';
            this.endDate = ''
            this.start_date = null
            this.end_date = null;
            this.salesTeamLog();
            // this.getUser();
        },
    },

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })

            return filtered.length > 0 ? filtered : [{
                user_name: '',
                login_time: '',
                login_address: '',
                logout_time: '',
                logout_address: '',
                total_activity: '',
            }]
        },

    },
}
