<template>
  <div>
    <div class="container">
      <div class="row no-gutters">
        <div class="col-md-12">
          <accordion-item-component
              v-for="(item,index) in groups"
              :item="item"
              :key="index"
              :group-name="index"
              @toggled="onToggle"
              :is-open="activeItemIndex == index"
          >
          </accordion-item-component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    groups: {},
  },
  data() {
    return {
      activeItemIndex: null,
    }
  },
  methods: {
    onToggle(index) {
      if (this.activeItemIndex == index) {
        return this.activeItemIndex = null;
      }
      this.activeItemIndex = index
    }
  }
}
</script>
