<template>
  <div>
    <loader-component v-if="loading"/>

    <div class="card" v-else>
      <div class="card-body">
        <div class="text-right">
          <b-button v-b-modal.modal variant="primary" class="mb-3"><span class="pr-4"><i
              class="fas fa-user-plus"></i></span>Create a new user
          </b-button>
        </div>
        <b-modal
            id="modal"
            title="Create user"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleSave"
            size="lg">
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <label for="">Select role <span class="text-danger">*</span></label>
            <b-form-select
                v-model="form.role_id"
                class="mb-3"
                :state="roleState">
              <b-form-select-option
                  :value="item.id"
                  v-for="(item, index) in roles"
                  :key="index"
                  :state="roleState"
                  required>
                {{ item.name }}
              </b-form-select-option>
            </b-form-select>

            <b-form-group
                label-for="name-input"
                invalid-feedback="Name is required"
                :state="nameState"
            >
              <label for="">Name <span class="text-danger"> * </span></label>
              <b-form-input
                  id="name-input"
                  v-model="form.name"
                  :state="nameState"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                label-for="name-input"
                invalid-feedback="Email is required"
                :state="emailState"
            >
              <label for="">Email <span class="text-danger"> * </span></label>
              <b-form-input
                  id="name-input"
                  v-model="form.email"
                  :state="emailState"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                label-for="name-input"
                invalid-feedback="Phone number is required"
                :state="phone_numberState"
            >
              <label for="">Phone number <span class="text-danger"> * </span></label>
              <b-form-input
                  id="name-input"
                  v-model="form.phone_number"
                  :state="phone_numberState"
                  required
              ></b-form-input>
            </b-form-group>

            <b-form-group
                label-for="name-input"
                invalid-feedback="Password is required"
                :state="passwordState"
            >
              <label for="">Password <span class="text-danger"> * </span></label>
              <b-form-input
                  id="name-input"
                  type="password"
                  v-model="form.password"
                  :state="passwordState"
                  required
              ></b-form-input>
            </b-form-group>


          </form>
        </b-modal>
        <div>
          <div class="row justify-content-end">
            <div class="col-md-6">
              <b-form-group
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <b-table
              class="mt-3"
              striped hover
              :items="users"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              thead-class="bg-primary text-white"
              responsive
              @filtered="onFiltered">
            <template #cell(role)="row">
              <b-badge class="mr-1 m-1" variant="info" v-for="(role, index) in row.item.role" :key="index">
                {{ role.name }}
              </b-badge>
            </template>
            <template #cell(is_active)="row">
              <b-badge class="mr-1 m-1" variant="success" v-if="row.item.is_active">
                Active
              </b-badge>
              <b-badge class="mr-1 m-1" variant="danger" v-else>
                In active
              </b-badge>
            </template>
            <template #cell(actions)="row">


              <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                          class="m-2">

                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <div>
                  <b-dropdown-item href="#" @click="changePassword(row.item)" class="d-flex align-item-center"><span
                      class="pr-4"><i class="fas fa-lock-open"></i></span>Change password
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="userDetails(row.item.id)" class="d-flex align-item-center"><span
                      class="pr-4"><i class="fas fa-eye"></i></span>View
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="userActiveInactive(row.item)" class="d-flex align-item-center"><span
                      class="pr-4"><i class="far fa-user"></i></span> Make
                    {{ row.item.is_active == true ? 'Inactive' : 'Active' }}
                  </b-dropdown-item>
                </div>
              </b-dropdown>

            </template>
          </b-table>
          <div v-if="users.length > 20 ">
            <b-pagination
                v-model="currentPage"
                :total-rows="users.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="resetPassword" size="lg" title="Reset Password" hide-footer>
      <b-form @submit="resetPassword" @reset="onReset">
        <b-form-group
            id="input-group-1"
            label="Password * "
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="reset.password"
              type="password"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Action comment">

          <b-form-textarea
              id="textarea"
              class="mb-2"
              v-model="reset.comment"
              placeholder="Enter something..."
              rows="3"
          ></b-form-textarea>
          <span class="text-danger"><b>Note: </b>Provide a valid reason/comment here. No dummy or irrelevant content will be acceptable</span>
        </b-form-group>

        <b-form-group>
          <div>
            <p>N.B: Password should be unique.</p>
            <p>1. Do not use repeated password.</p>
            <p>2. Please set at least 6 character length password.</p>
            <p>3. Do not use same password for multiple users.</p>
          </div>
        </b-form-group>

        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>

import {UserMixin} from "../../mixins/UserMixin";
import {validationMixin} from "vuelidate";
import {ResponseMixin} from "../../mixins/core/ResponseMixin";

export default {
  name: "Index",
  mixins: [UserMixin, validationMixin, ResponseMixin],
  title: 'User',
}
</script>

<style scoped>
/*.d-flex {*/
/*  align-items: center;*/
/*}*/
</style>
