import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {ORKO_PROFESSION} from "../api/ApiUrl";

export const OrkoProfession = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
                "Details": {
                    field: "details",
                },
                "Created_date": {
                    field: "created_at",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },

                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Slug',
                    key: 'slug',
                    sortable: true,
                },
                {
                    label: 'Details',
                    key: 'details',
                    sortable: true,
                },
                {
                    label: 'Created Date',
                    key: 'created_at',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'action',
                    sortable: true,
                },

            ],
            currentPage: 0,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
                slug: '',
                details: '',
                is_active: '',
            },
            filter: null,
            filterOn: [],
            title_state: null,
            form: {
                title: '',
                details: '',
                is_active: '',
            },
            row_id: '',
            is_edit: false,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Orko profession", route: "/orko-profession"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getBankList();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Orko profession',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
                slug: '',
                details: '',
                is_active: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getBankList() {
            axios.get(ORKO_PROFESSION)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 0;
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.title_state = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.title_state = null;
            this.is_edit = false;
            this.row_id = '';
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if (this.is_edit) {
                return this.update();
            }
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            let form = new FormData();
            form.append("title", this.form.title);
            form.append("details", this.form.details);
            form.append("is_active", this.form.is_active);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/doctor/profession/store?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getBankList();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        editAction(row) {
            this.$bvModal.show("modal-1");
            this.form = row;
            this.is_edit = true;
            this.row_id = row.id;
        },
        update() {
            if (!this.checkFormValidity()) {
                return
            }
            let form = new FormData();
            form.append("title", this.form.title);
            form.append("details", this.form.details);
            form.append("is_active", this.form.is_active);


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/doctor/profession/update/${this.row_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getBankList();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully updated');
                    })
            })

        },
    }
}