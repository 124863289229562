<template>
  <div>
    <div class="row">
      <div :class="`${basic_info.is_doctor == 1 ? 'col-md-12 ' : 'col-md-12'}`">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-50 symbol-lg-120">

                  <img :src="currentUserPhoto(basic_info.image)" @click="showImage(basic_info.image)" alt="image"/>
                  <!--                  <img src="../../../assets/img/blank.png" alt="" v-else>-->
                  <i :class="basic_info.is_online === 1 ? ' symbol-badge is_active_icon' : 'is_inactive_icon'"
                     v-b-tooltip.hover.bottom="'Online'"></i>

                  <div class="mt-3">
                    <!--  Confirm doctor-->
                    <a href="#" class="font-weight-bold" v-if="basic_info.confirmed === true"
                       v-b-tooltip.hover.bottom="'Confirmed'"
                    ><i class="flaticon2-correct is_active font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15 is_active">Verified</span></a>
                    <a href="#" class="text-danger font-weight-bold" v-else v-b-tooltip.hover.bottom="'Not confirm'"
                    ><i class="flaticon2-correct is_inactive font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15">Not verified</span></a>
                  </div>
                  <!--     otp varified      -->
                  <div class="">
                    <!--  Confirm doctor-->
                    <a href="#" class="font-weight-bold" v-if="basic_info.otp_verified === true"
                       v-b-tooltip.hover.bottom="'OTP Verified'"
                    ><i class="flaticon2-correct is_active font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15 is_active">OTP Verified</span></a>
                    <a href="#" class="text-danger font-weight-bold" v-else
                       v-b-tooltip.hover.bottom="'Otp not varified'"
                    ><i class="flaticon2-correct is_inactive font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15">Otp not verified</span></a>
                  </div>

                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a
                        href="#"
                        class="text-dark-75  font-size-h5 font-weight-bold mr-3">
                      <span class="font-size-h3">{{ basic_info.fullname ? basic_info.fullname : 'Not set yet' }}</span>
                      <span v-b-tooltip.hover.bottom="'User type'"> ({{
                          basic_info.user_type ? basic_info.user_type : 'Not set yet'
                        }})</span>
                      <br>
                      <span v-if="basic_info.is_doctor" class="font-size-h6"> Card Intro : {{
                          basic_info.doctor_information.card_intro ? basic_info.doctor_information.card_intro : 'Not set yet'
                        }}</span>
                    </a>
                  </div>
                  <div class="my-lg-0 my-3">
                    <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"
                              v-b-modal.basicInfo @click="editBasicInfo">Edit profile
                    </b-button>
                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <!--                    <div class="d-flex flex-wrap mb-4">-->
                    <div>
                      <div class="row">
                        <div class="col-md-10">
                          <div class="d-flex flex-wrap mb-4">
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Location'"
                            ><i class="fas fa-map-marker-alt mr-2 font-size-lg"></i>
                              {{ basic_info.location ? basic_info.location : 'Not set yet' }} ,
                              <span><b>Town</b>: {{ basic_info.town_name ? basic_info.town_name + ' , ' : ' ' }}</span>
                              <span><b>Region</b>: {{ basic_info.region ? basic_info.region + ' , ' : ' ' }}</span>
                            </a>

                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Phone number'"
                            ><i class="flaticon2-phone mr-2 font-size-lg"></i>
                              {{ basic_info.phone_number ? basic_info.phone_number : 'Not set yet' }}
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
          </div>
        </div>
      </div>
    </div>
    <AideAddEditModal @reloadComponent="reloadComponent" v-if="add_edit_modal_open"/>
    <div class="row mb-7">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h2>Institute</h2>
            <h4>{{ basic_info.pharmacy_details ? basic_info.pharmacy_details.institute_info.name : '' }}</h4>
            <p class="mb-0">
              {{ basic_info.pharmacy_details ? basic_info.pharmacy_details.institute_info.address : '' }}</p>
            <p class="mb-0">
              {{ basic_info.pharmacy_details ? basic_info.pharmacy_details.institute_info.contact_number : '' }}</p>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import AideAddEditModal from "../../modal/AideAddEditModal";

export default {
  name: "AideBasicInfo",
  components: {
    AideAddEditModal,
  },
  props: {
    basic_info: {},
  },
  data() {
    return {
      add_edit_modal_open: false,
    }
  },

  methods: {
    currentUserPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
      return process.env.BASE_URL + 'media/logos/logo.svg';
    },
    editBasicInfo() {
      this.add_edit_modal_open = true;
    },
    reloadComponent(value) {
      if (value) {
        this.$emit('reload', value)
      }
      this.add_edit_modal_open = false;
    },
    showImage(img) {
      const h = this.$createElement
      // More complex structure
      const messageVNode = h('div', {class: ['foobar']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
            thumbnail: true,
            center: false,
          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        // title: [titleVNode],
        buttonSize: 'sm',
        centered: true, size: 'lg'
      })
    },
  }
}
</script>

<style scoped>

</style>
