<template>
<div>
  <h3>User trannsaction statement</h3>
</div>
</template>

<script>
export default {
name: "UserStatement"
}
</script>

<style scoped>

</style>