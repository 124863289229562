<template>
  <div>
    <div class="card-box">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-3">

          <label for="form">From</label>
          <input type="date" id="form" v-model="startDate" class="form-control">

        </div>
        <div class="col-md-3">
          <label for="to">To</label>
          <input type="date" id="to" v-model="endDate" class="form-control">
        </div>
        <div class="col-md-3">
          <button type="button" class="btn btn-dark mt-7" @click="getIncome">Filter</button>
          <button type="button" class="btn btn-danger ml-5 mt-7" @click="clearFilter">Clear</button>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-3" v-for="(item, index) in items" :key="index">
        <div class="card-box tilebox-one">
          <div>
            <div class="d-flex justify-content-between align-items-cente">
              <h6 class="text-muted text-uppercase m-b-20">{{ item.name }}</h6>
              <span class="h1 float-right text-primary">৳</span>
            </div>
            <div>
              <h1>{{ item.total_income }}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "CenterWiseIncome",
  data() {
    return {
      items: [],
      startDate: null,
      endDate: null,
    }
  },
  mounted() {
    this.getIncome();
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Center wise income", route: "/center-wise-income"},
    ]);
  },
  methods: {
    getIncome() {

      axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/income?api_token=${localStorage.getItem('api_token')}${this.startDate ? '&start_date=' + this.startDate : ''}${this.endDate ? '&end_date=' + this.endDate : ''}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.loading = false
              this.items = response.data.data
            }
          })
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
    clearFilter() {
      this.startDate = null;
      this.endDate = null;
      this.getIncome();
    },
  }
}
</script>

<style scoped>
.card-box {
  padding: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
}

.tilebox-one i {
  font-size: 30px;
}

.tilebox-two i {
  font-size: 48px;
  opacity: 0.2;
  margin-top: 20px;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.box-item {
  background: #f2f5f8;
  padding: 7px;
  border-radius: 7px;
  margin: 0px 5px;
}

.card-box {
  overflow: scroll;
}
</style>