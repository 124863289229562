import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const SpecialitiesMixins = {
    data() {
        return {
            json_fields: {
                "Name": {
                    field: "name"
                },
                "Slug": {
                    field: "slug"
                },
            },
            columns: [
                {
                    key: 'name',
                    label: 'Name',
                },
                {
                    key: 'slug',
                    label: 'Slug',
                },

            ],
            items: [],
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },

                {
                    key: 'icon',
                    label: 'Icon',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'slug',
                    label: 'Slug',
                    sortable: true
                },
                {
                    key: 'parent_speciality_name',
                    label: 'Parent',
                    sortable: true
                },
                {
                    key: 'short_title',
                    label: 'Short title',
                    sortable: true
                },
                {
                    key: 'verified_doctor_count',
                    label: 'Verified doctor count',
                    sortable: true
                },
                {
                    key: 'actions',
                    label: 'Actions',
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                name: '',
                slug: '',
            },
            filter: null,
            filterOn: [],
            nameState: null,
            details: '',
            form: {
                name: '',
                description: '',
                parent_id: '',
            },
            loading: true,
            is_edit: false,
            specialities_id: '',
            medical_service: [],
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Specialities", route: "/specialities"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getSpeialitiesist();
        this.getMedicalService();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Orko specialities',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                slug: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getMedicalService() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medical/services/list`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.medical_service = response.data.data;
                        this.loading = false;
                    }
                })
        },
        getSpeialitiesist() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/speciality?show_all=1&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {

                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.nameState = null;
            this.is_edit = false;
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            if (this.is_edit) {
                return this.update();
            }
            this.handleSubmit()
        },
        handleSubmit() {

            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("name", this.form.name);

            if (this.form.description) {
                form.append("description", this.form.description);
            }
            if (this.form.parent_id) {
                form.append("parent_id", this.form.parent_id);
            }


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/speciality/create?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getSpeialitiesist();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        editAction(row) {
            this.$bvModal.show("modal-1");
            this.form = row;
            this.specialities_id = row.id;
            this.is_edit = true;

        },
        update() {
            if (!this.checkFormValidity()) {
                return
            }
            let form = new FormData();
            form.append("name", this.form.name);
            if (this.form.description) {
                form.append("description", this.form.description);
            }
            if (this.form.parent_id) {
                form.append("parent_id", this.form.parent_id);
            }

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/speciality/update/${this.specialities_id}?api_token=${localStorage.getItem('api_token')}&_method=put`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getSpeialitiesist();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/speciality/delete/${row.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getSpeialitiesist();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        }
    }
}