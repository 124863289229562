<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div>
        <!--begin::Details-->
        <div class="d-flex">
          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <h3 class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3">
                  Experience Information
                </h3>

              </div>
              <div class="my-lg-0 my-3">
                <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"
                          v-b-modal.exprienceModal>Add
                </b-button>
                <b-modal
                    id="exprienceModal"
                    title="Add Exprience"
                    @close="resetModal"
                    size="lg"
                    @hidden="resetModal"
                    @ok="handleOk">
                  <form ref="form" @submit.stop.prevent="handleSubmit">
                    <div class="mb-3">
                      <label for="jobtitle">Job title <span class="text-danger">*</span></label>
                      <v-select
                          :options="jobtitle"
                          :reduce="jobtitle => jobtitle.id"
                          v-model="experiences_data.designation_id"
                          label="name"
                          class="h-100"
                          name="name"
                          id="jobtitle"
                          required
                          :state="jobtitleState">
                      </v-select>
                      <b-form-invalid-feedback :state="jobtitleState" v-if="!experiences_data.designation_id">
                        Job title is required
                      </b-form-invalid-feedback>
                    </div>
                    <div class="mb-3">
                      <b-form-group
                          label-for="name-input">
                        <label for="">Department <span class="text-danger">*</span> </label>
                        <v-select
                            :options="departments"
                            :reduce="departments => departments.id"
                            v-model="experiences_data.department_id"
                            label="name"
                            name="name"
                            @input="getUnits(); resetDepartmentUnit()"
                            :state="departmentsState">
                        </v-select>
                        <b-form-invalid-feedback :state="departmentsState" v-if="!experiences_data.department_id">
                          Department is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="mb-3">
                      <b-form-group
                          label-for="name-input">
                        <label>Units</label>
                        <v-select
                            :options="units"
                            :reduce="units => units.id"
                            v-model="experiences_data.department_unit_id"
                            label="name"
                            name="name"
                            :state="unitState">
                        </v-select>
                        <b-form-invalid-feedback :state="unitState" v-if="!experiences_data.department_unit_id">
                          Unit is required
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="row align-content-center align-self-center justify-content-between">
                      <div class="col-md-9">
                        <div class="mb-3">
                          <label for="">Institute <span class="text-danger">*</span></label>
                          <v-select
                              :options="institutes"
                              :reduce="institutes => institutes.id"
                              v-model="experiences_data.institute_id"
                              label="name"
                              name="name"
                              :state="instituteState">
                          </v-select>
                          <b-form-invalid-feedback :state="instituteState" v-if="!experiences_data.institute_id">
                            Institute is required
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                      <div class="col-md-3 align-content-center align-self-center">
                        <add-institute v-if="add_institute_modal_open" @hideInstituteModal="hideModal($event)"/>
                        <b-button variant="success" class="mt-3" @click="addNewInstitute">Add</b-button>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div class="row">
                        <div class="col-6">
                          <label for="">Joining Date <span class="text-danger">*</span></label>
                          <b-form-input type="date" v-model="experiences_data.start_date"
                                        :state="start_date_state" required></b-form-input>
                          <b-form-invalid-feedback :state="start_date_state" v-if="!experiences_data.start_date">
                            Joining date is required
                          </b-form-invalid-feedback>
                        </div>
                        <div class="col-6">
                          <label for="">End Date</label>
                          <b-form-input type="date" v-model="experiences_data.end_date"
                                        :disabled="is_current_end_date==1"></b-form-input>
                          <b-form-invalid-feedback :state="end_date_state" v-if="!experiences_data.is_current_job">
                            End date is required
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <b-form-checkbox size="lg" v-model="experiences_data.is_current_job"
                                       :disabled="is_current==1">Currently working here
                      </b-form-checkbox>
                    </div>
                  </form>
                </b-modal>
              </div>
            </div>
            <!--end::Title-->
            <!--begin::Content-->
            <div class="row mt-10" v-if="exepriences.length">
              <div class="col-md-6 mb-5" v-for="(exeprience, index) in exepriences" :key="index">
                <b-card class="" :img-src="exeprience.certificate_image ? exeprience.certificate_image :''"
                        img-left>
                  <div class="d-flex justify-content-end">
                    <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"
                              @click="editExperience(exeprience)">Edit
                    </b-button>
                  </div>

                  <b-card-text>
                    <h3>{{ exeprience.institute_name }}
                      ({{ exeprience.start_date }} - {{
                        exeprience.is_current_job == 0 ? exeprience.end_date : 'Current'
                      }}) </h3>

                    <p class="mb-2">Designation: {{
                        exeprience.designation ? exeprience.designation : 'Not yet set'
                      }} </p>
                    <p class="mb-2">Department: <b> {{
                        exeprience.department ? exeprience.department : 'Not yet set'
                      }} </b></p>
                    <p class="mb-2">Department unit name
                      <b>{{ exeprience.department_unit_name ? exeprience.department_unit_name : 'Not yet set' }}</b></p>
                  </b-card-text>
                </b-card>
              </div>
            </div>

            <div v-else> Not set yet</div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Exeprience",
  props: {
    exepriences: {},
    user: {},
  },

  data() {
    return {
      experiences_data: {
        department_id: '',
        institute_id: '',
        designation_id: '',
        start_date: '',
        end_date: null,
        department_unit_id: '',
        is_current_job: '',
        institute_name: '',
      },
      jobtitle: [],
      departments: [],
      institutes: [],
      units: [],
      departmentState: null,
      institute_data: null,
      select: [],
      checked2: '',
      other_institute: '',
      institute_name: 0,
      add_institute_modal_open: false,
      jobtitleState: null,
      departmentsState: null,
      unitState: null,
      instituteState: null,
      start_date_state: null,
      end_date_state: null,
      is_current: 0,
      is_current_end_date: 0,
    }
  },
  created() {
    this.getJobTitle();
    this.getDepartment();
    this.getInstitute();
  },
  methods: {
    resetModal() {
      this.departmentState = null
      this.experiences_data = {
        department_id: '',
        institute_id: '',
        designation_id: '',
        start_date: '',
        end_date: null,
        department_unit_id: '',
        is_current_job: '',
        institute_name: '',
      };
      this.jobtitleState = null;
      this.departmentsState = null;
      this.unitState = null;
      this.instituteState = null;
      this.start_date_state = null;
      this.end_date_state = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.jobtitleState = valid;
      this.departmentsState = valid;
      // this.unitState = valid;
      this.instituteState = valid;
      this.start_date_state = valid;
      if (!this.experiences_data.is_current_job) {
        this.end_date_state = valid;
      }
      return valid
    },

    handleSubmit() {
      if (!this.checkFormValidity()) {
        return
      }
      let form = new FormData();
      let url = "";
      if (this.experiences_data.hasOwnProperty("id")) {
        url = `${process.env.VUE_APP_URL}/api/v1/doctor/work-experience/update/${this.experiences_data.id}?_method=put&user_id=${this.user.id}&platform=backoffice&api_token=${localStorage.getItem('api_token')}`;
      } else {
        url = `${process.env.VUE_APP_URL}/api/v1/doctor/work-experience/add?api_token=${localStorage.getItem('api_token')}&platform=backoffice&user_id=${this.user.id}`;

        form.append("experiences[0][institute_id]", this.experiences_data.institute_id);
        form.append("experiences[0][designation_id]", this.experiences_data.designation_id);
        form.append("experiences[0][department_id]", this.experiences_data.department_id);
        form.append("experiences[0][start_date]", this.experiences_data.start_date);
        if (this.experiences_data.department_unit_id) {
          form.append("experiences[0][department_unit_id]", this.experiences_data.department_unit_id);
        }
        if (this.experiences_data.end_date && !this.experiences_data.is_current_job) {
          form.append("experiences[0][end_date]", this.experiences_data.end_date);
        }
        form.append("experiences[0][is_current_job]", this.experiences_data.is_current_job);
      }


      this.$nextTick(() => {

        axios
            .post(url, this.experiences_data.id ? this.experiences_data : form)
            .then(response => {
              if (response.data.status_code == 400) {
                return this.$snotify.error('Something worng');
              }
              this.resetModal();
              this.$emit('addNewExeprience', 'state change')
              this.$bvModal.hide('exprienceModal')
              this.$snotify.success('Exprience added Successfully saved');
            })
      })
    },
    getJobTitle() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/designation`)
          .then(response => {
            this.jobtitle = response.data.data;
          })
    },
    getDepartment() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/medical-department/list`)
          .then(response => {
            this.departments = response.data.data;
          })
    },
    getInstitute() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000`)
          .then(response => {
            this.institutes = response.data.data
          })
    },
    resetDepartmentUnit() {
      this.experiences_data.department_unit_id = '';
    },
    getUnits() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/medical-department/list?department_id=${this.experiences_data.department_id}`)
          .then(response => {
            this.units = response.data.data
          })
    },
    querySelections(v) {
      this.loading = true
      // Simulated ajax query
      setTimeout(() => {
        this.items = this.states.filter(e => {
          return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
        })
        this.loading = false
      }, 500)
    },
    instituteByDept() {
      this.institute_name = 1;
    },

    addNewInstitute() {
      this.add_institute_modal_open = true;
    },
    hideModal(hideInstituteModal) {
      this.add_institute_modal_open = hideInstituteModal;
      this.getInstitute();
    },
    editExperience(exprience) {
      this.experiences_data = exprience;
      this.experiences_data.designation_id = exprience.department_id;
      this.experiences_data.institute_id = exprience.institute_id
      this.experiences_data.department_unit_id = exprience.department_unit_id
      this.experiences_data.is_current_job = exprience.is_current_job == 1 ? true : false;

      this.getUnits();
      this.$bvModal.show('exprienceModal');
    }
  },
  watch: {
    institute_data(val) {
      val && this.querySelections(val)
    },
    "experiences_data.end_date": function (val) {
      if (val) {
        this.is_current = 1;
      } else {
        this.is_current = 0
      }
    },
    "experiences_data.is_current_job": function (val) {
      if (val) {
        this.is_current_end_date = 1;
      } else {
        this.is_current_end_date = 0
      }
    },
    // "experiences_data.department_id": function (newVal, oldVal) {
    //   if (newVal == oldVal) {
    //     console.log('new value called')
    //     this.experiences_data.department_unit_id = oldVal;
    //   }
    // }
  },
}
</script>

<style scoped>
span {
  /*line-height: 30px;*/
}
</style>
