<template>
  <div>

    <div class="card">
      <div class="card-body">
        <b-button @click="followUpExport" variant="success">Export</b-button>
        <div class="row mt-5 mb-5 align-items-center justify-content-between">
          <div class="col-md-3">
            <label for="">From</label>
            <input type="date" v-model="startDate" class="form-control">
          </div>
          <div class="col-md-3">
            <label for="">To</label>
            <input type="date" v-model="endDate" class="form-control">
          </div>
          <div class="col-md-3">
            <label for="">Select center</label>
            <b-form-select
                v-model="center_id"
                :options="centers"
                value-field="id"
                text-field="name">
            </b-form-select>
          </div>
          <div class="col-md-3">
            <b-button @click="getAppointmentList" variant="primary" class="mt-7 mx-10">Filter</b-button>
            <b-button @click="dateRangeClear" variant="danger" class="mt-7">Reload</b-button>
          </div>
        </div>
       
        <div class="row">
          <div class="col-md-12">
            <b-table
                responsive
                striped hover
                :items="items"
                :fields="fields"
                :filter="filter"
                :per-page="perPage"
                @filtered="onFiltered"
                thead-class="bg-primary text-white"
                class="mt-5"
                show-empty>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(doctor_name)="row">
                <div>
                  <b-media tag="li">
                    <template #aside>

                      <b-img v-if="row.item.doctor_image != '/storage/'" blank-color="#ccc"
                             :src="userPhoto(row.item.doctor_image)" width="80" thumbnail></b-img>
                      <b-img v-else blank blank-color="#ccc" width="80"></b-img>
                    </template>
                    <h5 class="mt-0 mb-1">{{ row.item.doctor_name }}</h5>
                    <p class="mb-0">{{ row.item.doctor_phone_number }}</p>
                  </b-media>
                </div>
                <div>
                </div>
              </template>

              <template #cell(patient_name)="row">
                <div>
                  <b-media tag="li">
                    <template #aside>

                      <b-img v-if="row.item.patient_image != '/storage/'" blank-color="#ccc"
                             :src="userPhoto(row.item.patient_image)" width="80" thumbnail></b-img>
                      <b-img v-else blank blank-color="#ccc" width="80"></b-img>
                    </template>
                    <h5 class="mt-0 mb-1">{{ row.item.patient_name }}</h5>
                    <p class="mb-0">{{ row.item.patient_phone_number }}</p>
                  </b-media>

                </div>
              </template>

              <template #cell(fnf_name)="row">
                <div>
                  <h5 class="mt-0 mb-1">{{ row.item.fnf_name }}</h5>
                  <p class="mb-0">{{ row.item.fnf_phone_number }}</p>
                </div>
              </template>

              <template #cell(follow_up_date)="row">
                <div>
                  <p>{{ row.item.follow_up_date }}</p>
                </div>
              </template>


              <template #cell(last_created_prescription)="row">
                <div>
                  <span>{{ row.item.last_created_prescription | moment("ddd, MMM Do YYYY, h:mm:ss a") }}</span>
                </div>
              </template>


            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="total_count"
                :per-page="perPage"
                size="lg"
                class="my-0"
                @change="handlePageChange"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
// import {APPOINTMENT_LIST} from "../../api/ApiUrl";
import {ExportMixin} from "./../../mixins/core/ExportMixin";
export default {
  name: "FollowUpList",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Follow up appointment list", route: "/follow-up-appointment-list"},
    ]);
  },
  mixins:[ExportMixin],
  title: 'Appointment Followup List',
  data() {
    return {
      loading: true,
      items: [],
      filter: null,
      fields: [
        {
          key: 'index',
          label: 'Sl',
          sortable: true,
        },
        {
          key: 'doctor_name',
          label: 'Doctor',
          sortable: true,
        },
        {
          key: 'patient_name',
          label: 'Patient',
          sortable: true,
        },
        // {
        //   key: 'fnf_name',
        //   label: 'FNF',
        //   sortable: true,
        // },
        {
          key: 'follow_up_date',
          label: 'Follow up date',
          sortable: true,
        },
        {
          key: 'last_created_prescription',
          label: 'Last created prescription',
          sortable: true,
        },
      ],
      currentPage: 1,
      totalItems: '',
      perPage: 20,
      startDate: '',
      endDate: '',
      total_count: 0,
      centers: [],
      center_id: null,

    }
  },
  created() {
    localStorage.setItem('tabIndex', 10)
    this.$root.$emit('call-active-menu', 10);
    this.getAppointmentList();
    this.centerList();
  },
  computed: {
    sortOptions() {
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {

      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const filtered = this.items.filter(item => {
        const itemDate = new Date(item.transaction_date)
        if (startDate && endDate) {
          return startDate <= itemDate && itemDate <= endDate;
        }
        if (startDate && !endDate) {
          return startDate <= itemDate;
        }
        if (!startDate && endDate) {
          return itemDate <= endDate;
        }
        return Object.keys(this.filters).every(key =>

            String(item[key]).includes(this.filters[key]))
      })
      return filtered.length;
    },

    filterItem() {

      let filterType = this.selectedType;
      let startDate = this.localizeDate(this.startDate);
      let endDate = this.localizeDate(this.endDate);

      const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
      return itemsByType
          .filter(item => {
            const itemDate = new Date(item.date)
            if (startDate && endDate) {
              return startDate <= itemDate && itemDate <= endDate;
            }
            if (startDate && !endDate) {
              return startDate <= itemDate;
            }
            if (!startDate && endDate) {
              return itemDate <= endDate;
            }
            return true;
          })
    }
  },
  methods: {
    getAppointmentList() {
      this.loading = true;
      axios
          .get(`${process.env.VUE_APP_URL}/api/v2/administrator/appointment/followup/list?api_token=${localStorage.getItem('api_token')}&followup_start_date=${this.startDate}&followup_end_date=${this.endDate}&limit=${this.perPage}${this.center_id ? '&institute_id='+this.center_id : ''}&offset=0`)
          .then(response => {
            this.items = response.data.data;
            this.total_count = response.data.total_count;
            this.loading = false;
          })
    },
    handlePageChange(value) {
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      this.loading = true;
      axios
          .get(`${process.env.VUE_APP_URL}/api/v2/administrator/appointment/followup/list?api_token=${localStorage.getItem('api_token')}&followup_start_date=${this.startDate}&followup_end_date=${this.endDate}${this.center_id ? '&institute_id='+this.center_id : ''}&limit=${this.perPage}&offset=${correctValue}`)
          .then(response => {
            this.items = response.data.data;
            this.total_count = response.data.total_count;
            this.loading = false;
          })
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return "../../assets/img/blank.png";
      }
    },
    sendInfo(row) {
      this.$bvModal.show('statusModal');
    },
    dateRangeClear() {
      this.startDate = '';
      this.endDate = ''
      this.getAppointmentList();
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    followUpExport(){
      
      var url = `${process.env.VUE_APP_URL}//api/v2/administrator/appointment/followup/list/export?api_token=${localStorage.getItem('api_token')}&followup_start_date=${this.startDate}&followup_end_date=${this.endDate}${this.center_id ? '&institute_id='+this.center_id : ''}`;
      axios.get(url, {responseType: 'blob'})
          .then(response => {
              this.exportLink(response)
      })
    },
    centerList() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=orko&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              // this.loading = false
              this.centers = response.data.data
            }
          })
    },
  }
}
</script>

<style scoped>

</style>