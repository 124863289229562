<template>
  <div class="row">
    <div class="col-md-12">
      <!--begin::Card-->
      <div class="card card-custom gutter-b">
        <div class="card-body">
          <div>
            <!--begin::Details-->
            <div class="d-flex">
              <!--begin::Info-->
              <div class="flex-grow-1">

                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <h3 class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3">
                      Certification
                    </h3>
                  </div>
                  <div class="my-lg-0 my-3">
                    <!--                            <a-->
                    <!--                                href="#"-->
                    <!--                                class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"-->
                    <!--                            >Add</a-->
                    <!--                            >-->
                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->

                <div class="row mt-10" v-if="courses.length">
                  <div class="col-md-6 mb-5" v-for="(course, index) in courses" :key="index">
                    <b-card class="">
                      <b-card-text>
                        <h3> {{ course.name ? course.name : 'Not yet set' }}
                          ({{ course.start_date }} - {{ course.is_active == 0 ? course.end_date : 'Present'}})</h3>

                        <p class="mb-2">Institute: <b>{{ course.institute ? course.institute : 'Not yet set' }} </b></p>
                        <p class="mb-2">Organization: <b>{{ course.organization ? course.organization : 'Not yet set' }} </b></p>
                        <p class="mb-2">Speciality: <b>{{ course.speciality ? course.speciality : 'Not yet set' }} </b></p>
                      </b-card-text>
                    </b-card>
                  </div>
                </div>
                <div v-else> Not set yet</div>
                <!--end::Content-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
          </div>

        </div>
        <!--        <div v-else>-->
        <!--          <b-spinner variant="primary" label="Spinning"></b-spinner>-->
        <!--        </div>-->
      </div>
      <!--end::Card-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Course",
  props: {
    courses: {},
  }
}
</script>

<style scoped>

</style>