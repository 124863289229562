<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h3>Medical Records</h3>
        <div class="row justify-content-between mt-5">
          <div class="col-md-2">
            <b-form-group
                class="mb-0">
              <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
              ></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form @submit="searchDoctor">
              <b-form-group
                  label-for="filter-input"
                  label-cols-sm="1"
                  label-align-sm="right"
                  label-size="lg"
                  class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"/>
                  <b-input-group-append>
                    <b-button variant="primary" type="submit" >search</b-button>
                  </b-input-group-append>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-form>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-md-12">
            <b-table
                responsive
                striped hover
                :items="medical_records.length ? filtered : medical_records"
                :fields="fields"
                :current-page="currentPage"
                :per-page="0"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty>


              <template #cell(fullname)="row">
                <div>
                  <b-media tag="li">
                    <template #aside>
                      <b-img v-if=" row.item.profile_image != '/storage/'" blank-color="#ccc"
                             :src="userPhoto(row.item.profile_image)" class="user_image" width="80" thumbnail></b-img>
                      <b-img v-else blank blank-color="#ccc" width="80"></b-img>
                    </template>
                    <h5 class="mt-0 mb-1"><router-link :to="`user/details/${row.item.patient_id}`">{{ row.item.fullname }}</router-link></h5>
                    <p class="mb-0">{{ row.item.email }}</p>
                    <p class="mb-0">{{ row.item.phone_number }}</p>
                  </b-media>
                </div>
              </template>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(url)="row">
                <div class="">
                  <b-img :src="userPhoto(row.item.url)" class="reports_img"
                         v-if="row.item.url" @click="showImage(row.item.url)" thumbnail ></b-img>
                  <img src="../../assets/img/blank.png" class="user_image" v-else/>
                </div>
              </template>

              <template #cell(type)="row">
                <div>
                  {{ row.item.type }}
                </div>
              </template>

              <template #cell(details)="row">
                <div>
                  {{ row.item.details ? row.item.details : 'n/a' }}
                </div>
              </template>

              <template #cell(prescription_identifier)="row">
                <div>
                  {{ row.item.prescription_identifier ? row.item.prescription_identifier : 'n/a' }}
                </div>
              </template>

              <template #cell(is_paid)="row">
                <div>
                  {{ row.item.is_paid ? row.item.is_paid : 'n/a' }}
                </div>
              </template>

              <template #cell(created_at)="row">
                <div>
                  <span>{{ row.item.created_at | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</span>

                </div>
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-button variant="primary" @click="sendInfo(row)" v-b-tooltip.hover title="Change type"><i
                      class="far fa-edit"></i></b-button>
                </div>
              </template>
            </b-table>

            <b-pagination
                v-model="currentPage"
                :total-rows="total_record"
                :per-page="perPage"
                @change="handlePageChange"
                size="lg"
                class="my-0"
            ></b-pagination>

            <b-modal
                id="changeStatusModal"
                ref="modal"
                title="Medical record change type"
                @show="resetModal"
                @hidden="resetModal"
                size="lg"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit">

                <div class="mb-3">
                  <label for="">Select type</label>
                  <b-form-group :state="typeRequired" invalid-feedback="Type is required">
                    <select name="" id="" class="form-control" v-model="form.type" :state="typeRequired" required>
                      <option value="">Select type</option>
                      <option value="report">Report</option>
                      <option value="prescription">Prescription</option>
                    </select>
                  </b-form-group>
                </div>

                <div class="mb-3">
                  <b-form-group
                      label="Enter comment"
                      label-for="name-input"
                      invalid-feedback="Comment is required"
                      :state="commentRequired">
                    <b-form-textarea
                        id="textarea"
                        v-model="form.comment"
                        placeholder="Enter something..."
                        rows="3"
                        :state="commentRequired"
                        @keydown.space="preventLeadingSpace"
                        required
                    ></b-form-textarea>
                  </b-form-group>
                </div>

              </form>
            </b-modal>

          </div>
        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {MedicalRecordMixin} from "../../mixins/MedicalRecordMixin";

export default {
  name: "MedicalRecord",
  mixins: [MedicalRecordMixin],
  title: 'Medical Record',
}
</script>
<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
.reports_img {
  height: 100px;
  width: 200px;
  object-position: center;
  object-fit: cover;
}
</style>