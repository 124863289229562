<template>
  <div>
    <div class="row">
      <div :class="`${basic_info.is_doctor == 1 ? 'col-md-12 ' : 'col-md-12'}`">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-50 symbol-lg-120">

                  <img :src="currentUserPhoto(basic_info.image)" @click="showImage(basic_info.image)" alt="image"/>
                  <!--                  <img src="../../../assets/img/blank.png" alt="" v-else>-->
                  <i :class="basic_info.is_online === 1 ? ' symbol-badge is_active_icon' : 'is_inactive_icon'"
                     v-b-tooltip.hover.bottom="'Online'"></i>

                  <div class="mt-3">
                    <!--  Confirm doctor-->
                    <a href="#" class="font-weight-bold" v-if="basic_info.confirmed === true"
                       v-b-tooltip.hover.bottom="'Confirmed'"
                    ><i class="flaticon2-correct is_active font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15 is_active">Verified</span></a>
                    <a href="#" class="text-danger font-weight-bold" v-else v-b-tooltip.hover.bottom="'Not confirm'"
                    ><i class="flaticon2-correct is_inactive font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15">Not verified</span></a>
                  </div>
                  <!--     otp varified      -->

                  <div class="">
                    <!--  Confirm doctor-->
                    <a href="#" class="font-weight-bold" v-if="basic_info.otp_verified === true"
                       v-b-tooltip.hover.bottom="'OTP Verified'"
                    ><i class="flaticon2-correct is_active font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15 is_active">OTP Verified</span></a>
                    <a href="#" class="text-danger font-weight-bold" v-else
                       v-b-tooltip.hover.bottom="'Otp not varified'"
                    ><i class="flaticon2-correct is_inactive font-size-h5 mr-2"></i
                    ><span class="font-weight-bold font-size-15">Otp not verified</span></a>
                  </div>

                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a
                        href="#"
                        class="text-dark-75  font-size-h5 font-weight-bold mr-3">
                      <span class="font-size-h3">{{ basic_info.fullname ? basic_info.fullname : 'Not set yet' }}</span>

                      <span v-b-tooltip.hover.bottom="'User type'"> ({{
                          basic_info.user_type ? basic_info.user_type : 'Not set yet'
                        }})</span>

                      <span v-b-tooltip.hover.bottom="'User sub type'"> ({{ basic_info.sub_type }})</span>

                      <br>
                      <span v-if="basic_info.is_doctor" class="font-size-h6"> Card Intro : {{
                          basic_info.doctor_information.card_intro ? basic_info.doctor_information.card_intro : 'Not set yet'
                        }}</span>

                    </a>


                  </div>
                  <div class="my-lg-0 my-3">
                    <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"

                              v-b-modal.basicInfo @click="editBasicInfo">Edit profile
                    </b-button>
                    <b-button class="btn btn-sm btn-light-info font-weight-bolder text-uppercase mr-3"

                              @click="changePhoneNumber">Change phone number
                    </b-button>
                    <b-modal
                        id="changePhoneNumber"
                        ref="modal"
                        title="Change phone number"
                        @show="resetModal"
                        @hidden="resetModal"
                        @ok="handleOkChangePhonenumber"
                    >
                      <form ref="form" @submit.stop.prevent="handleSubmitChangePhonenumber">
                        <b-form-group
                            label="Old phone number"
                            label-for="name-input"
                            invalid-feedback="Old phone number is required"
                            :state="oldPhoneNumber"
                        >
                          <b-form-input
                              id="name-input"
                              v-model="change_phone.old_phone_number"
                              :state="oldPhoneNumber"
                              required
                          ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="New phone number"
                            label-for="name-input"
                            invalid-feedback="Name is required"
                            :state="newPhoneNumber"
                        >
                          <b-form-input
                              id="name-input"
                              v-model="change_phone.new_phone_number"
                              :state="newPhoneNumber"
                              required
                          ></b-form-input>
                        </b-form-group>

                        <sm class="text-danger">Node: Using +880 </sm>
                      </form>
                    </b-modal>

                    <b-modal
                        id="basicInfo"
                        title="Basic info "
                        @hidden="resetModal"
                        size="lg"
                        @ok="handleOk"
                    >
                      <b-spinner variant="primary" label="Spinning" v-if="modal_loading"></b-spinner>
                      <form ref="form" @submit.stop.prevent="saveDoctorBasicInfo">

                        <div class="row">
                          <div class="col-4">
                            <b-form-group
                                label="First name"
                                label-for="firstname"
                                class="mb-0">
                              <b-form-input
                                  id="firstname"
                                  v-model="basic_info_form.first_name"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Last name"
                                label-for="lastname"
                                class="mb-0">
                              <b-form-input
                                  id="lastname"
                                  v-model="basic_info_form.last_name"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Email"
                                label-for="email"
                                class="mb-0">
                              <b-form-input
                                  id="email"
                                  v-model="basic_info_form.email"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-4">
                            <b-form-group
                                label="Phone number"
                                label-for="phone"
                                class="mb-0">
                              <b-form-input
                                  id="phone"
                                  v-model="basic_info_form.phone_number"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Gender"
                                label-for="gender"
                                class="mb-0">
                              <select name="" class="form-control" v-model="basic_info_form.gender">
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                              </select>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Date of birth"
                                label-for="dateofbirth"
                                class="mb-0">
                              <b-form-input
                                  type="date"
                                  id="dateofbirth"
                                  v-model="basic_info_form.date_of_birth"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>
                        <div class="row mt-5">
                          <div class="col-4">
                            <b-form-group
                                label="Blood group"
                                label-for="gender"
                                class="mb-0">
                              <select name="" id="gender" class="form-control" v-model="basic_info_form.blood_group">
                                <option value="A+">A(+)ve</option>
                                <option value="B+">B(+)ve</option>
                                <option value="O+">O(+)ve</option>
                                <option value="AB+">AB(+)ve</option>
                                <option value="A-">A(-)ve</option>
                                <option value="B-">B(-)ve</option>
                                <option value="O-">O(-)ve</option>
                                <option value="AB-">AB(-)ve</option>
                              </select>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="Location"
                                label-for="location"
                                class="mb-0">
                              <b-form-textarea
                                  id="location"
                                  v-model="basic_info_form.location"
                              ></b-form-textarea>
                            </b-form-group>
                          </div>
                          <div class="col-4">
                            <b-form-group
                                label="NID"
                                label-for="nid"
                                class="mb-0">
                              <b-form-input
                                  id="nid"
                              ></b-form-input>
                            </b-form-group>
                          </div>
                        </div>

                      </form>
                    </b-modal>
                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <!--                    <div class="d-flex flex-wrap mb-4">-->
                    <div>
                      <div class="row">
                        <div class="col-md-10">
                          <div class="d-flex flex-wrap mb-4">

                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Location'"
                            ><i class="fas fa-map-marker-alt mr-2 font-size-lg"></i>
                              {{ basic_info.location ? basic_info.location : 'Not set yet' }} ,
                              <span><b>Town</b>: {{ basic_info.town_name ? basic_info.town_name + ' , ' : ' ' }}</span>
                              <span><b>Region</b>: {{ basic_info.region ? basic_info.region + ' , ' : ' ' }}</span>
                            </a>

                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Phone number'"
                            ><i class="flaticon2-phone mr-2 font-size-lg"></i>
                              {{ basic_info.phone_number ? basic_info.phone_number : 'Not set yet' }}
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BasicInfo",
  props: {
    basic_info: {},
  },
  data() {
    return {
      loading: false,
      basic_data: {
        service_fee: {
          chat_fee: '',
          call_fee: '',
          visit_fee: '',
        },
        id_type: '',
        basic_info: '',
        id_image: '',
        id_no: '',
        bmdc_no: '',
        registration_year: '',
        authority_name: '',
        blood_group: '',
        registration_authority_id: '',
      },
      id_types: [
        {
          value: 'nid',
          text: 'NID',
        },
        {
          value: 'passport',
          text: 'Passport',
        },
      ],
      authority_names: [
        {
          value: '1' +
              '',
          text: 'BMDC',
        },
        {
          value: '2',
          text: 'DGHS',
        },
      ],

      status: '',
      modal_loading: false,
      blood: [
        {
          id: 'A(+)ve',
          name: 'A(+)ve',
        },
        {
          id: 'B(+)ve',
          name: 'B(+)ve',
        },
        {
          id: 'O(+)ve',
          name: 'O(+)ve',
        },
        {
          id: 'AB(+)ve',
          name: 'AB(+)ve',
        },
        {
          id: 'A(-)ve',
          name: 'A(-)ve',
        },
        {
          id: 'B(-)ve',
          name: 'B(-)ve',
        },
        {
          id: 'O(-)ve',
          name: 'O(-)ve',
        },
        {
          id: 'AB(-)ve',
          name: 'AB(-)ve',
        },
      ],
      basic_info_form: {
        first_name: '',
        last_name: '',
        gender: '',
        location: '',
        blood_group: '',
        phone_number: '',
        email: '',
        date_of_birth: ''
      },
      user_api_toke: '',
      change_phone: {
        new_phone_number: '',
        old_phone_number: '',
      },
      oldPhoneNumber: null,
      newPhoneNumber: null,
    }
  },

  methods: {
    currentUserPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
      return process.env.BASE_URL + 'media/logos/logo.svg';
    },

    resetModal() {
      // this.name = ''
      this.change_phone = {};
      this.departmentState = null
    },
    saveDoctorBasicInfo() {
      this.$nextTick(() => {
        let form = new FormData();
        form.append("first_name", this.basic_info_form.first_name);
        form.append("last_name", this.basic_info_form.last_name);
        form.append("gender", this.basic_info_form.gender);
        form.append("date_of_birth", this.basic_info_form.date_of_birth);
        form.append("blood_group", this.basic_info_form.blood_group);
        form.append("location", this.basic_info_form.location);
        form.append("email", this.basic_info_form.email);
        axios
            .post(`${process.env.VUE_APP_URL}/api/v1/patient/profile/update/${this.$route.params.id}?api_token=${this.user_api_toke}&_method=put`, form)
            .then(response => {
              if (response.data.status_code == 400) {
                return this.$snotify.error('Something worng');
              }
              this.$bvModal.hide('basicInfo');
              this.editBasicInfo();
              this.$emit('call_basic_info')
            })
      })
    },
    editBasicInfo() {
      this.$bvModal.show('basicInfo');
      this.modal_loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.modal_loading = false
            this.basic_info_form = response.data.data;
            this.user_api_toke = response.data.data.api_token;
          })
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.saveDoctorBasicInfo()
    },
    showImage(img) {
      const h = this.$createElement
      // Using HTML string
      // const titleVNode = h('div', {domProps: {innerHTML: 'Signature'}})


      // More complex structure
      const messageVNode = h('div', {class: ['foobar']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
            thumbnail: true,
            center: false,
          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        // title: [titleVNode],
        buttonSize: 'sm',
        centered: true, size: 'lg'
      })
    },
    changePhoneNumber() {
      this.$bvModal.show('changePhoneNumber');
    },
    handleOkChangePhonenumber(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmitChangePhonenumber()
    },
    handleSubmitChangePhonenumber() {
      // if (!this.checkValidationChangePhoneNumber) {
      //   return
      // }
      let form = new FormData();
      form.append('phone_number', this.change_phone.new_phone_number);
      form.append('current_phone_number', this.change_phone.old_phone_number);
      form.append('platform', 'backoffice');
      axios
          .post(`${process.env.VUE_APP_URL}/api/v2/user/profile/update-credential?user_id=${this.basic_info.id}&api_token=${localStorage.getItem('api_token')}&platform=backoffice`, form)
          .then(response => {
            if (response.data.status_code == 400) {
              return this.$snotify.error(response.data.message);
            }
            this.$emit('call_basic_info')
            this.resetModal();
            this.$bvModal.hide('changePhoneNumber')
            this.$snotify.success('Phone number changed successfully');
          })
    }
  }
}
</script>

<style scoped>

</style>
