<template>
  <div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <h3 class="mb-10">Notification list</h3>
              <b-list-group>
                <b-list-group-item
                    href="#"
                    class="flex-column align-items-start"
                    v-for="(notification, index) in notifications" :key="index">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1" v-html="notification.data.data"></h5>
                    <div>

                    </div>
                  </div>
                  <small>{{ notification.created_at }}</small>
                </b-list-group-item>

              </b-list-group>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "BackofficeNotificationList",
  data() {
    return {
      notifications: [],
    }
  },
  mounted() {
    this.getNotification();
  },
  methods: {
    getNotification() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/bo-notification/list?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            if (res.data.status_code == 400) {
              this.notifications = res.data.data;
            } else {
              this.notifications = res.data.data;
            }
          })
    },
  }
}
</script>

<style scoped>

</style>