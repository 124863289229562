<template>
  <div>
    <div>
      <div class="card mt-5">
        <div class="card-body">
          <div class="row mt-5 align-items-center justify-content-between">
            <div class="col-md-4">
              <label for="">From</label>
              <input type="date" v-model="start_from" class="form-control">
            </div>
            <div class="col-md-4">
              <label for="">To</label>
              <input type="date" v-model="end_to" class="form-control">
            </div>
            <div class="col-md-4">
              <b-button @click="getPrescription" variant="primary" class="mt-7 mx-3">Submit</b-button>
              <b-button @click="dateRangeClear" variant="danger" class="mt-7">Clear</b-button>
            </div>
          </div>

          <div class="mt-5">
            <span class="font-weight-bold">Search : </span>
            <span class="badge bg-primary mx-2 text-white">{{ start_from }}</span>
            -
            <span class="badge bg-primary text-white mx-2">{{ end_to }}</span>
          </div>

          <div v-if="expanded">
            <div class="row mt-5 justify-content-between">
              <div class="col-md-1">
                <div class="">
                  <b-form-group
                      class="mb-0">
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="col-md-7 text-center">
                <div>
                  <download-excel
                      :data="items"
                      class="btn btn-primary"
                      :fields="json_fields"
                      worksheet="Doctor wise prescription list"
                      :name="`${checkFileNameFormat(start_from, end_to, '_doctor_wise_prescription')}`">
                    <i class="fas fa-file-excel"></i> Export Excel
                  </download-excel>
                </div>
              </div>
              <div class="col-md-4">
                <b-form-group
                    label-for="filter-input"
                    label-cols-sm="1"
                    label-align-sm="right"
                    label-size="lg"
                    class="mb-0">
                  <b-input-group size="sm">
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"/>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                    </b-input-group-append>

                  </b-input-group>

                </b-form-group>
              </div>
            </div>
            <b-table
                responsive
                striped hover
                :items="items"
                :fields="fields"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">

              <template #cell(doctor_name)="row">
                <router-link :to="`/user/details/${row.item.doc_id}`"> {{ row.item.doctor ?  row.item.doctor.fullname : '-' }}</router-link>
                ({{ row.item.doctor.sub_type }})
              </template>

              <template #cell(doctor_phone)="row">
                {{ row.item.doctor ? row.item.doctor.phone_number : '-' }}
              </template>

              <template #cell(created_at)="row">

                {{ createdAtFormat(row.item.created_at) }}


              </template>

              <template #cell(num)="row">
                <div>
                  <router-link :to="`/doctor-prescription/${row.item.doc_id}?from_date=${start_from}&to_date=${end_to}`" class="btn btn-primary">{{
                      row.item.num
                    }}
                  </router-link>
                </div>
              </template>

            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DoctorWisePrescriptionMixin} from "../../mixins/DoctorWisePrescriptionMixin";
import {DateFormat} from "../../mixins/core/DateFormat";

export default {
  name: "DoctorWisePrescriptionList",
  mixins: [DoctorWisePrescriptionMixin,DateFormat],
  title: 'Doctor wise prescription list',
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.user_icon i {
  font-size: 10px;
}

.user_info li {

  margin: 5px 0px;
}

.user_info li i {
  padding: 4px 6px;
  font-size: 10px;
  color: #44A0FF;
  border-radius: 50%;
  background-color: rgba(230, 242, 255, 0.9);
  margin-right: 5px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>