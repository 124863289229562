import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const DynogensticCenterMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Name": {
                    field: "name"
                },
                "Institute type": {
                    field: "institute_type"
                },
                "Address": {
                    field: "address"
                },
                "Contact number": {
                    field: "contact_number"
                },

            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Name',
                    key: 'name',
                    sortable: true,
                },
                {
                    label: 'Contact number',
                    key: 'contact_number',
                    sortable: true,
                },
                {
                    label: 'Address',
                    key: 'address',
                    sortable: true,
                },

                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            strengthState: null,
            useForState: null,
            dosagesState: null,
            darState: null,
            unit_priceState: null,
            dosages_desc_typeState: null,
            form: {
                institute_id: '',
                investigation_id: '',
                name: '',
                parient_id: '',
                address: '',
                contact_number: '',
                institute_type_id: "60",
                details: '',
            },
            edit: false,
            edit_id: '',
            medicine_generics: [],
            medicine_company: [],
            manufacturers: [],
            loading: true,
            medicine_generics_is_required: '',
            medicine_company_required: '',
            price_formet: '',
            institutes: [],
            investigations: [],
            diagnostic_features: [
                {
                    price: '',
                    discount: '',
                    medical_test: '',
                }
            ],
            price: [],
            discount: [],
            title: null,
            medicalTest: [],
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Manage Diagnostic Center", route: "/manage-diagnostic"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 3)
        this.$root.$emit('call-active-menu', 3);
        this.getInstitute();
        this.getInvesgtigations();
        this.instituteType();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Medicine',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.institutes.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.institutes.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },

    methods: {

        getMedicalTest() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/diagnostic/medical-test/list?api_token=${localStorage.getItem('api_token')}&diagnostic_centre_id=${this.form.institute_id}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.medicalTest = response.data.data;
                        this.diagnostic_features = response.data.data;
                        response.data.data.map(item => {
                            this.diagnostic_features.medical_test_id = item.medical_test;
                        })

                    }
                })
        },
        instituteType() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute-type/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        response.data.data.map(item => {
                            if (item.slug == "diagnostic-centre") {
                                this.institute_type = response.data.data
                            }
                        })
                        // this.institute_type = response.data.data

                    }
                })
        },
        addInstitute() {
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();
            if (this.form.parent_id) {
                form.append("parent_id", this.form.parent_id);
            }
            form.append("name", this.form.name);
            if (this.form.address) {
                form.append("address", this.form.address);
            }
            if (this.form.contact_number) {
                form.append("contact_number", this.form.contact_number);
            }
            if (this.form.details) {
                form.append("details", this.form.details);
            }

            form.append("institute_type_id", "60");


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/institute/create?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getInstitute();
                        this.$bvModal.hide('modal-2')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        addGroup() {
            this.diagnostic_features.push({
                price: '',
                discount: '',
                medical_test: '',
            });
        },
        deleteGroup(index) {
            this.diagnostic_features.splice(index, 1)
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            this.unit_priceState = valid
            this.strengthState = valid
            this.dosagesState = valid
            this.useForState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.titleState = null;
            this.medicine_generics_is_required = null;
            this.medicine_company_required = null;
            this.titleState = null;
            this.strengthState = null;
            this.useForState = null;
            this.dosagesState = null;
            this.darState = null;
            this.unit_priceState = null;
            this.dosages_desc_typeState = null;
            this.edit = false;
            this.edit_id = '';
            this.title = null;
            this.diagnostic_features = [];
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler

            if (this.edit) {
                return this.updatedata();
            }
            this.handleSubmit()
        },
        handleInstitute(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler

            if (this.edit) {
                return this.updatedata();
            }
            this.addInstitute();
        },
        handleSubmit() {

            let form = new FormData();
            this.diagnostic_features.map(item => {
                this.price.push(item.price);
            });
            this.diagnostic_features.map(item => {
                this.discount.push(item.discount);
            });

            this.diagnostic_features.medical_test.map(item => {
                this.medicalTest.push(item.id);
            })

            this.diagnostic_features.map((item, index) => {

                form.append(`medical_test[${index}][medical_test_id]`, item.medical_test.id);
                form.append(`medical_test[${index}][price]`, item.price);
                form.append(`medical_test[${index}][discount]`, item.discount);
            })

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/diagnostic/medical-test/add?api_token=${localStorage.getItem('api_token')}&institute_id=${this.form.institute_id}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicine();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        updatedata() {

            let form = new FormData();
            this.diagnostic_features.map((item, index) => {

                if (typeof item.medical_test === 'object') {
                    form.append(`medical_test[${index}][medical_test_id]`, item.medical_test.id);

                } else {

                    form.append(`medical_test[${index}][medical_test_id]`, item.medical_test);
                }
                form.append(`medical_test[${index}][price]`, item.price);
                form.append(`medical_test[${index}][discount]`, item.discount);
            })

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/diagnostic/medical-test/add?api_token=${localStorage.getItem('api_token')}&institute_id=${this.form.institute_id}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getInstitute();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },

        getInstitute() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?type=diagnostic&limit=7000`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.institutes = response.data.data
                    }
                })
        },
        getInvesgtigations() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medical-test/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.investigations = response.data.data;
                        this.loading = false;
                    }
                })
        },

        editAction(row) {
            this.form.institute_id = row.item.id;
            this.getMedicalTest();
            this.$bvModal.show('modal-1');
            this.edit = true;
            this.edit_id = row.item.id;
            this.form = row.item;
            this.form.institute_id = row.item.id


        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/medicine/remove/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getMedicine();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        isNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        }

    }
}