<template>
  <div class="card">
    <div class="card-body">
      <div>
        <h1>{{ details.title }} <span class="dosages_desc_type">{{ details.dosages_desc_type }}</span></h1>
      </div>
      <div class="mt-5">
        <h3 class="text-info">{{ details.medicine_generics ? details.medicine_generics.title : '' }}</h3>
        <h5>{{ details.strength }}</h5>
        <h5>{{ details.manufacturer ? details.manufacturer.title : '' }}</h5>
      </div>
      <div class="mt-5">
        <h5>Unit Price: {{ details.unit_price }}</h5>
      </div>

      <div class="mt-10">
        <div class="heading-bar">
          <h3 class="mb-0">Indications</h3>
        </div>
        <div class="p-5">
          <p>{{ details.medicine_generics.indications ? details.medicine_generics.indications : 'n/a' }}</p>
        </div>
      </div>

      <div class="mt-10">
        <div class="heading-bar">
          <h3 class="mb-0">Dosage & Administration</h3>
        </div>
        <div class="p-5">
          <p>{{ details.use_for ? details.use_for : 'n/a' }}</p>
        </div>
      </div>

      <div class="mt-10">
        <div class="heading-bar">
          <h3 class="mb-0">Interaction</h3>
        </div>
        <div class="p-5">
          <p>{{ details.medicine_generics ? details.medicine_generics.interaction : 'n/a' }}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MedicineDetails",
  data() {
    return {
      details: {},
    }
  },
  mounted() {
    this.getMedicineDetails();
  },
  created() {
    localStorage.setItem('tabIndex', 11)
    this.$root.$emit('call-active-menu', 11);
  },
  methods: {
    getMedicineDetails() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/show/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.details = response.data.data;
            }
          })
    }
  }
}
</script>

<style scoped>
.dosages_desc_type {
  font-size: 15px;
  font-weight: 300;
}

.heading-bar {
  background: #07589d;
  padding: 10px;
  color: #ffffff;
}
</style>