<template>
  <div>
    <div>
      <div class="card">
        <div class="card-body">
          <!-- buttton -->
          <div class="d-flex justify-content-end">
            <div class="mx-3">
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Orko Transaction Reprort"
                  name="Orko Transaction Reprort">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
            <div>
              <button class="btn btn-danger" @click="clearDate">Clear</button>
            </div>

            <div class="mx-3">
              <b-button @click="getOrkoTransactionReport()" variant="info" class=""><i
                  class="fas fa-redo-alt"></i></b-button>
            </div>
          </div>

          <div class="row align-items-center justify-content-between">
            <div class="col-md-1 mt-10">
              <div class="">
                <b-form-group
                    class="mb-0">
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="col-md-4  mt-10">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
            <div class="col-md-3">
              <label for="">From</label>
              <input type="date" v-model="startDate" class="form-control">
            </div>
            <div class="col-md-3">
              <label for="">To</label>
              <input type="date" v-model="endDate" class="form-control">
            </div>
          </div>

          <b-table
              striped hover
              :items="filtered"
              :fields="fields"
              :current-page="currentPage"
              :per-page="0"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              thead-class="bg-primary text-white"
              class="table-responsive mt-5">

            <template slot="top-row" slot-scope="{ fields }">
              <td v-for="field in fields" :key="field.key">
                <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
              </td>
            </template>

            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(is_paid)="data">
              <div>
                <b-badge v-if="data.item.is_paid == 'paid'" variant="success">{{ data.item.is_paid }}</b-badge>
                <b-badge v-else-if="data.item.is_paid == 'unpaid'" variant="danger">{{ data.item.is_paid }}</b-badge>
                <b-badge v-else variant="warning">{{ data.item.is_paid }}</b-badge>
              </div>
            </template>

            <template #cell(service_description)="data">
              <div v-if="data.item.service_description">
                <p v-html="data.item.service_description"></p>
              </div>
              <div v-else>
                n/a
              </div>
            </template>

            <template #cell(service_type)="data">
              <div v-if="data.item.service_type">
                <p v-html="data.item.service_type"></p>
              </div>
              <div v-else>
                n/a
              </div>
            </template>

          </b-table>
          <div class="description found"> Total : {{ items.length }}</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              @change="handlePageChange"
              size="lg"
              class="my-0"
          ></b-pagination>
        </div>
      </div>
      <!-- Report Wizard Start -->
      <div class="mt-20">
        <h2 class="mb-4">Reports</h2>
        <div class="row gutter-b">
          <!--begin::Item-->
          <div class="col-3">
            <a href="#"
               class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5 orko-element-bg-white"
               style="background: #fff;">
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Shopping/Euro.svg"/>
                <!--end::Svg Icon-->
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">Paid Amount</span>
              <span class="d-block font-weight-bold font-size-h6 mt-2"> {{ paid_amount }} </span>
            </a>
          </div>
          <div class="col-3">
            <a href="#"
               class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5 orko-element-bg-white"
               style="background: #fff;">
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <!--begin::Svg Icon-->
                <inline-svg src="media/svg/icons/Shopping/Euro.svg"/>
                <!--end::Svg Icon-->
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">Transaction amount</span>
              <span class="d-block font-weight-bold font-size-h6 mt-2"> {{ trxn_amount_sum }} </span>
            </a>
          </div>
          <!--end::Item-->
        </div>
      </div>
      <!--    Report Wizard End   -->
    </div>
    <div>
      <loader-component v-if="loading"/>

    </div>
  </div>
</template>

<script>

import {OrkoTransactionReport} from "../../mixins/reports/OrkoTransactionReportMixin";

export default {
  name: "OrkoTransactionReport",
  mixins: [OrkoTransactionReport],
  title: 'Transaction Report',
}
</script>

<style scoped>

</style>