<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Patient due list</h2>
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="Patient Withdrawal List">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="table-responsive mt-10"
                v-model="visibleRows"
                no-footer-sorting>

              <template #cell(index)="data" v-if="items.length">
                {{ data.index + 1 }}
              </template>

              <template #cell(total_payable)="data">
                <h4 class="mb-0">{{ data.item.total_payable }}</h4>
              </template>

              <template #cell(withdrawal_wallet_info)="data">
                <div v-if="data.item.withdrawal_wallet_info == 0">
                  <b-badge variant="danger"> Pending </b-badge>
                </div>
                <div v-else>
                  <b-badge variant="success"> {{ data.item.withdrawal_wallet_info ?  data.item.withdrawal_wallet_info.status : 'n/a'}} </b-badge>
                </div>
              </template>

              <template #cell(appointment_type)="data">
                <p class="mb-0" v-if="data.item.appointment_type">{{ underScoreToSpace(data.item.appointment_type) }}</p>
              </template>

              <template #cell(disbursed_amount)="data" v-if="items.length">
                <h4 class="mb-0" v-if="data.item.disbursed_amount">
                  {{ data.item.disbursed_amount[0] ? data.item.disbursed_amount[0].total_amount : '0' }}
                </h4>
              </template>

              <template #cell(total_amount)="data">
                <h4 class="mb-0" v-if="data.item.disbursed_amount">
                  {{
                    Number(data.item.total_payable) - Number(data.item.disbursed_amount ? data.item.disbursed_amount[0].total_amount : 0)
                  }}
                </h4>
              </template>

              <template slot="top-row" v-if="items.length" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions' || field.key == 'request_status'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>

              <template #cell(patient)="row">
                <div class="">
                  <b-media tag="li" class="d-flex justify-content-center align-items-center">
                    <template #aside>
                      <div v-if="row.item.patient_image">

                        <b-img
                            v-if="!imgError"
                            :src="userPhoto(row.item.patient_image ? row.item.patient_image : '' )"
                            class="user_image" @error="onImgError()">
                        </b-img>

                        <img src="../../assets/img/blank.png" class="user_image" v-else>

                      </div>
                    </template>

                    <router-link :to="`/user/details/${row.item.patient_id}`" class="mb-0 font-weight-bold p">
                      {{ row.item.patient_fullname }}
                    </router-link>
                    <p class="mb-0 text-muted">{{ row.item.patient_phone_number }}</p>

                  </b-media>
                </div>
              </template>


              <template #cell(actions)="row" v-if="items.length">
                <div>
                  <b-dropdown variant="primary" class="m-2" no-caret id="dropdown-dropleft" dropleft
                              text="Drop-Left">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>

                      <b-dropdown-item href="#">
                        <span class="pr-4"><i class="fas fa-eye"></i></span>View details
                      </b-dropdown-item>

                      <b-dropdown-item href="#" @click="sendInfo(row.item)" v-if="row.item.withdrawal_wallet_info.length == 0">
                        <span class="pr-4"><i class="fas fa-money-bill-alt"></i></span>Disburse
                      </b-dropdown-item>

                    </div>
                  </b-dropdown>
                </div>
              </template>

              <!--              &lt;!&ndash;  Footer &ndash;&gt;-->

              <!--              <template #foot(total_payable)>-->
              <!--                <h4 class="text-danger">{{ fTotalPayable }}</h4>-->
              <!--              </template>-->
              <!--              <template #foot(disbursed_amount)>-->
              <!--                <h4 class="text-danger">{{ fTotalDisbursed }}</h4>-->
              <!--              </template>-->
              <!--              <template #foot(total_amount)>-->
              <!--                <h4 class="text-danger">{{ fTotal }}</h4>-->
              <!--              </template>-->
              <!--              <template #foot(actions)>-->
              <!--                <h4 class="text-danger"></h4>-->
              <!--              </template>-->
              <!--              <template #foot(patient)>-->
              <!--                <h4 class="text-danger"></h4>-->
              <!--              </template>-->
              <!--              <template #foot(index)>-->
              <!--                <h4 class="text-danger"></h4>-->
              <!--              </template>-->

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12" v-if="items.length">
            <b-pagination
                v-if="items.length"
                v-model="currentPage"
                :per-page="perPage"
                size="lg"
                :total-rows="items.length"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <!-- modal for disburse -->
    <b-modal id="modal-disburse" size="lg" title="Please provide transaction details" hide-footer>
      <b-form @submit="disburseRequest" @reset="resetModal">

        <b-form-group
            id="input-group-1"
            label="Amount *"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="disburse.amount"
              type="text"
              required
              disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Transaction type">
          <b-form-select v-model="disburse.transaction_type" :options="transaction_type" required></b-form-select>
        </b-form-group>

        <b-form-group
            id="input-group-1"
            :label="`${disburse.transaction_type}`"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="disburse.banking_name"
              type="text"
              required
              :placeholder="`${disburse.transaction_type == 'Mobile Banking' ? 'Mobile Banking Name' : 'Bank Name , Branch Name'}`"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-1"
            label="Transaction id *"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="disburse.transaction_id"
              type="text"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Action comment *">
          <b-form-textarea
              id="textarea"
              v-model="disburse.comment"
              placeholder="Enter something..."
              rows="3"
              required
          ></b-form-textarea>
        </b-form-group>


        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>

    <!-- modal for bank account form -->
    <b-modal id="open-back-account-form" size="lg" title="Add bank account" hide-footer>
      <b-form @submit="accountCreate" @reset="resetModal">
        <div class="mb-8">
          <label>Select bank type</label>
          <v-select
              :options="back_type"
              :reduce="back_type => back_type.bank_account_type_id"
              v-model="account_info.bank_account_type_id"
              label="title">
          </v-select>
        </div>

        <b-form-group
            id="input-group-1"
            label="Account name *"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="account_info.account_name"
              type="text"
              required
          ></b-form-input>
        </b-form-group>

        <b-form-group
            id="input-group-1"
            label="Account number *"
            label-for="input-1">
          <b-form-input
              id="input-1"
              v-model="account_info.account_number"
              type="text"
              required
          ></b-form-input>
        </b-form-group>


        <div v-if="account_info.bank_account_type_id ==1">
          <div class="mb-8">
            <label>Select bank</label>
            <v-select
                :options="back_list"
                :reduce="back_list => back_list.id"
                v-model="account_info.bank_id"
                label="bank_title">
            </v-select>
          </div>


          <b-form-group
              id="input-group-1"
              label="Branch name *"
              label-for="input-1">
            <b-form-input
                id="input-1"
                v-model="account_info.branch_name"
                type="text"
                required
            ></b-form-input>
          </b-form-group>

        </div>
        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>

    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {PatientWithdrawalListMixin} from "../../mixins/payment/PatientWithdrawalListMixin";

export default {
  name: "PatientWithDrawalList",
  mixins: [PatientWithdrawalListMixin],
  title: 'Payment dua list',
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.user_icon i {
  font-size: 10px;
}

.user_info li {

  margin: 5px 0px;
}

.user_info li i {
  padding: 4px 6px;
  font-size: 10px;
  color: #44A0FF;
  border-radius: 50%;
  background-color: rgba(230, 242, 255, 0.9);
  margin-right: 5px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.user_image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  transition: .5s;
  object-position: center;
  object-fit: inherit;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}

.radio-group .radio:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.radio-group .radio:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.radio-group .radio {
  position: relative;
}

.radio input {
  visibility: hidden;
  position: absolute;

}

.radio label:hover {
  cursor: pointer;
}

.radio input:checked + label {
  border-color: #38C172;
  background-color: #E3FCEC;
  margin-bottom: 0px;
}
</style>