import axios from "axios";
import {underScoreToSpace} from "../healper/nameFormating";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import moment from "moment";

export const DoctorWisePrescriptionMixin = {
    data() {
        return {
            expanded: false,
            text: "View details.",
            expanded_icon: false,
            totalItems: '',
            items: [],
            loading: true,
            fields: [
                {
                    label: 'Doctor name',
                    key: 'doctor_name',
                    sortable: true,
                },
                {
                    label: 'Doctor phone number',
                    key: 'doctor_phone',
                    sortable: true,
                },
                {
                    label: 'Prescription num',
                    key: 'num',
                    sortable: true,
                },
                {
                    label: 'Created date',
                    key: 'created_at',
                    sortable: true,
                },

            ],
            json_fields: {
                "Created date": {
                    field: 'created_at'
                },
                "Doctor name": {
                    field: 'doctor',
                    callback: (value) => {
                        return `${value.fullname}`;
                    },
                },
                "Doctor phone number": {
                    field: 'doctor',
                    callback: (value) => {
                        return `'${value.phone_number}`;
                    },
                },
                "Total prescription": {
                    field: 'num',
                },

            },
            filter: null,
            filterOn: [],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                created_date: '',
                patient_details: '',
                doctor_details: '',
                prescription_identifier: '',
                is_paid: '',
                status: '',
            },
            startDate: null,
            endDate: null,
            counter: null,
            visible: true,
            pagination: {
                current_page: 1
            },
            show: true,
            user_id: '',
            form: {},
            prescription_details: {},
            exitImage: false,
            start_from: null,
            end_to: null,

        }
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Doctor wise prescription list", route: "doctor-wise-prescription-list"},
        ]);

        this.getPrescription();
    },
    created() {
        localStorage.setItem('tabIndex', 6)
        this.$root.$emit('call-active-menu', 6);
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.created_at)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                created_date: '',
                patient_details: '',
                doctor_details: '',
                prescription_identifier: '',
                is_paid: '',
                status: '',
            }]
        },
        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.created_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getPrescription() {
            if (this.start_from == null && this.end_to == null) {
                this.start_from = moment().startOf('month').format('YYYY-MM-DD');
                this.end_to = moment().endOf('month').format('YYYY-MM-DD');
            }

            let form = new FormData();
            if (this.start_from) {
                form.append('start_from', this.start_from);
            }
            if (this.end_to) {
                form.append('end_to', this.end_to);
            }


            axios.post(`${process.env.VUE_APP_URL}/api/v2/prescription/doctor/list?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.expanded = true;
                        this.items = response.data.data
                        this.loading = false;
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return process.env.BASE_URL + 'media/users/blank.png';
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateRangeClear() {
            this.startDate = '';
            this.endDate = '';
            this.start_from = '';
            this.end_to = '';
            this.getPrescription();
        },
        prescription_url(url) {
            if (url) {
                return `${process.env.VUE_APP_API_URL}/${url}`;
            } else {
                return ''
            }
        },
        underScoreToSpace,
        toggleView() {
            this.expanded = !this.expanded;

            if (this.expanded) {
                this.text = "Hide details.";
                this.expanded_icon = true

            } else {
                this.text = "View details.";
                this.expanded_icon = false;
            }
        },
        exportAsExcel() {
            const format = 'xlsx'
            const exportSelectedOnly = true
            const filename = 'test'
            this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
        },
        sendInfo(item) {
            this.prescription_details = item;
        },
        invalidPrescription() {

        },
        onReset(event) {
            event.preventDefault()
        },
        handlePageChange(value) {
            this.page = value;
            let offset = 0;
            if (this.currentPage > 1) {
                offset = this.page * this.perPage

            }
            axios.get(`${process.env.VUE_APP_URL}/api/v1/administrator/prescription/list?api_token=${localStorage.getItem('api_token')}&limit=100&offset=${offset}`)
                .then(response => {
                    this.items = response.data.data.detail
                    this.totalItems = response.data.data.counter.total_count
                    this.loading = false;
                })

        },
        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
    },

}