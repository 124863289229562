<template>
  <div>
    <div class="row" v-if="!message">
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <h5>Sales Activity Information</h5>
            <hr>
            <div class="row" >
              <div class="col-md-4 mb-5">
                <ValueWithSubTitle sub_title="Doctor name" :item="item.full_name"></ValueWithSubTitle>
              </div>
              <div class="col-md-4">
                <ValueWithSubTitle sub_title="Phone number" :item="item.phone_no"/>
              </div>
              <div class="col-md-4" v-if="item.designation">
                <ValueWithSubTitle sub_title="Designation" :item="item.designation"/>
              </div>
              <div class="col-md-4 mb-5" v-if="item.bmdc_reg_no">
                <ValueWithSubTitle sub_title="BMDC" :item="item.bmdc_reg_no"/>
              </div>
              <div class="col-md-4 mb-5" v-if="item.doctor_type">
                <ValueWithSubTitle sub_title="Doctor type" :item="item.doctor_type"/>
              </div>
              <div class="col-md-4 mb-5" v-if="item.speciality && item.speciality !== 'null'">
                <ValueWithSubTitle sub_title="Speciality" :item="item.speciality"/>
              </div>
              <div class="col-md-4 mb-5">
                <ValueWithSubTitle sub_title="Visit fee" :item="item.visit_fee ? item.visit_fee : 'n/a'"/>
              </div>
              <div class="col-md-4 mb-5">
                <ValueWithSubTitle sub_title="Virtual fee" :item="item.virtual_fee ? item.virtual_fee : 'n/a' "/>
              </div>
              <div class="col-md-4 mb-5">
                <ValueWithSubTitle sub_title="Followup fee" :item="item.followup_fee ? item.followup_fee : 'n/a'"/>
              </div>
              <div class="col-md-4 mb-5">
                <ValueWithSubTitle sub_title="Report fee" :item="item.report_fee ? item.report_fee : 'n/a'"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4" v-if="item.last_activity_call">
        <div class="card">
          <div class="card-body">
            <h5>Sales call Information</h5>
            <hr>
            <div class="">
              <ValueWithSubTitle sub_title="Chamber name" :item="item.last_activity_call.chamber_name"/>
              <ValueWithSubTitle v-if="item.last_activity_call.log_address" sub_title="Address"
                                 :item="item.last_activity_call.log_address"/>
              <p>Slot duration :
                <b-badge variant="success">{{ item.last_activity_call.slot_duration }}</b-badge>
              </p>
              <b-badge variant="success">{{ item.last_activity_call.start_time }}</b-badge>
              To
              <b-badge variant="success">{{ item.last_activity_call.end_time }}</b-badge>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-else>
      <div class="card">
        <div class="card-body">
          <h4 class="text-center">{{ message }}</h4>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import ValueWithSubTitle from "../../reusable-component/ValueWithSubTitle";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "DoctorSalesActivityCall",
  components: {
    ValueWithSubTitle,
  },
  data() {
    return {
      item: {},
      message: '',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales activity & Call", route: "/sales/activity/call/by/Doctor"},
    ]);
  },
  created() {
    this.getActivityCall();
  },
  methods: {
    getActivityCall() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/sales/team/activity/calls/by-doctor?api_token=${localStorage.getItem('api_token')}&phone_number=+${this.$route.query.phone_number}`).then(res => {
        if(res.data.status_code == 200 ){
          this.item = res.data.data
        }else {
          this.message = 'No Data Found';
        }

      })
    }
  }
}
</script>

<style scoped>
.sub_title {
  color: #313131 !important;
  font-size: 10px !important;
}
</style>
