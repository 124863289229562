<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h3>Notification List</h3>
        <div>
          <br>
          <div class="row align-items-center">
            <div class="col-md-2">
              <div class="">
                <b-form-group
                    class="mb-0">
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="col-md-2">
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Notification  list"
                  name="Notification  list">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
            <div class="col-md-4">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
            <b-col lg="4">
              <b-form-group
                  class="mb-0"
                  v-slot="{ ariaDescribedby }">
                <b-input-group size="sm">
                  <b-form-select
                      id="sort-by-select"
                      v-model="sortBy"
                      :options="sortOptions"
                      :aria-describedby="ariaDescribedby"
                      class="w-75">
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>

                  <b-form-select
                      v-model="sortDesc"
                      :disabled="!sortBy"
                      :aria-describedby="ariaDescribedby"
                      size="sm"
                      variant="primary"
                      class="w-25">
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
          </div>

          <b-table
              striped hover responsive
              :items="filtered"
              :fields="fields"
              :current-page="currentPage"
              :per-page="0"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              thead-class="bg-primary text-white"
              class="mt-5"
              id="my-table"
              show-empty>
            <template slot="top-row" slot-scope="{ fields }">
              <td v-for="field in fields" :key="field.key">
                <p v-if="field.key == 'actions' || field.key == 'status' || field.key=='is_paid' "></p>
                <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
              </td>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(notification_master_id)="data">
              {{ data.item.notification_master_id }}
            </template>

            <template #cell(created_at)="row">
              {{ row.item.created_at | moment("ddd, MMM Do YYYY, h:mm:ss a") }}
            </template>

            <template #cell(initiated_by_name)="row">
              <div>
                <b-media tag="li" class="">
                  <template #aside>
                    <div class="text-center">
                      <b-img :src="userPhoto(row.item.initiated_by_image)" v-if="row.item.initiated_by_image" width="85"
                             class="thumbnail"
                             thumbnail></b-img>
                      <img src="../../assets/img/blank.png" alt="" class="user_image" v-else>
                    </div>
                  </template>
                  <h5 class="mt-0 mb-1">{{ row.item.initiated_by_name }}</h5>
                  <p class="mb-0 font-weight-bold">{{ row.item.initiated_by_phone_number }}</p>
                  <p class="mb-0">{{ row.item.initiated_by_email }}</p>
                  <p class="mb-0">{{ row.item.initiated_by_user_type }}</p>

                </b-media>
              </div>
            </template>

            <template #cell(initiated_for_name)="row">
              <div>
                <b-media tag="li" class="">
                  <template #aside>
                    <div class="text-center">
                      <b-img :src="userPhoto(row.item.initiated_for_image)" v-if="row.item.initiated_for_image"
                             width="85" class="thumbnail"
                             thumbnail></b-img>
                      <img src="../../assets/img/blank.png" alt="" class="user_image" v-else>
                    </div>
                  </template>
                  <h5 class="mt-0 mb-1">{{ row.item.initiated_for_name }}</h5>
                  <p class="mb-0 font-weight-bold">{{ row.item.initiated_for_phone_number }}</p>
                  <p class="mb-0">{{ row.item.initiated_for_email }}</p>
                  <p class="mb-0">{{ row.item.initiated_for_user_type }}</p>

                </b-media>
              </div>
            </template>


            <template #cell(actions)="row">
              <b-button variant="primary" @click="updateData(row)"><i class="far fa-edit"></i></b-button>
            </template>

          </b-table>
          <div class="description found"> Total : {{ items.length }}</div>


        </div>

        <div>
          <b-pagination
              aria-controls="my-table"
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              @change="handlePageChange"
              size="lg"
              class="my-0"
          ></b-pagination>
        </div>
      </div>

    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {NotificationListMixin} from "../../mixins/NotificationListMixin";

export default {
  name: "NotificationList",
  mixins: [NotificationListMixin],
  title: 'Notification list',
}
</script>

<style scoped>
.my-class {
  width: 400px !important;
}

.test {
  color: darkred;
}

.media-aside {
  width: 100px;
}

.media {
  display: flex;
  align-items: flex-start;
  align-items: center;
}
</style>