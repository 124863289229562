import moment from "moment";

export const dateTimeSoftForm = (string) => {
    let covertedString = string.replace(/_/g, " ");
    return covertedString
}

export const monthRange = (month) => {
    console.log(month)
}

export const dateFormate = () => {
    var today = new Date();
    today.toLocaleTimeString('en-US', {timeZone: 'Asia/Dhaka'})
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    // // today
    today = yyyy + '-' + mm + '-' + dd;

    var need_days = new Object();
    need_days['today'] = today;
    need_days['weekly_first_day'] = moment().startOf('week').format('YYYY-MM-DD');
    need_days['weekly_last_day'] = moment().endOf('week').format('YYYY-MM-DD');
    need_days['biweekly_first_day'] = moment().day(1).format('YYYY-MM-DD');
    need_days['biweekly_last_day'] = moment().day(15).format('YYYY-MM-DD');
    need_days['monthlay_first_day'] = moment().startOf('month').format('YYYY-MM-DD');
    need_days['monthlay_last_day'] = moment().endOf('month').format('YYYY-MM-DD');
    need_days['month_start'] = moment().startOf('month').format('YYYY-MM');
    need_days['yearly_first_day'] = moment().startOf('year').format('YYYY-MM-DD');
    need_days['yearly_last_day'] = moment().endOf('year').format('YYYY-MM-DD');
    return need_days;
}

export const createdDateFormet = (date) => {
    return moment(date).format('Do MMM, YYYY h:mm:ss A'); // September 14th 2022, 11:53:10 am
}
