<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Product User list</h2>
        <div class="d-flex justify-content-end">
          <div class="mx-2">
            <b-button v-b-modal.modal variant="primary">Assign users by product</b-button>
          </div>
          <div class="mx-2">
            <download-excel
                :data="items"
                class="btn btn-primary"
                :fields="json_fields"
                worksheet="Product user list"
                name="Product user list">
              <i class="fas fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </div>
        <div>
          <b-modal
              id="modal"
              title="Assign users by product"
              @show="resetModal"
              @hidden="resetModal"
              size="lg"
              scrollable
              @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit" style="height: 50vh;">

              <div class="mb-3">
                <label>Select Product</label>
                <v-select
                    :options="products"
                    :reduce="products => products.id"
                    v-model="form.product_id"
                    label="title"
                    name="product_id"
                    id="role">
                </v-select>
              </div>

              <div class="mb-3">
                <label>Select Users</label>

                <v-select
                    ref="multi_select"
                    :options="users"
                    :reduce="users => users.id"
                    v-model="form.user_id"
                    name="user_id"
                    label="phone_number"
                    :components="{OpenIndicator}"
                    multiple>
                  <template slot="option" slot-scope="user">
                    {{ user.id }} - {{ user.name }} - ( {{ user.phone_number }} )
                  </template>
                </v-select>
              </div>
            </form>
          </b-modal>
        </div>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-modal
                id="modal-1"
                size="lg"
                title="Manage product"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOkEdit">

              <form ref="form" @submit.stop.prevent="handleSubmitEdit" style="height: 50vh;">

                <div class="mb-3">
                  <label>Select product</label>
                  <v-select
                      :options="products"
                      :reduce="products => products.id"
                      v-model="pro.product_id"
                      label="title"
                      name="product_id">
                  </v-select>
                </div>
                <b-form-group>
                  <label for="">Action comment <span class="text-danger">*</span></label>
                  <b-form-textarea
                      id="textarea"
                      v-model="pro.comment"
                      placeholder="Enter something..."
                      rows="3" required
                  ></b-form-textarea>
                </b-form-group>

              </form>
            </b-modal>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                class="table-responsive mt-5"
                show-empty>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>


              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
                </td>
              </template>

              <template #cell(user)="row">

                <user-card
                    :image="row.item.image"
                    :name="row.item.fullname"
                           :phone_number="row.item.phone_number">
                  <p>{{ row.item.sub_type }}</p>
                </user-card>

              </template>

              <template #cell(confirmation_status)="row">
                <div class="">
                  <b-badge  v-if="row.item.confirmation_status==1" variant="success">Active</b-badge>
                  <b-badge  v-else variant="danger">Inactive</b-badge>
                </div>
              </template>

              <template #cell(product)="row">
                <div class="">
                  <p class="mb-0" v-if="row.item.product">{{ row.item.product.title }}</p>
                  <b-badge variant="warning" v-else>No Product Selected</b-badge>
                </div>
              </template>

              <template #cell(action)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">

                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>
                      <b-dropdown-item href="#" @click="editAction(row.item)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit product
                      </b-dropdown-item>

                      <b-dropdown-item  @click="changeStatus(row.item)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Change status
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="items.length"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {ProductUsersMixin} from "../../mixins/ProductUsersMixin";
import {UserProductMixin} from "../../mixins/UserProductMixin";

export default {
  name: "ProductUsers",
  mixins: [ProductUsersMixin, UserProductMixin]
}
</script>

<style scoped>

</style>
