import { render, staticRenderFns } from "./InstituteType.vue?vue&type=template&id=c10d8496&scoped=true"
import script from "./InstituteType.vue?vue&type=script&lang=js"
export * from "./InstituteType.vue?vue&type=script&lang=js"
import style0 from "./InstituteType.vue?vue&type=style&index=0&id=c10d8496&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c10d8496",
  null
  
)

export default component.exports