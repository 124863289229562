import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const PendingReportPatientListMixin = {
    data() {
        let route_name = this.$route.name;
        return {
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            breadcrumbs: route_name + 'users',
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            localActive: false,
            nameState: null,
            emailState: null,
            users: [],
            items: [],
            fields: [
                {
                    key: 'patient_name',
                    label: 'Patient',
                    sortable: true
                },
                {
                    key: 'doctor_name',
                    label: 'Doctor',
                    sortable: true
                },
                {
                    key: 'follow_up_date',
                    label: 'Follow up date',
                    sortable: true
                },
                {
                    key: 'prescription_identifier',
                    label: 'Prescription',
                    sortable: true
                },
                {
                    key: "actions"
                }
            ],
            filter: null,
            filterOn: [],
            filters: {
                id: '',
                patient_name: '',
                doctor_name: '',
                patient_phone_number: '',
                doctor_phone_number: '',
                prescription_identifier: '',
            },
            startDate: null,
            endDate: null,
            show: true,
            base_url: process.env.VUE_APP_API_URL,
            user_id: '',
            callHistories: [],
            call_history_filed: [
                {
                    label: 'Sl',
                    key: 'index',
                    sortable: true,
                },
                {
                    label: 'Orko user',
                    key: 'fullname',
                    sortable: true,
                },
                {
                    label: 'User type',
                    key: 'user_type',
                    sortable: true,
                },
                {
                    label: 'Call category',
                    key: 'call_category',
                    sortable: true,
                },
                {
                    label: 'Call Type',
                    key: 'call_type',
                    sortable: true,
                },
                {
                    label: 'Call Response',
                    key: 'call_response',
                    sortable: true,
                },
                {
                    label: 'Reminder date',
                    key: 'reminder_date',
                    sortable: true,
                },
                {
                    label: 'Call action date',
                    key: 'call_action_date',
                    sortable: true
                },
                {
                    label: 'Remarks',
                    key: 'remarks',
                    sortable: true
                },
                {
                    label: 'Created at',
                    key: 'created_at',
                    sortable: true
                }

            ],
            call_history: {
                call_response: '',
                call_type: '',
                remarks: '',
                call_category: '',
                reminder_date: '',
                is_reminder: '',
                id: '',
            },
            call_category: [],
            user: {
                user_name: '',
                user_id: ''
            },
            total_point: '',
            user_name: '',
            user_phone_number: '',
            user_image: null,
            doctor_id: '',
            call_log: {},
            total_user: '',
            start_date: null,
            end_date: null,
            report_details: {},
            test_table_columns: [
                {
                    key: 'test_text',
                    label: 'Test',
                },
                {
                    key: 'result_remarks',
                    label: 'Result remarks',
                },
                {
                    key: 'pre_identifier',
                    label: 'Prescription identifier',
                },
                {
                    key: 'created_at',
                    label: 'Created date',
                },
            ]
        }
    },
    created() {
        localStorage.setItem('tabIndex', 9)
        this.$root.$emit('call-active-menu', 9);
        this.getUser();
        this.userPhoto();
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Pending report patient list", route: "report/pending/patient/list"},
        ]);
    },

    methods: {
        getUser() {
            this.loading = true
            axios.get(`${process.env.VUE_APP_URL}/api/v2/prescription/tests/not-uploaded?api_token=${localStorage.getItem('api_token')}&offset=0&limit=${this.perPage}&${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data;
                        this.total_user = response.data.total_count
                        this.loading = false;
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true;
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            axios.get(`${process.env.VUE_APP_URL}/api/v2/prescription/tests/not-uploaded?api_token=${localStorage.getItem('api_token')}&offset=${correctValue}&limit=${this.perPage}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        searchDoctor(event) {
            event.preventDefault()
            this.loading = true
            let form = new FormData();
            form.append('limit', '100');
            form.append('offset', '0');
            form.append('search_text', this.filter);
            axios.get(`${process.env.VUE_APP_URL}/api/v2/prescription/tests/not-uploaded?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.loading = false;
                        this.items = response.data.data;
                        this.total_user = response.data.total_count;
                    }
                })
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        userDetails(item) {
            this.reportDetails(item.id);
            this.$bvModal.show('report-details')
        },

        reportDetails(id) {
            var url = `${process.env.VUE_APP_API_URL}/api/v2/prescription/${id}/tests?api_token=${localStorage.getItem('api_token')}`;
            axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.report_details = response.data.data;
                    }
                })
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },

        sendInfo(item) {
            this.user_id = item.id
        },
        callHistory(item) {
            this.call_log = item;
            this.user.user_name = item.name;
            this.user.user_id = item.id;
            this.call_history.id = item.id
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs?prescription_id=${item.id}&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.callHistories = response.data.data;
                });
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/category/list?call_type=outbound&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.call_category = response.data.data
                });
        },
        saveCallHisory() {

            let form = new FormData();
            form.append('user_id', localStorage.getItem('user_id'));
            form.append('prescription_id', this.call_history.id);
            form.append('call_category_id', this.call_history.call_category);
            form.append('call_action_status_id', 1);
            form.append('call_type', this.call_history.call_type);
            form.append('call_response', this.call_history.call_response);
            if (this.call_history.is_reminder == 1) {
                form.append('has_reminder', this.call_history.is_reminder);
                form.append('reminder_date', this.call_history.reminder_date);
            }
            form.append('remarks', this.call_history.remarks);
            form.append('agent_username', '');

            axios.post(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs/store?api_token=${localStorage.getItem('api_token')}`, form)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    } else {
                        // this.$bvModal.hide('modal-call')
                        this.call_history = [];
                        this.callHistory(this.call_log);
                        this.$snotify.success('Call log added Successfully.');
                    }

                })
        },

        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.food = null
            this.form.checked = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.form.password = '';
            this.form.comment = '';
            this.call_history = {};
            this.call_log = {},
                this.$nextTick(() => {
                    this.show = true
                });


        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return '../../../public/media/users/blank.png'
            }
        },

        dateRangeClear() {
            this.startDate = '';
            this.endDate = ''
            this.start_date = null
            this.end_date = null
        },
        showImage(img) {
            const h = this.$createElement
            // More complex structure
            const messageVNode = h('div', {class: ['foobar']}, [
                h('b-img', {
                    props: {
                        src: `${process.env.VUE_APP_API_URL}/${img}`,
                        thumbnail: true,
                        center: false,
                    }
                })
            ])
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                buttonSize: 'sm',
                centered: true, size: 'lg'
            })
        },

        resetModal() {
            this.doctor_id = '';
            this.parient_id = '';
        },

        resetReportDetails() {
            this.report_details = {};
        },

        handleImport(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmitImport()
        },

        prescriptionUrl(url) {
            if (url) {
                return `${process.env.VUE_APP_API_URL}/${url}`;
            } else {
                return ''
            }
        },

    },

    computed: {
        rows() {
            return this.users.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.registration_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                patient_name: '',
                doctor_name: '',
                patient_phone_number: '',
                doctor_phone_number: '',
                prescription_identifier: '',
            }]
        },

        filteredData() {
            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.registration_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        },
        validation() {
            return this.patient_register.phone_number.length == 11
        }
    },

    currentPage: {
        handler: function (value) {
            this.getUser().catch(error => {
                console.error(error)
                console.error(value)
            })
        }
    },
}
