<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="row" v-else>
      <div class="col-md-12">
        <button class="btn btn-info" @click="addNew">Create/Update Therapies</button>

        <b-table
            style="width: 50%"
            striped hover responsive
            :items="items"
            :fields="fields"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-5">
        </b-table>

        <b-modal
            id="createEditModal"
            ref="modal"
            title="Create/Update Therapies"
            size="lg"
            @hidden="resetModal"
            @ok="handleOk"
            scrollable>
          <form ref="form" @submit.stop.prevent="handleSubmit" style="height: 50vh;">
            <b-form-group label="Select Therapies">
              <v-select
                  ref="multi_select"
                  :options="therapies"
                  :reduce="therapies => therapies.id"
                  v-model="form.orko_therapy_id"
                  label="title">
              </v-select>
            </b-form-group>

            <b-form-group label="Price">
              <b-form-input v-model="form.price"></b-form-input>
            </b-form-group>
          </form>
        </b-modal>

        <b-pagination
            v-model="currentPage"
            :total-rows="total_count"
            :per-page="perPage"
            @change="getInstituteTherapies"
            size="lg"
            class="my-0"
        ></b-pagination>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "InstituteTherapies",
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      items: [],
      fields: [
        {
          key: 'title',
          label: 'Title',
          sortable: true,
        },
        {
          key: 'price',
          label: 'Price',
          sortable: true,
        }
      ],
      total_count: 0,
      form: {
        orko_therapy_id: '',
        price: '',
        institute_id: this.$route.params.id,
      },
      therapies: [],
      loading: false,

    }
  },
  created() {
    this.getInstituteTherapies();
    this.getTherapy();
  },
  methods: {
    getInstituteTherapies(value) {
      this.loading = true;
      if (value) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }

      axios.get(`${process.env.VUE_APP_URL}/api/v2/institute/${this.$route.params.id}/therapies?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}${correctValue ? '&offset=' + correctValue : '&offset=0'}`)
          .then(response => {
            this.loading = false;
            this.items = response.data.data;
            this.total_count = response.data.total_count;
          })
    },

    getTherapy() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/partner/therapy/list?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.therapies = response.data.data;
            }
          })
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmit()
    },

    resetModal() {
      this.form = {
        orko_therapy_id: '',
        price: '',
        institute_id: this.$route.params.id,
      }
    },

    handleSubmit() {
      this.$nextTick(() => {
        let url = `${process.env.VUE_APP_URL}/api/v2/institute/therapies/assign?api_token=${localStorage.getItem('api_token')}`;
        axios
            .post(url, this.form)
            .then(response => {
              if (response.data.status_code == 400) {
                return this.$snotify.error(response.data.message);
              }
              this.resetModal();
              this.$bvModal.hide('createEditModal')
              this.getInstituteTherapies();
              this.$snotify.success('Successfully saved');
            })
      })
    },

    addNew() {
      this.$bvModal.show('createEditModal');
    }
  }
}
</script>

<style scoped>

</style>
