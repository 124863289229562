import { render, staticRenderFns } from "./Exeprience.vue?vue&type=template&id=ef225296&scoped=true"
import script from "./Exeprience.vue?vue&type=script&lang=js"
export * from "./Exeprience.vue?vue&type=script&lang=js"
import style0 from "./Exeprience.vue?vue&type=style&index=0&id=ef225296&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ef225296",
  null
  
)

export default component.exports