import Vue from 'vue';

// core component
Vue.component('page-header', require('../view/layout/subheader/Subheader').default)
Vue.component('users-component', require('./users/Index').default);
Vue.component('users-permissions', require('./users/Permissions').default);
Vue.component('users-roles', require('./users/Roles').default);
Vue.component('assign-permissions', require('./users/AssignPermissions').default);
Vue.component('assign-roles', require('./users/AssignRoles').default);
Vue.component('roles-details', require('./users/RoleDetails').default);
Vue.component('image-modal', require('./reusable-component/ImageModal').default);
Vue.component('star-rating', require('./reusable-component/StarRating').default);
Vue.component('random-generate', require('./reusable-component/RandomNumberGenerate').default);
Vue.component('back-button', require('./reusable-component/BackButton').default);
Vue.component('breadcrumbs', require('./reusable-component/Breadcrumbs').default);
Vue.component('autocomplete', require('./reusable-component/SearchAutocomplete').default);

// User component
Vue.component('user-parent-info', require('./user-component/ParentInfo').default);
Vue.component('user-card', require('./user-component/UserCard').default);
// Accordion component
Vue.component('accordion-component', require('./reusable-component/Accordion').default);
Vue.component('accordion-item-component', require('./reusable-component/Accordion-item').default);

Vue.component('loader-component', require('./reusable-component/Loader').default)

// Reports Component

// Doctor component
Vue.component('doctor-header', require('./users/doctor/Header').default);
Vue.component('doctor-basic-info', require('./users/doctor/BasicInfo').default);
Vue.component('doctor-publication', require('./users/doctor/Publication').default);
Vue.component('doctor-course', require('./users/doctor/Course').default);
Vue.component('doctor-award', require('./users/doctor/Award').default);
Vue.component('doctor-scientific', require('./users/doctor/ScientificConference').default);
Vue.component('doctor-volunteer', require('./users/doctor/Volunteer').default);
Vue.component('doctor-exeprience', require('./users/doctor/Exeprience').default);
Vue.component('doctor-education', require('./users/doctor/Education').default);
Vue.component('doctor-workplace', require('./users/doctor/DoctorWorkPlace').default);
Vue.component('doctor-overview', require('./users/doctor/DoctorOverview').default);
Vue.component('doctor-confirmation', require('./users/doctor/DoctorConfirmation').default);
Vue.component('add-institute', require('./users/doctor/AddInstitute').default);
Vue.component('doctor-skill', require('./users/doctor/Skill').default);
Vue.component('doctor-membership', require('./users/doctor/Membership').default);
Vue.component('doctor-medical-registration', require('./users/doctor/MedicalRegistration').default);
Vue.component('doctor-workplace-telemedicine', require('./users/doctor/workpleace/Telemedicine').default);
Vue.component('doctor-workplace-chamber', require('./users/doctor/workpleace/Chamber').default);
Vue.component('doctor-points', require('./users/doctor/Points').default);

// Agent component
Vue.component('agent-profile', require('./users/agent/AgentProfile').default);
Vue.component('agent-basicinfo', require('./users/agent/BasicInfo').default);
Vue.component('aide-info', require('./users/aide/AideInfo').default);

// Patient component
Vue.component('patient-basic-info', require('./users/patient/BasicInfo').default)

// Aide
Vue.component('aide-basic-info', require('./users/aide/AideBasicInfo').default);

// Book appointment
Vue.component('doctor-appointment-search', require('./appointments/DoctorSearch').default);

// Admin
// Patient component
Vue.component('admin-basic-info', require('./users/admin/BasicInfo').default);

// Dashboard component
Vue.component('doctor-report', require('./dashboard/dashboard-widget/DorctorReport').default);
Vue.component('patient-report', require('./dashboard/dashboard-widget/PatientReport').default);
Vue.component('agent-report', require('./dashboard/dashboard-widget/AgentReport').default);
Vue.component('box-card', require('./dashboard/dashboard-widget/BoxTitleWizard').default);
Vue.component('top-scorer-user', require('./dashboard/users/TopScorer').default);
Vue.component('latest-prescription', require('./dashboard/prescription/LatestPrescription').default);
Vue.component('level-wise-doctor-count-component', require('./dashboard/level/LevelWiseDoctorCount').default);

// Chart
Vue.component('pie-chart', require('./chart/Pie').default);