import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";


export const SubscriberUser = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
                "Price": {
                    field: "price",
                },
                "Start date": {
                    field: "start_date",
                },
                "End date": {
                    field: "end_date",
                },
                "Status": {
                    field: "status",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'User',
                    key: 'user',
                    sortable: true,
                },
                {
                    label: 'Package name',
                    key: 'subscription_package',
                    sortable: true,
                },
                {
                    label: 'Duration type',
                    key: 'duration_type',
                    sortable: true,
                },
                {
                    label: 'Is paid',
                    key: 'is_paid',
                    sortable: true,
                },
                {
                    label: 'Created date',
                    key: 'created_date',
                    sortable: true,
                },
                {
                    label: 'Expiry date',
                    key: 'expiry_date',
                    sortable: true,
                },
                {
                    label: 'Transaction id',
                    key: 'transaction_id',
                    sortable: true,
                },
                {
                    label: 'Actual amount',
                    key: 'actual_amount',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            form: {
                title: '',
                description: '',
                price: '',
                start_date: '',
                end_date: '',
                status: ''
            },
            edit: false,
            edit_id: '',
            titleState: null,
            descriptionState: null,
            priceState: null,
            startDateState: null,
            endDateState: null,
            single_item: {},
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Purchased subscription list", route: "/subscriber-users"},
        ]);
    },
    created() {
        this.getSubscriptionOffer();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Purchased subscription list',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getSubscriptionOffer() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/subscription/purchased/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data

                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        viewAction(row) {
            this.$bvModal.show('viewDetails');
            this.single_item = row.item;
        },

        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
    }
}