<template>
  <div>
    <div class="card" v-if="loading">
      <div class="card-body">
        <h3>Notification Type Lists</h3>
        <b-button v-b-modal.modal variant="primary">Add new Notification type</b-button>
        <div>
          <b-modal
              id="modal"
              title="Create a new notification type"
              @show="resetModal"
              @hidden="resetModal"
              size="lg"
              @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">

              <b-form-group
                  label="Notification Type Name"
                  label-for="name-input"
                  invalid-feedback="Notification type is required"
                  :state="notification_type_nameState">
                <b-form-input
                    id="name-input"
                    v-model="form.notification_type_term"
                    :state="notification_type_nameState"
                    required/>
              </b-form-group>

              <b-form-group
                  label="Relationship With"
                  label-for="name-input"
                  invalid-feedback="Relationship model is required"
                  :state="relationship_withState">
                <b-form-input
                    id="name-input"
                    v-model="form.relationship_with"
                    :state="relationship_withState"
                    required/>
              </b-form-group>

              <b-form-group
                  label="Relationship column*"
                  label-for="name-input"
                  invalid-feedback="Relationship column is required"
                  :state="relationship_columnState">
                <b-form-input
                    id="name-input"
                    v-model="form.relationship_with_column"
                    :state="relationship_columnState"
                    required/>
              </b-form-group>


              <b-form-checkbox
                  id="checkbox-2"
                  v-model="form.status"
                  name="checkbox-2"
                  value="1"
                  unchecked-value="0">
                Status
              </b-form-checkbox>


            </form>
          </b-modal>
          <br>
          <div class="row align-items-center">
            <div class="col-md-2 my-3">
              <div class="">
                <b-form-group
                    class="mb-0">
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>
            <div class="col-md-2 my-3">
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Notification type"
                  name="Notification type">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
            <div class="col-md-4 my-3">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
            <b-col lg="4">
              <b-form-group
                  class="mb-0"
                  v-slot="{ ariaDescribedby }">
                <b-input-group size="sm">
                  <b-form-select
                      id="sort-by-select"
                      v-model="sortBy"
                      :options="sortOptions"
                      :aria-describedby="ariaDescribedby"
                      class="w-75">
                    <template #first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>

                  <b-form-select
                      v-model="sortDesc"
                      :disabled="!sortBy"
                      :aria-describedby="ariaDescribedby"
                      size="sm"
                      variant="primary"
                      class="w-25"

                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
          </div>

          <b-table
              striped hover
              :items="filtered"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              thead-class="bg-primary text-white"
              show-empty
              class="table-responsive mt-10">

            <template slot="top-row" slot-scope="{ fields }">
              <td v-for="field in fields" :key="field.key">
                <p v-if="field.key == 'actions' || field.key == 'status' || field.key=='is_paid' "></p>
                <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
              </td>
            </template>
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(status)="data">
              <div v-if="data.item.status === '1'">
                <b-badge variant="success">Active</b-badge>
              </div>
              <div v-else>
                <b-badge variant="danger">Inactive</b-badge>
              </div>
            </template>

            <template #cell(actions)="row">
              <b-button variant="primary" @click="updateData(row)"><i class="far fa-edit"></i></b-button>
            </template>

          </b-table>
          <div class="description found"> Total : {{ items.length }}</div>

          <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              size="lg"
              class="my-0"
          ></b-pagination>


        </div>
      </div>
    </div>
    <div v-else>
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>

import {NotificationTypeMixin} from '../../mixins/NotificationTypeMixin'

export default {
  name: "NotificationType",
  mixins: [NotificationTypeMixin],
  title: 'Notification type',
}
</script>

<style scoped>

</style>
