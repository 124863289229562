import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const CenterWisePrescriptionListReportMixin = {
    data() {
        return {
            json_fields: {
                "Doctor Name": {
                    field: 'doctor_details',
                    callback: (value) => {
                        return value.fullname;
                    },
                },
                "Doctor Phone": {
                    field: 'doctor_details',
                    callback: (value) => {
                        return value.phone_number;
                    },
                },
                "Patient name": {
                    field: 'patient_details',
                    callback: (value) => {
                        return value.fullname;
                    },
                },
                "Patient phone": {
                    field: 'patient_details',
                    callback: (value) => {
                        return value.phone_number;
                    },
                },
                "Agent name": {
                    field: 'prescription_for_patient_details',
                    callback: (value) => {
                        return value.fullname;
                    },
                },
                "Agent phone": {
                    field: 'prescription_for_patient_details',
                    callback: (value) => {
                        return value.phone_number;
                    },
                },
                "Created date": {
                    field: 'created_date',
                },
                "Followup date": {
                    field: 'followup_date',
                },
                "Orko center": {
                    field: 'orko_center',
                },
                "Prescription identifier": {
                    field: 'prescription_identifier',
                },

                "Service type": {
                    field: 'service_details',
                    callback: (value) => {
                        return value.service_type;
                    },
                },
                "Service fee": {
                    field: 'service_details',
                    callback: (value) => {
                        return value.service_fee;
                    },
                },

            },
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            currentPage: 1,
            perPage: 30,
            pageOptions: [30, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                index: '',
                transaction_date: '',
                doctor_name: '',
                unique_id: '',
                doctor_phone: '',
                prescription_identifier: '',
                service_type: '',
                discount: '',
                paid_amount: '',
                transaction_amount: '',
                transaction_id: '',
            },
            items: [],
            loading: true,
            fields: [
                {
                    key: 'index',
                    label: 'SL#',
                    sortable: true
                },
                {
                    key: 'doctor_details',
                    label: 'Doctor info',
                    sortable: true
                },
                {
                    key: 'patient_details',
                    label: 'Agent info',
                    sortable: true
                },
                {
                    key: 'prescription_for_patient_details',
                    label: 'Patient info',
                    sortable: true
                },
                {
                    key: 'service_details',
                    label: 'Service Details',
                    sortable: true
                },
                {
                    key: 'prescription_identifier',
                    label: 'Prescription Identifier',
                    sortable: true
                },
                {
                    key: 'is_paid',
                    label: 'Is paid',
                    sortable: true
                },
                {
                    key: 'followup_date',
                    label: 'Followup Date',
                    sortable: true
                },
                {
                    key: 'created_date',
                    label: 'Created Date',
                    sortable: true
                },

            ],
            filter: null,
            filterOn: [],
            startDate: null,
            endDate: null,

            from_date: '',
            to_date: '',
            total_count: 0,
            centers: [],
            patient_count: 0,
            center_id: '',


        }
    },
    mounted() {
        this.agentList();
        this.getCenterWisePatientList();
    },
    created() {
        localStorage.setItem('tabIndex', 4)
        this.$root.$emit('call-active-menu', 4);
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Center wise prescription list", route: "/center-wise-prescription-list"},
        ]);
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        rows() {
            return this.items.length
        },
        filtered() {

            const filtered = this.items.filter(item => {


                return Object.keys(this.filters).every(key =>

                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                index: '',
                transaction_date: '',
                doctor_name: '',
                unique_id: '',
                doctor_phone: '',
                prescription_identifier: '',
                service_type: '',
                paid_amount: '',
                transaction_amount: '',
                discount: '',
                transaction_id: '',
            }]
        },

        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        }
    },
    methods: {
        agentList() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/orkocenter/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false
                        this.centers = response.data.data
                    }
                })
        },
        getCenterWisePatientList() {
            // if (this.startDate == null && this.endDate == null) {
            //     this.startDate = moment().startOf('month').format('YYYY-MM-DD');
            //     this.endDate = moment().endOf('month').format('YYYY-MM-DD');
            // }
            this.loading = true;
            axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/prescription/list?api_token=${localStorage.getItem('api_token')}${this.center_id ? '&center_id=' + this.center_id : ''}&platform=backoffice${this.startDate ? '&start_date=' + this.startDate : ''}${this.endDate ? '&end_date=' + this.endDate : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false
                        this.items = response.data.data
                        this.patient_count = response.data.count
                        // this.getCenterWisePatientList()

                    }
                })
        },
        handlePageChange(value) {
            this.loading = true;

            var offset = value - 1;
            var correctValue = offset * this.perPage;

            axios.get(`${process.env.VUE_APP_URL}/api/v2/report/orkocenter/prescription/list?start_from=${this.startDate}&end_to${this.endDate}&api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=${correctValue}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false
                        this.items = response.data.data.report;
                        this.paid_amount = response.data.data.paid_amount_sum;
                        this.trxn_amount_sum = response.data.data.trxn_amount_sum;
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },
        clearDate() {
            this.startDate = null;
            this.endDate = null;
            this.getCenterWisePatientList();
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return process.env.BASE_URL + 'media/users/blank.png';
            }
        },
        prescriptionUrl(url) {
            if (url) {
                return `${process.env.VUE_APP_API_URL}/${url}`;
            } else {
                return ''
            }
        },

    },

}