<template>
   <div>
    <QualityControlPrintPreviewVue />
   </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import QualityControlPrintPreviewVue from '../../view/pages/new/reports/QualityControlPrintPreview.vue';
export default {
    name: 'AuditView',
    components: {QualityControlPrintPreviewVue},
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Audit Preview", route: "/audit/view"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 9)
        this.$root.$emit('call-active-menu', 9);
        
    },
    
}
</script>
