import axios from "axios";
import {underScoreToSpace} from "../healper/nameFormating";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
// import Login from "../view/pages/auth/Login";
import {dateFormate} from "../healper/dateTimeFormet";
import moment from "moment";
export const PrescriptionMixin = {
    data() {
        return {
            expanded: false,
            totalItems: '',
            items: [],
            loading: false,
            fields: [
                {
                    label: 'Prescription Date',
                    key: 'created_date',
                },
                {
                    label: 'Patient Info',
                    key: 'patient_details',
                },
                {
                    label: 'Doctor info',
                    key: 'doctor_details'
                },
                {
                    label: 'Prescription Id',
                    key: 'prescription_identifier'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Followup date',
                    key: 'follow_update'
                },
                {
                    label: 'Payment Status',
                    key: 'is_paid'
                },
                {
                    label: 'Actions',
                    key: 'action'
                }
            ],
            filter: null,
            filterOn: [],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                created_date: '',
                patient_details: '',
                doctor_details: '',
                prescription_identifier: '',
                is_paid: '',
                status: '',
            },
            startDate: null,
            endDate: null,
            counter: {},
            visible: true,
            pagination: {
                current_page: 1
            },
            show: true,
            user_id: '',
            form: {},
            prescription_details: {},
            exitImage: false,
            pdf_viewer: null,
            from_date: moment().startOf('week').format('YYYY-MM-DD'),
            to_date: moment().endOf('week').format('YYYY-MM-DD'),
            custom_filter: {
                is_paid: null,
                status: null,
            },
            payment_status: [
                {value: null, text: 'Payment status'},
                {value: '1', text: 'Paid'},
                {value: '0', text: 'Unpaid'},
                {value: '2', text: 'Partial paid'},
            ],
            payment_filter: null,
            prescription_status: [
                {value: null, text: 'Prescription status'},
                {value: '1', text: 'Valid'},
                {value: '0', text: 'Invalid'}
            ],
            prescription_filter: null,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Prescriptions", route: "prescriptions"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 6)
        this.$root.$emit('call-active-menu', 6);
        this.getPrescription();
        this.getPrescriptionCounter();
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.created_at)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                created_date: '',
                patient_details: '',
                doctor_details: '',
                prescription_identifier: '',
                is_paid: '',
                status: '',
            }]
        },
        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.created_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getPrescription() {
            this.loading = true;
            // form.append('search_text', this.filter);
            const url = `/api/v1/administrator/prescription/list?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=0${this.from_date ? '&from_date=' + this.from_date : ''}${this.to_date ? '&to_date=' + this.to_date : ''}${this.filter ? '&q=' + this.filter : ''}${this.payment_filter ? '&is_paid=' + this.payment_filter : ''}${this.prescription_filter ? '&status=' + this.prescription_filter : ''}`;
            axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else if (response.data.status_code === 400) {
                        this.$snotify.error('Something wrong');
                        this.loading = false;
                    } else {
                        this.items = response.data.data.detail
                        this.totalItems = response.data.data.total
                        this.loading = false;
                    }
                })
        },
        getPrescriptionCounter() {
            this.loading = true;
            // form.append('search_text', this.filter);
            axios.get(`${process.env.VUE_APP_URL}/api/v1/administrator/prescription/counts?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=0${this.from_date ? '&from_date=' + this.from_date : ''}${this.to_date ? '&to_date=' + this.to_date : ''}${this.filter ? '&q=' + this.filter : ''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        // this.items = response.data.data.detail
                        this.counter = response.data.data

                        this.loading = false;
                    }
                })
        },
        regeneratePrescription(item) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "Your previews will be remove.",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios.post(`${process.env.VUE_APP_URL}/api/v2/prescription/regenerate?pre_identifier=${item.prescription_identifier}&api_token=${localStorage.getItem('api_token')}&doctor_id=${item.doctor_details.id}`)
                                    .then(res => {
                                        if (res.data.status_code == 200) {
                                            this.$snotify.success('Successfully generate');
                                        } else {
                                            this.$snotify.error('Oops something wrong');
                                        }
                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );

        },
        exportPrescription() {
            const url = `/api/v1/administrator/prescription/export?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=0${this.from_date ? '&from_date=' + this.from_date : ''}${this.to_date ? '&to_date=' + this.to_date : ''}${this.filter ? '&q=' + this.filter : ''}${this.payment_filter ? '&is_paid=' + this.payment_filter : ''}${this.prescription_filter ? '&status=' + this.prescription_filter : ''}`;

            axios.get(url)
                .then(res => {
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = url.split("/").pop();
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    this.loading = false;
                })
        },

        showPriview(item) {
            this.pdf_viewer = item.prescription_url
            this.$bvModal.show('prescription-viewer')
        },
        resetModal() {
            this.pdf_viewer;
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return process.env.BASE_URL + 'media/users/blank.png';
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateRangeClear() {
            this.startDate = '';
            this.endDate = '';
            this.from_date = '';
            this.to_date = '';
            this.payment_filter = null;
            this.prescription_filter = null;
            this.filter = null;
            this.getPrescription();
        },
        prescriptionUrl(url) {
            if (url) {
                return `${process.env.VUE_APP_API_URL}/${url}`;
            } else {
                return ''
            }
        },
        underScoreToSpace,

        exportAsExcel() {
            const format = 'xlsx'
            const exportSelectedOnly = true
            const filename = 'test'
            this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
        },
        sendInfo(item) {
            this.prescription_details = item;
        },
        invalidPrescription() {

        },
        onReset(event) {
            event.preventDefault()
        },
        handlePageChange(value) {

            this.loading = true;
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            const url = `/api/v1/administrator/prescription/list?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=${correctValue}${this.from_date ? '&from_date=' + this.from_date : ''}${this.to_date ? '&to_date=' + this.to_date : ''}${this.filter ? '&q=' + this.filter : ''}${this.payment_filter ? '&is_paid=' + this.payment_filter : ''}${this.prescription_filter ? '&status=' + this.prescription_filter : ''}`;

            axios.get(url)
                .then(response => {
                    this.items = response.data.data.detail
                    this.totalItems = response.data.data.total
                    this.loading = false;
                })

        },
        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
    },

}