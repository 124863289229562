import axios from "axios";

export default {
    namespaced: true,
    state: {
        doctors: [],
        patients: [],
        agents: []
    },
    getters: {
        getDoctor(state) {
            return state.doctors
        },

        getPatient(state) {
            return state.patients
        },

        getAgent(state) {
            return state.agents
        },

    },
    actions: {
        getReport({commit}) {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/user/report?api_token=${localStorage.getItem('api_token')}`)
                .then(res => {
                    commit("setDoctor", res.data.data.user.doctor);
                    commit("setPatients", res.data.data.user.patient);
                    commit("setAgents", res.data.data.user.agents);
                })
        }
    },
    mutations: {

        setDoctor(state, payload) {
            state.doctors = payload
        },
        setPatients(state, payload) {
            state.patients = payload
        },
        setAgents(state, payload) {
            state.agents = payload
        }
    }

}