import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {checkResponse} from "../healper/core";

export const UserProductMixin = {
    data() {
        return {
            loading: true,
            form: {
                product_id: '',
                user_id: '',
            },
            users: [],
            products: [],
            users_id: [],
            OpenIndicator: {
                render: createElement => createElement('span', {class: {'toggle': true}}),
            },
        }
    },
    mounted() {

        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Assign user by product", route: "assign-product"},
        ]);

    },
    created() {
        this.getRolesData();
        this.getUser();
    },
    methods: {
        getRolesData() {
            // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
            axios.get(`${process.env.VUE_APP_URL}/api/v1/product/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.products = response.data.data
                    }
                    // this.loading = false;
                })
        },
        getUser() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&user_type=doctor`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.users = response.data.data
                        this.loading = false;
                    }

                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            // this.nameState = valid
            return valid
        },
        resetModal() {
            // this.name = ''
            // this.nameState = null
            this.form = {}
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }

            // Hide the modal manually
            this.$nextTick(() => {
                let user_ids = this.form.user_id.join();
                let form = new FormData();
                form.append('product_id', this.form.product_id);
                form.append('user_id', user_ids);
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/product/user/store`, form)
                    .then(response => {
                        checkResponse('Successfully assigned', response.data.status_code);

                        this.resetModal();
                        this.getRolesData();
                        this.$bvModal.hide('modal')
                    })
            })
        },
    },
}