import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const AssignPermission = {
    data() {
        return {

            items: [],
            loading: false,
            roleIdState: null,
            submittedNames: [],
            isOpen: false,
            form: {
                role_id: '',
                permission_ids: ''
            },
            permissions_id: [],
            permission_data: [],
            modal_loading: false,
            updated_permission_data: '',
            isCheckedCheckbox: {},

        }
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Assign permissions", route: "assign-permissions"},
        ]);
    },

    created() {
        localStorage.setItem('tabIndex', 13)
        this.$root.$emit('call-active-menu', 13);
        this.getRolesData();
        this.getPermissions();
    },

    methods: {
        getRolesData() {
            // axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('api_token');
            axios.get(`${process.env.VUE_APP_URL}/api/v1/role/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data
                        this.loading = true;
                    }
                })
        },
        getPermissions() {
            this.$store.dispatch('auth/getPermissions');
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.roleIdState = valid
            return valid
        },
        checkPermissions(event, key) {
            if (event) {
                this.permission_data.push(key);
                let data = this.permission_data.join(',')
                this.form.permission_ids = data;
            } else {
                const index = this.permission_data.indexOf(key);
                this.permission_data.splice(index, 1);
                let data = this.permission_data.join(',')
                this.form.permission_ids = data;
            }
        },
        resetModal() {
            this.name = '';
            this.roleIdState = null;
            this.form.role_id = '';
            this.form.permission_ids = '';
            this.permissions_id = [];
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            this.submittedNames.push(this.name)
            // Hide the modal manually
            this.$nextTick(() => {
                // this.form.permission_ids = this.permissions_id
                let form = new FormData();

                form.append("role_id", this.form.role_id);
                form.append("permission_ids", this.permissions_id);
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/permission/set?api_token=${localStorage.getItem('api_token')}`, form)
                    // eslint-disable-next-line no-unused-vars
                    .then(response => {
                        this.form.permission_ids = '';
                        this.$bvModal.hide('modal');
                        this.$bvModal.hide('modal-update');
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        toggle(index) {
            if (this.activeItemIndex == index) {
                this.isOpen = index;
                return this.activeItemIndex = null;
            }
            this.isOpen = index;
            this.activeItemIndex = index
        },
        roleWisePermissionUpdate(item) {
            this.$bvModal.show('modal-update');
            this.modal_loading = true;
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/role/show/${item.id}?api_token=${localStorage.getItem('api_token')}`)
                .then(respose => {
                    this.modal_loading = false;
                    this.form.role_id = respose.data.data.id;
                    respose.data.data.permissions.map(permission => {
                        for (const [key] of Object.entries(permission)) {
                            this.permissions_id.push(key);
                        }
                    });
                })
        },
        permissionGenerate() {

            this.$snotify.clear();
            this.$snotify.confirm(
                "You want generate permission",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .get(`${process.env.VUE_APP_URL}/api/v1/permission/list/generate?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully generate",
                                            "Success"
                                        );

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        }

    },

    computed: {
        roles: {
            get() {
                return this.$store.state.auth.roles;
            }
        },
        permissions: {
            get() {
                return this.$store.state.auth.permissions;
            }
        }
    }
}
