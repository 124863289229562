<template>
  <div>
    <doctor-basic-info :basic_info="user" v-if="user.is_doctor == 1" @call_basic_info="getUserData"></doctor-basic-info>
    <agent-basicinfo @call_basic_info="getUserData" :basic_info="user"
                     v-if="user.user_type =='agent-pharmacy' || user.user_type == 'agent' || user.user_type=='agent_sales'"></agent-basicinfo>
    <aide-basic-info :basic_info="user" @reload="getUserData" v-if="user.user_type =='aide'"></aide-basic-info>
    <patient-basic-info :basic_info="user" v-if="user.user_type=='patient'"
                        @call_basic_info="getUserData"></patient-basic-info>
    <admin-basic-info :basic_info="user" v-if="user.user_type=='executive'"></admin-basic-info>
    <div class="">
      <b-tabs v-if="user.doctor_information && user.is_doctor == 1">
        <b-tab title="Doctor overview" active>
          <doctor-overview :doctorOverView="user" @doctorOverviewUpdate="getUserData"></doctor-overview>
        </b-tab>
        <b-tab title="Medical registration">
          <doctor-medical-registration :medical_registration="user.doctor_information.medical_registration"
                                       @call_medical_reg="getUserData"
                                       :user="user"></doctor-medical-registration>
        </b-tab>
        <b-tab title="Experience">
          <doctor-exeprience :exepriences="user.doctor_information.experiences" :user="user"
                             @addNewExeprience="getUserExprience($event)"></doctor-exeprience>
        </b-tab>
        <b-tab title="Medical Education">
          <doctor-education :educations="user.doctor_information.education" :user="user"  @addNewExeprience="getUserExprience($event)"></doctor-education>
        </b-tab>
        <b-tab title="Publication">
          <doctor-publication :publications="user.doctor_information.publications"></doctor-publication>
        </b-tab>
        <b-tab title="Certification">
          <doctor-course :courses="user.doctor_information.course"></doctor-course>
        </b-tab>
        <b-tab title="Doctor workplace">
          <doctor-workplace :workplaces="user.doctor_information.workplace" :doctor_id="user.id"
                            :doctor_api_token="user.api_token" @call_to_workpleace="getUserData"
                            :doctor="user.doctor_information"></doctor-workplace>
        </b-tab>
        <b-tab title="Award">
          <doctor-award :awards="user.doctor_information.award_scholarship"></doctor-award>
        </b-tab>
        <b-tab title="Scientific conference">
          <doctor-scientific
              :scientific_conferences="user.doctor_information.scientific_conference"></doctor-scientific>
        </b-tab>
        <b-tab title="Volunteer service">
          <doctor-volunteer :volunteers="user.doctor_information.volunteer_service"></doctor-volunteer>
        </b-tab>
        <b-tab title="Skills">
          <doctor-skill :skills="user.doctor_information.skills"></doctor-skill>
        </b-tab>
        <b-tab title="Membership">
          <doctor-membership :memberships="user.doctor_information.medical_society"></doctor-membership>
        </b-tab>
        <b-tab title="Scores">
          <doctor-points :user="user"></doctor-points>
        </b-tab>
        <b-tab title="Sales Activity & Call" @click="salesActivityCall(user.phone_number)"></b-tab>
      </b-tabs>
    </div>
    <!-- Agent profile -->
    <agent-profile :agent_info="user"
                   v-if="user.user_type == 'agent-pharmacy' || user.user_type == 'agent'"/>
    <aide-info :agent_info="user" v-if="user.user_type== 'aide'"/>
    <vue-snotify></vue-snotify>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {UserDetailsMixin} from "../../mixins/UserDetaailsMixin";

export default {
  name: "UserDetails",
  mixins: [UserDetailsMixin],
}
</script>

<style>
.invalid-feedback {
  /*display: block !important;*/
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  background-color: #4591d1;
  border-color: #E4E6EF #E4E6EF #ffffff;
}

</style>
