<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <h2>Medical Examination</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new Examination
            </b-button>
            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                :title="`${this.form.id ? 'Update' : 'Create'}  Examinations`"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group label="Select type">
                  <b-form-select v-model="form.type" :options="types"></b-form-select>
                </b-form-group>

                <b-form-group
                    label="Title"
                    label-for="input-title">
                  <b-form-input id="input-title" v-model="form.title"></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Unit"
                    label-for="input-unit">
                  <b-form-input id="input-title" v-model="form.unit"></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Status"
                    label-for="input-unit">
                  <b-form-checkbox
                      id="checkbox-1"
                      v-model="form.status"
                      name="checkbox-1"
                      value="1"
                      unchecked-value="0"
                  >
                    Status
                  </b-form-checkbox>
                </b-form-group>

              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="items"
                :fields="fields"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">
              <template #cell(status)="row">
                <b-badge :variant="`${row.item.status == 1 ? 'success' : 'danger'}`">
                  {{ row.item.status == 1 ? 'Active' : 'Inactive' }}
                </b-badge>
              </template>
              <template #cell(actions)="row">
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <div>
                    <b-dropdown-item href="#" @click="editAction(row)">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="total_count"
                :per-page="perPage"
                size="lg"
                @change="getMedicalExamination"
                class="my-0"
                aria-controls="doctorList"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  MEDICAL_EXAMINATION,
} from "../../api/ApiUrl";

export default {
  name: "MedicalExamination",
  data() {
    return {
      items: [],
      total_count: 0,
      fields: [
        {
          label: 'Title',
          key: 'title',
          sortable: true,
        },
        {
          label: 'Type',
          key: 'type',
          sortable: true,
        },
        {
          label: 'Unit',
          key: 'unit',
          sortable: true,
        },
        {
          label: 'Status',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Actions',
          key: 'actions'
        }

      ],
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, {value: 100, text: "All"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      form: {
        id: '',
        title: '',
        type: 'physical',
        unit: '',
        status: '1',
      },
      loading: true,
      types: [
        {
          text: 'Vital',
          value: 'vital',
        },
        {
          text: 'General',
          value: 'general',
        },
        {
          text: 'Systemic',
          value: 'systemic',
        },
        {
          text: 'Physical',
          value: 'physical',
        },
        {
          text: 'Pirani',
          value: 'pirani',
        },
      ]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Medical Examination", route: "/medical/examinations"},
    ]);
  },
  created() {
    localStorage.setItem('tabIndex', 7)
    this.$root.$emit('call-active-menu', 7);
    this.getMedicalExamination();
  },
  metaInfo: {
    title: 'Orko Health Ltd | Medical Examinations',
    htmlAttrs: {
      lang: 'en-US'
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {
      const filtered = this.items.filter(item => {
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })
      return filtered.length > 0 ? filtered : [{
        id: '',
        title: '',
      }]
    },
  },
  methods: {
    getMedicalExamination(value) {
      this.loading = true;
      if (value) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios.get(`${MEDICAL_EXAMINATION}?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    resetModal() {
      this.form = {
        title: '',
        type: 'physical',
        unit: '',
        status: '1',
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      // Hide the modal manually
      this.$nextTick(() => {
        let url = '';
        if (this.form.id) {
          url = `${MEDICAL_EXAMINATION}/${this.form.id}?api_token=${localStorage.getItem('api_token')}&_method=put`
        } else {
          url = `${MEDICAL_EXAMINATION}?api_token=${localStorage.getItem('api_token')}`
        }
        axios
            .post(url, this.form)
            .then(response => {
              if (response.data.status_code == 200) {
                this.$snotify.success(`Successfully ${this.form.id ? 'Updated' : 'saved'}`);
                this.getMedicalExamination();
                this.$bvModal.hide('modal-1')
              } else {
                this.$snotify.error(res.data.message);
              }
            })
      })
    },
    editAction(row) {
      this.$bvModal.show('modal-1');
      this.form = row.item;
    },
  }
}
</script>

<style scoped>

</style>
