import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {BACK_LIST, BANK_TYPE} from "../api/ApiUrl";

export const WithDrawalRequestMixin = {
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'index',
                    label: 'Select',
                },
                {
                    key: 'fullname',
                    label: 'Doctor Info',
                },
                {
                    key: 'total_balance',
                    label: 'Balance',
                },
                {
                    key: 'last_disbursed_at',
                    label: 'Last Disbursement Date',
                },
                {
                    key: 'actions',
                    label: 'Action'
                },
            ],
            accounts_table_columns: [
                {
                    key: 'account_name',
                    label: 'Account name',
                },
                {
                    key: 'account_no',
                    label: 'Account No.',
                },
                {
                    key: 'bank_account_type',
                    label: 'Account type',
                },
                {
                    key: 'bank_branch_name',
                    label: 'Branch name',
                },
                {
                    key: 'bank_info',
                    label: 'Bank Info',
                },
            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            sortOptionsCustomize: [
                {value: 'balance_asc', text: 'Balance ASC'},
                {value: 'balance_desc', text: 'Balance DESC'},
            ],
            filters: {
                id: '',
                account_information: '',
                transaction_id: '',
                user_name: '',
                phone_number: '',
                request_status: '',
                current_balance: '',
                amount: '',
                requested_at: '',
            },
            filter: null,
            filterOn: [],
            title: '',
            details: '',
            form: {
                comment: '',
            },
            disburse: {
                transaction_type: '',
                banking_name: '',
                transaction_id: '',
                comment: '',
            },
            loading: true,
            show: true,
            imgError: false,
            single_withdrawlRequest: {},
            transaction_type: [
                {
                    text: 'Bank',
                    value: 'Bank'
                },
                {
                    text: "Mobile Banking",
                    value: "Mobile Banking",
                }
            ],
            commnetState: null,
            request_status_numeric: '',
            account_info: {
                bank_id: '',
                branch_name: '',
                account_name: '',
                account_number: '',
                bank_account_type_id: '',
            },
            back_list: [],
            back_type: [],
            patient_api_token: '',
            search: '',
            min_balance: '',
            has_due: 0,
            sortDescCustom: '',
            total_request: 0,
            accounts: [
                {age: 32, first_name: 'Cyndi'},
                {age: 27, first_name: 'Havij'},
                {age: 42, first_name: 'Robert'}
            ],
            selected_rows: [],
            row_details: {},
            selected_row: [],
            disburse_form: {
                transaction_id: '',
                transaction_type: '',
                amount: '',
                disburse_equally: '',
                disburse_amount: null,
                comment: '',
                users: [],
                action_type: "disburse",
                status: "5",
                module_type: "withdrawal-request",
            },
            multiple_user_id: '',
            row: {
                withdrawal_amount: '',
                bank_account_id: '',
            },
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Doctor payment list", route: "/withdrawal-requests"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 8)
        this.$root.$emit('call-active-menu', 8);
        this.getWithdralRequest();
        this.search = this.$route.query.q;
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                account_information: '',
                user_name: '',
                phone_number: '',
                request_status: '',
                current_balance: '',
                amount: '',
                requested_at: '',
                transaction_id: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        setQueryParams() {
            let params = {};
            if (this.search) {
                params.q = this.search;
            }
            if (this.min_balance) {
                params.min_balance = this.min_balance;
            }
            if (this.has_due) {
                params.has_due = this.has_due;
            }
            if (this.sortBy) {
                params.sort_by = this.sortBy;
            }
            return params;
        },
        getBankList() {
            axios.get(BACK_LIST)
                .then(res => {
                    this.back_list = res.data.data;
                })
        },
        exportRequest() {
            var url = `${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/doctor/list/export?api_token=${localStorage.getItem('api_token')}${this.search ? '&q=' + this.search : ''}${this.min_balance ? '&min_balance=' + this.min_balance : ''}${this.has_due ? '&has_due=' + this.has_due : ''}${this.sortBy ? '&sort_by=' + this.sortBy : ''}`;

            axios.get(url, {responseType: 'blob'})
                .then(response => {
                    this.exportLink(response);
                })
        },
        getBankType() {
            axios.get(BANK_TYPE).then(res => {
                this.back_type = res.data.data;
            })
        },
        getWithdralRequest() {
            this.loading = true;
            const url = `${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/doctor/list?api_token=${localStorage.getItem('api_token')}${this.search ? '&q=' + this.search : ''}${this.min_balance ? '&min_balance=' + this.min_balance : ''}${this.has_due ? '&has_due=' + this.has_due : ''}${this.sortBy ? '&sort_by=' + this.sortBy : ''}&limit=${this.perPage}&offset=0`
            this.$router.replace({
                query: this.setQueryParams()
            })
            axios.get(url)
                .then(response => {

                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.total_request = response.data.total;
                        this.loading = false;
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true
            var offset = value - 1;
            var correctValue = offset * this.perPage;

            const url = `${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/doctor/list?api_token=${localStorage.getItem('api_token')}${this.search ? '&q=' + this.search : ''}${this.min_balance ? '&min_balance=' + this.min_balance : ''}${this.has_due ? '&has_due=' + this.has_due : ''}${this.sortBy ? '&sort_by=' + this.sortDescCustom : ''}&limit=${this.perPage}&offset=${correctValue}`

            axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        clearFilter() {
            this.search = '';
            this.has_due = 0;
            this.min_balance = '';
            this.getWithdralRequest();
        },
        refreshPage() {
            this.getWithdralRequest();
        },
        bulkDisburse() {
            this.$bvModal.show('modal-disburse')
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getUserData(patient_id) {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${patient_id}?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.getWithdralRequest();
                    this.patient_api_token = response.data.data.api_token;
                })
        },
        sendInfo(item) {
            this.getUserData(item.user_id);
            this.getBankList();
            this.getBankType();
            this.disburse.amount = item.total_balance;
            this.ref_table_id = item.wallet_id;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/bank/accounts/${item.user_id}?api_token=${localStorage.getItem('api_token')}`)
                .then(res => {
                    if (res.data.data == null) {
                        this.$bvModal.hide('modal-disburse')
                        return this.$bvModal.msgBoxConfirm('This patient has no bank information in our system. Do you want to create new one ?', {
                            title: 'Need to confirm',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'danger',
                            okTitle: 'YES',
                            cancelTitle: 'NO',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        })
                            .then(value => {
                                this.confirmation_box = value;
                                if (this.confirmation_box) {
                                    this.$bvModal.show('open-back-account-form')
                                }
                            })
                            .catch(err => {
                                console.log(err);
                                // An error occurred
                            })
                        // return this.$bvModal.show('open-back-account-form');
                    } else {
                        this.request_status_numeric = status;
                        this.single_withdrawlRequest = item;
                        this.$bvModal.show('modal-disburse')
                    }
                });


        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../../assets/img/blank.png";
            }
        },
        onImgError() {
            this.imgError = true;
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.commnetState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.disburse = {};
            this.row_details = {};
            this.multiple_user_id = '';
            this.selected_row = [];
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleDisburseOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleDisbburseSubmit()
        },
        handleSubmit(event) {
            event.preventDefault()
            // Push the name to submitted names
            /*
                1 => Requested',
                2 => Called',
                3 => Verified',
                4 => ’Unverified',
                5 => 'Disbursed',
                6 => 'Rejected'
            */
            let form = new FormData();
            form.append("comment", this.form.comment);
            form.append("withdrawal_request_id", this.single_withdrawlRequest.withdrawal_request_id)
            form.append("user_id", this.single_withdrawlRequest.user_account_information.user_id)
            form.append("module_type", "withdrawal-request");

            if (this.request_status_numeric == 6) {
                form.append("action_type", "reject");
                form.append("status", 6);
            } else if (this.request_status_numeric == 4) {
                form.append("action_type", "unverify");
                form.append("status", 4);
            } else if (this.request_status_numeric == 3) {
                form.append("action_type", "verify");
                form.append("status", 3);
            }


            // withdrawal_status_fd.append("action_type", "reject");
            // withdrawal_status_fd.append("comment", _withdrawal_data.comment);
            // withdrawal_status_fd.append("module_type", "withdrawal-request");
            // withdrawal_status_fd.append("withdrawal_request_id", _withdrawal_data.withdrawal_id);
            // withdrawal_status_fd.append("user_id", _withdrawal_data.user_id);
            // withdrawal_status_fd.append("status", 6);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/manage/request?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getWithdralRequest();
                        this.$bvModal.hide('modal-lg')
                        this.$snotify.success('Withdrawal Request Successfully ' + response.data.data.request_status);
                    })
            })
        },
        disburseRequest(event) {
            event.preventDefault()
            // Push the name to submitted names
            let withdrawal_status_fd = new FormData();
            withdrawal_status_fd.append("action_type", "disburse");
            withdrawal_status_fd.append("comment", this.disburse.comment);
            withdrawal_status_fd.append("module_type", "withdrawal-request");
            // withdrawal_status_fd.append("withdrawal_request_id", this.single_withdrawlRequest.id);
            withdrawal_status_fd.append("user_id", this.single_withdrawlRequest.user_id);
            withdrawal_status_fd.append("withdrawal_amount", this.disburse.amount);
            withdrawal_status_fd.append("bank_tran_id", this.disburse.transaction_id);
            withdrawal_status_fd.append("card_type", this.disburse.transaction_type);
            withdrawal_status_fd.append("card_brand", this.disburse.banking_name);
            withdrawal_status_fd.append("status", 5);
            withdrawal_status_fd.append("ref_table_id", this.ref_table_id);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/manage/request?api_token=${localStorage.getItem('api_token')}`, withdrawal_status_fd)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.disburse = {}
                        this.resetModal();
                        this.getWithdralRequest();
                        this.$bvModal.hide('modal-disburse')
                        this.$snotify.success('Withdrawal Request Successfully Disburse');
                    })
            })
        },
        handleDisbburseSubmit() {
            // this.row_details.map(item => {
            //     this.row.bank_account_id = item.bank_account_id
            // });
            //
            // let dataFormat = { users:[]};
            this.row_details.forEach((user) => {
                this.disburse_form.users.push(
                    {
                        user_id: user.user_id,
                        bank_account_id: user.bank_account_id,
                        amount: user.withdrawal_amount,
                        ref_table_id: user.wallet_id,
                        user_type: 'doctor',
                    }
                )
            })
            // this.row_details.map(item => {
            //     this.row.withdrawal_amount = item.withdrawal_amount
            // });

            // this.row_details.forEach(item => {
            //
            //     this.disburse_form.users.push(
            //         {
            //             'user_id': item.user_id,
            //             "bank_account_id": item.bank_account_id,
            //             "amount": item.withdrawl_amount,
            //             "ref_table_id": item.wallet_id,
            //             "user_type": "doctor",
            //         });
            // });

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/manage/multiple-request?api_token=${localStorage.getItem('api_token')}`, this.disburse_form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.disburse = {}
                        this.resetModal();
                        this.getWithdralRequest();
                        this.$bvModal.hide('modal-disburse')
                        this.$snotify.success('Withdrawal Request Successfully Disburse');
                    })
            })
        },
        accountCreate(event) {
            event.preventDefault()
            let form = new FormData();
            form.append('bank_account_type_id', this.account_info.bank_account_type_id);
            form.append('account_name', this.account_info.account_name);
            form.append('account_no', this.account_info.account_number);
            if (this.account_info.bank_account_type_id == 1) {

                form.append('branch_name', this.account_info.branch_name);
                form.append('bank_id', this.account_info.bank_id);
            }
            this.$nextTick(() => {
                axios.post(`${process.env.VUE_APP_URL}/api/v1/payment/user/account/add?api_token=${this.patient_api_token}`, form)
                    .then(res => {
                        if (res.data.status_code == 200) {
                            this.getWithdralRequest();
                            this.$snotify.success('Successfully added');
                            this.$bvModal.hide('open-back-account-form')

                        }
                    })
            });
        },
        getDetails(user_id) {
            var url = `${process.env.VUE_APP_API_URL}/api/v1/payment/withdrawal/doctor/details?api_token=${localStorage.getItem('api_token')}&user_id=${user_id}`;
            axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.row_details = response.data.data;
                    }
                })
        },
        getMulttipleDetails(user_id) {
            var url = `${process.env.VUE_APP_API_URL}/api/v1/payment/withdrawal/doctor/details?api_token=${localStorage.getItem('api_token')}&user_ids=${user_id}`;
            axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.row_details = response.data.data;


                    }
                })
        },
        viewDetails(item) {
            this.getDetails(item.user_id)
            this.$bvModal.show('view-details')
        },
        selectMultipleRow(item) {
            this.selected_rows.push(item.user_id);
            let data = this.selected_row.join(',')
            this.multiple_user_id = data;
        },
        disburseModalOpen() {

            this.getMulttipleDetails(this.multiple_user_id)

            this.$forceUpdate();
            this.$bvModal.show('disburse-model')

        },
        disburseEqually() {
            if (this.disburse_form.disburse_equally == 1) {
                var total_items = this.row_details.length;
                var single_person_amount = this.disburse_form.amount / total_items;
                this.row.withdrawal_amount = this.row_details.map(item => {
                    item.withdrawal_amount = single_person_amount.toFixed(2);
                });
                this.$forceUpdate();
            } else {
                this.withdrawl_amount = this.row_details.map(item => {
                    item.withdrawal_amount = '';
                });
            }
        },
        delRowDetailsItem(index) {
            this.row_details.splice(index, 1);
        }
    },
}
