<template>
  <div>
    <div class="card mt-5">
      <div class="card-body">
        <div class="row mt-5 align-items-center justify-content-between">
          <div class="col-md-4">
            <label for="">From</label>
            <input type="date" v-model="startDate" class="form-control">
          </div>
          <div class="col-md-4">
            <label for="">To</label>
            <input type="date" v-model="endDate" class="form-control">
          </div>
          <div class="col-md-4">
            <b-button @click="getPrescription" variant="primary" class="mt-7 mx-3">Submit</b-button>
            <b-button @click="dateRangeClear" variant="danger" class="mt-7">Clear</b-button>
          </div>
        </div>

        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>


              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="Prescription List">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>


            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>

        <b-table
            responsive
            striped hover
            :items="filtered"
            :fields="fields"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-5">


          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <p v-if="field.key == 'action' || field.key == 'status' || field.key=='is_paid' "></p>
              <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
            </td>
          </template>

          <template #cell(created_date)="row">
            <div class="">
              {{ row.item.created_date }} {{ row.item.created_at }}
            </div>
          </template>

          <template #cell(patient_details)="row">
            <div class="">
              <b-media tag="li">
                <template #aside>
                  <b-img :src="userPhoto(row.item.patient_details.image)" class="user_image"
                         :alt="row.item.patient_details.fullname"
                         v-if="row.item.patient_details.image"></b-img>


                  <img src="/../../assets/img/blank.png" class="user_image" v-else/>
                </template>
                <h5 class="mt-0 mb-1">{{ row.item.patient_details.fullname }}</h5>
                <ul class="mb-0 user_info">
                  <!--                  <li><i class="fas fa-user"></i> {{ row.item.patient_details.username }}</li>-->
                  <li>
                    <!--                    <i class="fas fa-phone-volume"></i>-->
                    {{ row.item.patient_details.phone_number }}
                  </li>
                </ul>
              </b-media>
              <div v-if="row.item.prescription_for_patient_details">
                <p class="text-center">
                  <b-badge href="#" variant="primary" class="text-center">Parent</b-badge>
                </p>
                <b-media tag="li">
                  <template #aside>
                    <b-img :src="userPhoto(row.item.prescription_for_patient_details.image)" class="user_image"
                           :alt="row.item.prescription_for_patient_details.fullname"
                           v-if="row.item.prescription_for_patient_details.image"></b-img>
                    <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>
                  </template>
                  <h5 class="mt-0 mb-1">{{ row.item.prescription_for_patient_details.fullname }}</h5>
                  <ul class="mb-0 user_info">
                    <!--                  <li><i class="fas fa-user"></i> {{ row.item.patient_details.username }}</li>-->
                    <li>
                      <!--                    <i class="fas fa-phone-volume"></i>-->
                      {{ row.item.prescription_for_patient_details.phone_number }}
                    </li>
                  </ul>
                </b-media>
              </div>
              <div v-else>
                <p>Self</p>
              </div>
              <div>
                <!--                <div class="accordion" role="tablist">-->
                <!--                  <b-card no-body class="mb-1">-->
                <!--                    <b-card-header header-tag="header" class="p-1" role="tab">-->
                <!--                      <b-button block class="btn-sm" v-b-toggle="`accordion-${row.item.prescription_id}`" variant="info">{{ row.item.prescription_for_patient_details ? 'Patient for' : 'Self'}}</b-button>-->
                <!--                    </b-card-header>-->
                <!--                    <b-collapse :id="`accordion-${row.item.prescription_id}`" accordion="my-accordion" role="tabpanel">-->
                <!--                      <b-card-body>-->
                <!--                        <b-card-text>-->
                <!--                          <b-media tag="li">-->
                <!--                            <template #aside>-->
                <!--                              <b-img :src="userPhoto(row.item.prescription_for_patient_details.image)" class="user_image" alt="placeholder"-->
                <!--                                     v-if="row.item.prescription_for_patient_details.image"></b-img>-->
                <!--                              <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>-->
                <!--                            </template>-->
                <!--                            <h5 class="mt-0 mb-1">{{ row.item.prescription_for_patient_details.fullname }}</h5>-->
                <!--                            <ul class="mb-0 user_info">-->
                <!--                              <li><i class="fas fa-user"></i> {{ row.item.prescription_for_patient_details.username }}</li>-->
                <!--                              <li><i class="fas fa-phone-volume"></i> {{ row.item.prescription_for_patient_details.phone_number }}</li>-->
                <!--                            </ul>-->
                <!--                          </b-media>-->
                <!--                        </b-card-text>-->
                <!--                      </b-card-body>-->
                <!--                    </b-collapse>-->
                <!--                  </b-card>-->
                <!--                </div>-->
              </div>
            </div>
          </template>

          <template #cell(doctor_details)="row">
            <div class="">
              <b-media tag="li">
                <template #aside>
                  <b-img :src="userPhoto(row.item.doctor_details.image)" class="user_image" alt="placeholder"
                         v-if="row.item.doctor_details.image"></b-img>
                  <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>
                </template>
                <h5 class="mt-0 mb-1">{{ row.item.doctor_details.fullname }}</h5>
                <ul class="mb-0 user_info">
                  <!--                  <li><i class="fas fa-user"></i>{{ row.item.doctor_details.username }}</li>-->
                  <li>
                    <!--                    <i class="fas fa-phone-volume"></i> -->
                    {{ row.item.doctor_details.phone_number }}
                  </li>
                </ul>
              </b-media>
            </div>
          </template>

          <template #cell(status)="row">
            <div class="">
              <b-badge variant="success" v-if="row.item.status == true">Valid</b-badge>
              <b-badge variant="danger" v-else>Invalid [ incomplete ]</b-badge>
            </div>
          </template>

          <template #cell(prescription_identifier)="row">
            <div class="">
              <a class="text-info font-weight-bold" :href="prescription_url(row.item.prescription_url)" target="_black">
                {{ row.item.prescription_identifier }} </a>
            </div>
          </template>

          <template #cell(follow_update)>
            <div class="">
              <b-badge> n/a</b-badge>
            </div>
          </template>

          <template #cell(is_paid)="row">
            <div class="">
              <b-badge variant="success" v-if="row.item.is_paid == 1">Paid</b-badge>
              <b-badge variant="info" v-else-if="row.item.is_paid == 2">Partial Paid</b-badge>
              <b-badge variant="danger" v-else> Unpaid</b-badge>
            </div>
          </template>

          <template #cell(action)="row">
            <div class="">
              <b-dropdown variant="primary" class="m-2" no-caret id="dropdown-dropleft" dropleft
                          text="Drop-Left">
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item href="#" @click="sendInfo(row.item)" v-b-modal.modal-lg>Reset Status</b-dropdown-item>
              </b-dropdown>
            </div>
          </template>


        </b-table>

        <p>Total : {{ items.length }}</p>


      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              size="lg"
              class="my-0"
              @change="handlePageChange"

          ></b-pagination>


        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal id="modal-lg" size="lg" title="Reset Prescription Status to invalid" hide-footer>
      <b-form @submit="invalidPrescription" @reset="onReset" v-if="show">

        <h4 class="mb-10">Prescription identifier :
          <b-badge variant="warning" pill>{{ prescription_details.prescription_identifier }}</b-badge>
        </h4>

        <b-form-group
            label="Comment">
          <b-form-textarea
              id="textarea"
              v-model="form.comment"
              placeholder="Enter something..."
              rows="3"
          ></b-form-textarea>
        </b-form-group>


        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {PerDoctorWisePrescriptionMixin} from "../../mixins/PerDoctorWisePrescriptionMixin";

export default {
  name: "PerDoctorWisePrescription",
  mixins: [PerDoctorWisePrescriptionMixin]
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.user_icon i {
  font-size: 10px;
}

.user_info li {

  margin: 5px 0px;
}

.user_info li i {
  padding: 4px 6px;
  font-size: 10px;
  color: #44A0FF;
  border-radius: 50%;
  background-color: rgba(230, 242, 255, 0.9);
  margin-right: 5px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>