import axios from "axios";
import moment from "moment";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const OrkoTransactionReport = {
    data() {
        return {
            json_fields: {
                "Doctor name": {
                    field: "doctor_name",
                },
                "Doctor phone": {
                    field: "doctor_phone",
                },
                "Unique id": {
                    field: "unique_id",
                },
                "Transaction id": {
                    field: "transaction_id",
                },
                "Service fee": {
                    field: "service_fee",
                },
                "Service type": {
                    field: "service_type",
                },
                "Service description": {
                    field: "service_description",
                },
                "Is paid": {
                    field: "is_paid",
                },
                "Paid amount": {
                    field: "paid_amount",
                },
                "Transaction amount": {
                    field: "transaction_amount",
                },
                "Transaction date": {
                    field: "transaction_date",
                },
                "Actual discount amount": {
                    field: "actual_discount_amount",
                },
                "Discount in percentage": {
                    field: "discount_in_percentage",
                },
            },
            paid_amount: '',
            list: null,
            listLoading: true,
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            currentPage: 1,
            perPage: 30,
            pageOptions: [30, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                index: '',
                transaction_date: '',
                doctor_name: '',
                unique_id: '',
                doctor_phone: '',
                prescription_identifier: '',
                service_type: '',
                discount: '',
                paid_amount: '',
                transaction_amount: '',
                transaction_id: '',
            },
            items: [],
            loading: true,
            name: '',
            nameState: null,
            submittedNames: [],
            groups: [
                {
                    id: 1,
                    name: "Users"
                }
            ],
            fields: [
                {
                    key: 'index',
                    label: 'SL#',
                    sortable: true
                },
                {
                    key: 'doctor_name',
                    label: 'Doctor name',
                    sortable: true
                },
                {
                    key: 'doctor_phone',
                    label: 'Doctor phone',
                    sortable: true
                },
                {
                    key: 'unique_id',
                    label: 'Unique id',
                    sortable: true
                },
                {
                    key: 'transaction_id',
                    label: 'Transaction id',
                    sortable: true
                },
                {
                    key: 'service_type',
                    label: 'Service type',
                    sortable: true
                },
                {
                    key: 'service_fee',
                    label: 'Service fee',
                    sortable: true
                },
                {
                    key: 'is_paid',
                    label: 'Is paid',
                    sortable: true
                },
                {
                    key: 'paid_amount',
                    label: 'Paid amount',
                    sortable: true
                },
                {
                    key: 'transaction_amount',
                    label: 'Transaction amount',
                    sortable: true
                },


                {
                    key: 'transaction_date',
                    label: 'Transaction date',
                    sortable: true
                },
                {
                    key: 'actual_discount_amount',
                    label: 'Actual discount amount',
                    sortable: true
                },
                {
                    key: 'discount_in_percentage',
                    label: 'Discount in percentage',
                    sortable: true
                },
            ],
            filter: null,
            filterOn: [],
            startDate: null,
            endDate: null,
            selectedType: '',
            from_date: '',
            to_date: '',
            trxn_amount_sum: '',
            total_count: 0,
        }
    },
    mounted() {
        this.getOrkoTransactionReport();
    },
    created() {

        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Transaction report", route: "/transaction-report"},
        ]);
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        rows() {
            return this.items.length
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.transaction_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>

                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                index: '',
                transaction_date: '',
                doctor_name: '',
                unique_id: '',
                doctor_phone: '',
                prescription_identifier: '',
                service_type: '',
                paid_amount: '',
                transaction_amount: '',
                discount: '',
                transaction_id: '',
            }]
        },

        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        }
    },
    methods: {
        getOrkoTransactionReport() {
            if (this.startDate == null && this.endDate == null) {
                this.startDate = moment().startOf('month').format('YYYY-MM-DD');
                this.endDate = moment().endOf('month').format('YYYY-MM-DD');
            }
            axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/transaction/report?start_from=${this.startDate}&end_to${this.endDate}&api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=0`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false
                        this.items = response.data.data.report;
                        this.paid_amount = response.data.data.paid_amount_sum;
                        this.total_count = response.data.data.total_count;
                        this.trxn_amount_sum = response.data.data.trxn_amount_sum;
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true;
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            // var correctValue = offset * this.perPage;

            axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/transaction/report?${this.startDate ? 'start_from=' + this.startDate + '' : ''}${this.endDate ? '&end_to=' + this.endDate + '' : ''}&api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=${correctValue}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false
                        this.items = response.data.data.report;
                        this.paid_amount = response.data.data.paid_amount_sum;
                        this.trxn_amount_sum = response.data.data.trxn_amount_sum;
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },
        clearDate() {
            this.startDate = null;
            this.endDate = null;
        }
    },

}