<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <!--begin::Details-->
            <div class="d-flex">
              <!--begin: Pic-->
              <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                <div class="symbol symbol-50 symbol-lg-120">
                  <img :src="currentUserPhoto(basic_info.image)" alt="image"/>

                </div>
              </div>
              <!--end::Pic-->
              <!--begin::Info-->
              <div class="flex-grow-1">
                <!--begin::Title-->
                <div class="d-flex justify-content-between flex-wrap mt-1">
                  <div class="d-flex mr-3">
                    <a
                        href="#"
                        class="text-dark-75  font-size-h5 font-weight-bold mr-3">
                      <span class="font-size-h3">{{ basic_info.fullname ? basic_info.fullname : 'Not set yet' }}</span>
                      <span v-b-tooltip.hover.bottom="'User type'"> ({{
                          basic_info.user_type ? basic_info.user_type : 'Not set yet'
                        }})</span>
                      <br>
                      <span v-if="basic_info.is_doctor" class="font-size-h6"> Card Intro : {{
                          basic_info.doctor_information.card_intro ? basic_info.doctor_information.card_intro : 'Not set yet'
                        }}</span>
                    </a>

                  </div>
                </div>
                <!--end::Title-->

                <!--begin::Content-->
                <div class="d-flex flex-wrap justify-content-between mt-1">
                  <div class="d-flex flex-column flex-grow-1 pr-8">
                    <!--                    <div class="d-flex flex-wrap mb-4">-->
                    <div>
                      <div class="row">
                        <div class="col-md-10">
                          <div class="d-flex flex-wrap">
                            <a
                                href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Email'"
                            ><i class="flaticon2-new-email mr-2 font-size-lg"></i
                            >{{ basic_info.email ? basic_info.email : 'Not set yet' }}</a>
                            <a href="#"
                                class="text-dark-50  font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                v-b-tooltip.hover.bottom="'Phone number'"
                            ><i class="flaticon2-phone mr-2 font-size-lg"></i>
                              {{ basic_info.phone_number ? basic_info.phone_number : 'Not set yet' }}
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <!--end::Info-->
            </div>
            <!--end::Details-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicInfo",
  props: {
    basic_info: {},
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    currentUserPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
      return process.env.BASE_URL + 'media/logos/logo.svg';
    }
  }
}
</script>

<style scoped>

</style>