<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <h2>Medicine Generics</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add medicine
              generics
            </b-button>
            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a new medicine generics"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Medicine generics title is required"
                    :state="titleState">
                  <label for="title-input">Medicine generics title <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.title"
                      :state="titleState"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="dose_child-input">
                  <label for="dose_child-input">Dose child</label>
                  <b-form-input
                      id="dose_child-input"
                      v-model="form.dose_child"
                  />
                </b-form-group>

                <b-form-group
                    label-for="dose_adult-input">
                  <label for="dose_adult-input">Dose Adult</label>
                  <b-form-input
                      id="dose_adult-input"
                      v-model="form.dose_adult"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="">Medicine Class<span class="text-danger">*</span></label>
                  <v-select
                      :options="medicine_class"
                      :reduce="medicine_class => medicine_class.id"
                      v-model="form.medicine_class_id"
                      label="title"
                      required>
                  </v-select>
                  <p class="text-danger">{{ medicine_class_is_required }}</p>
                </b-form-group>

                <b-form-group>
                  <label for="">Definition or pharmacology</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.definition_or_pharmacology"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Mode of action</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.mode_of_action"
                      placeholder="Enter something..."
                      rows="3"

                  ></b-form-textarea>
                </b-form-group>


                <b-form-group>
                  <label for="">Interaction</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.interaction"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Side effects</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.side_effects"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Precautions</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.precautions"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Pregnancy lactation</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.pregnancy_n_lactation"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Dosage admin</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.dosage_n_admin"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Dosage</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.dosage"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Administration</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.administation"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Contraindications</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.contraindications"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Overdose effects</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.overdose_effects"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Storage conditions</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.storage_conditions"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Compositions</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.compositions"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Use in special population</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.use_in_special_population"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Description</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.description"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Duration of treatment</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.duration_of_treatment"
                      placeholder="Enter something..."
                      rows="3"

                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Reconstitutions</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.reconstitutions"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>


              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Medicine Generics"
                  name="Medicine Generics">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class=" mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>


              <template #cell(medicine_class_id)="data">
                {{ data.item.medicine_class ? data.item.medicine_class.title : '' }}
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">

                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>
                      <b-dropdown-item href="#" @click="editAction(row)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <b-dropdown-item :href="`/medicine-generics/${row.item.id}`">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>View details
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {MedicineGenericsMixin} from "../../mixins/medicine/MedicineGenericsMixin";

export default {
  name: "MedicineGenerics",
  mixins: [MedicineGenericsMixin],
}
</script>