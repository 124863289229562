<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Product list</h2>
        <div class="d-flex justify-content-end">
          <div class="mx-2">
            <b-button variant="primary" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new product</b-button>
          </div>
          <div class="mx-2">
            <download-excel
                :data="items"
                class="btn btn-primary"
                :fields="json_fields"
                worksheet="Institute list"
                name="Institute list">
              <i class="fas fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </div>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a new product"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">

              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">


                <b-form-group
                    label="Title"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                    :state="title">
                  <b-form-input
                      id="name-input"
                      v-model="form.title"
                      :state="title"
                      required/>
                </b-form-group>

                <b-form-group
                    label="Title Bangla"
                    label-for="name-input"
                    invalid-feedback="Title is required"
                    :state="title">
                  <b-form-input
                      id="name-input"
                      v-model="form.title_bn"
                      :state="title"
                      required/>
                </b-form-group>


                <b-form-group>
                  <label for="">Intro en</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.intro_en"
                      placeholder="Enter address"
                      rows="3"
                      class="mt-3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group>
                  <label for="">Intro bn</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.intro_bn"
                      placeholder="Enter address"
                      rows="3"
                      class="mt-3"
                  ></b-form-textarea>
                </b-form-group>

                <!--                <ckeditor :editor="editor" v-model="form.description"></ckeditor>-->
                <!--                <div>-->
                <!--                  <label for="">Description en</label>-->
                <!--                  <vue-editor v-model="form.description"></vue-editor>-->
                <!--                </div>-->
                <!--                <div>-->
                <!--                  <label for="">Description bn</label>-->
                <!--                  <vue-editor v-model="form.description_bn"></vue-editor>-->
                <!--                </div>-->

                <p class="mb-0">Description</p>
                <div v-for="(item, index) in productDescriptions" :key="index" class="mb-2">
                  <div class="d-flex">
                    <input type="text" class="form-control"
                           v-model="item.description">
                    <button v-on:click="deleteGroup(index)" class="btn btn-danger" type="button"> X</button>
                  </div>
                </div>
                <div class="text-right">
                  <button v-on:click="addGroup" class="btn btn-info" type="button">Add</button>
                </div>
                <div>
                  <b-form-group>
                    <label for="">Price</label>
                    <b-form-input
                        id="name-input"
                        v-model="form.price"/>
                  </b-form-group>
                </div>


              </form>
            </b-modal>
          </div>
        </div>
        <div class="row  justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>

          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                class="table-responsive mt-10"
                show-empty>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>


              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
                </td>
              </template>

              <template #cell(image)="row">
                <div class="">
                  <b-img :src="userPhoto(row.item.image)" class="user_image" :alt="row.item.title"
                         v-if="row.item.image"></b-img>
                  <img src="../../assets/img/blank.png" class="user_image" v-else/>
                </div>
              </template>

              <template #cell(description)="row">
                <div>
                  <p v-for="(description, index) in row.item.description" :key="index">
                    {{ description.description }}
                  </p>
                </div>
              </template>

              <template #cell(action)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">

                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>
                      <b-dropdown-item href="#" @click="editAction(row.item)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <b-dropdown-item :to="`/product/permissions/${row.item.id}`">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Assign Permissions
                      </b-dropdown-item>
                      <!--                      <b-dropdown-item href="#" @click="deleteAction(row.item)">Delete</b-dropdown-item>-->
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="items.length"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {ProductMixin} from "../../mixins/ProductMixin";
// import Editor from '@tinymce/tinymce-vue'
import {VueEditor} from "vue2-editor";


export default {
  name: "ManageProduct",
  mixins: [ProductMixin],
  components: {
    VueEditor
  },

}
</script>

<style scoped>

</style>