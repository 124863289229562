import axios from "axios";

export const SalesUserMixins = {
    data() {
        return {
            sales_users: [],
            sales_user_id: '',
        }
    },
    created() {
        this.getSalesUsers();
    },
    methods: {
        getSalesUsers() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&user_type=agent_sales`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.sales_users = response.data.data
                    }
                })
        }
    }
}
