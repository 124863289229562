<template>
  <div>

    <div class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <h2>Institute type list</h2>
          <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new institute type
          </b-button>
          <b-modal
              id="modal-1"
              size="lg"
              title="Create a new institute type"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

              <b-form-group

                  label-for="name-input"
                  invalid-feedback="Bank name is required"
                  :state="nameState">
                <label for=""> Name <span class="text-danger">*</span></label>
                <b-form-input
                    id="name-input"
                    v-model="form.name"
                    :state="nameState"
                    required/>
              </b-form-group>
              <b-form-group class="mt-5" :state="detailsState" invalid-feedback="Bank address is required">
                <label for="">Details <span class="text-danger">*</span></label>
                <b-form-textarea
                    id="textarea"
                    v-model="form.details"
                    placeholder="Enter Details"
                    rows="3"
                    class=""
                    :state="detailsState"
                    required
                ></b-form-textarea>
              </b-form-group>


            </form>
          </b-modal>
        </div>
        <div class="row mt-3 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Institute type"
                  name="Institute type">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="table-responsive mt-3">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(status)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.status == 1">Active</b-badge>
                  <b-badge variant="success" v-else>Inactive</b-badge>
                </div>
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'bank_logo'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {InstituteTypeMixin} from "../../mixins/InstituteTypeMixin";

export default {
  name: "InstituteType",
  mixins: [InstituteTypeMixin]
}
</script>

<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>