<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <h2>Subscription package</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new package
            </b-button>
            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="xl"
                title="Create a new subscription package"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Title is required"
                    :state="titleState">
                  <label for="title-input">Title <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.title"
                      :state="titleState"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Title is required"
                    :state="titleState">
                  <label for="title-input">Description <span class="text-danger">*</span></label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.description"
                      placeholder="Enter something..."
                      rows="3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Title is required"
                    :state="titleState">
                  <label for="title-input">Price <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.price"
                      :state="titleState"
                      required/>
                </b-form-group>

                <b-form-group>
                  <label for="">Discount type</label>
                  <b-form-select v-model="form.discount_type" :options="discount_type"></b-form-select>
                </b-form-group>

                <b-form-group
                    label-for="discount-input">
                  <label for="discount-input">Discount</label>
                  <b-form-input
                      id="discount-input"
                      v-model="form.discount"/>
                </b-form-group>

                <b-form-group>
                  <label for="">Select offer</label>
                  <v-select
                      :options="offers"
                      :reduce="offers => offers.id"
                      v-model="form.offer_id"
                      label="title"
                      required>
                  </v-select>
                </b-form-group>

                <b-form-group
                    label-for="expiry-input">
                  <label for="expiry-input">Expiry duration</label>
                  <b-form-input
                      id="expiry-input"
                      v-model="form.expiry_duration"/>
                </b-form-group>

                <b-form-group>
                  <label for="">Package duration type</label>
                  <b-form-select v-model="form.package_duration_type" :options="package_duration_type"></b-form-select>
                </b-form-group>

                <b-form-group>
                  <label for="">Select status</label>
                  <b-form-select v-model="form.status" :options="status"></b-form-select>
                </b-form-group>

                <div class="row">
                  <div class="col-md-12">

                    <div class="card p-5">
                      <h3>Add package features</h3>
                      <div class="row align-content-center align-items-center mb-5"
                           v-for="(feature, index) in features" :key="index">

                        <div class="col-md-3">
                          <b-form-group
                              id="fieldset-1"
                              label="Title"
                              label-for="title"
                              class="mb-0">
                            <b-form-input id="title" type="text"
                                          v-model="feature.title"></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-md-2">
                          <b-form-group
                              id="fieldset-1"
                              label="Target count"
                              label-for="target_count"
                              class="mb-0">
                            <b-form-input id="target_count" type="text"
                                          v-model="feature.target_count"></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-md-2">
                          <b-form-group
                              id="fieldset-1"
                              label="Per target cost"
                              label-for="per_target_cost"
                              class="mb-0">
                            <b-form-input id="target_count" type="text"
                                          v-model="feature.per_target_cost"></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-md-3">
                          <b-form-group
                              id="fieldset-1"
                              label="Description"
                              label-for="description"
                              class="mb-0">
                            <b-form-input id="target_count" type="text"
                                          v-model="feature.description"></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-md-2">
                          <button type="button" class="btn btn-danger mt-8" @click="removeItem(feature)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <b-button variant="info" class="float-right mt-2" @click="AddItem()">Add more</b-button>


              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Subscription offer"
                  name="Subscription offer">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                responsive
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class=" mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(status)="data">
                <b-badge variant="success" v-if="data.item.status == 1">Active</b-badge>
                <b-badge variant="danger" v-else>Inactive</b-badge>
              </template>

              <template #cell(expiry_duration)="data">
                <p>{{ data.item.expiry_duration }} days</p>
              </template>

              <template #cell(discount)="data">
                <p>{{ data.item.discount }} {{ data.item.discount_type }}</p>
              </template>

              <template #cell(offer_id)="data">

                <p class="mb-0"><b>Offer: </b> {{ data.item.subscription_offer ? data.item.subscription_offer.title : '' }}</p>
                <p class="mb-0"><b>Price: </b> {{ data.item.subscription_offer ? data.item.subscription_offer.price : '' }}</p>
                <p class="mb-0"><b>Start date: </b> {{ data.item.subscription_offer ? data.item.subscription_offer.start_date : '' }}</p>
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <div>
                      <b-dropdown-item href="#" @click="editAction(row)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="deleteAction(row)">
                        <span class="pr-4"><i class="fas fa-trash"></i></span>Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {SubscriptionPackageMixin} from "../../mixins/subscription/SubscriptionPackageMixin";

export default {
  name: "SubscriptionPackage",
  mixins: [SubscriptionPackageMixin],
}
</script>

<style scoped>

</style>