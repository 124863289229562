import { render, staticRenderFns } from "./PrescriptionDashboard.vue?vue&type=template&id=4ed66c48&scoped=true"
import script from "./PrescriptionDashboard.vue?vue&type=script&lang=js"
export * from "./PrescriptionDashboard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed66c48",
  null
  
)

export default component.exports