import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters("auth", ["usrPerms"]),
    },
    methods: {
        checkUsrPermissions(reqPermission) {
            if (!reqPermission) return true;
            return this.usrPerms.includes(reqPermission);
        },
    },
};
