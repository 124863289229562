import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const SubscriptionFeatureMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
                "Price": {
                    field: "price",
                },
                "Start date": {
                    field: "start_date",
                },
                "End date": {
                    field: "end_date",
                },
                "Status": {
                    field: "status",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Price',
                    key: 'price',
                    sortable: true,
                },
                {
                    label: 'Start date',
                    key: 'start_date',
                    sortable: true,
                },
                {
                    label: 'End date',
                    key: 'end_date',
                    sortable: true,
                },
                {
                    label: 'Status',
                    key: 'status',
                    sortable: true,
                },

                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            form: {
                title: '',
                description: '',
                price: '',
                target_count: '',
                per_target_cost: '',
                status: ''
            },
            edit: false,
            edit_id: '',
            titleState: null,
            descriptionState: null,
            priceState: null,

            targetCountState: null,
            features: [
                {
                    title: '',
                    target_count: '',
                    per_target_cost: '',
                    description: '',
                    status: '',
                }
            ],
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Subscription offer", route: "/subscription-offer"},
        ]);
    },
    created() {
        this.getSubscriptionOffer();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Subscription offer',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getSubscriptionOffer() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/subscription/feature/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data.data

                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.titleState = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if (this.edit) {
                return this.updateData();
            }
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            let form = new FormData();
            form.append("title", this.form.title);
            form.append("description", this.form.title);
            form.append("price", this.form.price);
            form.append("start_date", this.form.start_date);
            form.append("end_date", this.form.end_date);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/subscription/offer/store?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getSubscriptionOffer();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        updateData() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);
            form.append("description", this.form.title);
            form.append("price", this.form.price);
            form.append("start_date", this.form.start_date);
            form.append("end_date", this.form.end_date);
            // form.append("_method", "PUT");

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/subscription/offer/update/${this.edit_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getSubscriptionOffer();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully updated');
                    })
            })
        },
        editAction(row) {
            this.edit = true;
            this.$bvModal.show('modal-1');
            this.form = row.item
            this.form.title = row.item.title;
            this.edit_id = row.item.id;
        },
        deleteAction(row) {

            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v2/subscription/offer/delete/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getSubscriptionOffer();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        AddItem() {
            this.features.push({
                title: '',
                target_count: '',
                per_target_cost: '',
                description: '',
                status: '',
            });
        },
        removeItem(item) {
            let index = this.features.findIndex(
                u => u === item
            );
            this.sunday.splice(index, 1);
        },
    }
}