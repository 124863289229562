<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div>
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <h3
                    class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3"
                >
                  Medical Education
                </h3>
                <div>
                  <button class="btn btn-info" @click="openModal">Add</button>
                  <b-modal
                      id="educationModal"
                      :title="form.id ? 'Edit Education' : 'Add Education'"
                      @close="resetModal"
                      size="lg"
                      @hidden="resetModal"
                      @ok="handleOk"
                  >
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                      <div
                          class="row align-content-center align-self-center justify-content-between"
                      >
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label for=""
                            >Institute
                              <span class="text-danger">*</span></label
                            >
                            <v-select
                                :options="institutes"
                                :reduce="(institutes) => institutes.id"
                                v-model="form.institute_id"
                                label="name"
                                name="name"
                            >
                            </v-select>
                          </div>
                        </div>
                      </div>

                      <div class="mb-3">
                        <b-form-group label-for="name-input">
                          <label for=""
                          >Course <span class="text-danger">*</span>
                          </label>
                          <v-select
                              :options="degrees"
                              :reduce="(degrees) => degrees.id"
                              v-model="form.degree_id"
                              label="name"
                              name="name"
                              @input="checkDegree()"
                          >
                          </v-select>
                        </b-form-group>
                      </div>

                      <div class="mb-3" v-if="!is_mbbs">
                        <b-form-group label-for="name-input">
                          <label for=""
                          >Specialities <span class="text-danger">*</span>
                          </label>
                          <v-select
                              :options="specialitis"
                              :reduce="(specialitis) => specialitis.id"
                              v-model="form.speciality_id"
                              label="name"
                              name="name"
                              @input="resetSubSpeciality()"
                          >
                          </v-select>
                        </b-form-group>
                      </div>

                      <div class="mb-3" v-if="sub_specialitis.length && !is_mbbs">
                        <b-form-group label-for="name-input">
                          <label for=""
                          >Sub Specialities <span class="text-danger">*</span>
                          </label>
                          <v-select
                              :options="sub_specialitis"
                              :reduce="(sub_specialitis) => sub_specialitis.id"
                              v-model="form.sub_speciality_id"
                              label="name"
                              name="name"
                          >
                          </v-select>
                        </b-form-group>
                      </div>

                      <div class="mb-3">
                        <div class="row">
                          <div class="col-6">
                            <label for=""
                            >Starting year
                              <span class="text-danger">*</span></label
                            >
                            <b-form-input
                                type="number"
                                v-model="form.starting_year"
                                required
                            ></b-form-input>
                          </div>
                          <div class="col-6">
                            <label for="">Passing year</label>
                            <b-form-input
                                type="number"
                                v-model="form.passing_year"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </form>
                  </b-modal>
                </div>
              </div>
              <div class="my-lg-0 my-3">
                <!--                <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"-->
                <!--                          v-b-modal.educationModal> Add-->
                <!--                </b-button>-->
              </div>
            </div>
            <div class="row mt-10" v-if="educations.length">
              <div
                  class="col-md-6 mb-5"
                  v-for="(education, index) in educations"
                  :key="index"
              >
                <b-card
                    class=""
                    :img-src="
                    education.certificate_image
                      ? education.certificate_image
                      : ''
                  "
                    img-left
                >
                  <b-button
                      @click="openEditModal(education)"
                      class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3 edit_btn"
                  >
                    Edit
                  </b-button>
                  <b-card-text>
                    <h3>
                      {{ education.degree_name }} ({{ education.passing_year }})
                    </h3>

                    <p class="mb-2">
                      {{
                        education.institute
                            ? education.institute
                            : "Not yet set"
                      }}
                    </p>
                    <p class="mb-2">
                      Speciality:
                      <b>
                        {{
                          education.speciality
                              ? education.speciality
                              : "Not yet set"
                        }}
                      </b>
                    </p>
                    <p class="mb-2">
                      Sub speciality
                      <b>{{
                          education.sub_speciality
                              ? education.sub_speciality
                              : "Not yet set"
                        }}</b>
                    </p>
                  </b-card-text>
                </b-card>
              </div>
            </div>
            <div v-else>Not set yet</div>

            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Education",
  props: {
    educations: {},
    user: {},
  },
  data() {
    return {
      education: {},
      jobtitle: [],
      departments: [],
      institutes: [],
      degrees: [],
      specialitis: [],
      sub_specialitis: [],
      form: {
        degree_id: "",
        institute_id: "",
        speciality_id: "",
        sub_speciality_id: "",
        starting_year: "",
        passing_year: "",
      },
      all_speclities: [],
      is_mbbs: false,
    };
  },
  mounted() {
    this.getInstitute();
    this.getDegree();
    this.getSpeciality();
  },
  methods: {
    checkDegree() {
      let degree = this.degrees.find((item) => item.name === 'MBBS' && item.id === this.form.degree_id)
      if (degree) {
        this.is_mbbs = true
        this.form.sub_speciality_id = '';
        this.form.speciality_id = '';
      } else {
        this.is_mbbs = false;
      }
    },
    resetSubSpeciality() {
      this.form.sub_speciality_id = '';
      this.sub_specialitis = this.all_speclities.filter((item) => {
        if (item.parent_id == this.form.speciality_id) {
          return item;
        }
      });
    },
    resetModal() {
      this.departmentState = null;
      this.form = {};
      this.is_mbbs = false;
    },
    openModal() {
      this.$bvModal.show("educationModal");
    },
    openEditModal(education) {
      this.form = education;
      this.sub_specialitis = this.all_speclities.filter((item) => {
        if (item.parent_id == this.form.speciality_id) {
          return item;
        }
      });
      this.checkDegree();
      this.openModal();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    getInstitute() {
      axios
          .get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000`)
          .then((response) => {
            this.institutes = response.data.data;
          });
    },
    getDegree() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/degree`).then((response) => {
        this.degrees = response.data.data;
      });
    },
    getSpeciality() {
      axios
          .get(`${process.env.VUE_APP_URL}/api/v1/speciality?show_all=1`)
          .then((response) => {
            this.all_speclities = response.data.data;
            this.specialitis = response.data.data.filter((item) => {
              if (!item.parent_id) {
                return item;
              }
            });
          });
    },

    handleSubmit() {
      // if (!this.checkFormValidity()) {
      //   return
      // }
      this.$nextTick(() => {
        let url = "";
        let form = new FormData();
        if (this.form.hasOwnProperty("id")) {
          url = `${process.env.VUE_APP_URL}/api/v1/doctor/education/update/${this.form.id}?_method=put&api_token=${localStorage.getItem('api_token')}&user_id=${this.user.id}&platform=backoffice`;
        } else {
          form.append("education[0][degree_id]", this.form.degree_id);
          form.append("education[0][institute_id]", this.form.institute_id);
          form.append("education[0][speciality_id]", this.form.speciality_id);
          if (this.form.sub_speciality_id) {
            form.append(
                "education[0][sub_speciality_id]",
                this.form.sub_speciality_id
            );
          }

          form.append("education[0][starting_year]", this.form.starting_year);
          form.append("education[0][passing_year]", this.form.passing_year);
          form.append("api_version", "v1");
          url = `${process.env.VUE_APP_URL}/api/v1/doctor/education/add/details?user_id=${this.user.id}&platform=backoffice&api_token=${localStorage.getItem('api_token')}`;
        }
        axios.post(url, this.form.id ? this.form : form).then((response) => {
          if (response.data.status_code == 400) {
            return this.$snotify.error("Something went wrong");
          }
          this.resetModal();
          this.$emit("addNewExeprience", "state change");
          this.$bvModal.hide("educationModal");
          this.$snotify.success("Education Successfully saved");
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit_btn {
  position: absolute;
  right: 0;
  top: 10px;
}
</style>>
