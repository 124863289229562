<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex mb-9">


          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                    href="#"
                    class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                >{{ role_user_data ? role_user_data.name : '' }}</a
                >
                <a href="#"
                ><i class="flaticon2-correct text-success font-size-h5"></i
                ></a>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8" v-if="role_user_data.permissions">

                <div v-for="(permission, index) in role_user_data.permissions" :key="index" class="m-2">
                <b-badge class="mr-1" variant="danger">
                  <span v-for="(n, t ) in permission" :key="t">
                    {{ n }}
                  </span>
                </b-badge>
                </div>

              </div>
              <div>
                no permission set yet.
              </div>

            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "RoleDetails",

  props: ['id'],
  data() {
    return {
      role_user_data: {},
    }
  },
  created() {
    this.getRoleUserData();
  },
  methods: {
    getRoleUserData() {
      axios.get(`/api/v1/role/show/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.role_user_data = response.data.data
            this.loading = true;
          })
    }
  }
}
</script>

<style scoped>

</style>
