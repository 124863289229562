<template>
  <div>
    <div>
      <h3 class="mb-10">Connection Request - Pending list
        <b-badge variant="primary">{{ count }}</b-badge>
      </h3>
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-end">
            <download-excel
                :data="items"
                class="btn btn-primary"
                :fields="json_fields"
                worksheet="Connection request panding list"
                name="Connection request panding list">
              <i class="fas fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
          <div class="row align-items-center justify-content-between mt-5">

            <div class="col-md-1 mt-10">
              <div class="">
                <b-form-group
                    class="mb-0">
                  <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>
            </div>

            <div class="col-md-3">
              <label for="">From</label>
              <input type="date" v-model="startDate" class="form-control">
            </div>
            <div class="col-md-3">
              <label for="">To</label>
              <input type="date" v-model="endDate" class="form-control">
            </div>
            <div class="col-md-4 mt-10">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>
              </b-input-group>

            </div>
          </div>
          <b-table
              striped hover
              :items="filtered"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              thead-class="bg-primary text-white"
              show-empty
              class=" mt-10">


            <template slot="top-row" slot-scope="{ fields }" v-if="items.length">
              <td v-for="field in fields" :key="field.key">
                <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
              </td>
            </template>

            <template #cell(index)="row" v-if="items.length">
              {{ row.index + 1 }}
            </template>

            <template #cell(duration)="row">
              <p v-if="row.item.duration">
                {{ durationMenupulate(row.item.duration) }}
              </p>
            </template>

          </b-table>

        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="items.length"
              :per-page="perPage"
              size="lg"
              class="my-0"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div>
      <loader-component v-if="loading"/>

    </div>
  </div>
</template>

<script>
import {ConnectionRequestMixin} from '../../mixins/reports/ConnectionRequestMixin'

export default {
  name: "ConnectionRequestPendingList",
  mixins: [ConnectionRequestMixin],
  title: 'Connection Request',
}
</script>

<style scoped>

</style>