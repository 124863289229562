<template>
  <div>
    <div class="row mb-10">
      <div class="col-md-4">
        <div class="card border-0">
          <div class="card-body text-center">
            <h4 class="text-muted">Total Calls</h4>
            <h2>{{ widget_counter.total_calls }}</h2>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card border-0">
          <div class="card-body text-center">
            <h4 class="text-muted">Total Pending Calls Today</h4>
            <h2>{{ widget_counter.total_pending_today }}</h2>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card border-0">
          <div class="card-body text-center">
            <h4 class="text-muted">Total Pending Calls</h4>
            <h2>{{ widget_counter.total_pending_calls }}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <loader-component v-if="loading"/>
      <div class="card-body">
        <div class="row justify-content-between align-items-center mb-5">
          <div class="col-md-3">
            <label for="">Call Response</label>
            <b-form-select
                v-model="filter.call_response"
                :options="call_responses"
            ></b-form-select>
          </div>
          <div class="col-md-3">
            <label for="">Status</label>
            <b-form-select
                v-model="filter.status"
                :options="statuses"
            ></b-form-select>
          </div>
          <div class="col-md-6">
            <div class="d-flex align-items-center justify-content-between">
              <div class="w-100">
                <label for="">From</label>
                <input
                    type="date"
                    v-model="filter.start_date"
                    class="form-control"
                />
              </div>
              <div class="w-100 mx-2">
                <label for="">To</label>
                <input
                    type="date"
                    v-model="filter.end_date"
                    class="form-control"
                />
              </div>
              <div class="mt-8 d-flex">
                <button type="button" class="btn btn-info" @click="getItems">
                  Filter
                </button>
                <button
                    type="button"
                    class="btn btn-danger mx-5"
                    @click="clear"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped
                hover
                responsive
                :items="items"
                :fields="fields"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5"
            >
              <template #cell(fullname)="row">
                <p class="mb-0">{{ row.item.fullname }}</p>
                <b-badge variant="secondary">{{ row.item.user_type }}</b-badge>
                <span>{{ row.item.user_phone }}</span>
              </template>
              <template #cell(status)="row">
                <b-badge
                    :variant="row.item.status == 'called' ? 'success' : 'warning'"
                >{{ row.item.status }}
                </b-badge
                >
              </template>
              <template #cell(appointment_date)="row">
                <span>{{ (row.item.callable && row.item.callable.schedule_date)?dateFormat(row.item.callable.schedule_date):'N/A'}}</span>
              </template>
              <template #cell(call_response)="row">
                <b-badge variant="success" v-if="row.item.call_response"
                >Yes
                </b-badge
                >
                <b-badge variant="danger" v-else>No</b-badge>
              </template>
              <template #cell(call_category)="row">
                <b-badge
                    variant="info"
                    v-if="row.item.call_category == 'Report Upload'"
                >{{ row.item.call_category }}
                </b-badge
                >
                <b-badge
                    variant="warning"
                    v-if="row.item.call_category == 'Followup Appointment'"
                >{{ row.item.call_category }}
                </b-badge
                >
                <b-badge
                    variant="primary"
                    v-if="row.item.call_category == 'Patient No Show'"
                >{{ row.item.call_category }}
                </b-badge
                >
              </template>
              <template #cell(has_reminder)="row">
                <b-badge variant="success" v-if="row.item.has_reminder"
                >Yes
                </b-badge
                >
                <b-badge variant="danger" v-else>No</b-badge>
              </template>
              <template #cell(call_action_status)="row">
                <b-badge variant="success" v-if="row.item.call_action_status">{{
                    row.item.call_action_status
                  }}
                </b-badge>
                <b-badge variant="warning" v-else>Not Called Yet</b-badge>
              </template>
              <template #cell(created_at)="row">
                {{ createdAtFormat(row.item.created_at) }}
              </template>
              <template #cell(actions)="row">
                <b-dropdown
                    id="dropdown-dropleft"
                    no-caret
                    dropleft
                    text="Drop-top"
                    variant="primary"
                    class="m-2"
                >
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item
                      v-if="row.item.status == 'pending'"
                      href="javascript:void(0)"
                      @click="showCallModal(row.item)"
                      v-b-modal.modal-call
                  >
                    <span class="pr-4"
                    ><i class="fas fa-phone-volume"></i
                    ></span>
                    Call log
                  </b-dropdown-item>

                  <b-dropdown-item
                      href="javascript:void(0)"
                      @click="showCallDetails(row.item)"
                  >
                    <span class="pr-4"
                    ><i class="fas fa-eye"></i
                    ></span>
                    Call Details
                  </b-dropdown-item>

                  <!--                  <b-dropdown-item-->
                  <!--                      :to="`/crm/outbound/calls/${row.item.log_id}`"-->
                  <!--                  >-->
                  <!--                    <span class="pr-4"-->
                  <!--                    ><i class="fas fa-eye"></i-->
                  <!--                    ></span>-->
                  <!--                    View Details-->
                  <!--                  </b-dropdown-item>-->


                </b-dropdown>
              </template>
            </b-table>
            <b-pagination
                v-model="currentPage"
                :total-rows="total_count"
                :per-page="perPage"
                size="lg"
                @change="getItems"
                class="my-0"
                aria-controls="doctorList"
            ></b-pagination>
          </div>
        </div>
      </div>
      <vue-snotify></vue-snotify>
    </div>

    <b-modal
        v-model="viewCallModal"
        size="xl"
        title="Update call log"
        hide-footer
    >
      <b-form @submit.prevent="updateCallLog">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Response *</label>
              <select class="form-control" v-model="form.call_response">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Action Status*</label>
              <v-select
                  :options="actionStatuses"
                  :reduce="(status) => status.id"
                  v-model="form.call_action_status_id"
                  class="h-100"
                  label="title"
                  name="title"
              >
              </v-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox
                id="checkbox-1"
                v-model="form.has_reminder"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
            >
              Want a reminder?
            </b-form-checkbox>

            <div v-if="form.has_reminder == 1">
              <br/>
              <b-form-datepicker
                  id="example-datepicker"
                  v-model="form.reminder_date"
                  class="mb-2"
              ></b-form-datepicker>
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Remarks: *</label>
              <b-form-textarea
                  id="textarea"
                  v-model="form.remarks"
                  placeholder="Remarks:*"
                  rows="1"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="row mt-10">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <b-modal id="call-details" size="lg" title="Details">
      <div class="card mb-5">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h4>Assigned</h4>
              <hr>
              <h4>{{ call_details.assigned_crm_name }} </h4>
              <p class="mb-0"><b>Call Date: </b>{{ call_details.call_action_date }}</p>

              <p class="mb-0"><b>Call Status</b>{{ call_details.call_action_status }}</p>
              <p class="mb-0"><b>Call Category: </b>{{ call_details.call_category }}</p>
              <p class="mb-0"><b>Call Type: </b>{{ call_details.call_type }}</p>
              <p class="mb-0"><b>Has reminder: </b>
                <b-badge :variant="`${call_details.has_reminder == true ? 'success' : 'danger'}`">
                  {{ call_details.has_reminder == true ? 'Yes' : 'No' }}
                </b-badge>
              </p>
              <div v-if="call_details.has_reminder">
                <p class="mb-0">Reminder Date: {{ call_details.reminder_date }}</p>
                <p class="mb-0">Remarks: {{ call_details.remarks }}</p>
              </div>
              <p class="mb-0"><b>Status: </b>
                <b-badge variant="primary">{{ call_details.status }}</b-badge>
              </p>
            </div>
            <div class="col-md-6">
              <h4>User</h4>
              <hr>
              <h4>{{ call_details.fullname }}</h4>
              <p>{{ call_details.user_phone }}</p>
              <b-badge variant="primary">{{ call_details.user_type }}</b-badge>

            </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="call_details.callable">
        <div class="card-body">
          <h3>Call For</h3>
          <hr>
          <a :href="prescriptionUrl(call_details.callable.url)" target="_blank"><h4>
            {{ call_details.callable.prescription_identifier }}</h4></a>
          <p>{{ createdAtFormat(call_details.callable.created_at) }}</p>
          <p>Payment Status:
            <b-badge :variant="`${call_details.callable.is_paid == 1 ? 'success' : 'danger'}`">
              {{ call_details.callable.is_paid == 1 ? "Paid" : 'Unpaid' }}
            </b-badge>
          </p>
          <p>Followup Date: {{ call_details.callable.follow_up_date }}</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {DateFormat} from "../../mixins/core/DateFormat";

export default {
  name: "OutboundCalls",
  mixins: [DateFormat],
  data() {
    return {
      loading: false,
      viewCallModal: false,
      currentPage: 1,
      perPage: 20,
      items: [],
      fields: [
        {
          key: "fullname",
          label: "User",
          sortable: true,
        },
        {
          key: "assigned_crm_name",
          label: "Assigned",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
        {
          key: "appointment_date",
          label: "Appointment Date",
          sortable: true,
        },
        {
          key: "call_category",
          label: "Call Category",
          sortable: true,
        },
        {
          key: "call_type",
          label: "Call type",
          sortable: true,
        },
        {
          key: "call_response",
          label: "Call response",
          sortable: true,
        },
        {
          key: "call_action_status",
          label: "Call Action Status",
          sortable: true,
        },
        {
          key: "has_reminder",
          label: "Reminder",
          sortable: true,
        },
        {
          key: "call_action_date",
          label: "Call action date",
          sortable: true,
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
        },
        {
          key: "actions",
        },
      ],
      total_count: 0,
      widget_counter: {
        total_calls: 0,
        total_pending_calls: 0,
        total_pending_today: 0,
      },
      filter: {
        start_date: null,
        end_date: null,
        call_response: "",
        status: "",
      },
      call_responses: [
        {
          text: "- Select One -",
          value: "",
        },
        {
          text: "Yes",
          value: "1",
        },
        {
          text: "No",
          value: "0",
        },
      ],
      statuses: [
        {
          text: "- Select One -",
          value: "",
        },
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Called",
          value: "called",
        },
      ],
      selectedCall: "",
      form: {
        call_response: "",
        call_action_status_id: "",
        has_reminder: "",
      },
      actionStatuses: [],
      call_details: {},
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Outbound Calls", route: "/crm/outbound/calls"},
    ]);
  },
  created() {
    localStorage.setItem("tabIndex", 9);
    this.$root.$emit("call-active-menu", 9);
    setTimeout(() => {
      if (this.currentUser) {
        this.getItems();
        this.getCounter();
        this.getActionStatuses();
      }
    }, 1000);
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/user"]
    }
  },
  methods: {
    getActionStatuses() {
      axios
          .get(
              `${
                  process.env.VUE_APP_URL
              }/api/v1/${this.currentUser.sub_type == 'GSO' ? 'agent' : 'crm'}/call/action/status/list?api_token=${localStorage.getItem(
                  "api_token"
              )}`
          )
          .then((response) => {
            this.actionStatuses = response.data.data;
          });
    },
    showCallModal(item) {
      this.viewCallModal = true;
      this.selectedCall = item;
      this.form = {...this.selectedCall};
    },
    showCallDetails(item) {
      this.$bvModal.show('call-details')
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/${this.currentUser.sub_type == 'GSO' ? 'agent' : 'crm'}/call-logs/${item.log_id}?api_token=${localStorage.getItem('api_token')}`).then(res => {
        this.call_details = res.data.data;
        this.loading = false
      })
    },
    prescriptionUrl(url) {
      if (url) {
        return `${process.env.VUE_APP_API_URL}/storage/${url}`;
      } else {
        return ''
      }
    },
    updateCallLog() {
      axios
          .put(
              `${process.env.VUE_APP_URL}/api/v2/${this.currentUser.sub_type == 'GSO' ? 'agent' : 'crm'}/call-logs/${
                  this.selectedCall.log_id
              }?api_token=${localStorage.getItem("api_token")}`,
              this.form
          )
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            if (response.data.status_code == 400) {
              return this.$snotify.error(response.data.message);

            } else {
              this.$snotify.success("Call log added Successfully.");
              this.getItems();
            }
            this.viewCallModal = false;
          });
    },
    getItems(value) {
      this.loading = true;
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      let url = `${
          process.env.VUE_APP_URL
      }/api/v2/${this.currentUser.sub_type == 'GSO' ? 'agent' : 'crm'}/call-logs?api_token=${localStorage.getItem("api_token")}
      ${this.filter.start_date ? "&start_date=" + this.filter.start_date : ""}
      ${this.filter.end_date ? "&end_date=" + this.filter.end_date : ""}
      ${this.filter.status ? "&status=" + this.filter.status : ""}
      ${
          this.filter.call_response
              ? "&call_response=" + this.filter.call_response
              : ""
      }
      &limit=${this.perPage}${correctValue ? "&offset=" + correctValue : ""}`;
      axios.get(url).then((response) => {
        if (response.data.status_code == "500") {
          this.$router.push("/access-denied");
        } else {
          this.items = response.data.data;
          this.total_count = response.data.total_count;
          this.loading = false;
        }
      });
    },
    getCounter() {
      this.loading = true;
      let url = `${
          process.env.VUE_APP_URL
      }/api/v2/${this.currentUser.sub_type == 'GSO' ? 'agent' : 'crm'}/call-logs/counts?api_token=${localStorage.getItem(
          "api_token"
      )}`;
      axios.get(url).then((response) => {
        if (response.data.status_code == "500") {
          this.$router.push("/access-denied");
        } else {
          this.widget_counter.total_calls = response.data.data.total_calls;
          this.widget_counter.total_pending_calls = response.data.data.total_pending_calls;
          this.widget_counter.total_pending_today = response.data.data.total_pending_today;
          this.loading = false;
        }
      });
    },
    clear() {
      this.from_date = null;
      this.end_date = null;
    },
  },
};
</script>

<style scoped></style>
