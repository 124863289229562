<template>
  <div class="card">
    <div class="card-body">
      <router-link to="/medicine/generic/advices">Medicine generic advice</router-link>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              :current-page="0"
              :per-page="perPage"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(full_name)="row">
              <user-card :name="row.item.full_name"
                         :phone_number="row.item.phone_no"
                         :image="row.item.doctor_image">

              </user-card>
            </template>

            <template #cell(stump_image)="row">
              <img :src="userPhoto(row.item.stump_image)" alt="Orko Health Ltd" @click="showImage(row.item.stump_image)" class="user_image">
            </template>

          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              @change="handlePageChange"
              size="lg"
              class="my-0"
              aria-controls="doctorList"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  name: "SalesActivitySeal",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales team activity seal", route: "/sales/activity-seal"},
    ]);

  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'stump_image',
          label: 'Stump image',
          sortable: true,
        },
        {
          key: 'full_name',
          label: 'Full name',
          sortable: true,
        },
        {
          key: 'bmdc_reg_no',
          label: 'BMDC reg no',
          sortable: true,
        },
        {
          key: 'designation',
          label: 'Designation',
          sortable: true,
        },
        {
          key: 'speciality',
          label: 'Speciality',
          sortable: true,
        },
      ],
      currentPage: 1,
      perPage: 20,
      loading: false,
      filters: {
        id: '',
        full_name: '',
      },
      total_count: 0,
    }
  },
  created() {
    this.getSeal();
    localStorage.setItem('tabIndex', 11)
    this.$root.$emit('call-active-menu', 11);
  },

  methods: {
    getSeal() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/sales/team/activity/seals?api_token=${localStorage.getItem('api_token')}&offset=0&limit=${this.perPage}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return "../../../public/media/users/blank.png";
      }
    },
    showImage(img) {
      console.log('img', img)
      const h = this.$createElement
      const messageVNode = h('div', {class: ['body_image']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,

          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        // title: [titleVNode],
        title: ' ',
        size: 'xl',
        hideHeaderClose: false,
        footerClass: 'd-none',
        bodyClass: 'p-0 m-0',
      })
    },
    handlePageChange(value){
      this.loading = true
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/sales/team/activity/seals?api_token=${localStorage.getItem('api_token')}&offset=${correctValue}&limit=${this.perPage}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    }

  }
}
</script>

<style scoped>
.user_image {
  height: 150px;
  cursor: pointer;
}
.modal-body img {
  height: auto;
  /*width: 200px !important;*/
}

.body_image img {
  height: auto !important;
  /*width: -webkit-fill-available !important;*/
}
</style>
