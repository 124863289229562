<template>
  <div>
    <div class="card">
      <div class="card-body">

        <b-button v-b-modal.modal variant="primary">Assign users by roles</b-button>
        <div>
          <b-modal
              id="modal"
              title="Assign users by roles"
              @show="resetModal"
              @hidden="resetModal"
              size="lg"
              scrollable
              @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit" style="height: 50vh;">

              <div class="mb-3">
                <label>Select Role</label>
                <v-select
                    :options="roles"
                    :reduce="roles => roles.id"
                    v-model="form.role_id"
                    label="name"
                    @input="getUser()"
                    name="role_id"
                    id="role">
                </v-select>
              </div>

              <div class="mb-3">
                <label>Select Users</label>
                <v-select
                    ref="multi_select"
                    :options="users"
                    :reduce="users => users.id"
                    v-model="form.user_id"
                    name="user_id"
                    label="phone_number"
                    :components="{OpenIndicator}"
                    multiple>
                  <template slot="option" slot-scope="user">
                     {{user.phone_number}} - ( {{user.id}} )
                  </template>
                </v-select>
              </div>
            </form>
          </b-modal>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
    <loader-component v-if="loading"/>

  </div>
</template>

<script>
import {AssignRole} from "../../mixins/AssignRole";

export default {
  name: "AssignRoles",
  mixins: [AssignRole],
  title: 'Assign role',
}
</script>
