<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <h2>Manage permissions</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                title="Update permission"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Medicine class title is required"
                    :state="titleState">
                  <label for="title-input">Name <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.name"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Medicine class title is required"
                    >
                  <label for="title-input">Method <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.method"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Medicine class title is required"
                    >
                  <label for="title-input">Permission group</label>
                  <b-form-input
                      id="title-input"
                      v-model="form.permission_group"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Medicine class title is required"
                    >
                  <label for="title-input">Api <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.api"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Medicine class title is required"
                    >
                  <label for="title-input">Access level <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.access_level"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Medicine class title is required"
                    >
                  <label for="title-input">Is common <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.is_common"
                      required/>
                </b-form-group>




              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Permission lists"
                  name="Permission lists">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class=" mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>


              <template #cell(medicine_class_id)="data">
                {{ data.item.title }}
              </template>

              <template #cell(manufacturer)="data">
                {{ data.item.manufacturer ? data.item.manufacturer.title : 'n/a' }}
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <div>
                      <b-dropdown-item href="#" @click="editAction(row)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {ManagePermissionMixin} from "../../mixins/medicine/ManagePermissionMixin";

export default {
  name: "ManagePermissions",
  mixins: [ManagePermissionMixin],

}
</script>

<style scoped>

</style>