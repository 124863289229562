import Vue from "vue";
import router from "./router";
import VueMeta from 'vue-meta'
import './components/index';
import JsonExcel from "vue-json-excel";
import './assets/css/style.css';
import titleMixin from "./mixins/coreMixin/titleMixin";
import permissionMixin from "./mixins/coreMixin/permissionMixin";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {VueEditor} from "vue2-editor";

Vue.config.productionTip = false;
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import vSelect from 'vue-select'
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;
Vue.use(require('vue-moment'));
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});
import VueExcelEditor from 'vue-excel-editor'

Vue.use(VueExcelEditor)
Vue.component("downloadExcel", JsonExcel);
Vue.use(CKEditor);
Vue.use(VueEditor);
// Vue 3rd party plugins
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@/core/plugins/treeselect";
import "@mdi/font/css/materialdesignicons.css";
import LaravelPermissions from 'laravel-permissions';
import axios from "axios";
import Snotify, {SnotifyPosition} from 'vue-snotify';

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement => createElement('span', '❌'),
    },
    OpenIndicator: {
        render: createElement => createElement('span', '🔽'),
    },
});

window.axios = require('axios');

var VueCookie = require('vue-cookie');
// Tell Vue to use the plugin
Vue.use(VueCookie);


Vue.component("v-select", vSelect);

// snofity
const snotifyOptions = {
    toast: {
        position: SnotifyPosition.rightTop
    }
};

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('api_token')

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }
    next()
});


Vue.use(Snotify, snotifyOptions);

Vue.mixin(titleMixin);
Vue.mixin(permissionMixin);

// this plugin use for role and permission wise menu hide and show
Vue.use(LaravelPermissions, {persistent: true});

axios.defaults.baseURL = process.env.VUE_APP_URL;

window.moment = require('moment');
