<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button type="button" class="btn btn-primary" @click="addNewTarget">Add new Prescription Target</button>
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-3">
          <label for="">Select TSM</label>
          <b-form-select
              v-model="tsm_id"
              :options="sales_users"
              text-field="name"
              value-field="id">
          </b-form-select>
        </div>

        <div class="col-md-3">
          <label for="">From</label>
                      <input type="month" v-model="start_date" class="form-control">
        </div>
        <div class="col-md-3">
          <label for="">To</label>
                      <input type="month" v-model="end_date" class="form-control">
        </div>
  
        <div class="col-md-3 mt-7">
          <button class="btn btn-success" @click="getItems" v-if="start_date && end_date">Filter</button>
          <button class="btn btn-success" disabled v-else>Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(month)="row">
              {{ (monthTranslate[row.item.month]) ?  monthTranslate[row.item.month] : '-'}}
            </template>
          </b-table>
        </div>
      </div>
      <b-modal
          id="addTarget"
          ref="modal"
          title="Add new target"
          @hidden="resetModal"
          @ok="handleOk"
          size="lg">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col cols="6">
              <label for="doctor_type">Select TSM</label>
              <b-form-select
                  id="doctor_type"
                  v-model="form.user_id"
                  :options="sales_users"
                  text-field="name"
                  value-field="id">
              </b-form-select>
            </b-col>
            <b-col cols="6">
              <label for="doctor_type">Month</label>
              <b-form-select id="doctor_type" v-model="form.month" :options="months"></b-form-select>
            </b-col>
            <b-col cols="6" class="my-5">
              <label for="year">Year</label>
              <b-form-select
                  id="doctor_type"
                  v-model="form.year"
                  :options="years">
              </b-form-select>
            </b-col>
            <b-col cols="6" class="my-5">
              <label>Target</label>
              <b-form-input v-model="form.target"></b-form-input>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";
import {dateFormate} from "../../healper/dateTimeFormet";

export default {
  name: "SalesPrescriptionTarget",
  mixins: [SalesUserMixins],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales Prescription Target", route: "/sales/prescription/target"},
    ]);
  },
  data() {
    const today = new Date();

    let all_months = [
      {
        text: 'January',
        value: '1'
      },
      {
        text: 'February',
        value: '2'
      },
      {
        text: 'March',
        value: '3'
      },
      {
        text: 'April',
        value: '4'
      },
      {
        text: 'May',
        value: '5'
      },
      {
        text: 'June',
        value: '6'
      },
      {
        text: 'July',
        value: '7'
      },
      {
        text: 'August',
        value: '8'
      },
      {
        text: 'September',
        value: '9'
      },
      {
        text: 'October',
        value: '10'
      },
      {
        text: 'November',
        value: '11'
      },
      {
        text: 'December',
        value: '12'
      },
    ];
    return {
      monthTranslate: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
      },
      items: [],
      fields: [
        {
          key: 'user_name',
          label: 'TSM name',
          sortable: true,
        },
        {
          key: 'month',
          label: 'Month',
          sortable: true,
        },
        {
          key: 'year',
          label: 'Year',
          sortable: true,
        },
        {
          key: 'target',
          label: 'Target',
          sortable: true,
        },
      ],
      loading: false,
      form: {
        user_id: '',
        user_name: '',
        month: '',
        year: '',
        target: '',
      },
      months: all_months,
      nameState: null,
      tsm_id: '',
      // month: '',
      // year: '',
      current_year: new Date,
      start_year: today.getFullYear(),
      years: [],
      start_date: dateFormate().month_start,
      end_date: dateFormate().month_start,
    }
  },
  created() {
    localStorage.setItem('tabIndex', 14)
    this.$root.$emit('call-active-menu', 14);
    this.getItems();
    this.setYears(15);
  },
  methods: {
    setQueryParams() {
      let params = {};
      if (this.tsm_id) {
        params.user_id = this.tsm_id;
      }
      if (this.month) {
        params.month = this.month;
      }
      if (this.year) {
        params.year = this.year;
      }
      if (this.doctor_type) {
        params.doctor_type = this.doctor_type;
      }
      return params;
    },
    getItems() {
      this.loading = true;
      let url = `${process.env.VUE_APP_URL}/api/v2/sales/team/sales/prescription-target/list?api_token=${localStorage.getItem('api_token')}${this.tsm_id ? '&user_id='+this.tsm_id : ''}${this.start_date ? '&start_date='+this.start_date : ''}${this.end_date ? '&end_date='+this.end_date : ''}`;
      this.$router.replace({
        query: this.setQueryParams()
      })
      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    addNewTarget() {
      this.$bvModal.show('addTarget')
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.form = {};
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      let sales_user_name = this.sales_users.find(item => item.id == this.form.user_id);
      this.form.user_name = sales_user_name.name;
      this.$nextTick(() => {
        axios.post(`${process.env.VUE_APP_API_URL}/api/v2/sales/team/sales/prescription-target/set?api_token=${localStorage.getItem('api_token')}`, this.form).then(res => {
          if (res.data.status_code == 200) {
            this.getItems();
            this.$bvModal.hide('addTarget')
            this.$snotify.success('Successfully saved');
          } else {
            this.$snotify.error(res.data.message);
          }
        })
      })
    },
    setYears(num) {
     for(let i = 0; i<num; i++) {
       this.years.push(this.start_year + i);
     }
    },
    clear(){
      this.tsm_id = '';
      this.month = '';
      this.year = '';
      this.getItems();
    },
  }
}
</script>

<style scoped>

</style>
