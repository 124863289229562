<template>
  <div>

    <!--  Chamber -->
    <doctor-workplace-chamber
        :workplaces="workplaces" :doctor_id="doctor_id" :doctor_api_token="doctor_api_token" :doctor="doctor"/>


    <!--  Telemedicine  -->
    <doctor-workplace-telemedicine :workplaces="workplaces" :doctor_id="doctor_id"
                                   :doctor_api_token="doctor_api_token"/>

    <br><br>
  </div>
</template>

<script>

export default {
  name: "DoctorWorkPlace",
  props: {
    workplaces: {},
    doctor_id: {},
    doctor_api_token: {},
    doctor: {},
  },
}
</script>

<style scoped>
.font-awesome-desgin {
  padding: 7px 10px;
  background: #f3f6f9;
  border-radius: 7px;
  color: #4591d1;
}

.bg-gray {
  padding: 10px;
  background: #f3f6f9;
}

.active_day {
  background-color: yellow;
}
</style>