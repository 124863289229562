import { render, staticRenderFns } from "./AssignTeamToDoctor.vue?vue&type=template&id=3eecff89&scoped=true"
import script from "./AssignTeamToDoctor.vue?vue&type=script&lang=js"
export * from "./AssignTeamToDoctor.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eecff89",
  null
  
)

export default component.exports