import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const MedicineGenericsMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
                "Definition or pharmacology": {
                    field: "definition_or_pharmacology",
                },
                "Medicine class ": {
                    field: "medicine_class_id",
                    callback: (value) => {
                        return value.title;
                    },
                },
                "Mode of action": {
                    field: "mode_of_action",
                },
                "Indications": {
                    field: "indications",
                },
                "Interaction": {
                    field: "interaction",
                },
                "Side effects": {
                    field: "side_effects",
                },
                "Precautions": {
                    field: "precautions",
                },
                "Pregnancy lactation": {
                    field: "pregnancy_n_lactation",
                },
                "Dosage admin": {
                    field: "dosage_n_admin",
                },
                "Dosage": {
                    field: "dosage",
                },
                "Administation": {
                    field: "administation",
                },
                "Contraindications": {
                    field: "contraindications",
                },
                "Overdose effects": {
                    field: "overdose_effects",
                },
                "Storage conditions": {
                    field: "storage_conditions",
                },
                "Compositions": {
                    field: "compositions",
                },
                "Use special population": {
                    field: "use_in_special_population",
                },
                "Description": {
                    field: "description",
                },
                "Duration of treatment": {
                    field: "duration_of_treatment",
                },
                "Reconstitutions": {
                    field: "reconstitutions",
                },

            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Medicine class',
                    key: 'medicine_class_id',
                    sortable: true,
                },
                {
                    label: 'Child dose',
                    key: 'dose_child',
                    sortable: true,
                },
                {
                    label: 'Adult dose',
                    key: 'dose_adult',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            form: {
                title: '',
                dose_child: '',
                dose_adult: '',
                medicine_class_id: '',
                definition_or_pharmacology: '',
                mode_of_action: '',
                interaction: '',
                side_effects: '',
                indications: '',
                precautions: '',
                pregnancy_n_lactation: '',
                dosage_n_admin: '',
                dosage: '',
                administation: '',
                contraindications: '',
                overdose_effects: '',
                storage_conditions: '',
                compositions: '',
                use_in_special_population: '',
                description: '',
                duration_of_treatment: '',
                reconstitutions: '',
            },
            edit: false,
            edit_id: '',
            medicine_class: [],
            loading: true,
            medicine_class_is_required: '',
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Medicine generics", route: "/medicine-generics"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 11)
        this.$root.$emit('call-active-menu', 11);
        this.getMedicineGennerics();
        this.getMedicineClass();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Medicine generics',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getMedicineGennerics() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/generics/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        getMedicineClass() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/class/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.medicine_class = response.data.data
                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.titleState = null;
            this.medicine_class_is_required = '';
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()

            if (this.edit) {
                return this.updateData();
            }
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }

            if (this.form.medicine_class_id == '' || this.form.medicine_class_id == null) {
                this.medicine_class_is_required = "Medicine class is required";
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);
            form.append("medicine_class_id", this.form.medicine_class_id);
            if (this.form.definition_or_pharmacology && this.form.definition_or_pharmacology != '') {
                form.append("definition_or_pharmacology", this.form.definition_or_pharmacology);
            }
            if (this.form.dose_child && this.form.dose_child != '') {
                form.append("dose_child", this.form.dose_child);
            }

            if (this.form.dose_adult && this.form.dose_adult != '') {
                form.append("dose_adult", this.form.dose_adult);
            }

            if (this.form.mode_of_action && this.form.mode_of_action != '') {
                form.append("mode_of_action", this.form.mode_of_action);
            }
            if (this.form.indications && this.form.indications != '') {
                form.append("indications", this.form.indications);
            }
            if (this.form.interaction && this.form.interaction != '') {
                form.append("interaction", this.form.interaction);
            }
            if (this.form.side_effects && this.form.side_effects != '') {
                form.append("side_effects", this.form.side_effects);
            }
            if (this.form.precautions && this.form.precautions != '') {
                form.append("precautions", this.form.precautions);
            }
            if (this.form.pregnancy_n_lactation && this.form.pregnancy_n_lactation != '') {
                form.append("pregnancy_n_lactation", this.form.pregnancy_n_lactation);
            }
            if (this.form.dosage_n_admin && this.form.dosage_n_admin != '') {
                form.append("dosage_n_admin", this.form.dosage_n_admin);
            }
            if (this.form.dosage && this.form.dosage != '') {
                form.append("dosage", this.form.dosage);
            }
            if (this.form.administation && this.form.administation != '') {
                form.append("administation", this.form.administation);
            }
            if (this.form.contraindications && this.form.contraindications != '') {
                form.append("contraindications", this.form.contraindications);
            }
            if (this.form.overdose_effects && this.form.overdose_effects != '') {
                form.append("overdose_effects", this.form.overdose_effects);
            }
            if (this.form.storage_conditions && this.form.storage_conditions != '') {
                form.append("storage_conditions", this.form.storage_conditions);
            }
            if (this.form.compositions && this.form.compositions != '') {
                form.append("compositions", this.form.compositions);
            }
            if (this.form.use_in_special_population && this.form.use_in_special_population != '') {
                form.append("use_in_special_population", this.form.use_in_special_population);
            }
            if (this.form.description && this.form.description != '') {
                form.append("description", this.form.description);
            }
            if (this.form.duration_of_treatment && this.form.duration_of_treatment != '') {
                form.append("duration_of_treatment", this.form.duration_of_treatment);
            }
            if (this.form.reconstitutions && this.form.reconstitutions != '') {
                form.append("reconstitutions", this.form.reconstitutions);
            }

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medicine/generics/create?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicineGennerics();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        updateData() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }

            if (this.form.medicine_class_id == '' || this.form.medicine_class_id == null) {
                this.medicine_class_is_required = "Medicine class is required";
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);
            form.append("medicine_class_id", this.form.medicine_class_id);

            if (this.form.dose_child && this.form.dose_child != '') {
                form.append("dose_child", this.form.dose_child);
            }

            if (this.form.dose_adult && this.form.dose_adult != '') {
                form.append("dose_adult", this.form.dose_adult);
            }

            if (this.form.definition_or_pharmacology || this.form.definition_or_pharmacology != null) {
                form.append("definition_or_pharmacology", this.form.definition_or_pharmacology);
            }
            if (this.form.mode_of_action || this.form.mode_of_action != null) {
                form.append("mode_of_action", this.form.mode_of_action);
            }
            if (this.form.indications ||  this.form.indications != null) {
                form.append("indications", this.form.indications);
            }
            if (this.form.interaction  || this.form.interaction != null) {
                form.append("interaction", this.form.interaction);
            }
            if (this.form.side_effects  || this.form.side_effects != null) {
                form.append("side_effects", this.form.side_effects);
            }
            if (this.form.precautions  || this.form.precautions != null) {
                form.append("precautions", this.form.precautions);
            }
            if (this.form.pregnancy_n_lactation  || this.form.pregnancy_n_lactation != null) {
                form.append("pregnancy_n_lactation", this.form.pregnancy_n_lactation);
            }
            if (this.form.dosage_n_admin || this.form.dosage_n_admin != null) {
                form.append("dosage_n_admin", this.form.dosage_n_admin);
            }
            if (this.form.dosage ||  this.form.dosage != null) {
                form.append("dosage", this.form.dosage);
            }
            if (this.form.administation  || this.form.administation != null) {
                form.append("administation", this.form.administation);
            }
            if (this.form.contraindications  || this.form.contraindications != null) {
                form.append("contraindications", this.form.contraindications);
            }
            if (this.form.overdose_effects  || this.form.overdose_effects != null) {
                form.append("overdose_effects", this.form.overdose_effects);
            }
            if (this.form.storage_conditions  || this.form.storage_conditions != null) {
                form.append("storage_conditions", this.form.storage_conditions);
            }
            if (this.form.compositions  || this.form.compositions != null) {
                form.append("compositions", this.form.compositions);
            }
            if (this.form.use_in_special_population || this.form.use_in_special_population != null) {
                form.append("use_in_special_population", this.form.use_in_special_population);
            }
            if (this.form.description  || this.form.description != null) {
                form.append("description", this.form.description);
            }
            if (this.form.duration_of_treatment  || this.form.duration_of_treatment != null) {
                form.append("duration_of_treatment", this.form.duration_of_treatment);
            }
            if (this.form.reconstitutions || this.form.reconstitutions != null) {
                form.append("reconstitutions", this.form.reconstitutions);
            }

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medicine/generics/update/${this.edit_id}?api_token=${localStorage.getItem('api_token')}&_method=put`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicineGennerics();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully updated');
                    })
            })
        },
        editAction(row) {
            this.$bvModal.show('modal-1');
            this.edit = true;
            this.form = row.item;
            this.edit_id = row.item.id
            // this.form.administation = row.item.administation;
            // this.form.mode_of_action = row.item.mode_of_action;
            // this.form.indications = row.item.indications;
            // this.form.medicine_class_id = row.item.medicine_class;
        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/medicine/generics/remove/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getMedicineGennerics();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        updateTheVariable(value) {
            this.form = value;
        }

    }
}