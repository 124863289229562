export const ExportMixin = {
    methods: {
        exportLink(response) {
            let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
            let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
            const url = URL.createObjectURL(new Blob([response.data], {
                type: 'application/vnd.ms-excel'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${filename}.${extension}`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link);
        }
    }
}