<template>
  <div>
    <!--    Filter start -->
    <div class="">
      <h3 class="mb-5"><i class="fas fa-filter"></i> Filtering </h3>
      <div class="row">
        <div class="col-md-4">
          <div class="mb-3 form-group">
            <label for="">From Date</label>
            <input type="date" class="form-control" v-model="from_date">
          </div>
        </div>
        <div class="col-md-4">
          <div class="mb-3 form-group">
            <label for="">To Date</label>
            <input type="date" class="form-control" v-model="to_date">
          </div>
        </div>
        <div class="col-md-4">
          <div class="mt-5 pt-3 form-group">
            <button class="btn btn-success" @click="reportFilter">Submit</button>
            <button class="btn btn-danger ml-4" @click="clearFilter">Clear</button>
          </div>
        </div>

      </div>
    </div>
    <!--    Filter End -->
    <div class="card">
      <div class="card-body">
        <h5 class="font-weight-bold mb-5 text-uppercase"> User Information </h5>
        <div class="row">
          <div class="col-md-4" v-for="(user , index) in users" :key="index" v-if="index !=='total_user'">
            <div class="">
              <h5 class="font-weight-bold mb-5 text-uppercase">{{ index }}</h5>
              <template>

                <!--begin: Item Total-->
                <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-primary">
                      <span class="svg-icon-primary mr-5">
                        <i class="fas fa-chart-line align-self-center text-primary"></i>
                      </span>
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a href="#"
                       class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18">
                      Total
                    </a>
                    <span class="font-size-lg"></span>
                  </div>
                  <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                        {{ user.total }}
                      </span>
                  </span>
                </div>
                <!--end: Item -->

                <!--begin: Item  Confirmed -->
                <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-success">
                      <span class="svg-icon-info mr-5">
                        <i class="far fa-check-circle h-50 align-self-center text-info"></i>
                      </span>
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a href="#"
                       class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18">
                      Confirmed
                    </a>
                    <span class=" font-size-lg"></span>
                  </div>

                  <span class="font-weight-bolder py-1 font-size-lg text-info">
                     <span class="badge badge-light font-size-20">
                        {{ user.confirmed }}
                     </span>
                  </span>
                </div>
                <!--end: Item -->

                <!--begin: Item Rejected-->
                <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-danger">
                      <span class="svg-icon-danger mr-5">
                        <i class="fab fa-r-project h-50 align-self-center text-danger"></i>
                      </span>

                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a href="#"
                       class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18">
                      Rejected
                    </a>
                    <span class="font-size-lg"></span>
                  </div>
                  <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                          {{ user.rejected }}
                    </span>
                  </span>
                </div>
                <!--end: Item -->

              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="card">
      <div class="card-body">
        <h5 class="font-weight-bold mb-5 text-uppercase"> Chat information </h5>

        <div class="row justify-content-center">
          <div class="col-md-4" v-for="(single_chat , index) in chat" :key="index">
            <div class="">
              <!--              <h5 class="font-weight-bold mb-5 text-uppercase">{{ index }}</h5>-->
              <template>

                <!--begin: Item Total-->
                <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-primary">
                      <span class="svg-icon-primary mr-5">

                        <i class="fas fa-chart-line align-self-center text-primary" v-if="index==='total_chat'"></i>
                        <i class="fas fa-comments align-self-center text-primary" v-else-if="index==='group_chat'"></i>
                        <i class="fas fa-redo-alt align-self-center text-primary"
                           v-else-if="index==='repeat_thread'"></i>
                        <i class="fas fa-phone-volume align-self-center text-primary"
                           v-else-if="index==='no_of_call'"></i>
                        <i class="fas fa-fingerprint align-self-center text-primary" v-else></i>

                      </span>
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a href="#"
                       class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                      {{ underScoreToSpace(index) }}
                    </a>
                    <span class="font-size-lg"></span>
                  </div>
                  <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                        {{ single_chat }}
                      </span>
                  </span>
                </div>
                <!--end: Item -->

              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="card">
      <div class="card-body">
        <h5 class="font-weight-bold mb-5 text-uppercase"> Service Fee </h5>

        <div class="row justify-content-center">
          <div class="col-md-4" v-for="(single_service , index) in service_fee" :key="index" v-if="index !='avg_chat_fee'">
            <div class="">
              <!--              <h5 class="font-weight-bold mb-5 text-uppercase">{{ index }}</h5>-->
              <template>

                <!--begin: Item Total-->
                <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-info">
                      <span class="svg-icon-primary mr-5">

                        <i class="fas fa-phone-volume align-self-center text-primary" v-if="index==='avg_call_fee'"></i>
                        <i class="fas fa-comments align-self-center text-primary"
                           v-else-if="index==='avg_chat_fee'"></i>

                        <i class="fas fa-eye align-self-center text-primary" v-else></i>

                      </span>
                  <div class="d-flex flex-column flex-grow-1 mr-2">
                    <a href="#"
                       class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">

                      {{ index === 'avg_call_fee' ? 'Avg virtual fee' : '' }}

                      {{ index === 'avg_visit_fee' ? 'Avg visit fee' : '' }}

                    </a>
                    <span class="font-size-lg"></span>
                  </div>
                  <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                        {{ single_service }}
                      </span>
                  </span>
                </div>
                <!--end: Item -->

              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {underScoreToSpace} from "../../healper/nameFormating";

export default {
  name: "BiGeneralReport",
  title: 'General Overview Report',
  data() {
    return {
      users: {},
      chat: {},
      service_fee: {},
      from_date: '',
      to_date: '',
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "General Reports", route: "/general-bi-overview"},
    ]);
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    this.getReport();
  },
  methods: {
    getReport() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/bi/analysis/report?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {

            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.users = response.data.data.user;
              this.chat = response.data.data.chat;
              this.service_fee = response.data.data.service_fee;
            }


          })
    },
    underScoreToSpace,
    reportFilter() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/bi/analysis/report?start_from=${this.from_date}&end_to${this.to_date}&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.users = response.data.data.user;
            this.chat = response.data.data.chat;
            this.service_fee = response.data.data.service_fee;
          })
    },
    clearFilter() {
      this.from_date = '';
      this.to_date = ''
      this.getReport();

    }

  }
}
</script>

<style scoped>
.orko-element-bg {
  background: #FFFFFF;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-18 {
  font-size: 15px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}
</style>