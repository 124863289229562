import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {underScoreToSpace} from "../../healper/nameFormating";
import {APPOINTMENT_LIST} from "../../api/ApiUrl";

export const AppointmentListMixin = {
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Book appointment", route: "/appointment-list"},
        ]);
        this.getAppointmentList();
    },
    created() {
        localStorage.setItem('tabIndex', 10)
        this.$root.$emit('call-active-menu', 10);
    },
    data() {
        return {
            loading: true,
            items: [],
            filter: null,
            fields: [
                {
                    key: 'id',
                    label: 'ID',
                    sortable: true,
                },
                {
                    key: 'doctor_id',
                    label: 'Doctor',
                    tdClass: 'test',
                    'class': 'my-class',
                    'no': {thStyle: {width: '400px !important'}},
                    sortable: true,
                },
                {
                    key: 'patient_id',
                    label: 'Patient',
                    sortable: true,
                },
                {
                    key: 'institute_name',
                    label: 'Institute',
                    sortable: true,
                },
                {
                    key: 'schedule_date',
                    label: 'Schedule date',
                    sortable: true,
                },

                {
                    key: 'appointment_status',
                    label: 'Appointment status',
                    sortable: true,
                },
                {
                    key: 'appointment_type',
                    label: 'Appointment type',
                    sortable: true,
                },
                {
                    key: 'service_type',
                    label: 'Service type',
                    sortable: true,
                },
                {
                    key: 'payable_amount',
                    label: 'Payable amount',
                    sortable: true,
                },
                {
                    key: 'penalty_amount',
                    label: 'Penalty amount',
                    sortable: true,
                },
                {
                    key: 'is_paid',
                    label: 'Is paid',
                    sortable: true,
                },
                {
                    key: 'is_doctor_show',
                    label: 'Doctor present'
                },
                {
                    key: 'is_patient_show',
                    label: 'Patient present',
                    sortable: true,
                },
                {
                    key: 'is_expire',
                    label: 'Expire',
                    sortable: true,
                },
                {
                    key: 'appointment_date',
                    label: 'Appointment taken',
                    sortable: true,
                },
                {
                    key: 'delay_time',
                    label: 'Delay time',
                    sortable: true,
                },
                {
                    key: 'details',
                    label: 'Details',
                    sortable: true,
                },
                {
                    key: 'actions',
                    label: 'Actions'
                },

            ],
            filters: {
                id: '',
                doctor_id: '',
                fullname: '',
                patient_id: '',
                workplace_id: '',
                schedule_date: '',
                schedule_started_at: '',
                schedule_end_at: '',
                status: '',
                is_doctor_show: '',
                is_patient_show: '',
                is_paid: '',
                service_type: '',
                payable_amount: '',
                penalty_amount: '',
                delay_time: '',
                appointment_type: '',
                details: '',
            },
            currentPage: 1,
            perPage: 20,
            totalItems: 0,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            pageOptions: [20, 40, 60, {value: 100, text: "all"}],
            appointment: {
                status: '',
                comment: ''
            },
            select: {
                status: null,
                appointment_type: null,
                service_type: null,
            },
            statuses: [
                {value: null, text: 'Appointment status'},
                {value: 'rescheduled', text: 'Rescheduled'},
                {value: 'canceled', text: 'Canceled'},
                {value: 'patient-absent', text: 'Patient absent'},
                {value: 'expired', text: 'Expired'},
                {value: 'booked', text: 'Booked'},
                {value: 'served', text: 'Served'},
                {value: 'pending', text: 'Pending'},
            ],
            appointment_type: [
                {value: null, text: 'Appointment type'},
                {value: 'booked_appointment', text: 'Booked appointment'},
                {value: 'quick_care', text: 'Quick care'},
                {value: 'walkin_appointment', text: 'Walking appointment'},
            ],
            service_type: [
                {value: null, text: 'Service type'},
                {value: 'virtual', text: 'Virtual'},
                {value: 'visit', text: 'Visit'},
            ],
            filter_status: '',
            start_date: '',
            end_date: '',
            dateRanchFilter: {
                started_date: '',
                end_date: '',
            },
            is_confirmed: '',
            appointment_id: '',
            payments: {
                amount: '',
                option: '',
                transaction_type: '',
                banking_name: '',
                transaction_id: '',
            },
            patient_id: '',
            request_id: '',
            transaction_type: [
                {
                    text: 'Bank',
                    value: 'Bank'
                },
                {
                    text: "Mobile Banking",
                    value: "Mobile Banking",
                }
            ],

            filterOn: [],
            startDate: null,
            endDate: null,
            appointment_status: [
                {item: 'A', name: 'Option A'},
            ],
            selectedStatus: '',
            cancel_id: '',
            appointment_type_filter: '',
            service_type_filter: '',
            patients: [],
            user_search: '',
        }
    },
    computed: {
        rows() {
            return this.users.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.schedule_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                doctor_Id: '',
                fullname: '',
                patient_id: '',
                workplace_id: '',
                schedule_date: '',
                schedule_started_at: '',
                schedule_end_at: '',
                status: '',
                is_doctor_show: '',
                is_patient_show: '',
                is_paid: '',
                service_type: '',
                payable_amount: '',
                penalty_amount: '',
                delay_time: '',
                appointment_type: '',
                details: '',
                edit_id: '',
            }]
        },
        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.registration_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        }
    },
    methods: {

        underScoreToSpace,

        getAppointmentList() {
            if (this.select.status) {
                this.filter_status = '&status=' + this.select.status;
            } else {
                this.filter_status = '';
            }
            if (this.select.appointment_type) {
                this.appointment_type_filter = '&appointment_type=' + this.select.appointment_type;
            }
            if (this.select.service_type) {
                this.service_type_filter = '&service_type=' + this.select.service_type;
            }
            if (this.start_date) {
                this.dateRanchFilter.started_date = '&schedule_start_date=' + this.start_date;
            } else {
                this.dateRanchFilter.started_date = '';
            }
            if (this.end_date) {
                this.dateRanchFilter.end_date = '&schedule_end_date=' + this.end_date;
            } else {
                this.dateRanchFilter.end_date = '';
            }
            this.loading = true;
            axios.get(`${APPOINTMENT_LIST}&limit=${this.perPage}&offset=0${this.filter_status}${this.appointment_type_filter}${this.service_type_filter}`)
                .then(response => {

                    this.totalItems = response.data.total_count;
                    this.items = response.data.data;
                    this.loading = false;
                })
        },
        handlePageChange(value) {
            var offset = value - 1;
            var correctValue = offset * this.perPage;

            if (this.select.status) {
                this.filter_status = '&status=' + this.select.status;
            } else {
                this.filter_status = '';
            }
            if (this.start_date) {
                this.dateRanchFilter.started_date = '&schedule_start_date=' + this.start_date;
            } else {
                this.dateRanchFilter.started_date = '';
            }
            if (this.end_date) {
                this.dateRanchFilter.end_date = '&schedule_end_date=' + this.end_date;
            } else {
                this.dateRanchFilter.end_date = '';
            }
            this.loading = true;
            axios.get(`${APPOINTMENT_LIST}&limit=${this.perPage}&offset=${correctValue}`)
                .then(response => {
                    this.items = response.data.data;
                    this.loading = false;
                })

        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        sendInfo(row) {
            this.is_confirmed = 'booked';
            this.appointment_id = row.id;
            this.$bvModal.show('statusModal');
        },
        resetModal() {
            this.patient_id = '';
            this.request_id = '';
            this.payments = {};
            this.cancel_id = '';
            this.appointment = {};
            this.edit_id = '';
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },
        cancelAppointmentOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleCancelAppointmentSubmit()
        },
        paymentOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.appointPayment()
        },
        handleSubmit() {
            let form = new FormData();
            form.append("comment", this.appointment.comment);
            form.append("id", this.edit_id);
            form.append("action_type", 'update');
            form.append("module_type", 'appointment');
            form.append("status", this.selectedStatus);

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/administrator/appointment/change/status?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getAppointmentList();
                        this.$bvModal.hide('statusModal')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        handleCancelAppointmentSubmit() {
            let form = new FormData();
            form.append("comment", this.appointment.comment);
            form.append("id", this.cancel_id);
            form.append("action_type", 'reject');
            form.append("module_type", 'appointment');
            form.append("status", 'expired');

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/administrator/appointment/change/status?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getAppointmentList();
                        this.$bvModal.hide('cancelModal')
                        this.$snotify.success('Successfully canceled');
                    })
            })
        },
        appointPayment() {
            let form = new FormData();
            form.append("request_id", this.request_id);
            form.append("amount", this.payments.amount);
            form.append("is_paid", this.payments.option);
            form.append("cart_type", this.payments.transaction_type);
            form.append("card_brand", this.payments.banking_name);
            form.append("user_id", this.patient_id);

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/administrator/appointment/payment?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        } else {
                            this.resetModal();
                            this.getAppointmentList();
                            this.$bvModal.hide('paymentModal')
                            this.$snotify.success('Successfully paid');
                        }
                    })
            })
        },
        dateRangeClear() {
            this.startDate = '';
            this.endDate = ''
            this.filter_status = '';
            this.handlePageChange();
        },
        showPaymentModal(item) {
            this.$bvModal.show('paymentModal')
            this.request_id = item.id;
            this.patient_id = item.patient_id;
        },
        localizeDate(date) {
            // Date picker uses ISO format (yyyy-mm-dd), which is UTC. The data
            // contains locale specific date strings (mm/dd/yyyy), which `Date`
            // parses with local time-zone offset instead of UTC. Normalize the
            // ISO date so we're comparing local times.
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            // this.currentPage = 1
        },
        changeStatus(item) {
            this.$bvModal.show('statusModal')
            this.selectedStatus = item.appointment_status;
            this.edit_id = item.id;

        },
        cancelAppointment(item) {
            this.$bvModal.show('cancelModal');
            this.cancel_id = item.id;
        },
        clearFilter() {
            this.select = {
                status: null,
                appointment_type: null,
                service_type: null,
            };
        },

    },

}
