<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <h2>Manage diagnostic</h2>

        <div class="d-flex justify-content-end">
          <div class="mx-2">
            <b-button variant="primary" v-b-modal.modal-2><i class="fas fa-plus"></i> Add new diagnostic
            </b-button>
          </div>
          <div class="mx-2">
            <div>
              <download-excel
                  :data="institutes"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Diagnostic"
                  name="Diagnostic">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
        </div>
        <div class="row ">
          <div class="col-md-12">

            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                title="Manage medical test"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data" style="height: 50vh;">
                <b-form-group>
                  <label for="">Choose diagnostic<span class="text-danger">*</span></label>
                  <v-select
                      :options="institutes"
                      :reduce="institutes => institutes.id"
                      v-model="form.institute_id"
                      label="name"
                      required>
                  </v-select>
                  <p class="text-danger">{{ medicine_generics_is_required }}</p>
                </b-form-group>
                <div>
                  <table class="table">
                    <tr>
                      <td>Choose investigations / Medical test</td>
                      <td>Price</td>
                      <td>Discount</td>
                      <td></td>
                    </tr>
                    <tr v-for="(item, index) in diagnostic_features" :key="index">
                      <td>
                        <v-select
                            :options="investigations"
                            :reduce="investigations => investigations.id"
                            v-model="item.medical_test"
                            label="title"
                            class=""
                            required>
                        </v-select>
                      </td>
                      <td>
                        <input type="text" class="form-control"
                               v-model="item.price"></td>
                      <td><input type="number" class="form-control" v-model="item.discount"></td>

                      <td>
                        <button v-on:click="deleteGroup(index)" class="btn btn-danger" type="button"> X</button>
                      </td>
                    </tr>
                  </table>
                  <button v-on:click="addGroup" class="btn btn-info float-right" type="button">Add</button>
                </div>
              </form>
            </b-modal>

            <!--            dygonstic modal -->
            <b-modal
                id="modal-2"
                size="lg"
                title="Create a new diagnostic"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleInstitute">
              <form ref="form" @submit.stop.prevent="addInstitute" enctype="multipart/form-data">

                <b-form-group>
                  <label for="">Parient institute</label>
                  <v-select
                      :options="institutes"
                      :reduce="institutes => institutes.id"
                      v-model="form.parent_id"
                      label="name">
                  </v-select>
                </b-form-group>

                <b-form-group
                    label="Name"
                    label-for="name-input"
                    invalid-feedback="Name is required"
                    :state="title">
                  <b-form-input
                      id="name-input"
                      v-model="form.name"
                      :state="title"
                      required/>
                </b-form-group>

                <b-form-group>
                  <label for="">Address</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.address"
                      placeholder="Enter address"
                      rows="3"

                      class="mt-3"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                    label="Contact number"
                    label-for="name-input">
                  <b-form-input
                      id="name-input"
                      v-model="form.contact_number"/>
                </b-form-group>

                <b-form-group>
                  <label for="">Details</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.details"
                      placeholder="Enter Details"
                      rows="3"

                      class="mt-3"
                  ></b-form-textarea>
                </b-form-group>

              </form>
            </b-modal>


          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="items.length ? filtered : items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>

              <template #cell(contact_number)="data">
                {{ data.item.contact_number ? data.item.contact_number : 'n/a' }}
              </template>
              <template #cell(address)="data">
                {{ data.item.address ? data.item.address : 'n/a' }}
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <div>
                      <b-dropdown-item href="#" @click="editAction(row)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Manage medical test
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="deleteAction(row)">
                        <span class="pr-4"><i class="fas fa-trash"></i></span> Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {DynogensticCenterMixin} from "../../mixins/medicine/DynogensticCenterMixin";

export default {
  name: "ManageDynogonestic",
  mixins: [DynogensticCenterMixin],
}
</script>

<style scoped>

</style>