<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <template>
              <!--begin: Item Total-->
              <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-primary">
                      <span class="svg-icon-primary mr-5">

                        <i class="fas fa-fingerprint align-self-center text-primary"></i>

                      </span>
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <a href="#"
                     class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                    Total Feedback
                  </a>
                  <span class="font-size-lg"></span>
                </div>
                <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ total_feedback }}
                      </span>
                  </span>
              </div>
              <!--end: Item -->

            </template>
          </div>
          <div class="col">
            <template>
              <!--begin: Item Total-->
              <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-primary">
                      <span class="svg-icon-primary mr-5">

                        <i class="fas fa-fingerprint align-self-center text-primary"></i>

                      </span>
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <a href="#"
                     class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                    Feedback count down
                  </a>
                  <span class="font-size-lg"></span>
                </div>
                <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ feedback_count_down }}
                      </span>
                  </span>
              </div>
              <!--end: Item -->

            </template>
          </div>
          <div class="col">
            <template>
              <!--begin: Item Total-->
              <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-primary">
                      <span class="svg-icon-primary mr-5">

                        <i class="fas fa-fingerprint align-self-center text-primary"></i>

                      </span>
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <a href="#"
                     class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                    Feedback count up
                  </a>
                  <span class="font-size-lg"></span>
                </div>
                <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ feedback_count_up }}
                      </span>
                  </span>
              </div>
              <!--end: Item -->

            </template>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <template>
              <!--begin: Item Total-->
              <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-primary">
                      <span class="svg-icon-primary mr-5">

                        <i class="fas fa-fingerprint align-self-center text-primary"></i>

                      </span>
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <a href="#"
                     class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                    Percentage of down review
                  </a>
                  <span class="font-size-lg"></span>
                </div>
                <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ percentage_of_down_review }}
                      </span>
                  </span>
              </div>
              <!--end: Item -->

            </template>
          </div>
          <div class="col">
            <template>
              <!--begin: Item Total-->
              <div class="d-flex align-items-center rounded p-5 mb-5 bg-light-primary">
                      <span class="svg-icon-primary mr-5">

                        <i class="fas fa-fingerprint align-self-center text-primary"></i>

                      </span>
                <div class="d-flex flex-column flex-grow-1 mr-2">
                  <a href="#"
                     class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1 font-size-18 text-transform-capitalize">
                    Percentage of up review
                  </a>
                  <span class="font-size-lg"></span>
                </div>
                <span class="font-weight-bolder py-1 font-size-lg text-info">
                    <span class="badge badge-light font-size-20">
                       {{ percentage_of_up_review }}
                      </span>
                  </span>
              </div>
              <!--end: Item -->

            </template>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-md-4">
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"></b-form-input>
              <b-input-group-append>
                <b-button :disabled="filter" @click="filter = ''" variant="primary">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div class="row">
          <b-table
              striped hover
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              show-empty
              thead-class="bg-primary text-white"
              class="mt-2">

            <template #cell(index)="data">
              <div class="d-flex justify-content-between align-content-center align-items-center">
                <p class="mb-0">{{ data.index + 1 }}</p>
                <b-button size="sm" variant="primary" @click="data.toggleDetails">
                  <i class="fas fa-plus-circle"></i>
                </b-button>
              </div>
            </template>
            <template #cell(action)="data">

              <b-button variant="primary" @click="showFeedbackDetails(data)"><i class="far fa-eye"></i></b-button>


            </template>

            <template #row-details="row">

              <b-card>
                <b-table
                    striped hover
                    :items="row.item.list"
                    :fields="itemDetailsField"
                    thead-class="bg-primary"
                    head-variant="dark">

                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template #cell(feedback_percentages)="data">
                    <p v-for="(fd_percenntage, index) in data.item.feedback_percentages" :key="index">
                      {{ fd_percenntage.percentage }} </p>
                  </template>
                </b-table>
              </b-card>
            </template>

          </b-table>
        </div>
      </div>

      <b-modal id="feedbackDetails" size="lg" hide-footer title="Feedback details">
        <b-table
            striped hover
            :items="itemDetails"
            :fields="itemDetailsField"
            thead-class="bg-primary text-white">

          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(feedback_percentages)="data">
            <p v-for="(fd_percenntage, index) in data.item.feedback_percentages" :key="index">
              {{ fd_percenntage.percentage }} </p>
          </template>


        </b-table>
      </b-modal>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
  name: "FeedbackAnalysisReport",
  title: 'Feedback Analysis Report',
  data() {
    return {
      total_feedback: '',
      feedback_count_down: '',
      feedback_count_up: '',
      percentage_of_down_review: '',
      percentage_of_up_review: '',
      items: [],
      itemDetails: [],
      fields: [
        {
          label: 'Sl.',
          key: 'index',
          sortable: true,
        },
        {
          label: 'Name',
          key: 'name',
          sortable: true,
        },
        {
          label: 'App type',
          key: 'app_type',
          sortable: true,
        },
        {
          label: 'Down count',
          key: 'down_count',
          sortable: true,
        },
        {
          label: 'Feature percentage of down review',
          key: 'feature_percentage_of_down_review',
          sortable: true,
        },
        {
          label: 'Feature percentage of up review',
          key: 'feature_percentage_of_up_review',
          sortable: true,
        },
        {
          label: 'Up count',
          key: 'up_count',
          sortable: true,
        },
      ],
      itemDetailsField: [
        {
          label: 'Sl',
          key: 'index'
        },
        {
          label: 'Name',
          key: 'name'
        },
        {
          label: 'Type',
          key: 'type'
        },
        {
          label: 'Count',
          key: 'count',
        },
        {
          label: 'Feedback percentages',
          key: 'feedback_percentages'
        },

      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
    }
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    this.getFeedbackAnalysisReport();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Feedback Analysis Report", route: "/feedback-analysis-report"},
    ]);
  },
  methods: {
    getFeedbackAnalysisReport() {
      axios
          .get(`${process.env.VUE_APP_URL}/api/v1/report/feedback/analysis?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.items = res.data.data.feedback_feature;
            this.total_feedback = res.data.data.feedback_count;
            this.feedback_count_down = res.data.data.feedback_count_down;
            this.feedback_count_up = res.data.data.feedback_count_up;
            this.percentage_of_down_review = res.data.data.percentage_of_down_review;
            this.percentage_of_up_review = res.data.data.percentage_of_up_review;
          })

    },
    showFeedbackDetails(row) {
      this.$bvModal.show('feedbackDetails')
      this.itemDetails = row.item.list;
    }
  }
}
</script>

<style scoped>
.font-size-20 {
  font-size: 20px;
}
</style>