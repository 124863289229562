<template>
  <div>
    <b-modal
        id="modal-1"
        size="lg"
        title="Assign Team to Doctor"
        @hidden="resetModal"
        @ok="handleOk">
      <form ref="form" @submit.stop.prevent="handleSubmitEdit">
        <div class="row mb-5">
          <div class="col-6">
            <label>Team<span class="text-danger">*</span></label>
            <v-select
                @input="getUsers"
                :options="teams"
                :reduce="team => team.name"
                v-model="form.team"
                label="label"
                class="h-100"
                name="team">
            </v-select>
          </div>

          <div class="col-6">
            <label>Team User<span class="text-danger">*</span></label>
            <v-select
                :options="users"
                :reduce="user => user.id"
                v-model="form.team_user_id"
                label="fullname"
                class="h-100"
                name="team_user_id">
            </v-select>
          </div>
        </div>

      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "AssignTeamToDoctor",
  props: {
    doctor_id: null
  },
  data() {
    return {
      users: [],
      form: {
        team: '',
        team_user_id: '',
      },
      teams: [
        { name: "bd", label:"BD" },
        { name: "crm", label:"CRM" },
      ]
    }
  },
  mounted() {
    this.$bvModal.show('modal-1')
    if (this.doctor_id) {
      this.getAssignedTeam();
    }
  },
  methods: {
    resetModal() {
      this.form = {}
      this.$emit('modalClosed')
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmitEdit()
    },
    getUsers() {
      let url ='';
      if(this.form.team == 'crm') {
        url = '/api/v1/user/list/orko-center';
      } else {
        url = '/api/v1/user/list/orko-center/bd-users';
      }
      axios.get(`${process.env.VUE_APP_URL}${url}?api_token=${localStorage.getItem('api_token')}`).then(res => {
        this.users = res.data.data;
      })
    },
    handleSubmitEdit() {
      this.form.user_id = this.doctor_id;
      axios.post(`${process.env.VUE_APP_URL}/api/v2/administration/doctor/${this.doctor_id}/assign-team?api_token=${localStorage.getItem('api_token')}`, this.form).then(res => {
        if (res.data.status_code == 200) {
          this.$snotify.success('Team Successfully Assigned');
          this.$bvModal.hide('modal-1');
          this.resetModal();
        } else {
          this.$snotify.error(res.data.message);
        }
      })
    },
    getAssignedTeam() {
      axios.get(`${process.env.VUE_APP_API_URL}/api/v2/administration/doctor/${this.doctor_id}/assigned-team?api_token=${localStorage.getItem('api_token')}&user_id=${this.doctor_id}`).then(res => {
        if (res.data.status_code == 200) {
          this.form = res.data.data;
          this.getUsers();
        }
        
      })
    }
  }
}
</script>

<style scoped>

</style>
