<template>
  <div>
    <PrescriptionDashboard :counter="counter"/>
    <div class="card mt-3">
      <div class="card-body">
        <b-form @submit.prevent="getPrescription">
          <div class="d-flex justify-content-end align-items-center">
            <div>
              <b-button @click="dateRangeClear" variant="danger">Clear</b-button>
            </div>
            <div class="mx-5">
              <button
                  type="button"
                  @click="exportPrescription"
                  class="btn btn-primary"
                  name="Prescription List">
                <i class="fas fa-file-excel"></i> Export Excel
              </button>
            </div>
            <div>
              <b-button @click="getPrescription()" variant="info"><i class="fas fa-redo-alt"></i>
              </b-button>
            </div>
          </div>
          <div class="row justify-content-between align-items-center">
            <div class="col-md-1 mt-9">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    @input="getPrescription"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>

            <div class="col-md-2">
              <div class="">
                <label for="">From</label>
                <input type="date" v-model="from_date" class="form-control">
              </div>
              </div>
            <div class="col-md-2">
              <div>
                <label for="to_date">To</label>
                <input id="to_date" type="date" v-model="to_date" class="form-control">
              </div>
            </div>

            <div class="col-md-3 mt-9">
              <div class="d-flex">
                <b-form-select class="mr-1" v-model="payment_filter" :options="payment_status"></b-form-select>
                <b-form-select class="" v-model="prescription_filter"
                               :options="prescription_status"></b-form-select>
              </div>
            </div>

            <div class="col-md-3 mt-9">
              <b-form-group
                  label-for="filter-input"
                  label-cols-sm="1"
                  label-align-sm="right"
                  label-size="lg"
                  class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"/>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-md-1">
              <div>
                <b-button @click="getPrescription" variant="success" class="mt-9">Filter</b-button>
              </div>
            </div>
          </div>
        </b-form>
        <b-table
            responsive
            striped hover
            :items="items.length ? filtered : items"
            :fields="fields"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-3">


          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <p v-if="field.key == 'action' || field.key == 'status' || field.key=='is_paid' "></p>
              <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
            </td>
          </template>

          <template #cell(created_date)="row">
            <div class="">
              {{ row.item.created_date }} {{ row.item.created_at }}
            </div>
          </template>

          <template #cell(patient_details)="row">
            <div class="">
              <b-media tag="li">
                <template #aside>
                  <b-img :src="userPhoto(row.item.patient_details ? row.item.patient_details.image : '')"
                         class="user_image"
                         :alt="row.item.patient_details ? row.item.patient_details.fullname : ''"
                         v-if="row.item.patient_details"></b-img>


                  <img src="../../assets/img/blank.png" class="user_image" v-else/>
                </template>
                <h5 class="mt-0 mb-1" :class="row.item.patient_details.is_deleted == true ? 'text-danger' : ''">
                  {{ row.item.patient_details ? row.item.patient_details.fullname : '' }}</h5>
                <ul class="mb-0 user_info">
                  <li>
                    {{ row.item.patient_details ? row.item.patient_details.phone_number : '' }}
                  </li>

                </ul>
              </b-media>
              <div v-if="row.item.prescription_for_patient_details">
                <div v-if="row.item.prescription_for_patient_details.id != row.item.patient_details.id" class="mt-3">
                  <b-media tag="li">
                    <template #aside>
                      <b-img :src="userPhoto(row.item.prescription_for_patient_details.image)" class="user_image"
                             :alt="row.item.prescription_for_patient_details.fullname"
                             v-if="row.item.prescription_for_patient_details.image"></b-img>
                      <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>
                    </template>
                    <h5 class="mt-0 mb-1">{{ row.item.prescription_for_patient_details.fullname }}</h5>
                    <ul class="mb-0 user_info">
                      <li>
                        {{ row.item.prescription_for_patient_details.phone_number }}
                      </li>
                      <li>
                        <b-badge variant="primary" class="text-center">Parent</b-badge>
                      </li>
                    </ul>
                  </b-media>
                </div>
                <div class="text-center" v-else>
                  <b-badge variant="primary" class="text-center">Self</b-badge>
                </div>
              </div>
              <div>
              </div>
            </div>
          </template>

          <template #cell(doctor_details)="row">
            <div class="">
              <b-media tag="li">
                <template #aside>
                  <b-img :src="userPhoto(row.item.doctor_details.image)" class="user_image" alt="placeholder"
                         v-if="row.item.doctor_details.image"></b-img>
                  <b-img blank blank-color="#abc" class="user_image" alt="placeholder" v-else></b-img>
                </template>

                <h5 class="mt-0 mb-1">
                  <router-link :to="`user/details/${row.item.doctor_details.id}`">{{
                      row.item.doctor_details.fullname
                    }}
                  </router-link>
                </h5>
                <ul class="mb-0 user_info">
                  <li>
                    {{ row.item.doctor_details.phone_number }}
                  </li>
                </ul>
              </b-media>
            </div>
          </template>

          <template #cell(status)="row">
            <div class="">
              <b-badge variant="success" v-if="row.item.status == true">Valid</b-badge>
              <b-badge variant="danger" v-else>Invalid</b-badge>
            </div>
          </template>

          <template #cell(prescription_identifier)="row">

            <div class="viewer">

              <div class="d-flex me-3">
                <a class="text-info font-weight-bold d-flex" 
                   :href="prescriptionUrl(row.item.prescription_url)"
                   target="_black">
                  {{ row.item.prescription_identifier }}</a>

                <!-- <a :href="prescriptionUrl(row.item.prescription_url)" class="btn btn-primary ml-5 text-dark" target="_blank"><span class=""><i
                    class="fas fa-external-link-alt"></i></span></a> -->
              </div>
              <div class="pdf-viewer">

              </div>
            </div>
          </template>

          <template #cell(follow_update)>
            <div class="">
              <b-badge> n/a</b-badge>
            </div>
          </template>

          <template #cell(is_paid)="row">
            <div class="">
              <b-badge variant="success" v-if="row.item.is_paid == 1">Paid</b-badge>
              <b-badge variant="info" v-else-if="row.item.is_paid == 2">Partial Paid</b-badge>
              <b-badge variant="danger" v-else> Unpaid</b-badge>
            </div>
          </template>

          <template #cell(action)="row">
            <div class="">
              <b-dropdown variant="primary" class="m-2" no-caret id="dropdown-dropleft" dropleft
                          text="Drop-Left">
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item href="#" @click="sendInfo(row.item)" v-b-modal.modal-lg>
                  <span class="pr-4"><i class="fas fa-info-circle"></i></span>Reset Status
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="regeneratePrescription(row.item)">
                  <span class="pr-4"><i class="fas fa-info-circle"></i></span>Re-generate prescription
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>


        </b-table>
        <p class="mb-0 text-muted">Total : {{ this.perPage * currentPage }} - {{ totalItems }}</p>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                size="lg"
                class="my-0"
                @change="handlePageChange"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal id="modal-lg" size="lg" title="Reset Prescription Status to invalid" hide-footer>
      <b-form @submit="invalidPrescription" @reset="onReset" v-if="show">
        <h4 class="mb-10">Prescription identifier :
          <b-badge variant="warning" pill>{{ prescription_details.prescription_identifier }}</b-badge>
        </h4>

        <b-form-group
            label="Comment">
          <b-form-textarea
              id="textarea"
              v-model="form.comment"
              placeholder="Enter something..."
              rows="3"
          ></b-form-textarea>
        </b-form-group>


        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>

      </b-form>
    </b-modal>
    <!--    preview modal -->
    <b-modal
        id="prescription-viewer"
        ref="modal"
        title="Prescription viewer"
        size="lg"
        @show="resetModal"
        @hidden="resetModal"
        :hide-footer="true">
      <form ref="form">
        <vue-pdf-app style="height: 80vh"
                     :pdf="prescriptionUrl(this.pdf_viewer)">

        </vue-pdf-app>

      </form>
    </b-modal>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {PrescriptionMixin} from '../../mixins/PrescriptionMixin';
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
import PrescriptionDashboard from '../../components/prescriptions/PrescriptionDashboard';

export default {
  name: "Prescriptions",
  mixins: [PrescriptionMixin],
  components: {
    VuePdfApp,
    PrescriptionDashboard
  },
  title: 'Prescription list',
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.user_icon i {
  font-size: 10px;
}

.user_info li {

  margin: 5px 0px;
}

.user_info li i {
  padding: 4px 6px;
  font-size: 10px;
  color: #44A0FF;
  border-radius: 50%;
  background-color: rgba(230, 242, 255, 0.9);
  margin-right: 5px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}

.pdf-viewer {
  display: none;
  position: absolute;
  height: 500px;
  width: 500px;
  z-index: 9999;

}

.viewer a:hover + .pdf-viewer {
  display: block;
}

.snotify-confirm {
  background-color: red !important;
}
</style>
