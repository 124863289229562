<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <button class="btn btn-info" @click="addMessage">Add New Message</button>
      <b-modal
          id="createEditModal"
          ref="modal"
          title="Send Message"
          size="lg"
          @hidden="resetModal"
          @ok="handleOk"
          scrollable
          class="height-100"
      >
        <form ref="form" @submit.stop.prevent="handleSubmit" style="height: 100vh;">
          <div class="row align-items-center">
            <div class="col-md-6">
              <b-form-group label="Language">
                <b-form-select v-model="form.language" :options="languages"></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Message Type">
                <b-form-select v-model="form.message_type" :options="message_types"></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-12 mb-5" v-if="form.message_type == 'bulk'">
              <b-form-checkbox
              id="is_corporate"
              v-model="form.is_corporate"
              name="is_corporate"
              value="1"
              unchecked-value="0"
              @input="getInsitute()"
          >
            Is Corporate ?
          </b-form-checkbox>
          <v-select
          v-if="form.is_corporate==1"
                    :options="institutes"
                    :reduce="institutes => institutes.id"
                    v-model="form.institute_id"
                    name="institute_id"
                    label="name"></v-select>
            </div>
            <div class="col-md-6" v-if="form.is_corporate != 1">
              <b-form-group label="Receiver Type">
                <b-form-select v-model="form.receiver_type" :options="receiver_types" @input="getUser"></b-form-select>
              </b-form-group>
            </div>
            
            <div class="col-md-6" v-if="form.receiver_type == 'other' && form.message_type == 'single'">
              <b-form-group label="Phone No">
                <b-form-input
                    id="input"
                    v-model="form.phone_no"
                    placeholder="Enter phone no."
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6" v-if="form.is_corporate != 1 && form.receiver_type != 'other'">
                <div>
                  <label for="">Enter phone number</label>
                  <vue-autosuggest
                    :suggestions="[{data:users}]"
                    v-model="form.user_id"
                    :input-props="{id:'autosuggest__input', placeholder:'Enter phone number', disabled: form.message_type=='single' && pushedUsers.length == 1 ? true : false}"
                    @input="onInputChange"
                    @selected="selectHandler"
                    @click="clickHandler"
                    :get-suggestion-value="getSuggestionValue"
                    
                >  
                  <template slot-scope="{suggestion}">
                    <span class="my-suggestion-item">{{suggestion.item.fullname}}</span>
                  </template>
                </vue-autosuggest>

                <div v-if="pushedUsers.length" class="position-relative">
                  <b-badge pill variant="info" class="m-3" v-for="(pushed_user, index) in pushedUsers" :key="index">{{ pushed_user.fullname }} <span @click="removePushedUser(index)" class="remove_user">X</span></b-badge>
                </div>
              </div> 
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-12">
              <b-form-group label="Message Body">
                <b-form-textarea
                    id="textarea"
                    v-model="form.body"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                    :disabled="body_is_desiable"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-form-checkbox
                id="checkbox-1"
                v-model="form.is_published"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
              >
                Is Published ?
              </b-form-checkbox>
            </div>
            
          </div>
          <!-- <div class="row mt-5" v-if="form.is_corporate==1">
            <div class="col-md-12">
              <label for="">Select template</label>
              <v-select
                    :options="items"
                    :reduce="items => items.id"
                    v-model="form.template_id"
                    name="body"
                    @input="templateSelected()"
                    label="body"></v-select>
            </div>
          </div> -->
          
        </form>
      </b-modal>
      <div class="row mt-5">
        <div class="col-md-3 ">
          <label for="">From</label>
          <input type="date" v-model="start_date" class="form-control">
        </div>
        <div class="col-md-3">
          <label for="">To</label>
          <input type="date" v-model="end_date" class="form-control">
        </div>
        <div class="col-md-2">
          <label for="">Select Is Publish</label>
          <b-form-select v-model="is_published" :options="publish_options"></b-form-select>
        </div>
        <div class="col-md-1 mt-8">
          <button type="button" class="btn btn-info" @click="getItems">Filter</button>

        </div>
        <div class="col-md-1 mt-8">
          <button type="button" class="btn btn-danger mx-5" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
            <template #cell(is_published)="row">
              <b-badge :variant="row.item.is_published == 1 ? 'primary' : 'warning'">
                {{ row.item.is_published == 1 ? 'Yes' : 'No' }}
              </b-badge>
            </template>
            <template #cell(message_type)="row">
              <b-badge :variant="row.item.message_type == 'single' ? 'primary' : 'info'">{{
                  row.item.message_type
                }}
              </b-badge>
            </template>
            <template #cell(actions)="row">
              <div v-if="row.item.is_published !=1">
                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item href="#" @click="edit(row.item)">
                    <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                  </b-dropdown-item>

                  <b-dropdown-item href="#" @click="publish(row.item)">
                    <span class="pr-4"><i class="fas fa-eye"></i></span>Publish
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              @change="getItems"
              class="my-0"
              aria-controls="doctorList"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
// import {dateFormate} from "../../healper/dateTimeFormet";
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {VueAutosuggest} from 'vue-autosuggest';

export default {
  name: "SmsList",
  title: 'Message',
  components: {
    VueAutosuggest
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: 20,
      items: [],
      fields: [
        {
          key: 'body',
          label: 'Message',
          sortable: true,
        },
        {
          key: 'language',
          label: 'Language',
          sortable: true,
        },
        {
          key: 'receiver_type',
          label: 'Receiver Type',
          sortable: true,
        },
        {
          key: 'phone_no',
          label: 'Phone No',
          sortable: true,
        },
        {
          key: 'message_type',
          label: 'Message type',
          sortable: true,
        },
        {
          key: 'is_published',
          label: 'Is published',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Created At',
          sortable: true,
        },
        {
          key: "actions",
        },
      ],
      total_count: 0,
      languages: [
        {
          text: 'English',
          value: 'english'
        },
        {
          text: 'Bangla',
          value: 'bangla'
        }
      ],
      message_types: [
        {
          text: 'Single',
          value: 'single'
        },
        {
          text: 'Bulk',
          value: 'bulk'
        }
      ],
      receiver_types: [
        {
          text: 'Patient',
          value: 'patient'
        },
        {
          text: 'Doctor',
          value: 'doctor'
        },
        {
          text: 'Hospital admin',
          value: 'hospital-admin'
        },
        {
          text: 'Agent pharmacy',
          value: 'agent-pharmacy'
        },
        {
          text: 'Agent',
          value: 'agent'
        },
        {
          text: 'aide',
          value: 'aide'
        },
        {
          text: 'Sales',
          value: 'agent_sales'
        },
        {
          text: 'Executive',
          value: 'executive'
        },
        {
          text: 'Other',
          value: 'other'
        },
      ],
      nameState: null,
      form: {
        id: '',
        body: '',
        language: 'bangla',
        receiver_type: 'patient',
        message_type: 'single',
        receiver_ids: [],
        is_published: 0,
        is_corporate: 0,
        user_id: '',
        institute_id: '',
        template_id: null,
      },
      users: [],
      user_loader: false,
      start_date: null,
      end_date: null,
      is_published: null,
      publish_options: [
        {
          text: 'yes',
          value: 1
        },
        {
          text: 'No',
          value: 0
        }
      ],
      sectionConfigs: {
        'default': {
          limit: 6,
          onSelected: function (item, originalInput) {
            console.log(item, originalInput, `Selected "${item.item}"`);
          }
        },
      },
      search: '',
      pushedUsers: [],
      institutes: [],
      body_is_desiable: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Message", route: "/messages"},
    ]);
  },
  created() {
    localStorage.setItem('tabIndex', 15)
    this.$root.$emit('call-active-menu', 15);
    this.getItems();

  },
  methods: {
    getItems(value) {
      this.loading = true;
      if (value) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }

      let url = `${process.env.VUE_APP_URL}/api/v2/administrator/messages?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}${(this.is_published == 1 || this.is_published == 0) ? '&is_published=' + this.is_published : ''}`;
      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    getUser() {
      console.log('ok');
      this.pushedUsers=[];
      this.user_loader = true
      axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&user_type=${this.form.receiver_type}&platform=backoffice`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied');
            } else {
              this.users = response.data.data
              this.user_loader = false;
            }
          })
    },
    templateSelected(){
      console.log('yes called', this.form.template_id);
      this.body_is_desiable = true;
      const template_obj = this.items.find(item => item.id ==  this.form.template_id);
      
      this.form.body = template_obj.body;
    },
    getInsitute(){
      axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000&api_token=${localStorage.getItem('api_token')}`)
      .then(res => {
        this.institutes = res.data.data;
      })
    },
    clear() {
      this.start_date = null;
      this.end_date = null;
      this.is_published = null;
      this.getItems();
    },
    addMessage() {
      this.$bvModal.show('createEditModal')
    },
    resetModal() {
      this.form = {
        id: '',
        body: '',
        language: 'bangla',
        receiver_type: 'patient',
        message_type: 'single',
        phone_no:'',
        receiver_ids: [],
        is_published: 0,
        is_corporate: 0,
        user_id: '',
        institute_id: '',
        template_id: null,
      }
      this.pushedUsers = [];
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      if (this.pushedUsers) {
          var res = this.pushedUsers.map(s=>s.id);
          this.form.receiver_ids = res
      }

      if(this.form.is_corporate == 1) {
        if(!this.form.institute_id) {
          return this.$snotify.error('Select an Institute');
        }
      }

      console.log('dada', this.form);
      
      this.$nextTick(() => {
        let url = "";
        if (this.form.id) {
          url = `${process.env.VUE_APP_URL}/api/v2/administrator/messages/${this.form.id}?_method=put&api_token=${localStorage.getItem('api_token')}`
        }else if(this.form.template_id){
          url = `${process.env.VUE_APP_URL}/api/v2/administrator/messages/${this.form.template_id}/publish?_method=put&api_token=${localStorage.getItem('api_token')}`
        } else {
          url = `${process.env.VUE_APP_URL}/api/v2/administrator/messages?api_token=${localStorage.getItem('api_token')}`
        }
        axios
            .post(url, this.form)
            .then(response => {
              if (response.data.status_code == 400) {
                return this.$snotify.error(response.data.message);
              }
              this.form = {
                id: '',
                body: '',
                language: 'bangla',
                receiver_type: 'patient',
                message_type: 'single',
                receiver_ids: [],
                is_published: 0,
                is_corporate: 0,
                user_id: '',
                institute_id: '',
                template_id: null,
              },
              this.resetModal();
              this.$bvModal.hide('createEditModal')
              this.getItems();
              this.$snotify.success('Successfully saved');
            })
      })
    
    },

    edit(item) {

      if (item.receiver_ids) {
        this.form.receiver_ids = item.receiver_ids;
      }
      this.form = item;
      this.getUser();
      this.$bvModal.show('createEditModal');
    },

    publish(row) {
      this.$snotify.clear();
      this.$snotify.confirm(
          "You want to Publish?",
          "Are you sure?",
          {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: "Yes",
                action: toast => {
                  this.$snotify.remove(toast.id);
                  axios
                      .put(`${process.env.VUE_APP_URL}/api/v2/administrator/messages/${row.id}/publish?api_token=${localStorage.getItem('api_token')}`)
                      .then(response => {

                        if (response.data.status_code == 400) {
                          return this.$snotify.error(
                              response.data.message,
                              "Error"
                          );
                        }

                        this.$snotify.success(
                            "Successfully Published",
                            "Success"
                        );
                        this.getItems();

                      })
                      .catch(e => {
                        this.$snotify.error(
                            e
                        );
                      });
                },
                bold: true
              },
              {
                text: "No",
                action: toast => {
                  this.$snotify.remove(toast.id);
                },
                bold: true
              }
            ]
          }
      );
    },

    // vue suggestion
    onInputChange(val){
        axios.get(`${process.env.VUE_APP_URL}/api/v2/user/search?api_token=${localStorage.getItem('api_token')}&user_type=${this.form.receiver_type}&q=${val}`)
            .then(response => {
                this.users = response.data.data
            })
    },

    selectHandler(item){
        if(this.form.user_id){
            this.form.user_id = ''
        }
        this.pushedUsers.push(item.item);
    },

    clickHandler(){
        if(this.form.user_id) {
            this.form.user_id = ''
        }
    },

    getSuggestionValue(suggestion) {
        return "";
    },

    removePushedUser(index){
        this.pushedUsers.splice(index, 1);
    }
  },
}
</script>

<style>

.height-100 {
  height: 100%;
}
#autosuggest__input {
  background-color: var(--theme-bg);
  caret-color: #ddd;
  color: var(--theme-color);
  position: relative;
  display: block;
  font-family: Poppins, Helvetica, "sans-serif" !important;
  font-size: 12px !important;
  border: 1px solid #e4e6ef !important;
  border-radius: 0.42rem !important;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
</style>
