import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import config from "./config.module";
import htmlClass from "./htmlclass.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import dashboard from "./dashboard";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        errors: []
    },

    getters: {
        errors: state => state.errors
    },

    mutations: {
        setErrors(state, errors) {
            state.errors = errors;
        }
    },

    actions: {},

    modules: {
        auth,
        config,
        htmlClass,
        breadcrumbs,
        profile,
        dashboard
    }
});
