<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <h3 class="mb-10">Doctor confirmation process</h3>
      <div class="row">
        <div class="col-md-4">
          <div>
            <b-form-checkbox
                id="identity_card"
                name="identity_card"
                v-model="varifing.identity_card"
                value="1"
                @change="changeStatus(varifing.identity_card, 'identity_card')"
                unchecked-value="0">
              Is NID Added?
            </b-form-checkbox>
          </div>
          <div>
            <b-form-checkbox
                id="medical_registration"
                name="medical_registration"
                value="1"
                @change="changeStatus(varifing.medical_registration, 'medical_registration')"
                v-model="varifing.medical_registration"
                unchecked-value="0">
              Is Medical Registration(BMDC) Added?
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-md-4">
          <div>
            <b-form-checkbox
                id="overview"
                name="overview"
                value="1"
                @change="changeStatus(varifing.overview, 'overview')"
                v-model="varifing.overview"
                unchecked-value="0">
              Is Overview Completed?
            </b-form-checkbox>
          </div>
          <div>
            <b-form-checkbox
                id="experience"
                name="experience"
                value="1"
                @change="changeStatus(varifing.experience, 'experience')"
                v-model="varifing.experience"
                unchecked-value="0">
              Is Job Experience Added?
            </b-form-checkbox>
          </div>
        </div>
        <div class="col-md-4">
          <div>
            <b-form-checkbox
                id="education"
                name="education"
                value="1"
                @change="changeStatus(varifing.education, 'education')"
                v-model="varifing.education"
                unchecked-value="0">
              Is Medical Educations Added?
            </b-form-checkbox>
          </div>

        </div>
      </div>
      <div class="mt-3 text-center">
        <button class="btn btn-danger btn-lg mr-5 custom-lg-btn" v-if="basic_info.confirmed" @click="unverify()">
          Unverify
        </button>
        <button class="btn btn-success btn-lg custom-lg-btn" v-else @click="verify()">Verify</button>
      </div>
      <div class="row" v-if="basic_info.action_comments">
        <div class="col-md-12">
          <b>Verified By</b>
          <div v-if="basic_info.action_comments.action_type == 'verify'">
            <h3>{{
                basic_info.action_comments.action_taker
              }}</h3>
            <p>{{
                basic_info.action_comments.action_comment
              }}</p>
          </div>
        </div>
      </div>
    </div>

    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "DoctorConfirmation",
  props: {
    basic_info: {},
  },
  data() {
    return {
      varifing: {
        visit_fee: '',
        identity_card: '',
        medical_registration: '',
        overview: '',
        experience: '',
        education: '',
        telemedicine: '',
      },
      status_for_varifing: '',
    }
  },
  mounted() {
    this.verifyDoctorStep();
  },
  methods: {
    verifyDoctorStep() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/doctor/profile/verification/status?doctor_id=${this.$route.params.id}&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.varifing = response.data.data
          })
    },
    unverify() {
      this.$snotify.clear();
      this.$snotify.confirm(
          "You want to unverify  this doctor",
          "Are you sure?",
          {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: "Yes",
                action: toast => {
                  this.$snotify.remove(toast.id);
                  axios
                      .post(`${process.env.VUE_APP_URL}/api/v1/report/doctor/profile-verification/manipulate?doctor_id=${this.basic_info.id}&status=0&api_token=${localStorage.getItem('api_token')}`)
                      .then(response => {
                        if (response.data.status_code == 400) {
                          return this.$snotify.error(
                              response.data.message,
                              "Error"
                          );
                        }
                        this.$emit('clicked', 'someValue')
                        this.$snotify.success(
                            "Doctor unverify successfully",
                            "Success"
                        );
                        location.reload();
                      })
                      .catch(e => {
                        this.$snotify.error(
                            e
                        );
                      });
                },
                bold: true
              },
              {
                text: "No",
                action: toast => {
                  this.$snotify.remove(toast.id);
                },
                bold: true
              }
            ]
          }
      );

    },
    verify() {
      this.$snotify.clear();
      this.$snotify.confirm(
          "You want verify this doctor",
          "Are you sure?",
          {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: "Yes",
                action: toast => {
                  this.$snotify.remove(toast.id);
                  axios
                      .post(`${process.env.VUE_APP_URL}/api/v1/report/doctor/profile-verification/manipulate?doctor_id=${this.basic_info.id}&status=1&api_token=${localStorage.getItem('api_token')}`)
                      .then(response => {

                        if(response.data.status_code == 200){
                          return this.$snotify.success(
                              "Doctor verify successfully",
                              "Success"
                          );
                        }

                        return this.$snotify.error(
                            response.data.message,
                            "Error"
                        );

                        // location.reload();

                      })
                      .catch(e => {
                        this.$snotify.error(
                            e
                        );
                      });
                },
                bold: true
              },
              {
                text: "No",
                action: toast => {
                  this.$snotify.remove(toast.id);
                },
                bold: true
              }
            ]
          }
      );

    },
    changeStatus(value, record_type) {

      this.$snotify.clear();
      this.$snotify.confirm(
          "You will change Doctor confirmation process",
          "Are you sure?",
          {
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            buttons: [
              {
                text: "Yes",
                action: toast => {
                  this.$snotify.remove(toast.id);
                  axios
                      .post(`${process.env.VUE_APP_URL}/api/v1/report/doctor/profile-status/manipulate?api_token=${localStorage.getItem('api_token')}&doctor_id=${this.basic_info.id}&record_type=${record_type}&status=${value}`)
                      .then(response => {

                        if (response.data.status_code == 400) {
                          var verificationDatas = document.getElementsByName(record_type);
                          for (var vdata of verificationDatas) {
                            vdata.checked = !vdata.checked;
                          }
                          return this.$snotify.error(
                              response.data.message,
                              "Error"
                          );
                        }
                        this.$snotify.success(
                            `${record_type} updated successfully`,
                            "Success"
                        );

                      })
                      .catch(e => {
                        this.$snotify.error(
                            e
                        );
                      });
                },
                bold: true
              },
              {
                text: "No",
                action: toast => {
                  var verificationDatas = document.getElementsByName(record_type);
                  for (var vdata of verificationDatas) {
                    vdata.checked = !vdata.checked;
                  }
                  this.$snotify.remove(toast.id);
                },
                bold: true
              }
            ]
          }
      );

    }
  }
}
</script>

<style scoped>
.custom-lg-btn {
  padding: 12px 10px;
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 15px;
}
</style>
