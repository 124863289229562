<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3 d-flex justify-content-end">
              <div>
                <div class="d-flex">
                  <div class="mx-2">
                    <b-button @click="dateRangeClear" variant="danger">Clear</b-button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="row justify-content-between">
                <div class="col-md-1 mt-9">
                  <div class="">
                    <b-form-group
                        class="mb-0">
                      <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="row">
                    <div class="col-md-6">
                      <label for="">From</label>
                      <input type="date" v-model="start_date" class="form-control">
                    </div>
                    <div class="col-md-6">
                      <label for="">To</label>
                      <input type="date" v-model="end_date" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="">Select TSO</label>
                  <b-form-group>
                    <v-select
                        :options="sales_users"
                        :reduce="sales_users => sales_users.id"
                        v-model="seller_id"
                        label="name">
                    </v-select>
                  </b-form-group>
                </div>
                <div class="col-md-2">
                  <b-form-group>
                    <label for="">Chart type</label>
                  <b-form-select v-model="chart_name" :options="filtersKay" ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-2" v-if="chart_name && chart_name != 'speciality'">
                  <b-form-group >
                    <label for="">Chart value</label>
                  <b-form-select v-model="chart_value" :options="filtersValue[chart_name]"></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-2" v-if="chart_name == 'speciality'">
                  <b-form-group >
                    <label for="">Chart value</label>
                  <b-form-select v-model="chart_value" value-field="speciality"
                                 text-field="speciality" :options="specialities"></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-md-1 mt-8">
                  <button type="button" class="btn btn-info" @click="getUser">Filter</button>
                </div>
              </div>
              <!-- Table -->
              <div class="row">
                <div class="col-12">
                  <b-table
                      striped hover responsive
                      :items="items"
                      :fields="computedFields"
                      :current-page="currentPage"
                      thead-class="bg-primary text-white"
                      class="mt-3"
                      show-empty>


                    <template #cell(seller_id)="row">
                      <user-card :image="row.item.seller_image" :name="row.item.seller_name"
                                 :phone_number="row.item.seller_phone_number" :user_id="row.item.seller_id"/>
                    </template>

                    <template #cell(is_senior_doctor)="row">
                      <b-badge variant="success" v-if="row.item.is_senior_doctor == 1">Senior</b-badge>
                      <b-badge variant="info" v-else>Junior</b-badge>
                    </template>

                    <template #cell(registered_at)="row">
                      <span v-if="row.item.registered_at">{{ createdAtFormat(row.item.registered_at) }}</span>
                      <span v-else>Not register yet</span>
                    </template>

                    <template #cell(full_name)="row">
                      <user-card :name="row.item.full_name"
                                 :phone_number="row.item.phone_no">
                        <p style="margin-bottom: 0" v-if="row.item.speciality">{{ row.item.speciality }}</p>
                        <p v-if="row.item.bmdc_reg_no">BMDC Reg: {{ row.item.bmdc_reg_no }}</p>
                      </user-card>
                    </template>

                    <template #cell(hospital_id)="row">
                      <div>
                        <p class="mb-0"><b>Hospital name: </b>{{ row.item.hospital_name }}</p>
                        <p class="mb-0"><b>Chamber address: </b>{{ row.item.chamber_address }}</p>
                        <p class="mb-0"><b>Daily patient: </b>
                          <b-badge variant="info" style="font-size: 16px;font-weight: bold;">{{
                              row.item.daily_patient
                            }}
                          </b-badge>
                        </p>
                      </div>
                    </template>


                    <template #cell(is_confirmed)="row">
                      <div class="is_online text-center" v-if="row.item.registered_at">
                        <p v-if="row.item.is_confirmed==1" class="text-success font-weight-bold"> Verified</p>
                        <p v-else-if="row.item.is_confirmed == 0" class="text-warning font-weight-bold">Pending</p>
                        <p v-else class="text-danger font-weight-bold">Unverified</p>
                      </div>
                      <div v-else>
                        not registered
                      </div>
                    </template>

                    <template #cell(created_at)="row">
                      {{ createdAtFormat(row.item.created_at) }}
                    </template>


                  </b-table>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="total_activity"
                      :per-page="perPage"
                      size="lg"
                      @change="handlePageChange"
                      class="my-0"
                      aria-controls="doctorList"
                  ></b-pagination>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {DateFormat} from "../../mixins/core/DateFormat";
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";
import {SalesActivityDoctorMixin} from "../../mixins/SalesActivityDoctorMixin";

export default {
  name: "SalesActivityDoctorList",
  title: 'Sales Activity Doctor Log',
  mixins: [SalesActivityDoctorMixin, DateFormat, SalesUserMixins],
}
</script>
<style scoped>
.squre-img {
  height: 50px;
}
</style>
