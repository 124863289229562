<template>
  <div class="mt-3 card card-custom gutter-b">
    <div class="card-body">

      <div class="mb-5 d-flex justify-content-between">
        <!--     Workplace modal     -->
        <b-modal
            id="addEditModal"
            title="Add or edit telemedicine"
            @hidden="resetModal"
            @show="resetModal"
            size="lg"
            @ok="handleOk">
          <form ref="form" @submit.stop.prevent="updateDoctorTimeSlot">

            <div>
              <b-form-group
                  id="fieldset-1"
                  label="Time slot">
                <b-form-input id="input-1" v-model="telimedicine.time_slot"></b-form-input>
              </b-form-group>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                      id="fieldset-1"
                      label="Primary Contact no.">
                    <b-form-input id="input-1" v-model="telimedicine.primary_contact_number" type="number"></b-form-input>
                  </b-form-group>
                </div>


                <div class="col-md-6">
                  <b-form-group
                      id="fieldset-1"
                      label="Secondary Contact no.">
                    <b-form-input id="input-1" v-model="telimedicine.second_contact_number" type="number"></b-form-input>
                  </b-form-group>
                </div>

                <div class="col-md-6">
                  <b-form-group
                      id="fieldset-1"
                      label="Select Active Status">
                    <b-form-select v-model="telimedicine.is_active" :options="activeOptions"></b-form-select>
                  </b-form-group>

                </div>
              </div>

              <b-button-group size="sm" style="width: 100%">
                <b-button
                    v-for="(btn, idx) in weeks"
                    :key="idx"
                    @click="clickDay(btn.caption)"
                    :pressed.sync="btn.state"
                    variant="primary"
                >
                  {{ btn.caption }}
                </b-button>
              </b-button-group>

              <!--       Sunday slot         -->
              <div v-if="chamber_is_sunday" class="row">
                <div class="col-md-12">
                  <div class="card p-5">
                    <b-card-text class="">
                      <h5>Sunday</h5>
                      <div class="row align-content-center align-items-center mb-5"
                           v-for="(sun, index) in sunday" :key="index">

                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="Start time slot"
                              label-for="sunday_  start_time"
                              class="mb-0">
                            <b-form-input id="sunday_start_time" type="time"
                                          v-model="sun.sunday_start_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="End time slot"
                              label-for="sunday_end_time"
                              class="mb-0"
                              type="time">
                            <b-form-input id="sunday_end_time" type="time"
                                          v-model="sun.sunday_end_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-danger mt-8" @click="removeItem(sun)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>

                      </div>
                      <b-button variant="info" class="float-right mt-2" @click="AddItem()" v-if="sunday.length < 3">Add
                        more
                      </b-button>
                    </b-card-text>
                  </div>

                </div>
              </div>

              <!--       Monday slot         -->
              <div v-if="chamber_is_monday" class="row mt-2">
                <div class="col-md-12">
                  <div class="card p-5">
                    <b-card-text class="">
                      <h5>Monday</h5>
                      <div class="row align-content-center align-items-center mb-5"
                           v-for="(mon, index) in monday" :key="index">

                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="Start time slot"
                              label-for="sunday_  start_time"
                              class="mb-0">
                            <b-form-input id="sunday_start_time" type="time"
                                          v-model="mon.monday_start_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="End time slot"
                              label-for="sunday_end_time"
                              class="mb-0"
                              type="time">
                            <b-form-input id="sunday_end_time" type="time"
                                          v-model="mon.monday_end_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-danger mt-8" @click="removeMonday(mon)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                      <b-button variant="info" class="float-right mt-2" @click="AddMondayItem()"
                                v-if="monday.length < 3">Add more
                      </b-button>
                    </b-card-text>
                  </div>

                </div>
              </div>

              <!--       Tuesday slot         -->
              <div v-if="chamber_is_tuesday" class="row mt-2">
                <div class="col-md-12">
                  <div class="card p-5">
                    <b-card-text class="">
                      <h5>Tuesday</h5>
                      <div class="row align-content-center align-items-center mb-5"
                           v-for="(tues, index) in tuesday" :key="index">

                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="Start time slot"
                              label-for="sunday_  start_time"
                              class="mb-0">
                            <b-form-input id="sunday_start_time" type="time"
                                          v-model="tues.tuesday_start_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="End time slot"
                              label-for="sunday_end_time"
                              class="mb-0"
                              type="time">
                            <b-form-input id="sunday_end_time" type="time"
                                          v-model="tues.tuesday_end_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-danger mt-8" @click="removeTuesday(tues)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                      <b-button variant="info" class="float-right mt-2" @click="AddTuesdayItem()"
                                v-if="tuesday.length < 3">Add more
                      </b-button>
                    </b-card-text>
                  </div>

                </div>
              </div>

              <!--       Wednesday slot         -->
              <div v-if="chamber_is_wednesday" class="row mt-2">
                <div class="col-md-12">
                  <div class="card p-5">
                    <b-card-text class="">
                      <h5>Wednesday</h5>
                      <div class="row align-content-center align-items-center mb-5"
                           v-for="(wed, index) in wednesday" :key="index">

                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="Start time slot"
                              label-for="sunday_  start_time"
                              class="mb-0">
                            <b-form-input id="sunday_start_time" type="time"
                                          v-model="wed.wednesday_start_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="End time slot"
                              label-for="sunday_end_time"
                              class="mb-0"
                              type="time">
                            <b-form-input id="sunday_end_time" type="time"
                                          v-model="wed.wednesday_end_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-danger mt-8" @click="removeWednesday(wed)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                      <b-button variant="info" class="float-right mt-2" @click="AddWednesdayItem()"
                                v-if="wednesday.length < 3">Add more
                      </b-button>
                    </b-card-text>
                  </div>

                </div>
              </div>

              <!--       Thursday slot         -->
              <div v-if="chamber_is_thursday" class="row mt-2">
                <div class="col-md-12">
                  <div class="card p-5">
                    <b-card-text class="">
                      <h5>Thursday</h5>
                      <div class="row align-content-center align-items-center mb-5"
                           v-for="(thur, index) in thursday" :key="index">

                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="Start time slot"
                              label-for="sunday_  start_time"
                              class="mb-0">
                            <b-form-input id="sunday_start_time" type="time"
                                          v-model="thur.thursday_start_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="End time slot"
                              label-for="sunday_end_time"
                              class="mb-0"
                              type="time">
                            <b-form-input id="sunday_end_time" type="time"
                                          v-model="thur.thursday_end_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-danger mt-8" @click="removeThursday(thur)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                      <b-button variant="info" class="float-right mt-2" @click="AddThursdayItem()"
                                v-if="thursday.length < 3">Add more
                      </b-button>
                    </b-card-text>
                  </div>

                </div>
              </div>

              <!--       Friday slot         -->
              <div v-if="chamber_is_friday" class="row mt-2">
                <div class="col-md-12">
                  <div class="card p-5">
                    <b-card-text class="">
                      <h5>Friday</h5>
                      <div class="row align-content-center align-items-center mb-5"
                           v-for="(fri, index) in friday" :key="index">

                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="Start time slot"
                              label-for="sunday_start_time"
                              class="mb-0">
                            <b-form-input id="sunday_start_time" type="time"
                                          v-model="fri.friday_start_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="End time slot"
                              label-for="sunday_end_time"
                              class="mb-0"
                              type="time">
                            <b-form-input id="sunday_end_time" type="time"
                                          v-model="fri.friday_end_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-danger mt-8" @click="removeFriday(fri)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                      <b-button variant="info" class="float-right mt-2" @click="AddFridayItem()"
                                v-if="friday.length < 3">Add more
                      </b-button>
                    </b-card-text>
                  </div>

                </div>
              </div>

              <!--       Saturday slot         -->
              <div v-if="chamber_is_saturday" class="row mt-2">
                <div class="col-md-12">
                  <div class="card p-5">
                    <b-card-text class="">
                      <h5>Saturday</h5>
                      <div class="row align-content-center align-items-center mb-5"
                           v-for="(sat, index) in saturday" :key="index">

                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="Start time slot"
                              label-for="sunday_start_time"
                              class="mb-0">
                            <b-form-input id="sunday_start_time" type="time"
                                          v-model="sat.saturday_start_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-5">
                          <b-form-group
                              id="fieldset-1"
                              label="End time slot"
                              label-for="sunday_end_time"
                              class="mb-0"
                              type="time">
                            <b-form-input id="sunday_end_time" type="time"
                                          v-model="sat.saturday_end_time"></b-form-input>
                          </b-form-group>
                        </div>
                        <div class="col-md-2">
                          <button type="button" class="btn btn-danger mt-8" @click="removeSaturDay(sat)">
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                      <b-button variant="info" class="float-right mt-2" @click="AddSaturdayItem()"
                                v-if="saturday.length < 3">Add more
                      </b-button>
                    </b-card-text>
                  </div>

                </div>
              </div>

            </div>

          </form>
        </b-modal>
      </div>

      <div class="row mt-10" v-if="workplaces">

        <div class="col-12">
          <div class="d-flex justify-content-between align-content-center align-items-center">
            <h3>Telemedicine Info</h3>
            <b-button class="btn btn-sm btn-light-info font-weight-bolder text-uppercase mr-3"
                      @click="addEditTelemedicinne">
              Manage telemedicine
            </b-button>
          </div>
          <div v-for="(telemedicine, index) in workplaces" :key="index">

            <div v-if="telemedicine.name == 'Telemedicine' && telemedicine.chamber_schedules">
              <div class="d-flex justify-content-end m-5 align-items-center">
                <h3 class="mb-0" v-if="telemedicine.is_active"><b-badge pill variant="success" class="mr-5">Active</b-badge></h3>
                <h3 class="mb-0" v-else><b-badge pill variant="danger" class="mr-5">Inactive</b-badge></h3>
<!--                <h3 class="mb-0" v-if="telemedicine.is_active"><b-badge  variant="success" class="mr-5">Actives</b-badge></h3>-->
<!--                <h3 class="mb-0" v-else><b-badge  variant="danger" class="mr-5">In Active</b-badge></h3>-->

              </div>

              <div class="mt-10">


                <div class="card mb-5" v-if="groupBy(telemedicine.chamber_schedules, 'day').sunday">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <h4>Sunday</h4>
                      <h4>Start time</h4>
                      <h4>End time</h4>
                    </div>
                    <div v-for="(tele, index) in groupBy(telemedicine.chamber_schedules, 'day')" :key="index">

                      <div v-for="(t, tt) in tele" :key="tt">
                        <div v-if="t.day == 'sunday'" class="mb-0 d-flex justify-content-between text-center">
                          <div>
                            {{ t.day }}
                          </div>
                          <div>
                            {{ t.chamber_start_time }}
                          </div>
                          <div>
                            {{ t.chamber_close_time }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div class="card mb-5" v-if="groupBy(telemedicine.chamber_schedules, 'day').monday">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <h4>Monday</h4>
                      <h4>Start time</h4>
                      <h4>End time</h4>
                    </div>
                    <div v-for="(tele, index) in groupBy(telemedicine.chamber_schedules, 'day')" :key="index">

                      <div v-for="(t, tt) in tele" :key="tt">
                        <div v-if="t.day == 'monday'" class="mb-0 d-flex justify-content-between text-center">
                          <div>
                            {{ t.day }}
                          </div>
                          <div>
                            {{ t.chamber_start_time }}
                          </div>
                          <div>
                            {{ t.chamber_close_time }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card mb-5" v-if="groupBy(telemedicine.chamber_schedules, 'day').tuesday">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <h4>Tuesday</h4>
                      <h4>Start time</h4>
                      <h4>End time</h4>
                    </div>
                    <div v-for="(tele, index) in groupBy(telemedicine.chamber_schedules, 'day')" :key="index">

                      <div v-for="(t, tt) in tele" :key="tt">
                        <div v-if="t.day == 'tuesday'" class="mb-0 d-flex justify-content-between text-center">
                          <div>
                            {{ t.day }}
                          </div>
                          <div>
                            {{ t.chamber_start_time }}
                          </div>
                          <div>
                            {{ t.chamber_close_time }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="card mb-5" v-if="groupBy(telemedicine.chamber_schedules, 'day').wednesday">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <h4>Wednesday</h4>
                      <h4>Start time</h4>
                      <h4>End time</h4>
                    </div>
                    <div v-for="(tele, index) in groupBy(telemedicine.chamber_schedules, 'day')" :key="index">

                      <div v-for="(t, tt) in tele" :key="tt">
                        <div v-if="t.day == 'wednesday'" class="mb-0 d-flex justify-content-between text-center">
                          <div>
                            {{ t.day }}
                          </div>
                          <div>
                            {{ t.chamber_start_time }}
                          </div>
                          <div>
                            {{ t.chamber_close_time }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="card mb-5" v-if="groupBy(telemedicine.chamber_schedules, 'day').thursday">
                  <div class="card-body">
                    <div class="d-flex justify-content-between ">
                      <h3>Thursday</h3>
                      <h3>Start time</h3>
                      <h3>End time</h3>
                    </div>
                    <div v-for="(tele, index) in groupBy(telemedicine.chamber_schedules, 'day')" :key="index">

                      <div v-for="(t, tt) in tele" :key="tt">
                        <div v-if="t.day == 'thursday'" class="mb-0 d-flex justify-content-between text-center">
                          <div>
                            {{ t.day }}
                          </div>
                          <div>
                            {{ t.chamber_start_time }}
                          </div>
                          <div>
                            {{ t.chamber_close_time }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="card mb-5" v-if="groupBy(telemedicine.chamber_schedules, 'day').friday">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <h3>Friday</h3>
                      <h3> Start time</h3>
                      <h3>End time</h3>
                    </div>
                    <div v-for="(tele, index) in groupBy(telemedicine.chamber_schedules, 'day')" :key="index">

                      <div v-for="(t, tt) in tele" :key="tt">
                        <div v-if="t.day == 'friday'" class="mb-0 d-flex justify-content-between text-center">
                          <div>
                            {{ t.day }}
                          </div>
                          <div>
                            {{ t.chamber_start_time }}
                          </div>
                          <div>
                            {{ t.chamber_close_time }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                <div class="card mb-5" v-if="groupBy(telemedicine.chamber_schedules, 'day').saturday">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <h4>saturday</h4>
                      <h4>Start time</h4>
                      <h4>End time</h4>
                    </div>
                    <div v-for="(tele, index) in groupBy(telemedicine.chamber_schedules, 'day')" :key="index">

                      <div v-for="(t, tt) in tele" :key="tt">
                        <div v-if="t.day == 'saturday'" class="mb-0 d-flex justify-content-between text-center">
                          <div>
                            {{ t.day }}
                          </div>
                          <div>
                            {{ t.chamber_start_time }}
                          </div>
                          <div>
                            {{ t.chamber_close_time }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>


            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {TelemedicineMixin} from "../../../../mixins/doctor/TelemedicineMixin";

export default {
  name: "Telemedicine",
  mixins: [TelemedicineMixin],
}
</script>

<style scoped>
.font-awesome-desgin {
  padding: 7px 10px;
  background: #f3f6f9;
  border-radius: 7px;
  color: #4591d1;
}

.bg-gray {
  padding: 10px;
  background: #f3f6f9;
}

.active_day {
  background-color: yellow;
}

</style>