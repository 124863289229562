<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="card-box tilebox-one">
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="text-muted text-uppercase m-b-20">Registered users (Total)</h6>
            <span class="h1 float-right text-primary">{{ total_user.total }}</span>
          </div>
          <div class="mt-5">

            <div class="d-flex text-center justify-content-between">

              <div class="box-item bg-light-primary">
                <small class=" text-uppercase m-b-20">Doctors</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_user.doctor }}</h3>
              </div>

              <div class="box-item bg-light-info">
                <small class=" text-uppercase m-b-20">Agents</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_user.agent }}</h3>
              </div>

              <div class="box-item bg-light-success">
                <small class=" text-uppercase m-b-20">Patients</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_user.patient }}</h3>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card-box tilebox-one">
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="text-muted text-uppercase m-b-20">Appointments (Total)</h6>
            <span class="h1 float-right text-success">{{ total_appointment ? total_appointment.total : '' }}</span>
          </div>
          <div class="mt-5">

            <div class="d-flex text-center justify-content-between">
              <div class="box-item bg-light-primary">
                <small class=" text-uppercase m-b-20">Book appointments</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_appointment ? total_appointment.book_appointment : '' }}</h3>
              </div>

              <div class="box-item bg-light-success">
                <small class="text-uppercase m-b-20">Quick care</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_appointment ? total_appointment.quick_care : '' }}</h3>
              </div>

              <div class="box-item bg-light-info">
                <small class="text-uppercase m-b-20">Walking patients</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_appointment ? total_appointment.walkin_appointment  : ''}}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card-box tilebox-one">
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="text-muted text-uppercase m-b-20">Prescriptions (Total)</h6>
            <span class="h1 float-right text-info">{{ total_prescription.total }}</span>
          </div>
          <div class="mt-5">

            <div class="d-flex text-center justify-content-between">

              <div class="box-item bg-light-primary">
                <small class=" text-uppercase m-b-20">Valid</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_prescription.valid }}</h3>
              </div>

              <div class="box-item bg-light-danger">
                <small class=" text-uppercase m-b-20">Invalid</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_prescription.invalid }}</h3>
              </div>

              <div class="box-item bg-light-primary">
                <small class=" text-uppercase m-b-20">Paid</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_prescription.paid }}</h3>
              </div>

              <div class="box-item bg-light-danger">
                <small class=" text-uppercase m-b-20">Unpaid</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_prescription.unpaid }}</h3>
              </div>

            </div>
          </div>
        </div>
      </div>



    </div>
    <div class="row">

      <div class="col-md-4">
        <div class="card-box tilebox-one">
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="text-muted text-uppercase m-b-20">Registered users (Today) </h6>
            <span class="h1 float-right text-primary">{{ total_today_user.total }}</span>
          </div>
          <div class="mt-5">

            <div class="d-flex text-center justify-content-between">

              <div class="box-item bg-light-primary">
                <small class=" text-uppercase m-b-20">Doctors</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_user.doctor }}</h3>
              </div>

              <div class="box-item bg-light-info">
                <small class=" text-uppercase m-b-20">Agents</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_user.agent }}</h3>
              </div>

              <div class="box-item bg-light-success">
                <small class=" text-uppercase m-b-20">Patients</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_user.patient }}</h3>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card-box tilebox-one">
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="text-muted text-uppercase m-b-20">Appointments (Today) </h6>
            <span class="h1 float-right text-success">{{ total_today_appointment.total }}</span>
          </div>
          <div class="mt-5">

            <div class="d-flex text-center justify-content-between">
              <div class="box-item bg-light-primary">
                <small class=" text-uppercase m-b-20">Book appointments</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_appointment.book_appointment }}</h3>
              </div>

              <div class="box-item bg-light-success">
                <small class="text-uppercase m-b-20">Quick care</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_appointment.quick_care }}</h3>
              </div>

              <div class="box-item bg-light-info">
                <small class="text-uppercase m-b-20">Walking patients</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_appointment.walkin_appointment }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="card-box tilebox-one">
          <div class="d-flex justify-content-between align-items-center">
            <h6 class="text-muted text-uppercase m-b-20">Prescriptions (Today) </h6>
            <span class="h1 float-right text-info">{{ total_today_prescription.total }}</span>
          </div>
          <div class="mt-5">

            <div class="d-flex text-center justify-content-between">

              <div class="box-item bg-light-primary">
                <small class=" text-uppercase m-b-20">Valid</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_prescription.valid }}</h3>
              </div>

              <div class="box-item bg-light-danger">
                <small class=" text-uppercase m-b-20">Invalid</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_prescription.invalid }}</h3>
              </div>

              <div class="box-item bg-light-primary">
                <small class=" text-uppercase m-b-20">Paid</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_prescription.paid }}</h3>
              </div>

              <div class="box-item bg-light-danger">
                <small class=" text-uppercase m-b-20">Unpaid</small>
                <h3 class="m-b-20" data-plugin="counterup">{{ total_today_prescription.unpaid }}</h3>
              </div>

            </div>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BoxTitleWizard",
  data() {
    return {
      total_appointment: {},
      total_prescription: {},
      total_user: {},
      total_today_user: {},
      total_today_prescription: {},
      total_today_appointment: {},
      active: 'home',
    }
  },
  mounted() {
    this.getSummary();
  },
  methods: {
    getSummary() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/dashboard/summary?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.total_appointment = res.data.data.total_appointment;
            this.total_prescription = res.data.data.total_prescription;
            this.total_user = res.data.data.total_user;
            this.total_today_user = res.data.data.total_today_user;
            this.total_today_prescription = res.data.data.total_today_prescription;
            this.total_today_appointment = res.data.data.total_today_appointment;
          })
    },
    makeActive: function(item) {
      this.active = item;
    },
  }
}
</script>

<style scoped>
.card-box {
  padding: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
}

.tilebox-one i {
  font-size: 30px;
}

.tilebox-two i {
  font-size: 48px;
  opacity: 0.2;
  margin-top: 20px;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.box-item {
  background: #f2f5f8;
  padding: 7px;
  border-radius: 7px;
  margin: 0px 5px;
}
.card-box {
  overflow: scroll;
}
</style>