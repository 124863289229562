import { render, staticRenderFns } from "./LocalityAnalysis.vue?vue&type=template&id=3d4dd1ae&scoped=true"
import script from "./LocalityAnalysis.vue?vue&type=script&lang=js"
export * from "./LocalityAnalysis.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d4dd1ae",
  null
  
)

export default component.exports