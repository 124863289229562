import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const InstituteMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Name": {
                    field: "name"
                },
                "Institute type": {
                    field: "institute_type"
                },
                "Address": {
                    field: "address"
                },
                "Contact number": {
                    field: "contact_number"
                },

            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'parent_id',
                    label: 'Parent',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'institute_type',
                    label: 'Institute type',
                    sortable: true
                },
                {
                    key: 'discount',
                    label: 'Discount(%)',
                    sortable: true
                },
                {
                    key: 'address',
                    label: 'Address',
                    sortable: true
                },
                {
                    key: 'contact_number',
                    label: 'Contact number',
                    sortable: true
                },
                {
                    key: 'website_url',
                    label: 'Website url',
                    sortable: true
                },
                {
                    key: 'google_map_url',
                    label: 'Google map url',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Actions'
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                name: '',
                institute_type: '',
                address: '',
                contact_number: '',
            },
            filter: null,
            filterOn: [],
            title: null,
            details: '',
            form: {
                name: '',
                parent_id: '',
                address: '',
                contact_number: '',
                institute_type_id: '',
                details: '',
                is_corporate: 0,
                facilities: [],
                city_id: '',
                town_id: '',
                discount: '',
                website_url: '',
                google_map_url: '',
            },
            loading: true,
            institute_type: [],
            row_id: '',
            is_edit: false,
            facilities: [],
            districts: [],
            thanas: [],
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Institute", route: "/institute"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getInstitute();
        this.instituteType();
        this.getFacilites();
        this.getDistrict();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Institute',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                institute_type: '',
                address: '',
                contact_number: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getDistrict() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=city&parent_id=18`)
                .then(response => {
                    this.districts = response.data.location;
                })
        },
        getThana(district_id) {
            this.form.town_id = '';
            axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=town&parent_id=${district_id}`)
                .then(response => {
                    this.thanas = response.data.location;
                })
        },
        getInstitute() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                        this.loading = false;
                    }
                })
        },
        getFacilites() {
            this.facilities = [
                {
                    title: 'Parking',
                    slug: 'Parking'
                },
                {
                    title: 'Food Corner',
                    slug: 'Food_Corner'
                },
                {
                    title: 'Tea Or Coffee Corner',
                    slug: 'Tea_Or_Coffee_Corner'
                },
                {
                    title: 'Water Dispenser',
                    slug: 'Water_Dispenser'
                },
                {
                    title: 'Washrooms',
                    slug: 'Washrooms'
                },
                {
                    title: 'Televisions',
                    slug: 'Televisions'
                },
                {
                    title: 'Newspapers',
                    slug: 'Newspapers'
                },
                {
                    title: 'ATM Booth',
                    slug: 'ATM_Booth'
                },
                {
                    title: 'Pharmacy',
                    slug: 'Pharmacy'
                },
                {
                    title: 'Waiting Rooms',
                    slug: 'Waiting_Rooms'
                },
                {
                    title: 'Internet Access',
                    slug: 'Internet_Access'
                },
                {
                    title: 'Wheel Chair Accessible',
                    slug: 'Wheel_Chair_Accessible'
                },
                {
                    title: 'Changing Room',
                    slug: 'Changing_Room'
                },
                {
                    title: 'Child Friendly',
                    slug: 'Child_Friendly'
                },
                {
                    title: 'Day Care Surgery',
                    slug: 'Day_Care_Surgery'
                },
                {
                    title: 'Examination Room',
                    slug: 'Examination_Room'
                },
                {
                    title: 'Disability Care Service',
                    slug: 'Disability_Care_Service'
                },
                {
                    title: 'Health Checkup',
                    slug: 'Health_Checkup'
                },
                {
                    title: 'Prayer Room',
                    slug: 'Prayer_Room'
                },
                {
                    title: 'Reception',
                    slug: 'Reception'
                },
                {
                    title: 'Supporting Staff',
                    slug: 'Supporting_Staff'
                },
                {
                    title: 'Drinking Water',
                    slug: 'Drinking_Water'
                },
                {
                    title: 'Wound Care',
                    slug: 'Wound_Care'
                },
                {
                    title: 'Breastfeeding',
                    slug: 'Breastfeeding'
                },
                {
                    title: 'Baby Changing Room',
                    slug: 'Baby_Changing_Room'
                },
                {
                    title: 'Air conditioning',
                    slug: 'Air_Conditioning'
                },
            ];
        },

        instituteType() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute-type/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.institute_type = response.data.data
                    }
                })
        },

        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.title = valid
            return valid
        },

        resetModal() {
            this.title = null;
            this.details = null;
            this.form = {
                name: '',
                parent_id: '',
                address: '',
                contact_number: '',
                institute_type_id: '',
                details: '',
                is_corporate: 0,
                facilities: [],
            };
            // this.facilities = [];
            this.is_edit = false;
            this.row_id = '';
        },

        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.is_edit) {
                return this.update();
            }
            this.handleSubmit()
        },

        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();
            let institute_image = this.$refs.institute_image.files[0];
            if (institute_image) {
                form.append("image", institute_image);
            }
            if (this.form.parent_id) {
                form.append("parent_id", this.form.parent_id);
            }
            form.append("name", this.form.name);
            if (this.form.address) {
                form.append("address", this.form.address);
            }
            if (this.form.contact_number) {
                form.append("contact_number", this.form.contact_number);
            }
            if (this.form.details) {
                form.append("details", this.form.details);
            }
            if (this.form.discount) {
                form.append("discount", this.form.discount);
            }
            if (this.form.institute_type_id) {
                form.append("institute_type_id", this.form.institute_type_id);
            }
            if (this.form.facilities) {
                const amanities = this.form.facilities.join(',')
                form.append("amanities", JSON.stringify(amanities.split(',')))
            }
            if (this.form.is_corporate == 1) {
                form.append("is_corporate", this.form.is_corporate);
            }
            if (this.form.city_id) {
                form.append("city_id", this.form.city_id);
            }
            if (this.form.town_id) {
                form.append("town_id", this.form.town_id);
            }

            if (this.form.website_url) {
                form.append("website_url", this.form.website_url);
            }
            if (this.form.google_map_url) {
                form.append("google_map_url", this.form.google_map_url);
            }


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/institute/create?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getInstitute();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },

        editAction(row) {
            this.getThana(row.city_id)
            this.$bvModal.show("modal-1");
            this.is_edit = true;
            this.row_id = row.id;
            this.form.name = row.name
            this.form.parent_id = row.parent_id
            this.form.address = row.address ? row.address : ''
            this.form.contact_number = row.contact_number;
            this.form.institute_type_id = row.institute_type_id
            this.form.is_corporate = row.is_corporate
            this.form.town_id = row.town_id
            this.form.city_id = row.city_id
            this.form.discount = row.discount
            this.form.website_url = row.website_url
            this.form.google_map_url = row.google_map_url
            if (row.details) {
                const facilities = JSON.parse(row.details);
                if (facilities.length > 1) {
                    this.form.facilities = JSON.parse(row.details)
                }
            }
        },

        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/speciality/delete/${row.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getSpeialitiesist();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },

        update() {
            if (!this.checkFormValidity()) {
                return
            }

            let form = new FormData();
            let institute_image = this.$refs.institute_image.files[0];

            if (this.form.parent_id) {
                form.append("parent_id", this.form.parent_id);
            }
            form.append("name", this.form.name);
            // if (this.form.address) {
            form.append("address", this.form.address);
            // }
            if (this.form.contact_number) {
                form.append("contact_number", this.form.contact_number);
            }
            if (this.form.institute_type_id) {
                form.append("institute_type_id", this.form.institute_type_id);
            }
            if (institute_image) {
                form.append("image", institute_image);
            }
            if (this.form.is_corporate == 1) {
                form.append("is_corporate", this.form.is_corporate);
            }

            if (this.form.city_id) {
                form.append("city_id", this.form.city_id);
            }
            if (this.form.discount) {
                form.append("discount", this.form.discount);
            }
            if (this.form.town_id) {
                form.append("town_id", this.form.town_id);
            }

            if (this.form.facilities) {
                const amanities = this.form.facilities.join(',')
                form.append("amanities", JSON.stringify(amanities.split(',')))
            }

            if (this.form.website_url) {
                form.append("website_url", this.form.website_url);
            }
            if (this.form.google_map_url) {
                form.append("google_map_url", this.form.google_map_url);
            }

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/institute/update/${this.row_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.$bvModal.hide('modal-1')
                        this.getInstitute();

                        this.$snotify.success('Successfully saved');
                    })
            })

        },

    }
}
