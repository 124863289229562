<template>
  <div>
    <h2>Faq Type</h2>
  </div>
</template>

<script>
export default {
  name: "FaqType"
}
</script>

<style scoped>

</style>