<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <b-button variant="primary" @click="addNewAdvice">Add new Advice</b-button>
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              :current-page="0"
              :per-page="perPage"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">

          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              @change="handlePageChange"
              size="lg"
              class="my-0"
              aria-controls="doctorList"
          ></b-pagination>
          <!--  Add edit modal -->
          <b-modal
              id="addEditModal"
              ref="modal"
              title="Add new Advice"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk"
          >
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-form-group>
                <label for="">Medicine generics<span class="text-danger">*</span></label>
                <v-select
                    :options="gennerics"
                    :reduce="gennerics => gennerics.id"
                    v-model="form.medicine_generic_id"
                    label="title"
                    required>
                </v-select>

              </b-form-group>
              <b-form-group
                  label="Enter title"
                  label-for="name-input">
                <b-form-tags input-id="tags-basic" v-model="form.title"></b-form-tags>
              </b-form-group>
            </form>
          </b-modal>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

import axios from "axios";

export default {
  name: "MedicineGenericAdvice",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Medicine Generic Advice", route: "/medicine/generic/advices"},
    ]);

  },
  data() {
    return {
      items: [],
      fields: [
        {
          key: 'title',
          label: 'Title',
          sortable: true,
        },
        {
          key: 'generic_name',
          label: 'Generic name',
          sortable: true,
        },
      ],
      currentPage: 1,
      perPage: 20,
      loading: false,
      total_count: 0,
      gennerics: [],
      form: {
        medicine_generic_id: '',
        title: [],
      }
    }
  },
  created() {
    localStorage.setItem('tabIndex', 7)
    this.$root.$emit('call-active-menu', 7);
    this.getItems();
    this.getMedicineGennerics();
  },
  methods: {
    getItems() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/medicine/generic/advices?api_token=${localStorage.getItem('api_token')}&offset=0&limit=${this.perPage}&platform=backoffice`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    handlePageChange(value) {
      this.loading = true
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/medicine/generic/advices?api_token=${localStorage.getItem('api_token')}&offset=${correctValue}&limit=${this.perPage}&platform=backoffice`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    getMedicineGennerics() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/generics/list?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.gennerics = response.data.data;
            }
          })
    },
    addNewAdvice() {
      this.$bvModal.show('addEditModal')
    },
    resetModal() {
      this.form = {
        medicine_generic_id: '',
        title: [],
      }
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      let new_arr = [];
      new_arr = this.form.title.map(item => {
        return {'title': item}
      })
      let data = {
        'medicine_generic_id': this.form.medicine_generic_id,
        "advices": new_arr
      }
      this.$nextTick(() => {
        axios.post(`${process.env.VUE_APP_URL}/api/v2/medicine/generic/advices?api_token=${localStorage.getItem('api_token')}`, data)
            .then(response => {
              if (response.data.status_code == 200) {
                this.$bvModal.hide('addEditModal');
                this.getItems();
                this.$snotify.success('Successfully saved');
              }else {
                this.$snotify.error('Failed!!');
              }
            })
      })
    }
  }
}
</script>

<style scoped>

</style>
