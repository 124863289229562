<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"> Level wise doctor count</span>
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">



        <div class="row justify-content-center">
          <div class="col color-variants" v-for="(item, index) in items" :key="index" v-if="index < 5">
            <div class="m-3  text-center p-5 bg d-flex flex-direction-row">
              <div class="font-size-16  font-weight-bold">
                {{ item.level_name }}
              </div>
              <div class="font-size-h4 font-weight-bolder mt-5">
                <b-badge variant="light">{{ item.doctors_count }}</b-badge>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col color-variants" v-for="(item, index) in items" :key="index" v-if="index >= 5">
            <div class="m-3  text-center p-5 bg d-flex flex-direction-row">
              <div class="font-size-16  font-weight-bold">
                {{ item.level_name }}
              </div>
              <div class="font-size-h4 font-weight-bolder mt-5">
                <b-badge variant="light">{{ item.doctors_count }}</b-badge>
              </div>
            </div>
          </div>
        </div>

        <!--        <div class="row">-->
        <!--          <div class="col" v-for="(item, index) in items" :key="index">-->
        <!--            <div class="box-item bg-light-primary">-->
        <!--              <b class="text-uppercase m-b-20">{{ item.level_name }}</b>-->
        <!--              <h1 class="m-b-20" data-plugin="counterup"> {{ item.doctors_count }} </h1>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "LevelWiseDoctorCount",
  data() {
    return {
      items: [],
    }
  },
  created() {
    this.getLevelWiseDoctorCount();
  },
  methods: {
    getLevelWiseDoctorCount() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/level/level/wise/doctor/list?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.items = res.data.data;
          })
    },
  }
}
</script>

<style scoped>
.card-box {
  padding: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
}

.tilebox-one i {
  font-size: 30px;
}

.tilebox-two i {
  font-size: 48px;
  opacity: 0.2;
  margin-top: 20px;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.box-item {
  background: #f2f5f8;
  padding: 7px;
  border-radius: 7px;
  margin: 0px 5px;
}
.flex-direction-row {
  flex-direction: column;
}

.color-variants:nth-child(even) {
  background-color: #E1F0FF !important;
  border: 3px solid #ffffff;

}
.color-variants:nth-child(odd) {
  background-color: #FFE2E5 !important;
  border: 3px solid #ffffff;
}
</style>