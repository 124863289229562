<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div>
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <h3 class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3">
                  Membership
                </h3>
              </div>
              <div class="my-lg-0 my-3">
              </div>
            </div>
            <div class="row mt-10" v-if="memberships.length">
              <div class="col-md-6 mb-5" v-for="(membership, index) in memberships" :key="index">
                <b-card class="">
                  <b-card-text>
                    <h3 class="mb-0">{{ membership.institute_name }} </h3>
                    <p class="text-muted">Member science : {{ membership.start_date }} </p>
                  </b-card-text>
                  <p class="mb-1"><b>Membership type : </b> {{ membership.membership_type_name }}</p>
                  <p class="mb-1"><b>Membership ID : </b> {{ membership.membership_id }}</p>
                  <p class="mb-1"><b>Nature of service</b> : {{ membership.service_name ? membership.service_name : 'n/a' }}</p>
                </b-card>
              </div>
            </div>
            <div v-else> Not set yet</div>

            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Membership",
  props: {
    memberships: {}
  }
}
</script>

<style scoped>

</style>