import axios from "axios";
import moment from "moment";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const FinancialStatementReportMixin = {

    data() {
        return {
            json_fields: {
                "Fullname": {
                    field: "user_fullname",
                },
                "Phone number": {
                    field: "user_phone",
                },
                "User unique id": {
                    field: "user_unique_id",
                },
                "Transaction date": {
                    field: "transaction_date",
                },
                "Total balance": {
                    field: "total_balance"
                },
                "description": {
                    field: "description"
                },
                "Debit" : {
                    field: "amount_dr"
                },
                "Credit":  {
                    field: "amount_cr"
                }

            },
            paid_amount: '',
            list: null,
            listLoading: true,
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            currentPage: 1,
            perPage: 20,
            pageOptions: [30, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                index: '',
                user_fullname: '',
                user_phone: '',
                user_unique_id: '',
                transaction_date: '',
                total_balance: '',
                description: '',
                amount_dr: '',
                amount_cr: '',
            },
            items: [],
            loading: true,
            name: '',
            nameState: null,
            submittedNames: [],
            groups: [
                {
                    id: 1,
                    name: "Users"
                }
            ],
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'transaction_date',
                    label: 'Transaction date',
                    sortable: true
                },
                {
                    key: 'user_fullname',
                    label: 'User',
                    sortable: true
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'amount_dr',
                    label: 'Debit',
                    sortable: true
                },
                {
                    key: 'amount_cr',
                    label: 'Credit',
                    sortable: true
                },
                {
                    key: 'total_balance',
                    label: 'Total balance',
                    sortable: true
                },
                {
                    key: 'action',
                    label: 'Action',
                }
            ],
            filter: null,
            filterOn: [],
            startDate: null,
            endDate: null,
            selectedType: '',
            trxn_amount_sum: '',
            total_item: 0,
            dateRanchFilter: {
                started_date: '',
                end_date: '',
            },
            payment_details: null,
        }
    },
    created() {
        localStorage.setItem('tabIndex', 4)
        this.$root.$emit('call-active-menu', 4);
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Financial statement", route: "financial-statement"},
        ]);

        this.getFinantialReport();
    },

    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.transaction_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }

                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                index: '',
                user_fullname: '',
                user_phone: '',
                user_unique_id: '',
                transaction_date: '',
                total_balance: '',
                description: '',
                amount_dr: '',
                amount_cr: '',
            }]
        },

        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        }
    },

    methods: {

        getFinantialReport() {
            //
            if (this.startDate == null && this.endDate == null) {
                this.startDate = moment().startOf('month').format('YYYY-MM-DD');
                this.endDate = moment().endOf('month').format('YYYY-MM-DD');
            }

            if (this.startDate) {
                this.dateRanchFilter.started_date = '&start_date=' + this.startDate;
            } else {
                this.dateRanchFilter.started_date = '';
            }

            if (this.endDate) {
                this.dateRanchFilter.end_date = '&end_date=' + this.endDate;
            } else {
                this.dateRanchFilter.end_date = '';
            }

            axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/financial/statement?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=0${this.dateRanchFilter.started_date}${this.dateRanchFilter.end_date}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false
                        this.items = response.data.data;

                        this.total_item = response.data.total_count;
                        this.paid_amount = response.data.data.paid_amount_sum;
                        this.trxn_amount_sum = response.data.data.trxn_amount_sum;
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true;
            if (this.startDate == null && this.endDate == null) {
                this.startDate = moment().startOf('month').format('YYYY-MM-DD');
                this.endDate = moment().endOf('month').format('YYYY-MM-DD');
            }

            if (this.startDate) {
                this.dateRanchFilter.started_date = '&start_date=' + this.startDate;
            } else {
                this.dateRanchFilter.started_date = '';
            }

            if (this.endDate) {
                this.dateRanchFilter.end_date = '&end_date=' + this.endDate;
            } else {
                this.dateRanchFilter.end_date = '';
            }
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/financial/statement?api_token=${localStorage.getItem('api_token')}&limit=${this.perPage}&offset=${correctValue}${this.dateRanchFilter.started_date}${this.dateRanchFilter.end_date}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.loading = false

                        this.items = response.data.data;

                        this.total_item = response.data.total_count;
                        this.paid_amount = response.data.data.paid_amount_sum;
                        this.trxn_amount_sum = response.data.data.trxn_amount_sum;
                    }
                })
        },
        sendInfo(item){

            this.$bvModal.show('payment_details');
            this.payment_details = item.payment_details;
        },
        resetModal(){
          this.payment_details = null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },

        clearDate() {
            this.startDate = null;
            this.endDate = null;
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return process.env.BASE_URL + 'media/users/blank.png';
            }
        },
    },

}