<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <div class="mx-2">
                <button
                    @click="exportSalesLog()"
                    class="btn btn-primary">
                  <i class="fas fa-file-excel"></i> Export Excel
                </button>
              </div>
              <div>
                <b-button @click="dateRangeClear" variant="primary">Clear</b-button>
              </div>
            </div>
            <div>
              <div class="row justify-content-between">
                <div class="col-md-1 mt-9">
                  <div class="">
                    <b-form-group
                        class="mb-0">
                      <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-md-3 ">
                  <label for="">From</label>
                  <input type="date" v-model="start_date" class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="">To</label>
                  <input type="date" v-model="end_date" class="form-control">
                </div>

                <div class="col-md-4">
                  <label for="">Filter user wise</label>
                  <b-form-group>
                    <v-select
                        :options="sales_users"
                        :reduce="sales_users => sales_users.id"
                        v-model="sales_user_id"
                        label="name">
                    </v-select>
                  </b-form-group>
                </div>

                <div class="col-md-1 mt-8">
                  <button type="button" class="btn btn-info" @click="salesTeamLog()">Filter</button>
                </div>
              </div>
              <!-- Table -->
              <div class="row">
                <div class="col-12">
                  <b-table
                      striped hover responsive
                      :items="items.length ? filtered : items"
                      :fields="fields"
                      :current-page="currentPage"
                      :per-page="perPage"
                      :filter="filter"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      @filtered="onFiltered"
                      :filter-included-fields="filterOn"
                      thead-class="bg-primary text-white"
                      class="mt-5"
                      show-empty>


                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <p v-if="field.key == 'actions' || field.key == 'image' || field.key=='otp' || field.key=='bulk_trending'"></p>
                        <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                      </td>
                    </template>

                    <template #cell(user_name)="row">
                      <p class="mb-0">{{ row.item.user_name }}</p>
                      <p class="mb-0 text-muted">{{ row.item.userid }}</p>
                    </template>

                  </b-table>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="items.length"
                      :per-page="perPage"
                      size="lg"
                      class="my-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {SalesTeamLogMixin} from "../../mixins/SalesTeamLogMixin";
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";
import {ExportMixin} from "../../mixins/core/ExportMixin";

export default {
  name: "SalesTeamLog",
  mixins: [SalesTeamLogMixin, SalesUserMixins, ExportMixin]
}
</script>