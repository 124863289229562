import { render, staticRenderFns } from "./SalesDailyReports.vue?vue&type=template&id=b951946c&scoped=true"
import script from "./SalesDailyReports.vue?vue&type=script&lang=js"
export * from "./SalesDailyReports.vue?vue&type=script&lang=js"
import style0 from "./SalesDailyReports.vue?vue&type=style&index=0&id=b951946c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b951946c",
  null
  
)

export default component.exports