<template>
  <div>
    <loader-component v-if="loading"/>
    <div class="card" v-else>
      <div class="card-body">
        <h2>Medical advice</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new advice
            </b-button>
            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a new advice"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group>
                  <label for="">Parient advice</label>
                  <v-select
                      :options="items"
                      :reduce="items => items.id"
                      v-model="form.parent_id"
                      label="title">
                  </v-select>
                </b-form-group>

                <div>
                  <b-form-group label="Select advice type" v-slot="{ ariaDescribedby }">
                      <b-form-radio-group
                        id="radio-group-2"
                        v-model="form.image_type"
                        :aria-describedby="ariaDescribedby"
                        name="radio-sub-component"
                        @change="chooseOption"
                      >
                        <b-form-radio value="text_with_image" >Text with image</b-form-radio>
                        <b-form-radio value="advice_image" >Only Image</b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                </div>

                <div v-if="!is_image">
                  <label for="title-input">Advice text</label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.title"
                     
                      rows="3"
                      max-rows="6"
                      />
                      <b-form-group class="mt-5">
                        <label for="">Upload advice image</label>
                        <b-form-file
                            class="mt-1"
                            ref="text_with_image"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </b-form-group>
                </div>
                <div v-else>
                  <b-form-group class="mt-5">
                        <label for="">Upload advice image</label>
                        <b-form-file
                            class="mt-1"
                            ref="advice_image"
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                        ></b-form-file>
                      </b-form-group>
                </div>

              </form>
            </b-modal>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Medical advice"
                  name="Medical advice">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">

            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class=" mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <p v-if="field.key == 'actions'"></p>
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control" v-else>
                </td>
              </template>


              <template #cell(image)="data">
                <div>
                  <div>
                   
                    <b-img :src="userPhoto(data.item.image)" class="user_image"
                           v-if="data.item.image"></b-img>
                  </div>
                </div>
              </template>


              <template #cell(title)="data">
                <div v-if="data.item.type == 'image'">
                 
                  <b-img  :src="userPhoto(data.item.title)" class="user_image"
                            ></b-img>
                </div>
                <div v-else>
                    {{ data.item.title }}
                </div> 
              </template>

              <template #cell(actions)="row">

                <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                            class="m-2">

                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>

                  <div>
                    <b-dropdown-item href="#" @click="editAction(row)">
                      <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                    </b-dropdown-item>
                    <b-dropdown-item href="#" @click="deleteAction(row)">Hide Record</b-dropdown-item>
                  </div>
                </b-dropdown>
              </template>

            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>


          </div>

        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {AdviceMixin} from "../../mixins/miscellaneous/AdviceMixin";
import {ResponseMixin} from "../../mixins/core/ResponseMixin";

export default {
  name: "Advice",
  mixins: [AdviceMixin, ResponseMixin],

}
</script>
<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>