<template>
  <div>
    <div class="card">
      <div class="card-body">
        <button type="button" class="btn btn-info" @click="addNewCall">Add New Call</button>
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="columns"
                  worksheet="Call log"
                  name="Call log">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
            responsive
            striped hover
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :filter="filter"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            thead-class="bg-primary text-white"
            class="mt-5"
            show-empty>

          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>

          <template #cell(user)="row">
            {{ row.item.fullname }}
          </template>

          <template #cell(call_status)="row">
            {{ row.item.call_action_status }}
          </template>

          <template #cell(call_action_date)="row">
            {{ row.item.call_action_date }}
          </template>


          <template #cell(call_response)="row">
            <b-badge variant="success" v-if="row.item.call_response == true">Yes</b-badge>
            <b-badge variant="danger" v-else>No</b-badge>
          </template>

          <template #cell(reminder_date)="row">
            {{ row.item.reminder_date ? row.item.reminder_date : 'N/A' }}
          </template>

        </b-table>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <!--    Call history -->
    <b-modal id="modal-call" size="xl" title="Add call log" hide-footer>
      <b-form @submit.prevent="saveCallHisory" @reset="onReset" v-if="show">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Response *</label>
              <select class="form-control" v-model="call_history.call_response">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="call_type">Call Type *</label>
              <select name="" id="call_type" class="form-control" @change="getCategory(call_history.call_type)"
                      v-model="call_history.call_type">
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
                <option value="social_media">Social Media</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Category *</label>
              <v-select
                  :options="call_category"
                  :reduce="call_category => call_category.id"
                  v-model="call_history.call_category"
                  class="h-100"
                  label="title"
                  name="title">
              </v-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Remarks: *</label>
              <b-form-textarea
                  id="textarea"
                  v-model="call_history.remarks"
                  placeholder="Remarks:*"
                  rows="1"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox
                id="checkbox-1"
                v-model="call_history.is_reminder"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
            >
              Want a reminder
            </b-form-checkbox>

            <div v-if="call_history.is_reminder ==1">
              <b-form-datepicker id="example-datepicker" v-model="call_history.reminder_date"
                                 class="mb-2"></b-form-datepicker>
            </div>

          </div>
        </div>

        <div class="row mt-10">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <vue-snotify></vue-snotify>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import axios from "axios";
import {DateFormat} from "../../mixins/core/DateFormat";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "SocialNetworkCallLog",
  mixins: [DateFormat],
  data() {
    return {
      filter: null,
      filterOn: [],
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, {value: 100, text: "All"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        {
          label: 'Sl',
          key: 'index',
          sortable: true,
        },
        {
          label: 'Orko user',
          key: 'fullname',
          sortable: true,
        },
        {
          label: 'User type',
          key: 'user_type',
          sortable: true,
        },
        {
          label: 'Call category',
          key: 'call_category',
          sortable: true,
        },
        {
          label: 'Call Type',
          key: 'call_type',
          sortable: true,
        },
        {
          label: 'Call Status',
          key: 'call_status',
          sortable: true,
        },
        {
          label: 'Call Response',
          key: 'call_response',
          sortable: true,
        },
        {
          label: 'Reminder date',
          key: 'reminder_date',
          sortable: true,
        },
        {
          label: 'Call action date',
          key: 'call_action_date',
          sortable: true
        },
        {
          label: 'Remarks',
          key: 'remarks',
          sortable: true
        },
        {
          label: 'Created date',
          key: 'created_at',
          sortable: true
        }

      ],
      columns: {
        "Full name": {
          field: 'fullname'
        },
        "User type": {
          field: 'user_type'
        },
        "Call category": {
          field: 'call_category'
        },
        "Call type": {
          field: 'call_action_status'
        },
        "Call response": {
          field: 'call_response'
        },
        "Reminder date": {
          field: 'reminder_date'
        },
        "Call action date": {
          field: 'call_action_date'
        },

        "Remarks": {
          field: 'remarks'
        },
        "Created date": {
          field: 'created_at'
        },

      },
      items: [],
      callHistories: [],
      call_history: {
        call_response: '',
        call_type: '',
        remarks: '',
        call_category: '',
        reminder_date: '',
        is_reminder: '',
        id: '',
      },
      show: true,
      call_log: {},
      call_category: [],
      loading: false,
    }
  },
  created() {
    localStorage.setItem('tabIndex', 9)
    this.$root.$emit('call-active-menu', 9);
    this.getCallLog();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Social media call list", route: "crm/social/call/log"},
    ]);
  },
  computed: {
    rows() {
      return this.users.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
  },
  methods: {
    getCallLog() {
      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs?api_token=${localStorage.getItem('api_token')}&call_type=social_media`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data
            }
            this.loading = false;
          })
    },
    getCategory(type) {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/category/list?call_type=${type}&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.call_category = response.data.data
          });
    },
    saveCallHisory() {
      let form = new FormData();
      form.append('user_id', localStorage.getItem('user_id'));
      form.append('call_category_id', this.call_history.call_category);
      form.append('call_action_status_id', 1);
      form.append('call_type', this.call_history.call_type);
      form.append('call_response', this.call_history.call_response);
      if (this.call_history.is_reminder == 1) {
        form.append('has_reminder', this.call_history.is_reminder);
        form.append('reminder_date', this.call_history.reminder_date);
      }
      form.append('remarks', this.call_history.remarks);
      form.append('agent_username', '');

      axios.post(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs/store?api_token=${localStorage.getItem('api_token')}`, form)
          // eslint-disable-next-line no-unused-vars
          .then(response => {
            if (response.data.status_code == 400) {
              return this.$snotify.error(response.data.message);
            } else {
              this.$bvModal.hide('modal-call')
              this.call_history = [];
              this.getCallLog();
              this.$snotify.success('Call log added Successfully.');
            }
          })
    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
      this.form = {};
      this.show = false
      this.call_history = {};
      this.call_log = {},
          this.$nextTick(() => {
            this.show = true
          });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    addNewCall() {
      this.$bvModal.show('modal-call')
    }
  }
}
</script>

<style scoped>

</style>
