<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div>
        <!--begin::Details-->
        <div class="d-flex">
          <!--begin::Info-->
          <div class="flex-grow-1">

            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <h3 class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3">
                  Award scholarship
                </h3>
              </div>
              <div class="my-lg-0 my-3">
                <!--                        <a-->
                <!--                            href="#"-->
                <!--                            class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"-->
                <!--                        >Add</a-->
                <!--                        >-->
              </div>
            </div>
            <!--end::Title-->

            <div class="row mt-10" v-if="awards.length">
              <div class="col-md-6 mb-5" v-for="(award, index) in awards" :key="index">
                <b-card class="">
                  <b-card-text>
                    <h3> {{ award.title ? award.title : 'Not yet set' }}({{ award.achievement_date }})</h3>

                    <p class="mb-2">Institute: <b>{{ award.institute ? award.institute : 'Not yet set' }} </b></p>
                    <p class="mb-2">Department: <b>{{
                        award.department_name ? award.department_name : 'Not yet set'
                      }} </b></p>
                    <p class="mb-2">Details: <b>{{ award.details ? award.details : 'Not yet set' }} </b></p>
                  </b-card-text>
                </b-card>
              </div>
            </div>
            <div v-else> Not set yet</div>

          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
      </div>

    </div>
    <!--    <div v-else>-->
    <!--      <b-spinner variant="primary" label="Spinning"></b-spinner>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "Award",
  props: {
    awards: {}
  }
}
</script>

<style scoped>

</style>