<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"
        >Top scorer (10)</span>
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0 pb-3">


        <div class="tab-content">
          <!--begin::Table-->
          <div class="table-responsive">
            <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
              <tr class="text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Doctor</span>
                </th>
                <th style="min-width: 120px">Nature of service</th>
                <th style="min-width: 100px">Scores</th>
                <th style="min-width: 100px">Level</th>
              </tr>
              </thead>
              <tbody v-if="items">
              <template v-for="(item, i) in items">
                <tr v-bind:key="i">
                  <td class="pl-0 py-4">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="">
                          <img
                              v-if="item.image"
                              :src="userPhoto(item.image)"
                              class="user_image align-self-end"
                              alt=""
                          />
                          <img src="../../../assets/img/blank.png" class="user_image" v-else>
                        </span>
                      </div>
                      <div>
                        <router-link :to="`/user/details/${item.user_id}`"
                                     class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {{ item.fullname }}
                        </router-link>
                        <div>
                          <span>{{ item.phone_number }}</span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{
                        item.nature_of_service ? item.nature_of_service.title : '-'
                      }}</span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{
                        item.score ? item.score : '0'
                      }}</span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{
                        item.level ? item.level : '0'
                      }}</span>
                  </td>
                </tr>
              </template>
              </tbody>
              <tbody v-else>
              <tr class="text-center">
                <td colspan="7">No data found</td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "TopScorer",
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.getTopScorer();
  },
  methods: {
    getTopScorer() {
      axios.get(`${process.env.VUE_APP_URL}/api/v2/user/top/scorer?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.items = res.data.data;
          })
    },
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
    },
  }
}
</script>

<style scoped>
.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>