<template>
<div>
  <doctor-appointment-search/>
</div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
  name: "Appointment",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Book appointment", route: "/book-appointment"},
    ]);
  },
  title: 'Book Appointment',

}
</script>

<style scoped>

</style>