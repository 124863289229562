<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="mb-0">Otp List</h3>
          <b-button @click="getOtpList" variant="info"><i class="fas fa-redo-alt"></i></b-button>
        </div>
        <div>
          <br>
          <b-input-group size="sm">
            <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
          <div>
            <b-table
                striped hover
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                class="mt-5"
                thead-class="bg-primary text-white"
                show-empty
                @filtered="onFiltered">

              <template #cell(expires_at)="row">
                <span> {{ row.item.expires_at | moment("ddd, MMM Do YYYY") }}</span>
              </template>

              <template #cell(created_at)="row">
                <span> {{ row.item.created_at | moment("dddd, MMMM Do YYYY") }}</span>
              </template>

            </b-table>
          </div>
          <!--          <div class="d-flex justify-content-center" v-else>-->
          <!--           <h3 class="text-center mt-10 mb-10 bg-light" >Not found</h3>-->
          <!--          </div>-->

          <b-pagination
              v-model="currentPage"
              v-if="items"
              :total-rows="items.length"
              :per-page="perPage"
              size="lg"
              class="my-0"
          ></b-pagination>


        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {OtpMixin} from "../../mixins/OtpMixin";

export default {
  name: "OtpListt",
  mixins: [OtpMixin],
  title: 'OTP list',
}
</script>

<style scoped>
.bg-light {
  background-color: #DDDDDD;
  display: inline-block;
  text-align: center;
  padding: 10px;
  border-radius: 59px;
  font-size: 16px;
}
</style>