<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h3>Custom Notification List</h3>
        <div>
          <br />
          <div class="row mt-5">
            <div class="col-md-3 ">
              <label for="">From</label>
              <input
                type="date"
                v-model="filter.start_date"
                class="form-control"
              />
            </div>
            <div class="col-md-3">
              <label for="">To</label>
              <input
                type="date"
                v-model="filter.end_date"
                class="form-control"
              />
            </div>

            <div class="col-md-2 mt-7">
              <button
                class="btn btn-success"
                @click="getItems()"
                v-if="filter.start_date && filter.end_date"
              >
                Filter
              </button>
              <button class="btn btn-success" disabled v-else>Filter</button>
              <button class="btn btn-danger mx-2 my-2" @click="clear">
                Clear
              </button>
            </div>
          </div>

          <b-table
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-5"
          >
            <template #cell(user_type)="row">
                {{ row.item.user_type }}s
            </template>
            <template #cell(notification_text)="row">
              <div v-if="row.item.notification_text.includes('http')">
                <a :href="row.item.notification_title" target="_blank">
                  <img :src="row.item.notification_text" :alt="row.item.notification_title" style="max-width:90px" loading="lazy">
                </a>  
              </div>
              <span v-else style="word-break:break-all">{{ row.item.notification_text }}</span>
            </template>
            <template #cell(notification_title)="row">
                {{ row.item.notification_text.includes('http')?'':row.item.notification_title }}
            </template>
          </b-table>
        </div>

        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="total_count"
            :per-page="perPage"
            size="lg"
            @change="getItems"
            class="my-0"
            aria-controls="doctorList"
          ></b-pagination>
        </div>
      </div>
    </div>
    <loader-component v-if="loading" />
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "CustomNotificationList",
  title: "Custom Notification list",
  data() {
    return {
      items: [],
      fields: [
        {
          key: "notification_text",
          label: "Notification Text",
          thStyle: { width: "50%" },
        },
        {
          key: "notification_title",
          label: "Title",
          thStyle: { width: "20%"},
        },
        {
          key: "user_type",
          label: "Receiver",
          thStyle: { width: "15%" },
        },
        {
          key: "created_at",
          label: "Notification Time",
          thStyle: { width: "15%" },
        },
      ],
      loading: false,
      total_count: 0,
      currentPage: 1,
      perPage: 20,
      filter: {
        start_date: null,
        end_date: null,
      },
    };
  },
  methods: {
    getItems(value) {
      this.loading = true;
      if (value) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v1/notification/custom/list?api_token=${localStorage.getItem(
            "api_token"
          )}${
            this.filter.start_date ? "&start_date=" + this.filter.start_date : ""
          }${this.filter.end_date ? "&end_date=" + this.filter.end_date : ""}
          &limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.total_count = res.data.total_count;
          this.loading = false;
        });
    },
    clear() {
      this.filter.start_date = null;
      this.filter.end_date = null;
      this.getItems();
    },
   
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Custom Notification list", route: "custom-notification-list" },
    ]);
    this.getItems();
  },
  created() {
    localStorage.setItem("tabIndex", 5);
    this.$root.$emit("call-active-menu", 6);
  },
};
</script>

<style scoped>
.my-class {
  width: 400px !important;
}

.test {
  color: darkred;
}

.media-aside {
  width: 100px;
}

.media {
  display: flex;
  align-items: flex-start;
  align-items: center;
}
</style>
