<template>
  <div>
    <loader-component v-if="loading"/>
    <div v-else>
      <div class="row justify-content-end">
        <div class="col-md-2 text-center">
          <div class="mx-2">
            <download-excel
                :data="all_data"
                class="btn btn-primary"
                :fields="json_fields"
                worksheet="Area wise doctor"
                :name="`${checkFileNameFormat(start_date, end_date, '_area_wise_doctor')}`">
              <i class="fas fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
        </div>
        <div class="col-md-1">
          <button class="btn btn-danger" @click="refresh">Refresh</button>
        </div>
      </div>
      <div class="row  justify-content-between mb-5">
        <div class="col-md-2">
          <div>

            <label for="">Select filter</label>
            <select name="" v-model="filter_data" class="form-control" id="" @change="getAreaWiseDoctor()">
              <option value="weekly">Weekly</option>
              <option value="today">Today</option>
              <option value="monthly">Monthly</option>
              <option value="yearly">Yearly</option>
              <option value="all">All</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <label for="">From</label>
          <input type="date" v-model="start_date" class="form-control">
        </div>
        <div class="col-md-3">
          <label for="">To</label>
          <input type="date" v-model="end_date" class="form-control">
        </div>
        <div class="col-md-1 mt-8">
          <button @click="getAreaWiseDoctor" class="btn btn-info" type="button">Filter</button>
        </div>
        <div class="col-md-3 mt-9">

          <b-form-group
              label-for="filter-input"
              label-cols-sm="1"
              label-align-sm="right"
              label-size="lg"
              class="mb-0">
            <b-input-group size="sm">
              <b-form-input
                  id="filter-input"
                  v-model="search_dada"
                  type="search"
                  placeholder="Type to Search"/>

              <b-input-group-append>
                <b-button variant="primary">Clear</b-button>
              </b-input-group-append>

            </b-input-group>

          </b-form-group>

        </div>
      </div>
      <p>Showing data {{ filter_data }}</p>
      <div class="row" v-if="items" v-for="(towns, index) in filteredList" :key="index">
        <div class="col-md-12">
          <h3 class="mb-3">{{ index }}</h3>
        </div>
        <div class="col-md-2" v-for="town in towns" :key="town.id">
          <div class="card-box tilebox-one">
            <div>
              <router-link class="d-block"
                           :to="`/city-wise-doctor/${town.town_id}?${filter_data == 'yearly' ? 'start_date='+yearly_first_day : '' || filter_data == 'monthly' ? 'start_date='+monthlay_first_day : '' || filter_data == 'weekly' ? 'start_date='+weekly_first_day : '' || filter_data == 'today' ? 'start_date='+today : '' }${start_date ? '&start_date='+start_date : ''}`">
                <div>
                  <div class="">
                    <h6 class="text-uppercase  text-color font-size-12 font-weight-bold">{{ town.town_name }}</h6>
                  </div>
                  <div>
                  <span class="d-block mb-0">
                    <span class="mr-4 mb-1">Doctors: </span>
                    <span class="font-weight-bold float-right">{{ town.total_user }}</span>
                  </span>

                    <span class="d-block">
                    <span class="mr-4">Prescription: </span>
                    <span class="font-weight-bold float-right">{{ town.no_of_prescription }}</span>
                  </span>
                  </div>

                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-md-12">
          <div class="card border-0">
            <div class="card-body">
              <p class="mb-0">{{ this.filter_data }} no data found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <DoctorAreaWisePicChart :data="area_wise_doctor" :options="{responsive: true, maintainAspectRatio: true}" />-->
  </div>
</template>

<script>
// import DoctorAreaWisePicChart from "../chart/DoctorAreaWisePicChart";
import axios from "axios";

import {dateFormate} from "../../healper/dateTimeFormet";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {DateFormat} from "../../mixins/core/DateFormat";

export default {
  name: "AreaWiseDoctor",
  components: {
    // DoctorAreaWisePicChart
  },
  mixins: [DateFormat],
  data() {
    return {
      area_wise_doctor: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: []
          }
        ]
      },
      pie_prescription_loading: false,
      items: {},
      start_date: null,
      end_date: null,
      today: dateFormate().today,
      weekly_first_day: dateFormate().weekly_first_day,
      weekly_last_day: dateFormate().weekly_last_day,
      monthlay_first_day: dateFormate().monthlay_first_day,
      yearly_first_day: dateFormate().yearly_first_day,
      loading: false,
      filter_data: 'weekly',
      search_dada: '',
      json_fields: {
        "City name": {
          field: "city_name",
        },
        "Area": {
          field: "town_name",
        },
        "Total doctors": {
          field: "total_user",
        },
        "Total prescription": {
          field: "no_of_prescription",
        }
      },
      all_data: [],
    }
  },

  created() {
    this.getAreaWiseDoctor();
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Area wise doctor", route: "/area-wise-doctor"},
    ]);
  },
  computed: {
    filteredList() {
      const filterData = this.all_data.filter(item => {
        return item.city_name.toLowerCase().includes(this.search_dada.toLowerCase()) || item.town_name.toLowerCase().includes(this.search_dada.toLowerCase())
      })
      return this.groupBy(filterData, 'city_name');
    }
  },
  methods: {
    refresh() {
      this.search_dada = '';
      this.start_date = null;
      this.end_date = null;
      this.filter_data = 'weekly';
      this.getAreaWiseDoctor();
    },
    getAreaWiseDoctor() {
      if (this.start_date && this.end_date) {
        this.filter_data = '';
      }
      this.loading = true;
      var base_url = `${process.env.VUE_APP_URL}/api/v1/report/doctor/statistics/area-wise?api_token=${localStorage.getItem('api_token')}`;
      // var url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
      if (this.filter_data == "weekly") {
        var url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
      } else if (this.filter_data == 'today') {
        url = `${base_url}&start_date=${this.today}&end_date=${this.today}`
      } else if (this.filter_data == 'monthly') {
        url = `${base_url}&start_date=${this.monthlay_first_day}&end_date=${this.today}`
      } else if (this.filter_data == 'yearly') {
        url = `${base_url}&start_date=${this.yearly_first_day}&end_date=${this.today}`
      } else if (this.start_date && this.end_date) {
        this.filter_data = 'test';
        url = `${base_url}&start_date=${this.start_date}&end_date=${this.end_date}`
      } else if (this.filter_data == 'all') {
        url = `${base_url}`
      } else {
        url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
      }

      axios.get(url)
          .then(res => {
            this.all_data = res.data.data;
            this.items = this.groupBy(this.all_data, 'city_name');
            this.loading = false;
          }).catch(error => {
        console.log(error)
      })
    },

    groupBy(array, key) {
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    }

    // getAreaWiseDoctor() {
    //   this.pie_prescription_loading = false;
    //   axios.get(`${process.env.VUE_APP_URL}/api/v1/report/doctor/statistics/area-wise?api_token=${localStorage.getItem('api_token')}`)
    //       .then(res => {
    //
    //         if (res.data.status_code == 400) {
    //           this.area_wise_doctor.datasets[0].data = [];
    //         } else {
    //
    //           var appoitment_result = [];
    //           var appointment_type = [];
    //           res.data.data.map(item => {
    //             appoitment_result.push(item.total_count);
    //             appointment_type.push(item.city_name);
    //           })
    //           this.area_wise_doctor.datasets[0].data = [];
    //           this.area_wise_doctor.datasets[0].data = appoitment_result;
    //           this.area_wise_doctor.labels = [];
    //           this.area_wise_doctor.labels = appointment_type;
    //           this.pie_prescription_loading = true;
    //         }
    //       })
    // }
  },
  watch: {
    "start_date": function (val) {
      if (val) {
        this.filter_data = ''
      }
    }
  }

}
</script>

<style scoped>
.card-box {
  padding: 15px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 20px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
  box-shadow: 0 0 24px 0 rgb(0 0 0 / 6%), 0 1px 0 0 rgb(0 0 0 / 2%);
}

.tilebox-one i {
  font-size: 30px;
}

.tilebox-two i {
  font-size: 48px;
  opacity: 0.2;
  margin-top: 20px;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.box-item {
  background: #f2f5f8;
  padding: 7px;
  border-radius: 7px;
  margin: 0px 5px;
}


.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.text-color {
  color: #768192;
}

.font-weight-bold {
  font-weight: bold !important;
}
</style>