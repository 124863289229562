import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const InstituteTypeMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Name": {
                    field: "name",
                },
                "Slug": {
                    field: "slug",
                },
                "details": {
                    field: "details",
                }
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Name',
                    key: 'name',
                },
                {
                    label: 'Slug',
                    key: 'slug'
                },
                {
                    label: 'Details',
                    key: 'details'
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                name: '',
                details: '',
            },
            filter: null,
            filterOn: [],
            nameState: null,
            detailsState: null,
            form: {
                name: '',
                details: '',
            }
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Institute type", route: "/bank"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.getBankList();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Institute type',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                details: ''
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getBankList() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute-type/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            this.detailsState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.nameState = null;
            this.detailsState = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names
            let form = new FormData();
            form.append("name", this.form.name);
            form.append("details", this.form.details);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/institute-type/create?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getBankList();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
    }
}