import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const CityWiseDoctorListMixin = {
    data() {
        let route_name = this.$route.name;
        return {
            downloadLoading: false,
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            breadcrumbs: route_name + 'users',
            districts: [],
            thanas: [],
            json_fields: {
                "name": {
                    field: "fullname",
                },
                "slug": {
                    field: "slug",
                },
                "Email": {
                    field: "email",
                },
                "Phone number": {
                    field: "phone_number",
                },
                "Registration date": {
                    field: "registration_date",
                },
                "Is confirmed ? ": {
                    field: 'confirmation',
                    callback: (value) => {
                        return value == true ? 'valid' : 'invalid';
                    },
                },

            },

            loading: true,
            currentPage: 0,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            localActive: false,
            nameState: null,
            emailState: null,
            users: [],
            items: [],
            fields: [
                {
                    key: 'bulk_trending',
                    label: '',
                },
                {
                    key: 'image',
                    label: 'Image',
                    sortable: true
                },
                {
                    key: 'fullname',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    sortable: true
                },
                {
                    key: 'phone_number',
                    label: 'Phone number',
                    sortable: true
                },
                {
                    key: 'doctor_information',
                    label: 'Total prescription',
                    sortable: true
                },
                {
                    key: 'confirmation',
                    label: 'Is confirmed?',
                    sortable: true,
                },
                {
                    key: 'otp',
                    label: 'Is otp verified?',
                    sortable: true,
                },
                {
                    key: 'last_update_date',
                    label: 'Updated date',
                    sortable: true,
                },
                {
                    key: "actions"
                }
            ],
            filter: null,
            filterOn: [],
            filters: {
                id: '',
                name: '',
                username: '',
                phone_number: '',
                confirmation: '',
                otp: '',
                registration_date: '',
                slug: '',
            },
            startDate: null,
            endDate: null,
            show: true,
            form: {
                comment: '',
                module_type: '',
                password: '',
            },
            base_url: process.env.VUE_APP_API_URL,
            user_id: '',
            callHistories: [],
            call_history_filed: [
                {
                    label: 'Sl',
                    key: 'index',
                    sortable: true,
                },
                {
                    label: 'Orko user',
                    key: 'fullname',
                    sortable: true,
                },
                {
                    label: 'User type',
                    key: 'user_type',
                    sortable: true,
                },
                {
                    label: 'Call category',
                    key: 'call_category',
                    sortable: true,
                },
                {
                    label: 'Call Type',
                    key: 'call_type',
                    sortable: true,
                },
                {
                    label: 'Call Status',
                    key: 'call_status',
                    sortable: true,
                },
                {
                    label: 'Call Response',
                    key: 'call_response',
                    sortable: true,
                },
                {
                    label: 'Reminder date',
                    key: 'reminder_date',
                    sortable: true,
                },
                {
                    label: 'Call action date',
                    key: 'call_action_date',
                    sortable: true
                },
                {
                    label: 'Remarks',
                    key: 'remarks',
                    sortable: true
                },
                {
                    label: 'Created at',
                    key: 'created_at',
                    sortable: true
                }

            ],
            call_history: {
                call_response: '',
                call_type: '',
                remarks: '',
                call_category: '',
                reminder_date: '',
                is_reminder: '',
            },
                    call_category: [],
            user: {
                user_name: '',
                user_id: ''
            },
            points: [],
            total_point: '',
            point_fields: [
                {
                    label: 'Point type',
                    key: 'point_type_description',
                },
                {
                    label: 'No of occurrence',
                    key: 'no_of_occurrence',
                },
                {
                    label: 'Point earned',
                    key: 'point_earned',
                },

            ],
            user_name: '',
            user_phone_number: '',
            user_image: null,
            doctor_id: '',
            call_log: {},
            agent_type: 'agent_pharmacy',
            partent_sub_types: [],
            parient_id: '',
            is_trending: [],
            trending_submit_btn: false,
            selectMode: 'multi',
            selected: [],
            userFilterKey: 'all',
            trendy: false,
            qc: false,
            total_doctor: 0,
            newDoctor: {
                first_name: '',
                last_name: '',
                phone_number: '',
                gender: 'male',
                user_type: 'doctor',
                country_id: '18',
                password: '',
                sub_type: 'physio',
            },
            fullNameState: null,
            passwordState: null,
            phoneNumberState: null,
            genderState: null,
            gender: [
                { text: 'Male', value: 'male' },
                { text: 'Female', value: 'female' },
                { text: 'Other', value: 'other' },
            ],
            sub_types: [
                { value: null, text: 'Please select sub-type' },
                { value: 'physio', text: 'Physio' },
            ]
        }
    },

    // metaInfo: {
    //     title: 'Doctor list',
    //     htmlAttrs: {
    //         lang: 'en-US'
    //     }
    // },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Doctor list", route: "/doctor-users"},
        ]);
        this.getUser();
        this.userPhoto();
    },

    created() {
        localStorage.setItem('tabIndex', 4)
        this.$root.$emit('call-active-menu', 4);

    },

    methods: {
        getUser() {
            this.loading = true
            axios.get(`${process.env.VUE_APP_URL}/api/v2/user/list/city-wise?api_token=${localStorage.getItem('api_token')}&town_id=${this.$route.params.id}${this.$route.query.start_date ? '&start_date='+this.$route.query.start_date :''}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.total_doctor = response.data.total_count;

                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        handlePageChange(value) {
            this.loading = true
            var offset = value - 1;
            var correctValue = offset * this.perPage;

            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?user_type=doctor&offset=${correctValue}&limit=${this.perPage}&platform=backoffice`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },

        searchDoctor(event) {
            event.preventDefault()
            this.loading = true
            let form = new FormData();
            form.append('user_type', 'doctor');
            form.append('limit', '100');
            form.append('offset', '0');
            form.append('search_text', this.filter);
            axios.post(`${process.env.VUE_APP_URL}/api/v1/user/filter/list?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.loading = false;
                        this.items = response.data.data;
                        this.total_doctor = response.data.total_count;
                    }
                })
        },

        getPoints() {
            axios.get(`${process.env.VUE_APP_URL}/api/v2/user/point/list?api_token=${localStorage.getItem('api_token')}&doctor_id=${this.doctor_id}`)
                .then(res => {
                    this.total_point = res.data.data.total_point;
                    this.points = res.data.data.list;
                })
        },
        getDistrict() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=city&parent_id=18`)
                .then(response => {
                    this.districts = response.data.location;
                })
        },
        getThana(district_id) {
            this.agent_details.agent_thana = '';
            axios.get(`${process.env.VUE_APP_URL}/api/v1/locations?location_type=town&parent_id=${district_id}`)
                .then(response => {
                    this.thanas = response.data.location;
                })
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            // this.currentPage = 1
        },

        userDetails(id) {
            this.$router.push(`/user/details/${id}`);
        },

        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },

        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },

        sendInfo(item) {
            this.user_id = item.id
        },
        callHistory(item) {
            this.call_log = item;
            this.user.user_name = item.name;
            this.user.user_id = item.id;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs?user_id=${item.id}&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.callHistories = response.data.data;
                });
            axios.get(`${process.env.VUE_APP_URL}/api/v1/crm/call/category/list?call_type=outbound&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.call_category = response.data.data
                });
        },
        saveCallHisory() {
            let form = new FormData();
            form.append('user_id', this.user.user_id);
            form.append('call_category_id', this.call_history.call_category);
            form.append('call_action_status_id', 1);
            form.append('call_type', this.call_history.call_type);
            form.append('call_response', this.call_history.call_response);
            if (this.call_history.is_reminder == 1) {
                form.append('has_reminder', this.call_history.is_reminder);
                form.append('reminder_date', this.call_history.reminder_date);
            }
            form.append('remarks', this.call_history.remarks);
            form.append('agent_username', '');
            axios.post(`${process.env.VUE_APP_URL}/api/v1/crm/call/logs/store?api_token=${localStorage.getItem('api_token')}`, form)
                // eslint-disable-next-line no-unused-vars
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error(response.data.message);
                    } else {
                        // this.$bvModal.hide('modal-call')
                        this.call_history = [];
                        this.callHistory(this.call_log);
                        this.$snotify.success('Call log added Successfully.');
                    }
                })
        },
        resetPassword(event) {
            event.preventDefault()
            let form = new FormData();
            form.append('password', this.form.password);
            form.append('comment', this.form.comment);
            form.append('action_type', 'general');
            form.append('module_type', 'user');
            form.append('user_id', this.user_id);
            if (!this.user_id) {
                return alert("Sorry user id not found");
            }
            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/user/password/update?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error('Something worng');
                    }
                    this.resetAgentFormModal();
                    this.$bvModal.hide('modal-lg')
                    this.form = '';
                    this.$snotify.success('Password successfully reset.');
                    // this.getUser();

                })
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.food = null
            this.form.checked = []
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.form.password = '';
            this.form.comment = '';
            this.call_history = {};
            this.call_log = {},
                this.$nextTick(() => {
                    this.show = true
                });


        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return '../../../public/media/users/blank.png'
            }
        },
        dateRangeClear() {
            this.startDate = '';
            this.endDate = ''
        },
        showImage(img) {
            const h = this.$createElement
            // More complex structure
            const messageVNode = h('div', {class: ['foobar']}, [
                h('b-img', {
                    props: {
                        src: `${process.env.VUE_APP_API_URL}/${img}`,
                        thumbnail: true,
                        center: false,
                    }
                })
            ])
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                buttonSize: 'sm',
                centered: true, size: 'lg'
            })
        },
        viewPoint(item) {
            this.$bvModal.show('view-point')
            this.user_name = item.name;
            this.user_phone_number = item.phone_number;
            this.user_image = item.image;
            this.doctor_id = item.id;
            this.getPoints();
        },
        resetModal() {
            this.doctor_id = '';
            this.parient_id = '';
        },
        setTrending(item) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to set trendy",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                let form = new FormData();
                                form.append('is_trending', 1);
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v2/administration/doctor/trendy/set/${item.id}?api_token=${localStorage.getItem('api_token')}`, form)
                                    .then(response => {
                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully set trendy",
                                            "Success"
                                        );
                                        this.getUser();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        setQc(item) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to set available quick care",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                let form = new FormData();
                                form.append('available_qc', 1);
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v2/administration/doctor/qc/allowed/set/${item.id}?api_token=${localStorage.getItem('api_token')}`, form)
                                    .then(response => {
                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully set available quick care",
                                            "Success"
                                        );
                                        this.getUser();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        unsetTrending(item) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to unset trendy",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                let form = new FormData();
                                form.append('is_trending', 0);
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v2/administration/doctor/trendy/set/${item.id}?api_token=${localStorage.getItem('api_token')}`, form)
                                    .then(response => {
                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully unset trendy",
                                            "Success"
                                        );
                                        this.getUser();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        unsetQc(item) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to unset quick care",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                let form = new FormData();
                                form.append('available_qc', 0);
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v2/administration/doctor/qc/allowed/set/${item.id}?api_token=${localStorage.getItem('api_token')}`, form)
                                    .then(response => {
                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully unset quick care",
                                            "Success"
                                        );
                                        this.getUser();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },
        onRowSelected(items) {
            this.selected = items
        },
        selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },
        trendyFilter(item) {
            if (item == 'trendy') {
                this.trendy = true;
                this.qc = false;
            } else if (item == 'qc') {
                this.qc = true;
                this.trendy = false;

            } else {
                this.trendy = false
                this.qc = false
            }
        },

        addNewDoctor() {
            this.$bvModal.show('addNewDoctor')
        },
        handleNewDoctor(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.submitNewDoctor()
        },
        checkFormValidityForNewDoctor() {
            const valid = this.$refs.form.checkValidity()
            this.fullNameState = valid
            this.phoneNumberState = valid
            this.genderState = valid
            this.passwordState = valid
            return valid
        },
        submitNewDoctor() {
            if (!this.checkFormValidityForNewDoctor()) {
                return
            }

            let form = new FormData();
            let profile_image = document.getElementById('profile_image').files[0]
            form.append(`first_name`, this.newDoctor.first_name);
            form.append(`last_name`, this.newDoctor.last_name);
            form.append(`prefix`, 'Dr.');
            form.append(`phone_number`, '+88'+this.newDoctor.phone_number);
            form.append(`password`, this.newDoctor.password);
            form.append(`gender`, this.newDoctor.gender);
            form.append(`country_id`, this.newDoctor.country_id);
            form.append(`user_type`, this.newDoctor.user_type);
            form.append(`sub_type`, this.newDoctor.sub_type);
            form.append(`register_via`, 'normal');
            form.append(`platform`, 'backoffice');
            form.append(`confirmed`, '1');
            form.append(`image`, profile_image);


            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/register?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getUser();
                        this.$bvModal.hide('addNewDoctor')
                        this.$snotify.success('Doctor successfully created');
                    })
            })
        }
    },

    computed: {
        rows() {
            return this.users.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },

        filtered() {
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.registration_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }

                if (this.trendy) {
                    return item.is_trending == 1;
                }

                if (this.qc) {

                    return item.available_qc == 1
                }

                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                name: '',
                phone_number: '',
                confirmation: '',
                otp: '',
                registration_date: '',
                slug: '',
            }]
        },

        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.registration_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        },

    },

    watch: {
        'is_trending': function (val) {
            console.log(val)
            if (this.is_trending.length) {
                this.trending_submit_btn = true;
            } else {
                this.trending_submit_btn = false;
            }
        }
    }
}