<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-end">
              <div>
                <b-button @click="dateRangeClear" variant="danger">Clear</b-button>
              </div>
            </div>


            <div>
              <div class="row justify-content-between">
                <div class="col-md-1 mt-9">
                  <div class="">
                    <b-form-group
                        class="mb-0">
                      <b-form-select
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-md-3 ">
                  <label for="">From</label>
                  <input type="date" v-model="start_date" class="form-control">
                </div>
                <div class="col-md-3">
                  <label for="">To</label>
                  <input type="date" v-model="end_date" class="form-control">
                </div>
                <div class="col-md-1 mt-8">
                  <button type="button" class="btn btn-info" @click="getUser">Filter</button>
                </div>
                <div class="col-md-4 mt-9">
                  <b-form @submit="searchDoctor" @reset="onReset">
                    <b-form-group
                        label-for="filter-input"
                        label-cols-sm="1"
                        label-align-sm="right"
                        label-size="lg"
                        class="mb-0">
                      <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"/>

                        <b-input-group-append>
                          <b-button variant="primary" type="submit">search</b-button>
                        </b-input-group-append>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                        </b-input-group-append>

                      </b-input-group>

                    </b-form-group>
                  </b-form>
                </div>
              </div>
              <!-- Table -->
              <div class="row">
                <div class="col-12">
                  <b-table
                      striped hover responsive
                      :items="items.length ? filtered : items"
                      :fields="fields"
                      :current-page="currentPage"
                      :per-page="0"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :sort-direction="sortDirection"
                      @filtered="onFiltered"
                      :filter-included-fields="filterOn"
                      thead-class="bg-primary text-white"
                      class="mt-5"
                      show-empty>


                    <template #cell(prescription_identifier)="row">
                      <a :href="`${prescriptionUrl(row.item.prescription_url)}`"
                         target="_blank">{{ row.item.prescription_identifier }}</a>
                    </template>

                    <template #cell(patient_name)="row">
                      <user-card :image="row.item.patient_image" :name="row.item.patient_name"
                                 :phone_number="row.item.patient_phone_number"/>
                    </template>

                    <template #cell(doctor_name)="row">
                      <user-card :image="row.item.doctor_image" :name="row.item.doctor_name"
                                 :phone_number="row.item.doctor_phone_number"/>
                    </template>


                    <template #cell(actions)="row">
                      <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                                  class="m-2">

                        <template #button-content>
                          <i class="fas fa-ellipsis-v"></i>
                        </template>

                        <b-dropdown-item @click="userDetails(row.item)"><span class="pr-4"><i
                            class="fas fa-eye"></i></span> View details
                        </b-dropdown-item>


                        <b-dropdown-item href="#" @click="callHistory(row.item)" v-b-modal.modal-call>
                          <span class="pr-4"><i class="fas fa-phone-volume"></i></span> Call log
                        </b-dropdown-item>

                      </b-dropdown>
                    </template>

                  </b-table>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-md-12">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="total_user"
                      :per-page="perPage"
                      @change="handlePageChange"
                      size="lg"
                      class="my-0"
                  ></b-pagination>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <!--    Call history -->
    <b-modal id="modal-call" size="xl" :title="`Add call log ( ${call_log.prescription_identifier} )`" hide-footer>
      <b-form @submit.prevent="saveCallHisory" @reset="onReset" v-if="show">
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Response *</label>
              <select class="form-control" v-model="call_history.call_response">
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="call_type">Call Type *</label>
              <select name="" id="call_type" class="form-control" v-model="call_history.call_type">
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Call Category *</label>
              <v-select
                  :options="call_category"
                  :reduce="call_category => call_category.id"
                  v-model="call_history.call_category"
                  class="h-100"
                  label="title"
                  name="title">
              </v-select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="">Remarks: *</label>
              <b-form-textarea
                  id="textarea"
                  v-model="call_history.remarks"
                  placeholder="Remarks:*"
                  rows="1"
              ></b-form-textarea>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <b-form-checkbox
                id="checkbox-1"
                v-model="call_history.is_reminder"
                name="checkbox-1"
                value="1"
                unchecked-value="0"
            >
              Want a reminder
            </b-form-checkbox>

            <div v-if="call_history.is_reminder ==1">
              <b-form-datepicker id="example-datepicker" v-model="call_history.reminder_date"
                                 class="mb-2"></b-form-datepicker>
            </div>

          </div>
        </div>

        <div class="row mt-10">
          <div class="col-md-12">
            <button type="submit" class="btn btn-primary">Save</button>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <h4>Call history</h4>
            <b-table
                responsive
                striped hover
                :items="callHistories"
                :fields="call_history_filed"
                thead-class="bg-primary text-white">
              <template #cell(call_response)="row">
                <b-badge variant="success" v-if="row.item.call_response">Yes</b-badge>
                <b-badge variant="danger" v-else>No</b-badge>
              </template>
              <template #cell(reminder_date)="row">
                <div>{{ createdAtFormat(row.item.reminder_date) }}</div>
              </template>
              <template #cell(call_action_date)="row">
                <div>{{ createdAtFormat(row.item.call_action_date) }}</div>
              </template>
              <template #cell(created_at)="row">
                <div>{{ createdAtFormat(row.item.created_at) }}</div>
              </template>
            </b-table>
          </div>
        </div>

      </b-form>
    </b-modal>

    <!--    report details -->
    <b-modal id="report-details" size="xl" @hidden="resetReportDetails" hide-footer>
      <div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="text-muted mt-5">
                  <h4>Patient Info</h4>
                  <user-card :image="report_details.patient_image" :name="report_details.patient_name"
                             :phone_number="report_details.patient_phone_number"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="text-muted mt-5">
                  <h4>Doctor Info</h4>
                  <user-card :image="report_details.doctor_image" :name="report_details.doctor_name"
                             :phone_number="report_details.doctor_phone_number"/>
                </div>
              </div>
              <div class="col-md-4">
                <div class="">
                  <h5 class="text-muted">Follow Date</h5>
                  <p class="mb-0">{{ report_details.follow_up_date }}</p>
                </div>
                <div class="mt-5">
                  <h5 class="text-muted">Prescription No</h5>
                  <p class="mb-0">{{ report_details.prescription_identifier }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <h3>Medical Test information</h3>
            <div class="mt-5">
              <b-table striped bordered hover :fields="test_table_columns" :items="report_details.tests"
                       show-empty>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <vue-snotify></vue-snotify>

    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import {PendingReportPatientListMixin} from "../../mixins/PendingReportPatientListMixin";
import {DateFormat} from "../../mixins/core/DateFormat";

export default {
  name: "PendingReportPatientList",
  title: 'Pending report patient list',
  mixins: [PendingReportPatientListMixin, DateFormat]
}
</script>

<style scoped>

</style>
