<template>

  <div class="card card-custom bg-radial-gradient-primary gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title font-weight-bolder text-white">Doctor</h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Chart-->
      <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="series"
          type="bar"
      ></apexchart>
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer bg-white card-rounded flex-grow-1">
        <!--begin::Row-->
        <div>
          <p class="text-danger font-weight-boldest text-right">Note : Including unconfirmed users</p>
          <div>
            <b-nav pills align="right">
              <b-nav-item @click="makeActive('total')" active>Total</b-nav-item>
              <b-nav-item @click="makeActive('month')">Monthly</b-nav-item>
              <b-nav-item @click="makeActive('year')">Yearly</b-nav-item>
              <b-nav-item @click="makeActive('today')">Today</b-nav-item>
            </b-nav>
          </div>


          <!--          <b-spinner variant="primary" label="Spinning" v-if="loading"></b-spinner>-->

          <div class="mt-5">
            <div class="row m-0 bg-light-success">
              <div class="col px-8 py-6 mr-8">
                <div class="font-size-sm  font-weight-bold">
                  Total
                </div>
                <div class="font-size-h4 font-weight-bolder">{{ doctors_report.total }}</div>
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col px-8 py-6 mr-3 my-3 bg-light-primary">
                <div class="font-size-sm  font-weight-bold">
                  Verified
                </div>
                <div class="font-size-h4 font-weight-bolder">
                  {{ doctors_report.confirmed }}
                </div>
              </div>
              <div class="col px-8 py-6 my-3 bg-light-warning">
                <div class="font-size-sm  font-weight-bold">
                  Pending
                </div>
                <div class="font-size-h4 font-weight-bolder">{{ doctors_report.pending }}</div>
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col px-8 py-6 my-3 mr-3 bg-light-primary">
                <div class="font-size-sm  font-weight-bold">
                  Online
                </div>
                <div class="font-size-h4 font-weight-bolder">{{ doctors_report.online }}</div>
              </div>
              <div class="col px-8 py-6 my-3 bg-light-danger">
                <div class="font-size-sm  font-weight-bold">
                  Offline
                </div>
                <div class="font-size-h4 font-weight-bolder">{{ doctors_report.offline }}</div>
              </div>
            </div>
            <div class="row m-0 my-3">
              <div class="col px-8 py-6 my-3 mr-3 bg-light-warning">
                <div class="font-size-sm  font-weight-bold">
                  Away
                </div>
                <div class="font-size-h4 font-weight-bolder">{{ doctors_report.busy }}</div>
              </div>
              <div class="col px-8 py-6  my-3 bg-light-danger">
                <div class="font-size-sm  font-weight-bold">
                  Rejected doctor
                </div>
                <div class="font-size-h4 font-weight-bolder">{{ doctors_report.rejected }}</div>
              </div>
            </div>

          </div>


        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import Dropdown2 from "@/view/content/dropdown/Dropdown2.vue";
import {mapGetters} from "vuex";
import moment from 'moment';
import axios from "axios";

export default {
  name: "widget-10",
  components: {
    Dropdown2
  },
  props: ['doctors', 'series'],
  data() {
    return {
      active_menu: 'home',
      loading: false,
      chartOptions: {},
      // series: [
      //   // {
      //   //   name: "Net Profit",
      //   //   data: [35, 65, 75, 55, 45, 60, 55]
      //   // },
      //   // {
      //   //   name: "Revenue",
      //   //   data: [40, 70, 80, 60, 50, 65, 60]
      //   // }
      // ],
      start_from: null,
      end_to: null,
      doctors_report: this.doctors,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.makeActive();
    // this.getChartData();
    this.chartOptions = {
      chart: {
        type: "bar",
        height: 200,
        toolbar: {
          show: false
        },
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["30%"],
          endingShape: "rounded"
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"]
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      yaxis: {
        min: 0,
        max: 100,
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          style: {
            colors: this.layoutConfig("colors.gray.gray-500"),
            fontSize: "12px",
            fontFamily: this.layoutConfig("font-family")
          }
        }
      },
      fill: {
        opacity: 1
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0
          }
        },
        hover: {
          filter: {
            type: "none",
            value: 0
          }
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0
          }
        }
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: this.layoutConfig("font-family")
        },
        y: {
          formatter: function (val) {
            return val;
          }
        }
      },
      colors: [this.layoutConfig("colors.theme.base.success"), "#ffffff"],
      grid: {
        borderColor: this.layoutConfig("colors.gray.gray-200"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: false
          }
        }
      }
    };
  },
  methods: {
    makeActive(item) {
      if (item == 'month') {
        this.start_from = moment().startOf('month').format('YYYY-MM-DD');
        this.end_to = moment().endOf('month').format('YYYY-MM-DD');
      } else if (item == 'year') {
        this.start_from = moment().startOf('year').format('YYYY-MM-DD');
        this.end_to = moment().endOf('year').format('YYYY-MM-DD');
      } else if (item == 'today') {
        this.start_from = moment().startOf('day').format('YYYY-MM-DD');
        this.end_to = moment().add(1, 'days').endOf('day').format('YYYY-MM-DD');
      } else {
        axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/user/report?api_token=${localStorage.getItem('api_token')}`)
            .then((res) => {
              this.loading = false;
              this.doctors_report = res.data.data.user.doctor;
            })

        return true;
      }

      this.loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v2/administration/system/user/report?api_token=${localStorage.getItem('api_token')}&start_from=${this.start_from}&end_to=${this.end_to}`)
          .then((res) => {
            this.loading = false;
            this.doctors_report = res.data.data.user.doctor;
          })

      this.active_menu = item;
    },

  }
};

</script>
<style scoped>
.bg-radial-gradient-primary {
  background-color: var(--v-primary-darken4);
  background-image: radial-gradient(circle, rgba(201, 247, 245, 0.4), #002147 70%);
}
</style>