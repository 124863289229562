<template>
  <div>
    <b-modal
        id="modal-1"
        size="lg"
        title="Manage product"
        @hidden="resetModal"
        @ok="handleOkEdit">

      <form ref="form" @submit.stop.prevent="handleSubmitEdit" style="height: 50vh;">

        <div class="mb-3">
          <label>Select product</label>
          <v-select
              :options="products"
              :reduce="products => products.id"
              v-model="pro.product_id"
              label="title"
              name="product_id">
          </v-select>
        </div>
        <b-form-group>
          <label for="">Action comment <span class="text-danger">*</span></label>
          <b-form-textarea
              id="textarea"
              v-model="pro.comment"
              placeholder="Enter something..."
              rows="3" required
          ></b-form-textarea>
        </b-form-group>

      </form>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ChangeProductModal",
  props: {
    change_modal_data: {},
    // is_change_modal_open: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  data() {
    return {
      pro: {
        product_id: this.change_modal_data.product_id ? this.change_modal_data.product_id : '',
        user_id:  this.change_modal_data.id ?  this.change_modal_data.id : '',
        comment: '',
      },
      products: [],
    }
  },
  mounted() {
      this.$bvModal.show('modal-1')
  },

  created() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/product/list?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied');
            } else {
              this.products = response.data.data
            }
          })
    },
    resetModal() {
      this.title = null;
      this.details = null;
      this.pro = {};
      this.update_user_id = '';
      this.$emit('modalClosed')
    },

    handleOkEdit(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      if (this.is_edit) {
        return this.updateProduct();
      }
      this.handleSubmitEdit()
    },

    handleSubmitEdit() {


      let form = new FormData();
      form.append('action_type', 'product');
      form.append('module_type', 'product');
      form.append("product_id", this.pro.product_id);
      form.append("comment", this.pro.comment);


      // Hide the modal manually
      this.$nextTick(() => {
        axios
            .post(`${process.env.VUE_APP_URL}/api/v1/product/user/update/${this.pro.user_id}?api_token=${localStorage.getItem('api_token')}`, form)
            .then(response => {
              if (response.data.status_code == 400) {
                return this.$snotify.error(response.data.message);
              }
              this.resetModal();
              this.$bvModal.hide('modal-1')
              this.$snotify.success('Successfully updated');
            })
      })

    },
  }
}
</script>

<style scoped>

</style>
