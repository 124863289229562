import axios from "axios";
import {dateFormate} from "../healper/dateTimeFormet";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const OrganizationMixin = {
    data() {
        return {
            counter: {},
            patients: [],
            prescriptions: [],
            doctors: [],
            start_date: null,
            end_date: null,
            today: dateFormate().today,
            weekly_first_day: dateFormate().weekly_first_day,
            weekly_last_day: dateFormate().weekly_last_day,
            monthlay_first_day: dateFormate().monthlay_first_day,
            yearly_first_day: dateFormate().yearly_first_day,
            fields: [
                {
                    key: 'user_name',
                    label: 'Name'
                },
                {
                    key: 'user_age',
                    label: 'Ages'
                },
                {
                    key: 'user_gender',
                    label: 'Gender'
                },
                {
                    key: 'blood_group',
                    label: 'Blood group'
                },
                {
                    key: 'patient_type',
                    label: 'Type'
                },
            ],
            doctorFields: [
                {
                    key: 'fullname',
                    label: 'Name'
                },
                {
                    key: 'doctor_information',
                    label: 'Total prescription',
                    sortable: true
                },
                {
                    key: 'confirmation',
                    label: 'Is confirmed?',
                    sortable: true,
                },
                {
                    key: 'otp',
                    label: 'Is otp verified?',
                    sortable: true,
                },
                {
                    key: 'last_update_date',
                    label: 'Updated date',
                    sortable: true,
                },
            ],
            prscriptionFileds: [
                {
                    key: 'patient_id',
                    label: 'Patient'
                },
                {
                    key: 'prescription_identifier',
                    label: 'Identifier'
                },
                {
                    key: 'is_paid',
                    label: 'Is paid'
                },
                {
                    key: 'status',
                    label: 'Status'
                },
                {
                    key: 'created_at',
                    label: 'Created at'
                },
                {
                    key: 'created_date',
                    label: 'Created date'
                },
                {
                    key: 'service_details',
                    label: 'Service'
                },
            ],
            filter_data: 'yearly',
            institutes: [],
            institute_id: '',
            loading: false,
            institute_loading: false,

        }
    },
    created() {
        localStorage.setItem('tabIndex', 3)
        this.$root.$emit('call-active-menu', 3);
        this.getInstitute();
        if (this.institute_id) {
            this.getCounter();
        }


    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Organization reports", route: "/organizations/report"},
        ]);
    },

    methods: {
        getAllApi() {
            if(this.institute_id) {
                this.getCounter();
                this.getDoctors();
                this.getPatients();
                this.getPrescriptionList();
            }

        },
        clear() {
            this.start_date = null;
            this.end_date = null;
            this.filter_data = 'yearly';
            this.getAllApi();
        },
        async getCounter() {
            if (this.start_date && this.end_date) {
                this.filter_data = 'test';
            }
            this.loading = true;
            var base_url = `${process.env.VUE_APP_URL}/api/v2/organisation/corporate/count?api_token=${localStorage.getItem('api_token')}&organisation_id=${this.institute_id}`;
            // var url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            if (this.filter_data == "weekly") {
                var url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            } else if (this.filter_data == 'today') {
                url = `${base_url}&start_date=${this.today}&end_date=${this.today}`
            } else if (this.filter_data == 'monthly') {
                url = `${base_url}&start_date=${this.monthlay_first_day}&end_date=${this.today}`
            } else if (this.filter_data == 'yearly') {
                url = `${base_url}&start_date=${this.yearly_first_day}&end_date=${this.today}`
            } else if (this.start_date && this.end_date) {
                this.filter_data = 'test';
                url = `${base_url}&start_date=${this.start_date}&end_date=${this.end_date}`
            } else if (this.filter_data == 'all') {

                url = `${base_url}`
            } else {
                url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            }

            await axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.counter = response.data.data;
                        this.loading = false;
                    }
                })
        },
        async getPatients() {
            this.loading = true;
            var base_url = `${process.env.VUE_APP_URL}/api/v2/organisation/corporate/patient/list?api_token=${localStorage.getItem('api_token')}&organisation_id=${this.institute_id}`;
            if (this.filter_data == "weekly") {
                var url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            } else if (this.filter_data == 'today') {
                url = `${base_url}&start_date=${this.today}&end_date=${this.today}`
            } else if (this.filter_data == 'monthly') {
                url = `${base_url}&start_date=${this.monthlay_first_day}&end_date=${this.today}`
            } else if (this.filter_data == 'yearly') {
                url = `${base_url}&start_date=${this.yearly_first_day}&end_date=${this.today}`
            } else if (this.start_date && this.end_date) {
                this.filter_data = 'test';
                url = `${base_url}&start_date=${this.start_date}&end_date=${this.end_date}`
            } else if (this.filter_data == 'all') {
                url = `${base_url}`
            } else {
                url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            }
            await axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.loading = false;
                        this.patients = response.data.data;
                    }
                })
        },
        async getDoctors() {
            this.loading = true;
            var base_url = `${process.env.VUE_APP_URL}/api/v2/organisation/corporate/doctor/list?api_token=${localStorage.getItem('api_token')}&organisation_id=${this.institute_id}`;
            if (this.filter_data == "weekly") {
                var url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            } else if (this.filter_data == 'today') {
                url = `${base_url}&start_date=${this.today}&end_date=${this.today}`
            } else if (this.filter_data == 'monthly') {
                url = `${base_url}&start_date=${this.monthlay_first_day}&end_date=${this.today}`
            } else if (this.filter_data == 'yearly') {
                url = `${base_url}&start_date=${this.yearly_first_day}&end_date=${this.today}`
            } else if (this.start_date && this.end_date) {
                this.filter_data = 'test';
                url = `${base_url}&start_date=${this.start_date}&end_date=${this.end_date}`
            } else if (this.filter_data == 'all') {
                url = `${base_url}`
            } else {
                url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            }
            await axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.doctors = response.data.data;
                        this.loading = false;
                    }
                })
        },
        async getPrescriptionList() {
            var base_url = `${process.env.VUE_APP_URL}/api/v2/organisation/corporate/prescription/list?api_token=${localStorage.getItem('api_token')}&organisation_id=${this.institute_id}`;
            if (this.filter_data == "weekly") {
                var url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            } else if (this.filter_data == 'today') {
                url = `${base_url}&start_date=${this.today}&end_date=${this.today}`
            } else if (this.filter_data == 'monthly') {
                url = `${base_url}&start_date=${this.monthlay_first_day}&end_date=${this.today}`
            } else if (this.filter_data == 'yearly') {
                url = `${base_url}&start_date=${this.yearly_first_day}&end_date=${this.today}`
            } else if (this.start_date && this.end_date) {
                this.filter_data = 'test';
                url = `${base_url}&start_date=${this.start_date}&end_date=${this.end_date}`
            } else if (this.filter_data == 'all') {

                url = `${base_url}`
            } else {
                url = `${base_url}&start_date=${this.weekly_first_day}&end_date=${this.weekly_last_day}`
            }
            await axios.get(url)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.prescriptions = response.data.data;
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return process.env.BASE_URL + 'media/users/blank.png';
            }
        },
        prescriptionUrl(url) {
            if (url) {
                return `${process.env.VUE_APP_API_URL}/${url}`;
            } else {
                return ''
            }
        },
        getInstitute() {
            this.institute_loading = true;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/institute/list?limit=7000&is_corporate=1`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {

                        this.institutes = response.data.data
                        this.institute_loading = false;
                    }
                })
        },
    }
}