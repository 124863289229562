import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const DisbursedListMixin = {
    data() {
        return {
            columns: {
                "Username": {
                    field: 'user_name'
                },
                "Transaction id": {
                    field: 'transaction_id'
                },
                "Phone Number": {
                    field: 'phone_number',
                },
                "Request status": {
                    field: 'request_status',
                },
                "Current balance": {
                    field: 'current_balance',
                },
                "amount": {
                    field: 'amount',
                },
                "Requested at": {
                    field: 'requested_at',
                },
                "Payment type": {
                    field: 'user_account_information',
                    callback: (value) => {
                        return value.bank_account_type
                    }
                },
                "Bacnk info": {
                    field: "user_account_information",
                    callback: (value) => {
                        return value.bank_info ? value.bank_info.bank_title : '';
                    }
                },

            },
            items: [],
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'user',
                    label: 'User info',
                    sortable: true,
                },
                {
                    key: 'bank_account_info',
                    label: 'Bank Account info',
                    sortable: true,
                },
                {
                    key: 'transaction_id',
                    label: 'Transaction Id',
                    sortable: true,
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    sortable: true
                },
                {
                    key: 'disbursement_date',
                    label: 'Disbursement date',
                    sortable: true
                },
                {
                    key: 'disbursed_by',
                    label: 'Disbursed by',
                    sortable: true
                },
            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                account_information: '',
                transaction_id: '',
                user_name: '',
                phone_number: '',
                request_status: '',
                current_balance: '',
                amount: '',
                requested_at: '',
            },
            filter: null,
            filterOn: [],
            title: '',
            details: '',
            form: {
                comment: '',
            },
            disburse: {
                transaction_type: '',
                banking_name: '',
                transaction_id: '',
                comment: '',
            },
            loading: true,
            show: true,
            imgError: false,
            single_withdrawlRequest: {},
            transaction_type: [
                {
                    text: 'Bank',
                    value: 'Bank'
                },
                {
                    text: "Mobile Banking",
                    value: "Mobile Banking",
                }
            ],
            commnetState: null,
            request_status_numeric: '',
        }

    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Disbursed list", route: "/withdrawlrequest"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 8)
        this.$root.$emit('call-active-menu', 8);
        this.getWithdralRequest();
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                account_information: '',
                user_name: '',
                phone_number: '',
                request_status: '',
                current_balance: '',
                amount: '',
                requested_at: '',
                transaction_id: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getWithdralRequest() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/payment/withdrawal/disburse/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.items = response.data.data;
                    this.loading = false;
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        sendInfo(item, status) {
            this.request_status_numeric = status;
            this.single_withdrawlRequest = item;
        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        onImgError() {
            this.imgError = true;
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.commnetState = valid
            return valid
        },
        resetModal() {
            this.form.comment = ''
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
    }
}