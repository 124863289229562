import axios from "axios";
import {underScoreToSpace} from "../healper/nameFormating";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const PerDoctorWisePrescriptionMixin = {
    data() {
        return {
            expanded: false,
            text: "View details.",
            expanded_icon: false,
            totalItems: '',
            items: [],
            loading: true,
            fields: [
                {
                    label: 'Prescription Date',
                    key: 'created_date',
                },
                {
                    label: 'Patient Info',
                    key: 'patient_details',
                },
                {
                    label: 'Doctor info',
                    key: 'doctor_details'
                },
                {
                    label: 'Prescription Id',
                    key: 'prescription_identifier'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Followup date',
                    key: 'follow_update'
                },
                {
                    label: 'Payment Status',
                    key: 'is_paid'
                },
                {
                    label: 'Actions',
                    key: 'action'
                }
            ],
            json_fields: {
                "Created at": {
                    field: 'created_date'
                },
                "Patient Information": {
                    field: 'patient_details',
                    callback: (value) => {
                        return `${value.fullname}\n${value.phone_number}`;
                    },
                },
                "Prescription for patient details": {
                    field: 'prescription_for_patient_details',
                    callback: (value) => {
                        return `${value.fullname}\n${value.phone_number}`;
                    },
                },
                "Doctor Information": {
                    field: 'doctor_details',
                    callback: (value) => {
                        return `${value.fullname}\n${value.phone_number}`;
                    },
                },
                "Prescription Identifier": {
                    field: "prescription_identifier"
                },
                "Status": {
                    field: 'status',
                    callback: (value) => {
                        return `${value == true ? 'Valid' : 'Invalid'}`
                    }
                },
                "Payment Status": {
                    field: "is_paid",
                    callback: (value) => {
                        if (value == 1) {
                            return "Paid"
                        } else if (value == 2) {
                            return "Partial Paid"
                        } else {
                            return "Unpaid"
                        }
                    }
                },

            },
            filter: null,
            filterOn: [],
            currentPage: 1,
            perPage: 100,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                created_date: '',
                patient_details: '',
                doctor_details: '',
                prescription_identifier: '',
                is_paid: '',
                status: '',
            },
            startDate: null,
            endDate: null,
            counter: null,
            visible: true,
            pagination: {
                current_page: 1
            },
            show: true,
            user_id: '',
            form: {},
            prescription_details: {},
            exitImage: false,

        }
    },
    metaInfo: {
        title: 'Orko Health Ltd | Prescription List',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Prescriptions", route: "rescriptions"},
        ]);

        this.startDate = this.$route.query.from_date;
        this.endDate = this.$route.query.to_date;
        
        this.getPrescription();
    },
    created() {
        localStorage.setItem('tabIndex', 6)
        this.$root.$emit('call-active-menu', 6);
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            // let startDate = this.localizeDate(this.startDate);
            // let endDate = this.localizeDate(this.endDate);

            // const filtered = this.items.filter(item => {
            //     const itemDate = new Date(item.created_at)
            //     if (startDate && endDate) {
            //         return startDate <= itemDate && itemDate <= endDate;
            //     }
            //     if (startDate && !endDate) {
            //         return startDate <= itemDate;
            //     }
            //     if (!startDate && endDate) {
            //         return itemDate <= endDate;
            //     }
            //     return Object.keys(this.filters).every(key =>
            //         String(item[key]).includes(this.filters[key]))
            // })


            // return filtered.length > 0 ? filtered : [{
            //     id: '',
            //     created_date: '',
            //     patient_details: '',
            //     doctor_details: '',
            //     prescription_identifier: '',
            //     is_paid: '',
            //     status: '',
            // }]

            return this.items;
        },
        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.created_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        filterItem() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getPrescription() {


            axios.get(`${process.env.VUE_APP_URL}/api/v1/administrator/prescription/list/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}&from_date=${this.startDate}&to_date=${this.endDate}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                        this.loading = false;
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return process.env.BASE_URL + 'media/users/blank.png';
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateRangeClear() {
            this.startDate = '';
            this.endDate = '';
            this.getPrescription();
        },
        prescription_url(url) {
            if (url) {
                return `${process.env.VUE_APP_API_URL}/${url}`;
            } else {
                return ''
            }
        },
        underScoreToSpace,
        toggleView() {
            this.expanded = !this.expanded;

            if (this.expanded) {
                this.text = "Hide details.";
                this.expanded_icon = true

            } else {
                this.text = "View details.";
                this.expanded_icon = false;
            }
        },
        exportAsExcel() {
            const format = 'xlsx'
            const exportSelectedOnly = true
            const filename = 'test'
            this.$refs.grid.exportTable(format, exportSelectedOnly, filename)
        },
        sendInfo(item) {
            this.prescription_details = item;
        },
        invalidPrescription() {

        },
        onReset(event) {
            event.preventDefault()
        },
        handlePageChange(value) {
            this.page = value;
            let offset = 0;
            if (this.currentPage > 1) {
                offset = this.page * this.perPage

            }
            axios.get(`${process.env.VUE_APP_URL}/api/v1/administrator/prescription/list?api_token=${localStorage.getItem('api_token')}&limit=100&offset=${offset}`)
                .then(response => {
                    this.items = response.data.data.detail
                    this.totalItems = response.data.data.counter.total_count
                    this.loading = false;
                })

        },
        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
    },

}