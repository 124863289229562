export const underScoreToSpace = (string) => {
    let covertedString = string.replace(/_/g, " ");
    return covertedString
}

export const getSingleLettter = (myName) => {
    if(myName) {
        const fullName = myName.split(" ");
        const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
        return initials.toUpperCase();
    }
    return;
}

export const statusCheck = (string) => {
    if (string == 'pending' || string == 'Pending' || string == 'delayed') {
        return 'warning'
    } else if (string == 'failed' || string == 'deleted' || string == 'booked') {
        return 'danger'
    } else if (string == 'active' || string == 'sent') {
        return 'success'
    }
}