<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <b-form-select v-model="form.role_id" class="mb-3">
              <b-form-select-option :value="item.id" v-for="(item, index) in items" :key="index">
                {{  item.name }}
              </b-form-select-option>
            </b-form-select>


            <div class="single-module" v-for="(permission,index) in permissions" :key="index">
              <h5 @click="toggle(index)" class="px-4 b mb-0 d-flex justify-content-between padding-bottom-10">
                <div class="d-flex">
                  <b-form-checkbox
                      size="lg">
                  </b-form-checkbox>
                  {{ index }}
                </div>
                <div>
                  <span class="px-5"> <i class="fas fa-chevron-up"></i> </span>

                </div>
              </h5>
              <div v-show="isOpen" class="p-3 px-4 accordion-description">
                <div v-for="(permission_data, dfs) in permission" :key="dfs">
                  <b-form-checkbox
                      size="lg"
                      :value="permission_data[0]"
                      v-model="permissions_id[permission_data[0]]"
                      @change="checkPermissions($event, permission_data[0])"

                  >{{ permission_data[1] }}</b-form-checkbox>
                </div>
              </div>

            </div>
            <button @click="setPermission" type="button" class="btn btn-primary">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PermissionsMixin } from "../../mixins/PermissionMixin";
export default {
  name: "Permissions",
  mixins: [PermissionsMixin],

}
</script>
<style scoped>
.accordion-description {
  padding-left: 40px !important;
}
</style>