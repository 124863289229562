import { render, staticRenderFns } from "./NotificationType.vue?vue&type=template&id=e661ea74&scoped=true"
import script from "./NotificationType.vue?vue&type=script&lang=js"
export * from "./NotificationType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e661ea74",
  null
  
)

export default component.exports