<template>
  <div>
    <div id="loader">

    </div>
    <div class="loading-text">Please wait...</div>
  </div>

</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>

#loader{
  /*width: 25%;*/
  /*height: 5px;*/
  overflow: hidden;
  /*background-image: linear-gradient(#05d280, #04a967);*/
  /*background-size: 100%;*/
  /*background-repeat: no-repeat;*/
  /*position: fixed;*/
  position: relative;
  z-index: 9999;
  top: 0;
  left:0;
  right:0;
  transition: width .1s ease-in;
  -moz-transition: width .1s ease-in;
  -ms-transition: width .1s ease-in;
  -o-transition: width .1s ease-in;
  -webkit-transition: width .1s ease-in;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

/* Image by > https://samherbert.net/svg-loaders/ */

#loader:after{
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  background-image: url("data:image/svg+xml,%3Csvg width='120' height='30' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Ccircle cx='15' cy='15' r='15'%3E%3Canimate attributeName='r' from='15' to='15' begin='0s' dur='0.8s' values='15;9;15' calcMode='linear' repeatCount='indefinite'/%3E%3Canimate attributeName='fill-opacity' from='1' to='1' begin='0s' dur='0.8s' values='1;.5;1' calcMode='linear' repeatCount='indefinite'/%3E%3C/circle%3E%3Ccircle cx='60' cy='15' r='9' fill-opacity='.3'%3E%3Canimate attributeName='r' from='9' to='9' begin='0s' dur='0.8s' values='9;15;9' calcMode='linear' repeatCount='indefinite'/%3E%3Canimate attributeName='fill-opacity' from='.5' to='.5' begin='0s' dur='0.8s' values='.5;1;.5' calcMode='linear' repeatCount='indefinite'/%3E%3C/circle%3E%3Ccircle cx='105' cy='15' r='15'%3E%3Canimate attributeName='r' from='15' to='15' begin='0s' dur='0.8s' values='15;9;15' calcMode='linear' repeatCount='indefinite'/%3E%3Canimate attributeName='fill-opacity' from='1' to='1' begin='0s' dur='0.8s' values='1;.5;1' calcMode='linear' repeatCount='indefinite'/%3E%3C/circle%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 72px auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 5px;
  z-index: 0;
}
.loading-text {
  position: absolute;
  left: 50%;
  z-index: 9999999999;
  color: white;
  top: 42%;
  font-size: 1rem;
  font-weight: 700;
  transform: translate(-50%, 50%);
}
</style>
