import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

import {required} from "vuelidate/lib/validators";

export const UserMixin = {
    data() {
        return {
            breadcrumbs: 'users',
            form: {
                name: '',
                email: '',
                password: '',
                phone_number: '',
            },
            reset: {
                password: '',
                comment: '',
            },
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            localActive: false,
            nameState: null,
            emailState: null,
            passwordState: null,
            roleState: null,
            phone_numberState: null,
            roles: [],
            users: [],
            fields: [
                {
                    key: 'id',
                    sortable: true
                },
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'email',
                    sortable: true
                },
                {
                    key: 'phone_number',
                    sortable: true
                },
                {
                    label: "Role",
                    key: 'role',
                },
                {
                    key: 'user_type',
                    sortable: true
                },
                {
                    key: 'is_active',
                    sortable: true,
                },
                {
                    key: 'confirmation',
                    sortable: true,
                },
                {
                    key: 'otp',
                    sortable: true,
                },
                {
                    key: "actions"
                }
            ],
            filter: null,
            filterOn: [],
            user_id: '',
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            email: {
                required,
            },
            phone_number: {
                required,
            }
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Users", route: "users"},
            {title: "Users"}
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 13)
        this.$root.$emit('call-active-menu', 13);
        this.getUser();
        this.getRolesData();
        this.getPermissions();
    },
    methods: {
        getRolesData() {
            axios.get(`api/v1/role/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.roles = response.data.data
                })
        },

        getUser() {
            axios.get(`api/v1/user/list?user_type=executive&limit=-1&platform=backoffice&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.users = response.data.data;
                        this.loading = false;
                    }
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            this.emailState = valid
            this.passwordState = valid
            this.roleState = valid
            this.phone_numberState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.nameState = null;
            this.emailState = null;
            this.passwordState = null;
            this.roleState = null;
            this.phone_numberState = null;
        },
        handleSave(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }


            let form = new FormData();
            form.append("fullname", this.form.name);
            form.append("email", this.form.email);
            form.append("password", this.form.password);

            let role = this.roles.find(item => item.id === this.form.role_id);

            if ((role && !["nurse", "cashier"].includes(role.name))) {
                form.append("user_has_role", this.form.role_id);
            }
            form.append("user_type", (role && ["nurse", "cashier"].includes(role.name)) ? role.name : 'executive');
            form.append("phone_number", this.form.phone_number);
            form.append("platform", "backoffice");
            form.append("is_system_user", "1");
            form.append("sub_type", "backoffice");
            form.append("system_user", localStorage.getItem('user_id'));

            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/register`, form)
                    .then(response => {
                        this.checkResponse(response.data.message, response.data.status_code);
                        this.getUser();
                        this.form = ''
                        this.$bvModal.hide('modal')

                    })
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        userDetails(id) {
            this.$router.push(`/user/details/${id}`);
        },
        changePassword(row) {
            this.user_id = row.id;
            this.$bvModal.show('resetPassword')
        },
        resetPassword(event) {
            event.preventDefault()
            let form = new FormData();
            form.append('password', this.reset.password);
            form.append('comment', this.reset.comment);
            form.append('action_type', 'general');
            form.append('module_type', 'user');
            form.append('user_id', this.user_id);
            if (!this.user_id) {
                return alert("Sorry user id not found");
            }
            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/user/password/update?api_token=${localStorage.getItem('api_token')}`, form)
                .then(response => {
                    if (response.data.status_code == 400) {
                        return this.$snotify.error('Something worng');
                    }
                    this.$bvModal.hide('resetPassword')
                    this.form = '';
                    this.$snotify.success('Password successfully reset.');
                    // this.getUser();

                })
        },
        onReset(event) {
            event.preventDefault()
            this.reset = {};
        },
        userActiveInactive(item) {
            this.$snotify.clear();
            this.$snotify.confirm(
                `You want to ${item.is_active == true ? 'Inactive' : 'Active'}`,
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                let form = new FormData();
                                form.append('status', item.is_active == true ? 'inactive' : 'active');
                                axios
                                    .post(`${process.env.VUE_APP_URL}/api/v2/user/${item.id}/status?api_token=${localStorage.getItem('api_token')}&_method=put`, form)
                                    .then(response => {
                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully" + item.is_active == true ? 'Active' : 'Inactive',
                                            "Success"
                                        );
                                        this.getUser();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        }
    },
    computed: {
        rows() {
            return this.users.length
        },
    },
    currentPage: {
        handler: function (value) {
            this.getUser().catch(error => {
                console.error(error)
                console.error(value)
            })
        }
    }

}
