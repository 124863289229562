<template>
  <div>
    <div class="card">
      <div class="card-body">

        <b-button v-b-modal.modal variant="primary">Assign users by product</b-button>
        <div>
          <b-modal
              id="modal"
              title="Assign users by product"
              @show="resetModal"
              @hidden="resetModal"
              size="lg"
              scrollable
              @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit" style="height: 50vh;">

              <div class="mb-3">
                <label>Select Product</label>
                <v-select
                    :options="products"
                    :reduce="products => products.id"
                    v-model="form.product_id"
                    label="title"
                    name="product_id"
                    id="role">
                </v-select>
              </div>

              <div class="mb-3">
                <label>Select Users</label>

                <v-select
                    ref="multi_select"
                    :options="users"
                    :reduce="users => users.id"
                    v-model="form.user_id"
                    name="user_id"
                    label="phone_number"
                    :components="{OpenIndicator}"
                    multiple>
                  <template slot="option" slot-scope="user">
                    {{user.id}} - {{user.name}} - ( {{user.phone_number}} )
                  </template>
                </v-select>
              </div>
            </form>
          </b-modal>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
    <loader-component v-if="loading"/>

  </div>
</template>

<script>
import {UserProductMixin} from "../../mixins/UserProductMixin";

export default {
  name: "UserProduct",
  mixins: [UserProductMixin]
}
</script>

<style scoped>

</style>