<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Orko Profession</h2>
        <div class="row mt-10">
          <div class="col-md-12">
            <b-button variant="primary" class="btn-lg" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new profession
            </b-button>
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a new profession"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group
                    label-for="name-input"
                    invalid-feedback="Title is required"
                    :state="title_state">
                  <label for="">Title <span class="text-danger">*</span></label>
                  <b-form-input
                      id="name-input"
                      v-model="form.title"
                      :state="title_state"
                      required/>
                </b-form-group>
                <b-form-group class="mt-5">
                  <label for="">Details <span class="text-danger">*</span></label>
                  <b-form-textarea
                      id="textarea"
                      v-model="form.details"
                      placeholder="Enter details"
                      rows="3"
                      class=""
                  ></b-form-textarea>
                </b-form-group>

                <b-form-checkbox
                    id="checkbox-1"
                    v-model="form.is_active"
                    name="checkbox-1"
                    class="mt-3"
                    value="1"
                    unchecked-value="0">
                  Active
                </b-form-checkbox>
              </form>
            </b-modal>
          </div>
        </div>
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Orko profession list"
                  name="Orko profession list">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(status)="data">
                <div>
                  <b-badge variant="success" v-if="data.item.status == 1">Active</b-badge>
                  <b-badge variant="success" v-else>Inactive</b-badge>
                </div>
              </template>

              <template #cell(action)="data">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <div>
                      <b-dropdown-item href="#" @click="editAction(data.item)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <!--                      <b-dropdown-item href="#" @click="deleteAction(row.item)">Delete</b-dropdown-item>-->
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {OrkoProfession} from "../../mixins/OrkoProfession";

export default {
  name: "OrkoProfession",
  mixins: [OrkoProfession],
}
</script>

<style scoped>

</style>