import { render, staticRenderFns } from "./ManageProduct.vue?vue&type=template&id=c8e461ca&scoped=true"
import script from "./ManageProduct.vue?vue&type=script&lang=js"
export * from "./ManageProduct.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8e461ca",
  null
  
)

export default component.exports