<template>
  <div v-if="this.parent_name" class="border-bottom">

    <router-link :to="`/user/details/${parent_id}`" class="font-weight-bold"> {{
        parent_name
      }}
    </router-link>
    <small>Parent({{ this.parent_id }})</small>
  </div>
</template>

<script>
export default {
  name: "ParentInfo",
  props: {
    parent_name: {
      type: String,
      required: false
    },
    parent_id: {
      type: Number,
      required: false
    },
  }
}
</script>

<style scoped>

</style>