<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <h2>Medicine</h2>
          </div>
          <div class="col-md-8 text-right">
            <b-button variant="primary" class="mr-3" v-b-modal.modal-1><i class="fas fa-plus"></i> Add new medicine
            </b-button>

            <button
                class="btn btn-warning mr-3"
                @click="importMedicine"
                name="Medicine">
              <i class="fas fa-file-excel"></i> <span class="ml-3"> Import Excel</span>
            </button>
            <!--    import modal        -->
            <b-modal
                id="importModal"
                ref="modal"
                title="Import medicine"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleImport"
            >
              <form ref="form" @submit.stop.prevent="handleSubmitImport">
                <b-form-group
                    label="Import medicine excel file"
                    label-for="name-input"
                >
                  <b-form-file
                      id="name-input"
                      ref="imported_file"
                      required
                  ></b-form-file>
                </b-form-group>

                <a :href="`${base_url}/downloadable-files/medicine_import_formate.xlsx`" download>Download sample file</a>
              </form>
            </b-modal>
            <!--  Add modal -->
            <b-modal
                id="modal-1"
                size="lg"
                title="Create a new medicine"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk">
              <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                <b-form-group
                    label-for="title-input"
                    invalid-feedback="Medicine class title is required"
                    :state="titleState">
                  <label for="title-input">Medicine class title <span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.title"
                      :state="titleState"
                      required/>
                </b-form-group>

                <b-form-group>
                  <label for="">Medicine generics<span class="text-danger">*</span></label>
                  <v-select
                      :options="medicine_generics"
                      :reduce="medicine_generics => medicine_generics.id"
                      v-model="form.medicine_generics_id"
                      label="title"
                      required>
                  </v-select>
                  <p class="text-danger">{{ medicine_generics_is_required }}</p>
                </b-form-group>

                <b-form-group>
                  <label for="">Medicine company<span class="text-danger">*</span></label>
                  <v-select
                      :options="manufacturers"
                      :reduce="manufacturers => manufacturers.id"
                      v-model="form.manufacturer_id"
                      label="title"
                      required>
                  </v-select>
                  <p class="text-danger">{{ medicine_company_required }}</p>
                </b-form-group>

                <b-form-group
                    label-for="strength-input"
                    invalid-feedback="Strength is required"
                    :state="titleState">
                  <label for="strength-input">Strength<span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.strength"
                      :state="strengthState"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="dosages_desc_type-input"
                    invalid-feedback="dosages desc type is required"
                    :state="dosagesState">
                  <label for="dosages_desc_type-input">Dosages desc type<span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.dosages_desc_type"
                      :state="dosagesState"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="use_for-input"
                    invalid-feedback="Use for is required"
                    :state="useForState">
                  <label for="strength-input">Use for<span class="text-danger">*</span></label>
                  <b-form-input
                      id="title-input"
                      v-model="form.use_for"
                      :state="useForState"
                      required/>
                </b-form-group>

                <b-form-group
                    label-for="unit_price-input"
                    :state="unit_priceState"
                    invalid-feedback="Unit price is required">
                  <label for="unit_price-input">Unit price <span class="text-danger">*</span> </label>
                  <b-form-input
                      id="unit_price-input"
                      v-model="form.unit_price"
                      :state="unit_priceState"
                      @keypress="isNumber($event)"
                      required/>
                </b-form-group>


              </form>
            </b-modal>
            <button
                class="btn btn-primary"
                @click="exportMedicine"
                v-if="total_items <= 1000"
                name="Medicine">
              <i class="fas fa-file-excel"></i> <span class="ml-3"> Export Excel</span>
            </button>
            <button
                v-else
                class="btn btn-primary"
                @click="exportMedicineModal"
                name="Medicine">
              <i class="fas fa-file-excel"></i> <span class="ml-3"> Export Excel</span>
            </button>
            <b-modal id="exportModal" title="Exports" size="lg" hide-footer>
              <p style="display: inline-block; margin-right: 5px"
                 v-for="(titem, index) in parseInt(total_export_excel_file)" :key="index">
                <button type="button" class="btn btn-info btn-sm"
                        @click="exportMedicine(index == 0 ? 0 : index*export_limit-1,  export_limit)">{{
                    index == 0 ? index*export_limit : index*export_limit+1
                  }} - {{ export_limit * titem }}
                </button>
              </p>
            </b-modal>

            <button
                class="btn btn-danger ml-3"
                @click="clearFilter">
              <span class=""> Clear </span>
            </button>
          </div>
        </div>
        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"

                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-3">
            <v-select
                :options="medicine_generics"
                :reduce="medicine_generics => medicine_generics.id"
                v-model="medicine_generics_id"
                label="title"
                @input="searchMedicine"
                placeholder="Select generics"
                name="id"
            >
            </v-select>
          </div>
          <div class="col-md-3">
            <v-select
                :options="manufacturers"
                :reduce="manufacturers => manufacturers.id"
                v-model="manufacturers_id"
                @input="searchMedicine"
                placeholder="Select Company"
                label="title"
                name="id"
            >
            </v-select>
          </div>
          <div class="col-md-4">
            <b-form @submit.prevent="searchMedicine">
              <b-form-group
                  label-for="filter-input"
                  label-cols-sm="1"
                  label-align-sm="right"
                  label-size="lg"
                  class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"/>

                  <b-input-group-append>
                    <b-button variant="primary" type="submit">search</b-button>
                  </b-input-group-append>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="getMedicine" variant="danger">Clear</b-button>
                  </b-input-group-append>

                </b-input-group>

              </b-form-group>
            </b-form>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="items.length ? filtered : items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="0"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(medicine_class_id)="data">
                {{ data.item.title }}
              </template>

              <template #cell(medicine_company)="data">
                {{ data.item.medicine_company ? data.item.medicine_company.title : 'n/a' }}
              </template>

              <template #cell(generics)="data">
                {{ data.item.generics ? data.item.generics.title : 'n/a' }}
              </template>
              <template #cell(generics_indications)="data">
                {{ data.item.generics ? data.item.generics.indications : 'n/a' }}
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">
                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>
                    <div>
                      <b-dropdown-item :to="`/medicine/details/${row.item.id}`">
                        <span class="pr-4"><i class="fas fa-eye"></i></span>View
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="editAction(row)">
                        <span class="pr-4"><i class="fas fa-edit"></i></span>Edit
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="deleteAction(row)">
                        <span class="pr-4"><i class="fas fa-trash"></i></span> Delete
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <!--  Pagination -->
        <div class="row mt-3">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="total_items"
                :per-page="perPage"
                @change="handlePageChange"
                size="lg"
                class="my-0"
            ></b-pagination>


          </div>
        </div>
      </div>
    </div>
    <loader-component v-if="loading"/>
    <vue-snotify></vue-snotify>
  </div>

</template>
<script>
import {MedicineMixin} from "../../mixins/medicine/MedicineMixin";

export default {
  name: "Medicine",
  mixins: [MedicineMixin],
}
</script>