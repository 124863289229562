<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <h2>Agent information</h2>
          <router-link :to="`/edit/agent/${agent_info.id}`"><i class="far fa-edit"></i></router-link>
        </div>

        <br>
        <div class="row mt-5">

          <div class="col-md-6">
            <div class="basic-info">
<!--              <h4 class="mb-0">{{ agent_info.fullname }}</h4>-->
<!--              <p class="text-muted mb-0">Username: <b>{{ agent_info.username }}</b></p>-->
<!--              <p class="text-muted">Secondary phone no: <b>{{-->
<!--                  agent_info.pharmacy_details.secondary_phone_no ? agent_info.pharmacy_details.secondary_phone_no : 'not set yet'-->
<!--                }}</b></p>-->

              <h5><b> Pharmacy Owner's Name </b> <br> {{ agent_info.pharmacy_details.organization_name }}</h5>
              <p class="mb-0 font-size-16"><b>Pharmacist name</b> <br>{{
                  agent_info.pharmacy_details.pharmacist_name ? agent_info.pharmacy_details.pharmacist_name : 'not set yet'
                }}</p>
              <p class="mb-0 font-size-16"><b>Tin no </b><br>
                {{ agent_info.pharmacy_details.tin_no ? agent_info.pharmacy_details.tin_no : 'not set yet' }}</p>
              <p class="font-size-16"> <b>Drug license</b> <br> {{
                  agent_info.pharmacy_details.drag_license ? agent_info.pharmacy_details.drag_license : 'not set yet'
                }}</p>
              <p class="font-size-16"> <b>Secondary Telephone</b><br>{{
                  agent_info.pharmacy_details.secondary_phone_no ? agent_info.pharmacy_details.secondary_phone_no : 'not set yet'
                }}</p>

            </div>
          </div>
          <div class="col-md-4">
            <b-row>
              <b-col>

                <b-img thumbnail fluid :src="userPhoto(agent_info.identity_card)" alt="Image 2"
                       v-if="agent_info.identity_card" @click="showImage(agent_info.identity_card)"></b-img>
                <b-img blank blank-color="#ccc" width="280" height="120" v-else></b-img>
                <p class="mb-0"> <b> Identity card type </b> <br>{{ agent_info.identity_card_type }}</p>
                <p class="mb-0"><b> Identity card no </b> <br>
                  {{ agent_info.identity_card_no ? agent_info.identity_card_no : 'not set yet' }}</p>
              </b-col>
            </b-row>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentProfile",
  props: {
    agent_info: {},
  },
  methods: {
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return "../../assets/img/blank.png";
      }
    },
    showImage(img) {
      const h = this.$createElement
      // Using HTML string

      // More complex structure
      const messageVNode = h('div', {class: ['foobar']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
            thumbnail: true,
            center: false,
          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        buttonSize: 'sm',
        centered: true, size: 'lg'
      })
    },
  }
}
</script>

<style scoped>
.basic-info {
  line-height: 25px;
}
</style>