<template>
  <div>
    <div>
      <div class="card">
        <div class="card-body">

          <div class="row justify-content-end">
            <div class="col-md-6 text-right">
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Center wise prescription list"
                  name="Center wise prescription list">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
              <b-button @click="getCenterWisePatientList()" variant="info" class=" ml-5"><i
                  class="fas fa-redo-alt"></i></b-button>
              <button class="btn btn-danger ml-5" @click="clearDate">Clear</button>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-md-6">
              <div>
                <v-select
                    :options="centers"
                    :reduce="centers => centers.id"
                    v-model="center_id"
                    @input="getCenterWisePatientList"
                    label="name"
                    class="h-100"
                    name="id"
                    placeholder="Select center to filter"
                    required>
                </v-select>

              </div>
            </div>
            <div class="col-md-6">
              <b-badge variant="primary">Total Prescription {{ patient_count }}</b-badge>
            </div>
          </div>
          <div>
            <!--  Date reange filter start -->
            <div class="row align-items-center">
              <div class="col-md-1 mt-7">
                <div class="">
                  <b-form-group
                      class="mb-0">
                    <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="col-md-3">

                <label for="">From</label>
                <input type="date" v-model="startDate" class="form-control">

              </div>

              <div class="col-md-3">
                <label for="">To</label>
                <input type="date" v-model="endDate" class="form-control">
              </div>
              <div class="col-md-1 text-center">
                <button class="btn btn-success mt-7" @click="getCenterWisePatientList">Filter</button>
              </div>
              <div class="col-md-4 mt-7">
                <b-input-group size="sm">
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"></b-form-input>
                  <b-input-group-append>
                    <b-button :disabled="filter" @click="filter = ''" variant="primary">Clear</b-button>
                  </b-input-group-append>
                </b-input-group>

              </div>

            </div>
            <!-- List table-->

            <b-table
                striped hover
                :items="items.length ? filtered : items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="0"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                class="table-responsive mt-5"
                show-empty>

              <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                  <input v-model="filters[field.key]" :placeholder="field.label" class="form-control">
                </td>
              </template>

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(doctor_details)="row">
                <div class="">
                  <b-media tag="li">
                    <template #aside>
                      <b-img :src="userPhoto(row.item.doctor_details ? row.item.doctor_details.image : '')"
                             class="user_image"
                             alt=""
                             v-if="row.item.doctor_details"></b-img>


                      <img src="../../assets/img/blank.png" class="user_image" v-else/>
                    </template>

                    <h5 class="mt-0 mb-1">
                      {{ row.item.doctor_details ? row.item.doctor_details.fullname : '' }}</h5>
                    <ul class="mb-0 user_info">
                      <li>
                        {{ row.item.doctor_details ? row.item.doctor_details.phone_number : '' }}
                      </li>

                    </ul>
                  </b-media>
                  <div>
                  </div>
                </div>
              </template>

              <template #cell(patient_details)="row">
                <div class="">
                  <b-media tag="li">
                    <template #aside>
                      <b-img :src="userPhoto(row.item.patient_details ? row.item.patient_details.image : '')"
                             class="user_image"
                             alt=""
                             v-if="row.item.patient_details"></b-img>


                      <img src="../../assets/img/blank.png" class="user_image" v-else/>
                    </template>

                    <h5 class="mt-0 mb-1">
                      {{ row.item.patient_details ? row.item.patient_details.fullname : '' }}</h5>
                    <ul class="mb-0 user_info">
                      <li>
                        {{ row.item.patient_details ? row.item.patient_details.phone_number : '' }}
                      </li>

                    </ul>
                  </b-media>
                  <div>
                  </div>
                </div>
              </template>

              <template #cell(prescription_for_patient_details)="row">
                <div class="">
                  <b-media tag="li">
                    <template #aside>
                      <b-img
                          :src="userPhoto(row.item.prescription_for_patient_details ? row.item.prescription_for_patient_details.image : '')"
                          class="user_image"
                          alt=""
                          v-if="row.item.prescription_for_patient_details"></b-img>


                      <img src="../../assets/img/blank.png" class="user_image" v-else/>
                    </template>

                    <h5 class="mt-0 mb-1">
                      {{
                        row.item.prescription_for_patient_details ? row.item.prescription_for_patient_details.fullname : ''
                      }}</h5>
                    <ul class="mb-0 user_info">
                      <li>
                        {{
                          row.item.prescription_for_patient_details ? row.item.prescription_for_patient_details.phone_number : ''
                        }}
                      </li>

                    </ul>
                  </b-media>
                  <div>
                  </div>
                </div>
              </template>

              <template #cell(service_details)="data">
                <p class="mb-0">{{ data.item.service_details.service_type }}</p>
                <p class="mb-0">{{ data.item.service_details.service_fee }}</p>
              </template>

              <template #cell(is_paid)="data">
                <b-badge variant="success" v-if="data.item.is_paid">Paid</b-badge>
                <b-badge variant="danger" v-else>Unpaid</b-badge>
              </template>

              <template #cell(prescription_identifier)="data">
                <a :href="prescriptionUrl(data.item.prescription_url)"
                   target="_blank">{{ data.item.prescription_identifier }}</a>
              </template>
              <template #cell(followup_date)="data">
                <p>{{ data.item.followup_date ? data.item.followup_date : 'n/a' }}</p>
              </template>


            </b-table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <!--   pagination -->
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              @change="handlePageChange"
              size="lg"
              class="my-0"
          ></b-pagination>
        </div>
      </div>
      <!-- Report Wizard Start -->
      <loader-component v-if="loading"/>

    </div>
  </div>
</template>

<script>

import {CenterWisePrescriptionListReportMixin} from "../../mixins/reports/CenterWisePrescriptionListReportMixin";

export default {
  name: "CenterPrescriptionList",
  mixins: [CenterWisePrescriptionListReportMixin],
  title: 'Center wise patient list',
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}


.user_icon i {
  font-size: 10px;
}

.user_info li {

  margin: 5px 0px;
}

.user_info li i {
  padding: 4px 6px;
  font-size: 10px;
  color: #44A0FF;
  border-radius: 50%;
  background-color: rgba(230, 242, 255, 0.9);
  margin-right: 5px;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}
</style>