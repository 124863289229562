import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {dateFormate} from "../healper/dateTimeFormet";
import {ExportMixin} from "./core/ExportMixin";

export const SalesActivityLogMixin = {
    mixins: [ExportMixin],
    data() {
        let route_name = this.$route.name;
        return {
            bookType: "xlsx",
            breadcrumbs: route_name + "users",
            loading: true,
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            items: [],
            fields: [
                {
                    key: "seller_id",
                    label: "TSO/ASO",
                    sortable: true,
                },
                {
                    key: "full_name",
                    label: "Doctor info",
                    sortable: true,
                },
                {
                    key: "update_history",
                    label: "Update Logs",
                    sortable: false,
                },
                {
                    key: "updated_at",
                    label: "Last Updated At",
                    sortable: true,
                },

            ],

            show: true,
            base_url: process.env.VUE_APP_API_URL,
            user_id: "",
            total_user: "",
            start_date: dateFormate().weekly_first_day,
            end_date: dateFormate().weekly_last_day,
            seller_id: "",
            unique_visits: false,
            activities_details: null,

            fullNameState: null,
            designationIdState: null,
            designationState: null,
            bmdcRegNoState: null,
            phoneNoState: null,
            hospitalIdState: null,
            hospitalNameState: null,
            latState: null,
            lonState: null,
            logAddressState: null,
            prescriptionModeState: null,
            hasOtherSoftware: null,
            vendorNameState: null,
            mobileDeviceState: null,
            preferenceState: null,
            dailyPatientState: null,
            connectivityState: null,
            prescription_modes: [
                {value: 'mobile', text: 'Mobile'},
                {value: 'pc', text: 'PC'},
                {value: 'laptop', text: 'Laptop'},
                {value: 'hr', text: 'HR'},
            ],
            institutes: [],
            designations: [],
            specilities: [],
            connectivities: [
                {value: 'wifi', text: 'wifi'},
                {value: 'own', text: 'own'},
                {value: 'both', text: 'both'},
            ],
            mobile_devices: [
                {value: 'android', text: 'android'},
                {value: 'ios', text: 'ios'},
                {value: 'both', text: 'both'},
            ],
            preferences: [
                {value: 'android', text: 'android'},
                {value: 'ios', text: 'ios'},
                {value: 'pc', text: 'pc'},
                {value: 'tab', text: 'tab'},
                {value: 'laptop', text: 'laptop'},
            ],
            submittedNames: [],
            phone_no_validation_msg: '',
            total_activity: 0,
            search: '',
        };
    },
    created() {
        localStorage.setItem("tabIndex", 14);
        this.$root.$emit("call-active-menu", 14);
        if (this.$route.params.id) {
            this.seller_id = parseInt(this.$route.params.id)
        }
        this.getUser();
        this.userPhoto();
    },

    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Sales activity logs", route: "/sales/activity-logs"},
        ]);
    },

    methods: {
        acceptNumber() {
            var phoneno = /(^(\+88)?(01){1}[3456789]{1}(\d){8})$/;
            if (this.form.phone_no.match(phoneno)) {
                return this.phone_no_validation_msg = "";
            } else {
                return this.phone_no_validation_msg = "Phone Number Invalid"
            }
        },
        getUser() {
            this.loading = true;
            this.currentPage = 1;
            var url = `${
                process.env.VUE_APP_URL
            }/api/v2/sales/team/activity/logs?api_token=${localStorage.getItem(
                "api_token"
            )}&platform=backoffice&limit=${this.perPage}&offset=0${
                this.start_date ? "&start_date=" + this.start_date : ""
            }${this.end_date ? "&end_date=" + this.end_date : ""}${
                this.seller_id ? "&seller_id=" + this.seller_id : ""
            }`;
            axios.get(url).then((response) => {
                if (response.data.status_code == "500") {
                    this.$router.push("/access-denied");
                } else {
                    if (response.data.data) {
                        this.items = response.data.data;
                        this.total_activity = response.data.total_count;
                    }
                    this.loading = false;
                }
            });
        },
        handlePageChange(value) {
            this.loading = true
            var offset = value - 1;
            var correctValue = offset * this.perPage;

            var url = `${
                process.env.VUE_APP_URL
            }/api/v2/sales/team/activity/logs?api_token=${localStorage.getItem(
                "api_token"
            )}&platform=backoffice&limit=${this.perPage}&offset=${correctValue}${
                this.start_date ? "&start_date=" + this.start_date : ""
            }${this.end_date ? "&end_date=" + this.end_date : ""}${
                this.seller_id ? "&seller_id=" + this.seller_id : ""
            }`;
            axios.get(url)
                .then(response => {
                    this.loading = false;
                    if (response.data.status_code == "500") {
                        this.$router.push("/access-denied");
                    } else {
                        if (response.data.data) {
                            this.items = response.data.data;
                            this.total_activity = response.data.total_count;
                        }

                    }
                })
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        localizeDate(date) {
            if (!date || !date.includes("-")) return date;
            const [yyyy, mm, dd] = date.split("-");
            return new Date(`${mm}/${dd}/${yyyy}`);
        },

        formatDate(date) {
            return new Intl.DateTimeFormat("en-US", {dateStyle: "long"}).format(
                new Date(date)
            );
        },

        sendInfo(item) {
            this.user_id = item.id;
        },

        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../../public/media/users/blank.png";
            }
        },

        dateRangeClear() {
            this.startDate = "";
            this.endDate = "";
            this.start_date = null;
            this.end_date = null;
            this.getUser();
        },

        showImage(img) {
            const h = this.$createElement;
            // More complex structure
            const messageVNode = h("div", {class: ["foobar"]}, [
                h("b-img", {
                    props: {
                        src: `${process.env.VUE_APP_API_URL}/${img}`,
                        thumbnail: true,
                        center: false,
                    },
                }),
            ]);
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                buttonSize: "sm",
                centered: true,
                size: "lg",
            });
        },
    },

    computed: {
        computedFields() {
            if (!this.unique_visits)
                return this.fields.filter(field => !field.isUniqueVisits);
            else
                return this.fields;
        },
        routeName() {
            return this.$route.name;
        },
    },
};
