import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";


export const NotificationSendMixin = {
    data() {
        return {
            form: {
                notification_type: '',
                notification_title: '',
                notification_body: '',
                user_id: '',
                user_type: '',
                individual: '1',
                notification_image_type_id: '',
            },
            notificationTypeState: null,
            notification_titleState: null,
            notificationBodyState: null,
            userTypeState: null,
            userIdState: null,

            notification_types: [],
            user_types: [],
            users: [],
            options: [
                {text: 'Send All User', value: '1'},
                {text: 'Individual User', value: 'true'},
            ],
            loading: false,
            userState: null,
            single_user_is_required: '',
            notification_type_is_required: '',
            user_type_is_required: '',
            user_loader: false,
            notification_type: [
                {item: 'text', name: 'Text'},
                {item: 'image', name: 'Image'},
            ],
            notification_type_selected: 'text',
            notification_img: null,
            pushedUsers: [],
        
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Notification send to user", route: "notification-send"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 5)
        this.$root.$emit('call-active-menu', 5);
        this.notificationType();
        this.userType();
    },

    computed: {
        validation() {
            return this.form.user_id.length > 0
        }
    },

    methods: {

        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.notificationTypeState = valid
            this.notification_titleState = valid
            this.notificationBodyState = valid
            this.userTypeState = valid
            this.userState = valid
            return valid
        },

        handleSubmit() {
            // Exit when the form isn't valid

            if (!this.checkFormValidity()) {
                return
            }

            if (this.form.notification_type == '' || this.form.notification_type == null) {
                return this.notification_type_is_required = 'Notification type  is required';
            }

            if (this.form.user_type == '' || this.form.user_type == null) {
                return this.user_type_is_required = 'User type is required';
            }

            if (this.form.individual === true) {
                if (this.form.user_id == '') {
                    return this.single_user_is_required = "User is required";
                }
            }


            // Push the name to submitted names
            let form = new FormData();
            if (this.pushedUsers) {
                var res = this.pushedUsers.map(s=>s.id);
                form.append("user_id", res);
            }


            if (this.notification_img) {
                let cimage = this.$refs.notification_img.files[0];
                form.append('notification_image', cimage);

                form.append("notification_type_id", this.form.notification_image_type_id);

            } else {
                form.append("notification_type_id", this.form.notification_type);
                form.append("notification_text", this.form.notification_body);
            }

            form.append("notification_title", this.form.notification_title);
            form.append("user_type", this.form.user_type);
            // Hide the modal manually

            this.$nextTick(() => {
                this.loading = true
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/notification/send/user?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            this.loading = false
                            return this.$snotify.error(response.data.message);
                        }
                        this.loading = false
                        this.form = {
                            notification_type: '',
                            notification_title: '',
                            notification_body: '',
                            user_id: '',
                            user_type: '',
                            individual: '1'
                        };
                        this.pushedUsers = [];
                        this.notificationTypeState = null;
                        this.notification_titleState = null;
                        this.notificationBodyState = null;
                        this.userTypeState = null;
                        this.userIdState = null;
                        this.notification_type_is_required = '';
                        this.user_type_is_required = '';
                        this.notification_img= null;
                        this.notification
                        this.$snotify.success('Successfully saved');

                    })
            })
        },

        notificationType() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/notification/manage/type/list?api_token=${localStorage.getItem('api_token')}&is_for_crm=1&limit=1000`)
                .then(response => {
                    this.notification_types = response.data.data
                })
        },

        userType() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/type/list?api_token=${localStorage.getItem('api_token')}&limit=-1&platform=backoffice`)
                .then(response => {
                    this.user_types = response.data.data
                })
        },

        userListByType() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&limit=-1&platform=backoffice&user_type=${this.form.user_type}`)
                .then(response => {
                    this.users = response.data.data
                })
        },

        userTypeDependAble() {
            this.user_loader = true;
            this.form.user_id = '';
            axios.get(`${process.env.VUE_APP_URL}/api/v1/user/list?api_token=${localStorage.getItem('api_token')}&limit=-1&platform=backoffice&user_type=${this.form.user_type}`)
                .then(response => {
                    this.users = response.data.data
                    this.user_loader = false;
                    if (this.form.user_type) {
                        this.options[0].text = `Send All ${this.form.user_type}`;
                        this.options[1].text = `Individual  ${this.form.user_type}`;
                    }
                })


        },

        userTypeOnchange() {
            this.form.user_id = '';
        },

        onInputChange(val){
            axios.get(`${process.env.VUE_APP_URL}/api/v2/user/search?api_token=${localStorage.getItem('api_token')}&user_type=${this.form.user_type}&q=${val}`)
                .then(response => {
                    this.users = response.data.data
                })
        },

        selectHandler(item){
            if(this.form.user_id){
                this.form.user_id = ''
            }
            this.pushedUsers.push(item.item);
        },

        clickHandler(){
            if(this.form.user_id) {
                this.form.user_id = ''
            }
        },

        getSuggestionValue(suggestion) {
            return "";

        },

        removePushedUser(index){
            this.pushedUsers.splice(index, 1);
        }
    },
    watch: {

        'notification_type_selected': function (val) {
            if (val == 'image') {
                this.notification_types.map(item => {
                    if (item.notification_type_term == 'promotional_image') {
                        this.form.notification_type = 'promotional_image';
                        this.form.notification_image_type_id = item.id;
                    }
                })

            } else {
                this.form.notification_type = '';
            }

        }
    }

}