<template>
  <div id="">
    <!--  Doctor overview start -->
    <div class="row">
      <div class="col-md-12">
        <!--begin::Card-->
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div>
              <!--begin::Details-->
              <div class="d-flex" id="doctroOverView">
                <!--begin::Info-->
                <div class="flex-grow-1">

                  <!--begin::Title-->
                  <div class="d-flex justify-content-between flex-wrap mt-1">
                    <div class="d-flex mr-3">
                      <h3 class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3">
                        Doctor overview
                      </h3>
                    </div>
                    <div class="my-lg-0 my-3">
                      <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"
                                @click="editDoctorOverviewModal">Edit overview
                      </b-button>
                      <b-modal
                          id="doctorOverViewModal"
                          title="Edit doctor overview"
                          @hidden="resetModal"
                          size="lg"
                          @ok="handleOk">
                        <b-spinner variant="primary" label="Spinning" v-if="modal_loading"></b-spinner>
                        <form ref="form" @submit.stop.prevent="saveDoctorOverView" v-else>
                          <div class="row">
                            <div class="col-md-6">
                              <label for="">Select nature of service</label>
                              <v-select
                                  :options="nature_of_services"
                                  :reduce="nature_of_services => nature_of_services.id"
                                  label="title"

                                  v-model="doctor_overview_form.nature_of_service_id"
                              >
                              </v-select>
                            </div>


                            <div class="col-md-6">
                              <label for="">Select Profession</label>
                              <v-select
                                  :options="by_professions"
                                  :reduce="by_professions => by_professions.id"
                                  label="title"

                                  v-model="doctor_overview_form.profession_id"
                              >
                              </v-select>
                            </div>


                            <div class="col-md-6">
                              <b-form-group label-for="virtual_fee">
                                <label for="">Virtual fee <span class="text-danger">*</span></label>
                                <b-form-input
                                    id="virtual_fee"
                                    v-model="doctor_overview_form.call_fee"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                            <div class="col-md-6">
                              <b-form-group label-for="visit_fee">
                                <label for="">Visit fee <span class="text-danger">*</span></label>
                                <b-form-input
                                    id="visit_fee"
                                    v-model="doctor_overview_form.visit_fee"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                            <div class="col-md-6">
                              <b-form-group label-for="virtual_fee">
                                <label for="">Report fee <span class="text-danger">*</span></label>
                                <b-form-input
                                    id="virtual_fee"
                                    v-model="doctor_overview_form.report_fee"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                            <div class="col-md-6">
                              <b-form-group label-for="visit_fee">
                                <label for="">Followup fee <span class="text-danger">*</span></label>
                                <b-form-input
                                    id="visit_fee"
                                    v-model="doctor_overview_form.followup_fee"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col">
                              <b-form-group label-for="about_doctor">
                                <label for="">About doctor </label>
                                <b-form-textarea
                                    id="about_doctor"
                                    rows="3"
                                    v-model="doctor_overview_form.about"

                                ></b-form-textarea>
                              </b-form-group>
                            </div>
                          </div>
                        </form>
                      </b-modal>
                    </div>
                  </div>
                  <!--end::Title-->

                  <!--  Doctor overview start  -->
                  <div class="row justify-content-between align-content-center mt-10">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-md-6">
                          <span class="text-center mb-5 font-weight-bold font-weight-bolder font-size-sm"> Doctor Signature</span><br><br>
                          <b-img fluid thumbnail :src="doctorSignature(doctorOverView.doctor_information.signature)"
                                 alt="Image 1" class="singature_image"
                                 @click="showImage(doctorOverView.doctor_information.signature)"></b-img>
                          <div class="file-upload">
                            <i class="fa fa-pencil-alt"></i>
                            <input type="file" ref="signature" @change="uploadImage" accept=".png,.jpg,.jpeg"/>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <span
                              class="text-center mb-5 font-weight-bold font-weight-bolder font-size-sm text-uppercase">{{
                              doctorOverView.identity_card_type
                            }} Card</span><br><br>
                          <b-img fluid thumbnail :src="doctorSignature(doctorOverView.identity_card)" alt="Image 1"
                                 class="singature_image" @click="showImage(doctorOverView.identity_card)"></b-img>
                          <div class="file-upload">
                            <i class="fa fa-pencil-alt"></i>
                            <input type="file" ref="identity_card" @change="uploadImage" accept=".png,.jpg,.jpeg"/>
                          </div>

                        </div>
                      </div>

                    </div>
                    <div class="col-md-6">
                      <div class="d-flex justify-content-between align-content-center">
                        <div class="mb-3">
                          <div class="mb-3 font-weight-bold font-weight-bolder font-size-sm">Nature of service</div>
                          <div class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5  mb-5"
                               v-b-tooltip.hover.bottom="'Nature of service name'">
                            <i class="fas fa-book-medical mr-2 font-size-lg"></i>
                            {{
                              doctorOverView.doctor_information.nature_of_service_name ? doctorOverView.doctor_information.nature_of_service_name : 'Not yet set'
                            }}
                          </div>
                        </div>
                        <div class="mb-3">
                          <div class="mb-3 font-weight-bold font-weight-bolder font-size-sm">By profession</div>
                          <div class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5  mb-5"
                               v-b-tooltip.hover.bottom="'profession name'">
                            <i class="fas fa-book-medical mr-2 font-size-lg"></i>
                            {{
                              doctorOverView.doctor_information.profession_name ? doctorOverView.doctor_information.profession_name : 'Not yet set'
                            }}
                          </div>
                        </div>
                        <div class="mb-3 mr-10">
                          <div class="mb-3 font-weight-bold font-weight-bolder font-size-sm">Experience</div>

                          <div class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8  mb-5"
                               v-b-tooltip.hover.bottom="'Work experiences'">
                            <i class="fas fa-book-medical mr-2 font-size-lg"></i>
                            {{ doctorExprience(doctorOverView.doctor_information.work_experiences) }}
                          </div>

                        </div>
                      </div>
                      <div class="separator separator-solid">
                        <hr/>
                      </div>
                      <div class="d-flex align-items-center  mt-5 mb-5"
                           v-if="doctorOverView.is_doctor">

                        <!--begin::Item-->
                        <div class="d-flex align-items-center justify-content-between flex-lg-fill  mb-2">
                            <span class="mr-4">
                              <i
                                  class="flaticon-file-2 display-4 text-muted font-weight-bold"
                              ></i>
                            </span>
                          <div class="d-flex flex-column justify-content-between flex-lg-fill">
                            <span class="font-weight-bolder font-size-sm"> Doctor Degree </span>

                            <span
                                v-b-tooltip.hover.leftbottom="'Doctor Degree'">
                              {{ doctorOverView.doctor_degree ? doctorOverView.doctor_degree : 'not set yet' }}
                            </span>

                          </div>
                        </div>
                        <!--end::Item-->

                        <!--begin::Item-->
                        <div class="d-flex align-items-center  justify-content-end flex-lg-fill mb-2">
                            <span class="mr-4">
                              <i
                                  class="flaticon-file-2 display-4 text-muted font-weight-bold"
                              ></i>
                            </span>
                          <div class="d-flex flex-column flex-lg-fill">
                            <span class="font-weight-bolder font-size-sm"> Doctor speciality </span>


                            <span class=""
                                  v-b-tooltip.hover.leftbottom="'Doctor speciality'">
                              {{
                                doctorOverView.doctor_speciality ? doctorOverView.doctor_speciality : 'not set yet'
                              }}
                            </span>
                          </div>
                        </div>
                        <!--end::Item-->
                      </div>
                      <div class="separator separator-solid">
                        <hr/>
                      </div>
                      <div class="d-flex align-items-center justify-content-between flex-wrap mt-8"
                           v-if="doctorOverView.is_doctor">
                        <!--begin::Item-->
                        <div class="d-flex align-items-center flex-lg-fill mr-2 mb-2"
                             v-for="(service_fee, index) in  doctorOverView.doctor_information.service_fee"
                             :key="index">
                              <span class="mr-4" v-if="index ==='call_fee' || index ==='visit_fee'">
<!--                                <i class="flaticon-piggy-bank display-4 text-muted font-weight-bold"></i>-->
                                <p class="display-4 text-muted font-weight-bold payment_icon"><i
                                    class="fas fa-file-invoice-dollar"></i></p>
                              </span>
                          <div class="d-flex flex-column text-dark-75">
                              <span class="font-weight-bolder font-size-sm">
                                {{ index === 'call_fee' ? 'Virtual fee' : '' }}
                                {{ index === 'visit_fee' ? 'Visit fee' : '' }}
                                {{ index === 'report_fee' ? 'Report fee' : '' }}
                                {{ index === 'followup_fee' ? 'Followup fee' : '' }}
                              </span>
                            <span class="font-weight-bolder font-size-h5"
                                  v-if="index ==='call_fee' || index ==='visit_fee' || index=='report_fee' || index=='followup_fee'">
                                <span class="text-dark-50 font-weight-bold"> ৳ </span>
                                {{ service_fee ? service_fee : '0' }}</span>
                          </div>


                        </div>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2" v-if="doctorOverView.is_doctor">
                            <span class="mr-4">
                              <i class="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
                            </span>
                          <div class="d-flex flex-column flex-lg-fill">
                            <span class="text-dark-75 font-weight-bolder font-size-sm">Call history</span>
                            <a href="#" class="text-primary font-weight-bolder">View</a>
                          </div>
                        </div>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                            <span class="mr-4">
                              <i class="flaticon-chat-1 display-4 text-muted font-weight-bold"></i>
                            </span>
                          <div class="d-flex flex-column">
                            <span class="text-dark-75 font-weight-bolder font-size-sm">Statement</span>
                            <router-link :to="`/doctor/transaction/statement/${doctorOverView.id}`"
                                         class="text-primary font-weight-bolder">View
                            </router-link>
                          </div>
                        </div>
                        <!--end::Item-->
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <div class="col-md-12">
                      <span class="font-weight-bold font-weight-bold font-size-16">About Doctor</span> <br>
                      <span> {{
                          doctorOverView.doctor_information.about ? doctorOverView.doctor_information.about : 'Not yet set'
                        }}</span>
                    </div>
                  </div>
                  <!--  Doctor overview end    -->

                </div>
                <!--end::Info-->
              </div>
              <!--end::Details-->
            </div>

          </div>
        </div>
        <!--end::Card-->
      </div>
    </div>
    <!--  Doctor overview End -->
  </div>
</template>

<script>
import axios from "axios";
import {ORKO_PROFESSION} from "../../../api/ApiUrl";

export default {
  name: "DoctorOverview",
  props: {
    doctorOverView: {},
  },
  data() {
    return {
      doctor_overview_form: {
        nature_of_service_id: '',
        doctor_degree: '',
        doctor_speciality: '',
        visit_fee: '',
        call_fee: '',
        followup_fee: '',
        report_fee: '',
        about: '',
        profession_id: '',
      },
      gender: [
        {
          id: 'male',
          value: 'male',
          text: 'male'
        },
        {
          id: 'female',
          value: 'female',
          text: 'female'
        }
      ],
      nature_of_services: [],
      modal_loading: false,
      image_show: false,
      error_msg: '',
      serviceState: null,
      by_professions: [],
      speialitiests: [],
    }
  },
  created() {
    this.getNatureOfService();
  },
  computed: {},
  methods: {
    resetModal() {
      this.departmentState = null
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.saveDoctorOverView()
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.serviceState = valid;
    },
    saveDoctorOverView() {
      // if(this.serviceState == null){
      //   return
      // }
      this.$nextTick(() => {
        let form = new FormData();
        if (this.doctor_overview_form.about) {
          form.append('about', this.doctor_overview_form.about);
        }

        if (this.doctor_overview_form.nature_of_service_id) {
          form.append('service_id', this.doctor_overview_form.nature_of_service_id);
        }
        if (this.doctor_overview_form.call_fee) {
          form.append('virtual_fee', this.doctor_overview_form.call_fee);
        }
        if (this.doctor_overview_form.visit_fee) {
          form.append('visit_fee', this.doctor_overview_form.visit_fee);
        }
        if (this.doctor_overview_form.report_fee) {
          form.append('report_fee', this.doctor_overview_form.report_fee);
        }
        if (this.doctor_overview_form.followup_fee) {
          form.append('followup_fee', this.doctor_overview_form.followup_fee);
        }

        if (this.doctor_overview_form.profession_id) {
          form.append('profession_id', this.doctor_overview_form.profession_id);
        }
        if (this.doctor_overview_form.doctor_degree) {
          form.append('degree_id', this.doctor_overview_form.doctor_degree);
        }

        axios
            .post(`${process.env.VUE_APP_URL}/api/v1/doctor/profile/overview/update?user_id=${this.doctorOverView.id}&api_token=${localStorage.getItem('api_token')}&platform=backoffice`, form)
            .then(response => {

              if (response.data.status_code == 400) {
                return this.$snotify.error(response.data.message);
              }
              this.resetModal();
              this.$emit('doctorOverviewUpdate')
              this.$bvModal.hide('doctorOverViewModal')
              this.$snotify.success('Doctor overview  Successfully updated');

            })


      })
    },
    doctorSignature(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      }
      return process.env.BASE_URL + 'media/logos/logo.svg';
    },
    doctorExprience(exp) {
      if (exp) {
        if (exp > 1) {
          return exp + "+ years"
        } else {
          return "les than 1 years"
        }
      } else {
        return "0 years"
      }
    },
    getNatureOfService() {
      this.modal_loading = true;
      axios.get(`${process.env.VUE_APP_URL}/api/v1/medical/services/list?for_whom=doctor`)
          .then(response => {
            this.modal_loading = false;
            this.nature_of_services = response.data.data;
          })
    },
    async getProfetional() {
      this.modal_loading = true;
      await axios.get(ORKO_PROFESSION)
          .then(response => {
            this.modal_loading = false;
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.by_professions = response.data.data
            }
          })
    },
    getSpeialitiesist() {
      axios.get(`${process.env.VUE_APP_URL}/api/v1/speciality?show_all=1&api_token=${localStorage.getItem('api_token')}`)
          .then(response => {

            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.speialitiests = response.data.data;
              console.log('sss', response.data.data)
              this.loading = false;
            }
          })
    },
    editDoctorOverviewModal() {
      this.getNatureOfService();
      this.getProfetional();
      this.getSpeialitiesist();
      this.doctor_overview_form.about = this.doctorOverView.doctor_information.about;
      if (this.doctorOverView.doctor_information.nature_of_service_id) {
        this.doctor_overview_form.nature_of_service_id = this.doctorOverView.doctor_information.nature_of_service_id;
      }
      this.doctor_overview_form.profession_id = this.doctorOverView.doctor_information.profession_id;
      this.doctor_overview_form.call_fee = this.doctorOverView.doctor_information.service_fee.call_fee;
      this.doctor_overview_form.visit_fee = this.doctorOverView.doctor_information.service_fee.visit_fee;
      this.doctor_overview_form.report_fee = this.doctorOverView.doctor_information.service_fee.report_fee;
      this.doctor_overview_form.followup_fee = this.doctorOverView.doctor_information.service_fee.followup_fee;

      this.$bvModal.show('doctorOverViewModal');
    },
    showImage(img) {
      const h = this.$createElement
      const messageVNode = h('div', {class: ['foobar']}, [
        h('b-img', {
          props: {
            src: `${process.env.VUE_APP_API_URL}/${img}`,
            thumbnail: true,
            center: false,
          }
        })
      ])
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        // title: [titleVNode],
        buttonSize: 'sm',
        centered: true, size: 'lg'
      })
    },
    uploadImage() {
      // const file = e.target.files[0];
      let identity_card = this.$refs.identity_card.files[0];
      let signature = this.$refs.signature.files[0];
      let form = new FormData();
      if (identity_card) {
        form.append("identity_card_image", identity_card);
      } else {
        form.append("signature", signature);
      }
      axios
          .post(`${process.env.VUE_APP_URL}/api/v1/doctor/profile/overview/update?api_token=${this.doctorOverView.api_token}&user_id=${this.doctorOverView.id}&platform=backoffice`, form)
          .then(response => {
            if (response.data.status_code == 400) {
              return this.$snotify.error(response.data.message);
            }
            this.$emit('doctorOverviewUpdate')
            this.$snotify.success('Identity card successfully updated');

          })
    }
  }
}
</script>

<style scoped>
.singature_image {
  height: 200px;
  width: 400px;
  object-fit: fill;
  object-position: center;
  cursor: pointer;
}

.invalid-feedback {
  display: block;
}

.file-upload {
  position: absolute;
  right: 30px;
  top: 50px;
  cursor: pointer;
}

.file-upload i {
  font-size: 20px;
  color: #fff;
  background: rgba(0, 0, 0, .7);
  position: absolute;
  right: 12%;
  top: 25%;
  padding: 10px;
  z-index: 0;
  border-radius: 20px;
  cursor: pointer;
}

.file-upload input {
  position: absolute;
  height: 50px;
  width: 50px;
  right: 10%;
  z-index: 1;
  background: transparent;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  cursor: pointer;
}

.file-upload img {
  height: 70px;
  width: 70px;
  margin: 15px;
  cursor: pointer;
}

.custom-badge {
  white-space: inherit;
}

.payment_icon {
  transform: scale(1.8);
  margin-bottom: 25px;
  margin-right: 10px;
}
</style>
