<template>
  <div>

    <div class="star-rating">
      <label
          class="star-rating__star" v-for="rating in ratings"
          :class="{'is-selected': ((value >= rating) && value != null), 'is-disabled': disabled}"
          v-on:click="set(rating)"
          v-on:mouseover="star_over(rating)"
          v-on:mouseout="star_out">

        <input
            class="star-rating star-rating__checkbox"
            type="radio"
            :value="rating"
            :name="name"
            v-model="value"
            :disabled="disabled">

        <i class="fas fa-star color-yellow" v-if="value >= rating && value != null"></i>
        <i class="far fa-star color-yellow" v-else></i>

      </label>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  data() {
    return {
      temp_value: null,
      ratings: [1, 2, 3, 4, 5]
    }
  },
  props: {
    'name': String,
    'value': null,
    'id': String,
    'disabled': Boolean,
    'required': Boolean
  },

  methods: {
    star_over(index) {
      var self = this;

      if (!this.disabled) {
        this.temp_value = this.value;
        return this.value = index;
      }

    },

    star_out() {
      var self = this;

      if (!this.disabled) {
        return this.value = this.temp_value;
      }
    },

    set(value) {
      var self = this;

      if (!this.disabled) {
        this.temp_value = value;
        return this.value = value;
      }
    }
  },
}
</script>

<style scoped>
.star-rating__checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.star-rating__star {
  display: inline-block;
  /*padding: 3px;*/
  vertical-align: middle;
  /*line-height: 1;*/
  font-size: 1.5em;
  margin: 0;
  color: #ababab;
  transition: color 0.2s ease-out;
}
.star-rating__star:hover {
  cursor: pointer;
}
.star-rating__star.is-selected {
  color: #f26f24;
}
.star-rating__star.is-disabled:hover {
  cursor: default;
}
.color-yellow {
  color: #f26f24;
  font-size: 10px;
}
</style>