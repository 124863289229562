import axios from "axios";

export const TelemedicineMixin = {
    props: {
        workplaces: {},
        doctor_id: {},
        doctor_api_token: {},
    },

    created() {
        this.todayDate();
        this.getChamberInfo();
        this.getChamber();
    },

    data() {
        return {
            medical_registration_authority: {},
            days_week: [
                {
                    text: 'Monday',
                    value: 'monday',
                    sl: '1',
                },
                {
                    text: 'Tuesday',
                    value: 'tuesday',
                    sl: '2',
                },
                {
                    text: 'Wednesday',
                    value: 'wednesday',
                    sl: '3',
                },
                {
                    text: 'Thursday',
                    value: 'thursday',
                    sl: '4',
                },
                {
                    text: 'Friday',
                    value: 'friday',
                    sl: '5',
                },
                {
                    text: 'Saturday',
                    value: 'saturday',
                    sl: '6',
                },
                {
                    text: 'Sunday',
                    value: 'sunday',
                    sl: '7',
                },
            ],
            chambers: [],
            visit: {
                date: null,
            },
            form: {
                sunday_time_slot: '',
                monday_time_slot: '',
                tuesday_time_slot: '',
                wednesday_time_slot: '',
                thursday_time_slot: '',
                friday_time_slot: '',
                saturday_time_slot: '',

                sunday_start_slot: [],
                sunday_end_slot: [],

                monday_start_slot: [],
                monday_end_slot: [],

                tuesday_start_slot: [],
                tuesday_end_slot: [],

                wednesday_start_slot: [],
                wednesday_end_slot: [],

                thursday_start_slot: [],
                thursday_end_slot: [],

                friday_start_slot: [],
                friday_end_slot: [],

                saturday_start_slot: [],
                saturday_end_slot: [],

            },
            today: null,
            faciliti: '',
            sunday: [],
            monday: [],

            tuesday: [],

            wednesday: [],

            thursday: [],

            friday: [],

            saturday: [],


            item: {},
            sunday_activities: [],
            time_slots: '',
            final_work_days: '',
            is_day_validation_pass: false,

            chamberName: null,
            chamber: {
                institute_id: '',
                address: '',
                contact_no: '',
                time_slot: '',
            },
            activeBtn: '',
            myToggle: false,

            weeks: [
                {caption: 'Sun', state: false},
                {caption: 'Mon', state: false},
                {caption: 'Tue', state: false},
                {caption: 'Wed', state: false},
                {caption: 'Thu', state: false},
                {caption: 'Fri', state: false},
                {caption: 'Sat', state: false},
            ],

            chamber_is_sunday: false,
            chamber_is_monday: false,
            chamber_is_tuesday: false,
            chamber_is_wednesday: false,
            chamber_is_thursday: false,
            chamber_is_friday: false,
            chamber_is_saturday: false,
            institutes: [],

            telimedicine: {
                time_slot: '',
                primary_contact_number: '',
                second_contact_number: '',
                is_active: '',
            },

            is_edit: false,

            activeOptions: [
                {value: '', text: 'Select Status'},
                {value: 1, text: 'Active'},
                {value: 0, text: 'In-Active'},
            ],

            telimidicine_chambers: [],

            telemedicine_fields: [
                {
                    label: 'Day',
                    key: 'day',
                },
                {
                    label: 'Chamber Start Time',
                    key: 'chamber_start_time'
                },
                {
                    label: 'Chamber Close Time',
                    key: 'chamber_close_time',
                },
                {
                    label: 'Time Per Slot',
                    key: 'time_per_slot',
                },
            ]
        }
    },

    methods: {
        getChamberInfo() {
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/doctor/chamber/list?api_token=${localStorage.getItem('api_token')}&schedule_date=${this.today}&doctor_id=${this.doctor_id}&platform=backoffice`)
                .then(res => {
                    this.chambers = res.data.data;
                })
        },

        getChamber() {
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/doctor/chamber/list?api_token=${localStorage.getItem('api_token')}&doctor_id=${this.doctor_id}&show_all=1&platform=backoffice`)
                .then(res => {
                    this.telimidicine_chambers = res.data.data;
                })
        },

        resetModal() {
            this.chamber = {};
            this.sunday = [];
            this.monday = [];
            this.saturday = [];
            this.tuesday = [];
            this.wednesday = [];
            this.thursday = [];
            this.friday = [];
            this.is_edit = false;
            this.chamber_is_sunday = false;
            this.chamber_is_monday = false;
            this.chamber_is_tuesday = false;
            this.chamber_is_wednesday = false;
            this.chamber_is_thursday = false;
            this.chamber_is_friday = false;
            this.chamber_is_saturday = false;
            this.telimedicine = {};
            this.form = {};
            this.time_slots = '';
            this.final_work_days = '';
            // this.workplaces = {};
        },

        todayDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = yyyy + '-' + mm + '-' + dd;
            this.today = today;
        },

        facilitis(items) {
            this.faciliti = items.split(',');
        },

        addEditTelemedicinne() {
            this.is_edit = true;
            this.$bvModal.show('addEditModal');
            let first = this.workplaces[0].chamber_schedules.slice(0, 1).shift();
            this.telimedicine.time_slot = first ? first.time_per_slot : '';
            this.telimedicine.is_active = this.workplaces[0].is_active;
            this.telimedicine.primary_contact_number = this.workplaces[0].primary_contact_no ? this.workplaces[0].primary_contact_no : '';
            this.telimedicine.second_contact_number = this.workplaces[0].secondary_contact_no ? this.workplaces[0].secondary_contact_no : '';
            // workpleace

            this.workplaces.map(chamber => {
                if (chamber.name == 'Telemedicine') {
                    chamber.chamber_schedules.map(item => {

                        if (item.day == 'sunday') {
                            this.chamber_is_sunday = true;
                            this.weeks[0].state = true;
                            this.sunday.push(
                                {
                                    sunday_start_time: item.chamber_start_time,
                                    sunday_end_time: item.chamber_close_time,
                                }
                            )
                        }

                        if (item.day == 'monday') {
                            this.chamber_is_monday = true;
                            this.weeks[1].state = true;
                            this.monday.push(
                                {
                                    monday_start_time: item.chamber_start_time,
                                    monday_end_time: item.chamber_close_time,
                                }
                            )
                        }

                        if (item.day == 'tuesday') {
                            this.chamber_is_tuesday = true;
                            this.weeks[2].state = true;
                            this.tuesday.push(
                                {
                                    tuesday_start_time: item.chamber_start_time,
                                    tuesday_end_time: item.chamber_close_time,
                                }
                            )
                        }

                        if (item.day == 'wednesday') {
                            this.chamber_is_wednesday = true;
                            this.weeks[3].state = true;
                            this.wednesday.push(
                                {
                                    wednesday_start_time: item.chamber_start_time,
                                    wednesday_end_time: item.chamber_close_time,
                                }
                            )
                        }

                        if (item.day == 'thursday') {
                            this.chamber_is_thursday = true;
                            this.weeks[4].state = true;
                            this.thursday.push(
                                {
                                    thursday_start_time: item.chamber_start_time,
                                    thursday_end_time: item.chamber_close_time,
                                }
                            )
                        }

                        if (item.day == 'friday') {
                            this.chamber_is_friday = true;
                            this.weeks[5].state = true;
                            this.friday.push(
                                {
                                    friday_start_time: item.chamber_start_time,
                                    friday_end_time: item.chamber_close_time,
                                }
                            )
                        }

                        if (item.day == 'saturday') {
                            this.chamber_is_saturday = true;
                            this.weeks[6].state = true;
                            this.saturday.push(
                                {
                                    saturday_start_time: item.chamber_start_time,
                                    saturday_end_time: item.chamber_close_time,
                                }
                            )
                        }
                    })

                }
            })

        },

        AddItem() {
            this.sunday.push({
                sunday_start_time: '',
                sunday_end_time: '',
            });
        },

        AddMondayItem() {
            this.monday.push({
                monday_start_time: '',
                monday_end_time: '',
            });
        },

        AddTuesdayItem() {
            this.tuesday.push({
                tuesday_start_time: '',
                tuesday_end_time: '',
            });
        },

        AddWednesdayItem() {
            this.wednesday.push({
                wednesday_start_time: '',
                wednesday_end_time: '',
            });
        },

        AddThursdayItem() {
            this.thursday.push({
                thursday_start_time: '',
                thursday_end_time: '',
            });
        },

        AddFridayItem() {
            this.friday.push({
                friday_start_time: '',
                friday_end_time: '',
            });
        },

        AddSaturdayItem() {
            this.saturday.push({
                saturday_start_time: '',
                saturday_end_time: '',
            });
        },

        removeItem(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.sunday.findIndex(
                u => u === item
            );
            this.sunday.splice(index, 1);
        },

        removeMonday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.monday.findIndex(
                u => u === item
            );
            this.monday.splice(index, 1);
        },

        removeTuesday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.tuesday.findIndex(
                u => u === item
            );
            this.tuesday.splice(index, 1);
        },

        removeWednesday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.wednesday.findIndex(
                u => u === item
            );
            this.wednesday.splice(index, 1);
        },

        removeThursday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.thursday.findIndex(
                u => u === item
            );
            this.thursday.splice(index, 1);
        },

        removeFriday(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.friday.findIndex(
                u => u === item
            );
            this.friday.splice(index, 1);
        },

        removeSaturDay(item) {
            // this.sunday.splice(this.sunday, 1)
            let index = this.saturday.findIndex(
                u => u === item
            );
            this.saturday.splice(index, 1);
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.updateDoctorTimeSlot()
        },

        removeComma(given_str) {
            return given_str.replace(/(^,)|(,$)/g, "");
        },

        updateDoctorTimeSlot() {

            // Sunday
            const sun_start_time = this.sunday.map(item => item.sunday_start_time);
            const sun_end_time = this.sunday.map(item => item.sunday_end_time);

            // monday
            const mon_start_time = this.monday.map(item => item.monday_start_time);
            const mon_end_time = this.monday.map(item => item.monday_end_time);

            // Tuesday
            const tues_start_time = this.tuesday.map(item => item.tuesday_start_time);
            const tues_end_time = this.tuesday.map(item => item.tuesday_end_time);

            // Wednesday
            const wed_start_time = this.wednesday.map(item => item.wednesday_start_time);
            const wed_end_time = this.wednesday.map(item => item.wednesday_end_time);

            // Thursday
            const thurs_start_time = this.thursday.map(item => item.thursday_start_time);
            const thurs_end_time = this.thursday.map(item => item.thursday_end_time);

            // Friday
            const fri_start_time = this.friday.map(item => item.friday_start_time);
            const fri_end_time = this.friday.map(item => item.friday_end_time);

            // Saturday
            const sat_start_time = this.saturday.map(item => item.saturday_start_time);
            const sat_end_time = this.saturday.map(item => item.saturday_end_time);

            let form = new FormData();

            if (sun_start_time.length && sun_end_time.length) {
                form.append("chamber[0][start_time][sunday]", sun_start_time.join(','));
                form.append("chamber[0][end_time][sunday]", sun_end_time.join(','));
                this.final_work_days += "sunday,";
                this.time_slots += this.telimedicine.time_slot + ',';

            }

            if (mon_start_time.length && mon_end_time.length) {
                form.append("chamber[0][start_time][monday]", mon_start_time.join(','));
                form.append("chamber[0][end_time][monday]", mon_end_time.join(','));
                this.final_work_days += "monday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (tues_start_time.length && tues_end_time.length) {
                form.append("chamber[0][start_time][tuesday]", tues_start_time.join(','));
                form.append("chamber[0][end_time][tuesday]", tues_end_time.join(','));
                this.final_work_days += "tuesday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (wed_start_time.length && wed_end_time.length) {
                form.append("chamber[0][start_time][wednesday]", wed_start_time.join(','));
                form.append("chamber[0][end_time][wednesday]", wed_end_time.join(','));
                this.final_work_days += "wednesday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (thurs_start_time.length && thurs_end_time.length) {
                form.append("chamber[0][start_time][thursday]", thurs_start_time.join(','));
                form.append("chamber[0][end_time][thursday]", thurs_end_time.join(','));

                this.final_work_days += "thursday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (fri_start_time.length && fri_end_time.length) {
                form.append("chamber[0][start_time][friday]", fri_start_time.join(','));
                form.append("chamber[0][end_time][friday]", fri_end_time.join(','));

                this.final_work_days += "friday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            if (sat_start_time.length && sat_end_time.length) {
                form.append("chamber[0][start_time][saturday]", sat_start_time.join(','));
                form.append("chamber[0][end_time][saturday]", sat_end_time.join(','));

                this.final_work_days += "saturday,";
                this.time_slots += this.telimedicine.time_slot + ',';
            }

            form.append("chamber[0][type]", "telemedicine");
            // if (this.telimedicine.is_active) {
            form.append("chamber[0][is_active]", this.telimedicine.is_active);
            // }
            if (this.telimedicine.second_contact_number) {
                form.append("chamber[0][secondary_contact_no]", this.telimedicine.second_contact_number);
            }
            if (this.telimedicine.primary_contact_number) {
                form.append("chamber[0][primary_contact_no]", this.telimedicine.primary_contact_number);
            }

            form.append("chamber[0][institute_id]", "712");
            form.append("chamber[0][institute_type_id]", "55");
            form.append("chamber[0][slot]", this.removeComma(this.time_slots));
            form.append("chamber[0][working_day]", this.removeComma(this.final_work_days));


            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/doctor/chamber/add?api_token=${localStorage.getItem('api_token')}&doctor_id=${this.doctor_id}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            this.$snotify.error(response.data.message);
                        } else {
                            this.$root.$emit('change_telemedicine_value')
                            this.$bvModal.hide('addEditModal');
                            this.$snotify.success('Successfully updated');
                        }
                    })
            })
        },

        clickDay(day) {
            if (day === 'Sun') {
                this.chamber_is_sunday = true;
                this.sunday = [
                    {
                        sunday_start_slot: '',
                        sunday_end_slot: ''
                    }
                ]
            }
            if (day == 'Mon') {
                this.chamber_is_monday = true;
            }
            if (day == 'Tue') {
                this.chamber_is_tuesday = true;
            }
            if (day == 'Wed') {
                this.chamber_is_wednesday = true;
            }
            if (day == 'Thu') {
                this.chamber_is_thursday = true;
            }
            if (day == 'Fri') {
                this.chamber_is_friday = true;
            }
            if (day == 'Sat') {
                this.chamber_is_saturday = true;
            }
        },

        groupBy(xs, key) {
            return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
    },
}
