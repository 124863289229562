<template>
  <div>
    <b-media tag="li">
      <template #aside>
        <b-img :src="userPhoto(image)"
               class="user_image"
               alt=""
               v-if="image"></b-img>
        <img src="../../assets/img/blank.png" class="user_image" v-else/>
      </template>
      <h5 class="mt-0 mb-1">
        <router-link :to="`${user_id != undefined ? '/user/details/'+user_id : '#' }`">{{ name ? name : '-' }}</router-link>
      </h5>
      <ul class="mb-0 user_info">
        <li>{{ phone_number ? phone_number : '-' }}</li>
        <slot></slot>
      </ul>
    </b-media>
  </div>
</template>

<script>
export default {
  name: "UserCard",

  props: {
    image: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    user_id: {
      type: Number,
      required: false
    },
    phone_number: {
      type: String,
      required: false
    }
  },
  methods: {
    userPhoto(img) {
      if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return process.env.BASE_URL + 'media/users/blank.png';
      }
    },
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  transition: .5s;
}

.user_image:hover {
  transform: scale(1.5);
  transition: .5s;
}

</style>