<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h2>Purchased subscription list</h2>

        <!--    Search bar    -->
        <div class="row mt-5 justify-content-between">
          <div class="col-md-1">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-7 text-center">
            <div>
              <download-excel
                  :data="items"
                  class="btn btn-primary"
                  :fields="json_fields"
                  worksheet="Subscription offer"
                  name="Subscription offer">
                <i class="fas fa-file-excel"></i> Export Excel
              </download-excel>
            </div>
          </div>
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="primary">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>
        </div>
        <!--  Table -->
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                responsive
                class=" mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(user)="row">
                <div>
                  <b-media tag="li" class="d-flex align-items-center">
                    <template #aside>

                      <b-img v-if="row.item.user_image != '/storage/'" blank-color="#ccc"
                             :src="userPhoto(row.item.user_image)" width="80" thumbnail></b-img>
                      <b-img v-else blank blank-color="#ccc" width="80"></b-img>
                    </template>
                    <h5 class="mt-0 mb-1">{{ row.item.user }}</h5>
                    <p class="mb-0">{{ row.item.user_phone }}</p>
                  </b-media>

                </div>
              </template>

              <template #cell(subscription_package)="data">
                <p class="mb-0">{{ data.item.subscription_package ? data.item.subscription_package.title : '' }}</p>
              </template>

              <template #cell(is_paid)="data">
                <p :class="`badge badge-${data.item.is_paid == 1 ? 'success' : 'danger'}`">
                  {{ data.item.is_paid == 1 ? 'Paid' : 'Unpaid' }}</p>
              </template>

              <template #cell(expiry_date)="row">
                <p>
                  {{ row.item.expiry_date | moment("ddd, MMM Do YYYY, h:mm:ss a") }}
                </p>
              </template>

              <template #cell(created_date)="row">
                <p>
                  {{ row.item.created_date | moment("ddd, MMM Do YYYY, h:mm:ss") }}
                </p>
              </template>

              <template #cell(actions)="row">
                <div>
                  <b-dropdown id="dropdown-dropleft" no-caret dropleft text="Drop-top" variant="primary"
                              class="m-2">

                    <template #button-content>
                      <i class="fas fa-ellipsis-v"></i>
                    </template>

                    <div>
                      <b-dropdown-item href="#" @click="viewAction(row)">
                        <span class="pr-4"><i class="fas fa-eye"></i></span>View
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>

            </b-table>
          </div>
        </div>
        <b-modal id="viewDetails" size="xl" title="Purchased subscription details">

          <div class="row">
            <div class="col-md-12">
              <div class="card card-custom gutter-b">
                <div class="card-body">
                  <h3 class="font-weight-bold  text-center mb-3">
            <span :class="`badge badge-${single_item.is_paid == 1 ? 'success' : 'danger'}`">{{
                single_item.is_paid == 1 ? 'Paid' : 'Unpaid'
              }}</span>
                  </h3>
                  <!--begin::Details-->
                  <div class="d-flex justify-content-between align-items-center">

                    <!--begin: Pic-->
                    <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                      <div class="symbol symbol-50 symbol-lg-120">
                        <img :src="userPhoto(single_item.user_image)" alt="image"/>

                      </div>
                    </div>
                    <!--end::Pic-->

                    <div class="flex-grow-1">
                      <!--begin::Title-->
                      <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex mr-3">
                          <a
                              href="#"
                              class="text-dark-75  font-size-h5 font-weight-bold mr-3">
                            <span class="font-size-h3">{{ single_item.user }}</span>
                            <br>
                            <span class="font-size-h6">
                              {{
                                single_item.user_phone
                              }}
                            </span>
                            <br>
                            <span class="font-size-h6 text-muted">
                              {{
                                single_item.transaction_id
                              }}
                            </span>
                          </a>

                        </div>
                      </div>
                      <!--end::Title-->

                      <!--begin::Content-->
                      <div class="d-flex flex-wrap justify-content-between mt-1">
                        <div class="d-flex flex-column flex-grow-1 pr-8">
                          <!--                    <div class="d-flex flex-wrap mb-4">-->
                          <div>
                            <div class="row">
                              <div class="col-md-10">
                                <div class="d-flex flex-wrap">
                                  <span
                                      class="font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5">
                                    Created date <br>

                                     {{ single_item.created_date | moment("ddd, MMM Do YYYY, h:mm:ss a") }}
                                  </span>

                                  <span
                                      class="font-weight-bold mr-lg-8 mr-5 mb-lg-0 my-5"
                                  >Expiry date <br>

                                    {{ single_item.expiry_date | moment("ddd, MMM Do YYYY, h:mm:ss a") }}
                                  </span>


                                </div>
                              </div>

                            </div>

                          </div>
                          <!--                    </div>-->
                        </div>
                      </div>


                    </div>

                    <div class="div">
                      <div class="d-flex flex-wrap justify-content-between mt-1">
                        <div class="d-flex flex-wrap">

                          <div>
                            <p class="mb-0 text-muted">Subscription fee</p>
                            <h1 class="display-2">{{ single_item.actual_amount }} BDT</h1>
                          </div>

                        </div>
                      </div>
                    </div>

                  </div>
                  <!--end::Details-->
                </div>
              </div>
            </div>
          </div>

          <div v-if="single_item.subscription_package">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card bg-white">
                      <div class="card-body">
                        <h1>{{ single_item.subscription_package.title }}</h1>
                        <p class="mb-10">{{ single_item.subscription_package.slug }}</p>

                        <h4 class="mb-4">
                          Description : {{
                            single_item.subscription_package.description ? single_item.subscription_package.description : 'n/a'
                          }}
                        </h4>

                        <h4 class="mb-4">Discount :
                          {{
                            single_item.subscription_package.discount ? single_item.subscription_package.discount : 'n/a'
                          }}
                        </h4>
                        <h4 class="mb-4">Expiry duration :  {{
                            single_item.subscription_package.expiry_duration ? single_item.subscription_package.expiry_duration : 'n/a'
                          }} {{
                            single_item.subscription_package.package_duration_type ? single_item.subscription_package.package_duration_type : 'n/a'
                          }}</h4>

                        <h4 class="mb-4">Price :
                          {{ single_item.subscription_package.price ? single_item.subscription_package.price : 'n/a' }}
                        </h4>
                        <h4 class="mb-4">
                          Status :
                          <span :class="`badge badge-${single_item.subscription_package.status == 1 ? 'success' : 'danger'}`">
                          {{
                            single_item.subscription_package.status == 1 ? 'Active' : 'Inactive'
                          }}
                            </span>

                        </h4>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="row" v-if="single_item.subscription_details">
                  <div class="col-md-12">

                    <h5>Subscription features</h5>
                    <div class="card my-5 bg-white" v-for="(item, index) in single_item.subscription_details"
                         :key="index">
                      <div class="card-body">
                        <h4> {{ item.subscription_feature.title }} <span
                            :class="`badge badge-${item.subscription_feature.status == 1 ? 'success' : 'danger'}`"
                            class="mx-10">{{ item.subscription_feature.status == 1 ? 'Active' : 'Inactive' }}</span>
                        </h4>
                        <p class="text-muted">{{ item.subscription_feature.created_at }}</p>
                        <p class="mb-0 text-muted">{{ item.subscription_feature.slug }}</p>
                        <br>
                        <p class="mb-0">{{ item.subscription_feature.description }}</p>

                        <div class="mt-5">
                      <span class="badge badge-success">

                        {{ item.achieved_target }}
                        <br>
                         <span>Achieved</span>
                      </span>
                          <span class="badge badge-warning mx-3">
                        {{ item.subscription_feature.target_count }}
                      <br>
                         <span>Target count</span>
                      </span>
                          <span class="badge badge-danger">
                        {{ item.subscription_feature.per_target_cost }}
                        <br>
                         <span>Per target cost</span>
                      </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

        </b-modal>
        <!--  Pagination -->
        <div class="row mt-3" v-if="items.length">
          <div class="col-md-12">
            <b-pagination
                v-model="currentPage"
                :total-rows="items.length"
                :per-page="perPage"
                size="lg"
                class="my-0"
            ></b-pagination>
          </div>
        </div>

      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import {SubscriberUser} from "../../mixins/subscription/SubscriberUser";

export default {
  name: "SubscriberUsers",
  mixins: [SubscriberUser]
}
</script>

<style scoped>
.user_image {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
  text-align: center;
}

</style>