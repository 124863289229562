import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {jsPDF} from "jspdf";
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas'
import VueHtml2pdf from 'vue-html2pdf'

export const DoctorTransactionStatementMixin = {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        // eslint-disable-next-line vue/no-unused-components
        VueHtml2pdf,
    },

    data() {
        return {
            pdf: false,
            downloadLoading: true,
            filename: '',
            autoWidth: true,
            bookType: 'xlsx',
            columns: [
                {
                    key: 'id',
                    label: 'Id',
                },
                {
                    key: 'fullname',
                    label: 'Fullname',
                },
                {
                    key: 'slug',
                    label: 'Slug',
                },
                {
                    key: 'phone_number',
                    label: 'Phone number',
                },
                {
                    key: 'age',
                    label: 'Age',
                },
                {
                    key: 'wallet',
                    label: 'Wallet',
                },
                {
                    key: 'action',
                    label: 'Action'
                }
            ],
            loading: true,
            currentPage: 1,
            // perPage: 20,
            // pageOptions: [10, 20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            localActive: false,
            items: [],
            fields: [
                {
                    key: 'transactions',
                    label: 'Transaction date',
                    sortable: true
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true
                },
                {
                    key: 'debit',
                    label: 'Debit',
                    sortable: true
                },
                {
                    key: 'credit',
                    label: 'Credit',
                    sortable: true
                },
                {
                    key: 'balance',
                    label: 'Balance',
                    sortable: true
                },


            ],
            filter: null,
            filterOn: [],
            filters: {
                transaction_date: '',
                description: '',
                debit: '',
                credit: '',
                balance: '',
            },
            startDate: null,
            endDate: null,
            show: true,
            base_url: process.env.VUE_APP_API_URL,
            response_data: [],
            user: {},
            prevRoute: null,
            html_options: {
                margin: [0, 0, 0, 0],
                pagebreak: {avoid: 'tr'},
                filename: this.user ? this.user.fullname : 'Transaction statement',
            }
        }
    },
    metaInfo: {
        title: 'Orko Health Ltd | Transaction Statement',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from.path
        })
    },
    mounted() {
        this.getTransactionStatement();
        this.getUserData();

        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Doctor Transaction Statement", route: "/doctor-wise-doctor-list"},
        ]);

        VueHtml2pdf().set({
            pagebreak: {avoid: 'tr'}
        });
    },

    computed: {
        rows() {
            return this.users.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {

            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.items.filter(item => {
                const itemDate = new Date(item.transactions.transaction_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                transaction_date: '',
                description: '',
                debit: '',
                credit: '',
                balance: '',
            }]
        },
        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.items.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.registration_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
        totalDebit() {
            if (this.items) {
                let debitTotal = this.items.reduce(function (sum, item) {
                    return sum += item.debit;
                }, 0);
                return debitTotal
            }
            return 0;
        },
        totalCredit() {
            if (this.items) {
                let creditTotal = this.items.reduce(function (sum, item) {
                    return sum += item.credit;
                }, 0);
                return creditTotal;
            }

            return 0;
        },
        totalBalance() {

            if (this.items) {
                let balanceTotal = this.items.reduce(function (sum, item) {
                    return sum += item.balance;
                }, 0);
                return balanceTotal;
            }
            return 0;
        },
    },
    methods: {
        async getTransactionStatement() {
            this.loading = true;
            await axios.get(`${process.env.VUE_APP_URL}/api/v1/report/user/transaction/statement?user_type=doctor&from_date=&to_date=&user_id=${this.$route.params.id}&api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    this.items = response.data.data.transactions
                    this.loading = false;
                })
        },
        async getUserData() {
            await axios.get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {

                    this.user = response.data.data
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return '../../../public/media/users/blank.png'
            }
        },
        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
        formatDate(date) {
            return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
        },
        pdfDownloadLink() {
            window.html2canvas = html2canvas;
            const doc = new jsPDF()
            autoTable(doc, {html: '#table'})
            doc.save('table.pdf')
            // doc.html(document.querySelector("#download"), {
            //   callback: function (pdf) {
            //     pdf.save("statement.pdf")
            //   }
            // })
        },

        generateReport() {
            this.$refs.html2Pdf.generatePdf()
        },

        async beforeDownload({html2pdf, options, pdfContent}) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        }

    }
}
