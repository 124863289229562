import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export const MedicineCompanyMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
                "Company Address": {
                    field: "company_address",
                },
                "Man lic bio": {
                    field: "man_lic_bio",
                },
                "Man lic non bio": {
                    field: "man_lic_non_bio",
                },
                "Company type": {
                    field: "company_type",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Company address',
                    key: 'company_address',
                    sortable: true,
                },
                {
                    label: 'Company type',
                    key: 'company_type',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
                address: '',
                man_lic_bio: '',
                man_lic_non_bio: '',
                company_type: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            addressState: null,
            typeState: null,
            form: {
                title: '',
                address: '',
                man_lic_bio: '',
                man_lic_non_bio: '',
                company_type: '',
            },
            edit: false,
            edit_id: '',
            company_type: [
                {
                    text: 'manufacturer',
                    value: 'manufacturer',
                },
                {
                    text: 'importer',
                    value: 'importer',
                },
                {
                    text: 'both',
                    value: 'both',
                },
            ],
            loading: true,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Medicine company", route: "/medicine-company"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 11)
        this.$root.$emit('call-active-menu', 11);
        this.getMedicineClass();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Medicine company',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getMedicineClass() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/medicine/company/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            this.addressState = valid
            this.typeState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.titleState = null;
            this.addressState = null;
            this.typeState = null;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.edit) {
                this.updateData();
            } else {
                this.handleSubmit();
            }

        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);
            form.append("company_address", this.form.address);
            form.append("company_type", this.form.company_type);
            form.append("man_lic_bio", this.form.man_lic_bio);
            form.append("man_lic_non_bio", this.form.man_lic_non_bio);
            form.append("created_by", localStorage.getItem('user_id'));

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medicine/company/create?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicineClass();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        updateData() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            let update_form = new FormData();
            update_form.append("title", this.form.title);
            update_form.append("company_address", this.form.address);
            update_form.append("company_type", this.form.company_type);
            update_form.append("man_lic_bio", this.form.man_lic_bio);
            update_form.append("man_lic_non_bio", this.form.man_lic_non_bio);
            update_form.append("created_by", localStorage.getItem('user_id'));

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/medicine/company/update/${this.edit_id}?api_token=${localStorage.getItem('api_token')}&_method=put`, update_form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getMedicineClass();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully updated');
                    })
            })
        },
        editAction(row) {
            this.edit = true;
            this.edit_id = row.item.id;
            this.$bvModal.show('modal-1');
            this.form.title = row.item.title;
            this.form.address = row.item.company_address;
            this.form.company_type = row.item.company_type;
            this.form.man_lic_bio = row.item.man_lic_bio;
            this.form.man_lic_non_bio = row.item.man_lic_non_bio;
        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .delete(`${process.env.VUE_APP_URL}/api/v1/medicine/company/remove/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }
                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getMedicineClass();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        }
    }
}