import moment from "moment";

export const DateFormat = {
    methods: {
        createdAtFormat(date) {
            return moment(date).format('Do MMM, YYYY h:mm:ss A');
        },

        dateFormat(date) {
            return moment(date).format('Do MMM, YYYY');
        },

        filterFileNameFormat(date) {
            return moment(date).format('DD_MMM');
        },

        checkFileNameFormat(start_date, end_date, file_name) {
            if (start_date && end_date) {
                return this.filterFileNameFormat(start_date) + '_to_' + this.filterFileNameFormat(end_date) + file_name
            } else if (start_date) {
                return this.filterFileNameFormat(start_date) + '_to_' + file_name
            } else if (end_date) {
                return this.filterFileNameFormat(end_date) + '_to_' + file_name
            } else {
                return 'all' + file_name;
            }
        }
    }
}