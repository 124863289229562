import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {required} from "vuelidate/lib/validators";

export const RoleMixin = {
    data() {
        return {
            form: {
                name: '',
            },
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            items: [],
            loading: false,
            name: '',
            nameState: null,
            submittedNames: [],
            groups: [
                {
                    id: 1,
                    name: "Users"
                }
            ],
            fields: [
                {
                    key: 'id',
                    sortable: true
                },
                {
                    key: 'name',
                    sortable: true
                },
                {
                    key: 'guard_name',
                    sortable: true
                },
                {
                    key: 'created_at',
                    sortable: true
                },
                {
                    key: "actions"
                }
            ],
            filter: null,
            filterOn: [],
            is_edit: false,
            edit_id: '',
        }
    },
    validations: {
        form: {
            name: {
                required,
            }
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Roles", route: "roles"},
        ]);
    },

    created() {
        this.getRolesData();
        localStorage.setItem('tabIndex', 13)
        this.$root.$emit('call-active-menu', 13);
    },

    methods: {
        validateState(name) {
            const {$dirty, $error} = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        getRolesData() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/role/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.items = response.data.data
                        this.loading = true;
                    }
                })
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.nameState = valid
            return valid
        },
        resetModal() {
            this.name = '';
            this.nameState = null;
            this.form = {};
            this.$nextTick(() => {
                this.$v.$reset();
            });
            this.is_edit = false;
            this.edit_id = '';
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {

            let form = new FormData();
            form.append('name', this.form.name);
            if (this.is_edit) {
                form.append('role_id', this.edit_id);
            }

            this.$nextTick(() => {
                if (this.is_edit) {
                    axios
                        .post(`${process.env.VUE_APP_URL}/api/v1/role/update?api_token=${localStorage.getItem('api_token')}`, form)
                        .then(response => {
                            this.resetModal();
                            this.getRolesData();
                            this.$bvModal.hide('modal')
                            this.$snotify.success('Successfully saved');
                        })
                } else {
                    axios
                        .post(`${process.env.VUE_APP_URL}/api/v1/role/create?api_token=${localStorage.getItem('api_token')}`, form)
                        .then(response => {
                            this.resetModal();
                            this.getRolesData();
                            this.$bvModal.hide('modal')
                            this.$snotify.success('Successfully saved');
                        })
                }
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        showRolesDetails(id) {
            this.$router.push(`/roles/show/${id}`);
        },
        edit(item) {
            this.$bvModal.show('modal')
            this.form = item;
            this.is_edit = true;
            this.edit_id = item.id;
        }
    },

    computed: {
        roles: {
            get() {
                return this.$store.state.auth.roles;
            },
            rows() {
                return this.items.length
            },
        },
        permissions: {
            get() {
                return this.$store.state.auth.permissions;
            }
        }
    }
}
