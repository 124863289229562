import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export const QuestionariesGroupMixin = {
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    key: 'question_group_title',
                    label: 'Title',
                    sortable: true
                },

                {
                    key: 'status',
                    label: 'status',
                    sortable: true
                },
                {
                    key: 'Actions',
                    label: 'actions',
                },

            ],
            json_fields: {
                "Short tag": {
                    field: "short_tag"
                },
                "Status": {
                    field: "status"
                },
                "Is active": {
                    field: "is_active"
                },
            },
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                short_tag: '',
                status: '',
                is_active: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            sortState: null,
            langState: null,
            statusState: null,
            details: '',
            form: {
                question_group_title: '',
                status: 1,
            },
            speciality: [],
            loading: true,
            langs: [
                {
                    value: 'en',
                    text: 'en'
                },
                {
                    value: 'bn',
                    text: 'bn',
                }
            ],
            types: [
                {
                    value: 'Symptom',
                    text: 'Symptom',
                },
                {
                    value: 'Speciality',
                    text: 'Speciality',
                },
                {
                    value: 'Disease',
                    text: 'Disease',
                },
            ],
            is_update: '',
            row_id: '',
            spciality_required: '',

        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Questionaries group", route: "/questionaries-group"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 7)
        this.$root.$emit('call-active-menu', 7);
        this.questionariesGroup();
        this.getSpeialitiesist();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Questionaries group',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                short_tag: '',
                status: '',
                is_active: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        questionariesGroup() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/questioneries/group/list?api_token=${localStorage.getItem('api_token')}&platform=backoffice`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        getSpeialitiesist() {
            axios.get(`${process.env.VUE_APP_URL}/api/v1/speciality`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.speciality = response.data.data;
                        this.loading = false;
                    }
                })
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid;
            this.sortState = valid;
            this.langState = valid;
            this.statusState = valid;
            return valid
        },
        resetModal() {
            this.titleState = null;
            this.sortState = null;
            this.langState = null;
            this.statusState = null;
            this.form = {};
            this.spciality_required = '';
            this.is_update = '';
            this.row_id = '';
        }
        ,
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            if (this.is_update) {
                return this.updatedata();
            }
            // Trigger submit handler
            this.handleSubmit()
        },
        handleSubmit() {


            if (!this.checkFormValidity()) {

                return
            }


            // Push the name to submitted names
            let form = new FormData();
            form.append("question_group_title", this.form.question_group_title);
            form.append("status", this.form.status);

            // Hide the modal manually
            this.$nextTick(() => {

                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/questioneries/group/store?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.questionariesGroup();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },

        edit(row) {

            this.$bvModal.show('modal-1')
            this.is_update = 1;
            this.row_id = row.item.id;
            this.form = row.item;
        },
        updatedata() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {

                return
            }

            let form = new FormData();
            form.append("question_group_title", this.form.question_group_title);
            form.append("status", this.form.status);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v1/questioneries/group/update/${this.row_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.questionariesGroup();
                        this.resetModal();
                        this.$snotify.success('Successfully updated');
                        this.$bvModal.hide('modal-1')
                    })
            })
        },
    }
}