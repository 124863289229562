import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export const MedicalRecordMixin ={
    data() {
        return {
            patients: [],
            medical_records: [],
            currentPage: 0,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "Show a lot"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filterOn: [],
            patient_id: '',
            filter: null,
            filters: {
                id: '',
                fullname: '',
                email: '',
                phone_number: '',
                type: '',
                prescription_identifier: '',
                details: '',
            },
            fields: [
                {
                    label: 'User',
                    key: 'fullname',
                    sortable:true,
                },
                {
                    label: 'Image',
                    key: 'url',
                    sortable:true,
                },
                {
                    label: 'Type',
                    key: 'type',
                    sortable:true,
                },
                {
                    label: 'Details',
                    key: 'details',
                    sortable:true,
                },
                {
                    label: 'Identifier',
                    key: 'prescription_identifier',
                    sortable:true,
                },
                {
                    label: 'Created date',
                    key: 'created_at',
                    sortable:true,
                },
                {
                    label: 'Actions',
                    key: 'actions',
                },
            ],
            error: false,
            form: {
                comment: '',
                type: '',
                document_id: '',
            },
            commentRequired: null,
            typeRequired: null,
            document_id: '',
            total_record: 0,
            loading:false,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Medical Records", route: "/medical-repords"},
        ]);
    },
    created() {
        localStorage.setItem('tabIndex', 6)
        this.$root.$emit('call-active-menu', 6);
        this.getMedicalReports();
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },

        filtered() {
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const filtered = this.medical_records.filter(item => {
                const itemDate = new Date(item.registration_date)
                if (startDate && endDate) {
                    return startDate <= itemDate && itemDate <= endDate;
                }
                if (startDate && !endDate) {
                    return startDate <= itemDate;
                }
                if (!startDate && endDate) {
                    return itemDate <= endDate;
                }

                if (this.trendy) {
                    return item.is_trending == 1;
                }

                if (this.qc) {

                    return item.available_qc == 1
                }

                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })


            return filtered.length > 0 ? filtered : [{
                id: '',
                fullname: '',
                phone_number: '',
                email: '',
            }]
        },

        filteredData() {

            let filterType = this.selectedType;
            let startDate = this.localizeDate(this.startDate);
            let endDate = this.localizeDate(this.endDate);

            const itemsByType = filterType ? this.medical_records.filter(item => item.type === filterType) : this.items
            return itemsByType
                .filter(item => {
                    const itemDate = new Date(item.registration_date)
                    if (startDate && endDate) {
                        return startDate <= itemDate && itemDate <= endDate;
                    }
                    if (startDate && !endDate) {
                        return startDate <= itemDate;
                    }
                    if (!startDate && endDate) {
                        return itemDate <= endDate;
                    }
                    return true;
                })
        },
    },
    methods: {
        getMedicalReports() {
            this.error = false;
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/patient/document/list?api_token=${localStorage.getItem('api_token')}&offset=0&limit=${this.perPage}`)
                .then(res => {
                    this.medical_records = res.data.data;
                    this.total_record = res.data.total_record;
                    this.error = true;
                })
        },
        handlePageChange(value) {
            this.loading = true;
            var offset = value - 1;
            var correctValue = offset * this.perPage;
            axios.get(`${process.env.VUE_APP_URL}/api/v1/patient/document/list?api_token=${localStorage.getItem('api_token')}&offset=${correctValue}&limit=${this.perPage}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.medical_records = response.data.data;
                        this.total_record = response.data.total_record;
                        this.loading = false;
                    }
                })
        },
        searchDoctor(event){
            event.preventDefault()
            axios.get(`${process.env.VUE_APP_URL}/api/v1/patient/document/list?api_token=${localStorage.getItem('api_token')}&search_text=${this.filter}`)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied');
                    } else {
                        this.medical_records = response.data.data;
                        this.total_record = response.data.total_record;
                        this.loading = false;
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        userPhoto(img) {
            if (img) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        sendInfo(row) {
            this.$bvModal.show('changeStatusModal');
            this.document_id = row.item.id;
            this.form.type = row.item.type;
        },
        resetModal() {
            this.form = {};
            this.commentRequired = null;
            this.typeRequired = null;
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.commentRequired = valid
            this.typeRequired = valid
            return valid
        },
        handleSubmit() {
            if (!this.checkFormValidity()) {
                return
            }
            let form = new FormData();
            form.append('type', this.form.type);
            form.append('comment', this.form.comment);
            form.append('document_id', this.document_id);
            form.append('module_type', 'document');
            form.append('action_type', 'update');

            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/administrator/prescription/medical-records/update?api_token=${localStorage.getItem('api_token')}`, form)
                .then(res => {
                    if (res.data.status_code == 400) {
                        return this.$snotify.error(res.data.message);
                    }
                    this.$bvModal.hide('changeStatusModal');
                    this.getMedicalReports();
                    this.$snotify.success('Successfully updated');
                })
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
        showImage(img) {
            const h = this.$createElement
            // Using HTML string
            // const titleVNode = h('div', {domProps: {innerHTML: ''}})


            // More complex structure
            const messageVNode = h('div', {class: ['foobar']}, [
                h('b-img', {
                    props: {
                        src: `${process.env.VUE_APP_API_URL}/${img}`,
                        thumbnail: true,
                        center: false,
                    }
                })
            ])
            // We must pass the generated VNodes as arrays
            this.$bvModal.msgBoxOk([messageVNode], {
                // title: [titleVNode],
                buttonSize: 'sm',
                centered: true, size: 'lg'
            })
        },
        localizeDate(date) {
            if (!date || !date.includes('-')) return date
            const [yyyy, mm, dd] = date.split('-')
            return new Date(`${mm}/${dd}/${yyyy}`)
        },
    }
}