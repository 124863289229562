import { render, staticRenderFns } from "./InstituteTherapies.vue?vue&type=template&id=885cd55c&scoped=true"
import script from "./InstituteTherapies.vue?vue&type=script&lang=js"
export * from "./InstituteTherapies.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "885cd55c",
  null
  
)

export default component.exports