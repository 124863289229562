<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-between">
          <div class="col-md-2">
            <div class="">
              <b-form-group
                  class="mb-0">
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
          <div class="col-md-2">
            <download-excel
                :data="items"
                class="btn btn-primary"
                :fields="json_fields"
                worksheet="Locality analysis"
                name="Locality analysis">
              <i class="fas fa-file-excel"></i> Export Excel
            </download-excel>
          </div>
          <div class="col-md-4">
            <b-form-group
                class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                show-empty
                thead-class="bg-primary text-white"
                class="mt-2">

              <template #cell(index)="data">
                <div class="d-flex justify-content-between align-content-center align-items-center">
                  <p class="mb-0">{{ data.index + 1 }}</p>
                </div>
              </template>


            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

export default {
  name: "LocalityAnalysis",
  title: 'Locality Analysis',
  data() {
    return {
      districts: [],
      items: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      currentPage: 1,
      perPage: 30,
      pageOptions: [30, 40, 60, {value: 100, text: "Show a lot"}],
      filter: null,
      fields: [
        {
          label: 'SL.',
          key: 'index'
        },
        {
          label: 'City name',
          key: 'city_name',
          sortable: true
        },
        {
          label: 'Doctor count',
          key: 'doctor_count',
          sortable: true
        },
        {
          label: 'Nature of service',
          key: 'nature_of_service',
          sortable: true
        },
      ],
      json_fields: {
        "City name": {
          field: "city_name",
        },
        "Doctor count": {
          field: "doctor_count",
        },
        "Nature of service": {
          field: "nature_of_service"
        }
      }
    }
  },
  created() {
    localStorage.setItem('tabIndex', 4)
    this.$root.$emit('call-active-menu', 4);
    this.getLocalityAnalysis();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Locality Analysis", route: "/locality-analysis-report"},
    ]);
  },
  methods: {
    getLocalityAnalysis() {
      axios
          .get(`${process.env.VUE_APP_URL}/api/v1/report/doctor/list/locality/filter?api_token=${localStorage.getItem('api_token')}`)
          .then(res => {
            this.items = res.data.data.cities;
          })
    },
    toggleDetails(city_id) {
      axios
          .get(`${process.env.VUE_APP_URL}/api/v1/report/doctor/list/locality/filter?api_token=${localStorage.getItem('api_token')}&city_id${city_id}`)
          .then(res => {
            this.items = res.data.data.cities;
          })
    }
  }
}
</script>

<style scoped>

</style>