<script>
import {Pie} from "vue-chartjs";

export default {
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: true,
        // plugins: {
        //  title: {
        //    display: true,
        //    text: 'prescription mode',
        //  }
        // },
        onClick: this.handle
      },

    }
  },
  extends: Pie,
  props: ["data", "name"],
  mounted() {
    this.renderChart(this.data, this.options);
  },

  methods: {
    handle(point, event) {
      // console.log('point', point, 'event', event, 'props',this.name)
      const item = event[0]
      this.$emit('on-receive', {
        value: item._view.label,
        name: this.name
      })
    },
  }
};
</script>
