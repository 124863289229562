import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import {CHAT_BOT_QUERY} from "../api/ApiUrl";

export const ChatBotQueryMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Type": {
                    field: "type",
                },
                "Answer": {
                    field: "answer",
                },
                "Url": {
                    field: "url",
                },
                "User type": {
                    field: "user_type",
                },
                "Status": {
                    field: "status",
                },
            },
            fields: [
                {
                    key: 'index',
                    label: 'SL.',
                    sortable: true
                },
                {
                    label: 'Type',
                    key: 'type'
                },
                {
                    label: 'Answer',
                    key: 'answer'
                },
                {
                    label: 'Url',
                    key: 'url'
                },
                {
                    label: 'User type',
                    key: 'user_type'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Action',
                    key: 'action'
                },

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                type: '',
                url: '',
                answer: '',
                user_type: ''
            },
            filter: null,
            filterOn: [],
            type_state: null,
            answer_state: null,
            user_type_state: null,
            form: {
                type: '',
                url: '',
                answer: '',
                user_type: ''
            },
            user_types: [
                {
                    value: 'doctor',
                    text: 'Doctor'
                },
                {
                    value: 'patient',
                    text: 'Patient'
                },
                {
                    value: 'all',
                    text: 'All'
                }
            ],
            row_id: '',
            is_edit: false,
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "User chat bot query", route: "/chat-bot-query"},
        ]);
    },
    created() {
        this.getBotUserQuery();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Bank List',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                type: '',
                url: '',
                answer: '',
                user_type: ''
            }]
        },
        routeName() {
            return this.$route.name
        },
    },
    methods: {
        getBotUserQuery() {
            axios.get(CHAT_BOT_QUERY)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data
                    }
                })
        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.type_state = valid
            this.user_type_state = valid
            this.answer_state = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.type_state = null;
            this.user_type_state = null;
            this.answer_state = null;
            this.row_id = '';
            this.is_edit = false;
        },
        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            if (this.is_edit) {
                return this.update();
            }
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("type", this.form.type);
            form.append("answer", this.form.answer);
            if (this.form.url) {
                form.append("url", this.form.url);
            }
            form.append("user_type", this.form.user_type)


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/chatbot/query/store?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getBotUserQuery();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })
        },
        editAction(row) {
            this.$bvModal.show("modal-1");
            this.form = row;
            this.is_edit = true;
            this.row_id = row.id;
        },
        update() {
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("type", this.form.type);
            form.append("answer", this.form.answer);
            if (this.form.url) {
                form.append("url", this.form.url);
            }
            form.append("user_type", this.form.user_type)


            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${process.env.VUE_APP_URL}/api/v2/chatbot/query/update/${this.row_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        if (response.data.status_code == 400) {
                            return this.$snotify.error(response.data.message);
                        }
                        this.resetModal();
                        this.getBotUserQuery();
                        this.$bvModal.hide('modal-1')
                        this.$snotify.success('Successfully saved');
                    })
            })

        },
    }
}