<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div>
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <h3 class="text-dark-100 text-hover-primary font-size-h3 font-weight-bold mr-3">
                  Skills
                </h3>
              </div>
              <div class="my-lg-0 my-3">
                <!--                <b-button class="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"-->
                <!--                          v-b-modal.educationModal> Add-->
                <!--                </b-button>-->
              </div>
            </div>
            <div class="row mt-10" v-if="skills.length">
              <div class="col-md-6 mb-5" v-for="(skill, index) in skills" :key="index">
                <b-card class="">
                  <b-card-text>
                    <h3>{{ skill.name }}</h3>
                  </b-card-text>
                </b-card>
              </div>
            </div>
            <div v-else> Not set yet</div>

            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Skill",
  props: {
    skills: {},
  }
}
</script>

<style scoped>

</style>