<template>
  <div class="card">
    <loader-component v-if="loading"/>
    <div class="card-body">
      <div class="row mt-5 align-items-center">
        <div class="col-md-3">
          <div class="row">
            <div class="col-md-12">
              <label for="">From</label>
              <b-form-select v-model="from_date" :options="filter_options"></b-form-select>
            </div>
          </div>
        </div>
        <div class="col-md-2 mt-7">
          <button class="btn btn-success" @click="getItems">Filter</button>
          <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
              striped hover responsive
              :items="items"
              :fields="fields"
              thead-class="bg-primary text-white"
              show-empty
              class="mt-5">
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="total_count"
              :per-page="perPage"
              size="lg"
              @change="getItems"
              class="my-0"
              aria-controls="doctorList"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {DateFormat} from "../../../mixins/core/DateFormat";
import {dateFormate} from "../../../healper/dateTimeFormet";

export default {
  name: "UserMovementTrackingDetail",
  title: 'Doctor Movement Tracking Details',
  mixins: [DateFormat],
  data() {
    return {
      loading: false,
      currentPage: 1,
      perPage: 20,
      items: [],
      fields: [
        {
          key: 'user_name',
          label: 'Doctor\'s Name',
          sortable: true,
        },
        {
          key: 'phone_number',
          label: 'Phone Number',
          sortable: true,
        },
        {
          key: 'app_name',
          label: 'App name',
          sortable: true,
        },
        {
          key: 'route_name',
          label: 'Target point',
          sortable: true,
        },
        {
          key: 'points',
          label: 'Points',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Created At',
          sortable: true,
        },
      ],
      from_date: 'weekly',
      total_count: 0,
      doctors: [],
      user_id: '',
      filter_options: [
        {
          text: 'Weekly',
          value: 'weekly'
        },
        {
          text: 'Biweekly',
          value: 'biweekly'
        },
        {
          text: 'monthly',
          value: 'Monthly'
        },
      ]
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Doctor Movement Tracking", route: "/doctor/movement/tracking"},
    ]);

    this.from_date = this.$route.query.from_date;
    this.getItems();
    this.getUser();
  },
  created() {
    localStorage.setItem('tabIndex', 1)
    this.$root.$emit('call-active-menu', 1);
  },
  methods: {
    getItems(value) {
      this.loading = true;
      var offset = value - 1;
      var correctValue = offset * this.perPage;
      var date_range = '';
      if(this.from_date == 'weekly'){
        date_range = dateFormate().weekly_first_day
      }else if(this.from_date == 'biweekly') {
        date_range = dateFormate().biweekly_first_day
      }else{
        date_range = dateFormate().monthlay_first_day
      }
      let url = `${process.env.VUE_APP_URL}/api/v2/administration/doctors/app/engagement/${this.$route.params.doctorId}?api_token=${localStorage.getItem('api_token')}${this.from_date ? '&from_date=' + date_range : ''}&limit=${this.perPage}${correctValue ? '&offset=' + correctValue : ''}${this.user_id ? '&user_id='+this.user_id : ''}`;
      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_count = response.data.total_count;
              this.loading = false;
            }
          })
    },
    clear() {
      this.from_date = null;
    }
  }
}
</script>

<style scoped>

</style>
