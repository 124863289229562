<template>
  <div>
    <div class="card">
      <loader-component v-if="loading"/>
      <div class="card-body">
        <div class="row mt-5 align-items-center justify-content-between">
          <div class="col-md-3">
            <label for="">Select TSM</label>
            <b-form-select
                id="doctor_type"
                v-model="tsm_id"
                :options="sales_users"
                text-field="name"
                value-field="id">
              <template #first>
                <b-form-select-option value="" disabled>-- Select TSM --</b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div class="col-md-3">
          <label for="">From</label>
                      <input type="month" v-model="start_date" class="form-control">
        </div>
        <div class="col-md-3">
          <label for="">To</label>
                      <input type="month" v-model="end_date" class="form-control">
        </div>
          <div class="col-md-2 mt-7">
            <button class="btn btn-success" @click="getItems">Filter</button>
            <button class="btn btn-danger mx-2 my-2" @click="clear">Clear</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover responsive
                :items="items"
                :fields="fields"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-5">
                <template #cell(target_prescription)="row">
                  <strong>{{ row.item.target_prescription }}</strong>
                  (<span v-for="(data, key) in row.item.months" :key="key">{{ data.month }}' {{ data.year }} - {{ data.target }};</span>)
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <vue-snotify></vue-snotify>
    </div>
    <div class="row mt-5">
      <div class="col-md-4">
        <div class="card border-0">
          <div class="card-body">
            <div class="text-center box-height">
              <h4>Achieved prescription percentage</h4>
              <h3>{{ achieved_prescription_percentage }}%</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card border-0">
          <div class="card-body">
            <div class="text-center box-height">
              <h4>Total Income</h4>
              <h3>{{ total_income }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h5 class="mt-6"><strong>Key Performance Indicator (KPI):</strong></h5>

    <div class="row mt-5">
      <div class="col-md-12">

        <div class="card border-0" v-for="(item, key) in items" :key="key" style="margin-bottom: 20px;">
          <div class="card-body">

              <h6 class="text-center mb-3"><strong>TSM: {{ item.seller_name }}</strong></h6>
              <div class="row mb-1">
                <div class="col-sm-3"><strong>Prescription Acquisition: ({{ calculateKPI(item.target_prescription, item.achieved_prescription, 100) }}%)</strong></div>
                <div class="col-sm-9 pt-1">
                  <b-progress :max="100" variant="info">
                    <b-progress-bar :value="calculateKPI(item.target_prescription, item.achieved_prescription, 100)" :label="`${calculateKPI(item.target_prescription, item.achieved_prescription, 100)}%`"></b-progress-bar>
                  </b-progress>
                </div>
              </div>

              


              <!-- <h4>Prescription Acquisition (70%)</h4>
              <b-progress :max="100" height="16px">
                <b-progress-bar :value="calculateKPI(item.target_prescription, item.achieved_prescription, 100)" :label="`${calculateKPI(item.target_prescription, item.achieved_prescription, 100)}%`"></b-progress-bar>
              </b-progress>

              <h4>Overall Progress</h4>
              <b-progress :max="100" height="16px">
                <b-progress-bar :value="calculateKPI(item.target_doctor, item.achieved_doctor, 30) + calculateKPI(item.target_prescription, item.achieved_prescription, 70)" :label="`${calculateKPI(item.target_prescription, item.achieved_prescription, 70) + calculateKPI(item.target_doctor, item.achieved_doctor, 30)}%`"></b-progress-bar>
              </b-progress> -->


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SalesUserMixins} from "../../mixins/users/SalesUserMixins";
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {dateFormate} from "../../healper/dateTimeFormet";
export default {
  name: "SalesPrescriptionTargetReport",
  mixins: [SalesUserMixins],
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Sales Prescription Target Report", route: "/sales/prescription/target/report"},
    ]);

  },
  data() {
    let all_months = [
      {
        text: 'January',
        value: '1'
      },
      {
        text: 'February',
        value: '2'
      },
      {
        text: 'March',
        value: '3'
      },
      {
        text: 'April',
        value: '4'
      },
      {
        text: 'May',
        value: '5'
      },
      {
        text: 'June',
        value: '6'
      },
      {
        text: 'July',
        value: '7'
      },
      {
        text: 'August',
        value: '8'
      },
      {
        text: 'September',
        value: '9'
      },
      {
        text: 'October',
        value: '10'
      },
      {
        text: 'November',
        value: '11'
      },
      {
        text: 'December',
        value: '12'
      },
    ];
    return {
      monthTranslate: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December"
      },
      items: [],
      fields: [
        {
          key: 'seller_name',
          label: 'TSM name',
          sortable: true,
        },
        {
          key: 'target_prescription',
          label: 'Target prescription',
          sortable: true,
        },
        {
          key: 'achieved_prescription',
          label: 'Achieved prescription',
          sortable: true,
        },

      ],
      loading: false,
      
      
      months: all_months,
      nameState: null,
      tsm_id: '',
      start_date: dateFormate().month_start,
      end_date: dateFormate().month_start,
      doctor_type: '',
      current_year: new Date,
      start_year: 2022,
      years: [],
      total_income: '',
      achieved_prescription_percentage: '',
    }
  },
  created() {
    localStorage.setItem('tabIndex', 14)
    this.$root.$emit('call-active-menu', 14);
    this.getItems();
    this.setYears(15);
    // this.month = this.currentMonth.value;
  },
  computed: {
    currentMonth() {
      let current_month = new Date().getMonth();
      return this.months[current_month.value]
    }
  },
  methods: {
    calculateKPI(target, achievement, weight) {
      if(achievement) {
      let calc = ((achievement * weight)/target).toFixed(2);
      return parseFloat(calc);
      }
      return 0;
    },
    setQueryParams() {
      let params = {};
      if (this.tsm_id) {
        params.user_id = this.tsm_id;
      }
      if (this.month) {
        params.month = this.month;
      }
      if (this.year) {
        params.year = this.year;
      }
      return params;
    },
    getItems() {
      this.loading = true;
      let url = `${process.env.VUE_APP_URL}/api/v2/sales/team/sales/prescription-target/report?api_token=${localStorage.getItem('api_token')}${this.tsm_id ? '&user_id=' + this.tsm_id : ''}${this.start_date ? '&start_date=' + this.start_date : ''}${this.end_date ? '&end_date=' + this.end_date : ''}`;
      this.$router.replace({
        query: this.setQueryParams()
      })
      axios.get(url)
          .then(response => {
            if (response.data.status_code == '500') {
              this.$router.push('/access-denied')
            } else {
              this.items = response.data.data;
              this.total_income = response.data.total_income;
              this.achieved_prescription_percentage = response.data.achieved_prescription_percentage;

              // const result = this.items.groupBy(({ user_id }) => user_id);
              // console.log(result);

              let newArr = [];
              this.items.forEach(item => {
                if(newArr.some((val) => val.user_id == item.user_id)) {
                  let index = newArr.findIndex((val) => val.user_id == item.user_id);
                  newArr[index].target_prescription += parseInt(item.target_prescription);
                  newArr[index].months.push({month: item.month, year: item.year, target: item.target_prescription});
                } else {
                  newArr.push({months:[{month: item.month, year: item.year, target: item.target_prescription}], ...item})
                }
              });

              this.items = newArr;
              this.loading = false;
            }
          })
    },
  
    setYears(num) {
      for (let i = 0; i < num; i++) {
        this.years.push(this.start_year + i);
      }
    },
    clear() {
      this.tsm_id = '';
      this.month = this.months[new Date().getMonth()].value;
      this.year = new Date().getFullYear();
      this.getItems();
    },
  }
}
</script>

<style scoped>
.box-height {
  height: 70px;
}
</style>
