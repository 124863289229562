import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
    CHIEF_COMPLAINTS_LIST,
    CHIEF_COMPLAINTS_STORE,
    CHIEF_COMPLAINTS_UPDATE,
    CHIEF_COMPLAINTS_DELETE,
    CHIEF_COMPLAINTS_MULTIPLE_DELETE
} from "../../api/ApiUrl";

export const ChiefComplaintsMixin = {
    data() {
        return {
            items: [],
            json_fields: {
                "Title": {
                    field: "title",
                },
            },
            fields: [
                {
                    key: 'id',
                    label: 'Id',
                    sortable: true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }

            ],
            currentPage: 1,
            perPage: 20,
            pageOptions: [20, 40, 60, {value: 100, text: "All"}],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filters: {
                id: '',
                title: '',
            },
            filter: null,
            filterOn: [],
            titleState: null,
            strengthState: null,
            useForState: null,
            darState: null,
            unit_priceState: null,
            dosages_desc_typeState: null,
            form: {
                title: '',

            },
            edit: false,
            edit_id: '',
            medicine_generics: [],
            medicine_company: [],
            loading: true,
            active: null,
            tabIndex: '111',
            selected_id: [],
            allDeletedAbleData: [],
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Chief Complaints", route: "/chief-complaints"},
        ]);
    },
    created() {
        this.getAdvice();
    },
    metaInfo: {
        title: 'Orko Health Ltd | Chief Complaints',
        htmlAttrs: {
            lang: 'en-US'
        }
    },
    computed: {
        rows() {
            return this.items.length
        },
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return {text: f.label, value: f.key}
                })
        },
        filtered() {
            const filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key =>
                    String(item[key]).includes(this.filters[key]))
            })
            return filtered.length > 0 ? filtered : [{
                id: '',
                title: '',
            }]
        },
        routeName() {
            return this.$route.name
        },
        checkAll: {
            get: function () {
                return this.items ? this.selected_id.length == this.items.length : false;
            },
            set: function (value) {

                if (value) {
                    var checked = [];
                    var pushData = [];
                    if (value) {
                        this.items.forEach(function (item) {
                            checked.push(item.id);
                            pushData.push(item);
                            // this.allPrintAbleData.push(item);
                        });
                        this.selected_id = checked;
                        this.allDeletedAbleData = pushData;
                    }
                } else {
                    this.selected_id = [];
                    this.allDeletedAbleData = [];
                }


            }
        }
    },
    methods: {

        getAdvice() {
            axios.get(CHIEF_COMPLAINTS_LIST)
                .then(response => {
                    if (response.data.status_code == '500') {
                        this.$router.push('/access-denied')
                    } else {
                        this.items = response.data.data;
                        this.loading = false;
                    }
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity()
            this.titleState = valid
            return valid
        },
        resetModal() {
            this.form = {};
            this.titleState = null;
            this.edit_id = '';
            this.edit = false;
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            if (this.edit) {
                return this.updatedata();
            }
            this.handleSubmit()
        },
        handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(CHIEF_COMPLAINTS_STORE, form)
                    .then(response => {
                        this.checkResponse(response.data.message, response.data.status_code);
                        this.getAdvice();
                        this.$bvModal.hide('modal-1')
                    })
            })
        },
        updatedata() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            // Push the name to submitted names

            let form = new FormData();
            form.append("title", this.form.title);

            // Hide the modal manually
            this.$nextTick(() => {
                axios
                    .post(`${CHIEF_COMPLAINTS_UPDATE}/${this.edit_id}?api_token=${localStorage.getItem('api_token')}`, form)
                    .then(response => {
                        this.checkResponse(response.data.message, response.data.status_code);
                        this.resetModal();
                        this.getAdvice();
                        this.$bvModal.hide('modal-1')

                    })
            })
        },
        editAction(row) {
            this.$bvModal.show('modal-1');
            this.edit = true;
            this.edit_id = row.item.id;

            this.form = row.item;
            this.form.title = row.item.title;
            this.form.medicine_generics = row.item.medicine_generics_id;
            this.form.manufacturer_id = row.item.manufacturer_id;

        },
        userPhoto(img) {
            if (img.match(/.(jpg|jpeg|png|gif)$/i)) {
                return `${process.env.VUE_APP_API_URL}/${img}`;
            } else {
                return "../../assets/img/blank.png";
            }
        },
        deleteAction(row) {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                axios
                                    .post(`${CHIEF_COMPLAINTS_DELETE}/${row.item.id}?api_token=${localStorage.getItem('api_token')}`)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }

                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.getAdvice();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        },

        deletedObj(item) {
            this.allDeletedAbleData.push(item);
        },

        multipleDelete() {
            this.$snotify.clear();
            this.$snotify.confirm(
                "You want to delete",
                "Are you sure?",
                {
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    buttons: [
                        {
                            text: "Yes",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                                let form = new FormData();
                                form.append("delete_ids", this.selected_id);
                                axios
                                    .post(`${CHIEF_COMPLAINTS_MULTIPLE_DELETE}`, form)
                                    .then(response => {

                                        if (response.data.status_code == 400) {
                                            return this.$snotify.error(
                                                response.data.message,
                                                "Error"
                                            );
                                        }

                                        this.$snotify.success(
                                            "Successfully deleted",
                                            "Success"
                                        );
                                        this.selected_id = [];
                                        this.getAdvice();

                                    })
                                    .catch(e => {
                                        this.$snotify.error(
                                            e
                                        );
                                    });
                            },
                            bold: true
                        },
                        {
                            text: "No",
                            action: toast => {
                                this.$snotify.remove(toast.id);
                            },
                            bold: true
                        }
                    ]
                }
            );
        }


    },
    watch: {
        'filter': function (val) {
            console.log('vl', val)
        },
    }
}
