<template>
  <div>
    <div class="card">
      <div class="card-body">

        <div class="row justify-content-end">
          <div class="col-md-4">
            <b-form-group
                label-for="filter-input"
                label-cols-sm="1"
                label-align-sm="right"
                label-size="lg"
                class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"/>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''" variant="danger">Clear</b-button>
                </b-input-group-append>

              </b-input-group>

            </b-form-group>
          </div>

        </div>
        <div class="row">
          <div class="col-md-12">
            <b-table
                striped hover
                :items="filtered"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                @filtered="onFiltered"
                :filter-included-fields="filterOn"
                thead-class="bg-primary text-white"
                show-empty
                class="mt-10">

              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(status)="row">
                <div>
                  <b-badge :variant="statusCheck(row.item.status)">{{ row.item.status }}</b-badge>
                </div>
              </template>

              <template #cell(history)="row">
                <div>
                  <p class="mb-0" v-for="(history, index) in JSON.parse(row.item.history)" :key="index">
                    <span
                        v-if="index=='status' || index=='appointment_type' || index=='payable_amount' || index=='service_type'"> {{
                        index
                      }} : <b>{{ history }}</b></span>
                  </p>
                </div>
              </template>

              <template #cell(history_date)="row">
                <div>
                  <p v-for="(history, index) in JSON.parse(row.item.history)" :key="index" class="mb-0">
                    <span
                        v-if="index == 'schedule_date' || index=='schedule_started_at' || index=='schedule_end_at' || index=='created_at' || index=='created_at'"> {{
                        index
                      }} : <b>{{ history }}</b></span>
                  </p>
                  <!--                  <ul class="mr-2 " style="list-style: circle" v-if="row.item.history">-->

                  <!--                    <li v-for="(history, index) in JSON.parse(row.item.history)" :key="index" class="mb-0 ml-5">-->
                  <!--                      <div v-if="index == 'schedule_date' || index=='schedule_started_at'">-->
                  <!--                        {{ index }} : <b>{{ history }}</b>-->
                  <!--                      </div>-->
                  <!--                    </li>-->
                  <!--                  </ul>-->
                </div>
              </template>


            </b-table>
          </div>
        </div>

      </div>
    </div>
    <loader-component v-if="loading"/>
  </div>
</template>

<script>
import axios from "axios";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {statusCheck} from "../../healper/nameFormating";

export default {
  name: "AppointByHistory",
  metaInfo: {
    title: 'Orko Health Ltd | Appointment by history',
    htmlAttrs: {
      lang: 'en-US'
    }
  },
  data() {
    return {
      loading: true,
      items: [],
      filter: null,
      fields: [
        {
          key: 'index',
          label: 'Sl',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'history',
          label: 'History',
          sortable: true,
        },
        {
          key: 'history_date',
          label: 'History date',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Created date',
          sortable: true,
        },
        {
          key: 'updated_at',
          label: 'Updated at',
          sortable: true,
        },

      ],
      currentPage: 1,
      perPage: 20,
      totalRows: '',
      pageOptions: [20, 40, 60, {value: 100, text: "All"}],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filters: {
        id: '',
        bank_logo: '',
        bank_title: '',
        country_name: '',
        address: '',
        status: '',
      },
      filterOn: [],
      startDate: '',
      endDate: '',
      histories: [],
      pagination: {
        current_page: 1
      },

    }
  },
  mounted() {
    this.getAppointmentList();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Appointment history", route: "/appointment-history"},
    ]);
  },

  computed: {
    rows() {
      return this.items.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return {text: f.label, value: f.key}
          })
    },
    filtered() {
      const filtered = this.items.filter(item => {
        return Object.keys(this.filters).every(key =>
            String(item[key]).includes(this.filters[key]))
      })
      return filtered.length > 0 ? filtered : [{
        id: '',
        history: '',
        created_at: '',
        updated_at: '',
        status: '',
      }]
    },
  },

  methods: {
    getAppointmentList() {
      this.loading = true;
      axios
          .get(`${process.env.VUE_APP_URL}/api/v1/appointment/history/${this.$route.params.id}?api_token=${localStorage.getItem('api_token')}`)
          .then(response => {
            this.loading = false;
            this.items = response.data;

          })
    },

    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}/${img}`;
      } else {
        return "../../assets/img/blank.png";
      }
    },
    statusCheck,
    dateRangeClear() {
      this.startDate = '';
      this.endDate = ''
      this.getAppointmentList();
    },
    localizeDate(date) {
      if (!date || !date.includes('-')) return date
      const [yyyy, mm, dd] = date.split('-')
      return new Date(`${mm}/${dd}/${yyyy}`)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', {dateStyle: 'long'}).format(new Date(date))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style scoped>

</style>